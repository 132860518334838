import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default ventilationPbApi;

function ventilationPbApi() {
  return {
    getVentilationPbByExerciceId: getVentilationPbByExerciceId(),
    getVentilationPbIndividuelByExerciceId: getVentilationPbIndividuelByExerciceId(),
    saveVentilationPb: postVentilationPb(),
    saveVentilationPbIndividuel: postVentilationPbIndividuel(),
    validateVentilationPb: postValidateVentilationPb(),
    exportAffectationPbExcel: exportAffectationPbExcel(),
    importAffectationPbExcel: importAffectationPbExcel(),
  };
}

function getVentilationPbByExerciceId() {
  function getVentilation(exerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.ventilationPb(exerciceId);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getVentilation;
}

function getVentilationPbIndividuelByExerciceId(){
  function getVentilationIndividuel(exerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.ventilationPbIndividuel(exerciceId);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getVentilationIndividuel;
}

function postVentilationPb() {
  function postVentilation(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.ventilationPb(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postVentilation;
}

function postVentilationPbIndividuel() {
  function postVentilationIndividuel(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.ventilationPbIndividuel(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postVentilationIndividuel;
}

function postValidateVentilationPb() {
  function validateVentilation(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.validateVentilationPb(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return validateVentilation;
}

function exportAffectationPbExcel() {
  function exportAffectationPbExcel(pbExerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.exportAffectationPbExcel(pbExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportAffectationPbExcel;
}

function importAffectationPbExcel() {
  function importAffectationPbExcel(pbExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.importAffectationPbExcel(pbExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return importAffectationPbExcel;
}
