import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default capitalApi;

function capitalApi() {
  return {
    getEvolutionCapital: getEvolutionCapital(),
    getRapportCacADate: getRapportCacADate(),
    getPortefeuilleByDate: getPortefeuilleByDate(),
    getCapitalADate: getCapitalADate(),
    getLiberationSouscriptions: getLiberationSouscriptions(),
    getStateEtatsDuCapital: getStateEtatsDuCapital(),
    generateEtatsDuCapital: generateEtatsDuCapital(),
    getEtatsDuCapital: getEtatsDuCapital(),
    getDisponibiliteDuCapital: getDisponibiliteDuCapital(),
  };
}

function getEvolutionCapital() {
  function getEvolutionCapital(dateReference, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(dateReference);
    let evolutionExcel = internalApiRoutes.capital.evolutionExcel(dateString);
    let getForPath = apiConstructor.get(evolutionExcel);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getEvolutionCapital;
}

function getRapportCacADate() {
  function rapportCacADate(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let rapportCacPath = internalApiRoutes.capital.rapportCacADate(dateString);
    let getForPath = apiConstructor.get(rapportCacPath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return rapportCacADate;
}

function getPortefeuilleByDate() {
  function portefeuilleADate(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let excelPortefeuillePath = internalApiRoutes.capital.portefeuilleByDate(dateString);
    let getForPath = apiConstructor.get(excelPortefeuillePath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return portefeuilleADate;
}

function getCapitalADate() {
  function capitalAdate(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let excelCapitalPath = internalApiRoutes.capital.aDate(dateString);
    let getForPath = apiConstructor.get(excelCapitalPath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return capitalAdate;
}

function getLiberationSouscriptions() {
  function liberationSouscriptions(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let liberationSouscriptionsPath = internalApiRoutes.capital.liberationSouscriptions(dateString);
    let getForPath = apiConstructor.get(liberationSouscriptionsPath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return liberationSouscriptions;
}

function getStateEtatsDuCapital() {
  function getStateEtatsDuCapital(queryParams, headers) {
    let stateEtatsDuCapitalPath = internalApiRoutes.capital.getStateEtatsDuCapital();
    let getForPath = apiConstructor.get(stateEtatsDuCapitalPath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStateEtatsDuCapital;
}

function generateEtatsDuCapital() {
  function generateEtatsDuCapital(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let generateEtatsDuCapitalPath = internalApiRoutes.capital.generateEtatsDuCapital(dateString);
    let postForPath = apiConstructor.post(generateEtatsDuCapitalPath);
    return postForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generateEtatsDuCapital;
}

function getEtatsDuCapital() {
  function getEtatsDuCapital(queryParams, headers) {
    let etatsDuCapitalPath = internalApiRoutes.capital.getEtatsDuCapital();
    let getForPath = apiConstructor.get(etatsDuCapitalPath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getEtatsDuCapital;
}

function getDisponibiliteDuCapital() {
  function getDisponibiliteDuCapital(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.capital.getDisponibiliteDuCapital(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getDisponibiliteDuCapital;
}
