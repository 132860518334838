<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formFiscalite" v-slot="{ validate, errors }">
        <base-layout :withFooter="false" headerSize="200px">
            <template v-slot:header>
                <loading-screen ref="loadingComponent"/>
                <v-row>
                    <v-col md="4" lg="4" xl="4" v-if="selectableExercice">
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list v-model="selectedExercice"
                                              :errors="errors"
                                              ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                    <v-col md="3" lg="3" xl="3" v-if="selectableYear">
                        <ValidationProvider vid="selectedYear" name="Année"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-years-list v-model="selectedYear"
                                          :errors="errors"
                                          ref="years"/>
                        </ValidationProvider>
                    </v-col>
                    <v-col md="3" lg="3" xl="3" v-if="selectableMonth">
                        <ValidationProvider vid="selectedMonth" name="Mois"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-months-list v-model="selectedMonth"
                                           :error-messages="errors"
                                           ref="months"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-tabs v-model="currentTab">
                    <v-tab key="fiscalite">Fiscalité</v-tab>
                    <v-tab v-if="univers==='CAPITAL'" key="listeminist">Liste ministérielle</v-tab>
                    <v-tab v-if="univers==='CAPITAL'" :disabled="!hasScicExtensionAccess()" key="fiscaliteScic">Scic</v-tab>
                </v-tabs>
            </template>
            <template v-slot:main>
                <v-row no-gutters>
                    <v-col>
                        <v-tabs-items v-model="currentTab">
                            <v-tab-item key="fiscalite">
                                <base-layout :withHeader="false" :withFooter="false">
                                    <template v-slot:main>
                                        <v-row no-gutters class="ml-3">
                                            <v-col md="2" lg="2" xl="2">
                                                <br/>
                                                <v-radio-group v-model="checkedValue" column
                                                               label="Sélectionnez le document à visualiser :">
                                                    <v-radio v-for="doc in documentsList" :key="doc.component"
                                                             :label="doc.title"
                                                             :value="doc.component" @click.native="loadTab"
                                                             :readonly="selectableExercice && selectedExercice === null || selectableYear && selectedYear === null"/>
                                                </v-radio-group>
                                                <v-cerfa-list :exerciceId="selectedExercice"
                                                            :univers="univers"
                                                            :typeCerfa="checkedValue"
                                                            :year="selectedYear"
                                                            :month="selectedMonth"
                                                            :correspondingCerfa="correspondingCerfa"
                                                            :selectedCerfa="selectedCerfa"
                                                            @update-corresponding-cerfa="correspondingCerfa = $event"
                                                            @update-selected-cerfa="selectedCerfa = $event"/>
                                                <br/>
                                                <p v-if="correspondingCerfa!==undefined">
                                                    Nous recommandons d'imprimer le document officiel '{{correspondingCerfa}}'
                                                </p>
                                                <p v-else>
                                                    Veuillez saisir toutes les informations demandées avant de télécharger un cerfa ({{ mandatoryInfoCerfaList.join(', ') }})
                                                </p>
                                            </v-col>
                                            <v-col md="10" lg="10" xl="10" v-if="univers === 'CAPITAL'">
                                                <v-ifu-capital v-if="checkedValue === 'IFU_2561_capital'"
                                                               :univers="univers"
                                                               :exerciceId="selectedExercice"
                                                               :year="selectedYear"
                                                               :month="selectedMonth"
                                                               :selectedCerfa="selectedCerfa"/>
                                                <v-pfu-capital v-if="checkedValue === 'PFU_2777_capital'"
                                                               :univers="univers"
                                                               :exerciceId="selectedExercice"
                                                               :year="selectedYear"
                                                               :month="selectedMonth"
                                                               :selectedCerfa="selectedCerfa"/>
                                            </v-col>
                                            <v-col md="10" lg="10" xl="10" v-else-if="univers === 'PARTICIPATION'">
                                                <v-ifu-participation v-if="checkedValue === 'IFU_2561_participation'"
                                                                     :univers="univers"
                                                                     @update-selectable-exercice="selectableExercice = $event"
                                                                     @update-selectable-year="selectableYear = $event"
                                                                     @update-selectable-month="selectableMonth = $event"
                                                                     :exerciceId="selectedExercice"
                                                                     :year="selectedYear"
                                                                     :month="selectedMonth"
                                                                     :selectedCerfa="selectedCerfa"/>
                                                <v-pfu-participation v-if="checkedValue === 'PFU_2777_participation'"
                                                                     :univers="univers"
                                                                     @update-selectable-exercice="selectableExercice = $event"
                                                                     @update-selectable-year="selectableYear = $event"
                                                                     @update-selectable-month="selectableMonth = $event"
                                                                     :exerciceId="selectedExercice"
                                                                     :year="selectedYear"
                                                                     :month="selectedMonth"
                                                                     :selectedCerfa="selectedCerfa"/>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </base-layout>
                            </v-tab-item>
                            <v-tab-item v-if="univers==='CAPITAL'" key="livretminist">
                                <base-layout :withHeader="false" :withFooter="false">
                                    <template v-slot:main>
                                        <v-row no-gutters class="pl-3">
                                            <!-- Grand bouton -->
                                            <v-col md="4" lg="4" xl="3">
                                                <v-btn color="primary" @click="downloadListeMinisterielle" block>
                                                    <v-icon left dark>{{ "$vuetify.icons.excel" }}
                                                    </v-icon>
                                                    Générer la liste ministérielle
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </base-layout>
                            </v-tab-item>
                            <v-tab-item v-if="univers==='CAPITAL'" key="fiscaliteScic">
                                <base-layout :withHeader="false" :withFooter="false">
                                    <template v-slot:main>
                                        <VScicFiscalite :selectedExercice="selectedExercice" :errorsList="errors"
                                                        :validate-form="validate"/>
                                    </template>
                                </base-layout>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import VIfuCapital from "@/components/fiscalite/VIfuCapital";
import VPfuCapital from "@/components/fiscalite/VPfuCapital";
import VIfuParticipation from "@/components/fiscalite/VIfuParticipation";
import VPfuParticipation from "@/components/fiscalite/VPfuParticipation";
import VCerfaList from "@/components/fiscalite/VCerfaList";
import VExercicesList from "@/components/VExercicesList";
import VYearsList from "@/components/VYearsList";
import VMonthsList from "@/components/VMonthsList";
import loadingScreen from "@/components/LoadingScreen.vue";
import BaseLayout from "@/components/BaseLayout";
import authService from "@/services/authService";
import VScicFiscalite from "@/views/scic/VScicFiscalite";
import _ from "lodash";

/**
 * Composant affichant les données relatives aux documents légaux
 * @displayName Commun - Fiscalité
 */
export default {
    name: "fiscalite",
    components: {
        VScicFiscalite,
        VIfuCapital,
        VPfuCapital,
        VIfuParticipation,
        VPfuParticipation,
        VCerfaList,
        VExercicesList,
        VYearsList,
        VMonthsList,
        loadingScreen,
        BaseLayout
    },
    data() {
        return {
            currentTab: undefined,
            checkedValue: undefined,
            selectableExercice: undefined,
            selectableYear: undefined,
            selectableMonth: undefined,
            selectedExercice: null,
            selectedYear: null,
            selectedMonth: null,
            univers: undefined, // Surchargé dans FiscaliteCapital et FiscaliteParticipation
            correspondingCerfa: undefined,
            selectedCerfa: undefined,
            documentsList: [], // Surchargé dans FiscaliteCapital et FiscaliteParticipation
        }
    },
    methods: {
        loadTab() {
            this.$refs.formFiscalite.validate();
        },
        hasScicExtensionAccess() {
            return authService.checkHasScicExtensionAccess();
        },
    },
    computed: {
        mandatoryInfoCerfaList() {
            let tmpList = [];
            if (this.univers === "CAPITAL"){
                tmpList.push("exercice");
            }else if (this.univers === "PARTICIPATION"){
                tmpList.push("année");
                if (this.checkedValue === "PFU_2777_participation"){
                    tmpList.push("mois");
                }
            }
            if (this.checkedValue === undefined){
                tmpList.push("type de document");
            }
            return tmpList;
        }
    }
}

</script>