<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-row no-gutters>
            <v-col md="3" lg="3" xl="3">
                <v-row no-gutters>
                    <v-col class="ml-5" md="12" lg="12" xl="12">
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list :value="exerciceId"
                                              @input="resetPaginationAndSearch($event, beneficiaireId)"
                                              :errors="errors"
                                              optionAllExercices/>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="ml-5" md="12" lg="12" xl="12" v-if="showBeneficiaires">
                        <ValidationProvider vid="beneficiaireId"
                                            name="Bénéficiaire"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-beneficiaires-list :value="beneficiaireId"
                                                  label="Bénéficiaire"
                                                  @input="resetPaginationAndSearch(exerciceId, $event)"
                                                  :errors="errors"
                                                  optionAllBeneficiaires
                            />
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="mx-5">
                <v-data-table :footer-props="footerProps"
                              :headers="table.headers"
                              :items="deblocages"
                              :loading="loading"
                              fixed-header
                              :height="height"
                              class="elevation-1"
                              no-data-text="Aucun déblocage."
                              ref="liste_deblocages"
                              :options.sync="pagination"
                              :server-items-length="total_items"
                              block
                >
                    <template v-slot:item="{item}">
                        <tr>
                            <td class="text-lg-left">{{item.tiers_name}}</td>
                            <td class="text-lg-left">{{item.numero_tiers}}</td>
                            <td class="text-lg-left">{{item.date_mouvement | dateIsoToFrFilter}}</td>
                            <td class="text-lg-left">{{item.deblocage_type}}</td>
                            <td class="text-lg-right">{{item.montant | roundEuroFilter}}</td>
                            <td class="text-lg-center">

                                <v-icon-button-with-tool-tip v-if="item.deblocage_type === 'Déblocage anticipé' && ! isEspaceBeneficiaire"
                                                             :routeCible="{name: 'deblocage-anticipe', params: {id: item.id}}"
                                                             detail="Consulter les détails du déblocage anticipé"
                                                             :icon="'$vuetify.icons.view'"
                                                             btnIsIcon btnRound btnText btnSmall iconSmall
                                                             tooltipBottom
                                                             btnColorClass="transparent"
                                                             iconColorClass="grey darken-1"
                                />
                                <v-icon-button-with-tool-tip v-else-if="item.deblocage_type === 'Déblocage anticipé' && isEspaceBeneficiaire"
                                                             :routeCible="{name: 'deblocage-anticipe-consultation-individuel', params: {id: item.id}}"
                                                             detail="Consulter les détails du déblocage anticipé"
                                                             :icon="'$vuetify.icons.view'"
                                                             btnIsIcon btnRound btnText btnSmall iconSmall
                                                             tooltipBottom
                                                             btnColorClass="transparent"
                                                             iconColorClass="grey darken-1"
                                />
                                <v-icon-button-with-tool-tip v-else-if="item.deblocage_type === 'Déblocage à terme' && ! isEspaceBeneficiaire"
                                                             :routeCible="{name: 'deblocage-terme', params: {id: item.id}}"
                                                             detail="Consulter les détails du déblocage à terme"
                                                             :icon="'$vuetify.icons.view'"
                                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                             btnColorClass="transparent"
                                                             iconColorClass="grey darken-1"
                                />
                                <v-icon-button-with-tool-tip v-else-if="item.deblocage_type === 'Déblocage à terme' && isEspaceBeneficiaire"
                                                             :routeCible="{name: 'deblocage-terme-consultation-individuel', params: {id: item.id}}"
                                                             detail="Consulter les détails du déblocage à terme"
                                                             :icon="'$vuetify.icons.view'"
                                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                             btnColorClass="transparent"
                                                             iconColorClass="grey darken-1"
                                />

                                <v-icon-button-with-tool-tip @click.stop="downloadBulletinDeblocage(item.id)"
                                                             detail="Bulletin de déblocage"
                                                             :icon="'$vuetify.icons.downloadPersonalFile'"
                                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                             btnColorClass="transparent"
                                                             iconColorClass="grey darken-1"
                                />

                                <v-custom-confirmation-dialog v-if="!isEspaceBeneficiaire"
                                                              :message="buildConfirmationMessage(item)"
                                                              :isIcon="true"
                                                              :iconValue="'$vuetify.icons.delete'"
                                                              :disabled="!canDeleteDeblocage(item)"
                                                              @action-confirmee="onDeleteOperation(item)"
                                                              tooltipDetailMessage="Supprimer l'opération"
                                                              tooltipDetailOnDisabledButtonMessage="La suppression d'un déblocage est uniquement autorisée pour la dernière opération du bénéficiaire sur le support (hors acquisition de parts sociales)"
                                                              style="display: inline;"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    import VExercicesList from "@/components/VExercicesList";
    import VBeneficiairesList from "@/components/VBeneficiairesList";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";

    /**
     * Composant pour afficher une table de déblocages
     * @display_name Participation - VDatatable déblocages
     */
    export default {
        name: "v-datatable-deblocages",
        components: {
            VExercicesList,
            VIconButtonWithToolTip,
            loadingScreen,
            VBeneficiairesList,
            VCustomConfirmationDialog,
        },
        props: {
            showBeneficiaires: {
                type: Boolean,
                required: false,
                default: true
            },
            height: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                exerciceId: undefined,
                beneficiaireId: undefined,
                isEspaceBeneficiaire: false,
                pagination: {
                    sortBy: ["date_mouvement"],
                    sortDesc: [true],
                    page: 1,
                    itemsPerPage: 10
                },
                total_items: -1,
                loading: true,
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                table: {
                    headers: [
                        {
                            text: "Bénéficiaire",
                            sortable: false,
                            value: "tiers_name",
                            align: "left",
                            class: "text-lg-left"
                        },
                                                {
                            text: "Numéro de tiers",
                            sortable: false,
                            value: "numero_tiers",
                            align: "left",
                            class: "text-lg-left"
                        },
                        {text: "Date", sortable: true, value: "date_mouvement", align: "left", class: "text-lg-left"},
                        {text: "Type", sortable: false, value: "deblocage_type", align: "left", class: "text-lg-left"},
                        {
                            text: "Montant débloqué",
                            sortable: false,
                            value: "montant",
                            align: "end",
                        },
                        {
                            text: "Actions",
                            sortable: false,
                            value: "actions",
                            align: "center",
                        }
                    ]
                },
                deblocages: [],
                /**
                 * La liste des opérations pouvant être supprimées
                 */
                deletable_deblocages_ids: [],
            }
        },
        watch: {
            pagination: {
                handler() {
                    this.getDeblocagesSync(this.exerciceId, this.beneficiaireId);
                },
                deep: true
            },
        },
        mounted() {
            const filtres_actuels = this.$store.getters.getFiltresDeblocages();
            if (!_.isNil(filtres_actuels)) {
                if (filtres_actuels.exercice_filtre) {
                    this.exerciceId = filtres_actuels.exercice_filtre;
                }
                if (filtres_actuels.beneficiaireId) {
                    this.beneficiaireId = filtres_actuels.beneficiaireId;
                }
            }
        },
        methods: {
            resetPagination() {
                this.pagination.page = 1;
            },
            /**
             * Met à jour le filtre sélectionné
             * @param {exerciceId} num L'exercice correspondant au filtre (-1 si aucun)
             * @param {beneficiaireId} Le bénéficiaire actuellement sélectionné
             */
            saveFiltre(exerciceId, beneficiaireId) {
                this.exerciceId = exerciceId;
                this.beneficiaireId = beneficiaireId;
                this.$store.commit("SET_FILTRES_DEBLOCAGES", {
                    exercice_filtre: exerciceId,
                    beneficiaireId: beneficiaireId
                });
            },
            resetPaginationAndSearch(exerciceId, beneficiaireId) {
                this.resetPagination();
                this.getDeblocagesSync(exerciceId, beneficiaireId);
            },
            getDeblocagesSync(exerciceId, beneficiaireId) {
                this.getDeblocages(exerciceId, beneficiaireId).then(data => {
                    this.deblocages = data.items;
                    this.deletable_deblocages_ids = data.deletable_deblocages_ids;
                    this.total_items = data.total;
                });
            },
            /**
             * Récupère les déblocages correspondant au filtre sélectionné
             * @param {exerciceId} num L'exercice correspondant au filtre (-1 si aucun)
             * @param {beneficiaireId} Le bénéficiaire actuellement sélectionné
             */
            getDeblocages(exerciceId, beneficiaireId) {
                this.saveFiltre(exerciceId, beneficiaireId);
                this.loading = true;

                return new Promise((resolve, reject) => {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;

                    let queryParam = {
                        page: page,
                        rows_per_page: itemsPerPage,
                        sort_key: sortBy[0],
                        descending: sortDesc[0],
                        exercice_id: exerciceId,
                        beneficiaire_id: beneficiaireId
                    };

                    internalApi.deblocage.getAll(queryParam)
                        .then(res => {
                            let items = res.deblocages;
                            let deletable_deblocages_ids = res.deletable_deblocages_ids;
                            const total = res.total_items;
                            resolve({
                                'items': items,
                                'deletable_deblocages_ids': deletable_deblocages_ids,
                                'total': total,
                            });
                        })
                        .catch(err => {
                            console.log(err);
                            reject({
                                'items': [],
                                'deletable_deblocages_ids': [],
                                'total': 0,
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            },
            /**
             * Récupère la liste des déblocages de la v-datatable
             */
            getRefListeDeblocages() {
                return this.$refs.liste_deblocages;
            },
            /**
             * Télécharge le bulletin associé au déblocage
             */
            downloadBulletinDeblocage(deblocageId) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.deblocage.downloadBulletinDeblocage(deblocageId)
                    .then(response => {
                        Utils.downloadFile(response, "bulletin_deblocage.pdf", Utils.pdfMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            canDeleteDeblocage(operation) {
                /**
                 * Si l'opération id est dans la liste deletable_deblocages_ids
                 * alors cette opération est supprimable
                 */
                return this.deletable_deblocages_ids.includes(operation.id);
            },
            onDeleteOperation: function (operation) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
                internalApi.operations.deleteByIdWithToastConfirmation(operation.id)
                    .then(response => {
                        this.getDeblocagesSync(this.exerciceId, this.beneficiaireId);
                        this.$emit("delete-operation", operation, this.pagination);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            buildConfirmationMessage(operation) {
                let message = "";
                if (operation.has_been_paid) {
                    const montantDetteRegle = Utils.roundEuroFilter(operation.montant_dette_regle);
                    const montantDette = Utils.roundEuroFilter(operation.montant_dette);
                    message += `
                        <p class="red--text">
                            <span style="font-weight: bold;">Attention</span><br/>
                            Ce déblocage est lié à une dette partiellement ou complètement réglée.<br/>
                            La suppression du déblocage entraine également la suppression de la dette et de ses règlements.
                        </p>
                        <p class="red--text">État de la dette : ${montantDetteRegle} réglés sur ${montantDette}.</p>
                    `;
                } else {
                    message += `<p>La dette liée à ce déblocage n'a pour l'instant reçu aucun règlement.</p>`;
                }
                message += `<p>Êtes-vous sûr(e) de vouloir supprimer ce déblocage ainsi que la dette liée ?</p>`;
                return message;
            },
        }

    }
</script>