// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonnePhysique from "./PersonnePhysique";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
    internalApi.personnePhysique.getInfosEspacePersonnel().then(result => {
      // Need to guess some data to use it correctly
      if(_.isNil(result.infos_bancaire)){
        result.infos_bancaire = {
          "nom_banque": null,
          "bic": null,
          "iban": null,
        };
      }
      if(_.isNil(result.salarie)){
        result.salarie = {
          sal_type_contrat: undefined,
          sal_temps_de_travail: undefined,
          sal_categorie_de_salarie: undefined,
          sal_role: undefined,
        };
      }
      this.personne_physique = result;
      this.modifier_donnees_salarie = !_.isNil(result.salarie.id);
    }).finally(() => {
      this.$refs.loadingComponent.resetLoading();
    });
  },
  extends: PersonnePhysique,
  computed: {
    pageTitle() {
      return "Modifier un tiers personne physique";
    }
  },
  methods: {
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonnePhysiqueBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
      return internalApi.personnePhysique
        .putInfosEspacePersonnelWithToastConfirmation(data_to_send)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          if (result) {
            this.$router.push({name: "home"});
          }
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    },
    cancel(){
      this.$router.push({name: "home"});
    },
  }
};
