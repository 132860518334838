<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="mvtsAssocie" datatableExcludedSize="310px" :withHeader="false" :withFooter="false">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <br/>
            <v-datatable-mouvements
                :readOnly="true"
                :mouvementsList="mouvements"
                consulationAnnulationRoute="consultationAnnulationAssocie"
                consultationSouscriptionRoute="consultationSouscriptionAssocie"
                @update-selected-exercice="function(newfiltreExe, currentPagination) {filtreMouvements(newfiltreExe, typeFilter, currentPagination)}"
                @update-mouvement-type-filter="function(newTypeFilter, currentPagination) {filtreMouvements(selectedExercice, newTypeFilter, currentPagination)}"
                @refresh-mvts-list="filtreMouvements(selectedExercice, typeFilter, $event)"
                :selectedExerciceId="selectedExercice"
                :selectedMouvementType="typeFilter"
                :showTypeSelection="true"
                :total_items="total_items"
                :height="datatableSize"
            />
        </template>
    </base-layout>
</template>

<script>
    import VDatatableMouvements from "@/components/mouvements/VDatatableMouvements.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";

    export default {
        components: {
            BaseLayout,
            VDatatableMouvements,
            loadingScreen
        },
        mounted() {
            const filtres_actuels = this.$store.getters.getFiltresMouvementsAssocie();
            if (!_.isNil(filtres_actuels)) {
                if (filtres_actuels.exercice_filtre) {
                    this.selectedExercice = filtres_actuels.exercice_filtre;
                }
            }
        },
        data() {
            return {
                selectedExercice: -1,
                mouvements: [],
                total_items: -1,
                typeFilter: "all",
            }
        },
        methods: {
            /**
             * Récupère les mouvements filtrés et mis en forme
             * @param currentPagination Objet contenant la mise en forme souhaitée
             */
            loadMouvements: function (currentPagination) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.mouvement.getMvtsAssocie({
                    page: currentPagination.page,
                    rows_per_page: currentPagination.itemsPerPage,
                    sort_key: currentPagination.sortBy[0],
                    descending: currentPagination.sortDesc[0],
                    exercice_filter: this.selectedExercice,
                    mouvement_type_filter: this.typeFilter
                }).then(result => {
                    this.mouvements = result.mouvements.filter(this.checkNbPartsSociales);
                    this.total_items = result.total_items;
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Met à jour les filtres de la datatable et recharge les mouvements
             * @param newSelectedExerciceId Filtre de l'exercice
             * @param newTypeFilter Filtre du type de mouvement
             * @param currentPagination Mise en forme des mouvements dans la datatable
             */
            filtreMouvements(newSelectedExerciceId, newTypeFilter, currentPagination) {
                this.saveFiltre(newSelectedExerciceId, newTypeFilter);
                this.loadMouvements(currentPagination)
            },
            /**
             * Enregistre les nouveaux filtres
             * @param exerciceId filtre par exercice
             * @param typeFilter filtre par type mouvement
             */
            saveFiltre(exerciceId, typeFilter) {
                this.selectedExercice = exerciceId;
                this.typeFilter = typeFilter;
                this.$store.commit("SET_FILTRES_MOUVEMENTS_ASSOCIE", {
                    exercice_filtre: exerciceId,
                    mouvement_type_filter: typeFilter
                });
            },
            checkNbPartsSociales(mouvement) {
                return !(mouvement['nb_parts_sociales'] === 0);
            },
        },
    };
</script>
