<template>
    <base-layout :withHeader="false" :withFooter="true">
        <template v-slot:main>
            <v-data-table :footer-props="footerProps"
                          :headers="table.headers"
                          :items="batches"
                          :loading="loading"
                          fixed-header
                          class="elevation-1"
                          no-data-text="Aucun traitement."
                          :options.sync="pagination"
                          block
            >
                <template v-slot:item="{item}">
                    <tr>
                        <td class="text-lg-left">{{item.request_id}}</td>
                        <td class="text-lg-left">{{item.username}}</td>
                        <td class="text-lg-left">{{item.task_name}}</td>
                        <td class="text-lg-left">{{item.lock_taken_time | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.lock_release_time | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.total_subtask_to_perform}}</td>
                        <td class="text-lg-left">{{item.active | toFrenchBool}}</td>
                        <td class="text-lg-left">{{item.libelle_exercice}}</td>
                        <td class="text-lg-left">{{item.personne_numero_tiers}}</td>
                        <td class="text-lg-left">{{item.support_name}}</td>
                        <td class="text-lg-left">{{item.description}}</td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout";
import internalApi from "@/api/internalApi";

export default {
    name: "AsynchronousBatchTaskMonitoring",
    data(){
        return {
            pagination: {
                sortBy: ["active", "lock_taken_time"],
                sortDesc: [true],
                page: 1,
                itemsPerPage: 10
            },
            footerProps: {
                itemsPerPageOptions: [
                    10,
                    20,
                    50,
                ],
            },
            loading: false,
            table: {
                headers: [
                    {
                        text: "Identifiant du traitement",
                        sortable: true,
                        value: "request_id",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Identifiant du lanceur",
                        sortable: true,
                        value: "username",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Tâche",
                        sortable: true,
                        value: "task_name",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Début",
                        sortable: true,
                        value: "lock_taken_time",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Fin",
                        sortable: true,
                        value: "lock_release_time",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Nombre de tâches enfants",
                        sortable: true,
                        value: "total_subtask_to_perform",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Est en cours",
                        sortable: true,
                        value: "active",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Exercice lié au traitement",
                        sortable: true,
                        value: "libelle_exercice",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Tiers lié au traitement",
                        sortable: true,
                        value: "personne_numero_tiers",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Support lié au traitement",
                        sortable: true,
                        value: "support_name",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Détail technique additionnel",
                        sortable: false,
                        value: "description",
                        align: "left",
                        class: "text-lg-left"
                    },
                ]
            },
            batches: [],
        }
    },
    components: {
        BaseLayout
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            internalApi.asynchronousApi.getBatchNotifications().then(result => {
                this.batches = result;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>