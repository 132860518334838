var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formNewUsersMassAccess",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var reset = ref.reset;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('base-layout',{attrs:{"headerSize":"300px"},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-5 pa-0",attrs:{"cols":"11"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":"","search":_vm.search,"headers":_vm.headers,"items":_vm.listeTiersSansAcces,"no-data-text":_vm.noDataTextMessage,"options":_vm.options,"fixed-header":"","hide-default-footer":"","loading":_vm.isLoading,"height":datatableSize,"disable-pagination":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})],1),_c('td',{staticClass:"text-lg-left"},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"text-lg-left"},[_c('span',[_vm._v(_vm._s(item.numero_tiers))])])])]}}],null,true),model:{value:(_vm.selectedTiers),callback:function ($$v) {_vm.selectedTiers=$$v},expression:"selectedTiers"}})],1)],1)]}}],null,true)},[_c('template',{slot:"header"},[_c('h1',[_vm._v("Créer des accès en masse")]),_c('v-row',[_c('v-col',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.infoBulleContent)}})])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',{staticClass:"ml-2",attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"vid":"loginType","name":"Modèle de login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"label":"Modèle de login :","error-messages":errors},model:{value:(_vm.loginType),callback:function ($$v) {_vm.loginType=$$v},expression:"loginType"}},_vm._l((_vm.loginTypeList),function(typeLogin){return _c('v-radio',{key:typeLogin.key,attrs:{"label":typeLogin.label,"value":typeLogin.key}})}),1)]}}],null,true)})],1),_c('v-col',{staticClass:"ml-5 pa-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filtrer les tiers","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('template',{slot:"footer"},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"showToast":false,"resetForm":reset,"validateForm":validate,"errorsList":errors,"saveMessage":"Créer des accès en masse"},on:{"emit-cancel":_vm.returnToDroitsAcces,"emit-valid-save":_vm.onCreateNewUsersMassAccess}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }