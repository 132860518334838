import Vue from "vue";
import Router from "vue-router";

import Home from "@/views/Home.vue";
import WrongUrl from "@/views/WrongUrl.vue";
import Login from "@/views/Login.vue";
import About from "@/views/About.vue";
import AccordParticipation from "@/views/participation/AccordParticipation.vue";
import Statuts from "@/views/capital/Statuts.vue";
import Company from "@/views/common/Company.vue";
import associes from "./associes";
import exercices from "./common/exercices";
import capExercices from "./capital/exercices";
import pbExercices from "./participation/exercices";
import inteExercices from "./interessement/exercices";
import pbSupports from "./participation/pbSupports";
import SupportsInteressementIndex from "@/views/interessement/support/Index";
import SupportInteressementList from "@/views/interessement/support/SupportInteressementList";
import SupportInteressementCreation from "@/views/interessement/support/SupportInteressementCreation";
import SupportInteressementEdition from "@/views/interessement/support/SupportInteressementEdition";
import MouvementList from "@/views/capital/MouvementList.vue";
import CategorieCapitalCreation from "@/views/capital/categorieCapital/CreationCategorieCapital";
import CategorieCapitalEdition from "@/views/capital/categorieCapital/EditionCategorieCapital";
import CategorieCapitalConsultation from "@/views/categorieCapital/ConsultationCategorieCapital";
import EditionAnnulation from "@/views/capital/annulation/EditionAnnulation";
import CreationAnnulation from "@/views/capital/annulation/CreationAnnulation";
import ConsultationAnnulation from "@/views/capital/annulation/ConsultationAnnulation";
import ConsultationAnnulationAssocie from "@/views/capital/annulation/ConsultationAnnulationAssocie";
import ConsultationSouscription from "@/views/capital/souscription/ConsultationSouscription";
import ConsultationSouscriptionAssocie from "@/views/capital/souscription/ConsultationSouscriptionAssocie";
import EditionSouscription from "@/views/capital/souscription/EditionSouscription";
import CreationSouscription from "@/views/capital/souscription/CreationSouscription";
import Conformite from "@/views/capital/conformite/Conformite.vue";
import CreationAnnulationTotale from "@/views/capital/annulation/CreationAnnulationTotale";
import MainAppView from "@/views/MainAppView.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import FiscaliteCapital from "@/views/capital/fiscalite/FiscaliteCapital";
import FiscaliteParticipation from "@/views/participation/fiscalite/FiscaliteParticipation";
import EspaceAssocie from "@/views/capital/espaceAssocie/EspaceAssocie.vue";
import EspaceBeneficiaire from "@/views/participation/espaceBeneficiaire/EspaceBeneficiaire.vue";
import EspaceIndividuelInte from "@/views/interessement/espaceIndividuelInte/EspaceIndividuelInte.vue";
import EspacePersonnelDocuments from "@/views/common/espacePersonnel/EspacePersonnelDocuments.vue";
import DashboardCapital from "@/views/capital/DashboardCapital";
import DashboardParticipation from "@/views/participation/DashboardParticipation.vue";
import TauxFiscalite from "@/views/TauxFiscalite.vue";
import DroitsAcces from "@/views/droitsAcces/DroitsAcces.vue";
import administration from "./administration";
import tiers from "./tiers";
import reglements from "./common/reglements";
import capReglements from "./capital/capReglements";
import pbReglements from "./participation/pbReglements";
import inteReglements from "./interessement/inteReglements";
import authService from '@/services/authService';
import _ from "lodash";
import rolesList from "@/router/rolesList";
import Index from "../views/Index.vue";
import pbDeblocages from "./participation/pbDeblocages";
import AccordInteressement from "@/views/interessement/AccordInteressement.vue";

Vue.use(Router);

let router = new Router({
    routes: [
      {
        path: "/:tenant?/login/",
        name: "login",
        component: Login,
        meta: {
          isNotProtectedByAuthentication: true,
        },
      },
      {
        path: "/wrongurl/",
        name: "wrongurl",
        component: WrongUrl,
        meta: {
          isNotProtectedByAuthentication: true,
        },
      },
      {
        path: "/",
        name: "main",
        redirect: {name: "wrongurl"},
        component: Index,
        children: [
          {
            path: "uv-commun/",
            name: "uv-commun",
            component: MainAppView,
            props: {universe: 'commun'},
            children: [
              {
                path: "home/",
                name: "home",
                component: Home,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE, rolesList.TIERS],
                },
              },
              {
                path: "about/",
                name: "about",
                component: About,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "unauthorized/",
                name: "unauthorized",
                component: Unauthorized
              },
              {
                path: "espace-capital/:activetab?/",
                name: "espace_capital",
                component: EspaceAssocie,
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "espace-participation/:activetab?/",
                name: "espace_participation",
                component: EspaceBeneficiaire,
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "espace_individuel_interessement/",
                name: "espace_individuel_interessement",
                component: EspaceIndividuelInte,
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "espace-personnel/documents/",
                name: "espace_personnel_documents",
                component: EspacePersonnelDocuments,
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "company/",
                name: "company",
                component: Company,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
                },
              },
              exercices,
              administration,
              tiers,
              reglements,
              {
                path: "droits-acces/",
                name: "droits_acces",
                component: DroitsAcces,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
                },
              },
              {
                path: "taux_fiscalite/",
                name: "taux_fiscalite",
                component: TauxFiscalite,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
                },
              },
            ]
          },
          {
            path: "uv-capital/",
            name: "uv-capital",
            component: MainAppView,
            props: {universe: 'capital'},
            meta: {
              needCapitalAccess: true,
            },
            children: [
              {
                path: "statuts/",
                name: "statuts",
                component: Statuts,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
                },
              },
              associes,
              capExercices,
              {
                path: "mouvements/",
                name: "mouvements",
                component: MouvementList,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "souscriptions/:id/",
                name: "consultationSouscription",
                component: ConsultationSouscription,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "souscriptions/:id/",
                name: "consultationSouscriptionAssocie",
                component: ConsultationSouscriptionAssocie,
                props: {aSpecificBackRoute: '{"name": "espace_capital", "params": {"activetab": "1"}}'},
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "souscriptions/:id/",
                name: "editableSouscription",
                component: EditionSouscription,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "souscriptions/",
                name: "creationSouscription",
                component: CreationSouscription,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "annulations/:id/",
                name: "consultationAnnulation",
                component: ConsultationAnnulation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "annulations/:id/",
                name: "consultationAnnulationAssocie",
                component: ConsultationAnnulationAssocie,
                props: {aSpecificBackRoute: '{"name": "espace_capital", "params": {"activetab": "1"}}'},
                meta: {
                  authorizedRoles: [rolesList.TIERS],
                },
              },
              {
                path: "annulations/:id/",
                name: "editableAnnulation",
                component: EditionAnnulation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "annulations/",
                name: "creationAnnulation",
                component: CreationAnnulation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "annulationtotale/",
                name: "annulationtotale",
                component: CreationAnnulationTotale,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "categories-capitaux/:categorieCapitalId/",
                name: "categorie-capital",
                component: CategorieCapitalEdition,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "categories-capitaux/:categorieCapitalId/",
                name: "consultation-categorie-capital",
                component: CategorieCapitalConsultation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "categories-capitaux/",
                name: "categorie-capital-creation",
                component: CategorieCapitalCreation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              capReglements,
              {
                path: "conformite/",
                name: "conformite",
                component: Conformite,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "dashboard-capital/",
                name: "dashboard_capital",
                component: DashboardCapital,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "fiscalite_capital/",
                name: "fiscalite_capital",
                component: FiscaliteCapital,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                }
              },
            ]
          },
          {
            path: "uv-participation/",
            name: "uv-participation",
            component: MainAppView,
            props: {universe: 'participation'},
            meta: {
              needParticipationAccess: true,
            },
            children: [
              pbExercices,
              {
                path: "accord/",
                name: "accord",
                component: AccordParticipation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              pbSupports,
              pbDeblocages,
              pbReglements,
              {
                path: "dashboard-participation/",
                name: "dashboard_participation",
                component: DashboardParticipation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              {
                path: "fiscalite_participation/",
                name: "fiscalite_participation",
                component: FiscaliteParticipation,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                }
              },
            ]
          },
          {
            path: "uv-interessement/",
            name: "uv-interessement",
            component: MainAppView,
            props: {universe: 'interessement', universeTitle: "intéressement"},
            meta: {
              needInteressementAccess: true,
            },
            children: [
              {
                path: "accord/",
                name: "inte-accord",
                component: AccordInteressement,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
              },
              inteExercices,
              {
                path: "supports-interessement/",
                component: SupportsInteressementIndex,
                meta: {
                  authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                },
                children: [
                  {
                    path: "",
                    name: "liste-supports-interessement",
                    component: SupportInteressementList,
                    meta: {
                      authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                    },
                  },
                  {
                    path: "inte-supports-de-placement/",
                    name: "creation-support-interessement",
                    component: SupportInteressementCreation,
                    meta: {
                      authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                    },
                  },
                  {
                    path: "inte-supports-de-placement/:id/",
                    name: "edition-support-interessement",
                    component: SupportInteressementEdition,
                    meta: {
                      authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
                    },
                  },
                ],
              },
              inteReglements,
            ],
          }
        ]
      },
      {
        path: "*",
        redirect: {name: "wrongurl"},
      },

    ]
  })
;


router.beforeEach(function (to, from, next) {
  if (!to.meta.isNotProtectedByAuthentication) {
    // si le token est valide, on continue
    authService.getValidToken()
      .then(result => {
        // On vérifie que l'utilisateur ne doit pas changer son mot de passe
        if (authService.getDoitChangerMotDePasse() && to.name !== "changePassword"){
          authService.forceChangePassword();
        }
        // on verifie les rôles
        else if (!_.isNil(to.meta.authorizedRoles)) {
          const currentRoles = authService.getCurrentRoles();
          const rolesIntersection = _.intersection(to.meta.authorizedRoles, currentRoles);
          if (!_.isEmpty(rolesIntersection)) {
            next();
          } else {
            console.log("Accès non authorisé lors du routage de la page " + from.fullPath + " vers la page " + to.fullPath);
            authService.unauthorized();
          }
        }
        // si la société a accès à l'univers captial
        else if (to.meta.needCapitalAccess && !authService.hasAccessCapital()) {
          console.error("Tentative d'accès a la page de capital de " + from.fullPath + " vers la page " + to.fullPath + " alors que la société n'a pas accès à capital");
          router.push({name: "home"});
        }
        // si la société a accès à l'univers participation
        else if (to.meta.needParticipationAccess && !authService.hasAccessParticipation()) {
          console.error("Tentative d'accès a la page de participation de " + from.fullPath + " vers la page " + to.fullPath + " alors que la société n'a pas accès à participation");
          router.push({name: "home"});
        }
        // si la société a accès à l'univers intéressement
        else if (to.meta.needInteressementAccess && !authService.hasAccessInteressement()) {
          console.error("Tentative d'accès a la page d'intéressement de " + from.fullPath + " vers la page " + to.fullPath + " alors que la société n'a pas accès à intéressement");
          router.push({name: "home"});
        // si la société a accès aux fonctionalités SCIC
        } else if (to.meta.needScicExtensionAccess && !authService.hasScicExtensionAccess()) {
          console.error("Tentative d'accès a la page pour les SCIC de " + from.fullPath + " vers la page " + to.fullPath + " alors que la société n'a pas été paramétrée comme SCIC");
          router.push({name: "home"});
        } else {
          next();
        }
      })
      .catch(err => {
        console.error("Erreur lors du routage de la page " + from.fullPath + " vers la page " + to.fullPath + " : " + err);
        authService.logout();
      });
  } else {
    // la page ne requiert pas d'autentification
    next();
  }
});


export default router;