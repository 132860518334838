import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default personneApi;

function personneApi() {
  const specificApi = {
    getAll: getAll(),
    getPersonWithNoUserAccess: getPersonWithNoUserAccess(),
    exportTiersPeriodesTravailExcel: exportTiersPeriodesTravailExcel(),
    importTiersPeriodesTravailExcel: importTiersPeriodesTravailExcel(),
    exportModeleTiersPeriodesTravailExcel: exportModeleTiersPeriodesTravailExcel(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.personnes.baseUrl, [
    ACCEPTED_METHODS.getLabels,
    ACCEPTED_METHODS.getById,
  ]));
}

function getAll() {
  function getAllPersonnes(queryParams, headers) {
    let path = internalApiRoutes.personnes.all;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAllPersonnes;
}


function getPersonWithNoUserAccess() {
  function getEmptyUserAccessPerson(queryParams, headers) {
    let path = internalApiRoutes.personnes.getEmptyUserAccessPerson();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getEmptyUserAccessPerson;
}


function exportTiersPeriodesTravailExcel() {
  function exportTiersPeriodesTravailExcel(queryParams, headers) {
    let path = internalApiRoutes.personnes.exportTiersPeriodesTravailExcel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportTiersPeriodesTravailExcel;
}

function importTiersPeriodesTravailExcel() {
  function importTiersPeriodesTravailExcel(body, queryParams, headers) {
    let path = internalApiRoutes.personnes.importTiersPeriodesTravailExcel();
    queryParams = queryParams || {};
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return importTiersPeriodesTravailExcel;
}

function exportModeleTiersPeriodesTravailExcel() {
  function exportModeleTiersPeriodesTravailExcel(queryParams, headers) {
    let path = internalApiRoutes.personnes.exportModeleTiersPeriodesTravailExcel();
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportModeleTiersPeriodesTravailExcel;
}