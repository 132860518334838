import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default beneficiaireApi;

function beneficiaireApi() {
  const specificApi = {
    getEtatDeLaParticipation: getEtatDeLaParticipation(),
    getBeneficiaireIndividuel: getBeneficiaireIndividuel()
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.beneficiaires.baseUrl, [
    ACCEPTED_METHODS.getAll,
  ]));
}

function getEtatDeLaParticipation() {
  function getEtatDeLaParticipation(id, queryParams, headers) {
    let path = internalApiRoutes.beneficiaires.etatDeLaParticipation(id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getEtatDeLaParticipation;
}

function getBeneficiaireIndividuel() {
  function getBeneficiaireIndividuel(queryParams, headers) {
    let path = internalApiRoutes.beneficiaires.getBeneficiaireIndividuel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getBeneficiaireIndividuel;
}