import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";

export default categorieCapitalApi;

function categorieCapitalApi() {
  const specificApi = {
    getCategoriesByDate: getCategoriesByDate(),
    getStatutCategById: getStatutCategById()
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.categoriesCapitaux.baseUrl));
}

function getCategoriesByDate() {
  function getCategoriesByDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.categoriesCapitaux.getCategoriesByDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getCategoriesByDate;
}


function getStatutCategById() {
  function getStatutCategById(categ_statut_id, queryParams, headers) {
    let path = internalApiRoutes.categoriesCapitaux.getStatutCategById(categ_statut_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutCategById;
}