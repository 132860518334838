export default {
    state: {
        companyName: undefined,
        companyResponsableNom: undefined,
        companyResponsablePrenom: undefined,
        companyResponsableEmail: undefined,
        companyResponsablePhone: undefined,
    },
    getters: {
        getCompanyName: state => () => {
            return state.companyName;
        },
        getCompanyResponsableNom: state => () => {
            return state.companyResponsableNom;
        },
        getCompanyResponsablePrenom: state => () => {
            return state.companyResponsablePrenom;
        },
        getCompanyResponsableEmail: state => () => {
            return state.companyResponsableEmail;
        },
        getCompanyResponsablePhone: state => () => {
            return state.companyResponsablePhone;
        },
    },
    mutations: {
        SET_CURRENT_COMPANY(state, new_company) {
            state.companyName = new_company.name;
            state.companyResponsableNom = new_company.nom_responsable;
            state.companyResponsablePrenom = new_company.prenom_responsable;
            state.companyResponsableEmail = new_company.contact_email;
            state.companyResponsablePhone = new_company.phone;
        },
    },
    actions: {
        resetCompany({commit}) {
            commit(
                "SET_CURRENT_COMPANY",
                {
                    "companyName": null,
                    "companyResponsableNom": null,
                    "companyResponsablePrenom": null,
                    "companyResponsableEmail": null,
                    "companyResponsablePhone": null,
                },
            );
        },
    },
}
