<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formChangePassword" v-slot="{ validate, errors, reset }">
        <base-layout id="changePassword" :withFooter="true" headerSize="60px">
            <template v-slot:header>
                <v-row class="pl-3" no-gutters>
                    <v-col>
                        <h1>{{ title }}</h1>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main>
                    <loading-screen ref="loadingComponent"/>
                    <v-row no-gutters>
                        <v-col cols="5">
                            <v-container>
                                <v-subheader><h2>Ancien mot de passe</h2></v-subheader>
                                <v-row no-gutters>
                                    <v-col md="10" lg="10" xl="8">
                                        <ValidationProvider
                                                vid="wrong_old_password"
                                                name="ancien mot de passe"
                                                rules="required" v-slot="{ errors }">        
                                                <v-password-visualiser
                                                        v-model="oldPassword"
                                                        label="Ancien mot de passe"
                                                        :errorMessages="errors"
                                                        />
                                        </ValidationProvider>        
                               
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5">
                    <v-new-password-text-and-confirmation v-model="newPassword"/>
                        </v-col>
                    </v-row>
            </template>
            <template slot="footer">
                    <v-row justify="end" dense>
                        <!-- Petits boutons -->
                        <v-col md="6" lg="6" xl="4">
                            <v-save-buton-with-validation :validateForm="validate"
                                                        :resetForm="reset"
                                                        :errorsList="errors"
                                                        :showToast="false"
                                                        @emit-cancel="onBackClick"
                                                        @emit-valid-save="onPasswordChangeClick"/>
                        </v-col>
                    </v-row>
                </template>
        </base-layout>
    </ValidationObserver>
</template>
<script>
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import authService from '@/services/authService';
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VNewPasswordTextAndConfirmation from "@/components/administration/VNewPasswordTextAndConfirmation";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import VPasswordVisualiser from '@/components/VPasswordVisualiser';

    export default {
        name: "v-change-password",
        components: {
            loadingScreen,
            BaseLayout,
            VNewPasswordTextAndConfirmation,
            VSaveButonWithValidation,
            VPasswordVisualiser,
        },
        data() {
            return {
                title: "Modification de votre mot de passe",
                newPassword: "",
                oldPassword: "",
                dialog: false,
                value: '',
                showPassword: false,
                type: 'password'
            };
        },
        methods: {
            onPasswordChangeClick: function () {
                this.$refs.formChangePassword.validate().then(
                    success => {
                        if (success) {
                            this.changePassword();
                        }
                    }
                )
            },
            closeDialog() {
                this.dialog = false;
            },
            changePassword() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
                let passwords = {"new_password": this.newPassword, "old_password": this.oldPassword};
                internalApi.user.changePassword(passwords)
                    .then(res => {
                        this.$refs.loadingComponent.resetLoading();
                        if(authService.getDoitChangerMotDePasse()){
                            authService.logout();
                        }
                    })
                    .catch(err => {
                        this.$refs.loadingComponent.resetLoading();
                        console.error(err);
                    });
            },
            onBackClick() {
                this.$router.push({
                    name: "home"
                });
            }
        },
    };
</script>