// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Utils from "@/utils.js";
import VIfu from "@/components/fiscalite/VIfu";

/**
 * Composant affichant les données IFU capital
 * @display_name Capital - IFU
 */
export default {
    extends: VIfu,
    methods: {
        /**
         * Détermine les intitulés de colonne
         */
        computeHeaders(){
            return [
                {
                    text: "Associé(e)",
                    // ajout d'une balise htlmtext pour afficher le header sur plusieurs ligne. Pas terrible, mais à priori le componsant v-data-table ne le gère pas nativement
                    htmltext: "Associé(e)",
                    value: "name",
                    sortable: true,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Numéro de tiers",
                    htmltext: "Numéro de tiers",
                    value: "numero_tiers",
                    sortable: false,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Adresse",
                    htmltext: "Adresse",
                    value: "formatedAddress",
                    sortable: false,
                    align: "center",
                    width: "20%"
                },
                {
                    text: "Date de naissance ou n° de SIRET",
                    htmltext: "Date de naissance<br/>ou n° de SIRET",
                    value: "identifiant",
                    sortable: false,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Commune de naissance",
                    htmltext: "Commune<br/>de naissance",
                    value: "lieu_naissance",
                    sortable: false,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Département de naissance",
                    htmltext: "Département<br/>de naissance",
                    value: "dep_naissance",
                    sortable: false,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Intérêts aux parts sociales réglés",
                    htmltext: "Intérêts aux parts<br/>sociales réglés",
                    value: "interets_percus",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Crédit d'impôt prélèvement (2 CK)",
                    htmltext: "Crédit d'impôt<br/>prélèvement (2 CK)",
                    value: "credit_impot",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
            ];
        },
        /**
         * Charge les données dans les tableaux
         */
        loadData() {
            this.loading = true;

            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;

                internalApi.fiscalite_capital.ifu2561Infos(this.exerciceId, {
                    page: page,
                    rows_per_page: itemsPerPage,
                    sort_key: sortBy[0],
                    descending: sortDesc[0],
                }).then(result => {
                    let items = result.detail;
                    const total = result.detail_rows_number;
                    const dateFin = result.date_fin;
                    const anneeAg = result.annee_ag
                    const tauxPrelevementForfaitaire = result.taux_prelevement_forfaitaire;
                    resolve({
                        'items': items,
                        'total': total,
                        'dateFin': dateFin,
                        'anneeAg': anneeAg,
                        'tauxPrelevementForfaitaire': tauxPrelevementForfaitaire,
                    });
                }).catch(err => {
                    console.error("Erreur lors de la récupération des ifu : ", err);
                    let items = [];
                    const total = 0;
                    const dateFin = undefined;
                    const anneeAg = undefined;
                    const tauxPrelevementForfaitaire = undefined;
                    reject({
                        'items': items,
                        'total': total,
                        'dateFin': dateFin,
                        'anneeAg': anneeAg,
                        'tauxPrelevementForfaitaire': tauxPrelevementForfaitaire,
                    });
                }).finally(() => {
                    this.loading = false
                });
            })
        },
        /**
         * Télécharge le cerfa 2561
         */
        downloadIfu2561: function () {
            if(!this.checkCanDownloadIfu()){
                return;
            }
            this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
            internalApi.fiscalite_capital.ifu2561PDF(this.exerciceId, this.selectedCerfa.name)
                .then(response => {
                    Utils.downloadFile(response, "IFU_2561.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Télécharge le cerfa 2561TER
         */
        downloadIfu2561Ter: function () {
            if(!this.checkCanDownloadIfu()){
                return;
            }
            this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
            internalApi.fiscalite_capital.ifu2561PDF(this.exerciceId, this.selectedCerfa.ter)
                .then(response => {
                    Utils.downloadFile(response, "IFU_2561_ter.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Télécharge le fichier de teletransmission
         */
        downloadTeletransmission: function (teletransmissonData) {
            this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
            let params = {
                "annee_teletransmission": teletransmissonData["annee_teletransmission"],
                "type_fichier": teletransmissonData["type_fichier"],
                "version_fichier": teletransmissonData["version_fichier"],
                "type_clef_gnupg": teletransmissonData["type_clef_gnupg"],
            }
            internalApi.fiscalite_capital.ifuTeletransmission(this.exerciceId, params)
                .then(response => {
                    Utils.downloadFile(response.data_fichier, response.nom_fichier, response.content_type);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        }
    }
};
