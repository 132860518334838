<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false">
        <template slot="main">
            <v-row no-gutters justify="center">
                <v-col md="12" lg="12" xl="11">
                    <loading-screen ref="loadingComponent"/>
                    <v-data-table :search="search"
                                  :footer-props="footerProps"
                                  :headers="headers"
                                  :items="items"
                                  fixed-header
                                  class="elevation-1"
                                  no-data-text="Aucune donnée"
                                  ref="item_list"
                                  :options.sync="pagination"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{item.date_debut | dateIsoToFrFilter}}</td>
                                <td>{{item.date_fin | dateIsoToFrFilter}}</td>
                                <td>{{getLabelFromMotif(item.motif_fin_contrat)}}</td>
                                <td>
                                    <v-row no-gutters>
                                        <v-col md="4" lg="3" xl="2">
                                            <v-new-salarie-history-item :salarie_id="salarie_id" :edition="true"
                                                                        :item-to-edit="item"
                                                                        @refresh-items-list="initHistory()">
                                            </v-new-salarie-history-item>
                                        </v-col>
                                        <v-col md="4" lg="3" xl="2">
                                            <v-custom-confirmation-dialog
                                                message="Êtes vous sûr de vouloir supprimer cet élément ?"
                                                :isIcon="true"
                                                :iconValue="'$vuetify.icons.delete'"
                                                @action-confirmee="deleteHistoryItem(item)"
                                                tooltipDetailMessage="Supprimer l'élément"/>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <template slot="footer">
            <v-row no-gutters>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-new-salarie-history-item :salarie_id="salarie_id"
                                                @refresh-items-list="initHistory()">
                    </v-new-salarie-history-item>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import VNewSalarieHistoryItem from "./VNewSalarieHistoryItem";

    /**
     * Composant affichant l'historique des périodes de travail d'un salarié
     * @display_name Commun - SalarieHistoryList
     */
    export default {
        name: "SalarieInfoHistory",
        components: {
            VNewSalarieHistoryItem,
            VCustomConfirmationDialog,
            BaseLayout,
            loadingScreen,
        },
        props: {
            /**
             * L'id du salarié pour lequel afficher l'historique
             */
            salarie_id: {
                type: Number,
                default: undefined,
            },
            getInfo: {
                type: Function,
            }
        },
        data() {
            return {
                items: [],
                search: "",
                pagination: {
                    sortBy: ["date_debut"],
                    sortDesc: [false]
                },
                footerProps: {
                    itemsPerPageOptions: [10, 20, 50]
                },
                headers: [
                    {text: "Date de début", sortable: true, value: "date_debut", align: "left"},
                    {text: "Date de fin", sortable: true, value: "date_fin", align: "left"},
                    {text: "Motif de fin", sortable: true, value: "motif_fin_contrat", align: "left"},
                    {text: "Actions", sortable: false, value: "actions", align: "left"}
                ],
                labels_motif_fin_contrat: undefined,
            }
        },
        watch: {
            salarie_id() {
                this.initHistory();
            }
        },
        mounted() {
            this.getLabelsMotif();
            this.initHistory();
        },
        methods: {
            /**
             * Récupère la liste des possibilités pour le motif de fin de contrat
             * @returns [string]
             */
            getLabelsMotif() {
                return internalApi.periodeTravail.getLabels().then(result => {
                    this.labels_motif_fin_contrat = result.motif_fin_contrat;
                });
            },

            /**
             * Returns the readable label from a motif_fin_contrat key
             */
            getLabelFromMotif(key) {
                if (this.labels_motif_fin_contrat !== undefined) {
                    return this.labels_motif_fin_contrat[key];
                } else {
                    return key;
                }
            },
            /**
             * Récupère les périodes de travail d'un salarié
             */
            initHistory() {
                if (this.salarie_id) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                    this.getItems()
                        .then(res => {
                            this.items = res;
                        }).catch(err => {
                            console.log(err);
                        }).finally(() => {
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            /**
             * Supprime une période de travail d'un salarié puis rafraîchit la liste des périodes
             * de travail
             * @param item la période de travail à supprimer
             */
            deleteHistoryItem(item) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

                this.deleteItem(item)
                    .then(res => {
                        this.initHistory();
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Retourne la promesse permettant de récupérer les périodes de travail d'un salarié
             * @returns {promise}
             */
            getItems() {
                return internalApi.periodeTravail.getPeriodesTravailSalarie(this.salarie_id);
            },
            /**
             * Retourne la promesse permettant de supprimer une période de travail via son id
             * @param item l'item contenant l'id de la période de travail
             * @returns {promise}
             */
            deleteItem(item) {
                return internalApi.periodeTravail.deletePeriodeTravailById(item.id);
            },
            /**
             * Retourne la promesse permettant de modifier une période de travail via son id
             * @param item l'item contenant l'id de la période de travail
             * @returns {promise}
             */
            editItem(item) {
                return internalApi.periodeTravail.putByIdWithToastConfirmation(item.id, item);
            },
        },
    }
</script>