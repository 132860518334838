<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout headerSize="80px">
            <template v-slot:header>
                <v-row class="pl-3" no-gutters>
                    <v-col>
                        <h1>{{ getTitle() }}</h1>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <br/>
                <v-row no-gutters>
                    <v-date-associe-header :valueSelectedItem="annulation.associe_id"
                                           labelDate="Date d'annulation"
                                           :valueDateMouvement="annulation.date_mouvement"
                                           :isDisabled="!isEditable"
                                           :canEditDate="canEditDate"
                                           @update-selected-associe="onUpdateSelectedAssocie($event)"
                                           @update-date-mouvement="onUpdateDateMouvement($event)"
                                           :allowedDateAfterLastExercice="allowedDateAfterLastExercice"
                                           ref="date_annu"/>
                </v-row>
                <v-col v-if="isEditable">
                    <v-portefeuille
                        ref="portefeuille"
                        :isBrouillon="isBrouillon"
                        :valueSelectedItem="annulation.associe_id"
                        :valueDateMouvement="annulation.date_mouvement"
                    />
                </v-col>
                <v-row v-if="isBrouillon" no-gutters>
                    <v-col md="4" lg="4" xl="4" offset-md="1">
                        <ValidationProvider vid="date_signa_bulle" name="Date de signature du bulletin"
                                            rules="required"
                                            v-slot="{ errors }">
                            <DateField ref="date_sign"
                                       v-model="annulation.date_signa_bulle"
                                       label="Date de signature du bulletin"
                                       :errors="errors"
                                       :disabled="!isEditable"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row disabled no-gutters>
                    <v-col md="4" lg="4" xl="4" offset-md="1">
                        <ValidationProvider vid="category_id" name="Catégorie de capital"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-categories-capital-list v-model="annulation.category_id"
                                                       :errors="errors"
                                                       @change="updateMontantARembourser"
                                                       :isDisabled="!isEditable"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row v-if="!isBrouillon" no-gutters>
                    
                    <v-col md="4" lg="4" xl="4" offset-md="1">
                        <ValidationProvider vid="motif" name="motif" rules="required" v-slot="{ errors }">
                            <v-select v-model="annulation.motif"
                                        :items="motifItems"
                                        label="Motif légal d'annulation"
                                        :disabled="!isEditable"
                                        :error-messages=errors
                            />
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col md="4" lg="4" xl="4" offset-md="1">
                        <ValidationProvider vid="nb_parts_sociales" name="Nombre de parts annulées"
                                            rules="required|numeric|min_value:1"
                                            v-slot="{ errors }">
                            <v-formatted-number-text-field v-model="annulation.nb_parts_sociales"
                                                           labelValue="Nombre de parts annulées"
                                                           :errors="errors"
                                                           @input="updateMontantARembourser"
                                                           :disabledValue="!isEditable"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col md="3" lg="3" xl="3" offset-md="1" v-if="!isBrouillon">
                        <v-formatted-number-text-field v-model="annulation.montant_remboursement_parts_sociales"
                                                       labelValue="Montant à rembourser"
                                                       prefixValue="€"
                                                       :reverseValue="true"
                                                       :disabledValue="true"
                                                       :hint="message"
                                                       persistent-hint
                        />
                    </v-col>
                    <v-col md="4" lg="4" xl="4" offset-md="1">
                        <ValidationProvider vid="commentaire" name="commentaire"
                                            rules="max:50"
                                            v-slot="{ errors }">
                            <v-text-field v-model="annulation.commentaire"
                                        label="Commentaire"
                                        :error-messages="errors"
                                        counter=50
                                        :disabled="!isEditable"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </template>
            <template slot="footer">
                <v-row v-if="isEditable" justify="end" dense>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      :showToast="false"
                                                      @emit-cancel="onBackClick"
                                                      @emit-valid-save="submit"/>
                    </v-col>
                </v-row>
                <v-row v-else justify="end" dense>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn block color="primary" @click="onBackClick">Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import _ from "lodash";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
import VDateAssocieHeader from "@/components/VDateAssocieHeader.vue";
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
import VPortefeuille from "@/components/VPortefeuille.vue";
import BaseLayout from "@/components/BaseLayout";
import DateField from "@/components/DateField.vue";
import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";
import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
import VSelect from "vuetify/lib/components/VSelect/VSelect";

export default {
    components: {
        VCategoriesCapitalList,
        BaseLayout,
        VFormattedNumberTextField,
        VDateAssocieHeader,
        VSaveButonWithValidation,
        DateField,
        loadingScreen,
        VPortefeuille,
        VSelect,
    },
    data() {
        return {
            selectValues: {},
            annulation: {
                associe_id: undefined,
                date_mouvement: undefined,
                montant_remboursement_parts_sociales: "",
                type_annu: "part",
                date_signa_bulle: undefined,
                motif: "aucun_motif",
            },
            message: "",
            isEditable: false,
            isBrouillon: false,
            canEditDate: false,
            allowedDateAfterLastExercice: true,
            specificBackRoute: undefined,
            motifItems: undefined,
        };
    },
    mounted() {
        this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

        let promise_labels = this.fetchLabels();

        Promise.all([
            promise_labels,
        ]).finally(() => {
            this.$refs.loadingComponent.resetLoading();
        });
    },
    props: {
        associeId: {
            type: Number
        },
        dateMouvement: {
            type: String
        }
    },
    methods: {
        onUpdateSelectedAssocie(eventValue) {
            this.annulation.associe_id = eventValue;
        },
        onUpdateDateMouvement(eventValue) {
            this.annulation.date_mouvement = eventValue;
            this.updateMontantARembourser();
        },
        computeMontantARembourserPossible(annulation) {
            return (
                this.isEditable &&
                _.isNumber(annulation.nb_parts_sociales) &&
                !_.isEmpty(annulation.date_mouvement) &&
                !_.isNil(annulation.category_id)
            );
        },
        updateMontantARembourser() {
            if (this.computeMontantARembourserPossible(this.annulation)) {
                let cloned_annulation = _.cloneDeep(this.annulation);
                delete cloned_annulation.id;
                if (_.isNil(cloned_annulation.commentaire)) {
                    cloned_annulation.commentaire = null;
                }
                internalApi.annulation
                    .postMontantARembourser(cloned_annulation)
                    .then(res => {
                        this.annulation.montant_remboursement_parts_sociales = res.montant_remboursement_parts_sociales;
                        this.message = res.err_msg;
                    });
            } else {
                this.message = "";
                this.annulation.montant_remboursement_parts_sociales = 0;
            }
        },

        fetchLabels() {
            return internalApi.annulation.getLabels().then(res => {
                this.motifItems = Utils.fromLabelToSelect(res["annulation.motif"]);
                this.selectValues = Object.assign({}, this.selectValues, {
                    types_annulation: Utils.fromLabelToSelect(res["annulation.type"])
                });
            });
        },

        /**
         * Méthode appelée par les composants enfants édition et consultation
         */
        getAnnulation() {
            internalApi.annulation.getById(this.annulationId).then(res => {
                this.annulation = res;
                // on affiche le nb de parts à annuler en positif alors que le back les sauvegarde en négatif !
                this.annulation.nb_parts_sociales = -this.annulation.nb_parts_sociales;
                this.annulation.montant_remboursement_parts_sociales = -this.annulation.montant_remboursement_parts_sociales;
            });
        },

        /**
         * Process VeeValidate fields controls and then make API calls
         * to POST or PUT resource on backend
         */
        submit() {
            this.submitForm();
        },
        pushToMouvements() {
            this.$router.push({
                name: "mouvements"
            });
        },
        onBackClick() {
            if (_.isNil(this.specificBackRoute)) {
                this.pushToMouvements();
            } else {
                this.$router.push(JSON.parse(this.specificBackRoute));
            }
        },
        // Méthodes pouvant être surchargée par le composant enfant !
        getTitle() {
            return this.computeTitle();
        },
        computeTitle() {
            return "Annulation Partielle";
        },
    }
};
</script>
