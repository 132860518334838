var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.categorieCapital)?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"10"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('base-layout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-row',[_c('h1',{staticClass:"pb-3"},[_vm._v("Catégorie de Capital")])])]},proxy:true},{key:"main",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"code","name":"Code catégorie de capital","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Code catégorie de capital","counter":"10","error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.code),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "code", $$v)},expression:"categorieCapital.code"}})]}}],null,true)})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"libelle","name":"Libellé catégorie de capital","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Libellé catégorie de capital","error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.libelle),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "libelle", $$v)},expression:"categorieCapital.libelle"}})]}}],null,true)})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"2","lg":"2","xl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"type_capital","name":"Type de capital","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Type de capital","items":_vm.selectItems.typeCapital,"error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.type_capital),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "type_capital", $$v)},expression:"categorieCapital.type_capital"}})]}}],null,true)})],1)],1),_c('v-container',[_c('v-expansion-panels',{attrs:{"multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('b',[_vm._v("Plancher et Plafond")])]),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"plan_is_montant_fixe","name":"Montant plancher fixe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Montant plancher fixe","error-messages":errors,"disabled":!_vm.isEditable},on:{"change":_vm.switchMontantPlancher},model:{value:(_vm.categorieCapital.plan_is_montant_fixe),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plan_is_montant_fixe", $$v)},expression:"categorieCapital.plan_is_montant_fixe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"plan_montant","name":"Montant plancher","rules":"currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"€","labelValue":"Montant plancher","reverseValue":true,"errors":errors,"disabledValue":!_vm.categorieCapital.plan_is_montant_fixe || !_vm.isEditable},model:{value:(_vm.categorieCapital.plan_montant),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plan_montant", $$v)},expression:"categorieCapital.plan_montant"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"8"}},[_c('ValidationProvider',{attrs:{"vid":"plan_compte_dans_montant_global","name":"Inclus dans le plancher global","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Inclus dans le plancher global","error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.plan_compte_dans_montant_global),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plan_compte_dans_montant_global", $$v)},expression:"categorieCapital.plan_compte_dans_montant_global"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageInclusPlancherGlobal)}})])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"space-between","no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"plaf_is_montant_fixe","name":"Montant plafond fixe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Montant plafond fixe","error-messages":errors,"disabled":!_vm.isEditable},on:{"change":_vm.switchMontantPlafond},model:{value:(_vm.categorieCapital.plaf_is_montant_fixe),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plaf_is_montant_fixe", $$v)},expression:"categorieCapital.plaf_is_montant_fixe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"plaf_montant","name":"Montant plafond","rules":"currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"€","labelValue":"Montant plafond","reverseValue":true,"errors":errors,"disabledValue":!_vm.categorieCapital.plaf_is_montant_fixe || !_vm.isEditable},model:{value:(_vm.categorieCapital.plaf_montant),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plaf_montant", $$v)},expression:"categorieCapital.plaf_montant"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"plaf_compte_dans_plaf_global","name":"Inclus dans le plafond global","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Inclus dans le plafond global","error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.plaf_compte_dans_plaf_global),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "plaf_compte_dans_plaf_global", $$v)},expression:"categorieCapital.plaf_compte_dans_plaf_global"}})]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('b',[_vm._v("Souscription")])]),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"sous_unique","name":"Souscription Unique","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Souscription Unique","prefix":"€","reverse":"","error-messages":errors,"disabled":!_vm.isEditable},model:{value:(_vm.categorieCapital.sous_unique),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "sous_unique", $$v)},expression:"categorieCapital.sous_unique"}})]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('b',[_vm._v("Rémunération")])]),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"is_remunere","name":"Peut être rémunéré","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Peut être rémunéré","error-messages":errors,"disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFieldsIfNonRemunere()}},model:{value:(_vm.categorieCapital.is_remunere),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "is_remunere", $$v)},expression:"categorieCapital.is_remunere"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"remu_rang","name":"Rang de rémunération","rules":{ required: _vm.categorieCapital.is_remunere, currency: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Rang de rémunération","reverseValue":true,"errors":errors,"disabledValue":!_vm.categorieCapital.is_remunere || !_vm.isEditable},model:{value:(_vm.categorieCapital.remu_rang),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "remu_rang", $$v)},expression:"categorieCapital.remu_rang"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-radio-group',{attrs:{"disabled":!_vm.categorieCapital.is_remunere || !_vm.isEditable},on:{"change":_vm.changeRemunerationType},model:{value:(_vm.categorieCapital.remu_type),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "remu_type", $$v)},expression:"categorieCapital.remu_type"}},_vm._l((_vm.typeRemuList),function(item){return _c('v-radio',{key:item.key,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('ValidationProvider',{attrs:{"vid":"remu_taux_fixe","name":"Taux fixe de rémunération","rules":{ required: _vm.categorieCapital.remu_type === 'fixe', between: {'min': 0, 'max': 100}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"%","labelValue":"Taux fixe de rémunération","reverseValue":true,"errors":errors,"disabledValue":_vm.categorieCapital.remu_type !=='fixe'},model:{value:(_vm.categorieCapital.remu_taux_fixe),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "remu_taux_fixe", $$v)},expression:"categorieCapital.remu_taux_fixe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageTauxFixeRemu)}})])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('b',[_vm._v("Remboursement")])]),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('ValidationProvider',{attrs:{"vid":"is_rembourse","name":"Peut être remboursé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Peut être remboursé","error-messages":errors,"disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFieldsIfNonRembourse()}},model:{value:(_vm.categorieCapital.is_rembourse),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "is_rembourse", $$v)},expression:"categorieCapital.is_rembourse"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"rembt_delai","name":"Délai de remboursement","rules":{ currency: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"Mois","labelValue":"Délai de remboursement","reverseValue":true,"errors":errors,"disabledValue":!_vm.categorieCapital.is_rembourse || !_vm.isEditable},model:{value:(_vm.categorieCapital.rembt_delai),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "rembt_delai", $$v)},expression:"categorieCapital.rembt_delai"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"10","xl":"10"}},[_c('ValidationProvider',{attrs:{"vid":"is_valeur_rmbt_liee_exercice","name":"Valeur de remboursement liée à l'exercice","rules":{ required: _vm.categorieCapital.is_rembourse}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Valeur de remboursement liée à l'exercice","error-messages":errors,"disabled":!_vm.categorieCapital.is_rembourse || !_vm.isEditable},model:{value:(_vm.categorieCapital.is_valeur_rmbt_liee_exercice),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "is_valeur_rmbt_liee_exercice", $$v)},expression:"categorieCapital.is_valeur_rmbt_liee_exercice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"1","lg":"1","xl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.info")+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageValeurRemboursement)}})])],1)],1)],1),_c('v-col',{attrs:{"md":"3","lg":"3","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"is_valeur_rmbt_fixe","name":"Valeur de remboursement fixe","rules":{ required: _vm.categorieCapital.is_rembourse}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Valeur de remboursement fixe","error-messages":errors,"disabled":!_vm.categorieCapital.is_rembourse || !_vm.isEditable},on:{"change":function($event){return _vm.clearFieldIfNonValeurRemboursementFixe()}},model:{value:(_vm.categorieCapital.is_valeur_rmbt_fixe),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "is_valeur_rmbt_fixe", $$v)},expression:"categorieCapital.is_valeur_rmbt_fixe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"rembt_valeur_fixe_part","name":"Valeur de remboursement fixe","rules":{ required: _vm.categorieCapital.is_rembourse && _vm.categorieCapital.is_valeur_rmbt_fixe, currency: true, min_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"prefixValue":"€","labelValue":"Valeur de remboursement fixe","reverseValue":true,"errors":errors,"disabledValue":!_vm.categorieCapital.is_rembourse || !_vm.categorieCapital.is_valeur_rmbt_fixe || !_vm.isEditable},model:{value:(_vm.categorieCapital.rembt_valeur_fixe_part),callback:function ($$v) {_vm.$set(_vm.categorieCapital, "rembt_valeur_fixe_part", $$v)},expression:"categorieCapital.rembt_valeur_fixe_part"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors,"disabledValue":!_vm.isEditable},on:{"emit-cancel":_vm.retourStatuts,"emit-valid-save":_vm.saveCategorieCapital}})],1)],1)]},proxy:true}],null,true)})]}}],null,false,3056302331)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }