<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-autocomplete v-model="localCategorieCapitalValue"
                        :label="label"
                        :items="categoriesCapitalList"
                        item-value="categorie_capital_id"
                        item-text="name"
                        :error-messages="errors"
                        :disabled="this.isDisabled"/>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import _ from "lodash";

    export default {
        props: {
            withDefaultValue: {
                type: Boolean,
                required: false,
                default: false
            },
            isDisabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                type: Number,
                required: false,
                default: undefined,
            },
            errors: {
                type: Array,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: "Catégorie de capital"
            }
        },
        mounted() {
            this.fetchCategoryCapitalList();
        },
        data() {
            return {
                categoriesCapitalList: []
            }
        },
        methods: {
            fetchCategoryCapitalList() {
                return internalApi.categorieCapital.getAll()
                    .then(results => {
                        this.categoriesCapitalList =_.sortBy(results, ["code"]);
                    });
            },
        },
        computed: {
            localCategorieCapitalValue: {
                get: function () {
                    return this.value;
                },
                set: function (newValue) {
                    this.$emit("input", newValue);
                    this.$emit("change");
                }
            }
        },
    };
</script>


