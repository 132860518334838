<!-- VUETIFY2 - OK -->
<template>
    <v-container>
        <v-row justify="space-between" dense>
            <v-col md="3" lg="3" xl="3">
                <ValidationProvider vid="localDateDebut"
                                    name="Date de début"
                                    :rules="{ required: true , date_before: date_fin }"
                                    v-slot="{ errors }">
                    <DateField
                        v-model="localDateDebut"
                        label="Date de début"
                        :errors="errors"
                        :disabled="exerciceValide"
                    />
                </ValidationProvider>
            </v-col>
            <v-col md="3" lg="3" xl="3">
                <ValidationProvider vid="localDateFin"
                                    :name="computeLabel(date_debut, 'Date de fin','Date de fin - Veuillez renseigner une date de début')"
                                    :rules="{ required: true , date_after: date_debut }"
                                    v-slot="{ errors }">
                    <DateField
                        v-model="localDateFin"
                        :label="computeLabel(date_debut, 'Date de fin','Date de fin - Veuillez renseigner une date de début')"
                        :errors="errors"
                        :disabled="exerciceValide || date_debut === undefined"
                    />
                </ValidationProvider>
            </v-col>
            <v-col md="3" lg="3" xl="3">
                <ValidationProvider vid="localDateAgAnnuelle"
                                    :name="computeLabel(date_fin, 'Date AG annuelle','Date AG annuelle - Veuillez renseigner une date de fin')"
                                    :rules="{ required: true , date_after: date_fin }"
                                    v-slot="{ errors }">
                    <DateField
                        v-model="localDateAgAnnuelle"
                        :label="computeLabel(date_fin, 'Date AG annuelle','Date AG annuelle - Veuillez renseigner une date de fin')"
                        :errors="errors"
                        :disabled="exerciceValide || date_fin === undefined"/>
                </ValidationProvider>
            </v-col>
        </v-row>
        <br/>
        <v-row justify="space-between" dense>
            <v-col md="5" lg="5" xl="5">
                <ValidationProvider vid="localLibelle"
                                    name="Libellé de l'exercice"
                                    rules="required|max:100"
                                    v-slot="{ errors }">
                    <v-text-field v-model="localLibelle"
                                  label="Libellé de l'exercice"
                                  :error-messages="errors"
                                  :disabled="exerciceValide"/>
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-row justify="space-between" dense>
            <v-col md="5" lg="5" xl="5">
                <ValidationProvider vid="localMontantParticipationBrute"
                                    name="Participation brute"
                                    rules="required|currency|min_value:0"
                                    v-slot="{ errors }">
                    <v-formatted-number-text-field v-model="localMontantParticipationBrute"
                                                   prefixValue="€"
                                                   labelValue="Participation brute"
                                                   :reverseValue="true"
                                                   :errors="errors"
                                                   :disabledValue="exerciceValide"/>
                </ValidationProvider>
            </v-col>
            <v-col md="5" lg="5" xl="5" v-if="showExcedentsNetsPb">
                <v-formatted-number-text-field v-model="taux_excedents_nets_pour_pb"
                                               prefixValue="%"
                                               labelValue="% d'excédents nets pour la participation"
                                               slot="activator"
                                               :reverseValue="true"
                                               :disabledValue="true"/>
            </v-col>
        </v-row>
        <v-row justify="space-between" dense>
            <v-col md="5" lg="5" xl="5">
                <ValidationProvider vid="localBenefices"
                                    name="Bénéfices nets"
                                    rules="required|currency|min_value:0"
                                    v-slot="{ errors }">
                    <v-formatted-number-text-field v-model="localBenefices"
                                                   prefixValue="€"
                                                   labelValue="Bénéfices nets"
                                                   :reverseValue="true"
                                                   :errors="errors"
                                                   :disabledValue="exerciceValide"/>
                </ValidationProvider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import Utils from "@/utils.js";
    import moment from "moment";

    /**
     * Composant affichant les données communes d'un exercice
     * @displayName Commun - CommonExercice
     */
    export default {
        name: "CommonExercice",
        components: {
            DateField,
            VFormattedNumberTextField,
        },
        props: {
            /**
             * La date de début de l'exercice
             */
            date_debut: {
                type: [Date, String],
                default: null
            },
            /**
             * La date de fin de l'exercice
             */
            date_fin: {
                type: [Date, String],
                default: null
            },
            /**
             * La date de l'ag de l'exercice
             */
            date_ag_annuelle: {
                type: [Date, String],
                default: null
            },
            /**
             * Le libelle de l'exercice
             */
            libelle: {
                type: String,
                default: null
            },
            /**
             * Détermine si l'exercice a été validé
             */
            exerciceValide: {
                type: Boolean,
            },
            /**
             * Le montant de la participation brute de l'exercice
             */
            montant_participation_brute: {
                type: Number
            },
            /**
             * Les bénéfices de l'exercice
             */
            benefices: {
                type: Number
            },

            /**
             * Afficher le pourcentage d'ENG dédié à la participation
             *  ENG (le pourcentage d'excédents nets)
             */
            showExcedentsNetsPb: {
                type: Boolean,
                required: false,
                default: true

            },
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            /**
             * Renforce le isNil en vérifiant également si le champ n'est pas la string vide
             * @param field le champ à vérifier
             * @returns {boolean}
             */
            isFilled(field) {
                return !_.isNil(field) && field !== ""
            },
            /**
             * Choisi un label en fonction de si le champ est rempli ou non
             * @param dependantField Le champ rempli ou non
             * @param text Le texte à retourner si le champ est rempli
             * @param nullText Le texte à retourner sinon
             * @returns {string}
             */
            computeLabel(dependantField, text, nullText) {
                if (this.isFilled(dependantField)) {
                    return text;
                } else {
                    return nullText;
                }
            },
            /**
             * Si la date de fin n'est pas définie, met la date de fin à la date de début plus un an moins un jour
             * @param value La date de début
             */
            onDateDebutChange(value) {
                if (this.isFilled(value) && !this.isFilled(this.localDateFin)) {
                    const newDateFin = moment(value).add(1, "year").subtract(1, "days");
                    this.localDateFin = newDateFin.format("YYYY-MM-DD");
                    this.computeLibelle(value, newDateFin);
                }
            },
            /**
             * Si la date de l'ag n'est pas définie,
             * ou si elle est définie à la même date que la date de fin ou à une date antérieure,
             * met la date de l'ag à la date de fin
             * @param value La date de fin
             */
            onDateFinChange(value) {
                if (this.isFilled(value) && (!this.isFilled(this.localDateAgAnnuelle) || this.localDateAgAnnuelle <= value)) {
                    this.localDateAgAnnuelle = moment(value).add(1, "days").format("YYYY-MM-DD");
                }
            },
            computeLibelle(dateDebut, dateFin) {
                if (this.isFilled(dateDebut) && this.isFilled(dateFin)) {
                    const anneeDebut = moment(dateDebut).year();
                    const anneeFin = moment(dateFin).year();
                    if (anneeDebut == anneeFin) {
                        this.localLibelle = "Exercice " + anneeDebut;
                    } else {
                        this.localLibelle = "Exercice " + anneeDebut + " - " + anneeFin;
                    }
                }
            }
        },
        computed: {
            localDateDebut: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_debut);
                },
                set: function (newvalue) {
                    this.onDateDebutChange(newvalue);
                    this.$emit("update-date-debut", newvalue);
                    this.computeLibelle(newvalue, this.localDateFin);
                }
            },
            localDateFin: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_fin);
                },
                set: function (newvalue) {
                    this.onDateFinChange(newvalue);
                    this.$emit("update-date-fin", newvalue);
                    this.computeLibelle(this.localDateDebut, newvalue);
                }
            },
            localDateAgAnnuelle: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_ag_annuelle);
                },
                set: function (newvalue) {
                    this.$emit("update-date-ag-annuelle", newvalue);
                }
            },
            localLibelle: {
                get: function () {
                    return this.getReturnValueIfNull(this.libelle);
                },
                set: function (newvalue) {
                    this.$emit("update-libelle", newvalue);
                }
            },
            localMontantParticipationBrute: {
                get: function () {
                    return this.getReturnValueIfNull(this.montant_participation_brute);
                },
                set: function (newvalue) {
                    this.$emit("update-montant-participation-brute", newvalue);
                }
            },
            localBenefices: {
                get: function () {
                    return this.getReturnValueIfNull(this.benefices);
                },
                set: function (newvalue) {
                    this.$emit("update-benefices", newvalue);
                }
            },
            taux_excedents_nets_pour_pb() {
                if (this.localBenefices == 0 || this.localBenefices == "") {
                    return "";
                }
                let res = Utils.formatNumber(this.localMontantParticipationBrute / this.localBenefices * 100);
                if (isNaN(res)) {
                    return "";
                }
                return res;
            },
        }
    }
</script>