// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import VIfu from "@/components/fiscalite/VIfu";
import Utils from "@/utils.js";

/**
 * Composant affichant les données IFU participation
 * @display_name Participation - IFU
 */
export default {
    mounted() {
        // Cannot mutate props directly
        this.$emit("update-selectable-exercice", false);
        this.$emit("update-selectable-year", true);
        this.$emit("update-selectable-month", false);
    },
    extends: VIfu,
    watch: {
        year: function () {
            this.loadDataSync();
        }
    },
    methods: {
        /**
         * Détermine les intitulés de colonne
         */
        computeHeaders(){
            return [
                {
                    text: "Bénéficiaire",
                    // ajout d'une balise htlmtext pour afficher le header sur plusieurs ligne. Pas terrible, mais à priori le componsant v-data-table ne le gère pas nativement
                    htmltext: "Bénéficiaire",
                    value: "name",
                    sortable: true,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Numéro de tiers",
                    htmltext: "Numéro de tiers",
                    value: "numero_tiers",
                    sortable: true,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Adresse",
                    htmltext: "Adresse",
                    value: "formatedAddress",
                    sortable: false,
                    align: "center",
                    width: "20%"
                },
                {
                    text: "Date de naissance",
                    htmltext: "Date de naissance",
                    value: "identifiant",
                    sortable: false,
                    align: "center",
                    width: "10%"
                },
                {
                    text: "Commune de naissance",
                    htmltext: "Commune<br/>de naissance",
                    value: "lieu_naissance",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Département de naissance",
                    htmltext: "Département<br/>de naissance",
                    value: "dep_naissance",
                    sortable: false,
                    align: "right",
                    width: "10%"
                },
                {
                    text: "Produits de placement à revenu fixe (2TR)",
                    htmltext: "Produits de placement<br/>à revenu fixe (2TR)",
                    value: "interets_percus",
                    sortable: false,
                    align: "right",
                    width: "15%"
                },
                {
                    text: "Crédit d'impôt prélèvement (2 CK)",
                    htmltext: "Crédit d'impôt<br/>prélèvement (2 CK)",
                    value: "credit_impot",
                    sortable: false,
                    align: "right",
                    width: "15%"
                },
            ];
        },
        /**
         * Charge les données dans les tableaux
         */
        loadData() {
            this.loading = true;

            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;

                internalApi.fiscalite_participation.ifu2561Infos(this.year, {
                    page: page,
                    rows_per_page: itemsPerPage,
                    sort_key: sortBy[0],
                    descending: sortDesc[0],
                }).then(result => {
                    let items = result.detail;
                    const total = result.detail_rows_number;
                    const dateFin = result.date_fin;
                    const tauxPrelevementForfaitaire = result.taux_prelevement_forfaitaire;
                    resolve({
                        'items': items,
                        'total': total,
                        'dateFin': dateFin,
                        'tauxPrelevementForfaitaire': tauxPrelevementForfaitaire,
                    });
                }).catch(err => {
                    console.error("Erreur lors de la récupération des ifu : ", err);
                    let items = [];
                    const total = 0;
                    reject({
                        'items': items,
                        'total': total,
                        'dateFin': undefined,
                        'tauxPrelevementForfaitaire': undefined,
                    });
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        /**
         * Télécharge le cerfa 2561
         */
        downloadIfu2561: function () {
            if(!this.checkCanDownloadIfu()){
                return;
            }
            this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
            internalApi.fiscalite_participation.ifu2561PDF(this.year, this.selectedCerfa.name)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "IFU_2561.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Télécharge le cerfa 2561TER
         */
        downloadIfu2561Ter: function () {
            if(!this.checkCanDownloadIfu()){
                return;
            }
            this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
            internalApi.fiscalite_participation.ifu2561PDF(this.year, this.selectedCerfa.ter)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "IFU_2561_ter.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
        },
    },
};
