import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default pbCompanyApi;

function pbCompanyApi() {
  const specificApi = {
    getCurrentPbCompany: getCurrentPbCompany(),
    getStatutsPbCompanyEnumByName: getStatutsPbCompanyEnumByName(),
    createStatut: createStatut(),
    getStatutByDate: getStatutByDate(),
    getStatutById: getStatutById(),
    deleteDocumentAccord: deleteDocumentAccord(),
    createPbAccordDocument: createPbAccordDocument(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.pbCompanies.baseUrl));
}

function getCurrentPbCompany() {
  function getCurrentStatutPbCompany(queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.statutsPbCompany();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getCurrentStatutPbCompany;
}

function getStatutsPbCompanyEnumByName() {
  function getStatutsPbCompanyEnumByName(queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.statutsPbCompanyEnumByName();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutsPbCompanyEnumByName;
}

function getStatutByDate() {
  function getStatutByDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.statutsPbCompanyByDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutByDate;
}

function getStatutById() {
  function getStatutById(pb_company_id, queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.statutsPbCompanyById(pb_company_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutById;
}

function createStatut() {
  function createStatut(body, queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.statutsPbCompany();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createStatut;
}

function deleteDocumentAccord() {
  function deleteDocumentAccord(id, body, queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.deleteDocumentAccord(id);
    let deleteForPath = apiConstructor.delete(path);
    return deleteForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deleteDocumentAccord;
}

function createPbAccordDocument() {
  function createPbAccordDocument(body, queryParams, headers) {
    let path = internalApiRoutes.pbCompanies.createPbAccordDocument();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createPbAccordDocument;
}