import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils";

export default capChartsApi;

function capChartsApi() {
  return {
    allAssocieData: getAllAssocieData(),
    associeData: getAssocieData(),
    allCompanyDataCapital: getAllCompanyDataCapital(),
    allDroitsDeVoteData: getAllDroitsDeVoteData(),
  };
}

function getAllAssocieData() {
  function allAssocieData(associeId, date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    queryParams.associe_id = associeId;
    let path = internalApiRoutes.capCharts.allAssocieCharts();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return allAssocieData;
}

function getAssocieData() {
  function associeData(date, queryParams, headers) {
    let path = internalApiRoutes.capCharts.associeCharts();
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return associeData;
}

function getAllCompanyDataCapital() {
  function allCompanyDataCapital(date, queryParams, headers) {
    let path = internalApiRoutes.capCharts.allCompanyChartsCapital();
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return allCompanyDataCapital;
}

function getAllDroitsDeVoteData() {
  function allDroitsDeVoteData(date, queryParams, headers) {
    let path = internalApiRoutes.capCharts.allDroitsDeVoteData();
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return allDroitsDeVoteData;
}
