import defaultApi, {ACCEPTED_METHODS} from "./defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";
import authService from "@/services/authService";

export default userApi;

const TENANT = "tenant";

function userApi() {
  let specificApi = {
    resetPasswordResponsable: resetPasswordResponsable(),
    changePassword: changePassword(),
    forgotPassword: forgotPassword(),
    createMassAccess: createMassAccess()
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.users.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById,
    ACCEPTED_METHODS.deleteById,
  ]));


  function resetPasswordResponsable() {
    function resetPwd(userId, body, queryParams, headers) {
      let path = internalApiRoutes.users.passwordModificationResponsable(userId);
      let postForPath = apiConstructor.put(path);
      return postForPath(body, queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
    }

    return resetPwd;
  }

  function changePassword() {
    function changePassword(body, queryParams, headers) {
      let path = internalApiRoutes.users.changePassword();
      let postForPath = apiConstructor.put(path);
      return postForPath(body, queryParams, headers).then(result => {
        let message = Utils.EMPTY_STRING
        if (authService.getDoitChangerMotDePasse()){
          message = "Vos droits ont été actualisés, veuillez vous reconnecter."
        }
        TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, message);
        return Promise.resolve(result);
      });
    }

    return changePassword;
  }

  function forgotPassword() {
    function forgotPwd(username, tenant, queryParams, headers) {
      sessionStorage.setItem(TENANT, tenant);
      let path = internalApiRoutes.users.forgotPassword();
      let postForPath = apiConstructor.post(path);
      const useToken = false;
      let body = {"username": username};
      return postForPath(body, queryParams, headers, useToken).then(result => {
        TOAST.success(TOAST_MESSAGE.success.asynchrone.resetPassword, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
    }
    return forgotPwd;
  }

  function createMassAccess() {
    function createMassUserAccess(body, queryParams, headers) {
      let path = internalApiRoutes.users.createMassAccess();
      let postForPath = apiConstructor.post(path);
      return postForPath(body, queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
    }
    return createMassUserAccess;
  }
}
