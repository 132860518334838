<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-data-table :headers="headers"
                          :items="colleges"
                          :footer-props="footerProps"
                          :height="datatableSize"
                          :loading="loading"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc">
                <template v-slot:item="{item}">
                    <tr>
                        <td class="text-lg-left">
                            {{item.code}}
                        </td>
                        <td class="text-lg-left">
                            {{item.name}}
                        </td>
                        <td class="text-lg-center">
                            {{item.description | limitStr(60)}}
                        </td>
                        <td class="text-lg-right">
                            {{item.pourcentage_droit_vote}}
                        </td>
                        <td class="text-lg-center">
                            <v-scic-college-edition :oldScicCollege="item"
                                            @edit-scic-college="(oldCollege, newCollege) => editScicCollege(oldCollege, newCollege)"
                            />
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
        <template v-slot:footer>
            <v-row dense justify="space-between">
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-scic-college-creation @add-scic-college="addScicCollege($event)"/>
                </v-col>
                <v-col md="6" lg="6" xl="4">
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancel()"
                                                      @emit-valid-save="submit()"/>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>
<script>
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import VScicCollegeCreation from "@/components/scic/VScicCollegeCreation.js";
    import VScicCollegeEdition from "@/components/scic/VScicCollegeEdition.js";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation";
    import Vue from 'vue';

    export default {
        name: "VScicCollegesList",
        components: {
            loadingScreen,
            BaseLayout,
            VSaveButonWithValidation,
            VScicCollegeCreation,
            VScicCollegeEdition,
        },
        props: {
            height: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                currentTab: undefined,
                headers: [
                    {text: "Code", value: "code", align: "start"},
                    {text: "Nom", value: "name", align: "start"},
                    {text: "Description", value: "description", align:"center"},
                    {text: "Droit de vote", value: "pourcentage_droit_vote", align: "end"},
                    {text: "Actions", value: "actions", align: "center"},
                ],
                colleges: [],
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                loading: true,
                sortBy: 'code',
                sortDesc: false,
            }
        },
        mounted() {
            this.fetchColleges();
        },
        methods: {
            fetchColleges() {
                this.loading = true;

                internalApi.scicCollege.getAll()
                    .then(result => {
                        this.colleges = result;
                    })
                    .catch(err => {
                        console.error(err);
                        this.colleges = [];
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addScicCollege(new_college) {
                this.colleges.push(new_college);
            },
            editScicCollege(old_college, new_college) {
                new_college.has_been_edited = true;
                const old_college_index = this.colleges.indexOf(old_college);
                if (old_college_index >= 0) {
                    Vue.set(this.colleges, old_college_index, new_college);
                } else {
                    console.error("Impossible de trouver le collège '" + old_college.code + "' dans la listes des collèges locale.")
                }
            },
            cancel() {
                this.fetchColleges();
            },
            _cleanCollegesForSubmit(colleges) {
                colleges.forEach(college => {
                    delete college.has_been_edited;
                    if (! college.hasOwnProperty("description")) {
                        college.description = null;
                    }
                });
            },
            submit() {

                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);

                // Get all newly created colleges
                const new_colleges = this.colleges.filter(college => {
                    return ! college.hasOwnProperty('id');
                });
                // Get all newly edited colleges
                const edited_colleges = this.colleges.filter(college => {
                    return college.hasOwnProperty('has_been_edited') && college.hasOwnProperty('id');
                });

                this._cleanCollegesForSubmit(new_colleges);
                this._cleanCollegesForSubmit(edited_colleges);

                const promise_new = internalApi.scicCollege.postMassEditing(new_colleges)
                    .catch(error => {
                        console.error(error);
                    });
                const promise_edit = internalApi.scicCollege.putMassEditing(edited_colleges)
                    .catch(error => {
                        console.error(error);
                    });

                Promise.all([promise_new, promise_edit]).finally(() => {
                    this.fetchColleges();
                    this.$refs.loadingComponent.resetLoading();
                });

            },
        },
    }
</script>