<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêter modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-if="!isModif" v-on="on" color="primary" dark block>{{openButtonMessage}}</v-btn>
            <v-icon-button-with-tool-tip v-else
                                         :detail="tooltipDetailMessage"
                                         :icon="iconValue"
                                         :activator="on"
                                         btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         block
            />
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" ref="formScicSousCategorie">
                 <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                 <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Code de la sous-catégorie" vid="code" rules="required|max:10" v-slot="{ errors }">
                                    <v-text-field v-model.trim="sousCategorie.code"
                                                label="Code de la sous-catégorie"
                                                :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Libellé de la sous-catégorie" vid="name" rules="required|max:100" v-slot="{ errors }">
                                    <v-text-field v-model.trim="sousCategorie.name"
                                                label="Libellé de la sous-catégorie"
                                                :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Catégorie mère" vid="pourcentage_droit_vote" rules="required" v-slot="{ errors }">
                                    <v-scic-param-categorie-list v-model="sousCategorie.param_categorie"
                                                                 @update-scic-categorie-associe="sousCategorie.param_categorie = $event"
                                                                 :scicCategorieAssocie="sousCategorie.param_categorie"
                                                                 :errors="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3" no-gutters>
                            <v-col>
                                <v-textarea v-model="sousCategorie.description"
                                            label="Description"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="mx-0">
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>{{labelConfirmation}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VScicParamCategorieList from "@/components/scic/VScicParamCategorieList.vue";
    import constantes from "@/utils/constantes";

    /**
     * Composant Affichant les données communes de l'exercice
     * @displayName Commun - Exercice
     */
    export default {
        name: "v-scic-sous-categorie",
        components: {
            VIconButtonWithToolTip,
            VScicParamCategorieList,
        },
        data() {
            return {
                sousCategorie: {
                    code: undefined,
                    name: undefined,
                    param_categorie: undefined,
                    description: undefined,
                },
                dialog: false,
                labelAnnulation: "Non, abandonner",
                labelConfirmation: "Oui, confirmer",
            };
        },
        computed: {
            isModif() {
                return undefined;
            },
            title() {
                return undefined;
            },
            openButtonMessage() {
                return undefined;
            },
            tooltipDetailMessage() {
                return undefined;
            },
            iconValue() {
                return undefined;
            },
        },
        methods: {
            submit() {
            },
            closePopUp() {
                this.dialog = false;
                this.sousCategorie.code = undefined;
                this.sousCategorie.name = undefined;
                this.sousCategorie.param_categorie = undefined;
                this.sousCategorie.description = undefined;
                this.$refs.formScicSousCategorie.reset();
            },
        }
    };
</script>
