import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";

export default operationsApi;

function operationsApi() {
  const specificApi = {
    getOperationsBySupports: getOperationsBySupports(),
    getOperationsBySupportsSimplifie: getOperationsBySupportsSimplifie(),
    getOperationsBySupportsSimplifieForSupport: getOperationsBySupportsSimplifieForSupport(),
    getOperationsBySupportsSimplifieIndividuel: getOperationsBySupportsSimplifieIndividuel(),
    exportDetailOperations: exportDetailOperations(),
    getDisponibilitesBeneficiaires: getDisponibilitesBeneficiaires(),
    exportDisponibilitesBeneficiaires: exportDisponibilitesBeneficiaires(),
    getStateEtatsDeLaParticipation: getStateEtatsDeLaParticipation(),
    generateEtatsDeLaParticipation: generateEtatsDeLaParticipation(),
    getEtatsDeLaParticipation: getEtatsDeLaParticipation(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.operations.baseUrl, [
    ACCEPTED_METHODS.deleteById,
  ]));
}

function getOperationsBySupports() {
  function getOperationsBySupports(beneficiaireId, dateOperation, queryParams, headers) {
    let path = internalApiRoutes.operations.getOperationsBySupports();
    queryParams = queryParams || {};
    queryParams.beneficiaire_id = beneficiaireId;
    queryParams.date_operation = dateOperation;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getOperationsBySupports;
}

function getOperationsBySupportsSimplifie() {
  function getOperationsBySupportsSimplifie(beneficiaireId, dateOperation, tauxRemu, aTerme, queryParams, headers) {
    let path = internalApiRoutes.operations.getOperationsBySupportsSimplifie();
    queryParams = queryParams || {};
    queryParams.beneficiaire_id = beneficiaireId;
    queryParams.date_operation = dateOperation;
    queryParams.taux_de_remuneration_interets_courus = tauxRemu;
    queryParams.a_terme = aTerme;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getOperationsBySupportsSimplifie;
}

function getOperationsBySupportsSimplifieForSupport() {
  function getOperationsBySupportsSimplifieForSupport(beneficiaireId, supportId, dateOperation, tauxRemu, aTerme, queryParams, headers) {
    let path = internalApiRoutes.operations.getOperationsBySupportsSimplifieForSupport();
    queryParams = queryParams || {};
    queryParams.beneficiaire_id = beneficiaireId;
    queryParams.support_id = supportId;
    queryParams.date_operation = dateOperation;
    queryParams.taux_de_remuneration_interets_courus = tauxRemu;
    queryParams.a_terme = aTerme;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getOperationsBySupportsSimplifieForSupport;
}

function getOperationsBySupportsSimplifieIndividuel() {
  function getOperationsBySupportsSimplifieIndividuel(dateReference, queryParams, headers) {
    const dateReferenceString = Utils.displayDateToIsoFormat(dateReference);
    let path = internalApiRoutes.operations.getOperationsBySupportsSimplifieIndividuel(dateReferenceString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getOperationsBySupportsSimplifieIndividuel;
}

function exportDetailOperations() {
  function exportDetailOperations(dateOperation, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date_operation = dateOperation;
    let path = internalApiRoutes.operations.exportDetailOperations();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return exportDetailOperations;
}

function getDisponibilitesBeneficiaires(){
  function getDisponibilitesBeneficiaires(dateReference, page, rows_per_page, sort_key, descending, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(dateReference);
    let path = internalApiRoutes.operations.getDisponibilitesBeneficiaires(dateString);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rows_per_page;
    queryParams.sort_key = sort_key;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getDisponibilitesBeneficiaires;
}

function exportDisponibilitesBeneficiaires(){
  function exportDisponibilitesBeneficiaires(dateReference, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(dateReference);
    let path = internalApiRoutes.operations.exportDisponibilitesBeneficiaires(dateString);
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return exportDisponibilitesBeneficiaires;
}

// Asynchrone etats de la participation
function getStateEtatsDeLaParticipation(){
  function getStateEtatsDeLaParticipation(queryParams, headers) {
    let path = internalApiRoutes.operations.getStateEtatsDeLaParticipation();
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getStateEtatsDeLaParticipation;
}

function generateEtatsDeLaParticipation() {
  function generateEtatsDeLaParticipation(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.operations.generateEtatsDeLaParticipation(dateString);
    let postForPath = apiConstructor.post(path);
    return postForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generateEtatsDeLaParticipation;
}

function getEtatsDeLaParticipation() {
  function getEtatsDeLaParticipation(queryParams, headers) {
    let path = internalApiRoutes.operations.getEtatsDeLaParticipation();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getEtatsDeLaParticipation;
}
// ---
