// <!-- VUETIFY2 - OK -->
import VDatatableMouvementsSupportsSimplifie from "./VDatatableMouvementsSupportsSimplifie";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";

export default {
  extends: VDatatableMouvementsSupportsSimplifie,
  methods: {
    updateDataTable() {
        this.getOperationsList();
    },
    getOperationsList() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
      return internalApi.operations.getOperationsBySupportsSimplifieIndividuel(this.dateOperation)
      .then(result => {
          this.operationsBySupports = result.operations_by_support;
          this.montantTotal = result.montant_total;
          this.computeTotalOnDateOperation();
      })
      .catch(err => {
          console.log(err);
      })
      .finally(() => {
          this.$refs.loadingComponent.resetLoading();
      });
    },
  },
};