<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px" content-class="overflow-visible">
        <template v-slot:activator="{ on }">
            <v-btn v-if="!isModif" v-on="on" color="primary" dark block>
                {{openButtonMessage}}
            </v-btn>
            <v-icon-button-with-tool-tip v-else
                                         :detail="tooltipDetailMessage"
                                         :icon="iconValue"
                                         :activator="on"
                                         btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         block
            />
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" ref="formTauxRemu" v-if="dialog === true">
                <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider vid="selectedExercice" name="Exercice"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-exercices-list v-model="tauxRemu.pb_exercice_id"
                                                      :errors="errors"
                                                      :propExercicesList="exercicesList"
                                                      class="ml-5">
                                    </v-exercices-list>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-taux-remu-field-with-tmop :tauxRemu="tauxRemu.taux_remu"
                                                             :dateReference="dateReferenceTauxRemu"
                                                             @fetch-new-taux-remu="tauxRemu.taux_remu = $event"
                                                             @update-taux-remu="tauxRemu.taux_remu = $event"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider name="Date de comptabilisation des intérêts"
                                                    vid="date_versement" rules="required" v-slot="{ errors }">
                                    <date-field v-model="tauxRemu.date_versement"
                                                label="Date de comptabilisation des intérêts"
                                                :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="mx-0">
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4" class="mr-2">
                            <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>{{labelConfirmation}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    import VExercicesList from "@/components/VExercicesList";
    import DateField from "@/components/DateField";
    import VTauxRemuFieldWithTmop from "@/components/tauxRemunerationSupport/VTauxRemuFieldWithTmop"
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant une fenêtre modale contenant les informations concernant
     * un taux de rémunération à positionner sur un support
     * @displayName Participation - VTauxRemuForSupport
     */
    export default {
        name: "VTauxRemuForSupport",
        components: {
            DateField,
            VIconButtonWithToolTip,
            VExercicesList,
            VTauxRemuFieldWithTmop,
        },
        data() {
            return {
                tauxRemu: {
                    pb_exercice_id: undefined,
                    taux_remu: undefined,
                    date_versement: undefined,
                },
                dateReferenceTauxRemu: undefined,
                dialog: false,
                labelAnnulation: "NON, ABANDONNER",
                labelConfirmation: "OUI, CONFIRMER",
                exercicesList: undefined,
            }
        },
        props: {
            pbSupportId: {
                type: Number,
                default: undefined,
            },
        },
        watch: {
            "tauxRemu.pb_exercice_id": {
                handler(new_val) {
                    if (_.isNil(new_val)) {
                        return;
                    }
                    internalApi.pbExercice.getById(new_val).then(result =>{
                        this.dateReferenceTauxRemu = result.date_fin;
                    });
                },
            },
        },
        computed: {
            isModif() {
                return undefined;
            },
            title() {
                return undefined;
            },
            openButtonMessage() {
                return undefined;
            },
            tooltipDetailMessage() {
                return undefined;
            },
            iconValue() {
                return undefined;
            },
        },
        methods: {
            submit() {
            },
            closePopUp() {
                this.dialog = false;
                this.tauxRemu.pb_exercice_id = undefined;
                this.tauxRemu.taux_remu = undefined;
                this.tauxRemu.date_versement = undefined;
                this.$refs.formTauxRemu.reset();
            },
            pbExerciceUpdated(newSelectedExercice) {
                this.tauxRemu.pb_exercice_id = newSelectedExercice;
            },
            fetchExercicesList() {
                if(!_.isNil(this.pbSupportId)) {
                    internalApi.pbExercice.getExercicesDisponiblesTauxRemu(this.pbSupportId).then(result => {
                        this.exercicesList = result;
                    });
                }
            },
        }
    }
</script>
