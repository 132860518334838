import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default deblocageAnticipeApi;

function deblocageAnticipeApi() {
  const specificApi = {
    getMontantDeblocageNetAnticipe: getMontantDeblocageNetAnticipe(),
  }
  return Object.assign(specificApi, defaultApi(internalApiRoutes.deblocagesAnticipes.baseUrl));
}


function getMontantDeblocageNetAnticipe(){
  function getMontantDeblocageNetAnticipe(beneficiaire_id, support_id, montant, date_operation, taux_de_remuneration_interets_courus, motif, front_provided_montant_souscription_part, queryParams, headers){
    let path = internalApiRoutes.deblocagesAnticipes.getMontantDeblocageNetAnticipe();
    queryParams = queryParams || {};
    queryParams.beneficiaire_id = beneficiaire_id;
    queryParams.support_id = support_id;
    queryParams.montant = montant;
    queryParams.date_operation = date_operation;
    queryParams.taux_de_remuneration_interets_courus = taux_de_remuneration_interets_courus;
    queryParams.motif = motif;
    queryParams.front_provided_montant_souscription_part = front_provided_montant_souscription_part;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getMontantDeblocageNetAnticipe;
}
