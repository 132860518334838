import Index from "@/views/Index";
import InteDettes from "@/views/interessement/reglements/InteDettes.vue";
import DetailDette from "@/views/common/reglements/DetailDette.vue";
import ReglementDette from "@/views/common/reglements/ReglementDette.vue";
import rolesList from "@/router/rolesList";

export default {
  path: "inte-dettes/",
  component: Index,
  children: [
    {
      path: "/",
      name: "inte-dettes",
      component: InteDettes,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/",
      name: "inte-detail-dette",
      component: DetailDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "interessement"
      }
    },
    {
      path: ":id/reglement-dette/",
      name: "inte-reglement-dette",
      component: ReglementDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "interessement"
      }
    }
  ]
};
