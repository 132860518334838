<template>
    <v-container>
        <v-row>
            <v-col>
                <loading-screen ref="loadingComponent"/>
                <v-data-table :search="search"
                              :footer-props="footerProps"
                              :headers="headers"
                              :items="salairePresences"
                              :loading="loadingSalairePresences"
                              :server-items-length="total_items"
                              :height="datatableSize"
                              fixed-header
                              class="elevation-1"
                              no-data-text="Aucune donnée."
                              ref="liste_salaires_presences"
                              :options.sync="options">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <span>
                                    {{item.name}}
                                </span>
                                <span class="ml-5" v-if="showPlafond && item.montant_salaire > item.plafond_proratise">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>Le salaire de ce(tte) salarié(e) dépasse son plafond personnalisé positionné sur cet exercice ({{ item.plafond_proratise | roundEuroFilter }}), le montant au dessus de ce plafond ne sera pas pris en compte lors de la répartition de la participation.</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{item.numero_tiers}}
                                </span>
                            </td>
                            <td v-if="repartitionSalaireActive">
                                <ValidationProvider :name="'Salaire de ' + item.name"
                                                    rules="currency|min_value:0"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field slot="input"
                                                                   v-model="item.montant_salaire"
                                                                   prefixValue="€"
                                                                   labelValue="Salaire"
                                                                   :reverseValue="true"
                                                                   :isSingleLine="true"
                                                                   :isAutoFocus="false"
                                                                   :min="0"
                                                                   :errors="errors"
                                                                   :hideDetails="true"
                                                                   specificClass="v-text-field-inline-datatable-editing"
                                                                   :disabledValue="disabled"
                                                                   @input="dataChanged = true"/>
                                </ValidationProvider>
                            </td>
                            <td v-if="repartitionPresenceActive">
                                <ValidationProvider :name="'Heures annuelles de ' + item.name"
                                                    rules="currency|min_value:0"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field slot="input"
                                                                   v-model="item.heures_annuelles"
                                                                   labelValue="Heures annuelles"
                                                                   :reverseValue="true"
                                                                   :isSingleLine="true"
                                                                   :isAutoFocus="false"
                                                                   :min="0"
                                                                   :errors="errors"
                                                                   :hideDetails="true"
                                                                   specificClass="v-text-field-inline-datatable-editing"
                                                                   :disabledValue="disabled"
                                                                   @input="dataChanged = true"/>
                                </ValidationProvider>
                            </td>
                            <td v-if="repartitionAncienneteActive">
                                <show-default-or-derogatory-value label="Coefficient d'ancienneté"
                                                                  :defaultValue="item.coefficient_anciennete"
                                                                  v-model="item.coefficient_anciennete_derogatoire"
                                                                  :explainTextDefaultValue="explainTextDefaultValue"
                                                                  :explainTextDerogatoryValue="explainTextDerogatoryValue"
                                                                  :cardSubtitleText="computedCardSubtitleText(item.name)"
                                                                  @update-value="derogatoryValue = $event"/>
                            </td>
                            <td>
                                <ValidationProvider :name="'Règle d\'éligibilité de ' + item.name"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-select v-model="item.regle_eligibilite"
                                              :items="choixReglesEligibilite"
                                              item-text="text"
                                              item-value="value"
                                              :hideDetails="true"
                                              :error-messages="errors"
                                              :disabled="disabled"
                                              @input="dataChanged = true"
                                    />
                                </ValidationProvider>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="repartitionSalaireActive">
            Total des salaires : {{ total_salaire | roundEuroFilter }}
        </v-row>
        <v-row v-if="repartitionPresenceActive">
            Total des temps de présence : {{ total_presence | roundNumberFilter}} heures
        </v-row>
        <v-row v-if="plafond_salaire > 0 && showPlafond">
            Plafond de salaire : {{ plafond_salaire | roundEuroFilter }} pour un salarié ayant travaillé sur tout l'exercice
        </v-row>
        <v-dialog v-model="confirmationDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col>
                                Vous avez saisi des données qui n'ont pas été enregistrées, êtes-vous sûr(e) de vouloir
                                continuer ?
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import ShowDefaultOrDerogatoryValue from "@/components/ShowDefaultOrDerogatoryValue"
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils";

    export default {
        name: "VDatatableSalairePresencesAbstract",
        abstract: true,
        components: {
            loadingScreen,
            VFormattedNumberTextField,
            ShowDefaultOrDerogatoryValue,
        },
        props: {
            exerciceId: {
                type: Number,
                required: true
            },
            datatableSize: {
                type: String,
                required: true
            },
            repartitionPresenceActive: {
                type: Boolean,
                required: true
            },
            repartitionSalaireActive: {
                type: Boolean,
                required: true
            },
            repartitionAncienneteActive: {
                type: Boolean,
                required: false,
                default: false
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                initCompleted: false,
                salairePresences: undefined,
                total_salaire: undefined,
                total_presence: undefined,
                plafond_salaire: 0,
                loadingSalairePresences: true,
                options: {
                    sortBy: ['name'],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                nextPagination: {},
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                total_items: -1,
                search: "",
                dataChanged: false,
                confirmationDialog: undefined,
                choixReglesEligibilite: [],
                explainTextDefaultValue: "Valeur calculée par EZISCOP à partir de la dernière période de travail.",
                explainTextDerogatoryValue: "Valeur dérogatoire personnalisée par le responsable.",
            }
        },
        computed: {
            showPlafond() {
                // Abstract method
                return undefined;
            },
            updateCoefficientAncienneteDerogatoire() {
                // Abstract method
                return undefined;
            },
            /**
             * Retourne une liste de string correspondant aux headers de la datatable en fonction de si
             * les répartitions des bénéfices selon le salaire et/ou selon le temps de présence sont
             * actives
             * @returns {[{}]}
             */
            headers() {
                let headers = [
                    {text: "Salarié", sortable: true, value: "name", align: "left", width: "15%"},
                    {text: "Numéro de tiers", sortable: false, value: "numero_tiers", align: "left", width: "15%"},
                ];
                if (this.repartitionSalaireActive) {
                    headers.push({
                        text: "Salaire de l'exercice",
                        sortable: false,
                        value: "montant_salaire",
                        align: "left",
                        width: "25%",
                    });
                }
                if (this.repartitionPresenceActive) {
                    headers.push({
                        text: "Temps de présence de l'exercice",
                        sortable: false,
                        value: "heures_annuelles",
                        align: "left",
                        width: "25%",
                    });
                }
                if (this.repartitionAncienneteActive) {
                    headers.push({
                        text: "Coefficient d'ancienneté",
                        sortable: false,
                        align: "left",
                        width: "25%",
                    });
                }
                headers.push(
                    {text: "Règle d'éligibilité", sortable: false, value: "regle_eligibilite", align: "left", width: "20%"},
                );
                return headers;
            },
        },
        watch: {
            options: {
                /**
                 *  Vérifie si les données ont été modifiées par l'utilisateur. Si c'est le cas, on revient à la
                 *  pagination initiale et on enregistre la nouvelle pagination. Sinon on récupère la ventilation
                 */
                handler(newV, oldV) {
                    if (!this.confirmationDialog && this.initCompleted) {
                        if (this.dataChanged) {
                            this.confirmationDialog = true;

                            // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                            // le changement
                            this.nextPagination.sortBy = newV.sortBy;
                            this.nextPagination.sortDesc = newV.sortDesc;
                            this.nextPagination.page = newV.page;
                            this.nextPagination.itemsPerPage = newV.itemsPerPage;

                            // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                            // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                            this.options.sortBy = oldV.sortBy;
                            this.options.sortDesc = oldV.sortDesc;
                            this.options.page = oldV.page;
                            this.options.itemsPerPage = oldV.itemsPerPage;
                        } else {
                            this.getAllSalairePresencesSync();
                        }
                    }
                },
                deep: true
            },
        },
        mounted() {
            let promises = []
            this.loadingSalairePresences = true;
            if (this.showPlafond) {
                promises.push(this.getMontantRenumerationSalairePlafond());
            }
            this.getChoixReglesEligibilite().then(
                res => {
                    promises.push(this.getAllSalairePresencesSync());
                }
            )
            Promise.all(promises).finally(
                () => {
                    this.loadingSalairePresences = false;
                    this.initCompleted = true;
                }
            )
        },
        methods: {
            /**
             * Ferme la boîte de dialogue de confirmation de changement de page
             */
            closePopUp() {
                this.confirmationDialog = false;
            },
            /**
             * Ferme la pop up et récupère la ventilation paginée à partir de la nouvelle pagination.
             * Met également le localDataChanged à false
             */
            confirmChangePagination() {
                this.options.sortBy = this.nextPagination.sortBy;
                this.options.sortDesc = this.nextPagination.sortDesc;
                this.options.page = this.nextPagination.page;
                this.options.itemsPerPage = this.nextPagination.itemsPerPage;

                this.closePopUp();
                this.getAllSalairePresencesSync();
                this.dataChanged = false;
            },
            /**
             * Récupère les périodes de travail par pagination
             */
            getAllSalairePresencesSync() {
                return this.getAllSalairePresences().then(data => {
                    this.salairePresences = data.items;
                    this.total_items = data.total;
                }).catch(rejected => {
                    this.salairePresences = rejected.items;
                    this.total_items = rejected.total;
                });
            },
            /**
             * Récupère les choix sélectionnables pour les règle l'éligibilité
             */
            getChoixReglesEligibilite() {
                return internalApi.commonSalairePresence.getLabels()
                .then(labels => {
                    // Labels règle éligibilité
                    this.choixReglesEligibilite = Utils.fromLabelToSelect(labels["regle_eligibilite"]);
                });
            },
            /**
             * Récupère le plafond de salaire positionné sur l'exercice
             */
            getMontantRenumerationSalairePlafond() {
                // Abstract method
            },
            /**
             * Récupère le total des salaire et des temps de présence pour les bénéficiaires
             * ayant au moins une période de travail sur l'exercice
             */
            getTotauxSalairePresences() {
                // Abstract method
            },
            /**
             * Récupère les périodes de travail des salariés valides entre la date de début
             * et la date de fin de l'exercice
             */
            getAllSalairePresences() {
                // Abstract method
            },
            /**
             * Enregistre les salaires et présences présent dans la page actuelle
             */
            saveSalairePresences() {
                // Abstract method
            },
            computedCardSubtitleText(name) {
                return `Choisir le type de valeur à utiliser pour ${name}`;
            },
        }
    }
</script>