import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";


export default annulationTotaleApi;

function annulationTotaleApi() {
  return {
    getByAssocieAndDate: getByAssocieAndDate(),
    postBulletinAnnulationTotale: postBulletinAnnulationTotale(),
    createAnnulationTotale: createAnnulationTotale(),
    deleteAnnulationTotale: deleteAnnulationTotale()
  };
}

function getByAssocieAndDate() {
  function getByAssocieAndDate(id, date, queryParams, headers) {
    let dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.annulationTotale.getByAssocieAndDate(id, dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getByAssocieAndDate;
}

function postBulletinAnnulationTotale() {
  function bulletin(body, queryParams, headers) {
    let path = internalApiRoutes.annulationTotale.brouillonBulletinAnnulationTotale;
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return bulletin;
}

function createAnnulationTotale() {
  function createAnnulationTotale(body, queryParams, headers) {
    let path = internalApiRoutes.annulationTotale.createAnnulationTotale;
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return createAnnulationTotale;
}

function deleteAnnulationTotale() {
  function deleteAnnulationTotale(associeId, date, queryParams, headers) {
    let dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.annulationTotale.deleteAnnulationTotale(associeId, dateString);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deleteAnnulationTotale;
}