<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }" ref="form">
        <base-layout headerSize="80px">
            <template slot="header">
                <h1>Accord de participation</h1>
            </template>
            <template slot="main">
                <loading-screen ref="loadingComponent"/>
                <form>
                    <v-container>
                        <versions-list :selectedVersion="pbCompany"
                                       :versionsList="pbCompaniesEnumByName"
                                       @update-selected-version="onSelectedVersionChanges($event)"
                                       labelUnivers="Version de l'accord de participation"
                                       ref="statuts"/>
                        <h3 class="my-2">Accord de participation</h3>
                        <v-row class="my-2" no-gutters>
                            <ValidationProvider vid="date_accord" name="date accord"
                                                rules="required" v-slot="{ errors }">
                                <DateField class="flex md4"
                                           label="Date de l'accord"
                                           name="date_accord"
                                           v-model="pbCompany.date_accord"
                                           :errors="errors"
                                           :disabled="isOldPbCompany"
                                />
                            </ValidationProvider>
                        </v-row>
                        <v-subheader>
                            <h2>Contrainte de répartition de la participation aux bénéfices</h2>
                            <v-col class="ml-3" md="1">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                                    </template>
                                    <span>Le calcul de l’ancienneté d’un exercice prend en compte deux exercices glissants</span>
                                </v-tooltip>
                            </v-col>
                        </v-subheader>
                        <v-row no-gutters>
                            <v-row no-gutters>
                                <v-col md="6" lg="6" xl="6">
                                    <v-checkbox v-model="hasContrainteAnciennete"
                                                label="Contrainte d'ancienneté"
                                                @change="switchHasContrainteAnciennete"
                                                :disabled="isOldPbCompany"
                                    />
                                </v-col>
                                <v-col md="4" lg="4" xl="4">
                                    <ValidationProvider vid="jours_anciennete" name="jours anciennete"
                                                        :rules="{ required: hasContrainteAnciennete, between: {'min': 0, 'max': 90} }" v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="pbCompany.elig_anciennete"
                                                                       labelValue="Jours d'ancienneté"
                                                                       :errors="errors"
                                                                       :disabledValue="!hasContrainteAnciennete || isOldPbCompany"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row no-gutters>
                            <v-col md="4" lg="4" xl="4">
                                <v-subheader>
                                    <h2>Seuil réglementaire</h2>
                                </v-subheader>
                                <ValidationProvider vid="plancher_participation"
                                                name="Participation aux bénéfices plancher"
                                                rules="required|between:25,50"
                                                v-slot="{ errors }">
                                    <v-formatted-number-text-field
                                        v-model="pbCompany.plancher_participation"
                                        prefixValue="%"
                                        labelValue="Participation aux bénéfices plancher"
                                        :reverseValue="true"
                                        :errors="errors"
                                        :disabledValue="isOldPbCompany"
                                    />
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                        <br/>
                        <br/>
                        <v-subheader>
                            <h2>Règles de répartition de la participation aux bénéfices</h2>
                        </v-subheader>
                        <br/>
                        <v-row no-gutters>
                            <h3 class="font-weight-medium">Total : {{ totalCles | roundPercentFilter }}</h3>
                        </v-row>
                        <br/>
                        <v-row no-gutters>
                            <v-expansion-panels multiple>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-row no-gutters>
                                            <h3>Répartition uniformisée</h3>
                                            <v-col class="ml-3" md="1">
                                                <v-tooltip top max-width="40%">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                                                    </template>
                                                    <span>Lorsque le prorata temporis n'est pas activé sur la clef de répartition uniformisée,
                                                    la participation est répartie de manière uniforme entre chaque bénéficiaire ayant travaillé au moins 1 jour sur l'exercice.</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row no-gutters>
                                            <v-col md="4" lg="4" xl="4">
                                                <ValidationProvider vid="clef_repart_uniforme" name="clef repart uniforme"
                                                                    rules="required|between:0,100" v-slot="{ errors }">
                                                    <v-formatted-number-text-field v-model="pbCompany.part_repartition_uniforme"
                                                                                   prefixValue="%"
                                                                                   :reverseValue="true"
                                                                                   labelValue="Clef de répartition"
                                                                                   :errors="errors"
                                                                                   :disabledValue="isOldPbCompany"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col md="1" lg="1" xl="1" align="right">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                                                    </template>
                                                    <span v-html="infoBulleUniforme"></span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col md="2" lg="2" xl="2">
                                                <v-checkbox v-model="pbCompany.valorisation_uniforme_selon_anciennete"
                                                            label="Valorisation selon ancienneté"
                                                            :disabled="!pbCompany.prorata_repartie_uniforme"
                                                />
                                            </v-col>
                                            <v-col md="2" lg="2" xl="2" offset-md="1" offset-lg="1" offset-xl="1">
                                                <v-checkbox v-model="pbCompany.prorata_repartie_uniforme"
                                                            label="Prorata temporis"
                                                            :disabled="isOldPbCompany"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <clef-repartition-valorisee :type="'salaire'"
                                                            :title="titleSalaire"
                                                            :infoBulleContent="infoBulleSalaire"
                                                            :pbCompanyId="pbCompany.id"
                                                            :isOldPbCompany="isOldPbCompany"
                                                            :partRepartieValue="pbCompany.part_repartition_selon_salaire"
                                                            :valorisationSelonAnciennete="pbCompany.valorisation_salaire_selon_anciennete"
                                                            @update-part-repartie-value="pbCompany.part_repartition_selon_salaire = $event"
                                                            @update-valorisation-selon-anciennete="pbCompany.valorisation_salaire_selon_anciennete = $event"
                                />
                                <clef-repartition-valorisee :type="'presence'"
                                                            :title="titlePresence"
                                                            :infoBulleContent="infoBullePresence"
                                                            :pbCompanyId="pbCompany.id"
                                                            :isOldPbCompany="isOldPbCompany"
                                                            :partRepartieValue="pbCompany.part_repartition_selon_presence"
                                                            :valorisationSelonAnciennete="pbCompany.valorisation_presence_selon_anciennete"
                                                            @update-part-repartie-value="pbCompany.part_repartition_selon_presence = $event"
                                                            @update-valorisation-selon-anciennete="pbCompany.valorisation_presence_selon_anciennete = $event"
                                />
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <h3>Répartition selon l'ancienneté</h3>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row no-gutters>
                                            <v-col md="4" lg="4" xl="4">
                                                <ValidationProvider vid="clef_repart_anciennete" name="clef repartition ancienneté"
                                                                    rules="required|between:0,100" v-slot="{ errors }">
                                                    <v-formatted-number-text-field v-model="pbCompany.part_repartition_selon_anciennete"
                                                                                   prefixValue="%"
                                                                                   :reverseValue="true"
                                                                                   labelValue="Clef de répartition"
                                                                                   data-vv-as="% clef repartition anciennete"
                                                                                   data-vv-name="clef_repart_anciennete"
                                                                                   :errors="errors"
                                                                                   :disabledValue="isOldPbCompany"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <v-subheader style="margin-top: 2em;">
                                <h2>Règles d'ancienneté</h2>
                            </v-subheader>

                            <repartition-pb-anciennete :pbCompanyId="pbCompany.id"
                                                       :disabled="isOldPbCompany"
                                                       ref="repartitionPbAnciennete"
                            />

                        </v-row>
                    </v-container>
                </form>
            </template>
            <template slot="footer">
                <v-row no-gutters>
                    <!-- Grand bouton -->
                    <v-col md="4" lg="4" xl="3">
                        <new-accord-pb
                            v-if="isResponsable()"
                            :dialog="dialog"
                            :versionsList="pbCompaniesEnumByName"
                            :nouvelleVersion="pbCompany"
                            :disabledValue="isOldPbCompany"
                            @on-open-dialog="onOpenDialog($event)"
                            @on-version-created="updatePbCompany()"
                        >
                        </new-accord-pb>
                    </v-col>
                    <v-spacer/>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="5">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="undo()"
                                                      @emit-valid-save="saveCompany()"
                                                      :disabledValue="isOldPbCompany"
                        />
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>

    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import VersionsList from "@/components/VersionsList";
    import NewAccordPb from "@/components/versions/NewAccordPb"
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue"
    import RepartitionPbAnciennete from "@/components/RepartitionPbAnciennete";
    import authService from "@/services/authService";
    import rolesList from "@/router/rolesList";
    import constantes from "@/utils/constantes";
    import DateField from "@/components/DateField";
    import ClefRepartitionValorisee from "@/components/ClefRepartitionValorisee";

    export default {
        name: "Accord",
        components: {
            ClefRepartitionValorisee,
            RepartitionPbAnciennete,
            BaseLayout,
            loadingScreen,
            VersionsList,
            NewAccordPb,
            VFormattedNumberTextField,
            VSaveButonWithValidation,
            DateField,
        },
        data() {
            return {
                dialog: undefined,
                pbCompany: {
                    id: 0,
                    nom_version: "",
                    is_interets_places: undefined,
                    doc_accord_id: undefined,
                    part_repartition_uniforme: 0,
                    part_repartition_selon_salaire: 0,
                    part_repartition_selon_presence: 0,
                    part_repartition_selon_anciennete: 0,
                    valorisation_salaire_selon_anciennete: false,
                    valorisation_presence_selon_anciennete: false,
                    plancher_participation: 25,
                },
                pbCompaniesEnumByName: [],
                hasContrainteAnciennete: false,
                isOldPbCompany: false,
                titlePresence: "Répartition selon le temps de présence",
                titleSalaire: "Répartition selon le salaire",
                infoBulleUniforme: `La valorisation uniformisée selon l’ancienneté
                                    consiste à multiplier la part d’un bénéficiaire sur l’exercice par
                                    le coefficient d’ancienneté des bénéficiaires au dernier jour
                                    de l’exercice.<br/>
                                    Ce coefficient est déterminé à partir des règles d’ancienneté`,
                infoBulleSalaire: `La valorisation du salaire selon l’ancienneté
                                    consiste à multiplier le salaire de l’exercice par
                                    le coefficient d’ancienneté des bénéficiaires au dernier jour
                                    de l’exercice.<br/>
                                    Ce coefficient est déterminé à partir des règles d’ancienneté.`,
                infoBullePresence: `La valorisation du temps de présence selon l’ancienneté
                                    consiste à multiplier le temps de présence de l’exercice par
                                    le coefficient d’ancienneté des bénéficiaires au dernier jour
                                    de l’exercice.<br/>
                                    Ce coefficient est déterminé à partir des règles d’ancienneté.`,
            };
        },
        watch: {
            pbCompany: function (val) {
                // The list of statuts is sorted by date
                if (!_.isEmpty(this.pbCompaniesEnumByName)) {
                    this.isOldPbCompany = val.id !== this.pbCompaniesEnumByName[0].id;
                    this.hasContrainteAnciennete = this.pbCompany.elig_anciennete!=null
                }
            },
            pbCompaniesEnumByName: function (val) {
                // The list of statuts is sorted by date
                if (!_.isNil(this.pbCompany)) {
                    this.isOldPbCompany = this.pbCompany.id !== val[0].id;
                }
            },
            'pbCompany.prorata_repartie_uniforme': function (val) {
                if (! val) {
                    // Si "prorat temporis" est décoché sur la clef de répartition uniformisée,
                    // décocher "valorisation selon ancienneté"
                    this.pbCompany.valorisation_uniforme_selon_anciennete = false;
                }
            },
        },
        computed: {
            totalCles() {
                return this.pbCompany.part_repartition_uniforme
                    + this.pbCompany.part_repartition_selon_salaire
                    + this.pbCompany.part_repartition_selon_presence
                    + this.pbCompany.part_repartition_selon_anciennete
            }
        },
        mounted() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            this.getStatutsPbCompanyEnumByName().catch(error => {
                // S'il y a eu une erreur, retirer le loading, sinon attendre que updateSelectedVersion() le fasse
                this.$refs.loadingComponent.resetLoading();
            });
        },
        methods: {
            getStatutsPbCompanyEnumByName() {
                return internalApi.pbCompany.getStatutsPbCompanyEnumByName()
                    .then(results => {
                        this.pbCompaniesEnumByName = results;

                        // Séléction du statut à afficher par défaut lors du chargement de la page
                        if (this.pbCompaniesEnumByName.length > 0) {
                            this.pbCompany.id = this.pbCompaniesEnumByName[0].id;
                            this.pbCompany.nom_version = this.pbCompaniesEnumByName[0].nom_version;
                        } else {
                            this.pbCompany = undefined;
                        }

                        if (! _.isNil(this.pbCompany)) {
                            this.updateSelectedVersion();
                        }
                    });
            },
            getPbCompanyById(pb_company_id) {
                return internalApi.pbCompany.getStatutById(pb_company_id)
                    .then(result => {
                        this.pbCompany = result;
                        this.hasContrainteAnciennete = this.pbCompany.elig_anciennete != null;
                    });
            },
            getCurrentPbCompany() {
                return internalApi.pbCompany.getCurrentPbCompany()
                    .then(result => {
                        this.pbCompany = result;
                    });
            },
            switchHasContrainteAnciennete() {
                if (!this.hasContrainteAnciennete) {
                    this.pbCompany.elig_anciennete = null;
                }
            },
            onSelectedVersionChanges(newSelectedStatut) {
                this.pbCompany = newSelectedStatut;
                this.updateSelectedVersion();

            },
            updateSelectedVersion() {

                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

                let promise_get_pb_company = this.getPbCompanyById(this.pbCompany.id);
                // Attendre que la pb_company soit chargée
                Promise.all([promise_get_pb_company]).then(res => {
                    if (! _.isNil(this.$refs.repartitionPbAnciennete)) {
                        return this.$refs.repartitionPbAnciennete.getAllRepartitionPbAnciennete(this.pbCompany.id);
                    }
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            onOpenDialog(newVal) {
                if (newVal) {
                    this.$refs.form.validate().then(validForm => {
                        this.dialog = validForm;
                    });
                } else {
                    this.dialog = false;
                }
            },
            updatePbCompany() {
                this.getStatutsPbCompanyEnumByName();
                this.dialog = false;
            },
            saveCompany() {
                // If checkbox for jours d'anciennete not checked, send null -> None server side
                if (!this.hasContrainteAnciennete){
                    this.pbCompany.elig_anciennete = null;
                }
                let dataToSend = this.getInfosCompany();
                const id = dataToSend.id;
                delete dataToSend.id;
                return internalApi.pbCompany
                    .putByIdWithToastConfirmation(id, dataToSend)
                    .then(() => {
                        this.updatePbCompany();
                    });
            },
            getInfosCompany: function () {
                return _.cloneDeep(this.pbCompany);
            },
            undo() {
                this.getPbCompanyById(this.pbCompany.id);
            },
            isResponsable: function () {
                return authService.getCurrentRoles().includes(rolesList.RESPONSABLE);
            },
        }
    }
</script>