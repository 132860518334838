<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <h3>{{title}}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row no-gutters>
                <v-col md="4" lg="4" xl="4">
                    <ValidationProvider :vid="'clef_repart'+type" name="Clef de répartition"
                                        rules="required|between:0,100" v-slot="{ errors }">
                        <v-formatted-number-text-field
                            v-model="localPartRepartieValue"
                            prefixValue="%"
                            :reverseValue="true"
                            labelValue="Clef de répartition"
                            :errors="errors"
                            :disabledValue="isOldPbCompany"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col md="1" lg="1" xl="1" align="right">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                        </template>
                        <span v-html="infoBulleContent">
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col md="4" lg="4" xl="4">
                    <v-checkbox v-model="localValorisationSelonAnciennete"
                                label="Valorisation selon l'ancienneté"
                                :disabled="isOldPbCompany"
                    />
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";

    /**
     * Composant affichant une clef de répartition pour être valorisée selon l'ancienneté
     * @displayName Participation - Clef de répartition valorisée
     */
    export default {
        name: "ClefRepartitionValorisee",
        components: {
            VFormattedNumberTextField
        },
        props: {
            /**
             * Indique le type de clef de répartition (utilisé pour différencier les composants v-formatted-text-field
             * lors de la validation)
             */
            type: {
                type: String,
                required: true
            },
            /**
             * Attribut de pbCompany indiquant la valeur de la clef de répartition
             */
            partRepartieValue: {
                type: Number,
                required: true
            },
            /**
             * Attribut de pbCompany indiquant si la part issue de la clef de répartition est valorisée selon
             * l'ancienneté de bénéficiaire
             */
            valorisationSelonAnciennete: {
                type: Boolean,
                required: true
            },
            /**
             * Titre du panel indiquant le nom de la clef de répartition
             */
            title: {
                type: String,
                required: true
            },
            /**
             * Informations sur l'effet de la valorisation selon l'ancienneté
             */
            infoBulleContent: {
                type: String,
                required: true
            },
            /**
             * L'id de la pbCompany
             */
            pbCompanyId: {
                type: Number,
                required: true
            },
            /**
             * Indique si la pbCompany est une ancienne version ou non
             */
            isOldPbCompany: {
                type: Boolean,
                required: false,
                default: false
            }
        },
         computed: {
            localPartRepartieValue: {
                get: function () {
                    return this.partRepartieValue;
                },
                set: function (newvalue) {
                    this.$emit("update-part-repartie-value", newvalue);
                }
            },
            localValorisationSelonAnciennete: {
                get: function () {
                    return this.valorisationSelonAnciennete;
                },
                set: function (newvalue) {
                    this.$emit("update-valorisation-selon-anciennete", newvalue);
                }
            },
         }
    }
</script>