// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Fiscalite from "@/views/common/fiscalite/Fiscalite";
import Utils from "@/utils";
import loadingScreen from "@/components/LoadingScreen.vue";

/**
 * Logique de l'écran capital pour la fiscalité
 * @displayName Capital - Fiscalité
 */
export default {
    components: {
        loadingScreen,
    },
    data() {
        return {
            univers: "CAPITAL",
            selectableExercice: true,
            selectableYear: false,
            selectableMonth: false,
            documentsList: [
                {
                    title: "2561/2561 ter",
                    component: "IFU_2561_capital"
                },
                {
                    title: " 2777",
                    component: "PFU_2777_capital"
                }
            ],
        };
    },
    extends: Fiscalite,
    methods: {
        downloadListeMinisterielle() {
            this.$refs.formFiscalite.validate().then(
                success => {
                    if(success){
                        this.$refs.loadingComponent.setLoading("Génération de la liste ministérielle en cours");
                        internalApi.fiscalite_capital.listeMinisterielle(this.selectedExercice)
                            .then(response => {
                                Utils.downloadFile(response, "liste_ministerielle.xlsx", Utils.excelMimetype);
                            })
                            .catch(err => {
                                console.error(err);
                            })
                            .finally( () => {
                                this.$refs.loadingComponent.resetLoading();
                            });
                    }
                }
            );
        }
    }
};
