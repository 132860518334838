var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"withHeader":false},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Tableau de support aux Imprimés Fiscaux Uniques")]),_c('v-col',{staticClass:"ml-3",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s("La version des Cerfa retenue par l'outil est celle valable à la date du règlement le plus récent de l'exercice")}})])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[(_vm.univers === 'CAPITAL')?_c('span',[_vm._v("Données concernant l'exercice clos le "+_vm._s(_vm._f("dateIsoToFrFilter")(_vm.dateFin)))]):_c('span',[_vm._v("Données concernant l'année "+_vm._s(_vm.year))]),_c('br'),_c('br'),_c('span',[_vm._v("Prélèvement forfaitaire : "+_vm._s(_vm._f("roundPercentFilter")(_vm.tauxPrelevementForfaitaire)))])]),_c('v-col',{attrs:{"md":"8","lg":"8","xl":"4"}},[_c('v-detail-societe',{attrs:{"dateVersionSociete":_vm.dateFin}})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{ref:"ifu_datatable",staticClass:"elevation-1",attrs:{"fixed-header":"","no-data-text":"Aucune donnée.","footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.detailIfu,"server-items-length":_vm.total_items,"loading":_vm.loading,"max-height":datatableSize,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{'grey lighten-1': item.exclu_documents_legaux}},[_c('td',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('span',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),(item.exclu_documents_legaux)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("Exclu(e) de l'édition des documents légaux")])]):_vm._e()],1)],1),_c('td',[_vm._v(_vm._s(item.numero_tiers))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.formatAddress(item))}}),_c('td',[_vm._v(_vm._s(item.identifiant))]),_c('td',[_vm._v(_vm._s(item.lieu_naissance))]),_c('td',{staticClass:"text-lg-right"},[_vm._v(_vm._s(item.dep_naissance))]),_c('td',{staticClass:"text-lg-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item.interets_percus,0)))]),_c('td',{staticClass:"text-lg-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilterNotDisplayZero")(item.credit_impot,0)))])])]}}],null,true)})],1)],1)]}}])},[_c('template',{slot:"footer"},[_c('v-row',{attrs:{"justify":"end","dense":""}},[(_vm.univers === 'CAPITAL')?_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-dialog-annee-reglement-ag',{attrs:{"openButtonMessage":"Générer le fichier de télétransmission","title":"Générer le fichier de télétransmission","isIcon":false,"anneeAg":_vm.anneeAg,"activator":on},on:{"update-data-teletransmission":function($event){return _vm.downloadTeletransmission($event)}}})]}}],null,false,2489917353)},[_c('span',[_vm._v(_vm._s(_vm.messageGenererFichierTeletransmisson))])])],1):_vm._e(),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.downloadIfu2561}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.pdf"))]),_vm._v(" Générer le CERFA 2561 ")],1)],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.downloadIfu2561Ter}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.pdf"))]),_vm._v(" Générer le CERFA 2561 ter ")],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }