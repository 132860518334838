// <!-- VUETIFY2 - OK -->
import VTmop from "./VTmop"
import Utils from "@/utils.js";


export default {
  extends: VTmop,
  props: {
    oldTmop: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  mounted() {
    this.onOldTmopChanges(this.oldTmop)
  },
  watch: {
    oldTmop(new_val, old_val) {
      this.onOldTmopChanges(new_val);
    }
  },
  computed: {
    isModif() {
      return true;
    },
    title() {
      return "Modification d'un TMOP";
    },
    tooltipDetailMessage() {
      return "Modifier un TMOP";
    },
    iconValue() {
      return "$vuetify.icons.edit";
    },
  },
  methods: {
    onOldTmopChanges(new_value) {
      if(!_.isNil(new_value.id)) {
        this.tmop.id = new_value.id;
      }
      this.tmop.annee = Utils.dateToYear(new_value.annee);
      this.tmop.semestre = new_value.semestre;
      this.tmop.taux_interet_retard = new_value.taux_interet_retard;
      this.tmop.tmop_applicable = new_value.tmop_applicable;
    },
    submit() {
        const data_to_update = _.cloneDeep(this.tmop);
        data_to_update.annee = this.preparedAnnee();
        this.$emit("edit-tmop", this.oldTmop, data_to_update);
        this.closePopUp();
    },
    closePopUp() {
      this.dialog = false;
      this.$refs.formTmop.reset();
    },
  }
};