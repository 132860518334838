<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout datatableExcludedSize="110px">
            <template slot="header">
                <h1>Affectation de l'intéressement</h1>
                <v-row align="center" no-gutters>
                    <v-col>
                        <h2>{{ exercice.libelle }}</h2>
                    </v-col>
                    <v-spacer/>
                    <v-col lg="2" xl="1" v-if="!exercice.inte_exercice.is_affectation_inte_valide">
                        <v-import-export-file detail="Importer l'affectation de l'intéressement"
                                       :typesFile="getTypesExtensionsFiles().excel"
                                       :allowedExport="!exercice.inte_exercice.is_affectation_inte_valide"
                                       exportDetail="Télécharger le modèle d'import de l'affectation de l'intéressement"
                                       @export-file="exportAffectationInte"
                                       @import-file="function(formData){onAffectationInteImport(formData)}"/>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main="{ datatableSize }">
                <loading-screen ref="loadingComponent"/>
                <v-container>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12">
                            <v-datatable-affectation-inte @update-affectations="affectations = $event"
                                                          @update-inte-supports="inteSupports = $event"
                                                          @update-data-changed="dataChanged = $event"
                                                          :height="datatableSize"
                                                          :affectations="affectations"
                                                          :inteSupports="inteSupports"
                                                          :dataChanged="dataChanged"
                                                          :selectedExercice="exercice"
                                                          :displayAffectedGrey="true"
                                                          ref="datatable_affectation_inte"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
            <!-- Espace entre les boutons -->
                <v-row dense>
                    <!-- Petit bouton 'télécharger excel' -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn dark color="primary" outlined @click="downloadAffectationInte" block>
                            <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                            Télécharger
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                    <template v-if="!exercice.inte_exercice.is_affectation_inte_valide">
                        <!-- Petit bouton 'enregistrer' -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-btn block color="secondary" @click="saveAffectationInte(validate, errors)">
                                <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                        <!-- Petit bouton 'valider' -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-custom-confirmation-dialog :message="buildConfirmationMessage()"
                                                          openButtonMessage="Valider"
                                                          title="Valider l'affectation de l'intéressement"
                                                          :isIcon="false"
                                                          @action-confirmee="validateAffectationInte"
                                                          @open-popup-click="activeValidation=true;"
                                                          @cancel-popup-click="activeValidation=false;"
                                                          :externalValidateForm="validate"
                                                          :externalErrorsList="errors"
                            />
                        </v-col>
                    </template>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" class="px-1">
                        <v-btn block :to="{name: 'inte-exercices'}">Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import internalApi from "@/api/internalApi";
import {TOAST} from "@/plugins/toast/definition";
import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";
import BaseLayout from "@/components/BaseLayout";
import Utils from "@/utils";
import VDatatableAffectationInte from "@/components/affectationInte/VDatatableAffectationInte";
import VImportExportFile from "@/components/VImportExportFile";

/**
 * Vue affichant l'affectation de l'intéressement pour les
 * bénéficiaires concernés
 * @displayName Intéressement - AffectationInte
 */
export default {
    components: {
        BaseLayout,
        VDatatableAffectationInte,
        VCustomConfirmationDialog,
        loadingScreen,
        VImportExportFile,
    },
    data() {
        return {
            affectations: [],
            inteSupports: [],
            noDataTextMessage: "Aucune donnée disponible.",
            confirmationMessage: "Une fois l'affectation de l'intéressement validée, les montants ne seront plus modifiables." +
                "<br/>Confirmez-vous cette opération ?",
            debouncedVersementImmediatNet: undefined,
            supportDeversementName: undefined,
            dataChanged: false,
            exercice: {
                inte_exercice: {
                    is_affectation_inte_valide: false
                },
            },
            selected_category_id: undefined,
            date_souscription: undefined,
            activeValidation: false,
        };
    },
    mounted() {
        const {inteExerciceId} = this.$route.params;
        this.getInteExerciceById(inteExerciceId);
    },
    methods: {
        getInteExerciceById(inte_exercice_id) {
            return internalApi.inteExercice.getById(inte_exercice_id).then(
                result => {
                    this.exercice = result;
                    this.getSupportDeversementName();
                }
            );
        },
        /**
         * Retourne la constante des types d'extensions de fichiers
         */
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
        /**
         * Enregistre sans valider l'affectation actuelle
         */
        saveAffectationInte(validate, errors) {
            validate().then(
                success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                        internalApi.affectationInte.saveInteAffectationByInteExerciceId(this.exercice.id, this.affectations)
                            .finally(() => {
                                this.dataChanged = false;
                                this.$refs.loadingComponent.resetLoading();
                            });
                    } else {
                        this.showErrorToast(errors);
                    }
                }
            );
        },
        getObjectForValidate() {
            return {"affectations": this.affectations}
        },
        /**
         * Valide l'affectation actuelle puis revient à l'écran des exercices
         */
        validateAffectationInte() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            internalApi.affectationInte.validateInteAffectationByInteExerciceId(this.exercice.id, this.getObjectForValidate()).then(res => {
                this.activeValidation = false;
                this.dataChanged = false;
                this.$refs.loadingComponent.resetLoading();
                this.$router.push({
                    name: "inte-exercices"
                });
            }).catch(error => {
                this.$refs.loadingComponent.resetLoading();
            })
        },
        /**
         * Calcule le montant restant non affecté
         * @param affectation L'affectation actuelle
         * @returns {number}
         */
        getReste(affectation) {
            let reste = affectation.montant_net_inte - affectation.montant_brut_vers_immediat;
            _.forEach(affectation.placements, placement => {
                reste -= placement.montant;
            });
            return Utils.roundNumber(reste);
        },
        /**
         * Récupère le nom du support de déversement par défaut via son ID
         */
        getSupportDeversementName() {
            if (this.exercice.inte_exercice.inte_support_deversement_id) {
                internalApi.inteSupport.getById(this.exercice.inte_exercice.inte_support_deversement_id).then(result => {
                    this.supportDeversementName = result.name;
                })
            }
        },
        /**
         * Construit le message de confirmation et s'il y a un montant restant non affecté,
         * ajoute un message concernant le support de déversement
         * @returns {string}
         */
        buildConfirmationMessage() {
            let message = "";
            if (this.supportDeversementName) {
                message += this.buildDeversementMessage();
            }
            message += this.confirmationMessage;
            return message;
        },
        /**
         * Construit un message indiquant que le montant restant partira dans le support
         * de déversement par défaut
         * @returns {string}
         */
        buildDeversementMessage() {
            let flag = false;
            _.forEach(this.affectations, (affectationBenef) => {
                if (this.getReste(affectationBenef) !== 0) {
                    flag = true;
                }
            });
            if (flag) {
                return `<p class="red--text">Attention ! Une partie de l'intéressement n’est pas affectée. Par défaut, `
                    + `les montants  concernés seront placés sur le support « ${this.supportDeversementName} »</p>`;
            } else {
                return "";
            }
        },
        /**
         * Display errors from the table
         */
        showErrorToast(raw_errors) {
            const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
            errors.forEach(error => TOAST.error("Champ requis manquant", error));
        },
        onAffectationInteImport(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.import);
            internalApi.affectationInte.importInteAffectationByInteExerciceId(this.exercice.id, formData)
                .then(() => {
                    this.$refs.datatable_affectation_inte.getAllAffectationParBeneficiaireSync();
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Exporte les données de la datatable sous forme d'un fichier excel
         */
        exportAffectationInte() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
            internalApi.affectationInte.getTemplateImportInteAffectationByInteExerciceId(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "modele_import_affectation_interessement.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Télécharge les données de la datatable sous forme d'un fichier excel
         */
        downloadAffectationInte() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.affectationInte.downloadInteAffectationByInteExerciceId(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "affectation_interessement.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
    }
};
</script>
