import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default tauxFiscaliteApi;

function tauxFiscaliteApi() {
  const specificApi = {
    deleteTauxFiscaliteById: deleteTauxFiscaliteById(),
    getTauxFiscaliteByDate: getTauxFiscaliteByDate()
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.tauxFiscalites.baseUrl));
}

// TODO : Mettre la fonction dans defaultApi
function deleteTauxFiscaliteById() {
  function deleteTauxFiscaliteById(id, queryParams, headers) {
    let path = internalApiRoutes.tauxFiscalites.deleteTauxFiscaliteById(id);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return deleteTauxFiscaliteById;
}

function getTauxFiscaliteByDate() {
  function getTauxFiscaliteByDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.tauxFiscalites.getTauxFiscaliteByDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getTauxFiscaliteByDate;
}