var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"withHeader":false},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sousCategories,"footer-props":_vm.footerProps,"height":datatableSize,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-lg-left"},[_vm._v(" "+_vm._s(item.code)+" ")]),_c('td',{staticClass:"text-lg-left"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-lg-center"},[_vm._v(" "+_vm._s(_vm._f("limitStr")(item.description,80))+" ")]),_c('td',{staticClass:"text-lg-center"},[_c('v-scic-sous-categorie-edition',{attrs:{"oldScicSousCategorie":item},on:{"edit-scic-sous-categorie":function (oldSousCategorie, newSousCategorie) { return _vm.editScicSousCategorie(oldSousCategorie, newSousCategorie); }}})],1)])]}}],null,true)})]}},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('v-scic-sous-categorie-creation',{on:{"add-scic-sous-categorie":function($event){return _vm.addScicSousCategorie($event)}}})],1),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors},on:{"emit-cancel":function($event){return _vm.cancel()},"emit-valid-save":function($event){return _vm.submit()}}})]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }