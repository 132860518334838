import isCurrency from "validator/lib/isCurrency";

const validateCurrency = value => {
  if (Array.isArray(value)) {
    return value.every(val =>
      isCurrency(String(val), {
        digits_after_decimal: [1, 2],
      })
    );
  }

  return isCurrency(String(value), {
    digits_after_decimal: [1, 2],
  });
};

export {validateCurrency};
