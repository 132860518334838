<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout datatableExcludedSize="110px">
            <template slot="header">
                <h1>Affectation de la participation aux bénéfices</h1>
                <v-row align="center" no-gutters>
                    <v-col>
                        <h2>{{ exercice.libelle }}</h2>
                    </v-col>
                    <v-spacer/>
                    <v-col lg="2" xl="1" v-if="!exercice.pb_exercice.is_ventilation_pb_valide">
                        <v-import-export-file detail="Importer l'affectation de la participation aux bénéfices"
                                        :typesFile="getTypesExtensionsFiles().excel"
                                        @import-file="function(formData){onAffectationPbImport(formData)}"
                                        :allowedExport="!exercice.pb_exercice.is_ventilation_pb_valide"
                                        exportDetail="Télécharger le modèle d'import de l'affectation de la participation aux bénéfices"
                                        @export-file="exportAffectationPb"/>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main="{ datatableSize }">
                <loading-screen ref="loadingComponent"/>
                <v-container>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12">
                            <v-datatable-ventilation-pb @update-ventilations="ventilations = $event"
                                                        @update-pb-supports="pbSupports = $event"
                                                        @update-data-changed="dataChanged = $event"
                                                        :height="datatableSize"
                                                        :ventilations="ventilations"
                                                        :pbSupports="pbSupports"
                                                        :dataChanged="dataChanged"
                                                        :selectedExercice="exercice"
                                                        :displayAffectedGrey="true"
                                                        ref="datatable_ventilation_pb"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
            <!-- Espace entre les boutons -->
                <v-row dense>
                    <!-- Petit bouton 'télécharger excel' -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn dark color="primary" outlined @click="downloadExcel" block>
                            <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                            Télécharger
                        </v-btn>
                    </v-col>
                    <!-- Petit bouton 'rapport annuel' -->
                    <v-col md="3" lg="3" xl="2" v-if="exercice.pb_exercice.is_ventilation_pb_valide">
                        <v-btn color="primary" @click="downloadRapportAnnuel" block>
                            <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                            Rapport annuel
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                    <template v-if="!exercice.pb_exercice.is_ventilation_pb_valide">
                        <!-- Petit bouton 'enregistrer' -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-btn block color="secondary" @click="saveVentilationPb(validate, errors)">
                                <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                        <!-- Petit bouton 'valider' -->
                        <v-col md="3" lg="3" xl="2" class="px-1">
                            <v-custom-confirmation-dialog :message="buildConfirmationMessage()"
                                                          openButtonMessage="Valider"
                                                          title="Valider l'affectation de la participation"
                                                          :isIcon="false"
                                                          @action-confirmee="validateVentilationPb"
                                                          @open-popup-click="activeValidation=true;"
                                                          @cancel-popup-click="activeValidation=false;"
                                                          :externalValidateForm="validate"
                                                          :externalErrorsList="errors">
                                <template slot="custom-infos">
                                    <div v-if="showCategCapitalAffectationPb && activeValidation">
                                        <div>
                                            Veuillez sélectionner la catégorie de capital et la date de souscription
                                            qui feront l'objet des soucriptions obligatoires.
                                        </div>
                                        <v-row>
                                            <v-col cols="6">
                                                <ValidationProvider vid="selected_category_id"
                                                                    name="Catégorie de capital"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <v-categories-capital-list v-model="selected_category_id"
                                                                               :errors="errors"/>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="6">
                                                <ValidationProvider vid="date_souscription" name="Date de souscription"
                                                                    rules="required"
                                                                    v-slot="{ errors }">
                                                    <DateField v-model="date_souscription"
                                                               ref="date_souscription"
                                                               label="Date de souscription"
                                                               :errors="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>
                            </v-custom-confirmation-dialog>
                        </v-col>
                    </template>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" class="px-1">
                        <v-btn block :to="{name: 'pb-exercices'}">Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import internalApi from "@/api/internalApi";
import {TOAST} from "@/plugins/toast/definition";
import XLSX from "xlsx";
import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";
import BaseLayout from "@/components/BaseLayout";
import Utils from "@/utils";
import VDatatableVentilationPb from "@/components/ventilationPb/VDatatableVentilationPb";
import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
import DateField from "@/components/DateField";
import authService from "@/services/authService";
import VImportExportFile from "@/components/VImportExportFile";

/**
 * Vue affichant la ventilation de la participation aux bénéfices pour les
 * bénéficiaires concernés
 * @displayName Participation - VentilationPb
 */
export default {
    components: {
        VCategoriesCapitalList,
        BaseLayout,
        VDatatableVentilationPb,
        VCustomConfirmationDialog,
        loadingScreen,
        DateField,
        VImportExportFile,
    },
    data() {
        return {
            ventilations: [],
            pbSupports: [],
            noDataTextMessage: "Aucune donnée disponible.",
            confirmationMessage: "Une fois l'affectation de la participation aux bénéfices validée, les montants ne seront plus modifiables." +
                "<br/>Confirmez-vous cette opération ?",
            debouncedVersementImmediatNet: undefined,
            supportDeversementName: undefined,
            dataChanged: false,
            exercice: {
                pb_exercice: {},
            },
            selected_category_id: undefined,
            date_souscription: undefined,
            activeValidation: false,
        };
    },
    computed: {
        showCategCapitalAffectationPb() {
            /**
             * Affiche l'encart de sélection de la catégorie de capital et la date de souscription
             */
            return authService.hasAccessCapital() && this.exercice.pb_exercice.allows_edit_montee_capital;
        }
    },
    mounted() {
        const {id} = this.$route.params;
        this.getPbExerciceById(id);
    },
    methods: {
        getPbExerciceById(pb_exercice_id) {
            return internalApi.pbExercice.getById(pb_exercice_id).then(
                result => {
                    this.exercice = result;
                    this.getSupportDeversementName();
                }
            );
        },
        /**
         * Retourne la constante des types d'extensions de fichiers
         */
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
        /**
         * Enregistre sans valider la ventilation actuelle
         */
        saveVentilationPb(validate, errors) {
            validate().then(
                success => {
                    if (success) {
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                        internalApi.ventilationPb.saveVentilationPb(this.exercice.id, this.getObjetForSaveOrValidate())
                            .finally(() => {
                                this.dataChanged = false;
                                this.$refs.loadingComponent.resetLoading();
                            });
                    } else {
                        this.showErrorToast(errors);
                    }
                }
            );
        },
        getObjetForSaveOrValidate() {
            return {"ventilations": this.ventilations, "date_souscription": this.date_souscription, "category_id": this.selected_category_id}
        },
        /**
         * Valide la ventilation actuelle puis revient à l'écran des exercices
         */
        validateVentilationPb() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            internalApi.ventilationPb.validateVentilationPb(this.exercice.id, this.getObjetForSaveOrValidate()).then(res => {
                this.activeValidation = false;
                this.dataChanged = false;
                this.$router.push({
                    name: "pb-exercices"
                });
            }).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            })
        },
        /**
         * Télécharge le rapport annuel
         */
        downloadRapportAnnuel() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.repartitionPb.getRapportAnnuel(this.exercice.id)
                .then(result => {
                    Utils.downloadFile(result, "rapport_annuel.pdf", Utils.pdfMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                })
        },
        getDataExcel() {
            const headers = this.buildHeadersForExcel();
            const rows = this.buildRowsForExcel();

            return _.concat(headers, rows);
        },
        buildHeadersForExcel() {
            let headers = ["Bénéficiaire", "Numéro de tiers", "Montant net PB", "Montée au capital", "Montant brut versement immédiat", "Montant forfait social complémentaire", "Montant net versement immédiat"];

            _.forEach(this.pbSupports, pbSupport => {
                headers.push(pbSupport.name);
            });

            headers.push("Montant restant à affecter");

            return [headers];
        },
        buildRowsForExcel() {
            let rows = [];
            _.forEach(this.ventilations, (ventilBenef) => {
                const row = [
                    ventilBenef.beneficiaire_name,
                    ventilBenef.numero_tiers,
                    ventilBenef.montant_net_pb,
                    ventilBenef.montant_capital,
                    ventilBenef.montant_brut_deblocage,
                    ventilBenef.montant_fs_vers_imm_deblocage,
                    ventilBenef.montant_deblocage
                ];
                _.forEach(this.pbSupports, pbSupport => {
                    row.push(this.getMontantPlacementSupport(ventilBenef, pbSupport.id))
                });
                row.push(this.getReste(ventilBenef));

                rows.push(row);
            });
            rows.push([]);
            let finalRow = ["","","","",""];
            _.forEach(this.pbSupports, pbSupport => {
                finalRow.push(pbSupport.url_link);
            })
            rows.push(finalRow);
            return rows;
        },
        downloadExcel: function () {
            const ws = XLSX.utils.aoa_to_sheet(this.getDataExcel());
            const wb = XLSX.utils.book_new();
            wb.props = {
                Title: "Affectation PB",
                Subject: "Affectation de la participation aux bénéfices",
                Author: "EZIScop",
                CreatedDate: Date.now()
            };
            XLSX.utils.book_append_sheet(wb, ws, "Affectation");
            XLSX.writeFile(wb, "affectation_pb.xlsx");
        },
        /**
         * Récupère dynamiquement le montant d'un support pb
         * @param ventilation La ventilation contenant les placements sur suppot
         * @param support_id L'id du support dont on recherche le montant
         * @returns {number}
         */
        getMontantPlacementSupport(ventilation, support_id) {
            const placement = _.find(ventilation.placements, placement => placement.support_id === support_id);
            return placement ? placement.montant : 0;
        },
        /**
         * Calcul le montant restant non affecté
         * @param repartition La ventilation actuelle
         * @returns {number}
         */
        getReste(repartition) {
            let reste = repartition.montant_net_pb - repartition.montant_capital - repartition.montant_brut_deblocage;
            _.forEach(repartition.placements, placement => {
                reste -= placement.montant;
            });
            return Utils.roundNumber(reste);
        },
        /**
         * Récupère le nom du support de déversement par défaut via son ID
         */
        getSupportDeversementName() {
            if (this.exercice.pb_exercice.support_deversement_id) {
                internalApi.pbSupport.getById(this.exercice.pb_exercice.support_deversement_id).then(result => {
                    this.supportDeversementName = result.name;
                })
            }
        },
        /**
         * Construit le message de confirmation et s'il y a un montant restant non affecté,
         * ajoute un message concernant le support de déversement
         * @returns {string}
         */
        buildConfirmationMessage() {
            let message = "";
            if (this.supportDeversementName) {
                message += this.buildDeversementMessage();
            }
            message += this.confirmationMessage;
            return message;
        },
        /**
         * Construit un message indiquant que le montant restant partira dans le support
         * de déversement par défaut
         * @returns {string}
         */
        buildDeversementMessage() {
            let flag = false;
            _.forEach(this.ventilations, (ventilBenef) => {
                if (this.getReste(ventilBenef) !== 0) {
                    flag = true;
                }
            });
            if (flag) {
                return `<p class="red--text">Attention ! Une partie de la participation n’est pas affectée. Par défaut, `
                    + `les montants  concernés seront placés sur le support « ${this.supportDeversementName} »</p>`;
            } else {
                return "";
            }
        },
        /**
         * Display errors from the table
         */
        showErrorToast(raw_errors) {
            const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
            errors.forEach(error => TOAST.error("Champ requis manquant", error));
        },
        onAffectationPbImport(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.import);
            internalApi.ventilationPb.importAffectationPbExcel(this.exercice.id, formData)
                .then(() => {
                    this.$refs.datatable_ventilation_pb.getAllAffectationParBeneficiaireSync();
                })
                .catch(error => console.error(error))
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Exporte les données de la datatable sous forme d'un fichier excel
         */
        exportAffectationPb() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
            internalApi.ventilationPb.exportAffectationPbExcel(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "affectation_participation.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
    }
};
</script>
