<template>
    <base-layout :withHeader="true" :withFooter="true" header-size="160px">
        <template v-slot:header>
            <v-row>
                <v-col md="3" lg="3" xl="2">
                    <v-text-field v-model="search"
                                  append-icon="search"
                                  label="Id du traitement"
                                  single-line
                                  @input="fetchData()"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main>
            <v-data-table :footer-props="footerProps"
                          :headers="table.headers"
                          :items="tasksMonitoringList"
                          :loading="loading"
                          fixed-header
                          class="elevation-1"
                          no-data-text="Aucune tâche pour ce traitement."
                          :options.sync="pagination"
                          block
            >
                <template v-slot:item="{item}">
                    <tr>
                        <td class="text-lg-left">{{item.id}}</td>
                        <td class="text-lg-left">{{item.routing_key}}</td>
                        <td class="text-lg-left">{{item.headers}}</td>
                        <td class="text-lg-left">{{item.body}}</td>
                        <td class="text-lg-left">{{item.creation_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.rabbitmq_ack_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.last_worker_received_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.last_sent_to_dlq_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.last_error_stack_trace}}</td>
                        <td class="text-lg-left">{{item.next_task_created_by_dlq_id}}</td>
                        <td class="text-lg-left">{{item.last_worker_ack_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.manual_deletion_date | dateIsoToFrFilterWithHourMin}}</td>
                        <td class="text-lg-left">{{item.original_request_id}}</td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout";
import internalApi from "@/api/internalApi";

export default {
    name: "AsynchronousTaskMonitoring",
    data(){
        return {
            search: undefined,
            pagination: {
                sortBy: ["active", "lock_taken_time"],
                sortDesc: [true],
                page: 1,
                itemsPerPage: 10
            },
            footerProps: {
                itemsPerPageOptions: [
                    10,
                    20,
                    50,
                ],
            },
            loading: false,
            table: {
                headers: [
                    {
                        text: "Identifiant de la tâche",
                        sortable: true,
                        value: "id",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Clé de routage",
                        sortable: true,
                        value: "routing_key",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "En-tête",
                        sortable: true,
                        value: "headers",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Corps",
                        sortable: true,
                        value: "body",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Date de création",
                        sortable: true,
                        value: "creation_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Acquittement rabbitmq",
                        sortable: true,
                        value: "rabbitmq_ack_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Dernière réception par un worker",
                        sortable: true,
                        value: "last_worker_received_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Dernier envoi à la DLQ",
                        sortable: true,
                        value: "last_sent_to_dlq_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Stack d'erreur",
                        sortable: true,
                        value: "last_error_stack_trace",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Tâche suivante en cas de rejeu depuis la DLQ (redispatch)",
                        sortable: true,
                        value: "next_task_created_by_dlq_id",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Dernier acquittement fait par un worker (consume)",
                        sortable: true,
                        value: "last_worker_ack_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Date de suppression manuelle du message (delete)",
                        sortable: true,
                        value: "manual_deletion_date",
                        align: "left",
                        class: "text-lg-left"
                    },
                    {
                        text: "Identifiant traitement parent",
                        sortable: true,
                        value: "original_request_id",
                        align: "left",
                        class: "text-lg-left"
                    },
                ]
            },
            tasksMonitoringList: [],
        }
    },
    components: {
        BaseLayout
    },
    methods: {
        fetchData() {
            this.loading = true;
            internalApi.asynchronousApi.getTasksMonitoring({"request_id": this.search}).then(result => {
                this.tasksMonitoringList = result;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>