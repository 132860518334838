<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-btn @click.stop="localDialog = true" color="primary" block :disabled="disabledValue">
            {{buttonText}}
        </v-btn>
        <!-- Fenêtre modale -->
        <v-dialog v-model="localDialog" persistent max-width="600px" :disabled="disabledValue">
            <ValidationObserver v-slot="{ handleSubmit }" ref="formNewVersion" v-if="localDialog === true">
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row no-gutters>
                                <v-col md="12" lg="12" xl="12">
                                    <ValidationProvider vid="nom_version_statuts" name="nom version statuts"
                                                        :rules="{ required: true, excluded: nomVersionsUsed }"
                                                        v-slot="{ errors }">
                                        <v-text-field v-model="nom_version"
                                                      label="Nom de la version"
                                                      data-vv-name="nom_version_statuts"
                                                      data-vv-as="Nom de la version"
                                                      :error-messages="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters wrap>
                                <v-col md="12" lg="12" xl="12">
                                    <ValidationProvider vid="date_debut" name="date debut"
                                                        rules="required: true" v-slot="{ errors }">
                                        <date-field v-model="date_debut"
                                                    label="Date de début"
                                                    name="date_debut"
                                                    data-vv-as="Date de début"
                                                    :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                {{messageAvertissement}}
                            </v-row>
                            <v-row no-gutters>
                                <ValidationProvider vid="confirmation" name="confirmation"
                                                    rules="required:true|is:Je valide" v-slot="{ errors }">
                                    <v-text-field v-model="messageConfirmation"
                                                  label="Confirmation"
                                                  data-vv-name="confirmation"
                                                  data-vv-as="Confirmation"
                                                  :error-messages="errors"

                                    />
                                </ValidationProvider>
                            </v-row>
                            <v-row no-gutters>
                                <i>Pour confirmer l'opération, saisissez « Je valide »</i>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row no-gutters justify="end">
                            <!-- Bouton modal -->
                            <v-col class="mr-2" md="4" lg="4" xl="4">
                                <v-btn color="primary" @click="closePopUp()" block>Non, abandonner</v-btn>
                            </v-col>
                            <!-- Bouton modal -->
                            <v-col md="4" lg="4" xl="4">
                                <v-btn color="primary" @click="handleSubmit(submitNewVersion)" block>Oui, confirmer
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>
    </div>
</template>

<script>
    import DateField from "@/components/DateField.vue";

    /**
     * Composant affichant un formulaire permettant la création d'une nouvelle version
     * de l'accord, des statuts ou de la société
     * @displayName Commun - NewVersion
     */
    export default {
        components: {
            DateField
        },
        props: {
            /**
             * Gère l'affichage ou de la fenêtre
             */
            dialog: {
                type: Boolean,
                required: false
            },
            /**
             * La liste des versions existante
             */
            versionsList: {
                type: Array,
                required: true,
            },
            /**
             * Les informations concernant la nouvelle version
             */
            nouvelleVersion: {
                type: Object,
                required: true
            },
            /**
             * Détermine si le formulaire est accessible ou non
             */
            disabledValue: {
                type: Boolean,
                required: false
            }
        },
        computed: {
            localDialog: {
                get: function () {
                    return this.dialog;
                },
                set: function (newvalue) {
                    this.$emit("on-open-dialog", newvalue);
                }
            },
            localNouvelleVersion: {
                get: function () {
                    return this.nouvelleVersion;
                },
                set: function (newvalue) {
                }

            },
            nomVersionsUsed() {
                return this.versionsList.map(function (version) {
                    return version.nom_version
                });
            },
        },
        data() {
            return {
                messageConfirmation: undefined,
                messageAvertissement: undefined,
                buttonText: undefined,
                title: undefined,
                nom_version: undefined,
                date_debut: undefined
            }
        },
        methods: {
            submitNewVersion() {
            },
            closePopUp() {
                this.nom_version = undefined;
                this.date_debut = undefined;
                this.localDialog = false;
                this.messageConfirmation = "";
                this.$refs.formNewVersion.reset();
            },
        }
    }
</script>