<!-- VUETIFY2 - OK -->
<template>
        <v-autocomplete :value="value"
                        :label="label"
                        :items="countriesList"
                        :error-messages="errors"
                        @input="$emit('input', $event)"
                        :disabled="isDisabled"/>
</template>

<script>
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant un auto complete avec la liste des pays disponibles en majuscules
     * @displayName Capital - Auto complete pays
     */
    export default {
        name: "v-countries-list",
        props: {
            /**
             * L'item sélectionné
             */
            value: {
                type: String
            },
            isDisabled: Boolean,
            errors: {
                type: Array,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: "Pays"
            }
        },
        beforeMount() {
            this.uniqueValidationId = "countries" + Math.random();
        },
        mounted() {
            this.fetchCountriesList();
        },
        data() {
            return {
                countriesList: [],
                uniqueValidationId: "countries"
            }
        },
        methods: {
            /**
             * Récupère la liste des pays disponibles
             */
            fetchCountriesList() {
                internalApi.countriesList.getAll().then(res => {
                    this.countriesList = res.countries.map(item => {
                        return {
                            value: item.country,
                            text: item.country,
                            cog: item.cog
                        };
                    });
                });
            },
        }
    };
</script>


