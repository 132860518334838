<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" headerSize="90px">
        <template v-slot:header>
            <v-container>
                <v-row align="center">
                    <v-col cols="6">
                        Veuillez sélectionner une date afin de visualiser l'état de votre portefeuille à cette date :
                    </v-col>
                    <v-col md="6" lg="3" xl="2">
                        <DateField ref="date_annu"
                                   v-model="dateDataCharts"
                                   label="Date de référence"
                                   :clearable="false"
                                   name="Date de référence"/>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-container>
                <v-row justify="space-around">
                    <v-col md="12" lg="6" xl="6">
                        <conteneur-graphique type="pieorbar" :title="portefeuilleAssocieDataTitle"
                                             :data="portefeuilleAssocieData"
                                             :date="dateDataCharts"></conteneur-graphique>
                    </v-col>
                    <v-col md="12" lg="6" xl="6">
                        <conteneur-graphique type="line_cap" :title="evolutionPortefeuilleAssocieDataTitle"
                                             :data="evolutionPortefeuilleAssocieData"
                                             :date="dateDataCharts"></conteneur-graphique>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </base-layout>
</template>

<script>
    import moment from "moment/moment";
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import ConteneurGraphique from "@/components/ConteneurGraphique.vue";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Composant affichant, à une date donnée, des graphiques indiquant l'état
     * du portefeuille de l'associé
     */
    export default {
        components: {
            DateField,
            loadingScreen,
            ConteneurGraphique,
            BaseLayout,
        },
        data() {
            return {
                dateDataCharts: moment().format("YYYY-MM-DD"),
                portefeuilleAssocieDataTitle: "Répartition du portefeuille",
                portefeuilleAssocieData: undefined,
                evolutionPortefeuilleAssocieDataTitle: "Évolution du portefeuille",
                evolutionPortefeuilleAssocieData: undefined,
            };
        },
        watch: {
            dateDataCharts: function () {
                this.callAssocieCharts();
            }
        },
        mounted() {
            this.callAssocieCharts();
        },
        methods: {
            /**
             * Récupère les graphiques de l'associé à une date donnée
             */
            callAssocieCharts() {
                if (!_.isNil(this.dateDataCharts)) {
                    this.$refs.loadingComponent.setLoading("Chargement des graphiques");
                    internalApi.capCharts.associeData(this.dateDataCharts)
                        .then(result => {
                            this.$refs.loadingComponent.resetLoading();
                            this.portefeuilleAssocieData = result.portefeuille_associe;
                            this.evolutionPortefeuilleAssocieData = result.evolution_portefeuille_associe;
                        })
                        .catch(err => {
                            console.error("Erreur lors de la récupération des graphiques : ", err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
        }
    };
</script>
