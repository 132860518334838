// <!-- VUETIFY2 - OK -->
import Souscription from "./Souscription";
import internalApi from "@/api/internalApi";

export default {
  mounted() {
    this.isEditable = false;
    this.getSouscriptionAssocie();
    this.specificBackRoute = this.aSpecificBackRoute;
  },
  extends: Souscription,
  computed: {
    souscriptionId() {
      return this.$route.params.id;
    },
  },
  props: {
    aSpecificBackRoute: {
      type: String,
      required: false
    }
  },
  methods: {
    submitForm() {
    },
    getSouscriptionAssocie() {
      internalApi.souscription.associe.getById(this.souscriptionId).then(res => {
        this.souscription = res;
      });
    }
  }
};