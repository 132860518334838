<!-- VUETIFY2 - OK -->
<template>
    <v-select   :items="scicParamCategorieLabels"
                label="Catégorie d'associé"
                :error-messages="errors"
                :disabled="isDisabled"
                v-model="localScicCategorieAssocie"
    />
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    /**
     * Composant affichant la liste des catégories d'associé (SCIC).
     * @displayName Scic - Liste catégories d'associé
     */
    export default {
        name: "VScicParamCategorieList",
        props: {
            /**
             * La catégorie sélectionnée
             */
            scicCategorieAssocie: {
                type: String,
            },
            /**
             * Désactive ou non le composant
             */
            isDisabled: Boolean,
            /**
             * Les erreurs générées par un ValidationProvider
             */
            errors: {
                type: Array,
                required: false,
            }
        },
        mounted() {
            this.fetchScicParamCategorieLabels();
        },
        computed: {
            localScicCategorieAssocie: {
                get: function () {
                    return this.scicCategorieAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-categorie-associe", newvalue);
                }
            },
        },
        data() {
            return {
                scicParamCategorieLabels: [],

            }
        },
        methods: {
            fetchScicParamCategorieLabels() {
                internalApi.scicParamCategorie.getLabels().then(res => {
                    this.scicParamCategorieLabels = Utils.fromLabelToSelect(res.categories);
                });
            }
        }
    };
</script>


