// <!-- VUETIFY2 - OK -->
import VDatatableVentilationPb from "./VDatatableVentilationPb";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import {TOAST} from "@/plugins/toast/definition";
import constantes from "@/utils/constantes";

/**
 * Composant affichant la ventilation d'un bénéficiaire connecté
 * @display_name Participation - VDatatable Ventilation espace bénéficiaire
 */
export default {
  extends: VDatatableVentilationPb,
  name: "v-datatable-ventilation-pb-espace-beneficiaire",
  data() {
    return {
        displayAffectedGrey: false,
    };
  },
  mounted(){
    this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
      constantes.droits_acces.noms.espace_pb_affectation_versement_immediat,
      [constantes.droits_acces.valeurs_possibles.edition],
    )
    this.canEditMonteeCapital = this.$store.getters.hasDroitAcces(
        constantes.droits_acces.noms.espace_pb_affectation_montee_capital,
        [constantes.droits_acces.valeurs_possibles.edition],
    )
    this.canEditAffectationSupports = this.$store.getters.hasDroitAcces(
      constantes.droits_acces.noms.espace_pb_affectation_supports,
      [constantes.droits_acces.valeurs_possibles.edition],
    )
  },
  methods: {
      updateDataTable() {
          this.loadingPeriodes = true;

          return new Promise((resolve, reject) => {

              if (this.selectedExercice == null) {
                // No exercice selected
                resolve({
                    'items': [],
                    'total': 0
                });
                this.loadingPeriodes = false;
              }

              const {sortBy, sortDesc, page, itemsPerPage} = this.options;

              internalApi.ventilationPb.getVentilationPbIndividuelByExerciceId(
                  this.selectedExercice.id,
                  page,
                  itemsPerPage,
                  sortBy[0],
                  sortDesc[0],
              ).then(res => {
                  this.localPbSupports = res.supports;
                  this.allowsEditMonteeCapital = res.allows_edit_montee_capital;
                  this.allowsEditVersementImmediat = res.allows_edit_versement_immediat;
                  this.initPlacementsTemp(res);
                  resolve({
                      'items': res.ventilations,
                      'total': res.total_items
                  });
              }).catch(err => {
                  console.error("Erreur lors de la récupération des répartitions de pb : ", err);
                  reject({
                      'items': [],
                      'total': 0
                  });
              }).finally(() => {
                  this.loadingPeriodes = false;
              });
          });
      },
  },
  watch: {
    selectedExercice: function(){
      if (! _.isNil(this.selectedExercice.id)) {
        if (!this.selectedExercice.pb_exercice.is_repartition_pb_valide){
          TOAST.warning("Affectation inaccessible. Cet exercice n'a pas encore été validé en AG.", Utils.EMPTY_STRING);
          this.localVentilations = [];
        } else {
          this.getAllAffectationParBeneficiaireSync();
        }
      }
    }
  }
};