import VDatatableAffectationCapital from "./VDatatableAffectationCapital";
import constantes from "@/utils/constantes";
import internalApi from "@/api/internalApi";

/**
 * Composant affichant l'affectation d'un accocié connecté
 * 
 */

export default {
    extends: VDatatableAffectationCapital,
    name: "v-datatable-affectation-capital-espace-associe",
    data() {
        return {
            displayAffectedGrey: false,
        };
    },
    mounted(){
        this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
            constantes.droits_acces.noms.espace_capital_affectation_versement_immediat,
            [constantes.droits_acces.valeurs_possibles.edition],
        )
        this.canEditMonteeCapital = this.$store.getters.hasDroitAcces(
            constantes.droits_acces.noms.espace_capital_affectation_montee_capital,
            [constantes.droits_acces.valeurs_possibles.edition],
        )
        this.canEditCompteCourant = this.$store.getters.hasDroitAcces(
            constantes.droits_acces.noms.espace_capital_affectation_compte_courant,
            [constantes.droits_acces.valeurs_possibles.edition],
        )
    },
    methods: {

        updateDataTable() {
            this.loadingAffectations = true;
            return new Promise((resolve, reject) => {
                internalApi.capExercice.affectation
                .getAffectationCapitalIndividuelByExerciceId(this.selectedExercice.id)
                .then(res => {
                    this.allowAffectationIpsVersementImmediat = res.allow_affectation_ips_versement_immediat;
                    this.allowAffectationIpsMonteeCapital = res.allow_affectation_ips_montee_capital;
                    this.allowAffectationIpsCompteCourant = res.allow_affectation_ips_compte_courant;
                    resolve({
                        'items': res.affectations,
                        'total': res.total_items
                    });
                    
                }).catch(err => {
                    console.error("Erreur lors de la récupération des répartitions de capital : ", err);
                    reject({
                        'items': [],
                        'total': 0
                    });
                }).finally(() => {
                    this.loadingAffectations = false;
                });
            });
        },
    },
};