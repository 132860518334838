<!-- VUETIFY2 - OK -->
<template>
    <repartition-inte-espace-individuel-inte
        ref="repartitionInteEspaceIndividuelInte"/>
</template>

<script>
    import RepartitionInteEspaceIndividuelInte from "@/views/interessement/espaceIndividuelInte/RepartitionInteEspaceIndividuelInte.vue";
    import authService from "@/services/authService";
    export default {
        components: {
            RepartitionInteEspaceIndividuelInte,
        },
        computed: {
            beneficiaireId: function () {
                return parseInt(authService.getIdBeneficiaire());
            },
        }
    };
</script>