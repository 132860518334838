<!-- VUETIFY2 - OK -->
<template>
    <v-btn block color="primary" @click="validate" :disabled="disabledValue">{{btnLabel}}</v-btn>
</template>


<script>

    import {TOAST} from "@/plugins/toast/definition";

    export default {
        name: "v-button-with-validation",
        props: {
            validateForm: {
                type: Function,
                required: false
            },
            errorsList: {
                type: Object,
                required: false
            },
            showToast: {
                type: Boolean,
                default: true
            },
            btnLabel: {
                type: String,
                required: true
            },
            disabledValue: {
                type: Boolean,
                required: false
            }
        },
        methods: {
            validate() {
                this.validateForm().then(
                    success => {
                        if (success) {
                            this.$emit("emit-valid-click");
                        } else if (this.showToast) {
                            this.showErrorToast();
                        }
                    }
                ).catch(err => {
                    console.error(err);
                });
            },
            showErrorToast() {
                let raw_errors = this.errorsList;
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            }
        }
    };
</script>