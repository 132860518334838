<template>
    <base-layout :withFooter="false" headerSize="110px">
        <template v-slot:header>
            <h1>Fiscalité</h1>
            <v-tabs v-model="currentTab">
                <v-tab key="general">Général</v-tab>
                <v-tab key="tmop">Tmop</v-tab>
                <v-tab key="scic" :disabled="!hasScicExtensionAccess()">Scic</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="general">
                    <base-layout :withHeader="false">
                        <template v-slot:main="{ datatableSize }">
                            <v-data-table
                                :headers="headers"
                                :items="tauxFiscaliteList"
                                no-data-text="Aucun taux de fiscalité enregistré."
                                class="elevation-1"
                                :height="datatableSize"
                                fixed-header
                                :options.sync="pagination"
                            >
                                <template v-slot:item="{item}">
                                    <tr>
                                        <td class="text-lg-left">{{item.date_debut | dateIsoToFrFilter}}</td>
                                        <td class="text-lg-right">{{item.taux_csg_crds | roundPercentFilter}}</td>
                                        <td class="text-lg-right">{{item.taux_charges_sociales | roundPercentFilter}}</td>
                                        <td class="text-lg-right">{{item.taux_prelevement_forfaitaire | roundPercentFilter}}</td>
                                        <td class="text-lg-right">{{item.forfait_social_standard | roundPercentFilter}}</td>
                                        <td class="text-lg-right">{{item.forfait_social_vers_immediat | roundPercentFilter}}</td>
                                        <td class="text-lg-left">{{item.date_fin | dateIsoToFrFilter}}</td>
                                        <td class="text-lg-left" v-if="item.is_last">
                                            <v-custom-confirmation-dialog
                                                :message="'Etes vous sûr de vouloir annuler ces taux ?'"
                                                :isIcon="true"
                                                :iconValue="'$vuetify.icons.delete'"
                                                @action-confirmee="deleteTauxFiscalite(item.id)"
                                                tooltipDetailMessage="Supprimer les taux"/>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </template>
                        <template v-slot:footer>
                            <v-row no-gutters>
                                <v-col md="4" lg="4" xl="4">
                                    <v-new-taux-fiscalite @taux-fiscalite-created="getTauxFiscalite()"></v-new-taux-fiscalite>
                                </v-col>
                            </v-row>
                        </template>
                    </base-layout>
                </v-tab-item>
                <v-tab-item key="tmop">
                    <tmop-list/>
                </v-tab-item>
                <v-tab-item key="scic">
                    <v-scic-taux-fiscalite v-if="hasScicExtensionAccess()"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VNewTauxFiscalite from "@/components/VNewTauxFiscalite";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import TmopList from "@/components/tmop/TmopList";
    import VScicTauxFiscalite from "@/components/scic/tauxFiscalite/VScicTauxFiscalite";
    import authService from "@/services/authService";

    export default {
        name: "TauxFiscalite",
        components: {
            VScicTauxFiscalite,
            VNewTauxFiscalite,
            VCustomConfirmationDialog,
            BaseLayout,
            loadingScreen,
            TmopList,
        },
        data() {
            return {
                tauxFiscaliteList: undefined,
                headers: [
                    {text: "Date de début", value: "date_debut", sortable: true},
                    {text: "Taux CSG - CRDS", value: "taux_csg_crds", sortable: false, align: "end"},
                    {text: "% charges sociales", value: "taux_charges_sociales", sortable: false, align: "end"},
                    {text: "% prélèvement forfaitaire", value: "taux_prelevement_forfaitaire", sortable: false, align: "end"},
                    {text: "Forfait social standard", value: "forfait_social_standard", sortable: false, align: "end"},
                    {text: "Forfait social versement immédiat", value: "forfait_social_vers_immediat", sortable: false, align: "end"},
                    {text: "Date de fin", value: "date_fin", sortable: false},
                    {text: "Actions"},
                ],
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                tauxFiscalitesToCreate: [],
                tauxFiscalitesIdsToDelete: [],
                currentTab: undefined,
            }
        },
        mounted() {
            this.getTauxFiscalite();
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
            getTauxFiscalite() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.tauxFiscalite.getAll()
                    .then(result => {
                        this.tauxFiscaliteList = result;
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            deleteTauxFiscalite(tauxFiscaliteId) {
                internalApi.tauxFiscalite.deleteTauxFiscaliteById(tauxFiscaliteId)
                    .then(result => {
                        this.getTauxFiscalite();
                    });
            }
        }
    }
</script>
