// <!-- VUETIFY2 - OK -->
import VScicCollege from "./VScicCollege"

export default {
  name: "v-scic-college-creation",
  extends: VScicCollege,
  computed: {
    isModif() {
      return false;
    },
    title() {
      return "Créer un collège de vote";
    },
    openButtonMessage() {
      return "Créer un collège de vote";
    },
  },
  methods: {
    submit() {
      let data = _.cloneDeep(this.college);
      this.$emit("add-scic-college", data);
      this.closePopUp();
    }
  }
};