<!-- VUETIFY2 - OK -->
<template>
    <v-expansion-panels>
        <v-expansion-panel :disabled="expansionIsDisable">
            <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="center" spacer>
                    <v-col class="ml-5" md="1" lg="1" xl="1">
                        <v-avatar>
                            <v-icon v-show="checkState === true" color="success">{{ "$vuetify.icons.success" }}</v-icon>
                            <v-icon v-show="checkState === false" color="error">{{ "$vuetify.icons.error" }}</v-icon>
                            <v-progress-circular v-show="checkState === undefined" indeterminate/>
                        </v-avatar>
                    </v-col>
                    <v-col md="10" lg="10" xl="10">
                        <span class="conformite-subheader">{{ checkTitle }}</span>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card color="grey lighten-2">
                    <v-card-text class="conformite-details" v-html="checkMessage">
                    </v-card-text>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import _ from "lodash";

    /**
     * Composant affichant les détails d'une règle de conformité
     * @displayName Capital - Conformité ligne
     */
    export default {
        props: {
            /**
             * Résumé du détail de règle
            */
            checkTitle: {
                type: String,
                required: true
            },
            /**
             * État du détail (vrai si validé, faux si erreur)
             */
            checkState: {
                type: Boolean
            },
            /**
             * Description du détail de la règle
             */
            checkMessage: {
                type: String
            }
        },
        computed: {
            expansionIsDisable() {
                return _.isEmpty(this.checkMessage);
            }
        }
    };
</script>

<style>
    .conformite-subheader {
        font-size: large;
    }
    .conformite-details {
        font-size: medium;
    }
</style>
