<!-- VUETIFY2 - OK -->
<template>
    <div class="my-2">
        <div class="header grey--text text--darken-1 caption">{{label}}</div>
        <div class="mono default black--text" v-html="message"></div>
    </div>
</template>
<script>

    export default {
        name: "v-not-editable-text-information-display",
        props: {
            label: {
                type: String,
                required: false
            },
            message: {
                required: true
            },
        },
        computed: {},
        methods: {}
    };
</script>
