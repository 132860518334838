<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="120px">
        <template slot="header">
            <h1>Conformité</h1>
            <v-row no-gutters>
                <v-col md="2" xl="2" lg="2">
                    <date-field v-model="controlDate" :clearable="false" @input="addPromiseResponseToCheckList"/>
                </v-col>
            </v-row>
        </template>
        <template slot="main">
            <v-container>
                <div v-for="checkHeader in toCheckHeader" :key="checkHeader.id">
                    <conformite-header :title="checkHeader.title" :toCheckList="checkHeader.checkList"/>
                </div>
            </v-container>
        </template>
    </base-layout>
</template>

<script>
    import DateField from "@/components/DateField";
    import ConformiteHeader from "@/views/capital/conformite/ConformiteHeader";
    import internalApi from "@/api/internalApi";
    import moment from "moment";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Composant affichant l'ensemble des règles de conformité
     * @displayName Capital - Conformité
     */
    export default {
        components: {
            BaseLayout,
            ConformiteHeader,
            DateField
        },
        data() {
            return {
                controlDate: moment().format("YYYY-MM-DD"),
                toCheckHeader: [headerMontantCapital, headerRepartitionCapital, headerDroitsVote],
                capCompany: undefined,
            };
        },
        mounted() {
            let promise_cap_company = this.getCurrentCapCompany();
            Promise.all([promise_cap_company]).then(res => {
                this.addPromiseResponseToCheckList(this.controlDate);
            });
        },
        methods: {
            getCurrentCapCompany() {
                return internalApi.capCompany.getCurrentStatutCapCompany()
                    .then(result => {
                        this.capCompany = result;
                    });
            },
            addPromiseResponseToCheckList(date) {
                for (let header of this.toCheckHeader) {
                    for (let check of header.checkList) {
                        check.state = undefined;
                        check.message = "";
                        check.promise(date, this.capCompany.id).then(resolve => {
                            check.state = resolve.checkIsValid;
                            check.message = resolve.message;
                        });
                    }
                }
            }
        }
    };

    let headerMontantCapital = {
        title: "Montant du Capital",
        checkList: [
            {
                id: 1,
                title:
                    "Le capital social doit être supérieur au seuil plancher défini par les statuts",
                promise: internalApi.controleStatus.getCapitalSuperieurPlancher,
                state: undefined,
                message: ""
            },
            {
                id: 2,
                title:
                    "Le capital social doit être supérieur au quart du capital le plus élevé atteint par l'entreprise",
                promise: internalApi.controleStatus.getCapitalSuperieurQuartCapitalMax,
                state: undefined,
                message: ""
            }
        ]
    };

    let headerRepartitionCapital = {
        title: "Répartition du Capital",
        checkList: [
            {
                id: 1,
                title:
                    "Les salarié(e)s et ex-salarié(e)s doivent détenir plus de 50% du capital",
                promise: internalApi.controleStatus.getCapitalSalariesExSalariesSuperieurMoitieCapital,
                state: undefined,
                message: ""
            },
            {
                id: 2,
                title:
                    "Aucun(e) associé(e) ne doit détenir plus de 50% du capital",
                promise: internalApi.controleStatus.getCapitalAssocieInferieurMoitieCapital,
                state: undefined,
                message: ""
            }
        ]
    };

    let headerDroitsVote = {
        title: "Droits de vote",
        checkList: [
            {
                id: 1,
                title:
                    "Les associé(e)s externes (hors sociétés coopératives) doivent détenir moins de 35% des droits de vote",
                promise: internalApi.controleStatus.checkDroitsVoteAssocieExterneHorsCoop,
                state: undefined,
                message: ""
            },
            {
                id: 2,
                title:
                    "Les associé(e)s externes (dont sociétés coopératives) doivent détenir moins de 50% des droits de vote",
                promise: internalApi.controleStatus.checkDroitsVoteAssocieExterneDontCoop,
                state: undefined,
                message: ""
            }
        ]
    };
</script>
