<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formSupportInteressement" v-slot="{validate, errors, reset }">
        <base-layout>
            <template slot="header">
                <h1>{{pageTitle}}</h1>
            </template>
            <template slot="main">
                <v-container>
                    <loading-screen ref="loadingComponent"/>
                        <v-row justify="space-between" class="pl-3" no-gutters>
                            <v-col md="3" lg="3" xl="3">
                                <ValidationProvider name="Nom du support" vid="nom_support" rules="required|max:100" v-slot="{ errors }">
                                    <v-text-field v-model.trim="support.name"
                                                  label="Nom du support"
                                                  hint="Doit être unique dans cet univers"
                                                  :error-messages="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col md="3" lg="3" xl="3">
                                <ValidationProvider name="Code du support" vid="code_support" rules="required|max:10" v-slot="{ errors }">
                                    <v-text-field v-model.trim="support.code"
                                                  label="Code du support"
                                                  hint="Doit être unique dans l'application"
                                                  :error-messages="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col md="3" lg="3" xl="3">
                                <v-checkbox v-model="support.actif"
                                            label="Actif"
                                            false-value="false"
                                />
                            </v-col>
                        </v-row>
                        <v-row justify="space-between" class="pl-3" no-gutters>
                            <v-col md="3" lg="3" xl="3">
                                <ValidationProvider name="Date de début" vid="date_debut" rules="required" v-slot="{ errors }">
                                    <date-field v-model="support.debut_validite"
                                                label="Date de début"
                                                :errors="errors"/>
                                </ValidationProvider>
                            </v-col>
                            <v-col md="3" lg="3" xl="3">
                                <date-field v-model="support.fin_validite"
                                            label="Date de fin"/>
                            </v-col>
                            <v-col md="3" lg="3" xl="3">
                                <v-row no-gutters>
                                        <h3 style="display: inline-block;">
                                            Gestion des fonds
                                        </h3>
                                        <v-tooltip top style="display: inline-block;" class="pb-2 ml-2">
                                            <template v-slot:activator="{ on }">
                                                <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                            </template>
                                            <span>Le transfert externe du support permet de sortir l'intéressement qui n’est pas géré par EZISCOP</span>
                                        </v-tooltip>
                                </v-row>
                                <v-row no-gutters class="mt-0">
                                    <ValidationProvider name="Type de support" vid="type_support" rules="required" v-slot="{ errors }">
                                        <v-radio-group v-model="support.type_support" :error-messages="errors">
                                            <v-radio v-for="typeSupport in switchTypeSupport" :key="typeSupport.value" 
                                                                                              :label="typeSupport.text"
                                                                                              :value="typeSupport.value"
                                                                                              :disabled="true"/>
                                        </v-radio-group>
                                    </ValidationProvider>
                                </v-row>
                            </v-col>
                            
                        </v-row>
                        <h2 class="my-2">Coordonnées du teneur de registre</h2>
                        <v-row class="pl-3" no-gutters>
                            <v-col md="3" lg="3" xl="3">
                                <ValidationProvider name="Nom du teneur de registre" vid="nom_teneur_registre" rules="required" v-slot="{ errors }">
                                    <v-text-field v-model.trim="support.nom_teneur_registre"
                                                label="Nom du teneur de registre"
                                                :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3" no-gutters>
                            <v-address :address1="support.adresse_teneur_registre.address1"
                                    :address2="support.adresse_teneur_registre.address2"
                                    :zipCode="support.adresse_teneur_registre.zip_code"
                                    :city="support.adresse_teneur_registre.city"
                                    :typeVoie="support.adresse_teneur_registre.type_voie"
                                    :numeroVoie="support.adresse_teneur_registre.numero_voie"
                                    :country="support.adresse_teneur_registre.country"
                                    @update-address-1="support.adresse_teneur_registre.address1 = $event"
                                    @update-address-2="support.adresse_teneur_registre.address2 = $event"
                                    @update-zip-code="support.adresse_teneur_registre.zip_code = $event"
                                    @update-city="support.adresse_teneur_registre.city = $event"
                                    @update-numero-voie="support.adresse_teneur_registre.numero_voie = $event"
                                    @update-type-voie="support.adresse_teneur_registre.type_voie = $event"
                                    @update-country="support.adresse_teneur_registre.country = $event"
                            />
                        </v-row>
                        <v-row class="pl-3" no-gutters>
                            <v-col>
                                <v-textarea v-model="support.description"
                                            label="Description"/>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3" no-gutters>
                            <v-col>
                                <ValidationProvider name="Lien du support"
                                                    vid="url_check"
                                                    :rules="{ regex: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})$/, max:250}"
                                                    v-slot="{ errors }">
                                                    <v-text-field :error-messages="errors" v-model.trim="support.url_link"
                                                                    label="Lien du support"
                                                                    placeholder="Par exemple : https://lien-du-support-a.fr"/>
                                </ValidationProvider>
                                <!-- vee-validate upgrade version future ? rules="url" -->
                            </v-col>
                        </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row justify="end" no-gutters>
                    <!-- Petit bouton -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancel"
                                                      @emit-valid-save="submit"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import DateField from "@/components/DateField";
    import VAddress from "@/components/VAddress";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";

    export default {
        name: "SupportInteressementAbstract",
        abstract: true,
        components: {
            loadingScreen,
            VAddress,
            DateField,
            BaseLayout,
            VSaveButonWithValidation
        },
        computed: {
            inteSupportId() {
                return parseInt(this.$route.params.id) || undefined;
            }
        },
        data() {
            return {
                support: {
                    id: undefined,
                    code: undefined,
                    name: undefined,
                    description: undefined,
                    debut_validite: undefined,
                    fin_validite: undefined,
                    actif: true,
                    url_link: undefined,
                    url_check: undefined,
                    nom_teneur_registre: undefined,
                    adresse_teneur_registre: {
                        address1: undefined,
                        address2: undefined,
                        zip_code: undefined,
                        city: undefined,
                        type_voie: undefined,
                        numero_voie: undefined,
                        country: "FRANCE"
                    },
                    type_support: "transfert_externe",
                },
                pageTitle: "",
                switchTypeSupport: {},
                isCreation: false,
            }
        },
        mounted() {
            this.fetchSupportDePlacementLabels();
        },
        methods: {
            /**
             * Remplace le champ 'url_link' par la valeur 'null', si le champ est vide
            */
            getCleanedSupport() {
                let cleaned_support = _.cloneDeep(this.support);
                delete cleaned_support.type_support;
                if (cleaned_support.url_link === "") {
                    cleaned_support.url_link = null;
                }
                return cleaned_support;
            },
            /**
             * Validation du formulaire
             */
            submit() {
                // Abstract method
            },
            /**
             * Retour à l'écran précédent
             */
            cancel() {
                this.$router.push({
                    name: "liste-supports-interessement"
                });
            },
            /**
             * Récupération des labels à afficher
             */
            fetchSupportDePlacementLabels() {
                internalApi.inteSupport.getLabels().then(res => {
                    this.switchTypeSupport = Utils.fromLabelToSelect(res.types_support);
                });
            },
        }
    }
</script>