import rolesList from "@/router/rolesList";
import Administration from "@/views/administration/Administration.vue";
import ChangePassword from "@/views/administration/ChangePassword.vue";
import NewUserAccess from "@/views/administration/NewUserAccess.vue";
import Index from "@/views/Index";
import NewUsersMassAccessCreation from "@/views/administration/NewUsersMassAccessCreation";

export default {
  path: "administration/",
  component: Index,
  children: [
    {
      path: "",
      name: "administration",
      component: Administration,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
      }
    },
    {
      path: "change-password/",
      name: "changePassword",
      component: ChangePassword,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE, rolesList.TIERS, rolesList.DOIT_CHANGER_MOT_DE_PASSE],
      }
    },
    {
      path: "new-user-access/",
      name: "newUserAccess",
      component: NewUserAccess,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
      }
    },
    {
      path: "new-users-mass-access-creation/",
      name: "newUsersMassAccessCreation",
      component: NewUsersMassAccessCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE],
      }
    }
  ]
}
;
