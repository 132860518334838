// <!-- VUETIFY2 - OK -->
import Support from "./Support";
import internalApi from "@/api/internalApi";

export default {
  extends: Support,
  data() {
    return {
      isCreation: true,
      pageTitle: "Créer un support de placement de participation",
    };
  },
  methods: {
    submit() {
      this.$refs.formSupport.validate().then(
        success => {
          if (success) {

            const cleaned_support = this.getCleanedSupport();

            internalApi.pbSupport.postAllWithToastConfirmation(cleaned_support)
              .then(res => {
                this.cancel();
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
      );
    }
  }
};