import DatatableAboAttribution from "@/components/abondement/DatatableAboAttribution";
import internalApi from "@/api/internalApi";

/**
 * Composant affichant l'abo attribution d'un associé connecté
 * 
 */

export default {
    extends: DatatableAboAttribution,
    name: "datatable-abo-attribution-espace-associe",
    data() {
        return {
            displayAffectedGrey: false,
        };
    },
    computed: {
        noDataTextMessage() {
            if (_.isNil(this.exercice) || _.isNil(this.exercice.id)){
                return "Aucun exercice n'est sélectionné.";
            }
            if (!this.exercice.abo_exercice.is_parametrage_complete){
                return `Le paramétrage de l'abondement de l'exercice (${this.exercice.libelle}) est incomplet.`;
            }
            
            return "Vous n'êtes pas concerné(e) par l'abondement de cet exercice.";
        },
    },
    methods: {
        fetchAboAttributions() {
            this.isLoading = true;

            return new Promise((resolve, reject) => {
                internalApi.aboAttribution.getAboAttributionByAboExerciceIdIndividuel(
                    this.exercice.id
                ).then(res => {
                    resolve({
                        'items': res.items,
                        'total': res.total_items,
                        'progress_bar_total': 0,
                        'progress_bar_completed': 0,
                    });
                }).catch(err => {
                    console.error("Erreur lors de la récupération des attributions d'abondement : ", err);
                    reject({
                        'items': [],
                        'total': 0,
                        'progress_bar_total': 0,
                        'progress_bar_completed': 0,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
            });
        },
    },
};