<template>
    <ValidationObserver ref="formGestionDroitsAcces" v-slot="{ reset, validate }">
        <base-layout :withHeader="false" :withFooter="true" footerSize="50px"
                                         datatableExcludedSize="200px">
            <template v-slot:main="{ datatableSize }">
                <v-container>
                    <v-row>
                        <v-col md="3" lg="3" xl="2">
                            <v-select v-model="selected_type"
                                  label="Type de droits d'accès"
                                  :items="types"
                                  item-text="text"
                                  item-value="value"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="headers"
                                :items="droits_acces"
                                :loading="loading"
                                :search="selected_type"
                                :custom-filter="filterOnSelectedType"
                                :single-expand="singleExpand"
                                :expanded.sync="expanded"
                                item-key="nom"
                                sort-by="nom"
                                :sort-desc="false"
                                fixed-header
                                class="elevation-1"
                                :height="datatableSize"
                            >
                                <template v-for="h in valeurs_possibles" v-slot:[`header.${h.value}`]>
                                    <v-tooltip v-if="h.tooltip" top :key="h.value">
                                        <template v-slot:activator="{ on }">
                                            <span class="ml-2">
                                            <span>
                                                {{ h.text }}
                                            </span>
                                                <v-icon small right v-on="{ ...on }" class="header-tooltip">
                                                    {{ "$vuetify.icons.info" }}
                                                </v-icon>
                                            </span>
                                        </template>
                                        <span v-html="h.tooltip"/>
                                    </v-tooltip>
                                    <span v-else :key="h.value">
                                        {{ h.text }}
                                    </span>
                                </template>
                                <template v-slot:item="{ item, expand, isExpanded }">
                                    <tr>
                                        <td >
                                            <v-icon-button-with-tool-tip
                                                v-if="item.children.length"
                                                :disabled="!item.children.length"
                                                @click="expand(!isExpanded)"
                                                :icon="isExpanded ? '$vuetify.icons.up_arrow' : '$vuetify.icons.down_arrow'"
                                                btnRound btnText btnSmall iconSmall tooltipBottom
                                                btnColorClass="transparent"
                                                iconColorClass="grey darken-1"
                                                :detail="isExpanded ? `Masquer ${subDetailExpendedIconText}` : `Afficher ${subDetailExpendedIconText}`"
                                            />
                                        </td>

                                        <td>{{ item.nom_affichage }}</td>
                                        <td class="text-lg-center" v-for="valeur_possible in valeurs_possibles" :key="valeur_possible.value">
                                            <v-radio-group v-model="item.valeur" class="justify-center">
                                                <v-radio :value="valeur_possible.value" class="justify-center" :disabled="!item.valeurs_autorisees.includes(valeur_possible.value)"/>
                                            </v-radio-group>
                                        </td>
                                    </tr>

                                </template>
                                <template v-slot:expanded-item="{ item }">

                                    <td :colspan="headers.length">
                                        <v-data-table
                                            :headers="headers"
                                            :items="item.children"
                                            hide-default-footer
                                            hide-default-header
                                            item-key="nom"
                                            fixed-header
                                        >
                                            <template v-slot:header="{  }">
                                                <tr>
                                                    <th
                                                        :style="{width: header.width, 'min-width': header.width}"
                                                        class="text-start sortable"
                                                        role="columnheader"
                                                        v-for="header in headers" :key="header.value">
                                                    </th>
                                                </tr>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <tr>
                                                    <td></td>
                                                    <td>{{ item.nom_affichage }}</td>
                                                    <td class="text-lg-center" v-for="valeur_possible in valeurs_possibles" :key="valeur_possible.value">
                                                        <v-radio-group v-model="item.valeur" class="justify-center">
                                                            <v-radio :value="valeur_possible.value" class="justify-center" :disabled="!item.valeurs_autorisees.includes(valeur_possible.value) || isConsultation(item.get_parent().valeur)"/>
                                                        </v-radio-group>
                                                    </td>
                                                </tr>

                                            </template>
                                        </v-data-table>
                                    </td>

                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:footer>
                <v-row justify="end" no-gutters>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      @emit-cancel="resetForm"
                                                      @emit-valid-save="saveForm"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>
<script>
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils.js";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";

    export default {
        name: "VGestionDroitsAcces",
        components: {
            BaseLayout,
            VSaveButonWithValidation,
            VIconButtonWithToolTip,
        },
        props: {},
        data() {
            return {
                types: [
                    {value: constantes.droits_acces.types.type_personnel, text: "Espace personnel"},
                ],
                selected_type: "",
                valeurs_possibles: [
                    {
                        value: constantes.droits_acces.valeurs_possibles.consultation,
                        text: "Consultation",
                        tooltip: "Les salarié(e)s/associé(e)s peuvent uniquement consulter leurs données"
                    },
                    {
                        value: constantes.droits_acces.valeurs_possibles.edition,
                        text: "Édition",
                        tooltip: "Les salarié(e)s/associé(e)s peuvent lire et modifier leurs données"
                    },
                ],
                headers: [
                    {value: "", text:"", "width": "10%", },
                    {value: "nom_affichage", text: "Entrée de menu", align: "start", "width": "70%",},
                    // Les valeurs possibles sont ajoutées automatiquement lors du mounted()
                ],
                noms_affichage: [
                    {value: constantes.droits_acces.noms.donnees_personnelles, text:"Données personnelles"},
                    {value: constantes.droits_acces.noms.espace_capital, text: "Espace capital"},
                    {value: constantes.droits_acces.noms.espace_capital_affectation_montee_capital, text:"Affectation montée au capital"},
                    {value: constantes.droits_acces.noms.espace_capital_affectation_versement_immediat, text:"Affectation versement immédiat"},
                    {value: constantes.droits_acces.noms.espace_capital_affectation_compte_courant, text:"Affectation compte courant"},
                    {value: constantes.droits_acces.noms.espace_participation, text: "Espace participation"},
                    {value: constantes.droits_acces.noms.espace_pb_affectation_montee_capital, text:"Affectation montée au capital"},
                    {value: constantes.droits_acces.noms.espace_pb_affectation_versement_immediat, text:"Affectation versement immédiat"},
                    {value: constantes.droits_acces.noms.espace_pb_affectation_supports, text: "Affectation sur supports"},
                    {value: constantes.droits_acces.noms.espace_individuel_interessement, text: "Espace intéressement"},
                    {value: constantes.droits_acces.noms.espace_inte_affectation_versement_immediat, text: "Affectation versement immédiat"},
                    {value: constantes.droits_acces.noms.espace_inte_affectation_supports, text: "Affectation sur supports"},
                    {value: constantes.droits_acces.noms.documents, text: "Documents"},
                ],
                noms_affichage_as_dict: {},
                droits_acces: [],
                droits_acces_previous_state: [],
                loading: false,
                expanded: [],
                singleExpand: false,
                subDetailExpendedIconText: "les options détaillées pour cette entrée menu"
            }
        },
        watch: {
            droits_acces: {
                handler(new_value) {
                    if (new_value.length == this.droits_acces_previous_state.length) {
                        for(let index_parent = 0; index_parent < new_value.length; index_parent ++) {
                            // Si la valeur du droit d'accès à changé et que la nouvelle valeur est "édition"
                            // Passer tous ses droits d'accès enfants en édition
                            if (new_value[index_parent].valeur != this.droits_acces_previous_state[index_parent].valeur && new_value[index_parent].valeur == constantes.droits_acces.valeurs_possibles.edition) {
                                new_value[index_parent].children.forEach(child => {
                                    child["valeur"] = constantes.droits_acces.valeurs_possibles.edition;
                                });
                            }
                            // Sinon, si la valeur du droit d'accès est "consultation" (qu'elle vienne de changer ou pas)
                            // => Passer tous ses droits d'accès enfants en consultation
                            else if (new_value[index_parent].valeur == constantes.droits_acces.valeurs_possibles.consultation) {
                                new_value[index_parent].children.forEach(child => {
                                    child["valeur"] = constantes.droits_acces.valeurs_possibles.consultation;
                                });
                            }
                        }
                    }
                    this.droits_acces_previous_state = _.cloneDeep(new_value);
                },
                deep: true,
            },
        },
        mounted() {
            this.computeNomsAffichageAsDict();
            this.getDroitsAccesFromStore();
            this.valeurs_possibles.forEach(valeur_possible => {
                this.headers.push({
                    "value": valeur_possible.value,
                    "text": valeur_possible.text,
                    "align": "center",
                    "width": "10%",
                });
            });
            this.selected_type = this.types[0].value;
        },
        methods: {
            isConsultation (valeur) {
                return constantes.droits_acces.valeurs_possibles.consultation == valeur;
            },
            computeNomsAffichageAsDict() {
                let res = {};
                this.noms_affichage.forEach(nom_affichage => {
                    res[nom_affichage.value] = nom_affichage.text;
                });
                this.noms_affichage_as_dict = res;
            },
            getDroitsAccesFromStore() {
                /*
                Rafraîchis les données liées aux droits d'accès dans le store
                puis acquère ces données depuis le store
                */
                this.loading = true;
                return this.$store.dispatch("fetchDroitsAcces").then(() => {
                    let res = [];
                    this.$store.getters.getDroitsAcces().forEach(droit_acces => {
                        droit_acces["nom_affichage"] = this.noms_affichage_as_dict[droit_acces.nom];
                        droit_acces["children"].forEach(child => {
                            child["nom_affichage"] = this.noms_affichage_as_dict[child.nom]
                            child["get_parent"] = function() {
                                return droit_acces;
                            }
                        });
                        res.push(droit_acces);
                    });
                    this.droits_acces = res;
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            filterOnSelectedType(value, search, item) {
                return item.type == search;
            },
            saveForm() {
                const flatten_droits_acces = Utils.flattenFamily(this.droits_acces);
                const flatten_old_droits_acces = Utils.flattenFamily(this.$store.getters.getDroitsAcces());
                const droits_acces_modifies = [];
                for (let i = 0; i<flatten_droits_acces.length; i++) {
                    if (flatten_droits_acces[i].id == flatten_old_droits_acces[i].id && flatten_droits_acces[i].valeur != flatten_old_droits_acces[i].valeur) {
                        delete flatten_old_droits_acces[i].type;
                        delete flatten_old_droits_acces[i].nom;
                        delete flatten_old_droits_acces[i].univers;
                        delete flatten_old_droits_acces[i].valeurs_autorisees;
                        delete flatten_old_droits_acces[i].children;
                        flatten_old_droits_acces[i].valeur = flatten_droits_acces[i].valeur;
                        droits_acces_modifies.push(flatten_old_droits_acces[i]);
                    }
                }

                const promise = internalApi.gestion_droits_acces.putAllWithToastConfirmation(droits_acces_modifies)
                .finally(() => {
                    this.getDroitsAccesFromStore();
                });

                return promise;
            },
            resetForm() {
                this.getDroitsAccesFromStore();
            },
        },
    }
</script>