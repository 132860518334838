<template>

  <v-text-field
        name="password"
        v-model="password"
        prepend-icon="lock"
        :append-icon="showPassword ? '$vuetify.icons.eye' : '$vuetify.icons.eyeSlash'"
        :label="label"
        :type="showPassword ? 'text' : 'password'"
        @click:append="toggleShowPassword"
        :error-messages="errorMessages"
        :success="success"
        />

</template>

<script>
export default {
  name: "VPasswordVisualiser",
  props: {
    value: {
      twoWay: true,
      required: true
    },
    label:{
      type: String,
      default: 'Mot de passe'
    },
    errorMessages: {
      type: Array,
      required: false,
    },
    success: {
      type: Boolean,
      required: false
    },
    prependIcon: {
      type: String,
      reguierd: false
    }
    
    
  },

  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    toggleShowPassword() {
        this.showPassword = !this.showPassword
    },
  },
  computed: {
    password: {
      get: function () {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    }
  }

  
};
</script>