<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="espaceassocie" :withFooter="false" headerSize="60px">
        <template v-slot:header>
            <v-tabs v-model="currentTab">
                <v-tab key="graphiques">Portefeuille associé(e)</v-tab>
                <v-tab key="mouvements">Historique des mouvements</v-tab>
                <v-tab key="interets">Intérêts aux parts sociales</v-tab>
                <v-tab key="abondement" :disabled="!hasAbondementExtensionAccess">Abondement</v-tab>
                <v-tab key="scic" :disabled="!hasScicExtensionAccess">Scic</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="graphiques">
                    <portefeuille-espace-associe ref="portefeuilleAssocie"/>
                </v-tab-item>
                <v-tab-item key="mouvements">
                    <mouvements-espace-associe ref="mouvementsAssocie"/>
                </v-tab-item>
                <v-tab-item key="interets">
                    <repartition-cap-espace-associe
                        ref="repartitionCapAssocie"/>
                </v-tab-item>
                <v-tab-item key="abondement">
                    <v-abondement-espace-associe v-if="hasAbondementExtensionAccess"/>
                </v-tab-item>
                <v-tab-item key="scic">
                    <v-scic-espace-associe v-if="hasScicExtensionAccess"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import PortefeuilleEspaceAssocie from "@/views/capital/espaceAssocie/PortefeuilleEspaceAssocie.vue"
    import MouvementsEspaceAssocie from "@/views/capital/espaceAssocie/MouvementsEspaceAssocie.vue"
    import RepartitionCapEspaceAssocie from "@/views/capital/espaceAssocie/RepartitionCapEspaceAssocie.vue"
    import authService from "@/services/authService";
    import BaseLayout from "@/components/BaseLayout";
    import VScicEspaceAssocie from "@/views/scic/espaceAssocieScic/VScicEspaceAssocie";
    import VAbondementEspaceAssocie from "@/components/abondement/VAbondementEspaceAssocie"

    /**
     * Vue affichant l'espace personnel côté capital
     * @displayName Capital - EspaceAssocie
     */
    export default {
        components: {
            VScicEspaceAssocie,
            BaseLayout,
            PortefeuilleEspaceAssocie,
            MouvementsEspaceAssocie,
            RepartitionCapEspaceAssocie,
            VAbondementEspaceAssocie,
        },
        data() {
            return {
                currentTab: undefined,
            };
        },
        computed: {
            collaborateurId: function () {
                return authService.getIdAssocie();
            },
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
            hasAbondementExtensionAccess() {
                return authService.checkHasAbondementExtensionAccess();
            }
        },
        mounted() {
            try {
                this.currentTab = parseInt(this.$route.params.activetab);
            } catch (err) {
                console.error("Erreur de parsing d'int sur l'espace associé");
            }
        },
    };
</script>
