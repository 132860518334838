<template>
    <v-container>
        <v-data-table :search="search"
                      :footer-props="footerProps"
                      :headers="headers"
                      :items="periodesTravail"
                      :loading="loadingPeriodes"
                      :server-items-length="total_items"
                      :height="datatableSize"
                      fixed-header
                      class="elevation-1"
                      no-data-text="Aucune période."
                      ref="liste_periodes"
                      :options.sync="options">
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{item.salarie_name}}</td>
                    <td>{{item.salarie_numero_tiers}}</td>
                    <td>{{item.date_debut | dateIsoToFrFilter}}</td>
                    <td>{{item.date_fin | dateIsoToFrFilter}}</td>
                    <td>{{getLabelFromMotif(item.motif_fin_contrat)}}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import internalApi from "@/api/internalApi";

    export default {
        name: "VDatatablePeriodesTravail",
        props: {
            exercice: {
                type: Object,
                required: true
            },
            datatableSize: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {text: "Salarié", sortable: true, value: "salarie_name", align: "left"},
                    {text: "Numéro de tiers", sortable: true, value: "salarie_numero_tiers", align: "left"},
                    {text: "Date début", sortable: true, value: "date_debut", align: "left"},
                    {text: "Date fin", sortable: true, value: "date_fin", align: "left"},
                    {text: "Motif de fin", sortable: false, value: "motif_fin_contrat", align: "left"}
                ],
                labels_motif_fin_contrat: undefined,
                periodesTravail: undefined,
                loadingPeriodes: true,
                options: {
                    sortBy: ['salarie_name'],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                total_items: -1,
                search: ""
            }
        },
        watch: {
            options: {
                handler() {
                    this.getAllPeriodesTravailSync();
                },
                deep: true
            },
        },
        mounted() {
            this.getLabelsMotif();
            this.getAllPeriodesTravailSync();
        },
        methods: {
            /**
             * Returns the readable label from a motif_fin_contrat key
             */
            getLabelFromMotif(key) {
                if (this.labels_motif_fin_contrat !== undefined) {
                    return this.labels_motif_fin_contrat[key];
                } else {
                    return key;
                }
            },
            /**
             * Récupère les motifs de fin de contrat possibles pour le champ à choix
             * multiples
             */
            getLabelsMotif() {
                return internalApi.periodeTravail.getLabels().then(result => {
                    this.labels_motif_fin_contrat = result.motif_fin_contrat;
                });
            },
            /**
             * Récupère les périodes de travail par pagination
             */
            getAllPeriodesTravailSync() {
                this.getAllPeriodesTravail().then(data => {
                    this.periodesTravail = data.items;
                    this.total_items = data.total;
                }).catch(rejected => {
                    this.periodesTravail = rejected.items;
                    this.total_items = rejected.total;
                });
            },
            /**
             * Récupère les périodes de travail des salariés valides entre la date de début
             * et la date de fin de l'exercice
             */
            getAllPeriodesTravail() {
                this.loadingPeriodes = true;

                return new Promise((resolve, reject) => {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.options;

                    internalApi.periodeTravail.getPeriodesTravailInPeriode(
                        this.exercice.date_debut,
                        this.exercice.date_fin,
                        page,
                        itemsPerPage,
                        sortBy[0],
                        sortDesc[0],
                    ).then(result => {
                        let items = result.periodes_travail;
                        const total = result.total_items;
                        resolve({
                            'items': items,
                            'total': total,
                        });
                    }).catch(err => {
                        console.error("Erreur lors de la récupération des périodes de travail : ", err);
                        let items = [];
                        const total = 0;
                        reject({
                            'items': items,
                            'total': total,
                        });
                    }).finally(() => {
                        this.loadingPeriodes = false;
                    });

                });
            },
        }
    }
</script>