import {apiConstructor} from "@/api";
import Utils from "@/utils";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default controleStatusApi;

function controleStatusApi() {
  return {
    getCapitalSuperieurPlancher: getCapitalSuperieurPlancher(),
    getCapitalSuperieurQuartCapitalMax: getCapitalSuperieurQuartCapitalMax(),
    getCapitalSalariesExSalariesSuperieurMoitieCapital: getCapitalSalariesExSalariesSuperieurMoitieCapital(),
    getCapitalAssocieInferieurMoitieCapital: getCapitalAssocieInferieurMoitieCapital(),
    checkDroitsVoteAssocieExterneHorsCoop: checkDroitsVoteAssocieExterneHorsCoop(),
    checkDroitsVoteAssocieExterneDontCoop: checkDroitsVoteAssocieExterneDontCoop()
  };
}

function getCapitalSuperieurPlancher() {
  function getCapitalSuperieurPlancher(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    queryParams.company_id = company_id;
    let path = internalApiRoutes.controleStatus.capitalSuperieurPlancher();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getCapitalSuperieurPlancher;
}

function getCapitalSuperieurQuartCapitalMax() {
  function getCapitalSuperieurQuartCapitalMax(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.controleStatus.capitalSuperieurQuartCapitalMax();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getCapitalSuperieurQuartCapitalMax;
}

function getCapitalSalariesExSalariesSuperieurMoitieCapital() {
  function getCapitalSalariesExSalariesSuperieurMoitieCapital(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.controleStatus.capitalSalariesExSalariesSuperieurMoitieCapital();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getCapitalSalariesExSalariesSuperieurMoitieCapital;
}

function getCapitalAssocieInferieurMoitieCapital() {
  function getCapitalAssocieInferieurMoitieCapital(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.controleStatus.capitalAssocieInferieurMoitieCapital();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getCapitalAssocieInferieurMoitieCapital;
}

function checkDroitsVoteAssocieExterneHorsCoop() {
  function getCheckDroitsVoteAssocieExterneHorsCoop(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.controleStatus.checkDroitsVoteAssocieExterneHorsCoop();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getCheckDroitsVoteAssocieExterneHorsCoop;
}

function checkDroitsVoteAssocieExterneDontCoop() {
  function getCheckDroitsVoteAssocieExterneDontCoop(date, company_id, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.controleStatus.checkDroitsVoteAssocieExterneDontCoop();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getCheckDroitsVoteAssocieExterneDontCoop;
}