import internalApi from "@/api/internalApi";
import VDocumentAbstract from "./VDocumentAbstract";
import constantes from "@/utils/constantes";

export default {
    name: "VDocumentPbAccord",
    abstract: false,
    extends: VDocumentAbstract,

    computed: {
        infoBulleContent: function() {
            return "Ce document est partagé avec tous les bénéficiaires";
        },
        documentTitle: function() {
            return "Accord de participation";
        },
        typeDocument: function() {
            return "PbAccord";
        },
        fileName: function() {
            return "accord_de_participation.pdf";
        },
    },
    methods: {
        uploadFile(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.pbCompany.createPbAccordDocument(formData)
                .then(res => {
                    this.loadDocument().then(result => {
                        this.$emit("new-uploaded-doc", res);
                    });
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
        },
        loadDocument: function () {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.pbCompany.getCurrentPbCompany()
                .then(res => {
                    this.documentId = res.doc_accord_id;
                    this.companyUnivId = res.id;
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });

        },

        /**
         * Update before deleting doc
         */
        
        unlinkDocument: function () {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
            return internalApi.pbCompany.deleteDocumentAccord(this.companyUnivId, {"doc_accord_id": null})
                .then(res => {
                    this.loadDocument().then(result => {
                        this.$emit("on-delete-document");
                    });
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
        },    
    }

}    
