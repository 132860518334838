var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formReglementDette",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('base-layout',{attrs:{"headerSize":"50px"}},[_c('template',{slot:"header"},[_c('h1',[_vm._v(" Règlement ")])]),_c('template',{slot:"main"},[_c('loading-screen',{ref:"loadingComponent"}),_c('br'),_c('v-subheader',[_c('h2',[_vm._v(" Bénéficiaire ")])]),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"associe_name","name":"Bénéficiaire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bénéficiaire","error-messages":errors,"disabled":true},model:{value:(_vm.associe_name),callback:function ($$v) {_vm.associe_name=$$v},expression:"associe_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"5","lg":"5","xl":"5"}},[_c('v-checkbox',{attrs:{"label":"Bénéficiaire autre que l'ayant droit"},on:{"change":function($event){return _vm.clearFieldIfNoBeneficiaireDifferent()}},nativeOn:{"click":function($event){return _vm.removeValidatonErrorsFromDenominationTiers.apply(null, arguments)}},model:{value:(_vm.nouveau_reglement.beneficiaire_different),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "beneficiaire_different", $$v)},expression:"nouveau_reglement.beneficiaire_different"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"md":"5","lg":"5","xl":"5"}},[_c('ValidationProvider',{ref:"denomination_tiers_provider",attrs:{"vid":"denomination_tiers","name":"Dénomination tiers","rules":_vm.nouveau_reglement.beneficiaire_different ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Dénomination tiers","disabled":!_vm.nouveau_reglement.beneficiaire_different,"error-messages":errors},model:{value:(_vm.nouveau_reglement.denomination_tierce),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "denomination_tierce", $$v)},expression:"nouveau_reglement.denomination_tierce"}})]}}],null,true)})],1)],1)],1),_c('br'),_c('v-subheader',[_c('h2',[_vm._v(" Règlement de la dette ")])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"date_reglement","name":"Date du paiement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{attrs:{"label":"Date du paiement","name":"date_reglement","errors":errors},model:{value:(_vm.nouveau_reglement.date_reglement),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "date_reglement", $$v)},expression:"nouveau_reglement.date_reglement"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"montant_reglement","name":"Montant du paiement","rules":'required|currency|min_value:0|max_value:' + _vm.restant_du.toString()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Montant du paiement","errors":errors,"prefixValue":"€","reverseValue":true},model:{value:(_vm.nouveau_reglement.montant_reglement),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "montant_reglement", $$v)},expression:"nouveau_reglement.montant_reglement"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"5","lg":"5","xl":"5"}},[_c('v-formatted-number-text-field',{attrs:{"labelValue":"Restant dû","prefixValue":"€","reverseValue":true,"disabledValue":true},model:{value:(_vm.restant_du),callback:function ($$v) {_vm.restant_du=$$v},expression:"restant_du"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","lg":"3","xl":"2"}},[_c('v-btn',{attrs:{"text":"","color":"primary","block":""},on:{"click":function($event){return _vm.solderDette()}}},[_vm._v(" Solder la dette")])],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"type_reglement","name":"Type de paiement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.choixTypeReglement,"label":"Type de paiement","item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.nouveau_reglement.type_reglement),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "type_reglement", $$v)},expression:"nouveau_reglement.type_reglement"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"5","lg":"5","xl":"5"}},[_c('v-text-field',{attrs:{"label":"Commentaire"},model:{value:(_vm.nouveau_reglement.commentaire),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "commentaire", $$v)},expression:"nouveau_reglement.commentaire"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"vid":"reference_reglement","name":"Référence du paiement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Référence du paiement","error-messages":errors},model:{value:(_vm.nouveau_reglement.reference_reglement),callback:function ($$v) {_vm.$set(_vm.nouveau_reglement, "reference_reglement", $$v)},expression:"nouveau_reglement.reference_reglement"}})]}}],null,true)})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors,"showToast":false},on:{"emit-cancel":_vm.retourDetailDette,"emit-valid-save":_vm.submit}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }