var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-screen',{ref:"loadingComponent"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isIcon)?_c('v-icon-button-with-tool-tip',{attrs:{"detail":_vm.tooltipDetailMessage,"icon":_vm.iconValue,"activator":on,"btnIsIcon":"","btnRound":"","btnText":"","btnSmall":"","iconSmall":"","tooltipBottom":"","btnColorClass":"transparent","iconColorClass":"grey darken-1","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick.apply(null, arguments)}}}):_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","block":""},on:{"click":_vm.onButtonClick}},Object.assign({}, on, _vm.activator)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.txt"))]),_vm._v(" "+_vm._s(_vm.openButtonMessage)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 center"},[_c('v-row',{staticClass:"ma-n1",attrs:{"justify":"start"}},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info")+" ")])]}}])},[_c('span',[_vm._v(" La télédéclaration du CERFA 2561 nécessite un traitement en trois étapes : nommage, compression et chiffrage. "),_c('br'),_vm._v(" Seul le fichier chiffré est attendu par l’administration fiscale, cependant vous pouvez librement accéder aux fichiers intermédiaires. "),_c('br'),_vm._v(" Deux clefs de chiffrage sont proposées, l'une pour tester votre fichier, l'autre pour le dépôt définitif. ")])])],1)],1),_c('v-card-text',[(_vm.dialog)?_c('ValidationObserver',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"anneeTeletransmisson","name":"anneeTeletransmisson"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-years-list',{attrs:{"label":"Année de règlement des intérêts aux parts sociales","errors":errors},model:{value:(_vm.anneeTeletransmisson),callback:function ($$v) {_vm.anneeTeletransmisson=$$v},expression:"anneeTeletransmisson"}})]}}],null,false,2353937892)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"versionFichier","name":"Version du fichier","rules":"required|numeric|max:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Version du fichier","errors":errors},model:{value:(_vm.versionFichier),callback:function ($$v) {_vm.versionFichier=$$v},expression:"versionFichier"}})]}}],null,false,65673908)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"typeFichier","name":"Type de fichier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.selectTypesFichiers,"label":"Type de fichier","item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.typeFichier),callback:function ($$v) {_vm.typeFichier=$$v},expression:"typeFichier"}})]}}],null,false,1770984412)})],1)],1),(_vm.typeFichier == 'encrypted_file')?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"typeClefGnupg","name":"Clef de chiffrement","rules":"required"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Chiffrer le fichier pour un import en environnement de :")]),_c('v-radio-group',{staticClass:"mt-1",model:{value:(_vm.typeClefGnupg),callback:function ($$v) {_vm.typeClefGnupg=$$v},expression:"typeClefGnupg"}},[_c('v-radio',{attrs:{"value":"production","label":"Production","disabled":!_vm.isProductionKeyPresent}}),_c('v-radio',{attrs:{"value":"test","label":"Test","disabled":!_vm.isTestKeyPresent}})],1)],1)],1)],1):_vm._e()],1):_vm._e()],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{staticClass:"mr-2",attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.closePopUp}},[_vm._v(_vm._s(_vm.labelAnnulation))])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.onSetDataTeletransmission}},[_vm._v(_vm._s(_vm.labelConfirmation))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }