var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.isAssocieByDates && !_vm.isAssocieByCapital)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"orange--text"},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.squareCheckQuestion")+" ")])]}}],null,false,1509152667)},[_c('span',[_vm._v("Ce tiers est associé(e) actif(ve) à la date du jour mais ne possède pas de capital")])]):(_vm.isAssocieByCapital && !_vm.isAssocieByDates)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"orange--text"},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.squareCheckQuestion")+" ")])]}}])},[_c('span',[_vm._v("Ce tiers est associé(e) actif(ve) à la date du jour car possède toujours du capital")])]):(_vm.isAssocie)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.squareCheck")+" ")])]}}])},[_c('span',[_vm._v("Ce tiers est associé(e) actif(ve) à la date du jour")])]):(_vm.showCrossIfNotAssocie)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, on)),[_vm._v(" "+_vm._s("$vuetify.icons.squareCheckCross")+" ")])]}}])},[_c('span',[_vm._v("Ce tiers n'est pas associé(e) actif(ve) à la date du jour")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }