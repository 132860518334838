import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";
import defaultApi from "../defaultApi";

export default tauxMoyenRendementObliApi;

function tauxMoyenRendementObliApi() {
    const specificApi = {
    deleteTauxMoyenRendementObliById: deleteTauxMoyenRendementObliById(),
    getTauxRemuProposition: getTauxRemuProposition(),
    getTauxRemuDetailsProposition: getTauxRemuDetailsProposition(),
    getTauxRemuPropositionValide: getTauxRemuPropositionValide(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.tauxMoyenRendementObli.baseUrl));

}

function deleteTauxMoyenRendementObliById() {
  function deleteTauxMoyenRendementObliById(id, queryParams, headers) {
    let path = internalApiRoutes.tauxMoyenRendementObli.tauxMoyenRendementObliById(id);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deleteTauxMoyenRendementObliById;
}

function getTauxRemuProposition() {
  function getTauxRemuProposition(date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.tauxMoyenRendementObli.getTauxRemuProposition();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getTauxRemuProposition;
}

function getTauxRemuDetailsProposition() {
  function getTauxRemuDetailsProposition(date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.tauxMoyenRendementObli.getTauxRemuDetailsProposition();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getTauxRemuDetailsProposition;
}

function getTauxRemuPropositionValide() {
  function getTauxRemuPropositionValide(date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.tauxMoyenRendementObli.getTauxRemuPropositionValide();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getTauxRemuPropositionValide;
}
