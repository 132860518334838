// <!-- VUETIFY2 - OK -->
import VDatatableDeblocages from "./VDatatableDeblocages";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import constantes from "@/utils/constantes";

export default {
  extends: VDatatableDeblocages,
  name: "v-datatable-deblocages-espace-beneficiaire",
  data() {
    return {
      isEspaceBeneficiaire: true,
    };
  },
  methods: {
    getDeblocages(exerciceId, search) {
      this.saveFiltre(exerciceId, search);
      this.loading = true;

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

        let queryParam = {
          page: page,
          rows_per_page: itemsPerPage,
          sort_key: sortBy[0],
          descending: sortDesc[0],
          exercice_id: exerciceId
        };

        internalApi.deblocage.getDeblocagesIndividuel(queryParam)
          .then(res => {
            let items = res.deblocages;
            const total = res.total_items;
            resolve({
              'items': items,
              'deletable_deblocages_ids': [],
              'total': total,
            });
          })
          .catch(err => {
            console.log(err);
            reject({
              'items': [],
              'deletable_deblocages_ids': [],
              'total': 0,
            });
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    /**
     * Télécharge le bulletin associé au déblocage
     */
     downloadBulletinDeblocage(deblocageId) {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
      internalApi.deblocage.downloadBulletinDeblocageIndividuel(deblocageId)
          .then(response => {
              Utils.downloadFile(response, "bulletin_deblocage.pdf", Utils.pdfMimetype);
          })
          .catch(err => {
              console.error(err);
          }).finally(() => {
          this.$refs.loadingComponent.resetLoading();
      });
  }
  }
};