import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default mouvementApi;

function mouvementApi() {
  const specificApi = {
    deleteMouvementById: deleteMouvementById(),
    getMvtsAssocie: getMvtsAssocie(),
    getAll: getAll(),
    importMouvementsPartsSociales: importMouvementsPartsSociales(),
    exportMouvementsPartsSociales: exportMouvementsPartsSociales(),
    importBulletinsMasse: importBulletinsMasse(),
    exportBulletinsMasse: exportBulletinsMasse(),
    downloadTotaliteMouvementsExcel: downloadTotaliteMouvementsExcel(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.mouvements, [
    ACCEPTED_METHODS.getLabels
  ]));
}

function deleteMouvementById() {
  function deleteMvt(id, queryParams, headers) {
    let path = internalApiRoutes.deleteMouvementById(id);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deleteMvt;
}

function getMvtsAssocie() {
  function mvtsAssocie(queryParams, headers) {
    let path = internalApiRoutes.getMvtsAssocie();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return mvtsAssocie;
}


function getAll() {
  function getAllMouvements(queryParams, headers) {
    let path = internalApiRoutes.mouvements;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAllMouvements;
}


function importMouvementsPartsSociales() {
  function importMouvementsPartsSociales(body, queryParams, headers) {
    let path = internalApiRoutes.importMouvementsPartsSociales();
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return importMouvementsPartsSociales;
}

function exportMouvementsPartsSociales() {
  function exportMouvementsPartsSociales(body, queryParams, headers) {
    let path = internalApiRoutes.exportMouvementsPartsSociales();
    let getForPath = apiConstructor.get(path, body);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return exportMouvementsPartsSociales;
}


function importBulletinsMasse() {
  function importBulletinsMasse(body, queryParams, headers) {
    let path = internalApiRoutes.importBulletinsMasse();
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return importBulletinsMasse;
}


function exportBulletinsMasse() {
  function exportBulletinsMasse(queryParams, headers) {
    let path = internalApiRoutes.exportBulletinsMasse();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return exportBulletinsMasse;
}

function downloadTotaliteMouvementsExcel() {
  function downloadTotaliteMouvementsExcel(queryParams, headers) {
    let path = internalApiRoutes.downloadTotaliteMouvementsExcel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return downloadTotaliteMouvementsExcel;
}