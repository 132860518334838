<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid fill-height class="pa-0">
        <loading-screen ref="loadingComponent"/>
        <Header @toggle-navigation="toggleNavigation()"/>
        <Menu ref="menuComponent" :universe="universe" :universeTitle="universeTitle"/>
        <v-main class="fill-height">
            <router-view/>
        </v-main>
    </v-container>
</template>

<script>
    import Header from "@/components/Header.vue";
    import Menu from "@/components/Menu.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import authService from "../services/authService";

    export default {
        name: "MainAppView",
        components: {
            Header,
            Menu,
            loadingScreen,
        },
        props: ['universe', 'universeTitle'],
        methods: {
            toggleNavigation: function () {
                this.$refs.menuComponent.toggleNavigation();
            }
        }
    };
</script>
