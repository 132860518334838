<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid>
        <loading-screen ref="loadingComponent"/>
        <v-row justify="space-between" align="center">
            <v-col xl="11">
                <h2>{{ univers }}</h2>
            </v-col>
            <v-col cols="1" v-if="canUpload">
                <v-import-export-file detail="Importer un document"
                               :isIcon="true"
                               :typesFile="getTypesExtensionsFiles().pdf"
                               @import-file="function(formData){uploadFile(formData)}"
                               :allowedExport="false"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :headers="headers"
                              :items="documentsList"
                              :options.sync="pagination"
                              fixed-header
                              :height="height"
                              :no-data-text="computeNoDataText()">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-icon>{{'$vuetify.icons.pdf'}}</v-icon>
                            </td>
                            <td>{{ item.titre_document }}</td>
                            <td>{{ item.date_ajout | dateIsoToFrFilter }}</td>
                            <td class="text-lg-center">
                                <v-row align="center">
                                    <v-col md="3" lg="3" xl="3">
                                        <v-icon-button-with-tool-tip
                                            detail="Télécharger le document"
                                            :icon="'$vuetify.icons.download'"
                                            btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                            btnColorClass="transparent"
                                            iconColorClass="grey darken-1"
                                            @click.stop="downloadDocument(item)"
                                        />
                                    </v-col>
                                    <v-col md="3" lg="3" xl="3" v-if="canDelete">
                                        <v-custom-confirmation-dialog
                                            :message="buildConfirmationMessage(item.titre_document)"
                                            :isIcon="true"
                                            :iconValue="'$vuetify.icons.delete'"
                                            @action-confirmee="onDeleteDocument(item.id)"
                                            tooltipDetailMessage="Supprimer le document"/>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VImportExportFile from "@/components/VImportExportFile";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import internalApi from "@/api/internalApi";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils";

    /**
     * Composant affichant sous forme de datatable les documents d'un certain type du tiers.
     * Si autorisé le tiers peut les supprimer ou en upload des nouveaux
     * @displayName Commun - VDocumentsList
     */
    export default {
        components: {
            VImportExportFile,
            VCustomConfirmationDialog,
            VIconButtonWithToolTip,
            loadingScreen
        },
        props: {
            /**
             * La liste des documents sous forme d'une liste d'objets
             */
            documentsList: {
                type: Array,
                required: true,
            },
            /**
             * L'identifiant du collaborateur à qui appartient les documents
             */
            collaborateurId: {
                required: true
            },
            /**
             * L'univers affiché par la datable
             */
            univers: {
                type: String,
                required: false,

            },
            /**
             * Autorise ou non la suppression de documents
             */
            canDelete: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * Autorise ou non l'upload de documents
             */
            canUpload: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * Le type des documents listés
             */
            typeDocument: {
                type: String,
                required: true
            },
            /**
             * Autorise l'accès à cet écran, sinon ne charge aucune donnée
             */
            hasAccess: {
                type: Boolean,
                required: true,
            },
            /**
             * Si les documents sont ceux de la personne actuellement connectée,
             * collaborateurId devra être setté si areDocumentsPersonnels == false
             */
            areDocumentsPersonnels: {
                type: Boolean,
                required: false,
                default: false,

            },
            height: String
        },
        data() {
            return {
                headers: [
                    {
                        text: "",
                        value: "icone",
                        width: "10%"
                    },
                    {
                        text: "Titre du document",
                        value: "titre_document",
                        width: "60%"
                    },
                    {
                        text: "Date d'ajout",
                        value: "date_ajout",
                        width: "20%"
                    },
                    {
                        text: "Actions",
                        value: "actions",
                        width: "10%"
                    }
                ],
                pagination: {
                    sortBy: ['titre_document'],
                    sortDesc: [true]
                },
                formData: new FormData()
            };
        },
        methods: {
            /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            /**
             * Supprime le document via son identifiant. Emet un évènement on-delete-document
             * si succès
             * @param docId l'identifiant du document à supprimer
             */
            onDeleteDocument: function (docId) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
                internalApi.documents.deleteByIdWithToastConfirmation(docId)
                    .then(res => {
                        this.$refs.loadingComponent.resetLoading();
                        this.$emit("on-delete-document");
                    })
                    .catch(err => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Créer un message de confirmation basé sur le nom du document
             * @param docName le nom du docuemnt
             * @returns {string} le message créé
             */
            buildConfirmationMessage(docName) {
                let message = "Etes vous sûr de vouloir supprimer ce document : ";
                message += docName;
                message += " ?";
                return message
            },
            /**
             * Récupère le fichier via son identifiant et le télécharge pour
             * l'utilisateur
             * @param item objet contenant l'identifiant du fichier à télécharger
             * et son titre
             */
            downloadDocument(item) {
                let promise;
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);

                if (this.areDocumentsPersonnels) {
                    promise = internalApi.documents.downloadDocPersonnel(item.id);
                } else {
                    promise = internalApi.documents.downloadDoc(item.id);
                }

                promise.then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, item.titre_document, Utils.pdfMimetype);
                }).catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Upload un document sur le serveur. Emet un évènement new-uploaded-doc si succès.
             */
            uploadFile(formData) {
                let promise;
                if (this.areDocumentsPersonnels) {
                    promise = internalApi.documents.uploadDocPersonnel(this.typeDocument, formData);
                } else {
                    promise = internalApi.documents.uploadDoc(this.collaborateurId, this.typeDocument, formData);
                }
                promise.then(res => {
                    this.$emit("new-uploaded-doc");
                }).catch(err => {
                    console.log(err);
                });
                return promise;
            },
            /**
             * Enregistre le fichier choisi par l'utilisateur
             * @param file
             */
            onFileChange(file) {
                if (file != null) {
                    this.formData.set("file", file, file.name);
                    this.uploadFile();
                }
            },
            computeNoDataText() {
                if (this.hasAccess){
                    return "Aucun document.";
                }else{
                    return "Nécessite l'accès à l'univers " + this.univers;
                }
            }
        }
    };
</script>
