<template>
    <base-layout :withHeader="false">
        <template v-slot:main="{ datatableSize }">
            <v-data-table
                :headers="headers"
                :items="tauxFiscaliteList"
                no-data-text="Aucun taux de réduction d'impôts enregistré."
                class="elevation-1"
                :height="datatableSize"
                fixed-header
                :options.sync="pagination"
            >
                <template v-slot:item="{item}">
                    <tr>
                        <td class="text-lg-left">{{ item.date_debut | dateIsoToFrFilter }}</td>
                        <td class="text-lg-left">{{ item.taux_reduction_impots | roundPercentFilter }}</td>
                        <td class="text-lg-left">{{ item.date_fin | dateIsoToFrFilter }}</td>
                        <td class="text-lg-left" v-if="item.is_last">
                            <v-custom-confirmation-dialog
                                :message="'Etes vous sûr de vouloir annuler ce taux ?'"
                                :isIcon="true"
                                :iconValue="'$vuetify.icons.delete'"
                                @action-confirmee="deleteTauxFiscalite(item.id)"
                                tooltipDetailMessage="Supprimer le taux"/>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
        <template v-slot:footer>
            <v-row no-gutters>
                <v-col md="4" lg="4" xl="4">
                    <v-scic-new-taux-fiscalite @on-taux-reduction-impot-created="getTauxFiscalite()"/>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
import BaseLayout from "@/components/BaseLayout";
import internalApi from "@/api/internalApi";
import VScicNewTauxFiscalite from "@/components/scic/tauxFiscalite/VScicNewTauxFiscalite.vue";

export default {
    name: "VScicTauxFiscalite",
    components: {
        VCustomConfirmationDialog,
        BaseLayout,
        VScicNewTauxFiscalite
    },
    data() {
        return {
            tauxFiscaliteList: undefined,
            headers: [
                {text: "Date de début", value: "date_debut", sortable: true},
                {text: "Taux de réduction d'impôts", value: "taux_reduction_impots", sortable: false},
                {text: "Date de fin", value: "date_fin", sortable: false},
                {text: "Actions"},
            ],
            pagination: {
                sortBy: ['date_debut'],
                sortDesc: [true]
            },
        }
    },
    mounted() {
        this.getTauxFiscalite();
    },
    methods: {
        getTauxFiscalite() {
            internalApi.ScicTauxFiscalite.getAll()
                .then(result => {
                    this.tauxFiscaliteList = result;
                });
        },
        deleteTauxFiscalite(tauxFiscaliteId) {
            internalApi.ScicTauxFiscalite.deleteByIdWithToastConfirmation(tauxFiscaliteId)
                .then(result => {
                    this.getTauxFiscalite();
                });
        }
    }
}
</script>