<!-- VUETIFY2 - OK -->
<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="1" lg="1" xl="1">
                        <v-icon large v-if="checkListStateIsSuccess" color="success">{{ "$vuetify.icons.doubleSuccess" }}
                        </v-icon>
                        <v-icon large v-else-if="checkListStateIsWarning" color="warning">{{ "$vuetify.icons.warning" }}
                        </v-icon>
                        <v-icon large v-else-if="checkListStateIsError" color="error">{{ "$vuetify.icons.error" }}
                        </v-icon>
                        <v-progress-circular v-else indeterminate/>
                    </v-col>
                    <v-col md="9" lg="9" xl="9">
                        <span class="title">{{ title }}</span>
                    </v-col>
                    <v-col md="1" lg="1" xl="1">
                        <v-chip v-if="chipMessage" class="font-weight-bold" text-color="white" color="error">
                            {{ chipMessage }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-for="toCheck in toCheckList" :key="toCheck.title">
                    <conformite-line :checkTitle="toCheck.title" :checkState="toCheck.state"
                                    :checkMessage="toCheck.message"/>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import ConformiteLine from "@/views/capital/conformite/ConformiteLine";
    import {countBy} from "lodash";

    const StatusEnum = Object.freeze({
        ERROR: "ERROR",
        SUCCESS: "SUCCESS",
        WARNING: "WARNING"
    });

    /**
     * Composant affichant une règle de conformité
     * @displayName Capital - Conformité header
     */
    export default {
        components: {
            ConformiteLine
        },
        props: {
            /**
             * Résumé de la règle
             */
            title: {
                type: String,
                required: true
            },
            /**
             * Liste de détails (sous-règles) pour la règle
             */
            toCheckList: {
                type: Array,
                required: true
            }
        },
        watch: {
            stateCounter() {
                return countBy(this.toCheckList, "state");
            }
        },
        computed: {
            stateCounter() {
                return countBy(this.toCheckList, "state");
            },
            errorCounter() {
                return this.stateCounter.false;
            },
            successCounter() {
                return this.stateCounter.true;
            },
            chipMessage() {
                let msg = "";
                if (this.errorCounter > 0) {
                    msg = this.errorCounter + " erreur";
                }
                if (this.errorCounter > 1) {
                    msg += "s";
                }
                return msg;
            },
            checkListStateIsError() {
                return this.checkListStateIs(StatusEnum.ERROR);
            },
            checkListStateIsWarning() {
                return this.checkListStateIs(StatusEnum.WARNING);
            },
            checkListStateIsSuccess() {
                return this.checkListStateIs(StatusEnum.SUCCESS);
            }
        },
        methods: {
            checkListStateIs(expectedState) {
                let currentState = "";
                if (this.errorCounter === this.toCheckList.length) {
                    currentState = StatusEnum.ERROR;
                } else if (this.errorCounter > 0) {
                    currentState = StatusEnum.WARNING;
                } else if (this.successCounter === this.toCheckList.length) {
                    currentState = StatusEnum.SUCCESS;
                }
                return currentState === expectedState;
            }
        }
    };
</script>
