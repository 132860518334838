<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
                <v-icon-button-with-tool-tip v-if="isIcon"
                                             @click.stop="onButtonClick"
                                             :detail="tooltipDetailMessage"
                                             :icon="iconValue"
                                             :activator="on"
                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                             btnColorClass="transparent"
                                             iconColorClass="grey darken-1"
                                             block
                />
                <v-btn text v-else v-on="{...on, ...activator}" @click="onButtonClick" color="primary" dark block>{{openButtonMessage}}
                    <v-icon right dark>{{"$vuetify.icons.link"}}</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline grey lighten-2 center">
                    <v-row justify="start" class="ma-n1">{{title}}</v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="ma-n1">
                            Ce lien vous emmène vers&nbsp;:&nbsp;<i>{{  link  }}</i><br>
                            Êtes-vous sûr(e) de vouloir sortir de l'application EZISCOP ?
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters justify="end">
                        <!-- Bouton modal -->
                        <v-col class="mr-2" cols="4">
                            <v-btn color="primary" @click="closePopUp" block>NON, ABANDONNER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="4">
                            <v-btn color="primary" @click="openExternalLink" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import _ from "lodash";

    export default {
        name: "v-dialog-external-link",
        components: {
            VIconButtonWithToolTip
        },
        $_veeValidate: {
            validator: 'new'
        },
        props: {
            /**
             * Url du lien externe
             */
            link: {
                type: String,
                required: true,
            },
            /**
             * Errors from the other form
             */
            externalErrorsList: {
                type: Object,
                required: false
            },
            /**
             * Show errors of the other form
             */
            showToast: {
                type: Boolean,
                default: true
            },
            activator: {
                type: Object,
                required: false,
                default: undefined
            },
            openButtonMessage: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
                default: "ATTENTION !"
            },
            labelAnnulation: {
                type: String,
                required: false,
                default: "Non, abandonner"
            },
            labelConfirmation: {
                type: String,
                required: false,
                default: "Oui, confirmer"
            },
            iconValue: {
                required: false
            },
            isIcon: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipDetailMessage: {
                type: String,
                required: false,
                default: ""
            },

        },
        data() {
            return {
                dialog: false,
                messageConfirmation: undefined,
            };
        },
        methods: {
            /**
             * Ouverture du lien externe
             */
            openExternalLink: function () {
                let link = this.link
                window.open(link);
                this.dialog = false;     
            },
            /**
             * Ferme la pop up
             */
            closePopUp() {
                this.dialog = false;
            },
            onButtonClick: function () {
              this.$emit("open-popup-click");
            },
        }
    };
</script>