import Index from "@/views/Index";
import InteExercice from "@/views/interessement/exercice/InteExercice";
import InteExercicesList from "@/views/interessement/exercice/InteExercicesList";
import RepartitionInte from "@/views/interessement/exercice/RepartitionInte";
import AffectationInte from "@/views/interessement/exercice/AffectationInte";
import rolesList from "@/router/rolesList";


export default {
  path: "exercices/",
  component: Index,
  children: [
    {
      path: "",
      name: "inte-exercices",
      component: InteExercicesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/edit/",
      name: "inte-edition-exercice",
      props: {
        creationMode: false
      },
      component: InteExercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "create/",
      name: "inte-creation-exercice",
      props: {
        creationMode: true
      },
      component: InteExercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":inteExerciceId/repartition/",
      name: "inte-repartition-exercice",
      component: RepartitionInte,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":inteExerciceId/affectation/",
      name: "inte-affectation-exercice",
      component: AffectationInte,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
  ],
}