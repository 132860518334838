<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="50px" datatableExcludedSize="300px">
        <template slot="header">
            <h1>Répartition de la participation aux bénéfices</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container fluid>
                <v-row justify="space-between">
                    <v-col md="5" lg="5" xl="2">
                        <v-text-field v-model="exercice.libelle" :disabled="true" label="Exercice"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field v-model="exercice.participation_brute"
                                                       prefixValue="€" :reverseValue="true"
                                                       :disabledValue="true"
                                                       labelValue="Participation aux bénéfices brute"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field v-model="tauxFiscalite.taux_csg_crds" prefixValue="%"
                                                       :reverseValue="true" :disabledValue="true"
                                                       labelValue="CSG - CRDS"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field v-model="tauxFiscalite.forfait_social_standard"
                                                       prefixValue="%"
                                                       :reverseValue="true" :disabledValue="true"
                                                       labelValue="Forfait social"/>
                    </v-col>
                </v-row>
                <v-datatable-repartition-pb @update-repartitions="repartitions = $event"
                                            @update-repartition-uniforme-active="repartitionUniformeActive = $event"
                                            @update-repartition-presence-active="repartitionPresenceActive = $event"
                                            @update-repartition-salaire-active="repartitionSalaireActive = $event"
                                            @update-repartition-anciennete-active="repartitionAncienneteActive = $event"
                                            @update-nombre-distributions="nombreDistributions = $event"
                                            @update-detail-calcul="detailCalcul = $event"
                                            :repartitions="repartitions"
                                            :repartitionUniformeActive="repartitionUniformeActive"
                                            :repartitionPresenceActive="repartitionPresenceActive"
                                            :repartitionSalaireActive="repartitionSalaireActive"
                                            :repartitionAncienneteActive="repartitionAncienneteActive"
                                            :nombreDistributions="nombreDistributions"
                                            :detailCalcul="detailCalcul"
                                            :selectedExerciceId="exercice.id"
                                            :height="datatableSize"
                                            ref="datatableRepartitionPb"
                />
            </v-container>
        </template>
        <template slot="footer">
            <v-row dense>
                <!-- Petit bouton -->
                <v-col md="2" lg="2" xl="2">
                    <v-btn dark color="primary" outlined @click="downloadExport" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <v-col md="2" lg="2" xl="2" v-if="!exercice.reprise">
                    <detail-repartition-pb :explain="detailCalcul"/>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" v-if="exercice.pb_exercice.is_repartition_pb_valide">
                   <v-popup-asynchrone
                        documentsNamePlural="fiches individuelles"
                        :isDocumentGenderFeminine="true"
                        documentsFullNamePlural="fiches individuelles participation"
                        documentPdfName="fiches_individuelles_participation"
                        :getStateDocuments="getStateFichesIndividuelles"
                        :generateDocuments="generateFichesIndividuelles"
                        :downloadDocuments="downloadFichesIndividuelles"
                    />
                </v-col>
                
                <v-spacer/>
                <template v-if="!(exercice.pb_exercice.is_repartition_pb_valide)">
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn block :to="{name: 'pb-exercices'}">Annuler</v-btn>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-custom-confirmation-dialog :message="confirmationMessage"
                                                      justifyMessage="end"
                                                      openButtonMessage="Valider"
                                                      title="Valider la répartition de la participation"
                                                      :isIcon="false"
                                                      @action-confirmee="validateRepartitionPb()"
                                                      maxWidth="1200px"
                        >
                            <template slot="custom-infos">
                                <h3>Récapitulatif des cas exceptionnels présents dans cette répartition</h3>
                                <v-tabs v-model="recapValidationCurrentTab" grow>
                                    <v-tab key="exclusAnciennete">
                                        Seuil d'ancienneté non atteint ({{ repartitionsExcluesNonForce.length }})
                                    </v-tab>
                                    <v-tab key="plafondAtteint">
                                        Plafond atteint ({{ repartitionsPlafondAtteint.length }})
                                    </v-tab>
                                    <v-tab key="exclusForce">
                                        Exclu(e)s manuellement ({{ repartitionsExcluesForce.length }})
                                    </v-tab>
                                    <v-tab key="inclusForce">
                                        Inclu(e)s manuellement ({{ repartitionsIncluesForce.length }})
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="recapValidationCurrentTab">
                                    <v-tab-item key="exclusAnciennete">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsExcluesNonForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'est exclu(e) de par son ancienneté"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="plafondAtteint">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsPlafondAtteint"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'a atteint son plafond"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="exclusForce">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsExcluesForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'est manuellement exclu(e)"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="inclusForce">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsIncluesForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'est manuellement inclu(e)"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                </v-tabs-items>
                            </template>
                        </v-custom-confirmation-dialog>
                    </v-col>
                </template>
                <!-- Petit bouton -->
                <v-col v-else md="2" lg="2" xl="2">
                    <v-btn block :to="{name: 'pb-exercices'}">Retour</v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import XLSX from "xlsx";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import Utils from "@/utils";
    import DetailRepartitionPb from "@/components/repartitionPb/VDetailRepartitionPb";
    import VDatatableRepartitionPb from "@/components/repartitionPb/VDatatableRepartitionPb";
    import VPopupAsynchrone from "@/components/VPopupAsynchrone.vue";

    /**
     * Composant affichant la datatable de la répartition de la participation aux bénéfices
     * pour un exercice donné
     * @displayName Participation - RepartitionPb
     */
    export default {
        components: {
            DetailRepartitionPb,
            BaseLayout,
            VFormattedNumberTextField,
            VCustomConfirmationDialog,
            loadingScreen,
            VDatatableRepartitionPb,
            VPopupAsynchrone,
        },
        data() {
            return {
                repartitions: [],
                repartitionUniformeActive: false,
                repartitionPresenceActive: false,
                repartitionSalaireActive: false,
                repartitionAncienneteActive: false,
                nombreDistributions: 1,
                confirmationMessage: "Une fois la répartition de la participation aux bénéfices validée, les montants ne seront plus modifiables." +
                    "<br/>Confirmez-vous cette opération ?",
                detailDialog: false,
                detailCalcul: {},
                tauxFiscalite: {},
                exercice: {
                    pb_exercice: {
                        reprise: true,
                    },
                },
                recapValidationHeaders: [
                    {
                        text: "Bénéficiaire",
                        value: "beneficiaire_name",
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                    },
                ],
                recapValidationPagination: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ["beneficiaire_name", "numero_tiers"],
                    sortDesc: [false, false]
                },
                recapValidationCurrentTab: null,
            };
        },
        mounted() {
            const {id} = this.$route.params;
            this.getPbExerciceById(id).then(result => {
                this.getTauxFiscaliteByDate();
            });
        },
        computed: {
            repartitionsExcluesForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionPb.isBeneficiaireExcluForce(repartition)
                });
            },
            repartitionsIncluesForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionPb.isBeneficiaireIncluForce(repartition)
                });
            },
            repartitionsExcluesNonForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionPb.isBeneficiaireExcluNonForce(repartition)
                });
            },
            repartitionsPlafondAtteint() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionPb.isBeneficiairePlafondAtteint(repartition)
                });
            },
        },
        methods: {
            getPbExerciceById(pb_exercice_id) {
                return internalApi.pbExercice.getById(pb_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            /**
             *  Récupère les taux de fiscalité valables à la date de fin de l'exercice
             */
            getTauxFiscaliteByDate() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return internalApi.tauxFiscalite.getTauxFiscaliteByDate(this.exercice.date_fin)
                    .then(result => {
                        this.tauxFiscalite = result;
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Valide la répartition de la participation affichée dans la datatable puis reviens
             * à l'écran des exercices
             */
            validateRepartitionPb() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.repartitionPb.validateRepartitionPbByExerciceId(this.exercice.id).then(res => {
                    this.$router.push({
                        name: "pb-exercices"
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            downloadExport() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.exportLong);
                internalApi.repartitionPb.exercicesExportRepartitionPbExcel(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "export_repartition_pb.xlsx", Utils.excelMimetype);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            getStateFichesIndividuelles(){
                return internalApi.repartitionPb.getStateFichesIndividuelles(this.exercice.id);
            },
            /**
             * Lance la génération des fiches individuelles
             */
            generateFichesIndividuelles(){
                return internalApi.repartitionPb.generateFichesIndividuelles(this.exercice.id);
            },
            /** Télécharge les fiches individuelles */
            downloadFichesIndividuelles(){
                return internalApi.repartitionPb.getFichesIndividuelles(this.exercice.id);
            },
        },
    };
</script>

<style>
    /*To ensure numbers fit in one line*/
    th {
        min-width: 120px;
    }
</style>
