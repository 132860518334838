// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import CategorieCapital from "../capital/categorieCapital/CategorieCapital";

export default {
  mounted() {
    this.isEditable = false;
    let categorieCapitauxId = this.$route.params.categorieCapitalId;
    internalApi.categorieCapital.getStatutCategById(categorieCapitauxId)
      .then(result => {
        this.categorieCapital = result;
      })
      .catch(error => console.error(error));
  },
  extends: CategorieCapital,
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {}
  }
};
