// <!-- VUETIFY2 - OK -->
import VTauxRemuForSupport from "./VTauxRemuForSupport"
import internalApi from "@/api/internalApi";

export default {
  extends: VTauxRemuForSupport,
  computed: {
    isModif() {
      return false;
    },
    title() {
      return "Créer un taux de rémunération";
    },
    openButtonMessage() {
      return "Créer un taux de rémunération";
    },
  },
  mounted() {
    this.fetchExercicesList();
    this.isTauxRemuFieldDisabled = true;
  },
  watch: {
    "tauxRemuPropose": {
      handler(new_val) {
        if (! _.isNil(new_val)) {
          this.tauxRemu.taux_remu = new_val;
          this.isTauxRemuFieldDisabled = false;
        }
      },
    },
    "tauxRemu.pb_exercice_id": {
      handler(new_val) {
        const matchingExercice = this.exercicesList.find(exercice => exercice.id === new_val);
        if (!_.isNil(matchingExercice)) {
          this.tauxRemu.date_versement = matchingExercice.date_fin;
        }
      }
    }
  },
  methods: {
    submit() {
      const data = _.cloneDeep(this.tauxRemu);
      this.$emit("create-taux-remu", data);
      this.closePopUp();
    },
  }
};