// <!-- VUETIFY2 - OK -->
import VDatatableSalairePresencesAbstract from "./VDatatableSalairePresencesAbstract"

import constantes from "@/utils/constantes";
import internalApi from "@/api/internalApi";

export default {
    name: "VDatatableInteSalairePresences",
    extends: VDatatableSalairePresencesAbstract,
    abstract: false,
    computed: {
        showPlafond() {
            return false;
        },
    },
    methods: {
        /**
         * Récupère le plafond de salaire positionné sur l'exercice
         */
        getMontantRenumerationSalairePlafond() {
            // Pas utilisé si showPlafond == false
            return new Promise();
        },
        /**
         * Récupère le total des salaires et des temps de présence pour les bénéficiaires
         * ayant au moins une période de travail sur l'exercice
         */
        getTotauxSalairePresences() {
            return internalApi.inteSalairePresence.getTotauxSalairePresencesForExercice(this.exerciceId)
                .then(res => {
                    this.total_salaire = res.total_salaire;
                    this.total_presence = res.total_presence;
                });
        },
        /**
         * Récupère les périodes de travail des salariés valides entre la date de début
         * et la date de fin de l'exercice
         */
        getAllSalairePresences() {
            this.loadingSalairePresences = true;

            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.options;

                internalApi.inteSalairePresence.getSalairePresencesInPeriode(
                    this.exerciceId,
                    page,
                    itemsPerPage,
                    sortBy[0],
                    sortDesc[0],
                ).then(result => {
                    let items = result.salaire_presences;
                    const total = result.total_items;
                    resolve({
                        'items': items,
                        'total': total,
                    });
                }).catch(err => {
                    console.error("Erreur lors de la récupération des salaires et des présences : ", err);
                    let items = [];
                    const total = 0;
                    reject({
                        'items': items,
                        'total': total,
                    });
                }).finally(() => {
                    this.getTotauxSalairePresences();
                    this.loadingSalairePresences = false;
                });

            });
        },
        /**
         * Enregistre les salaires et présences présents dans la page actuelle
         */
        saveSalairePresences() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.inteSalairePresence.saveSalairePresences(this.exerciceId, this.salairePresences)
                .finally(() => {
                    this.dataChanged = false;
                    this.$refs.loadingComponent.resetLoading();
                    this.getTotauxSalairePresences();
                });
        },
    }
};
