import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default repartitionPbApi;

function repartitionPbApi() {
  return {
    getRepartitionPbByExerciceId: getRepartitionPbByExerciceId(),
    getRepartitionPbIndividuelByExerciceId: getRepartitionPbIndividuelByExerciceId(),
    validateRepartitionPbByExerciceId: validateRepartitionPb(),
    exercicesExportRepartitionPbExcel: exercicesExportRepartitionPbExcel(),
    getStateFichesIndividuelles: getStateFichesIndividuelles(),
    generateFichesIndividuelles: generateFichesIndividuelles(),
    getFichesIndividuelles: getFichesIndividuelles(),
    getFicheIndividuelle: getFicheIndividuelle(),
    checkBenefHasRepartitionOrOperationsOnSupport: checkBenefHasRepartitionOrOperationsOnSupport(),
    getRapportAnnuel: getRapportAnnuel(),
  };
}

function getRepartitionPbByExerciceId() {
  function getRepartition(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.repartitionPb(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getRepartition;
}

function getRepartitionPbIndividuelByExerciceId() {
  function getRepartitionIndividuel(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.repartitionPbIndividuel(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getRepartitionIndividuel;
}

function validateRepartitionPb() {
  function postRepartition(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.repartitionPb(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postRepartition;
}

function exercicesExportRepartitionPbExcel() {
  function exercicesExportRepartitionPbExcel(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.exercicesExportRepartitionPbExcel(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return exercicesExportRepartitionPbExcel;
}

function getStateFichesIndividuelles() {
  function getState(pbExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.getStateFichesIndividuelles(pbExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getState;
}

function generateFichesIndividuelles() {
  function generate(pbExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.generateFichesIndividuelles(pbExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generate;
}

function getFichesIndividuelles() {
  function fis(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.fichesIndividuelles(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return fis;
}

function getFicheIndividuelle() {
  function fiche(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.ficheIndividuelle(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return fiche;
}

function checkBenefHasRepartitionOrOperationsOnSupport() {
  function checkBenefHasRepartitionOrOperationsOnSupport(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.benefHasRepartitionOrOperationsOnSupport(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return checkBenefHasRepartitionOrOperationsOnSupport;
}

function getRapportAnnuel() {
  function rap(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.participation.rapportAnnuel(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return rap;
}
