<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-data-table :headers="headers" :items="localRepartitions" :no-data-text="noDataTextMessage"
                      fixed-header
                      :footer-props="footerOpts"
                      :height="height"
                      class="elevation-1"
                      :options.sync="options">
            <template v-slot:item="{ item }">
                <tr>
                    <td class="text-lg">
                        <v-row align="center" justify="space-between">
                            <v-col>
                                {{ item.beneficiaire_name}}
                            </v-col>
                            <v-col md="1" lg="1" xl="1">
                                <v-tooltip right v-if="computeInfoBulle(item)">
                                    <template v-slot:activator="{ on }" class="text-xs-right">
                                        <v-icon small right v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                    </template>
                                    <span>{{ computeInfoBulle(item) }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </td>
                    <td>
                        <span class="text-xs-left">{{ item.numero_tiers }}</span>
                    </td>
                    <template v-for="(distribution, index) in item.distributions">
                        <template v-if="index === 0">
                            <td :key="index" class="text-lg-right" v-if="localRepartitionUniformeActive">{{distribution.montant_uniforme | roundCurrencyFilter }}</td>
                            <td :key="'distributionmontant_salaire'+index" class="text-lg-right" v-if="localRepartitionSalaireActive">{{ distribution.montant_salaire | roundCurrencyFilter }}</td>
                            <td :key="'distributionmontant_presence'+index" class="text-lg-right" v-if="localRepartitionPresenceActive">{{ distribution.montant_presence | roundCurrencyFilter }}</td>
                            <td :key="'distributionmontant_anciennete'+index" class="text-lg-right" v-if="localRepartitionAncienneteActive">{{ distribution.montant_anciennete | roundCurrencyFilter}}</td>
                            <td :key="'distributiontotal'+index" class="text-lg-right" v-if="localNombreDistributions > 1">{{ distribution.total | roundCurrencyFilter }}</td>
                            <td :key="'distributionreliquat'+index" class="text-lg-right" v-if="localNombreDistributions > 1">{{ distribution.reliquat | roundCurrencyFilter }}</td>
                        </template>
                        <template v-else-if="index < localNombreDistributions - 1">
                            <td :key="'distributioncumul'+index" class="text-lg-right">{{ distribution.cumul | roundCurrencyFilter }}</td>
                            <td :key="'distributionreliquat'+index" class="text-lg-right">{{ distribution.reliquat | roundCurrencyFilter }}</td>
                        </template>
                    </template>
                    <template v-if="totals.distribTotals.length == 0">
                        <td class="text-lg-right">{{ 0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right">{{ 0 | roundCurrencyFilter }}</td>
                    </template>
                    <template v-if="item.distributions.length == 0">
                        <td class="text-lg-right" v-if="localRepartitionUniformeActive">{{0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right" v-if="localRepartitionSalaireActive">{{ 0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right" v-if="localRepartitionPresenceActive">{{ 0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right" v-if="localRepartitionAncienneteActive">{{ 0 | roundCurrencyFilter}}</td>
                        <td class="text-lg-right" v-if="localNombreDistributions > 1">{{ 0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right" v-if="localNombreDistributions > 1">{{ 0 | roundCurrencyFilter }}</td>
                    </template>
                    <td class="text-lg-right">{{ item.total_brut | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.montant_forfait_social_standard | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.montant_csg_crds | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.total_net | roundCurrencyFilter }}</td>
                </tr>
            </template>
            <template v-slot:[`body.append`] v-if="isDisplayFooter">
                <tr class="v-data-table__nohoverezi">
                    <td class="font-weight-bold" colspan="2">Totaux</td>
                    <template v-for="(totalsDistrib, index) in totals.distribTotals">
                        <template v-if="index === 0">
                            <td :key="'totalsDistrib'+index" class="text-lg-right font-weight-bold" v-if="localRepartitionUniformeActive">{{totalsDistrib.montant_uniforme | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribmontant_salaire'+index" class="text-lg-right font-weight-bold" v-if="localRepartitionSalaireActive">{{ totalsDistrib.montant_salaire | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribmontant_presence'+index" class="text-lg-right font-weight-bold" v-if="localRepartitionPresenceActive">{{ totalsDistrib.montant_presence | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribmontant_anciennete'+index" class="text-lg-right font-weight-bold" v-if="localRepartitionAncienneteActive">{{ totalsDistrib.montant_anciennete | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribtotal'+index" v-if="localNombreDistributions > 1" class="text-lg-right font-weight-bold">{{ totalsDistrib.total | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribreliquat'+index" v-if="localNombreDistributions > 1" class="text-lg-right font-weight-bold">{{ totalsDistrib.reliquat | roundCurrencyFilter }}</td>
                        </template>
                        <template v-else>
                            <td :key="'totalsDistribCumul'+index" v-if="index < localNombreDistributions - 1" class="text-lg-right font-weight-bold">{{ totalsDistrib.cumul | roundCurrencyFilter }}</td>
                            <td :key="'totalsDistribReliquat'+index" v-if="index < localNombreDistributions - 1" class="text-lg-right font-weight-bold">{{ totalsDistrib.reliquat | roundCurrencyFilter }}</td>
                        </template>
                    </template>
                    <template v-if="totals.distribTotals.length == 0">
                        <td class="text-lg-right font-weight-bold">{{ 0 | roundCurrencyFilter }}</td>
                        <td class="text-lg-right font-weight-bold">{{ 0 | roundCurrencyFilter }}</td>
                    </template>
                    <td class="text-lg-right font-weight-bold">{{ totals.total_brut | roundCurrencyFilter }}</td>
                    <td class="text-lg-right font-weight-bold">{{ totals.montant_forfait_social_standard | roundCurrencyFilter }}</td>
                    <td class="text-lg-right font-weight-bold">{{ totals.montant_csg_crds | roundCurrencyFilter }}</td>
                    <td class="text-lg-right font-weight-bold">{{ totals.total_net | roundCurrencyFilter }}</td>
                </tr>
            </template>
        </v-data-table>
        <v-col v-if="this.showReliquat">
            <span
                class="font-weight-medium">Reliquat global : {{ this.reliquatGlobal | roundEuroFilter }}
            </span>
        </v-col>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import _ from "lodash";

    /**
     * Composant affichant une table de répartitions pb
     * @display_name Participation - VDatatable Répartitions Pb
     */
    export default {
        name: "VDatatableRepartitionPb",
        components: {
            loadingScreen,
        },
        props: {
            /**
             * La hauteur de la datatable
             */
            height: String,
            /**
             * L'id de l'exercice sélectionné
             */
            selectedExerciceId: {
                type: Number,
            },
            /**
             * Message si table vide
             */
            noDataTextMessage: {
                type: String,
                required: false,
                default: "Aucune donnée disponible."
            },
            /**
             * Contenu de la fenêtre de détail
             */
            detailCalcul: {
                type: Object,
                required: true,
            },
            /**
             * Contenu de la table
             */
            repartitions: {
                type: Array,
                required: true,
            },
            /**
             * Affichage de la répartition uniforme
             */
            repartitionUniformeActive: {
                type: Boolean,
                required: true,
            },
            /**
             * Affichage de la répartition présentielle
             */
            repartitionPresenceActive: {
                type: Boolean,
                required: true,
            },
            /**
             * Affichage de la répartition sur le salaire
             */
            repartitionSalaireActive: {
                type: Boolean,
                required: true,
            },
            /**
             * Affichage de la répartition sur l'ancienneté
             */
            repartitionAncienneteActive: {
                type: Boolean,
                required: true,
            },
            /**
             * Nombre de lignes
             */
            nombreDistributions: {
                type: Number,
                required: true,
                default: 1,
            }
        },
        data() {
            return {
                footerOpts: {itemsPerPageOptions: [10, 20, 50, -1]},
                isDisplayFooter: true,
                showReliquat: false,
                headers: [],
                options: {
                    sortBy: ["beneficiaire_name"]
                },
            }
        },
        computed: {
            localRepartitions: {
                get: function(){
                    return this.repartitions;
                },
                set: function(newvalue){
                    this.$emit("update-repartitions", newvalue);
                }
            },
            localRepartitionUniformeActive: {
                get: function(){
                    return this.repartitionUniformeActive;
                },
                set: function(newvalue){
                    this.$emit("update-repartition-uniforme-active", newvalue);
                }
            },
            localRepartitionPresenceActive: {
                get: function(){
                    return this.repartitionPresenceActive;
                },
                set: function(newvalue){
                    this.$emit("update-repartition-presence-active", newvalue);
                }
            },
            localRepartitionSalaireActive: {
                get: function(){
                    return this.repartitionSalaireActive;
                },
                set: function(newvalue){
                    this.$emit("update-repartition-salaire-active", newvalue);
                }
            },
            localRepartitionAncienneteActive: {
                get: function(){
                    return this.repartitionAncienneteActive;
                },
                set: function(newvalue){
                    this.$emit("update-repartition-anciennete-active", newvalue);
                }
            },
            localNombreDistributions: {
                get: function(){
                    return this.nombreDistributions;
                },
                set: function(newvalue){
                    this.$emit("update-nombre-distributions", newvalue);
                }
            },
            localDetailCalcul: {
                get: function(){
                    return this.detailCalcul;
                },
                set: function(newvalue){
                    this.$emit("update-detail-calcul", newvalue);
                }
            },
            totals() {
                const totals = {
                    distribTotals: [],
                    total_brut: _.sumBy(this.localRepartitions, "total_brut"),
                    montant_forfait_social_standard: _.sumBy(this.localRepartitions, "montant_forfait_social_standard"),
                    montant_csg_crds: _.sumBy(this.localRepartitions, "montant_csg_crds"),
                    total_net: _.sumBy(this.localRepartitions, "total_net")
                };

                _.forEach(_.range(this.localNombreDistributions), index => {
                    totals.distribTotals.push({
                        montant_uniforme: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "montant_uniforme")),
                        montant_salaire: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "montant_salaire")),
                        montant_presence: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "montant_presence")),
                        montant_anciennete: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "montant_anciennete")),
                        total: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "total")),
                        cumul: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "cumul")),
                        reliquat: _.sumBy(this.localRepartitions, repart => this.get_montant(repart.distributions[index], "reliquat")),
                    })
                });

                return totals;
            },
        },
        mounted() {
            if (!_.isNil(this.selectedExerciceId)) {
                this.updateDataTable();
            } else {
                this.headers = this.computeHeaders();
            }
        },
        watch: {
            selectedExerciceId: function(new_exercice_id, old_exercice_id) {
                if (!_.isNil(new_exercice_id)) {
                    this.updateDataTable();
                }
            },
        },
        methods: {
            computeInfoBulle(repartition) {
                let result = null;
                if (this.isBeneficiaireExcluNonForce(repartition)) {
                    result = "Seuil d'ancienneté non atteint";
                } else if (this.isBeneficiairePlafondAtteint(repartition)) {
                    if (this.isBeneficiaireIncluForce(repartition)) {
                        result = "Inclusion manuelle dans la répartition et plafond individuel atteint";
                    } else {
                        result = "Plafond individuel atteint";
                    }
                } else if (this.isBeneficiaireExcluForce(repartition)) {
                    result = "Exclusion manuelle de la répartition";
                } else if (this.isBeneficiaireIncluForce(repartition)) {
                    result = "Inclusion manuelle dans la répartition";
                }
                return result;
            },
            isBeneficiaireExcluForce(repartition) {
                return !repartition.eligible && repartition.inclusion_exclusion_forcee;
            },
            isBeneficiaireIncluForce(repartition) {
                return repartition.eligible && repartition.inclusion_exclusion_forcee;
            },
            isBeneficiaireExcluNonForce(repartition) {
                return !repartition.eligible && !repartition.inclusion_exclusion_forcee;
            },
            isBeneficiairePlafondAtteint(repartition) {
                return repartition.plafond_atteint;
            },
            /**
             * Charge les données de la VDatatable
             */
            updateDataTable() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.repartitionPb.getRepartitionPbByExerciceId(this.selectedExerciceId).then(res => {
                    this.localRepartitions = res.repartition;
                    this.localNombreDistributions = res.nombre_distributions;
                    this.reliquatGlobal = res.reliquat_global;
                    this.showReliquat = true;
                    this.localDetailCalcul = res.detail;
                    if (! _.isEmpty(res.detail)) {
                        this.localRepartitionUniformeActive = res.detail.repartition_uniforme.montant_total > 0;
                        this.localRepartitionPresenceActive = res.detail.repartition_presence.montant_total > 0;
                        this.localRepartitionSalaireActive = res.detail.repartition_salaire.montant_total > 0;
                        this.localRepartitionAncienneteActive = _.some(res.detail.repartition_anciennete.regles, regle => regle.montant_total > 0);
                    }
                }).finally(() => {
                    this.headers = this.computeHeaders();
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Détermine les intitulés des colonnes
             */
            computeHeaders() {
                let headers = [
                    {
                        text: "Bénéficiaire",
                        value: "beneficiaire_name",
                        sortable: true
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                        sortable: false
                    },
                ];

                if (this.localRepartitionUniformeActive) {
                    headers.push({
                        text: "Répartition uniformisée",
                        value: "montant_uniforme",
                        align: "right",
                    });
                }
                if (this.localRepartitionSalaireActive) {
                    headers.push({
                        text: "Répartition / Salaires",
                        value: "montant_salaire",
                        align: "right",
                    });
                }
                if (this.localRepartitionPresenceActive) {
                    headers.push({
                        text: "Répartition / Temps de présence",
                        value: "montant_presence",
                        align: "right",
                    });
                }
                if (this.localRepartitionAncienneteActive) {
                    headers.push({
                        text: "Répartition / Anciennete",
                        value: "montant_anciennete",
                        align: "right",
                    });
                }

                _.forEach(_.range(this.localNombreDistributions - 1), () => {
                    headers = headers.concat([
                        {
                            text: "Total",
                            value: "cumul",
                            align: "right",
                        },
                        {
                            text: "Reliquat individuel",
                            value: "reliquat",
                            align: "right",
                        },
                    ]);
                });

                headers = headers.concat([
                    {
                        text: "Total brut",
                        value: "total_brut",
                        align: "right",
                    },
                    {
                        text: "Forfait social standard",
                        value: "montant_forfait_social_standard",
                        align: "right",
                    },
                    {
                        text: "CSG - CRDS",
                        value: "montant_csg_crds",
                        align: "right",
                    },
                    {
                        text: "Total net",
                        value: "total_net",
                        align: "right",
                    },
                ]);

                return headers;
            },
            get_montant(distribution, key) {
                let mount = 0;
                if (distribution !== undefined) {
                    mount = distribution[key];
                }
                return mount;
            }
        }
    }
</script>

<style scoped>
    /*To ensure numbers fit in one line*/
    th {
        min-width: 120px;
    }
    .header {
        padding: 5px;
        color: white;
        font-weight: bold;
        font-size: 15px;
        background-color: black;
    }
    .cell {
        text-align: center;
        padding: 5px;
        color: black;
        font-size: 15px;
        background-color: lightgray;
    }
</style>