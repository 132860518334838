<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
    <base-layout :withHeader="false">
        <template v-slot:main>
            <v-container>
                <loading-screen ref="loadingComponent"/>
                <h3 slot="header">Règles d'abondement</h3>
                <v-container pt-0>
                    <v-row justify="space-between" dense>
                        <v-col cols="5">
                            <ValidationProvider
                                vid="apport_associe_part_sociale"
                                name="Apport de l'associé par part sociale"
                                :rules="{ currency: true , between: {'min': 0, 'max': capitalMontantSouscriptionPart}, required: true }"
                                v-slot="{ errors }">
                                    <v-formatted-number-text-field
                                        v-model="localExercice.abo_exercice.apport_associe_part_sociale"
                                        prefixValue="€"
                                        labelValue="Apport de l'associé par part sociale"
                                        :reverseValue="true"
                                        :errors="errors"
                                        :disabledValue="isAttributionValide"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="5" align-self="center">
                            Montant de l'abondement&nbsp;:
                            <span class="text-no-wrap">{{montantAbondement | roundEuroFilter}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            <ValidationProvider vid="dateReferenceAnciennete"
                                                name="Date de référence"
                                                :rules="{ required: true }"
                                                v-slot="{ errors }">
                                <DateField
                                    v-model="localExercice.abo_exercice.date_reference_anciennete"
                                    label="Date de référence"
                                    :errors="errors"
                                    :disabled="isAttributionValide"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                    </v-icon>
                                </template>
                                <span
                                    v-html="messageDateReference"/>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
                <h3 slot="header">Contrôles plafonds</h3>
                <v-container pt-0>
                    <v-row justify="space-between" dense>
                        <v-col cols="5">
                            <ValidationProvider
                                vid="montant_plafond_abondement"
                                name="Montant plafond d'abondement"
                                :rules="{ currency: true , min: 0, required: true}"
                                v-slot="{ errors }">
                                    <v-formatted-number-text-field
                                        v-model="localExercice.abo_exercice.montant_plafond_abondement"
                                        prefixValue="€"
                                        labelValue="Montant plafond d'abondement"
                                        :reverseValue="true"
                                        :errors="errors"
                                        :disabledValue="isAttributionValide"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="5">
                            <v-row align="center" no-gutters>
                                <v-col>
                                    <ValidationProvider vid="plafond_annuel_securite_sociale"
                                                        name="Plafond Annuel de la Sécurité Sociale"
                                                        ref="plafond_annuel_securite_sociale"
                                                        rules="required|currency|min_value:0"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field
                                            v-model="localExercice.plafond_annuel_securite_sociale"
                                            prefixValue="€"
                                            labelValue="Plafond Annuel de la Sécurité Sociale"
                                            :reverseValue="true"
                                            :errors="errors"
                                            :disabledValue="isAttributionValide || !canEditPass"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between" dense>
                        <v-spacer/>
                        <v-col cols="5">
                            <v-row align="center" no-gutters>
                                <v-col class="shrink text-no-wrap">
                                    Montant de souscription plafond&nbsp;:
                                    <span>{{plafondSouscription | roundEuroFilter}}</span>
                                </v-col>
                                <v-col md="1" lg="1" xl="1" class="pl-2">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                            </v-icon>
                                        </template>
                                        <span
                                            v-html="`Montant de souscription plafond : 0,5 × Plafond Annuel de la Sécurité Sociale.`"/>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <h3 slot="header">Campagne d'abondement</h3>
                <v-container pt-0>
                    <v-row justify="space-between" dense>
                        <v-col cols="5">
                            <ValidationProvider vid="dateDebutCampagne"
                                                name="Date de début de campagne"
                                                :rules="{ required: true , date_after: localExercice.date_debut, date_before: localExercice.date_fin }"
                                                v-slot="{ errors }">
                                <DateField
                                    v-model="localExercice.abo_exercice.date_debut_campagne"
                                    label="Date de début de campagne"
                                    :errors="errors"
                                    :disabled="isAttributionValide"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="5">
                            <ValidationProvider vid="dateFinCampagne"
                                                name="Date de fin de campagne"
                                                :rules="{ required: true , date_after: localExercice.abo_exercice.date_debut_campagne || localExercice.date_debut, date_before: localExercice.date_fin }"
                                                v-slot="{ errors }">
                                <DateField
                                    v-model="localExercice.abo_exercice.date_fin_campagne"
                                    label="Date de fin de campagne"
                                    :errors="errors"
                                    :disabled="isAttributionValide"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
                <h3 slot="header">Ancienneté</h3>
                <v-container pt-0>
                    <v-row justify="space-between" dense>
                        <v-col cols="5">
                            <v-checkbox v-model="hasContrainteAnciennete"
                                        label="Contrainte d'ancienneté"
                                        @change="switchHasContrainteAnciennete"
                                        :disabled="isAttributionValide"
                            />
                        </v-col>
                        <v-col cols="5">
                            <ValidationProvider vid="moisAnciennete" name="Mois d'ancienneté"
                                                :rules="{ required: hasContrainteAnciennete, min: 0 }" v-slot="{ errors }">
                                <v-formatted-number-text-field v-model="localExercice.abo_exercice.contrainte_anciennete"
                                                                labelValue="Mois d'ancienneté"
                                                                :errors="errors"
                                                                :disabledValue="!hasContrainteAnciennete || isAttributionValide"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </template>
        <template slot="footer">
            <v-row justify="end" no-gutters>
                <!-- Petits boutons -->
                <v-col v-if="!isAttributionValide" md="6" lg="6" xl="4">
                    <v-save-buton-with-validation :validateForm="validate"
                                                :resetForm="reset"
                                                :errorsList="errors"
                                                @emit-cancel="returnToExerciceList"
                                                @emit-valid-save="save"/>
                </v-col>
                <!-- Petit bouton -->
                <v-col v-else md="3" lg="3" xl="2">
                    <v-btn block @click="returnToExerciceList" color="primary">Retour</v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
    </ValidationObserver>
</template>

<script>
    import _ from "lodash";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import DateField from "@/components/DateField";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    export default {
        name: "AboExercice",
        components: {
            BaseLayout,
            loadingScreen,
            VFormattedNumberTextField,
            DateField,
            VSaveButonWithValidation,
        },
        data() {
            return {
                capitalMontantSouscriptionPart: undefined,
                isAttributionValide: false,
                canEditPass: false,
                hasContrainteAnciennete: false,
                messageDateReference: "La date de référence sert de repère pour le calcul de l'ancienneté, la prise en compte des statuts et de la fiscalité."
            }
        },
        props: {
            exercice: {
                type: [Object],
                required: true,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        watch: {
            exercice: {
                handler() {
                    this.getDataFromExercice();
                },
                deep: true
            },
        },
        computed: {
            localExercice: {
                get: function () {
                    return this.exercice;
                },
                set: function (newExercice) {
                    this.$emit("update-exercice", newExercice);
                }
            },
            montantAbondement() {
                return this.capitalMontantSouscriptionPart - (this.localExercice.abo_exercice.apport_associe_part_sociale || 0);
            },
            plafondSouscription() {
                return (this.localExercice.plafond_annuel_securite_sociale || 0) / 2;
            },
        },
        mounted() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            this.getDataFromExercice();

            this.getCapitalMontantSouscriptionPart().finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        },
        methods: {
            getDataFromExercice() {
                this.hasContrainteAnciennete = !_.isNil(this.localExercice.abo_exercice.contrainte_anciennete);
                this.canEditPass = this.localExercice.can_edit_pass;
                this.isAttributionValide = this.localExercice.abo_exercice.is_attribution_valide;
            },
            /**
             * Récupère la valeur courante d'acquisition d'une part sociale
             */
            getCapitalMontantSouscriptionPart() {
                return internalApi.capCompany.getStatutByDate(this.localExercice.date_fin).then(result => {
                    this.capitalMontantSouscriptionPart = result.capital_montant_souscription_part;
                });
            },
            /**
             * Gère la valeur de la contrainte d'ancienneté si on coche ou décoche la case
             */
            switchHasContrainteAnciennete() {
                if (!this.hasContrainteAnciennete) {
                    this.localExercice.abo_exercice.contrainte_anciennete = null;
                }
            },
            /**
             * Met à jour l'exercice
             */
            save() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);

                const data_to_send = _.cloneDeep(this.localExercice);
                // On ne veut pas pouvoir mettre ces données à jour depuis cet écran
                delete data_to_send.id;
                delete data_to_send.abo_exercice.id;
                delete data_to_send.abo_exercice.is_parametrage_complete;
                delete data_to_send.cap_exercice;
                delete data_to_send.pb_exercice;
                delete data_to_send.inte_exercice;
                internalApi.aboExercice
                    .putByIdWithToastConfirmation(
                        this.localExercice.id,
                        data_to_send,
                    )
                    .catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Affiche la vue de la liste des exercices côté capital
             */
            returnToExerciceList() {
                this.$router.push({
                    name: "cap-exercices",
                });
            },
            formatError(errors) {
                return Utils.formatError(errors);
            },
        }
    }
</script>
