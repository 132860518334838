<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" headerSize="110px">
        <template v-slot:header>
            <h2>Société</h2>
            <v-tabs v-model="currentTab">
                <v-tab key="general">Général</v-tab>
                <v-tab key="documents_partages">Documents partagés</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="general">
                    <v-row v-if="company" class="pl-3" no-gutters>
                        <v-col md="12" lg="12" xl="10">
                            <CompanyInformation :company.sync="company" @refresh-company-event="refreshCompany()"
                                    @undo-event="undo()" ref="companyInfos"/>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item key="documents_partages">
                    <h3 class="my-12 pl-12">Documents partagés</h3>
                    <v-row v-if="hasAccessParticipation()">
                        <v-document-pb-accord
                            :canUpload="true"
                            :canDelete="true"
                        />
                    </v-row>
                    <v-row v-if="hasAccessInteressement()">
                        <v-document-inte-accord
                            :canUpload="true"
                            :canDelete="true"
                        />
                    </v-row>
                    <v-row v-if="hasAccessCapital()">
                        <v-document-statuts
                            :canUpload="true"
                            :canDelete="true"
                        />
                    </v-row>
                    <template>
                        <v-datatable-docs-list
                            :documentsList="docs"
                            @new-uploaded-doc="getAllAutreDocuments(typeDocumentGeneral)"
                            @on-delete-document="getAllAutreDocuments(typeDocumentGeneral)"
                            :collaborateurId="collaborateurId"
                            :typeDocument="typeDocumentGeneral"
                            :canDelete="true"
                            :canUpload="true"
                            :hasAccess="true"
                        />
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>

    import authService from "@/services/authService";
    import CompanyInformation from "@/views/common/CompanyInformation.vue";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VDatatableDocsList from "@/views/documents/VDatatableDocsList.vue";
    import VDocumentPbAccord from "@/components/documents/VDocumentPbAccord";
    import VDocumentInteAccord from "@/components/documents/VDocumentInteAccord";
    import VDocumentStatuts from "@/components/documents/VDocumentStatuts";

    /**
     * Vue Affichant les informations de la société
     * @displayName Commun - Company
     */
    export default {
        components: {
            CompanyInformation,
            BaseLayout,
            loadingScreen,
            VDatatableDocsList,
            VDocumentPbAccord,
            VDocumentInteAccord,
            VDocumentStatuts,
        },
        data() {
            return {
                //Data shared with children
                company: undefined,
                currentTab: null,
                docs: [],
                formData: new FormData(),
                collaborateurId: 0,
                typeDocumentGeneral: "General",
            };
        },
        
        mounted() {
            this.getCompany();
            this.getAllAutreDocuments(this.typeDocumentGeneral);
        },
        //Methods shared with children
        methods: {
            getCompany() {
                return internalApi.company.getVersionCompany()
                    .then(result => {
                        this.company = this.handleCompanyInfosBancaire(result);
                    });
            },
            getAllAutreDocuments() {
                return internalApi.documents.getDocumentsGeneral()
                    .then(res => {
                        this.docs = res;
                    });
            },
            hasAccessParticipation: function () {
                return authService.hasAccessParticipation();
            },
            hasAccessCapital: function () {
                return authService.hasAccessCapital();
            },
            hasAccessInteressement: function () {
                return authService.hasAccessInteressement();
            },
            undo() {
                this.getCompany();
            },
            refreshCompany() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
                const dataToSend = this.$refs.companyInfos.getInfosCompany();
                return internalApi.company
                    .putByIdWithToastConfirmation(dataToSend.id, dataToSend)
                    .then(result => {
                        this.company = this.handleCompanyInfosBancaire(result);
                        this.$store.commit("SET_CURRENT_COMPANY", result);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * A partir des données de la société, si le champ contenant les infos_bancaire est null, créer un objet
             * sous ce champ contenant les champs nécessaires à la saisie utilisateur, puis renvoie l'objet société
             * complété
             * @param companyData
             * @returns {*}
             */
            handleCompanyInfosBancaire(companyData) {
                let newCompanyData = _.cloneDeep(companyData);
                if(_.isNil(newCompanyData.infos_bancaire)) {
                    newCompanyData.infos_bancaire = {
                        nom_banque: undefined,
                        bic: undefined,
                        iban: undefined,
                    };
                }
                return newCompanyData;
            },
        },
    };
</script>
