<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialogDetail" max-width="600px" :disabled="disabledValue">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" dark color="primary" outlined :disabled="disabledValue" block>
                <v-icon dark left>{{"$vuetify.icons.info"}}</v-icon>
                Détail calcul
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Détail du calcul des intérêts bruts
            </v-card-title>

            <v-card-text>
                <br/>
                <div class="subheading">Règle d'éligibilité appliquée aux mouvements de parts :
                    <span class="font-weight-bold">{{explain.regle_elligibilite}}</span>
                </div>
                <br/>
                <div>
                    <span class="title">Affectation de base {{explain.montant_base | roundEuroFilter}}</span>
                    <br/>
                    <span class="sub-title">Rémunération à taux fixe</span>
                </div>
                <br/>
                <div>
                    <div v-for="categ in explain.detail_categories" :key="categ.code">
                        Catégorie {{categ.code}} : <span
                        class="font-weight-bold">{{categ.total | roundEuroFilter }}</span> soit
                        <span class="font-weight-bold">{{categ.nb_parts | roundNumberFilter}}</span> part(s)
                        rémunérée(s)
                        à hauteur de
                        <span class="font-weight-bold">{{categ.valeur_part | roundEuroFilter }}</span>
                        <v-divider/>
                    </div>
                </div>
                <br/>
                <div class="title">Affectation du montant résiduel {{explain.montant_residuel |
                    roundEuroFilter}}
                </div>
                <br/>
                Valeur de part résiduelles: <span class="font-weight-bold">{{explain.valeur_part_residuelle | roundEuroFilter}}</span>
                <br/>
                <br/>
                <div>
                    <div v-for="categ in explain.detail_categories_residu" :key="categ.code">
                        Catégorie {{categ.code}} : <span
                        class="font-weight-bold">{{categ.total | roundEuroFilter }}</span> soit
                        <span class="font-weight-bold">{{categ.nb_parts | roundNumberFilter }}</span> part(s)
                        rémunérée(s)
                        à hauteur de
                        <span class="font-weight-bold">{{categ.valeur_part | roundEuroFilter }}</span>
                        <v-divider/>
                    </div>
                </div>
                <br/>
                <div class="title">Affectation totale {{explain.montant_total | roundEuroFilter}}</div>
                <br/>
                <div>
                    <div v-for="categ in explain.detail_categories_total" :key="categ.code">
                        Catégorie {{categ.code}} : <span
                        class="font-weight-bold">{{categ.total | roundEuroFilter}}</span> soit
                        <span class="font-weight-bold">{{categ.nb_parts | roundNumberFilter}}</span> part(s)
                        rémunérée(s)
                        à hauteur de
                        <span class="font-weight-bold">{{categ.valeur_part | roundEuroFilter}}</span>
                        <v-divider/>
                    </div>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-row class="mx-0" justify="end">
                    <!-- Bouton modal -->
                    <v-col md="4" lg="4" xl="4">
                        <v-btn color="primary" text @click="dialogDetail = false" block>
                            Fermer
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /**
     * Composant affichant une fenêtre modale qui met en forme l'explication de la
     * répartition des intérêts aux parts sociales
     * @displayName Capital VDetailRepartitionCap
     */
    export default {
        name: "v-detail-repartition-cap",
        props: {
            /**
             * Les informations sur comment la répartition des intérêts
             * aux parts sociales a été distributée
             */
            explain: {
                type: Object
            },
            disabledValue: {
                type: Boolean
            }
        },
        data() {
            return {
                dialogDetail: false
            }
        }
    }
</script>