<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-autocomplete v-model="localSupportValue"
                        :label="label"
                        :items="supportList"
                        :error-messages="errors"
                        :disabled="!this.isEnabled"/>
    </div>
</template>

<script>

    export default {
        name: "v-supports-list",
        props: {
            withDefaultValue: {
                type: Boolean,
                required: false,
                default: false
            },
            supportFilter: {
                type: String,
                required: false,
                default: undefined,
            },
            isEnabled: {
                type: Boolean,
                required: false,
                default: true,
            },
            value: {
                type: Number,
                required: false,
                default: undefined,
            },
            errors: {
                type: Array,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: ""
            }
        },
        mounted() {
            this.fetchSupportList();
        },
        data() {
            return {
                supportList: []
            }
        },
        computed: {
            localSupportValue: {
                get: function () {
                    return this.value;
                },
                set: function (newValue) {
                    this.$emit("input", newValue);
                }
            }
        },
    };
</script>


