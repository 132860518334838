import Index from "@/views/Index";
import PbDettes from "@/views/participation/reglements/PbDettes.vue";
import DetailDette from "@/views/common/reglements/DetailDette.vue";
import ReglementDette from "@/views/common/reglements/ReglementDette.vue";
import rolesList from "@/router/rolesList";

export default {
  path: "pb-dettes/",
  component: Index,
  children: [
    {
      path: "/",
      name: "pb-dettes",
      component: PbDettes,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/",
      name: "pb-detail-dette",
      component: DetailDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "participation"
      }
    },
    {
      path: ":id/reglement-dette/",
      name: "pb-reglement-dette",
      component: ReglementDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "participation"
      }
    },
  ]
};
