<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêter modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-if="!isModif" v-on="on" color="primary" dark block>{{openButtonMessage}}</v-btn>
            <v-icon-button-with-tool-tip v-else
                                         :detail="tooltipDetailMessage"
                                         :icon="iconValue"
                                         :activator="on"
                                         btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         block
            />
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" ref="formScicCollege">
                 <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                 <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Code du collège de vote" vid="code" rules="required|max:10" v-slot="{ errors }">
                                    <v-text-field v-model.trim="college.code"
                                                label="Code du collège"
                                                :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Libellé du collège de vote" vid="name" rules="required|max:100" v-slot="{ errors }">
                                    <v-text-field v-model.trim="college.name"
                                                label="Libellé du collège"
                                                :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider name="Pourcentage des droits de vote" vid="pourcentage_droit_vote" rules="required|currency|min_value:0|max_value:50" v-slot="{ errors }">
                                    <v-formatted-number-text-field v-model.trim="college.pourcentage_droit_vote"
                                                labelValue="Pourcentage des droits de vote"
                                                :errors="errors"
                                                prefixValue="%"
                                                :reverseValue="true"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3" no-gutters>
                            <v-col>
                                <v-textarea v-model="college.description"
                                            label="Description"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="mx-0">
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>{{labelConfirmation}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import constantes from "@/utils/constantes";

    /**
     * Composant Affichant les données communes de l'exercice
     * @displayName Commun - Exercice
     */
    export default {
        name: "v-scic-college",
        components: {
            VFormattedNumberTextField,
            VIconButtonWithToolTip,
        },
        data() {
            return {
                college: {
                    code: undefined,
                    name: undefined,
                    pourcentage_droit_vote: undefined,
                    description: undefined,
                },
                dialog: false,
                labelAnnulation: "Non, abandonner",
                labelConfirmation: "Oui, confirmer",
            };
        },
        computed: {
            isModif() {
                return undefined;
            },
            title() {
                return undefined;
            },
            openButtonMessage() {
                return undefined;
            },
            tooltipDetailMessage() {
                return undefined;
            },
            iconValue() {
                return undefined;
            },
        },
        methods: {
            submit() {
            },
            closePopUp() {
                this.dialog = false;
                this.college.code = undefined;
                this.college.name = undefined;
                this.college.pourcentage_droit_vote = undefined;
                this.college.description = undefined;
                this.$refs.formScicCollege.reset();
            },
        }
    };
</script>
