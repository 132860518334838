<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-datatable-tiers-list :personnesList="personnesList"
                                :total_items="total_items"
                                :isLoading="isLoading"
                                @refresh-tiers-list="function(currentPagination, search, selectedAssocieStateFilter, selectedSalarieStateFilter) {updateTiersList(currentPagination, search, selectedAssocieStateFilter, selectedSalarieStateFilter)}"
                                @on-download-etat-du-capital="downloadEtatDuCapital($event)"
                                @on-download-etat-de-la-participation="downloadEtatDeLaParticipation($event)"
                                routeCreationPerPhy="creation-tiers-personne-physique"
                                routeCreationPerMor="creation-tiers-personne-morale"
                                routeEditionPerPhy="edition-tiers-personne-physique"
                                routeEditionPerMor="edition-tiers-personne-morale"
                                :showCapitalData="hasCapitalAccess()"
                                :showParticipationData="hasParticipationAccess()"
        />
    </div>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import authService from "@/services/authService";
    import constantes from "@/utils/constantes";
    import VDatatableTiersList from "@/components/tiers/VDatatableTiersList.vue";
    import Utils from "@/utils";

    export default {
        components: {
            loadingScreen,
            VDatatableTiersList,
        },
        data() {
            return {
                personnesList: [],
                total_items: 0,
                isLoading: true,
            };
        },
        methods: {
            hasCapitalAccess: function () {
                return authService.hasAccessCapital();
            },
            hasParticipationAccess: function () {
                return authService.hasAccessParticipation();
            },
            downloadEtatDuCapital(assoc_id) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.associe.getEtatDuCapitalById(assoc_id)
                    .then(response => {
                        Utils.downloadFile(response, "etat_du_capital.pdf", Utils.pdfMimetype);
                    })
                    .catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                });
            },
            downloadEtatDeLaParticipation(benef_id) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.beneficiaire.getEtatDeLaParticipation(benef_id)
                    .then(response => {
                        Utils.downloadFile(response, "etat_de_la_participation.pdf", Utils.pdfMimetype);
                    })
                    .catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Met à jour la datatable des tiers en les filtrant
             * @param pagination Les informations de mise en forme des données
             * @param search La chaîne de caractère filtrant les tiers
             */
            updateTiersList(pagination, search, selectedAssocieStateFilter, selectedSalarieStateFilter) {
                this.isLoading = true;
                internalApi.personne.getAll({
                    page: pagination.page,
                    rows_per_page: pagination.itemsPerPage,
                    associe_filter: selectedAssocieStateFilter,
                    salarie_filter: selectedSalarieStateFilter,
                    search: search,
                }).then(result => {
                    this.total_items = result.total_items;
                    this.personnesList = result.personnes;
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
        }
    };
</script>
