import _ from "lodash";

export default {
  state: {
    pagination_tiers: {
      page: 1,
      itemsPerPage: 10,
    },
    pagination_dettes: {
      page: 1,
      itemsPerPage: 10,
    },
    pagination_cap_dettes: {
      page: 1,
      itemsPerPage: 10,
    },
    pagination_pb_dettes: {
      page: 1,
      itemsPerPage: 10,
    },
    pagination_inte_dettes: {
      page: 1,
      itemsPerPage: 10,
    },
  },

  getters: {
    getPaginationTiers: state => () => {
      return _.cloneDeep(state.pagination_tiers);
    },
    getPaginationDettes: state => () => {
      return _.cloneDeep(state.pagination_dettes);
    },
    getPaginationCapDettes: state => () => {
      return _.cloneDeep(state.pagination_cap_dettes);
    },
    getPaginationPbDettes: state => () => {
      return _.cloneDeep(state.pagination_pb_dettes);
    },
    getPaginationInteDettes: state => () => {
      return _.cloneDeep(state.pagination_inte_dettes);
    },
  },

  mutations: {
    SET_PAGINATION_TIERS(state, obj) {
      state.pagination_tiers = obj;
    },
    SET_PAGINATION_DETTES(state, obj) {
      state.pagination_dettes = obj;
    },
    SET_PAGINATION_CAP_DETTES(state, obj) {
      state.pagination_cap_dettes = obj;
    },
    SET_PAGINATION_PB_DETTES(state, obj) {
      state.pagination_pb_dettes = obj;
    },
    SET_PAGINATION_INTE_DETTES(state, obj) {
      state.pagination_inte_dettes = obj;
    },
  },

  actions: {
    resetPaginations({commit}) {
      commit("SET_PAGINATION_TIERS",  {
        page: 1,
        itemsPerPage: 10,
      });
      commit("SET_PAGINATION_DETTES",  {
        page: 1,
        itemsPerPage: 10,
      });
      commit("SET_PAGINATION_CAP_DETTES",  {
        page: 1,
        itemsPerPage: 10,
      });
      commit("SET_PAGINATION_PB_DETTES",  {
        page: 1,
        itemsPerPage: 10,
      });
      commit("SET_PAGINATION_INTE_DETTES",  {
        page: 1,
        itemsPerPage: 10,
      });
    }
  }
};
