<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout :withFooter="false" headerSize="140px">
            <template slot="header">
                <h1>Exercice</h1>
                <v-tabs v-model="currentTab">
                    <v-tab key="donnees_exercice">Données exercice</v-tab>
                    <v-tab key="abo_edition_exercice" :disabled="creationMode || !hasAccessAbondement">Abondement</v-tab>
                    <v-tab key="abo_donnees_associes" v-if="!creationMode && hasAccessAbondement">Données associé(e)s</v-tab>
                </v-tabs>
            </template>
            <template slot="main">
                <loading-screen ref="loadingComponent"/>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item key="donnees_exercice">
                        <base-layout :withHeader="false">
                            <template v-slot:main>
                                <v-container>
                                    <common-exercice :date_debut="exercice.date_debut"
                                                    :date_fin="exercice.date_fin"
                                                    :date_ag_annuelle="exercice.date_ag_annuelle"
                                                    :libelle="exercice.libelle"
                                                    :benefices="exercice.benefices"
                                                    :montant_participation_brute="exercice.participation_brute"
                                                    @update-date-debut="exercice.date_debut = $event"
                                                    @update-date-fin="exercice.date_fin = $event"
                                                    @update-date-ag-annuelle="exercice.date_ag_annuelle = $event"
                                                    @update-libelle="exercice.libelle = $event"
                                                    @update-montant-participation-brute="exercice.participation_brute = $event"
                                                    @update-benefices="exercice.benefices = $event"
                                                    :exerciceValide="exerciceValide || exercice.is_common_exercice_any_state_valide">
                                    </common-exercice>
                                    <h3 slot="header">Intérêts aux parts sociales</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <ValidationProvider vid="montant_global_interets_part_sociale"
                                                                    :name="computeLabel(exercice.participation_brute, 'Montant global d\'intérêt','Montant global d\'intérêt - Veuillez renseigner un montant global de PB')"
                                                                    :rules="{ currency: true , between: {'min': 0, 'max': exercice.participation_brute} }"
                                                                    v-slot="{ errors }">
                                                    <v-formatted-number-text-field
                                                        v-model="exercice.cap_exercice.montant_global_interets_part_sociale"
                                                        prefixValue="€"
                                                        :labelValue="computeLabel(exercice.participation_brute, 'Montant global d\'intérêt','Montant global d\'intérêt - Veuillez renseigner un montant global de PB')"
                                                        :reverseValue="true"
                                                        :errors="errors"
                                                        :disabledValue="exerciceValide || exercice.cap_exercice.is_repartition_valide || !isFilled(exercice.participation_brute)"/>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5" v-if="is_regle_eligibilite_parts_possedees_a_date">
                                                <ValidationProvider vid="date_repartition"
                                                                    name="Date de répartition"
                                                                    :rules="{ required: is_regle_eligibilite_parts_possedees_a_date, date_after: exercice.date_debut, date_before: exercice.date_ag_annuelle }"
                                                                    v-slot="{ errors }">
                                                    <DateField
                                                        v-model="exercice.cap_exercice.date_repartition"
                                                        label="Date de répartition"
                                                        :errors="errors"
                                                        :disabled="exerciceValide || exercice.date_ag_annuelle === undefined || exercice.date_debut === undefined"/>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Remboursement dérogatoire​</h3>
                                    <v-container pt-0>
                                        <v-row align="center" justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="preciser_valeur_remboursement_part"
                                                            label="Attribution d'une valeur dérogatoire pour l'exercice"
                                                            :disabled="exerciceValide"
                                                            @change="clearFieldIfNonValeurDerogatoire()"/>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-row align="center">
                                                    <v-col md="11" lg="11" xl="11">
                                                        <ValidationProvider vid="valeur_remboursement_part_sociale_temp"
                                                                            name="Valeur de remboursement des parts sociales"
                                                                            :rules="{ currency: true , between: {'min': 0, 'max': capitalMontantSouscriptionPart} }"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field v-model="valeur_remboursement_part_sociale_temp"
                                                                                        prefixValue="€"
                                                                                        labelValue="Valeur de remboursement des parts sociales"
                                                                                        :reverseValue="true"
                                                                                        :errors="errors"
                                                                                        :disabledValue="exerciceValide || !preciser_valeur_remboursement_part"/>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                                            </template>
                                                            <span v-html="messageValeurRemboursement"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Affectation des intérêts aux parts sociales</h3>
                                    <v-container pt-0>
                                        <v-row align="center" justify="space-between" dense>
                                            <v-col md="6" lg="3" xl="3">
                                                <ValidationProvider
                                                    vid="affectation_ips"
                                                    name="Affectation des intérêts aux parts sociales"
                                                    rules="required"
                                                    v-slot="{ errors }"
                                                >
                                                    <ul class="fields-list">
                                                        <li class="order">Choisir au moins une option</li>
                                                        <li>
                                                            <v-row no-gutters>
                                                                 <v-col md="9" lg="10" xl="5">
                                                                    <v-checkbox v-model="affectation_ips"
                                                                                value="allow_affectation_ips_versement_immediat"
                                                                                label="Versement immédiat"
                                                                                :disabled="is_affectation_valide"
                                                                                :error="errors.length > 0"
                                                                    />
                                                                </v-col>
                                                                <v-col>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small left v-on="on"> {{ canEditVersementImmediat ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                                        </template>
                                                                        <span 
                                                                            v-html="getTooltipTextIconeCadenas(canEditVersementImmediat)"/>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </li>
                                                        <li>
                                                            <v-row no-gutters>
                                                                <v-col md="9" lg="10" xl="5">
                                                                    <v-checkbox v-model="affectation_ips"
                                                                                value="allow_affectation_ips_montee_capital"
                                                                                label="Montée au capital"
                                                                                :disabled="is_affectation_valide"
                                                                                :error="errors.length > 0"
                                                                    />
                                                                </v-col>
                                                                <v-col>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small left v-on="on"> {{ canEditMonteeCapital ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                                        </template>
                                                                        <span 
                                                                            v-html="getTooltipTextIconeCadenas(canEditMonteeCapital)"/>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </li>
                                                        <li>
                                                            <v-row no-gutters>
                                                                <v-col md="9" lg="10" xl="5">
                                                                    <v-checkbox v-model="affectation_ips"
                                                                                value="allow_affectation_ips_compte_courant"
                                                                                label="Compte courant d'associé(e)"
                                                                                :disabled="is_affectation_valide"
                                                                                :error="errors.length > 0"
                                                                                :error-messages="errors"
                                                                    />
                                                                </v-col>
                                                                <v-col>
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small left v-on="on"> {{ canEditCompteCourant ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                                        </template>
                                                                        <span 
                                                                            v-html="getTooltipTextIconeCadenas(canEditCompteCourant)"/>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </li>
                                                    </ul>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <ValidationProvider
                                                    vid="affectation_default"
                                                    name="Affectation des intérêts aux parts sociales"
                                                    rules="is_default_affectation_valid:@affectation_ips"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-select v-model="exercice.cap_exercice.affectation_default"
                                                            label="Affectation par défaut"
                                                            :disabled="is_affectation_valide"
                                                            :items="affectationDefaultItems"
                                                            clearable
                                                            :error="errors.length > 0"
                                                            :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                            </template>
                            <template slot="footer">
                                <v-row justify="end" no-gutters>
                                    <!-- Petits boutons -->
                                    <v-col v-if="!is_affectation_valide" md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                    :resetForm="reset"
                                                                    :errorsList="errors"
                                                                    @emit-cancel="returnToExerciceList"
                                                                    @emit-valid-save="save"/>
                                    </v-col>
                                    <!-- Petit bouton -->
                                    <v-col v-else md="3" lg="3" xl="2">
                                        <v-btn block @click="returnToExerciceList" color="primary">Retour</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </v-tab-item>
                    <v-tab-item key="abo_edition_exercice">
                        <abo-exercice v-if="hasAccessAbondement"
                                      :exercice="exercice"
                                      @update-exercice="exercice = $event"
                        />
                    </v-tab-item>
                    <v-tab-item key="abo_donnees_associes">
                        <!-- Le composant est retiré du rendu quand son onglet n'est pas affiché
                        pour éviter des comportement indésirables lors de la modification de l'abo_exercice -->
                        <abo-donnees-associes v-if="hasAccessAbondement && currentTab == 2"
                                              :exercice="exercice"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import CommonExercice from "@/components/exercice/CommonExercice";
    import AboExercice from "@/views/abondement/AboExercice.vue";
    import AboDonneesAssocies from "@/views/abondement/AboDonneesAssocies.vue";
    import authService from "../../../services/authService";
    import Utils from "@/utils";
    import { extend } from 'vee-validate';

    const AFFECTATION_IPS_FIELDS = [
        "allow_affectation_ips_versement_immediat",
        "allow_affectation_ips_montee_capital",
        "allow_affectation_ips_compte_courant"
    ];

    extend('is_default_affectation_valid', {
        params: ['affectation_options'],
        validate(value, { affectation_options }) {
            switch (value) {
                case "VERSEMENT_IMMEDIAT":
                    return affectation_options.includes("allow_affectation_ips_versement_immediat");
                case "MONTEE_CAPITAL":
                    return affectation_options.includes("allow_affectation_ips_montee_capital");
                case "COMPTE_COURANT":
                    return affectation_options.includes("allow_affectation_ips_compte_courant");
                default:
                    return true;
            }
        },
        message: "L'affectation par défaut doit être active",
    });

    /**
     * Composant affichant les informations liées à un exercice côté capital
     * @displayName Capital - CapExercice
     */
    export default {
        components: {
            DateField,
            VFormattedNumberTextField,
            VSaveButonWithValidation,
            loadingScreen,
            BaseLayout,
            CommonExercice,
            AboExercice,
            AboDonneesAssocies,
        },
        props: {
            /**
             * Détermine si on est en train de créer ou de modifier l'exercice
             */
            creationMode: undefined
        },
        data() {
            return {
                currentTab: undefined,
                exercice: {
                    is_common_exercice_any_state_valide: true,
                    cap_exercice: {
                        allow_affectation_ips_versement_immediat: true,
                        allow_affectation_ips_montee_capital: false,
                        allow_affectation_ips_compte_courant: false,
                        affectation_default : null,
                    },
                },
                affectation_ips: [],
                exerciceValide: false,
                preciser_valeur_remboursement_part: false,
                valeur_remboursement_part_sociale_temp: null,
                messageValeurRemboursement: "L'enregistrement d'une valeur de remboursement dérogatoire conduit à attribuer une valeur de remboursement fixe aux parts sociales annulées sur l'exercice.",
                capitalMontantSouscriptionPart: undefined,
                company: undefined,
                affectationDefaultItems: [],
                labelsAffectationDefault: [],
                is_affectation_valide: false,
                droitAccesSubText: `sur l'espace personnel. <br/> Ce paramètre peut être modifié depuis les droits d'accès.`,
                canEditMonteeCapital: false,
                canEditVersementImmediat: false,
                canEditCompteCourant: false,
                is_regle_eligibilite_parts_possedees_a_date: false,
            };
        },
        watch: {
            currentTab(newValue) {
                // Re-fetcher l'exercice si on va sur l'onglet "Données associé(e)s"
                // et qu'on a accès à l'abondement
                if (newValue == 2 && this.hasAccessAbondement) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                    this.fetchExerciceById(this.exerciceId).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }
            },
        },
        mounted() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            this.is_regle_dividende_eligibilite_date_reference();
            let promises = [];

            let promise_labels = this.getLabels();
            promises.push(promise_labels);
            let promise_company = this.getCompany();
            promises.push(promise_company);
            if (!this.creationMode) {
                let promise_exercice = this.fetchExerciceById(this.exerciceId);
                promises.push(promise_exercice);
            } else {
                this.exercice = {
                    date_debut: undefined,
                    date_fin: undefined,
                    date_ag_annuelle: undefined,
                    libelle: undefined,
                    benefices: undefined,
                    participation_brute: undefined,
                    cap_exercice: {
                        valeur_remboursement_part_sociale: undefined,
                        montant_global_interets_part_sociale: undefined,
                        date_repartition: undefined,
                        allow_affectation_ips_versement_immediat: true,
                        allow_affectation_ips_montee_capital: false,
                        allow_affectation_ips_compte_courant: false,
                    },
                }
            }

            let promise_souscription = this.getCapitalMontantSouscriptionPart();
            promises.push(promise_souscription);

            Promise.all(promises).then(res => {
                this.initCheckboxListAffectationIps();
                this.computeAffectationDefaultItems();
                this.$refs.loadingComponent.resetLoading();
            });
            this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_capital_affectation_versement_immediat,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
            this.canEditMonteeCapital = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_capital_affectation_montee_capital,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
            this.canEditCompteCourant = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_capital_affectation_compte_courant,
                [constantes.droits_acces.valeurs_possibles.edition],
            )
        },
        computed: {
            hasAccessParticipation() {
                return authService.hasAccessParticipation();
            },
            hasAccessInteressement() {
                return authService.hasAccessInteressement();
            },
            hasAccessAbondement() {
                return authService.checkHasAbondementExtensionAccess();
            },
            exerciceId() {
                return this.creationMode ? null : this.$route.params.exerciceId;
            }
        },
        methods: {
            fetchExerciceById(exerciceId) {
                return this.getCapExerciceById(exerciceId).then(result => {
                    this.exerciceValide = this.exercice.cap_exercice.is_repartition_valide;
                    this.is_affectation_valide = this.exercice.cap_exercice.is_affectation_valide;
                    this.preciser_valeur_remboursement_part = this.exercice.cap_exercice.valeur_remboursement_part_sociale != null;
                    this.valeur_remboursement_part_sociale_temp = this.exercice.cap_exercice.valeur_remboursement_part_sociale;
                });
            },
            computeAffectationDefaultItems() {
                let labelsOrder = [
                    "VERSEMENT_IMMEDIAT",
                    "MONTEE_CAPITAL",
                    "COMPTE_COURANT",
                ];
                let affectationDefaultItems = [];

                labelsOrder.forEach(label => {
                    affectationDefaultItems.push({
                        text: this.labelsAffectationDefault[label],
                        value: label,
                    });
                });

                this.affectationDefaultItems = affectationDefaultItems;
            },
            getLabels() {
                return internalApi.capExercice.getLabels().then(
                    result => {
                        this.labelsAffectationDefault = result.affectation_default;
                    }
                );
            },
            initCheckboxListAffectationIps() {
                this.affectation_ips = Utils.fromObjectToCheckboxList(
                    this.exercice.cap_exercice, AFFECTATION_IPS_FIELDS
                );
            },
            setCheckboxListAffectationIps() {
                Utils.fromCheckboxListToObject(
                    this.affectation_ips, this.exercice.cap_exercice, AFFECTATION_IPS_FIELDS
                );
            },
            getCapExerciceById(cap_exercice_id) {
                return internalApi.capExercice.getById(cap_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            getCompany() {
                return internalApi.company.getVersionCompany()
                    .then(result => {
                        this.company = result;
                    });
            },
            /**
             * Vérifier si la règle d'éligibilité des intérêts aux parts sociales est "Parts possédées à la date de répartition"
             * pour afficher ou pas le champ date_repartition
             */
            is_regle_dividende_eligibilite_date_reference() {
                
                return internalApi.capCompany.getCurrentStatutCapCompany()
                    .then(result => {
                        this.is_regle_eligibilite_parts_possedees_a_date = result.interet_regle_elligibilite === "parts_possedees_date_repart"
                    });
            },
            /**
             * Obtient la valeur du montant de souscription de la part sociale
             */
            getCapitalMontantSouscriptionPart() {
                return internalApi.capCompany.getCurrentStatutCapCompany()
                .then(result => {
                    this.capitalMontantSouscriptionPart = result.capital_montant_souscription_part;
                });
            },
            /**
             * Renforce le isNil en vérifiant également si le champ n'est pas la string vide
             * @param field le champ à vérifier
             * @returns {boolean}
             */
            isFilled(field) {
                return !_.isNil(field) && field !== ""
            },
            /**
             * Choisi un label en fonction de si le champ est rempli ou non
             * @param dependantField Le champ rempli ou non
             * @param text Le texte à retourner si le champ est rempli
             * @param nullText Le texte à retourner sinon
             * @returns {string}
             */
            computeLabel(dependantField, text, nullText) {
                if (this.isFilled(dependantField)) {
                    return text;
                } else {
                    return nullText;
                }
            },
            /**
             * Crée/Met à jour l'exercice en fonction de si on est en mode création ou édition
             */
            save() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                this.exercice.company_id = this.company.id;
                if (this.preciser_valeur_remboursement_part) {
                    this.exercice.cap_exercice.valeur_remboursement_part_sociale = this.valeur_remboursement_part_sociale_temp;
                } else {
                    this.exercice.cap_exercice.valeur_remboursement_part_sociale = null;
                }
                this.setCheckboxListAffectationIps();

                if (this.creationMode) {
                    internalApi.capExercice
                        .postAllWithToastConfirmation(this.exercice)
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            console.error(err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                } else {
                    const data_to_send = _.cloneDeep(this.exercice);
                    // On ne veut pas pouvoir mettre ces données à jour depuis cet écran
                    delete data_to_send.id;
                    delete data_to_send.cap_exercice.id;
                    delete data_to_send.pb_exercice;
                    delete data_to_send.inte_exercice;
                    delete data_to_send.abo_exercice;
                    internalApi.capExercice
                        .putByIdWithToastConfirmation(
                            this.exercice.id,
                            data_to_send
                        )
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            console.error(err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            /**
             * Affiche la vue de la liste des exercices côté capital
             */
            returnToExerciceList() {
                this.$router.push({
                    name: "cap-exercices"
                });
            },
            /**
             * Clear la valeur de remboursement si on ne la précise pas
             */
            clearFieldIfNonValeurDerogatoire() {
                if (!this.exercice.preciser_valeur_remboursement_part) {
                    this.valeur_remboursement_part_sociale_temp = null;
                }
            },
            hasSelectedAffectationIps: function () {
                return (
                    this.exercice.cap_exercice.allow_affectation_ips_versement_immediat ||
                    this.exercice.cap_exercice.allow_affectation_ips_montee_capital ||
                    this.exercice.cap_exercice.allow_affectation_ips_compte_courant
                )
            },
            getTooltipTextIconeCadenas(estDeverrouille) {
                const estDeverrouilleStr = estDeverrouille ? "déverrouillée" : "verrouillée";
                return `L'option d'affectation est ${estDeverrouilleStr} sur l'espace personnel. <br/> Ce paramètre peut être modifié depuis les droits d'accès.`;
            },
        }
    };
</script>
