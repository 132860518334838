<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-row no-gutters>
                <h2>Tableau de support aux Imprimés Fiscaux Uniques</h2>
                <v-col cols="1" class="ml-3">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                        </template>
                        <span
                            v-html="`La version des Cerfa retenue par l'outil est celle valable à la date du règlement le plus récent de l'exercice`">
                        </span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-around" no-gutters>
                <v-col md="4" lg="4" xl="3">
                    <span v-if="univers === 'CAPITAL'">Données concernant l'exercice clos le {{ dateFin | dateIsoToFrFilter }}</span>
                    <span v-else>Données concernant l'année {{ year }}</span>
                    <br/>
                    <br/>
                    <span>Prélèvement forfaitaire : {{ tauxPrelevementForfaitaire | roundPercentFilter }}</span>
                </v-col>
                <v-col md="8" lg="8" xl="4">
                    <v-detail-societe :dateVersionSociete="dateFin"/>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
                <v-col md="12" lg="12" xl="12">
                    <v-data-table ref="ifu_datatable"
                                  class="elevation-1"
                                  fixed-header
                                  no-data-text="Aucune donnée."
                                  :footer-props="footerProps"
                                  :headers="headers"
                                  :items="detailIfu"
                                  :server-items-length="total_items"
                                  :loading="loading"
                                  :max-height="datatableSize"
                                  :options.sync="pagination">
                        <template v-slot:item="{ item }">
                            <tr :class="{'grey lighten-1': item.exclu_documents_legaux}">
                                <td>
                                    <v-row align="center" justify="space-between">
                                        <span class="text-xs-left">{{ item.name }}</span>
                                        <v-tooltip right v-if="item.exclu_documents_legaux">
                                            <template v-slot:activator="{ on }" class="text-xs-right">
                                                <v-icon small right v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                            </template>
                                            <span>Exclu(e) de l'édition des documents légaux</span>
                                        </v-tooltip>
                                    </v-row>
                                </td>
                                <td>{{ item.numero_tiers }}</td>
                                <td v-html="formatAddress(item)"/>
                                <td>{{ item.identifiant }}</td>
                                <td>{{ item.lieu_naissance }}</td>
                                <td class="text-lg-right">{{ item.dep_naissance }}</td>
                                <td class="text-lg-right">{{ item.interets_percus | roundEuroFilter(0) }}</td>
                                <td class="text-lg-right">{{ item.credit_impot | roundEuroFilterNotDisplayZero(0) }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <template slot="footer">
            <v-row justify="end" dense>
                <!-- pop-up -->
                <v-col md="4" lg="4" xl="3" v-if="univers === 'CAPITAL'">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-dialog-annee-reglement-ag
                                openButtonMessage="Générer le fichier de télétransmission"
                                title="Générer le fichier de télétransmission"
                                :isIcon="false"
                                :anneeAg="anneeAg"
                                :activator="on"
                                @update-data-teletransmission="downloadTeletransmission($event)"
                            />
                        </template>
                        <span>{{messageGenererFichierTeletransmisson}}</span>
                    </v-tooltip>
                </v-col>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn color="primary" @click="downloadIfu2561" block>
                        <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                        Générer le CERFA&nbsp;2561
                    </v-btn>
                </v-col>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn color="primary" @click="downloadIfu2561Ter" block>
                        <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                        Générer le CERFA&nbsp;2561&nbsp;ter
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import VDetailSociete from "@/components/VDetailSociete.vue"
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VDialogAnneeReglementAg from "@/components/VDialogAnneeReglementAg.vue";
    import BaseLayout from "@/components/BaseLayout";
    import Utils from "@/utils";
    import constantes from "@/utils/constantes.js"
    import {TOAST} from "@/plugins/toast/definition";

    /**
     * Composant affichant les informations relatives à l'IFU
     * @display_name Commun - IFU
     */
    export default {
        name: "v-ifu",
        components: {
            BaseLayout,
            loadingScreen,
            VDialogAnneeReglementAg,
            VDetailSociete,
        },
        data() {
            return {
                detailIfu: [],
                total_items: 0,
                dateFin: undefined,
                anneeAg: undefined,
                tauxPrelevementForfaitaire: undefined,
                headers: [],
                loadingMsgIFU: "L’édition des IFU de la société nécessite un traitement susceptible de durer plusieurs minutes.",
                loading: true,
                pagination: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ["name"],
                    sortDesc: [false]
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                messageGenererFichierTeletransmisson: "Le transfert des déclarations annuelles des revenus de capitaux mobiliers peut se faire par transmission d’un fichier TD Bilatéral (CERFA 2561)."
            }
        },
        props: {
            /**
             * Indique l'univers sélectionné
             */
            univers: {
                required: true
            },
            /**
             * L'id de l'exercice à traiter
             */
            exerciceId: {
                required: true
            },
            /**
             * L'année sélectionnée (ex: 2020, 2029, ...)
             */
            year: {
                required: true
            },
            /**
             * Le mois sélectionné (1 pour Janvier, ..., 12 pour Décembre)
             */
            month: {
                required: true
            },
            /**
             * Le modèle de cerfa à utiliser
             */
            selectedCerfa: {
                type: Object
            }
        },
        watch: {
            pagination: {
                handler() {
                    this.loadDataSync();
                },
                deep: true
            },
            exerciceId() {
                this.loadDataSync();
            },
        },
        mounted() {
            this.headers = this.computeHeaders();
        },
        methods: {
            computeHeaders(){
                //overriden
            },
            loadDataSync() {
                let finalData;
                this.loadData().then(data => {
                    finalData = data;
                }).catch(rejected => {
                    finalData = rejected;
                }).finally(() => {
                    this.detailIfu = finalData.items;
                    this.total_items = finalData.total;
                    this.dateFin = finalData.dateFin;
                    this.anneeAg = finalData.anneeAg;
                    this.tauxPrelevementForfaitaire = finalData.tauxPrelevementForfaitaire;
                });
            },
            loadData(){
                //overriden
            },
            /**
             * Vérifie qu'on a bien sélectionné un cerfa
             * @returns true si tout va bien false sinon
             */
            checkCanDownloadIfu: function() {
                if (this.selectedCerfa === undefined){
                    TOAST.error(constantes.toast_message.missingCerfaTitle, constantes.toast_message.missingCerfaBody);
                    return false;
                }
                return true;
            },
            downloadIfu2561(){
                //overriden
            },
            downloadIfu2561Ter(){
                //overriden
            },
            formatAddress(item) {
                return Utils.formatAddress(item.adresse);
            },
        }
    }
</script>