// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import CategorieCapital from "./CategorieCapital";

export default {
  mounted() {
    this.isEditable = true;
    let categorieCapitauxId = this.$route.params.categorieCapitalId;
    internalApi.categorieCapital.getStatutCategById(categorieCapitauxId)
      .then(result => {
        this.categorieCapital = result;
      })
      .catch(error => console.error(error));
  },
  extends: CategorieCapital,
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      internalApi.categorieCapital.putByIdWithToastConfirmation(
          this.categorieCapital.categorie_capital_id,
          this.categorieCapital
        )
        .then(result => {
          this.$router.push({
            name: "statuts"
          });
        });
    }
  }
};
