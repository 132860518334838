import Index from "@/views/Index";
import rolesList from "@/router/rolesList";
import DeblocagesList from "@/views/participation/deblocage/DeblocagesList";
import DeblocageAnticipeConsultation from "@/views/participation/deblocage/DeblocageAnticipeConsultation";
import DeblocageAnticipeConsultationIndividuel from "@/views/participation/deblocage/DeblocageAnticipeConsultationIndividuel";
import DeblocageAnticipeCreation from "@/views/participation/deblocage/DeblocageAnticipeCreation";
import DeblocageTermeConsultation from "@/views/participation/deblocage/DeblocageTermeConsultation";
import DeblocageTermeConsultationIndividuel from "@/views/participation/deblocage/DeblocageTermeConsultationIndividuel";
import DeblocageTermeCreation from "@/views/participation/deblocage/DeblocageTermeCreation";
import DeblocagesGroupes from "@/views/participation/deblocage/DeblocagesGroupes";

export default {
  path: "pb-deblocages/",
  component: Index,
  children: [
    {
      path: "",
      name: "pb-deblocages",
      component: DeblocagesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "anticipe/:id/",
      name: "deblocage-anticipe-consultation-individuel",
      component: DeblocageAnticipeConsultationIndividuel,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.TIERS],
      },
    },
    {
      path: "groupes/",
      name: "deblocages-groupes",
      component: DeblocagesGroupes,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "anticipe/create/",
      name: "deblocage-anticipe-creation",
      component: DeblocageAnticipeCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "anticipe/:id/",
      name: "deblocage-anticipe",
      component: DeblocageAnticipeConsultation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "terme/create/",
      name: "deblocage-terme-creation",
      component: DeblocageTermeCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "terme/:id/",
      name: "deblocage-terme-consultation-individuel",
      component: DeblocageTermeConsultationIndividuel,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.TIERS],
      },
    },
    {
      path: "terme/:id/",
      name: "deblocage-terme",
      component: DeblocageTermeConsultation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE, rolesList.TIERS],
      },
    },
  ]
};