import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";

export default interetApi;

function interetApi() {
  return {
    getPropositionRepartitionCapByExerciceId: getPropositionRepartitionCapByExerciceId(),
    getRepartitionCapByExerciceId: getRepartitionCapByExerciceId(),
    postRepartitionCapByExerciceId: postRepartitionCapByExerciceId(),
    getRepartitionCapByExerciceIdEspaceAssocie: getRepartitionCapByExerciceIdAssocie(),
    getStateFichesIndividuelles: getStateFichesIndividuelles(),
    generateFichesIndividuelles: generateFichesIndividuelles(),
    getFichesIndividuelles: getFichesIndividuelles(),
    getCapFicheIndividuelle: getCapFicheIndividuelle(),
  };
}

function getPropositionRepartitionCapByExerciceId() {
  function getPropositionInteret(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.proposition(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getPropositionInteret;
}

function getRepartitionCapByExerciceId() {
  function getInteret(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.baseUrl(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getInteret;
}

function getRepartitionCapByExerciceIdAssocie() {
  function getInteretAssocie(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.espaceAssocie(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getInteretAssocie;
}

function postRepartitionCapByExerciceId() {
  function postInteret(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.baseUrl(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postInteret;
}

// Fiches individuelles asynchrones
function getStateFichesIndividuelles() {
  function getState(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.getStateFichesIndividuelles(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getState;
}

function generateFichesIndividuelles() {
  function generate(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.generateFichesIndividuelles(capExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generate;
}

function getFichesIndividuelles() {
  function getFichesIndividuelles(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.fichesIndividuelles(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getFichesIndividuelles;
}
// Fiche individuelle individuel
function getCapFicheIndividuelle() {
  function fiche(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.capExercices.repartition.ficheIndividuelle(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return fiche
}

