import NewVersion from "@/components/versions/NewVersion";
import internalApi from "@/api/internalApi";
import {TOAST} from "@/plugins/toast/definition";

export default {
  mounted() {
    this.messageAvertissement = `La création d'une nouvelle version de statut permet d'enregistrer 
    des nouveaux paramètrages. Confirmez-vous cette opération ?`;
    this.buttonText = `Créer une version des statuts`;
    this.title = `Créer une version des statuts`;
  },
  extends: NewVersion,
  name: "new-statut",
  props: {
    categoriesCapital: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitNewVersion() {
      for (let i = 0; i < this.categoriesCapital.length; i++){
        delete this.categoriesCapital[i].showIcon;
      }
      this.localNouvelleVersion.nom_version = this.nom_version;
      this.localNouvelleVersion.date_debut = this.date_debut;
      const statutToSubmit = {
        "cap_company": this.localNouvelleVersion,
        "categories_capital": this.categoriesCapital,
      };
      internalApi.capCompany.createStatut(statutToSubmit).then(result => {
        this.$emit("on-version-created");
        this.closePopUp();
      }).catch(error => {
        console.error(error);
      });
    },
  }
};
