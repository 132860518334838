import _ from "lodash";

export default {
  state: {
    filtres_mouvements: {},
    filtres_mouvements_associe: {},
    filtres_deblocages: {},
    filtres_dettes: {},
    filtres_tiers: {},
  },

  getters: {
    getFiltresMouvements: state => () => {
      return _.cloneDeep(state.filtres_mouvements);
    },
    getFiltresMouvementsAssocie: state => () => {
      return _.cloneDeep(state.filtres_mouvements_associe);
    },
    getFiltresDeblocages: state => () => {
      return _.cloneDeep(state.filtres_deblocages);
    },
    getFiltresDettes: state => () => {
      return _.cloneDeep(state.filtres_dettes);
    },
    getFiltresTiers: state => () => {
      return _.cloneDeep(state.filtres_tiers);
    }
  },

  mutations: {
    SET_FILTRES_MOUVEMENTS(state, obj) {
      state.filtres_mouvements = obj;
    },
    SET_FILTRES_MOUVEMENTS_ASSOCIE(state, obj) {
      state.filtres_mouvements_associe = obj;
    },
    SET_FILTRES_DEBLOCAGES(state, obj) {
      state.filtres_deblocages = obj;
    },
    SET_FILTRES_DETTES(state, obj) {
      state.filtres_dettes = obj;
    },
    SET_FILTRES_TIERS(state, obj) {
      state.filtres_tiers = obj;
    }
  },

  actions: {
    resetFilters({commit}) {
      commit("SET_FILTRES_MOUVEMENTS", null);
      commit("SET_FILTRES_MOUVEMENTS_ASSOCIE", null);
      commit("SET_FILTRES_DEBLOCAGES", null);
      commit("SET_FILTRES_DETTES", null);
      commit("SET_FILTRES_TIERS", null);
    }
  }
};
