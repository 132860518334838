import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default salarieApi;

function salarieApi() {
  return defaultApi(internalApiRoutes.salaries, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.getLabels
  ]);
}
