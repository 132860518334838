import Index from "@/views/Index";
import Exercice from "@/views/capital/Exercice/CapExercice";
import ExercicesList from "@/views/capital/Exercice/CapExercicesList";
import AboAttribution from "@/views/abondement/AboAttribution.vue";
import RepartitionCap from "@/views/capital/Exercice/RepartitionCap";
import SouscriptionObligatoire from "@/views/capital/Exercice/SouscriptionObligatoire.vue";
import CapAffectation from "@/views/capital/Exercice/CapAffectation.vue";
import rolesList from "@/router/rolesList";

export default {
  path: "exercices/",
  component: Index,
  children: [
    {
      path: "",
      name: "cap-exercices",
      component: ExercicesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/edit/",
      name: "cap-edition-exercice",
      props: {
        creationMode: false
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "create/",
      name: "cap-creation-exercice",
      props: {
        creationMode: true
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/proposition-repartition-cap/",
      name: "proposition-repartition",
      component: RepartitionCap,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/repartition-cap/",
      name: "repartition",
      component: RepartitionCap,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/abo-attribution/",
      name: "abo-attribution",
      component: AboAttribution,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/souscriptions-obligatoires/",
      name: "souscriptions-obligatoires",
      component: SouscriptionObligatoire,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/affectation-interets/",
      name: "cap-affectation-interets",
      component: CapAffectation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    }
  ]
};
