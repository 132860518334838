/* VUETIFY2 - OK */
import VRegleRepartitionPbAnciennete from "./VRegleRepartitionPbAnciennete";
import internalApi from "@/api/internalApi";

export default {
  extends: VRegleRepartitionPbAnciennete,
  computed: {
    title() {
      return "Créer une règle de calcul de répartition selon l'ancienneté";
    },
    isModif() {
      return false;
    },
    openButtonMessage() {
      return "Créer une règle";
    }
  },
  methods: {
    submit() {
      this.$refs.formRegleRepartitionPbAnciennete.validate().then(validForm => {
        if (validForm) {
          let data = _.cloneDeep(this.regle);
          delete data.id;
          data.regle = "plus_de";
          data.pb_company_id = this.pbCompanyId;

          internalApi.repartitionPbAnciennete.postAllWithToastConfirmation(data)
            .then(res => {
              this.$emit("create-regle");
              this.closePopUp();
            })
            .catch(err => {
              console.log(err);
              this.closePopUp();
            });
        }
      });
    }
  }
};