<template>
    <div>
        <v-row>
            <v-col class="py-0">
                <ValidationProvider name="Taux de rémunération" vid="taux" v-slot="{ errors }"
                                    rules="required|between:0,100">
                    <v-formatted-number-text-field v-model="localTauxRemu"
                                                    labelValue="Taux de rémunération"
                                                    prefixValue="%"
                                                    :reverseValue="true"
                                                    :errors="errors"
                                                    :disabledValue="disabled"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table :headers="headersDetailsPropositionTauxRemu"
                                :items="itemsDetailsPropositionTauxRemu"
                                :loading="loadingDetailsPropositionTauxRemu"
                                hide-default-footer
                                dense>
                    <template v-slot:[`item.annee`]="{ item }">
                        {{ item.annee | onlyYearFilter }}
                    </template>
                    <template v-slot:[`item.tmop_applicable`]="{ item }">
                        {{ item.tmop_applicable }} %
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p v-if="tauxRemuPropose != 0">
                    Avec les données actuelles, le taux de rémunération proposé est de {{ tauxRemuPropose }}%.
                </p>
                <p v-if="! isTauxRemuValide" style="color: red; text-align:justify">
                    Attention ! La liste des TMOP n’est pas à jour, l’onglet TMOP doit être actualisé.<br/>
                    {{ tauxRemuInvalideMotif }}
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant un champ taux de rémunération ainsi que la table des tmop récents pour une proposition
     * @displayName Participation - VTauxRemuFieldWithTmop
     */
    export default {
        name: "v-taux-remu-field-with-tmop",
        components: {
            VFormattedNumberTextField,
        },
        props: {
            /**
             * Le Taux de rémunération à saisir
             */
            tauxRemu: {
                type: Number,
                required: false,
            },
            /**
             * S'il faut désactiver la saisie du champ
             */
            disabled: {
                type: Boolean,
                default: false,
            },
            /**
             * La date sur laquelle se baser pour aller cherche la proposition de tmop
             */
            dateReference: {
                type: [Date, String],
                required: false,
            }
        },
        data() {
            return {
                isTauxRemuValide: true,
                tauxRemuInvalideMotif: "",
                tauxRemuPropose: undefined,
                loadingDetailsPropositionTauxRemu: false,
                headersDetailsPropositionTauxRemu: [
                    { text: 'Année', value: 'annee' },
                    { text: 'Semestre', value: 'semestre' },
                    { text: 'TMOP applicable', value: 'tmop_applicable' },
                ],
                itemsDetailsPropositionTauxRemu: [],
            }
        },
        computed: {
            localTauxRemu: {
                get: function () {
                    return this.tauxRemu;
                },
                set: function (newvalue) {
                    this.$emit("update-taux-remu", newvalue);
                }
            },
        },
        watch: {
            dateReference() {
                this.onDateReferenceUpdate()
            }
        },
        mounted() {
            // Si la date est déjà valorisée il faut requêter dès le chargement
            this.onDateReferenceUpdate();
        },
        methods: {
            onDateReferenceUpdate(){
                if (_.isNil(this.dateReference)){
                    return;
                }
                this.fetchDetailsPropositionTauxRemu();
                this.fetchPropositionTauxRemu();
                this.fetchPropositionTauxRemuValide();
            },
            fetchDetailsPropositionTauxRemu() {
                this.loadingDetailsPropositionTauxRemu = true;
                internalApi.tauxMoyenRendementObli.getTauxRemuDetailsProposition(this.dateReference).then(res => {
                    this.itemsDetailsPropositionTauxRemu = res;
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.loadingDetailsPropositionTauxRemu = false;
                });
            },
            fetchPropositionTauxRemu() {
                internalApi.tauxMoyenRendementObli.getTauxRemuProposition(this.dateReference).then(res => {
                    this.tauxRemuPropose = Math.round(res.proposition_taux_remu * 100) / 100;
                    // Event spécial pour différencier la saisie utilisateur de la proposition
                    this.$emit("fetch-new-taux-remu", this.tauxRemuPropose);
                }).catch(err => {
                    console.error(err);
                });
            },
            fetchPropositionTauxRemuValide() {
                internalApi.tauxMoyenRendementObli.getTauxRemuPropositionValide(this.dateReference).then(res => {
                    this.isTauxRemuValide = res.is_valid;
                    this.tauxRemuInvalideMotif = res.motif;
                }).catch(err => {
                    console.error(err);
                });
            },
        }
    }
</script>
