// <!-- VUETIFY2 - OK -->
import Support from "./Support";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";

export default {
  extends: Support,
  data() {
    return {
      isCreation: false,
      pageTitle: "Editer un support de placement de participation",
    };
  },
  mounted() {
    this.getSupportById(this.supportId);
  },
  methods: {
    getSupportById(id) {
      internalApi.pbSupport.getById(id)
        .then(result => {
          this.support = result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    submit() {
      this.$refs.formSupport.validate().then(
        success => {
          if (success) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            const cleaned_support = this.getCleanedSupport();

            internalApi.pbSupport.putByIdWithToastConfirmation(this.support.id, cleaned_support)
              .then(res => {
                this.$refs.loadingComponent.resetLoading();
                this.cancel();
              })
              .catch(err => {
                console.log(err);
                this.$refs.loadingComponent.resetLoading();
              });
          }
        }
      );
    }
  }
}