import internalApi from "@/api/internalApi";
import Utils from "@/utils.js";

export default {
    state: {
        droitsAcces: [],
    },
    getters: {
        getDroitsAcces: state => () => {
            return _.cloneDeep(state.droitsAcces);
        },
        hasDroitAcces: state => (droit_acces_name, required_value) => {
            const flatten_droits_acces = Utils.flattenFamily(state.droitsAcces);
            for(let i = 0; i<flatten_droits_acces.length; i++) {
                if (flatten_droits_acces[i].nom == droit_acces_name && required_value.includes(flatten_droits_acces[i].valeur)) {
                    return true;
                }
            }
            return false;
        },
    },
    mutations: {
        SET_DROITS_ACCES(state, new_list_droits_acces) {
            state.droitsAcces = new_list_droits_acces;
        },
    },
    actions: {
        fetchDroitsAcces({commit}) {
            let promise = internalApi.gestion_droits_acces.getAll()
                .then(res => {
                    commit("SET_DROITS_ACCES", res);
                });

            return promise;
        },
        resetDroitsAcces({commit}) {
            commit("SET_DROITS_ACCES", null);
        },
    },
}
