<!-- VUETIFY2 - OK -->
<template>
    <v-card>
        <v-card-title class="grey lighten-1">
            <b>
                Détail de la société
            </b>
        </v-card-title>
        <v-card-text>
            <v-row no-gutters wrap>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Nom ou raison sociale" :message="company.name"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Adresse" :message="formatAddress()"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Email" :message="company.contact_email"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Numéro de téléphone" :message="company.phone"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="SIREN" :message="company.legal_siren"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="SIRET" :message="company.legal_siret"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Nom responsable" :message="company.nom_responsable"/>
                </v-col>
                <v-col md="6" lg="6" xl="6" class="text-lg-left">
                    <v-not-editable-text-information-display label="Prénom responsable" :message="company.prenom_responsable"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VNotEditableTextInformationDisplay from "@/components/VNotEditableTextInformationDisplay.vue"
    import Utils from "@/utils.js";

    export default {
        name: "v-detail-societe",
        components: {
            VNotEditableTextInformationDisplay,
        },
        props: {
            dateVersionSociete: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                company: {
                    name: undefined,
                    adresse: undefined,
                    phone: undefined,
                    legal_siren: undefined,
                    legal_siret: undefined,
                    contact_email: undefined,
                    nom_responsable: undefined,
                    prenom_responsable: undefined,
                },
            }
        },
        watch: {
            dateVersionSociete() {
                this.fetchCompany();
            }
        },
        methods: {
            formatAddress() {
                return Utils.formatAddress(this.company.adresse);
            },
            fetchCompany() {
                internalApi.company.getVersionCompany(this.dateVersionSociete)
                    .then(result => {
                        this.company = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
        }
    };
</script>
