<!-- VUETIFY2 - OK -->
<template>
    <span>

        <!-- Associé par dates de sociétariat uniquement -->
        <v-tooltip top v-if="isAssocieByDates && !isAssocieByCapital">
            <template v-slot:activator="{ on }">
                <v-icon v-on="{...on}" class="orange--text">
                    {{"$vuetify.icons.squareCheckQuestion"}}
                </v-icon>
            </template>
            <span>Ce tiers est associé(e) actif(ve) à la date du jour mais ne possède pas de capital</span>
        </v-tooltip>

        <!-- Associé par possession de capital uniquement -->
        <v-tooltip top v-else-if="isAssocieByCapital && !isAssocieByDates">
            <template v-slot:activator="{ on }">
                <v-icon v-on="{...on}" class="orange--text">
                    {{"$vuetify.icons.squareCheckQuestion"}}
                </v-icon>
            </template>
            <span>Ce tiers est associé(e) actif(ve) à la date du jour car possède toujours du capital</span>
        </v-tooltip>

        <!-- Associé par dates de sociétariat et par possession de capital -->
        <v-tooltip top v-else-if="isAssocie">
            <template v-slot:activator="{ on }">
                <v-icon v-on="{...on}">
                    {{"$vuetify.icons.squareCheck"}}
                </v-icon>
            </template>
            <span>Ce tiers est associé(e) actif(ve) à la date du jour</span>
        </v-tooltip>

        <!-- Non associé -->
        <v-tooltip top v-else-if="showCrossIfNotAssocie">
            <template v-slot:activator="{ on }">
                <v-icon v-on="{...on}">
                    {{"$vuetify.icons.squareCheckCross"}}
                </v-icon>
            </template>
            <span>Ce tiers n'est pas associé(e) actif(ve) à la date du jour</span>
        </v-tooltip>

    </span>
</template>
<script>
    export default {
        name: "IconeEtatAssocie",
        props: {
            isAssocie: {
                type: Boolean,
                required: false,
                default: false,
            },
            isAssocieByDates: {
                type: Boolean,
                required: false,
                default: false,
            },
            isAssocieByCapital: {
                type: Boolean,
                required: false,
                default: false,
            },
            showCrossIfNotAssocie: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
    }
</script>