import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default documentsApi;

function documentsApi() {
  let specificApi = {
    getAll: getAll(),
    getDocumentsGeneral:getDocumentsGeneral(),
    getAllPersonnel: getAllPersonnel(),
    uploadDoc: uploadDoc(),
    uploadDocPersonnel: uploadDocPersonnel(),
    downloadDoc: downloadDoc(),
    documentAccordDownload: documentAccordDownload(),
    downloadDocPersonnel: downloadDocPersonnel(),
    getByIdPersonnel: getByIdPersonnel(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.documents.baseUrl, [
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.deleteById,
  ]));
}

function getByIdPersonnel() {
  function getDocument(docId, queryParams, headers) {
    let path = internalApiRoutes.documents.getByIdPersonnel(docId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getDocument;
}

function getAll() {
  function getAllDocs(associe_id, type_document, queryParams, headers) {
    let path = internalApiRoutes.documents.documents(associe_id, type_document);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getAllDocs;
}

function getDocumentsGeneral() {
  function getDocumentsGeneral(queryParams, headers) {
    let path = internalApiRoutes.documents.documentsGeneral();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getDocumentsGeneral;
}
function getAllPersonnel() {
  function getAllDocs(type_document, queryParams, headers) {
    let path = internalApiRoutes.documents.documentsPersonnels(type_document);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getAllDocs;
}

function uploadDoc() {
  function upload(collaborateurId, type_document, body, queryParams, headers) {
    let path = internalApiRoutes.documents.documents(collaborateurId, type_document);
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return upload;
}
function uploadDocPersonnel() {
  function upload(type_document, body, queryParams, headers) {
    let path = internalApiRoutes.documents.documentsPersonnels(type_document);
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return upload;
}

function downloadDoc() {
  function download(docId, queryParams, headers) {
    let path = internalApiRoutes.documents.documentDownload(docId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return download;
}
function documentAccordDownload() {
  function download(type_document, queryParams, headers) {
    let path = internalApiRoutes.documents.documentAccordDownload(type_document);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return download;
}
function downloadDocPersonnel() {
  function download(docId, queryParams, headers) {
    let path = internalApiRoutes.documents.documentPersonnelDownload(docId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return download;
}
