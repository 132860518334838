import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";

export default monteeCapitalApi;

function monteeCapitalApi() {
  return {
    getParticipationByExerciceId: getParticipationByExerciceId(),
    postParticipationByExerciceId: postParticipationByExerciceId(),
    validateMontantParticipationByExerciceId: validateMontantParticipationByExerciceId(),
    souscrireParticipationByExerciceId: souscrireParticipationByExerciceId(),
    recalcul: recalcul(),
    getStateBulletinsObligatoires: getStateBulletinsObligatoires(),
    generateBulletinsObligatoires: generateBulletinsObligatoires(),
    getBulletinsObligatoires: getBulletinsObligatoires(),
    
  };
}

function getParticipationByExerciceId() {
  function getParticipation(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.monteesCapitalByExercice(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getParticipation;
}

function postParticipationByExerciceId() {
  function postParticipation(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.monteesCapitalByExercice(exerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return postParticipation;
}

function validateMontantParticipationByExerciceId() {
  function validateMontantParticipation(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.monteesCapitalValidation(exerciceId);
    let validateForPath = apiConstructor.post(path);
    return validateForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return validateMontantParticipation;
}

function souscrireParticipationByExerciceId() {
  function souscrireParticipation(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.monteesCapitalSouscription(exerciceId);
    let souscrireForPath = apiConstructor.post(path);
    return souscrireForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return souscrireParticipation;
}

function recalcul() {
  function postRecalcul(exerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.monteesCapitalRecalcul(exerciceId);
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return postRecalcul;
}

function getStateBulletinsObligatoires() {
  function getState(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.getStateBulletinsObligatoires(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getState;
}

function generateBulletinsObligatoires() {
  function generateBulletins(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.generateBulletinsObligatoires(capExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return generateBulletins;
}

function getBulletinsObligatoires() {
  function getBulletins(capExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.monteesCapital.getBulletinsObligatoires(capExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getBulletins;
}
