import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default periodeTravailApi;

function periodeTravailApi() {
  const specificApi = {
    deletePeriodeTravailById: deletePeriodeTravailById(),
    getPeriodesTravailSalarie: getPeriodesTravailSalarie(),
    getPeriodesTravailInPeriode: getPeriodesTravailInPeriode(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.periodeTravail.baseUrl));
}

function getPeriodesTravailSalarie() {
  function getPeriodesTravail(salarie_id, queryParams, headers) {
    let path = internalApiRoutes.periodeTravail.getPeriodesTravailSalarie(salarie_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getPeriodesTravail;
}

function deletePeriodeTravailById() {
  function deletePeriodeTravail(id, queryParams, headers) {
    let path = internalApiRoutes.periodeTravail.deletePeriodeTravailById(id);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deletePeriodeTravail;
}

function getPeriodesTravailInPeriode() {
  function periodesTravailInPeriode(from_date, to_date, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.periodeTravail.baseUrl;
    queryParams = queryParams || {};
    queryParams.from_date = from_date;
    queryParams.to_date = to_date;
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return periodesTravailInPeriode;
}