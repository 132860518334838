import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";

export default inteSupportApi;

function inteSupportApi() {
  const specificApi = {
    getInteLivretEpargneSalariale: getInteLivretEpargneSalariale(),
  };
  
  return Object.assign(specificApi, defaultApi(internalApiRoutes.inteSupports.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.putById,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.getLabels,
  ]));
}

function getInteLivretEpargneSalariale() {
  function les(body, queryParams, headers) {
    let path = internalApiRoutes.inteSupports.inteLivretEpargneSal();
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return les;
}