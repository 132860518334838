<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors }">
        <base-layout :withFooter="false" headerSize="130px">
            <template v-slot:header>
                <v-container>
                    <h1>Intéressement</h1>
                    <v-row align="center">
                        <v-col cols="6">
                            Veuillez sélectionner un exercice afin d'afficher la répartition et l'affectation de l'intéressement correspondantes :
                        </v-col>
                        <v-col md="6" lg="3" xl="2">
                            <ValidationProvider vid="selectedExercice" name="Exercice"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-exercices-list :value="selectedExerciceId"
                                                @update-selected-exercice="onExerciceChange($event)"
                                                :errors="errors"
                                                ref="exercices"/>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:main>
                <v-container>
                    <loading-screen ref="loadingComponent"/>

                    <h2>Répartition de l’intéressement</h2>
                    <v-datatable-repartition-inte-espace-individuel
                        @update-repartitions="repartitions = $event"
                        @update-repartition-uniforme-active="repartitionUniformeActive = $event"
                        @update-repartition-presence-active="repartitionPresenceActive = $event"
                        @update-repartition-salaire-active="repartitionSalaireActive = $event"
                        @update-nombre-distributions="nombreDistributions = $event"
                        @update-detail-calcul="detailCalcul = $event"
                        :repartitions="repartitions"
                        :repartitionUniformeActive="repartitionUniformeActive"
                        :repartitionPresenceActive="repartitionPresenceActive"
                        :repartitionSalaireActive="repartitionSalaireActive"
                        :nombreDistributions="nombreDistributions"
                        :detailCalcul="detailCalcul"
                        :selectedExercice="selectedExercice"
                        :selectedExerciceId="selectedExerciceId"
                        :noDataTextMessage="noDataTextMessage"
                    />
                    <v-row no-gutters justify="end" class="my-3">
                        <v-col md="3" lg="3" xl="2">
                            <detail-repartition-inte :explain="detailCalcul"/>
                        </v-col>
                        <v-spacer/>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2">
                            <v-btn color="primary" @click="downloadFicheIndividuelle" :disabled="repartitions.length == 0" block>
                                <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                                Fiche individuelle
                            </v-btn>
                        </v-col>
                    </v-row>

                    <h2>Affectation de l'intéressement</h2>
                    <v-datatable-affectation-inte-espace-individuel
                        @update-affectations="affectations = $event"
                        @update-inte-supports="inteSupports = $event"
                        :affectations="affectations"
                        :inteSupports="inteSupports"
                        :selectedExercice="selectedExercice"
                        :noDataTextMessage="noDataTextMessage"
                        :dataChanged="false"
                        :disabledValue="!canSaveAffectation"
                    />
                    <v-row no-gutters justify="end" class="mt-3">
                        <v-spacer/>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2"
                               v-if="affectations.length && !selectedExercice.inte_exercice.is_affectation_inte_valide">
                            <v-btn block color="secondary" @click="saveAffectationInteIndividuel(validate, errors)" :disabled="!canSaveAffectation">
                                <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                                Enregistrer
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import BaseLayout from "@/components/BaseLayout";
    import VExercicesList from "@/components/VExercicesList";
    import VDatatableRepartitionInteEspaceIndividuel from "@/components/repartitionInte/VDatatableRepartitionInteEspaceIndividuel";
    import DetailRepartitionInte from "@/components/repartitionInte/VDetailRepartitionInte";
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";
    import VDatatableAffectationInteEspaceIndividuel from "@/components/affectationInte/VDatatableAffectationInteEspaceIndividuel";

export default {
    components: {
        loadingScreen,
        VExercicesList,
        BaseLayout,
        VDatatableRepartitionInteEspaceIndividuel,
        DetailRepartitionInte,
        VDatatableAffectationInteEspaceIndividuel,
    },
    data() {
        return {
            affectations: [],
            inteSupports: [],
            repartitions: [],
            nombreDistributions: 1,
            detailCalcul: {},
            selectedExerciceId: undefined,
            selectedExercice: undefined,
            repartitionUniformeActive: false,
            repartitionPresenceActive: false,
            repartitionSalaireActive: false,
            canGetFicheIndividuelle: true,
            noDataTextMessage: "Aucune donnée disponible.",
            canSaveAffectation: false
        };
    },
    mounted() {
        this.canSaveAffectation = this.$store.getters.hasDroitAcces(
            constantes.droits_acces.noms.espace_individuel_interessement,
            [constantes.droits_acces.valeurs_possibles.edition],
        )
    },
    methods: {
        getInteExerciceById(inte_exercice_id) {
            return internalApi.inteExercice.getById(inte_exercice_id).then(
                result => {
                    this.selectedExercice = result;
                    this.selectedExerciceId = result.id;
                }
            );
        },
        onExerciceChange(selected_exercice) {
            this.getInteExerciceById(selected_exercice.inte_exercice_id);
        },
        /**
         * Retourne la constante des types d'extensions de fichiers
         */
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
        onAffectationInteImport(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.import);
            internalApi.affectationInte.importInteAffectationByInteExerciceId(this.exercice.id, formData)
                .then(() => {
                    this.$refs.datatable_affectation_inte.getAllAffectationParBeneficiaireSync();
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        downloadFicheIndividuelle() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.repartitionInte.getInteFicheIndividuelle(this.selectedExerciceId)
                .then(result => {
                    Utils.downloadFile(result, "fiche_individuelle_interessement.pdf", Utils.pdfMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                })
        },
        /**
         * Display errors from the table
         */
        showErrorToast(raw_errors) {
            const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
            errors.forEach(error => TOAST.error("Champ requis manquant", error));
        },
        saveAffectationInteIndividuel(validate, errors) {
            validate().then(success => {
                if (success) {
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                    internalApi.affectationInte.saveInteAffectationIndividuelByInteExerciceId(this.selectedExerciceId, this.affectations)
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                } else {
                    this.showErrorToast(errors);
                }
            });
        },
    }
}
</script>
