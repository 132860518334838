// <!-- VUETIFY2 - OK -->
import NewVersion from "@/components/versions/NewVersion";
import internalApi from "@/api/internalApi";
import {TOAST} from "@/plugins/toast/definition";

export default {
  mounted() {
    this.messageAvertissement = `La création d'une nouvelle version de l'accord de participation
    permet d'enregistrer de nouveaux paramètrages. Confirmez-vous cette opération ?`;
    this.buttonText = `Créer une version de l'accord`;
    this.title = `Créer une version de l'accord`;
  },
  extends: NewVersion,
  name: "new-accord-pb",
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitNewVersion() {
      const data = _.cloneDeep(this.localNouvelleVersion);
      delete data.id;
      data.nom_version = this.nom_version;
      data.date_debut = this.date_debut;
      this.$refs.formNewVersion.validate().then(validForm => {
        if (validForm) {
          internalApi.pbCompany.createStatut(data).then(result => {
            this.$emit("on-version-created");
            this.closePopUp();
          }).catch(error => {
            console.error(error);
          });
        }
      });
    },
  }
};
