import annulationTotaleApi from "./capital/annulationTotale";
import annulationApi from "./capital/annulation";
import associeApi from "./capital/associe";
import exerciceApi from "./common/exercice";
import capExerciceApi from "./capital/exercice";
import monteeCapitalApi from "./capital/monteeCapital";
import aboAttributionApi from "./abondement/aboAttribution";
import aboExerciceApi from "./abondement/aboExercice";
import aboEligibilitesApi from "./abondement/aboEligibilites";
import pbExerciceApi from "./participation/exercice";
import inteExerciceApi from "./interessement/exercice";
import salarieApi from "./capital/salarie";
import periodeTravailApi from "./common/periodeTravail";
import commonSalairePresenceApi from "./common/salairePresence";
import pbSalairePresenceApi from "./participation/pbSalairePresence";
import inteSalairePresenceApi from "./interessement/inteSalairePresence";
import beneficiaireApi from "./participation/beneficiaire";
import capitalApi from "./capital/capital";
import categorieCapitalApi from "./capital/categorieCapital";
import companyApi from "./common/company";
import capCompanyApi from "./capital/company";
import inteCompanyApi from "./interessement/company";
import pbCompanyApi from "./participation/company";
import controleStatusApi from "./capital/controleStatus";
import mouvementApi from "./capital/mouvementPartSociale";
import personnePhysiqueApi from "./capital/personnePhysique";
import personneMoraleApi from "./capital/personneMorale";
import souscriptionApi from "./capital/souscription";
import userApi from "./user";
import personneApi from "./capital/personne";
import dettesApi from "./capital/dettes";
import reglementsApi from "./capital/reglements";
import authenticationApi from "./authentication";
import cerfaApi from "./common/cerfa";
import fiscaliteCapitalApi from "./capital/fiscaliteCapital";
import fiscaliteParticipationApi from "./participation/fiscaliteParticipation";
import capChartsApi from "./capital/capCharts";
import pbChartsApi from "./participation/pbCharts";
import manuelUtilisateur from "./manuelUtilisateur";
import documents from "./capital/documents";
import administration from "./administration";
import repartitionPbApi from "./participation/repartitionPb";
import repartitionPbAncienneteApi from "./participation/repartitionPbAnciennete";
import repartitionInteApi from "./interessement/repartitionInte";
import affectationInteApi from "./interessement/affectationInte";
import ventilationPbApi from "./participation/ventilationPb";
import pbSupportApi from "./participation/pbSupport";
import inteSupportApi from "./interessement/inteSupport";
import tauxMoyenRendementObliApi from "./participation/tauxMoyenRendementObli";
import deblocageApi from "./participation/deblocage";
import deblocageAnticipeApi from "./participation/deblocageAnticipe";
import deblocageTermeApi from "./participation/deblocageTerme";
import operationsApi from "./participation/operations";
import tauxFiscaliteApi from "./common/tauxFiscalite";
import countriesListApi from "./common/countriesList";
import scicApi from "./scic/scic";
import scicParamCategorieApi from "./scic/scicParamCategorie";
import scicCollegeApi from "./scic/scicCollege";
import scicSousCategorieApi from "./scic/scicSousCategorie";
import scicAdministrationParametresApi from "./scic/scicAdministrationParametres";
import scicDocumentsApi from "./scic/scicDocuments";
import scicTauxFiscaliteApi from "./scic/scicTauxFiscalite";
import asynchronousApi from "@/api/internalApi/common/task_api";
import gestion_droits_acces from "@/api/internalApi/common/gestion_droits_acces";
import chiffrement from "@/api/internalApi/common/chiffrement";

export default {
  annulation: annulationApi(),
  annulationtotale: annulationTotaleApi(),
  associe: associeApi(),
  countriesList: countriesListApi(),
  salarie: salarieApi(),
  periodeTravail: periodeTravailApi(),
  commonSalairePresence: commonSalairePresenceApi(),
  pbSalairePresence: pbSalairePresenceApi(),
  inteSalairePresence: inteSalairePresenceApi(),
  beneficiaire: beneficiaireApi(),
  capital: capitalApi(),
  categorieCapital: categorieCapitalApi(),
  company: companyApi(),
  capCompany: capCompanyApi(),
  inteCompany: inteCompanyApi(),
  pbCompany: pbCompanyApi(),
  controleStatus: controleStatusApi(),
  exercice: exerciceApi(),
  capExercice: capExerciceApi(),
  monteeCapital: monteeCapitalApi(),
  aboAttribution: aboAttributionApi(),
  aboExercice: aboExerciceApi(),
  aboEligibilites: aboEligibilitesApi(),
  pbExercice: pbExerciceApi(),
  inteExercice: inteExerciceApi(),
  repartitionPb: repartitionPbApi(),
  repartitionPbAnciennete: repartitionPbAncienneteApi(),
  repartitionInte: repartitionInteApi(),
  affectationInte: affectationInteApi(),
  ventilationPb: ventilationPbApi(),
  mouvement: mouvementApi(),
  personnePhysique: personnePhysiqueApi(),
  personneMorale: personneMoraleApi(),
  souscription: souscriptionApi(),
  user: userApi(),
  personne: personneApi(),
  dettes: dettesApi(),
  reglements: reglementsApi(),
  authentication: authenticationApi(),
  cerfa: cerfaApi(),
  fiscalite_capital: fiscaliteCapitalApi(),
  fiscalite_participation: fiscaliteParticipationApi(),
  capCharts: capChartsApi(),
  pbCharts: pbChartsApi(),
  manuelUtilisateur: manuelUtilisateur(),
  documents: documents(),
  administration: administration(),
  pbSupport: pbSupportApi(),
  inteSupport: inteSupportApi(),
  deblocage: deblocageApi(),
  deblocageAnticipe: deblocageAnticipeApi(),
  deblocageTerme: deblocageTermeApi(),
  operations: operationsApi(),
  tauxMoyenRendementObli: tauxMoyenRendementObliApi(),
  tauxFiscalite: tauxFiscaliteApi(),
  scic: scicApi(),
  scicParamCategorie: scicParamCategorieApi(),
  scicCollege: scicCollegeApi(),
  scicSousCategorie: scicSousCategorieApi(),
  scicAdministrationParametres: scicAdministrationParametresApi(),
  scicDocuments: scicDocumentsApi(),
  ScicTauxFiscalite: scicTauxFiscaliteApi(),
  asynchronousApi: asynchronousApi(),
  gestion_droits_acces: gestion_droits_acces(),
  chiffrement: chiffrement(),
};
