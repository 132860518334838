<!-- VUETIFY2 - OK -->
<template>
    <v-autocomplete :value="value"
                    @input="$emit('input', $event)"
                    label="Mois"
                    :items="localMonthsList"
                    :error-messages="errors"
                    :disabled="isDisabled"/>
</template>

<script>
    import _ from "lodash";
    import constantes from "@/utils/constantes";

    /**
     * Composant affichant une liste déroulante des mois de l'année
     * @displayName Commun - Liste des mois
     */
    export default {
        name: "v-months-list",
        props: {
            /**
             * Mois à présélectionner
             */
            value: {
                type: [Object, Number]
            },
            /**
             * Si vrai, la liste est désactivée, si faux, la liste est activée
             */
            isDisabled: Boolean,
            errors: {
                type: Array,
                required: false
            },
        },
        mounted() {
            this.localMonthsList = this.computeMonthsList();

        },
        data() {
            return {
                localMonthsList: [],
            }
        },
        methods: {
            computeMonthsList() {
                var finalList = [];
                constantes.monthsList.forEach( (month, index) => {
                    finalList.push({text: month, value: index+1});
                });
                return finalList;
            }
        }
    };
</script>


