<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialogDetail" max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn slot="activator" dark color="primary" outlined :disabled="disabledValue" block v-on="on">
                <v-icon dark left>{{ "$vuetify.icons.info" }}</v-icon>
                Détail calcul
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                Détail du calcul de la répartition de l'intéressement
            </v-card-title>

            <v-card-text>
                <div class="subheading my-2">Montant d'intéressement :
                    <span class="font-weight-bold">{{explain.montant_inte | roundEuroFilter}}</span>
                </div>
                <div v-if="explain.repartition_uniforme && explain.repartition_uniforme.cle" class="my-2">
                    <div class="title my-2">
                        Répartition uniforme
                    </div>
                    <div>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{explain.repartition_uniforme.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{explain.repartition_uniforme.montant_total | roundEuroFilter}}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold" v-if="explain.repartition_uniforme.prorata_temporis">
                            {{explain.repartition_uniforme.montant_jour | roundEuroFilter }}
                        </span>
                        <span class="font-weight-bold" v-else>
                            {{explain.repartition_uniforme.montant_unitaire | roundEuroFilter }}
                        </span>
                        <span> par bénéficiaire
                            <span v-if="explain.repartition_uniforme.prorata_temporis"> et par jour</span>
                        </span>
                    </div>
                </div>
                <div v-if="explain.repartition_salaire && explain.repartition_salaire.cle" class="my-2">
                    <div class="title my-2">Répartition selon le salaire</div>
                    <div>
                        <span>Revenus mobilisés pour la répartition : </span>
                        <span class="font-weight-bold">{{explain.repartition_salaire.masse_montant | roundEuroFilter }}</span>
                        <br/>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{explain.repartition_salaire.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{explain.repartition_salaire.montant_total | roundEuroFilter}}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold">
                            {{explain.repartition_salaire.montant_unitaire * 1000 | roundEuroFilter }}
                        </span>
                        <span> pour {{1000 | roundEuroFilter}} de revenu annuel brut pour un salarié ayant travaillé sur tout l'exercice</span>
                    </div>
                </div>
                <div v-if="explain.repartition_presence && explain.repartition_presence.cle" class="my-2">
                    <div class="title my-2">Répartition selon le temps de présence</div>
                    <div>
                        <span>Temps de présence mobilisés pour la répartition : </span>
                        <span class="font-weight-bold">{{ explain.repartition_presence.masse_montant || 0}} h</span>
                        <br/>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{ explain.repartition_presence.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{ explain.repartition_presence.montant_total | roundEuroFilter }}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold">
                            {{ explain.repartition_presence.montant_unitaire * 1000 | roundEuroFilter }}
                        </span>
                        <span> pour 1 000 heures de présence sur l'année</span>
                    </div>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-row no-gutters class="mx-0" justify="end">
                    <!-- Bouton modal -->
                    <v-col md="4" lg="4" xl="4">
                        <v-btn color="primary" text @click="dialogDetail = false" block>
                            Fermer
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /**
     * Composant affichant le détail des calculs pour une répartition inte
     * @display_name Intéressement - Détail de Répartition Inte
     */
    export default {
        name: "DetailRepartitionInte",
        props: {
            /**
             * Informations détaillant le calcul
             */
            explain: {
                type: Object
            },
            /**
             * Est désactivé
             */
            disabledValue: {
                type: Boolean
            }
        },
        data() {
            return {
                dialogDetail: false
            }
        }
    }
</script>