<template>
    <v-container style="max-width: 1000px;" class="ml-16">
        <loading-screen ref="loadingComponent"/>
        <v-row style="min-height: 4px" no-gutters>
            <v-col cols="4">
                <h3 style="display: inline-block;">{{documentTitle}}</h3>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon class="ml-2" small left v-on="on">{{"$vuetify.icons.info"}}</v-icon>
                    </template>
                    <span v-html="infoBulleContent"/>
                </v-tooltip>
            </v-col>
            <v-spacer/>
            <v-col cols="4" v-if="!documentId && !canUpload">
                <span>Aucun {{documentTitle}} (PDF) importé</span>
            </v-col>
            <v-col cols="8" v-if="!documentId && canUpload">
                <v-import-export-file :detail="detail"
                            :typesFile="getTypesExtensionsFiles().pdf"
                            @import-file="function(formData){uploadFile(formData)}"
                            :allowedExport="false"/>          
            </v-col>
            <v-col cols="4" v-if="documentId">
                <v-btn color="primary" @click="downloadDocument()" block>
                    <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                    Télécharger
                </v-btn>
            </v-col>
            <v-col cols="4" v-if="documentId && canDelete">
                <v-custom-confirmation-dialog
                    message="Êtes-vous sûr(e) de vouloir supprimer ce document ?"
                    :isIcon="true"
                    :iconValue="'$vuetify.icons.delete'"
                    @action-confirmee="unlinkDocument()"
                    tooltipDetailMessage="Supprimer le document"/>
            </v-col>
            
        </v-row>
    </v-container>
</template>

<script>

    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils";
    import VImportExportFile from "@/components/VImportExportFile";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";

    export default {
        name: "VDocumentAbstract",
        abstract: true,
        components: {
            VImportExportFile,
            loadingScreen,
            VCustomConfirmationDialog,
        },
        props: {
            areDocumentsPersonnels: {
                type: Boolean,
                required: false,
                default: false,
            },
            canDelete: {
                type: Boolean,
                required: false,
                default: true
            },
            canUpload: {
                type: Boolean,
                required: false,
                default: true
            },

        },
        data() {
            return {
                formData: new FormData(),
                detail: "Export",
                documentId: undefined,
                companyUnivId: undefined,
            }
        },
        computed: {
            infoBulleContent: function() {
                // Abstract method
                return undefined;
            },
            documentTitle: function() {
                // Abstract method
                return undefined;
            },
            typeDocument: function() {
                // Abstract method
                return undefined;
            },
            fileName: function() {
                // Abstract method
                return undefined;
            },
        },
        mounted() {
            this.loadDocument();
        },
        methods: {
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            uploadFile(formData) {
                // Abstract method
            },
            /**
             * Delete document
             */
            unlinkDocument: function () {
                // Abstract method 
            },
            downloadDocument: function() {
                let promise;
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                promise = internalApi.documents.documentAccordDownload(this.typeDocument);
                return promise.then(response => {
                    Utils.downloadFile(response, this.fileName, Utils.pdfMimetype);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Récupère pour un collaborateur l'ensemble des documents correspondants
             * à l'attribut typeDocument
             * @returns Promise
             */
            loadDocument: function () {
                // Abstract method
            }, 
        }
    }
</script>
