var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"vertical elevation-1",attrs:{"headers":_vm.headersInterets,"items":[_vm.detail],"hide-default-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-lg-center",attrs:{"colspan":"3"}},[_vm._v("Intérêts")])])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((_vm.headersInterets),function(header){return _c('tr',{key:header.value,class:{ bold: header.bold },style:(_vm.coloredTrStyle(header.color ? header.color.secondaire : null))},[_c('th',{style:(_vm.coloredThStyle(header.color ? header.color.principale : null))},[_vm._v(_vm._s(header.text))]),_c('td',{staticClass:"text-lg-center"},[_vm._v(_vm._s(header.symbol))]),_vm._l((items),function(item){return _c('td',{key:item.id,staticClass:"text-lg-right"},[(!(header.nullable && item[header.value] == null))?[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item[header.value])))]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[(header.value == 'montant_charges_sociales')?_c('span',{domProps:{"innerHTML":_vm._s("Le bénéficiaire était exonéré de charges sociales à la date de création de la dette.")}}):(header.value == 'montant_prelevement_forfaitaire')?_c('span',{domProps:{"innerHTML":_vm._s("Le bénéficiaire avait renoncé au prélèvement forfaitaire à la date de création de la dette.")}}):_vm._e()]),_vm._v(" "+_vm._s(_vm._f("roundEuroFilter")(0))+" ")]],2)})],2)}),0)]}}])})],1)],1),(_vm.isMontantParticipationAvailable)?_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"vertical elevation-1",attrs:{"headers":_vm.headersParticipation,"items":[_vm.detail],"hide-default-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-lg-center",attrs:{"colspan":"3"}},[_vm._v("Participation aux bénéfices")])])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((_vm.headersParticipation),function(header){return _c('tr',{key:header.value,class:{ bold: header.bold },style:(_vm.coloredTrStyle(header.color ? header.color.secondaire : null))},[_c('th',{style:(_vm.coloredThStyle(header.color ? header.color.principale : null))},[_vm._v(_vm._s(header.text))]),_c('td',{staticClass:"text-lg-center"},[_vm._v(_vm._s(header.symbol))]),_vm._l((items),function(item){return _c('td',{key:item.id,staticClass:"text-lg-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item[header.value])))])})],2)}),0)]}}],null,false,3464935121)})],1)],1):_vm._e()],1),(_vm.isMontantParticipationAvailable)?_c('v-divider',{attrs:{"vertical":"","role":"presentation"}}):_vm._e(),(_vm.isMontantParticipationAvailable)?_c('v-col',{staticClass:"d-flex align-start flex-column"},[_c('v-data-table',{staticClass:"vertical elevation-1",attrs:{"headers":_vm.headersTotal,"items":[_vm.detail],"hide-default-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-lg-center",attrs:{"colspan":"3"}},[_vm._v("Total")])])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((_vm.headersTotal),function(header){return _c('tr',{key:header.value,class:{ bold: header.bold },style:(_vm.coloredTrStyle(header.color ? header.color.secondaire : null))},[_c('th',{style:(_vm.coloredThStyle(header.color ? header.color.principale : null))},[_vm._v(_vm._s(header.text))]),_c('td',{staticClass:"text-lg-center"},[_vm._v(_vm._s(header.symbol))]),_vm._l((items),function(item){return _c('td',{key:item.id,staticClass:"text-lg-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item[header.value])))])})],2)}),0)]}}],null,false,3755987243)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }