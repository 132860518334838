import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default capCompanyApi;

function capCompanyApi() {
  const specificApi = {
    getCurrentStatutCapCompany: getCurrentStatutCapCompany(),
    getStatutCapCompanyById: getStatutCapCompanyById(),
    createStatut: createStatut(),
    getStatutByDate: getStatutByDate(),
    getStatutsCapCompanyEnumByName: getStatutsCapCompanyEnumByName(),
    deleteStatutsDocument: deleteStatutsDocument(),
    createStatutsDocument: createStatutsDocument(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.capCompanies.baseUrl));
}

function getCurrentStatutCapCompany() {
  function getCurrentStatutCapCompany(queryParams, headers) {
    let path = internalApiRoutes.capCompanies.statutsCapCompany();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getCurrentStatutCapCompany;
}

function getStatutCapCompanyById() {
  function getStatutCapCompanyById(cap_company_id, queryParams, headers) {
    let path = internalApiRoutes.capCompanies.getStatutCapCompanyById(cap_company_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutCapCompanyById;
}

function getStatutByDate() {
  function getStatutByDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.capCompanies.statutsCapCompanyByDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutByDate;
}

function getStatutsCapCompanyEnumByName() {
  function getStatutsCapCompanyEnumByName(queryParams, headers) {
    let path = internalApiRoutes.capCompanies.statutsCapCompanyEnumByName();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getStatutsCapCompanyEnumByName;
}

function createStatut() {
  function createStatut(body, queryParams, headers) {
    let path = internalApiRoutes.capCompanies.statutsCapCompany();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createStatut;
}

function deleteStatutsDocument() {
  function deleteStatutsDocument(id,queryParams, headers) {
    let path = internalApiRoutes.capCompanies.deleteStatutsDocument(id);
    let deleteForPath = apiConstructor.delete(path);
    return deleteForPath(queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
    });
  }
  return deleteStatutsDocument;
}

function createStatutsDocument() {
  function createStatutsDocument(body, queryParams, headers) {
    let path = internalApiRoutes.capCompanies.createStatutsDocument();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createStatutsDocument;
}