<template>
    <ValidationObserver v-slot="{ validate, errors }">
    <base-layout :withHeader="false" datatableExcludedSize="190px">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container>
                Date de référence pour l'ancienneté : 
                <span v-if="dateReferenceAnciennete">{{ dateReferenceAnciennete | dateIsoToFrFilter }}</span>
                <span v-else>non définie</span>
                <v-datatable-abondement-eligibilites :exercice="exercice"
                                                     :disabled="isAttributionValide"
                                                     :datatableSize="datatableSize"
                                                     ref="datatableAboEligibilites"
                />
            </v-container>
        </template>
        <template v-slot:footer>
            <v-row v-if="!isAttributionValide" justify="end" no-gutters>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-btn block color="secondary" @click="saveAboEligibilites(validate, errors)" :disabled="!dateReferenceAnciennete">
                        <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                        Enregistrer les données des associés
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
    </ValidationObserver>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import VDatatableAbondementEligibilites from "@/components/abondement/VDatatableAbondementEligibilites";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import {TOAST} from "@/plugins/toast/definition";
    import internalApi from "@/api/internalApi";

    export default {
        name: "v-abo-donnees-associes",
        abstract: false,
        components: {
            VDatatableAbondementEligibilites,
            BaseLayout,
            loadingScreen,
        },
        data() {
            return {};
        },
        props: {
            exercice: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        computed: {
            dateReferenceAnciennete() {
                return this.exercice.abo_exercice.date_reference_anciennete;
            },
            isAttributionValide() {
                return this.exercice.abo_exercice.is_attribution_valide;
            },
        },
        methods: {
            /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            saveAboEligibilites(validate, errors) {
                validate().then(
                    success => {
                        if (success) {
                            this.$refs.datatableAboEligibilites.saveAboEligibilites();
                        } else {
                            this.showErrorToast(errors);
                        }
                    }
                );
            },
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },
        },
    }
</script>