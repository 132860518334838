<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-data-table :headers="headers"
                          :items="sousCategories"
                          :footer-props="footerProps"
                          :height="datatableSize"
                          :loading="loading"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc">
                <template v-slot:item="{item}">
                    <tr>
                        <td class="text-lg-left">
                            {{item.code}}
                        </td>
                        <td class="text-lg-left">
                            {{item.name}}
                        </td>
                        <td class="text-lg-center">
                            {{item.description | limitStr(80)}}
                        </td>
                        <td class="text-lg-center">
                            <v-scic-sous-categorie-edition :oldScicSousCategorie="item"
                                                           @edit-scic-sous-categorie="(oldSousCategorie, newSousCategorie) => editScicSousCategorie(oldSousCategorie, newSousCategorie)"
                            />
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
        <template v-slot:footer>
            <v-row dense justify="space-between">
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-scic-sous-categorie-creation @add-scic-sous-categorie="addScicSousCategorie($event)"/>
                </v-col>
                <v-col md="6" lg="6" xl="4">
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancel()"
                                                      @emit-valid-save="submit()"/>
                    </ValidationObserver>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>
<script>
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import VScicSousCategorieCreation from "@/components/scic/VScicSousCategorieCreation.js";
    import VScicSousCategorieEdition from "@/components/scic/VScicSousCategorieEdition.js";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation";
    import Vue from 'vue';

    export default {
        name: "VScicSousCategoriesList",
        components: {
            loadingScreen,
            BaseLayout,
            VSaveButonWithValidation,
            VScicSousCategorieCreation,
            VScicSousCategorieEdition,
        },
        props: {
            height: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                currentTab: undefined,
                headers: [
                    {text: "Code", value: "code", align: "start"},
                    {text: "Nom", value: "name", align: "start"},
                    {text: "Description", value: "description", align: "center"},
                    {text: "Actions", value: "actions", align: "center"},
                ],
                sousCategories: [],
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                loading: true,
                sortBy: 'code',
                sortDesc: false,
            }
        },
        mounted() {
            this.fetchSousCategories();
        },
        methods: {
            fetchSousCategories() {
                this.loading = true;

                internalApi.scicSousCategorie.getAll()
                    .then(result => {
                        this.sousCategories = result;
                    })
                    .catch(err => {
                        console.error(err);
                        this.sousCategories = [];
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addScicSousCategorie(new_sous_categorie) {
                this.sousCategories.push(new_sous_categorie);
            },
            editScicSousCategorie(old_sous_categorie, new_sous_categorie) {
                new_sous_categorie.has_been_edited = true;
                const old_sous_categorie_index = this.sousCategories.indexOf(old_sous_categorie);
                if (old_sous_categorie_index >= 0) {
                    Vue.set(this.sousCategories, old_sous_categorie_index, new_sous_categorie);
                } else {
                    console.error("Impossible de trouver la sous-catégorie '" + old_sous_categorie.code + "' dans la listes des sous-catégories locale.")
                }
            },
            cancel() {
                this.fetchSousCategories();
            },
            _cleanSousCategoriesForSubmit(sousCategories) {
                sousCategories.forEach(sousCategorie => {
                    delete sousCategorie.has_been_edited;
                    if (! sousCategorie.hasOwnProperty("description")) {
                        sousCategorie.description = null;
                    }
                });
            },
            submit() {

                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);

                // Get all newly created sous categories
                const new_sous_categories = this.sousCategories.filter(sous_categorie => {
                    return ! sous_categorie.hasOwnProperty('id');
                });
                // Get all newly edited sous categories
                const edited_sous_categories = this.sousCategories.filter(sous_categorie => {
                    return sous_categorie.hasOwnProperty('has_been_edited') && sous_categorie.hasOwnProperty('id');
                });

                this._cleanSousCategoriesForSubmit(new_sous_categories);
                this._cleanSousCategoriesForSubmit(edited_sous_categories);

                const promise_new = internalApi.scicSousCategorie.postMassEditing(new_sous_categories)
                    .catch(error => {
                        console.error(error);
                    });
                const promise_edit = internalApi.scicSousCategorie.putMassEditing(edited_sous_categories)
                    .catch(error => {
                        console.error(error);
                    });

                Promise.all([promise_new, promise_edit]).finally(() => {
                    this.fetchSousCategories();
                    this.$refs.loadingComponent.resetLoading();
                });

            },
        },
    }
</script>