import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default scicAdministrationParametresApi;

function scicAdministrationParametresApi() {
  return defaultApi(internalApiRoutes.scicAdministrationParametres.baseUrl, [
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById,
  ]);
}