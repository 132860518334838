import Tiers from "@/views/common/Tiers/Index";
import PersonnesList from "@/views/common/Tiers/PersonnesList";
import PersonnePhysiqueCreation from "@/views/common/Tiers/PersonnePhysiqueCreation";
import PersonnePhysiqueEdition from "@/views/common/Tiers/PersonnePhysiqueEdition";
import PersonneMoraleCreation from "@/views/common/Tiers/PersonneMoraleCreation";
import PersonneMoraleEdition from "@/views/common/Tiers/PersonneMoraleEdition";
import rolesList from "@/router/rolesList";
import EspacePersoPersonnePhysiqueEdition from "@/views/common/Tiers/EspacePersoPersonnePhysiqueEdition";
import EspacePersoPersonneMoraleEdition from "@/views/common/Tiers/EspacePersoPersonneMoraleEdition";


export default {
  path: "tiers/",
  component: Tiers,
  children: [
    {
      path: "",
      name: "tiers",
      component: PersonnesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "tiers-personne-physique/:id/",
      name: "edition-tiers-personne-physique",
      component: PersonnePhysiqueEdition,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "tiers-personne-physique/",
      name: "creation-tiers-personne-physique",
      component: PersonnePhysiqueCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "tiers-personne-morale/:id/",
      name: "edition-tiers-personne-morale",
      component: PersonneMoraleEdition,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "tiers-personne-morale/",
      name: "creation-tiers-personne-morale",
      component: PersonneMoraleCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "tiers-pp-infos-personnelles/",
      name: "pp_infos_personnelles",
      component: EspacePersoPersonnePhysiqueEdition,
      meta: {
        authorizedRoles: [rolesList.TIERS],
        allowedPersonneType: "per_phy",
      },
    },
    {
      path: "tiers-pm-infos-personnelles/",
      name: "pm_infos_personnelles",
      component: EspacePersoPersonneMoraleEdition,
      meta: {
        authorizedRoles: [rolesList.TIERS],
        allowedPersonneType: "per_mor",
      },
    },
  ]
};