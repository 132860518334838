<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }" v-if="showButton">
            <v-btn v-on="on" color="primary" @click="getStateDocuments" block>
                <v-icon left>{{ "$vuetify.icons.download" }}</v-icon>
                {{ documentsNamePlural }}
            </v-btn>
        </template>
        <v-card>
            <loading-screen ref="loadingComponent"/>
            <v-card-title class="headline grey lighten-2" primary-title>{{ computedPopupTitle | capitalizeFirstLetterFilter}}</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <div>
                        Les {{ documentsNamePlural }}
                        <span v-if="state_dernier_traitement==='never_happened'">
                            n'ont pas encore été généré{{ documentGenderText }}s.
                        </span>
                         <span v-else-if="state_dernier_traitement === 'finished'">
                            ont été généré{{ documentGenderText }}s le <b>{{ date_dernier_traitement | dateIsoToFrFilter }}</b>
                            <span v-if="showDateReferenceDernierTraitement && date_reference_dernier_traitement">
                                avec comme date de référence le <b>{{ date_reference_dernier_traitement | dateIsoToFrFilter }}</b>.
                            </span>
                        </span>
                        <span v-else-if="state_dernier_traitement==='running'">
                            sont en cours de génération depuis le {{ date_dernier_traitement | dateIsoToFrFilter }}.
                        </span>
                        <span v-else>
                            sont indisponibles.
                        </span>
                    </div>
                    <v-row>
                        <v-col cols="auto">
                            <v-radio-group v-model="selectedAction">
                                <v-radio :label="computedlabelGenerate + ' les documents'" :value="valueGenerate" :disabled="state_dernier_traitement==='running'"/>
                                <v-radio label="Télécharger les documents" :value="valueDownload" :disabled="state_dernier_traitement!=='finished'"/>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense justify="center">
                        <span>
                            Les {{ documentsNamePlural }} doivent dans un premier temps être généré{{ documentGenderText }}s de manière asynchrone.
                            <br/>
                            Dès que le traitement sera terminé, vous pourrez télécharger l'intégralité des documents.
                            <span v-if="state_dernier_traitement ==='finished' && selectedAction===valueGenerate"
                            style="color: orange; text-align:justify">
                            <br/>
                            Le fait de re-générer les {{ documentsNamePlural }} vous empêchera de les télécharger jusqu'à ce que le nouveau traitement soit terminé.
                            </span>
                        </span>
                    </v-row>
                    <v-row dense justify="end">   
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn @click="closePopUp" block>
                                Non, annuler
                            </v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="4" class="mr-4">
                            <v-btn color="primary" @click="actionDocuments" block>
                                Oui, confirmer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils.js";
    import constantes from "@/utils/constantes";
    /**
     * Composant affichant une pop-up pour manipuler les documents
     * @displayName VPopupAsynchrone Génération asynchrone des documents
     */
    export default {
        name: "VPopupAsynchrone",
        components: {
            loadingScreen
        },
        data() {
            return {
                dialog: false,
                selectedAction: undefined,
                valueGenerate: "generate",
                valueDownload: "download",
                labelDownload: "Télécharger les documents",
                state_dernier_traitement: undefined,
                date_dernier_traitement: undefined,
                date_reference_dernier_traitement: undefined,
            }
        },
        props: {
            documentsNamePlural: {
                type: String,
                required: true
            },
            /**
             * Documents Nom complet Pluriel
             */
            documentsFullNamePlural: {
                type: String,
                required: false,
                default: undefined
            },
            isDocumentGenderFeminine: {
                type: Boolean,
                required: true,
            },
            /**
             * PDF document name without extension
             */
            documentPdfName: {
                type: String,
                required: true
            },
            showButton: {
                type: Boolean,
                required: false,
                default: true,
            },
            showDateReferenceDernierTraitement: {
                type: Boolean,
                required: false,
                default: false,
            },
            /**
            * Obtenir l'état du traitement asynchrone pour ce document
            */
            getStateDocuments: {
                type: Function,
                required: true
            },
            /**
             * Lance la génération des documents
             */
            generateDocuments: {
                type: Function,
                required: true
            },
            /*
            * Télécharge les documents
            */
            downloadDocuments: {
                type: Function,
                required: true
            },
        },
        mounted() {
            this.getStateEtats();   
            this.getStateDocuments();
        },
        computed: {
            computedPopupTitle() {
                 return this.documentsFullNamePlural !== undefined
                    ? this.documentsFullNamePlural
                    : this.documentsNamePlural;
            },
            documentGenderText() {
                return this.isDocumentGenderFeminine ? "e" : "";
            },
            
            computedlabelGenerate() {
                return this.date_dernier_traitement ? "Re-générer" : "Générer";
            },
        },
        watch: {
            dialog() {
                if (this.dialog) {
                    this.getStateEtats();
                }
            },
        },
        methods: {
            closePopUp() {
                this.selectedAction = undefined;
                this.state_dernier_traitement = undefined;
                this.date_dernier_traitement = undefined;
                this.dialog = false;
            },
            openPopup() {
                this.dialog = true;
                this.getStateEtats();
            },
            getStateEtats() {
                this.getStateDocuments().then(result => {
                    this.state_dernier_traitement = result.state;
                    this.date_dernier_traitement = result.date_dernier_traitement;
                    this.date_reference_dernier_traitement = result.date_reference_dernier_traitement;
                    if(result.state==='finished'){
                        this.selectedAction = this.valueDownload;
                    } else if(result.state==='never_happened'){
                        this.selectedAction = this.valueGenerate;
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            downloadDocument() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                this.downloadDocuments().then(result => {
                    Utils.downloadFile(result, `${this.documentPdfName}.pdf`, Utils.pdfMimetype);
                    this.$refs.loadingComponent.resetLoading();
                    this.closePopUp();
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            generate() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                this.generateDocuments().then(() => {
                    // Peut poser problème si on ne reset pas avant de fermer la pop-up
                    this.$refs.loadingComponent.resetLoading();
                    this.closePopUp();
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            actionDocuments() {
                if (this.selectedAction===this.valueGenerate){
                    return this.generate();
                } else if (this.selectedAction===this.valueDownload){
                    return this.downloadDocument();
                }
            }
        }
    }
</script>
