<!-- VUETIFY2 - OK -->
<template>
    <base-layout>
        <template v-slot:header>
            <h1>Supports de placement</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-row class="pl-3" no-gutters>
                <v-col>
                    <v-data-table :search="search"
                                  :headers="headers"
                                  :items="supports"
                                  :loading="loading"
                                  hide-default-footer
                                  :footer-props="footerProps"
                                  class="elevation-1"
                                  fixed-header
                                  :height="datatableSize"
                                  :options.sync="pagination">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="text-lg-left">{{item.name}}</td>
                                <td class="text-lg-center">
                                    <v-row no-gutters justify="center" v-if="item.actif">
                                        <v-col md="3" lg="3" xl="3">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">
                                                        {{ "$vuetify.icons.squareCheck" }}
                                                    </v-icon>
                                                </template>
                                                <span v-html="'Ce support est actif à la date du jour'"/>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td class="text-lg-left">
                                    {{switchTypeSupport[item.type_support]}}
                                </td>
                                <td class="text-lg-center">
                                    <v-row no-gutters justify="center">
                                        <v-col md="4" lg="4" xl="3">
                                            <v-icon-button-with-tool-tip
                                                :routeCible="{name: 'edition-support', params: { id: item.id }}"
                                                detail="Paramétrage du support"
                                                :icon="'$vuetify.icons.edit'"
                                                btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                btnColorClass="transparent"
                                                iconColorClass="grey darken-1"/>
                                        </v-col>
                                        <v-col md="4" lg="4" xl="3">
                                            <v-icon-button-with-tool-tip
                                                v-if="item.type_support=='placement_interne'"
                                                :routeCible="{name: 'pb-support-interets-echus', params: { id: item.id }}"
                                                detail="Intérêts échus"
                                                :icon="'$vuetify.icons.percentage'"
                                                btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                btnColorClass="transparent"
                                                iconColorClass="grey darken-1"/>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <template v-slot:footer>
            <v-row no-gutters>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn color="primary" @click="downloadLES" block>
                        <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                        Livret d'épargne salariale
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn dark color="primary" slot="activator" :to="{name: 'creation-support'}" block>
                        <v-icon left>{{ "$vuetify.icons.add" }}</v-icon>
                        Créer un support de placement
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import LoadingScreen from "@/components/LoadingScreen";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";

    export default {
        name: "SupportsList",
        components: {
            LoadingScreen,
            VIconButtonWithToolTip,
            BaseLayout
        },
        data() {
            return {
                headers: [
                    {
                        text: "Nom",
                        value: "name",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Actif",
                        value: "actif",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Gestion des fonds",
                        value: "type_support",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Actions",
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ],
                switchTypeSupport: {},
                supports: [],
                loading: false,
                search: "",
                pagination: {
                    sortBy: ["actif"],
                    sortDesc: [true],
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
            }
        },
        mounted() {
            this.fetchSupportDePlacementLabels().then(result => {
                this.getAllSupports();
            });
        },
        methods: {
            /**
             * TODO #2876 voir si communalisable avec inte supports
             */
            getAllSupports() {
                this.loading = true;
                internalApi.pbSupport.getAll()
                    .then(result => {
                        this.supports = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            downloadLES() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.pbSupport.getLivretEpargneSalariale()
                    .then(result => {
                        Utils.downloadFile(result, "livret_epargne_salariale.pdf", Utils.pdfMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
            fetchSupportDePlacementLabels() {
                this.loading = true;
                return internalApi.pbSupport.getLabels().then(res => {
                    this.switchTypeSupport = res.types_support;
                })
                .finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>
