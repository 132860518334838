import defaultApi from "@/api/internalApi/defaultApi";
import repartitionCapApi from "@/api/internalApi/capital/exercice/repartitionCap";
import capAffectationApi from "@/api/internalApi/capital/exercice/affectation";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default capExerciceApi;

function capExerciceApi() {
  const specificApi = {
    // Specific capExercice api
    getCertificatsParticipation: getCertificatsParticipationExerciceById(),
    dateMinimumSouscription: dateMinimumSouscription(),

    // Submodules api
    repartition: repartitionCapApi(),
    affectation: capAffectationApi(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.capExercices.baseUrl));
}

function getCertificatsParticipationExerciceById() {
  function certificatsParticipation(id, queryParams, headers) {
    let path = internalApiRoutes.capExercices.certificatsPbById(id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return certificatsParticipation;
}

function dateMinimumSouscription() {
  function getDateMinimumSouscription(queryParams, headers) {
    let path = internalApiRoutes.capExercices.dateMinimumSouscription.baseUrl();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getDateMinimumSouscription;
}
