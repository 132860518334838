<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêter modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-if="!isModif" v-on="on" color="primary" dark block>{{openButtonMessage}}</v-btn>
            <v-icon-button-with-tool-tip v-else
                                         :detail="tooltipDetailMessage"
                                         :icon="iconValue"
                                         :activator="on"
                                         btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         block
            />
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" ref="formTmop">
                <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col md="12" lg="12" xl="12">
                                <ValidationProvider vid="annee"
                                                    name="Année"
                                                    rules="required|digits:4|min_value:2010|max_value:2100"
                                                    v-slot="{ errors }">
                                    <v-text-field v-model="tmop.annee"
                                                  label="Année"
                                                  :error-messages="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="11" lg="11" xl="11">
                                <ValidationProvider vid="semestre"
                                                    name="Semestre"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-select v-model="tmop.semestre"
                                              label="Semestre"
                                              :items="selectSemestre"
                                              :error-messages="errors"/>
                                </ValidationProvider>
                            </v-col>
                            <v-col md="1" lg="1" xl="1">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small left class="mt-6" v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                    </template>
                                    <span v-html="`Semestre 1 du 01/01 au 01/07 (inclu)<br/>Semestre 2 du 02/07 au 31/12`"/>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="12" lg="12" xl="12">
                                <ValidationProvider vid="tmop_applicable"
                                                    name="Taux moyen de rendement des obligations des sociétés privées (TMOP)"
                                                    rules="required|between:-100,100"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field v-model="tmop.tmop_applicable"
                                                                   labelValue="Taux Moyen de rendement des Obligations des sociétés Privées (TMOP)"
                                                                   prefixValue="%"
                                                                   :reverseValue="true"
                                                                   :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="mx-0">
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>{{labelConfirmation}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    import VTextField from "vuetify/lib/components/VTextField/VTextField";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";

    /**
     * Composant affichant une fênetre modal pour la saisie des données d'un TMOP
     * @displayName Participation - VTmop
     */
    export default {
        name: "v-tmop",
        components: {
            VTextField,
            VIconButtonWithToolTip,
            VFormattedNumberTextField,
        },
        data() {
            return {
                selectSemestre: undefined,
                tmop: {
                    annee: undefined,
                    semestre: undefined,
                    tmop_applicable: undefined
                },
                dialog: false,
                labelAnnulation: "NON, ABANDONNER",
                labelConfirmation: "OUI, CONFIRMER",
            }
        },
        mounted() {
            this.getSelectItems();
        },
        computed: {
            isModif() {
                return undefined;
            },
            title() {
                return undefined;
            },
            openButtonMessage() {
                return undefined;
            },
            tooltipDetailMessage() {
                return undefined;
            },
            iconValue() {
                return undefined;
            },
        },
        methods: {
            /**
             * Récupère les choix pour le semestre (1 ou 2)
             * @returns {*}
             */
            getSelectItems() {
                return internalApi.tauxMoyenRendementObli.getLabels().then(result => {
                    this.selectSemestre = Utils.fromLabelToSelect(result);
                });
            },
            submit() {
            },
            closePopUp() {
                this.dialog = false;
                this.tmop.annee = undefined;
                this.tmop.semestre = undefined;
                this.tmop.tmop_applicable = undefined;
                this.$refs.formTmop.reset();
            },
            preparedAnnee() {
                return Utils.yearToDate(this.tmop.annee);
            }
        }
    }
</script>
