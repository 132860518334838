import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import Utils from "@/utils.js";

export default scicParamCategorie;

function scicParamCategorie() {
  const specificApi = {
    getGraphParamCategorieGraphRepartitionMontantCapital: getGraphParamCategorieGraphRepartitionMontantCapital(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.scicParamCategorie.baseUrl, [
    ACCEPTED_METHODS.getLabels,
  ]));
}

function getGraphParamCategorieGraphRepartitionMontantCapital() {
  function getGraph(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.scicParamCategorie.getGraphParamCategorieGraphRepartitionMontantCapital(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getGraph;
}
