// <!-- VUETIFY2 - OK -->
import AnnulationTotale from "./AnnulationTotale";
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import constantes from "@/utils/constantes";


export default {
  mounted() {
    this.specificBackRoute = this.aSpecificBackRoute;
    this.isBrouillon = true;
    this.canEditDate = true;
    this.allowedDateAfterLastExercice = false;
    this.annulationtotale.date_mouvement = this.dateMouvement;
    this.annulationtotale.associe_id = this.associeId;
    this.date_signature_bulletin = this.dateMouvement;
    this.updateDataTable();
  },
  extends: AnnulationTotale,
  props: {
    aSpecificBackRoute: {
      type: String,
      required: false
    },
    associeId: {
      type: Number,
      required: true
    },
    dateMouvement: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
      const annulations_totale = this.annulationtotaleinformations.categories.map(categorie_detail => {
        return {
          "category_id": categorie_detail.id,
          "nb_parts_sociales": categorie_detail.nb_parts,
        };
      });
      const body = {
          "associe_id": this.annulationtotale.associe_id,
          "date_mouvement": this.annulationtotale.date_mouvement,
          "date_signa_bulle": this.date_signature_bulletin,
          "mouvements": annulations_totale
      };
      internalApi.annulationtotale.postBulletinAnnulationTotale(body)
        .then(response => {
          Utils.downloadFile(response, "bulletin_annulation.pdf", Utils.pdfMimetype);
          this.$refs.loadingComponent.resetLoading();
          this.$router.push({name: "tiers"});
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    },
    computeTitle() {
      return "Edition du bulletin d'annulation totale";
    },
    saveButtonMessage() {
      return "Editer le document";
    }
  }
};
