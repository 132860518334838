import Utils from "@/utils.js";
import LineChart from "@/graphiques/graphique_line.js";

export default {
  extends: LineChart,
  methods: {
    computeLabel(tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].code || '';
      if (label) {
        label = " Catégorie " + label + " : ";
      }
      return label + Utils.separerMilierParEspace(String(tooltipItem.yLabel)) + " €, " + Utils.separerMilierParEspace(String(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].detail)) + " parts";
    },
  },
}