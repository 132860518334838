// <!-- VUETIFY2 - OK -->
import Annulation from "./Annulation";

export default {
  mounted() {
    this.isEditable = false;
    this.getAnnulation();
  },
  extends: Annulation,
  computed: {
    annulationId() {
      return this.$route.params.id;
    },
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
    }
  }
};