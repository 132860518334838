import Vue from "vue";
import Vuex from "vuex";

import CapCompany from "./capCompany";
import Company from "./company";
import DroitsAcces from "./droitsAcces";
import Filtres from "./filtres";
import Paginations from "./paginations";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    capCompany: CapCompany,
    company: Company,
    droitsAcces: DroitsAcces,
    filtres: Filtres,
    paginations: Paginations,
  }
});
