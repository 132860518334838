<!-- VUETIFY2 - OK -->
<template>
    <v-row>
        <div class="about">
            <h1>This is an about page</h1>
        </div>
        <v-btn color="info" @click="validate()">
            call api
        </v-btn>
    </v-row>
</template>

<script>
    import api from "@/api";

    export default {
        methods: {
            validate() {
                return api.requestApi.call(this, "get", "/status");
            }
        }
    };
</script>
