<!-- VUETIFY2 - OK -->
<template>
    <v-container>
        <loading-screen ref="loadingComponent"/>
        <v-card>
            <v-container>
                <v-row align="center">
                    <v-col md="3" lg="3" xl="3">
                        <v-row>
                            <v-col md="12" lg="12" xl="12">
                                <ValidationProvider vid="selectedExercice" name="Exercice"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-exercices-list :value="selectedExerciceId"
                                                      @input="filtreMouvementsOnExercice($event)"
                                                      :errors="errors"
                                                      optionAllExercices>
                                    </v-exercices-list>
                                </ValidationProvider>
                            </v-col>
                            <v-col md="12" lg="12" xl="12" v-if="showAssocieSelection">
                                <ValidationProvider vid="selectedAssocieId"
                                                    name="Associé(s)"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <v-associes-list :value="selectedAssocieId"
                                                     label="Associé(s)"
                                                     @input="filtreMouvementsOnAssocie($event)"
                                                     :errors="errors"
                                                     optionAllAssocies/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="5" lg="5" xl="5" v-if="showTypeSelection">
                        <v-radio-group v-model="localSelectedMouvementType">
                            <v-radio v-for="typeChart in switchMouvementType" :key="typeChart.key"
                                     :label="typeChart.label"
                                     :value="typeChart.key"/>
                        </v-radio-group>
                    </v-col>
                    <v-spacer/>
                    <v-col md="2" lg="2" xl="1" v-if="canImportNewMvt">
                        <v-import-export-file detail="Importer les mouvements de parts sociales"
                                        :isIcon="true"
                                        :typesFile="getTypesExtensionsFiles().excel"
                                        @import-file="function(formData){mouvementsPartsSocialesImport(formData)}"
                                        exportDetail="Télécharger le modèle d'import de mouvements"
                                        @export-file="exportMouvements()"
                                        :allowedExport="canExportMvt"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table :footer-props="footerProps"
                                      :headers="headers"
                                      :height="height"
                                      :items="descriptivesMouvements"
                                      :options.sync="pagination"
                                      :server-items-length="total_items"
                                      :loading="isDataLoading"
                                      fixed-header>
                            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                                <v-row v-bind:key="'tooltip_' + h.value" align="center">
                                    <v-col :align-self="header.align">
                                        {{ header.text }}
                                    </v-col>
                                    <v-col v-if="header.tooltip != null">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                            </template>
                                            <span v-html="header.tooltip"/>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-lg-left">{{ item.name }}</td>
                                    <td class="text-lg-left">{{ item.numero_tiers }}</td>
                                    <td class="text-lg-center">{{ item.date_mouvement | dateIsoToFrFilter }}</td>
                                    <td class="text-lg-center">{{ item.type_mouvement }}</td>
                                    <td class="text-lg-center">{{ item.sous_type }}</td>
                                    <td class="text-lg-center">{{ compute_date_disponibilite(item) }}</td>
                                    <td class="text-lg-center">{{ item.category_name }}</td>
                                    <td class="text-lg-right">{{ item.nb_parts_sociales | roundNumberFilter }}</td>
                                    <td class="text-lg-right">{{
                                            item.montant_nominal_signe_parts_sociales |
                                                roundEuroFilter
                                        }}
                                    </td>
                                    <td class="pa-0">
                                        <v-row align="center" justify="center" no-gutters>
                                            <v-col md="3" lg="3" xl="3">
                                                <v-icon-button-with-tool-tip
                                                    v-if="item.type_mouvement === labels['sous']"
                                                    :routeCible="{name: consultationSouscriptionRoute,params: {id: item.id}}"
                                                    detail="Consulter les détails de la souscription"
                                                    :icon="'$vuetify.icons.view'"
                                                    btnIsIcon btnRound btnText btnSmall iconSmall
                                                    tooltipBottom
                                                    btnColorClass="transparent"
                                                    iconColorClass="grey darken-1"
                                                />
                                                <v-icon-button-with-tool-tip
                                                    v-else-if="item.type_mouvement === labels['annu']"
                                                    :routeCible="{name: consulationAnnulationRoute,params: {id: item.id}}"
                                                    detail="Consulter les détails de l'annulation"
                                                    :icon="'$vuetify.icons.view'"
                                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                    btnColorClass="transparent"
                                                    iconColorClass="grey darken-1"
                                                />
                                            </v-col>
                                            <v-col md="3" lg="3" xl="3">
                                                <v-icon-button-with-tool-tip
                                                    v-if="!readOnly && item.type_mouvement === labels['sous'] && item.is_editable"
                                                    :routeCible="{name: 'editableSouscription',params: {id: item.id}}"
                                                    detail="Editer la souscription"
                                                    :icon="'$vuetify.icons.edit'"
                                                    :disabled="!canUpdateOrDelete(item.id)"
                                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                    btnColorClass="transparent"
                                                    iconColorClass="grey darken-1"
                                                />
                                                <v-icon-button-with-tool-tip
                                                    v-else-if="!readOnly && item.type_mouvement === labels['annu'] && item.sous_type !== labels_sous_types['tot'] && item.is_editable"
                                                    :routeCible="{name: 'editableAnnulation',params: {id: item.id}}"
                                                    detail="Editer l'annulation"
                                                    :icon="'$vuetify.icons.edit'"
                                                    :disabled="!canUpdateOrDelete(item.id)"
                                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                    btnColorClass="transparent"
                                                    iconColorClass="grey darken-1"
                                                />
                                            </v-col>
                                            <v-col md="3" lg="3" xl="2" v-if="item.sous_type !== labels_sous_types['tot']">
                                                <v-custom-confirmation-dialog
                                                    v-if="!readOnly && item.is_editable && item.sous_type !== labels_sous_types['tot']"
                                                    :message="buildConfirmationMessage(item.type_mouvement)"
                                                    :isIcon="true"
                                                    :iconValue="'$vuetify.icons.delete'"
                                                    :disabled="!canUpdateOrDelete(item.id)"
                                                    @action-confirmee="onDeleteMouvement(item.id)"
                                                    tooltipDetailMessage="Supprimer le mouvement"/>
                                            </v-col>
                                            <v-col md="3" lg="3" xl="2" v-else>
                                                <v-custom-confirmation-dialog
                                                    v-if="!readOnly && item.is_editable && item.sous_type === labels_sous_types['tot']"
                                                    :message="buildConfirmationMessage(item.type_mouvement)"
                                                    :isIcon="true"
                                                    :iconValue="'$vuetify.icons.delete'"
                                                    :disabled="!canUpdateOrDelete(item.id)"
                                                    @action-confirmee="onDeleteAnnulationTotale(item)"
                                                    tooltipDetailMessage="Supprimer l'annulation totale"/>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import internalApi from "@/api/internalApi";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VExercicesList from "@/components/VExercicesList";
    import VImportExportFile from "@/components/VImportExportFile";
    import VExportFile from "@/components/VExportFile";
    import VAssociesList from "@/components/VAssociesList.vue"
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import authService from "@/services/authService";
    import rolesList from "@/router/rolesList";
    /**
     * Composant affichant une liste des mouvements.
     * @displayName Capital - Liste mouvements
     */
    export default {
        components: {
            VImportExportFile,
            VCustomConfirmationDialog,
            VIconButtonWithToolTip,
            VExercicesList,
            VAssociesList,
            loadingScreen,
        },
        props: {
            height: {
                type: String,
                required: true,
            },
            /**
             * Indique au composent si la datatable attends des données
             */
            isDataLoading: {
                type: Boolean,
                default: false
            },
            /**
             * Affiche ou non le composant de sélection d'un associé
             */
            showAssocieSelection: {
                type: Boolean,
                default: false
            },
            /**
             * Affiche ou non le composant de sélection par type de mouvement
             */
            showTypeSelection: {
                type: Boolean,
                default: false
            },
            /**
             * Lecture seule : aucune action possible
             */
            readOnly: {
                type: Boolean,
                default: true
            },
            /**
             * Nombre total d'éléments : utilisé pour la pagination
             */
            total_items: {
                type: Number,
                required: true
            },
            /**
             * La liste des mouvements sur la page courante
             */
            mouvementsList: {
                type: Array,
                required: true
            },
            /**
             * La liste des mouvements pouvant être modifiés ou supprimés
             */
            lastMouvementsIds: {
                type: Array,
                required: false
            },
            /**
             * La route front de consultation des annulations
             */
            consulationAnnulationRoute: {
                type: String,
                required: false,
                default: "consultationAnnulation"
            },
            /**
             * La route front de consultation des souscriptions
             */
            consultationSouscriptionRoute: {
                type: String,
                required: false,
                default: "consultationSouscription"
            },
            /**
             * L'identifiant de l'exercice sélectionné dans les filtres
             */
            selectedExerciceId: {
                type: Number,
                default: undefined
            },
            /**
             * Afiche ou non le bouton d'import excel des mouvements
             */
            canImportNewMvt: {
                type: Boolean,
                default: false
            },
            /**
             * Afiche ou non le bouton d'export excel pour saisir un groupe de mouvements
             */
            canExportMvt: {
                type: Boolean,
                default: false
            },
            /**
             * L'identifiant de l'exercice sélectionné dans les filtres
             */
            selectedAssocieId: {
                type: Number,
                default: undefined
            },
            /**
             * Le type de mouvement sélectionné dans les filtres
             */
            selectedMouvementType: {
                type: String,
                default: "all"
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: "Associé(e)",
                        value: "name",
                        width: "10%",
                        sortable: false,
                        align: "start",
                        tooltip: null
                    },
                    {
                        text: "Numéro de tiers",
                        value: "name",
                        width: "10%",
                        sortable: false,
                        align: "start",
                        tooltip: null
                    },
                    {
                        text: "Date",
                        value: "date_mouvement",
                        sortable: true,
                        align: "center",
                        tooltip: null
                    },
                    {
                        text: "Type",
                        value: "type_mouvement",
                        width: "10%",
                        sortable: false,
                        align: "center",
                        tooltip: null
                    },
                    {
                        text: "Sous type",
                        value: "sous_type",
                        width: "10%",
                        sortable: false,
                        align: "center",
                        tooltip: null
                    },
                    {
                        text: "Date de disponibilité",
                        value: "date_dispo",
                        width: "10%",
                        sortable: false,
                        align: "center",
                        tooltip: "La date de disponibilité est la date à laquelle les parts sociales seront éligibles à une annulation partielle."+
                            "<br/>Cette date concerne uniquement les parts sociales issues de la participation aux bénéfices."+
                            "<br/>Pour ces parts sociales la date de disponibilité est fixée à date du mouvement + 5 ans."
                    },
                    {
                        text: "Cat. Capital",
                        value: "category_name",
                        width: "25%",
                        sortable: false,
                        align: "center",
                        tooltip: null
                    },
                    {
                        text: "Nb parts",
                        value: "nb_parts_sociales",
                        sortable: false,
                        align: "end",
                        tooltip: null
                    },
                    {
                        text: "Montant",
                        value: "montant_remboursement_parts_sociales",
                        sortable: false,
                        align: "end",
                        tooltip: null
                    },
                    {
                        text: "Actions",
                        value: "actions",
                        sortable: false,
                        align: "center",
                        tooltip: null
                    }
                ],
                labels: {},
                labels_sous_types: {},
                pagination: {
                    sortBy: ["date_mouvement"],
                    sortDesc: [true],
                    page: 1,
                    itemsPerPage: 10
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                switchMouvementType: [
                    {
                        label: "Souscriptions et annulations de parts sociales",
                        key: "all"
                    },
                    {
                        label: "Souscriptions de parts sociales",
                        key: "sous"
                    },
                    {
                        label: "Annulations de parts sociales",
                        key: "annu"
                    },
                ],
                isAdministrateur: false,
                isResponsable: false,
            };
        },
        mounted() {
            internalApi.mouvement.getLabels().then(result => {
                this.labels = result["type_mouvement"];
                this.labels_sous_types = result["sous_type_mouvement"];
            });
            this.$emit("set-headers-value", this.headers);
            const currentRoles = authService.getCurrentRoles();
            this.isAdministrateur = !_.isEmpty(_.intersection([rolesList.ADMINISTRATEUR], currentRoles));
            this.isResponsable = !_.isEmpty(_.intersection([rolesList.RESPONSABLE], currentRoles));
        },
        watch: {
            pagination: {
                handler() {
                    this.$emit("refresh-mvts-list", this.pagination);
                },
                deep: true
            }
        },
        computed: {
            descriptivesMouvements() {
                let mvts = _.cloneDeep(this.mouvementsList);
                let labels = this.labels;
                let labels_sous_types = this.labels_sous_types;
                _.forEach(mvts, function (element) {
                    element.type_mouvement = labels[element.type_mouvement];
                    element.sous_type = labels_sous_types[element.sous_type];
                });
                return mvts;
            },
            localSelectedMouvementType: {
                get() {
                    return this.selectedMouvementType;
                },
                set(newvalue) {
                    this.filtreMouvementsOnType(newvalue);
                }
            }
        },
        methods: {
            /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            buildConfirmationMessage(type) {
                let message = "Êtes-vous sûr(e) de vouloir supprimer cette ";
                if (type === 'Souscription') {
                    message += "souscription"
                } else {
                    message += "annulation"
                }
                message += " ?";
                return message
            },
            resetPagination() {
                this.pagination.page = 1;
            },
            canUpdateOrDelete(mouvement_id) {
                return this.lastMouvementsIds.includes(mouvement_id);
            },
            onDeleteMouvement: function (mvt) {
                this.resetPagination();
                /**
                 * Déclenché quand un mouvement est supprimé
                 *
                 * @property {array} la pagination
                 */
                this.$emit("delete-mouvement", mvt, this.pagination);
            },
            onDeleteAnnulationTotale: function (mouvement) {
                this.resetPagination();
                /**
                 * Déclenché quand une annulation totale est supprimée
                 *
                 * @property {array} la pagination
                 */
                
                this.$emit("delete-annulation-totale", mouvement, this.pagination);
            },
            showDeleteBtnOverAnnulation(id) {
                let all_mvts = _.cloneDeep(this.mouvementsList)
                const minDate = new Date(Math.min(...all_mvts.map(element => {
                    return new Date(element.date_mouvement);
                })));
                all_mvts.map(element => {       
                    return ((minDate <= element.date_mouvement) && this.lastMouvementsIds.includes(id));
                })
            },
            filtreMouvementsOnExercice(newSelectedExerciceId) {
                this.resetPagination();
                /**
                 * Déclenché quand un exercice est sélectionné dans les filtres
                 *
                 * @property {number} l'id de l'exercice
                 * @property {array} la pagination
                 */
                this.$emit("update-selected-exercice", newSelectedExerciceId, this.pagination);
            },
            filtreMouvementsOnType(newSelectedMouvementType) {
                this.resetPagination();
                /**
                 * Déclenché quand un type de mouvement est sélectionné dans les filtres
                 *
                 * @property {string} le type de mouvement
                 * @property {array} la pagination
                 */
                this.$emit("update-mouvement-type-filter", newSelectedMouvementType, this.pagination)
            },
            filtreMouvementsOnAssocie(newAssocieId) {
                this.resetPagination();
                /**
                 * Déclenché quand un associé est sélectionné dans la liste
                 *
                 * @property {number} l'id de l'associé
                 * @property {array} la pagination
                 */
                this.$emit("update-associe-filter", newAssocieId, this.pagination)
            },
            importMvtsDone() {
                this.resetPagination();
                /**
                 * Refraichit les mouvements
                 *
                 * @property {array} la pagination
                 */
                this.$emit("refresh-mvts-list", this.pagination)
            },
            compute_date_disponibilite(item) {
                if (!_.isNil(item.date_disponibilite)) {
                    return Utils.formatDateIsoToFr(item.date_disponibilite);
                } else {
                    return "-";
                }
            },
            /**
             * Réalise l'import du fichier des mouvements de parts sociales
             */
            mouvementsPartsSocialesImport(formData) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.mouvement.importMouvementsPartsSociales(formData)
                    .then(() => {
                        this.importMvtsDone();
                        this.dialog = false;
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
            exportMouvements() {
                /**
                 * Déclenché quand on souhaite exporter des mouvments pour import
                 */
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.mouvement.exportMouvementsPartsSociales()
                    .then(response => {
                        Utils.downloadFile(response, "mouvements_parts_sociales.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            }
        }
    };
</script>