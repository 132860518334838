import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import defaultApi from "../defaultApi";
import {TOAST} from "@/plugins/toast/definition";

export default repartitionPbAncienneteApi;

function repartitionPbAncienneteApi() {
  const specificApi = {
    getAllByPbCompany: getAllByPbCompany()
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.repartitionPbAnciennete.baseUrl));
}


function getAllByPbCompany() {
  function getAllByPbCompany(pbCompanyId, queryParams, headers) {
    let path = internalApiRoutes.repartitionPbAnciennete.baseUrl;
    queryParams = queryParams || {};
    queryParams.pb_company_id = pbCompanyId;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getAllByPbCompany;
}