// <!-- VUETIFY2 - OK -->
import DeblocageTerme from "./DeblocageTerme";
import internalApi from "@/api/internalApi";

export default {
  extends: DeblocageTerme,
  methods: {
    submit() {
      internalApi.deblocageTerme.postAllWithToastConfirmation(this.deblocage)
        .then(result => {
          this.onBackClick();
        })
        .catch(err => {
          console.log(err);
        });
    },
  }
};