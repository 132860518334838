<!-- VUETIFY2 - OK -->
<template>
    <v-text-field v-model="textFieldToNumber"
                  :prefix="prefixValue"
                  :suffix="suffixValue"
                  :reverse="reverseValue"
                  :disabled="disabledValue"
                  :error-messages="errors"
                  :label="labelValue"
                  :hint="hintValue"
                  :persistentHint="persistentHintValue"
                  @change="propagateChange"
                  :single-line="isSingleLine"
                  :autofocus="isAutoFocus"
                  :hide-details="hideDetails"
                  :class="classes"
                  @focus="clearOnFocus"
                  :readonly="readonlyValue"
                  :prepend-inner-icon="prependInnerIcon">
        
        <template v-slot:prepend-inner>
            <slot name="prepend-inner"></slot>
        </template>
        
    </v-text-field>
</template>
<script>

    /**
     * Composant affichant un champ à remplir avec un nombre
     * @display_name Commun - Formatted Number Text Field
     */
    export default {
        name: "v-formatted-number-text-field",
        props: {
            value: {
                twoWay: true,
                required: true
            },
            errors: {
                type: Array,
                required: false
            },
            prefixValue: {
                type: String,
                required: false
            },
            labelValue: {
                type: String,
                required: false
            },
            suffixValue: {
                type: String,
                required: false
            },
            reverseValue: {
                type: Boolean,
                required: false
            },
            disabledValue: {
                type: Boolean,
                required: false
            },
            hintValue: {
                type: String,
                required: false
            },
            persistentHintValue: {
                type: Boolean,
                required: false
            },
            changeFunctionValue: {
                type: Function,
                required: false
            },
            isSingleLine: {
                type: Boolean,
                required: false
            },
            isAutoFocus: {
                type: Boolean,
                required: false
            },
            hideDetails: {
                type: Boolean,
                required: false,
                default: false
            },
            specificClass: {
                type: String,
                required: false,
                default: ""
            },
            /**
             * Valeur minimale du champ
             */
            min: {
                type: Number,
                required: false,
                default: undefined
            },
            prependInnerIcon: {
                type: String,
                required: false,
                default: undefined,
            },
            readonlyValue: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        computed: {
            textFieldToNumber: {
                get: function () {
                    if (!_.isNil(this.value)) {
                        return this.value.toLocaleString("Fr-fr", {minimumSignificantDigits: 1});
                    }
                    return this.value;
                },
                set: function (newvalue) {
                    // on commence par remplacer les espaces
                    let mutableValue;
                    const newvalueWithoutSpace = newvalue.replace(/\s/g, "");
                    // on remplace l'éventuel . par une virgule
                    const switchToPointSeparator = newvalueWithoutSpace.replace(/,/g, ".");
                    const number = (_.isNil(switchToPointSeparator) || switchToPointSeparator === "") ? undefined : _.toNumber(switchToPointSeparator);
                    // si c'est un nombre valide
                    if (!isNaN(number)) {
                        mutableValue = number;
                    }
                    // sinon
                    else {
                        mutableValue = switchToPointSeparator;
                    }
                    // On check la valeur minimale et on remplace si on la dépasse
                    if (this.min !== undefined && mutableValue<this.min){
                        mutableValue = this.min;
                    }
                    // On check si c'est la string vide et dans ce cas on renvoie null
                    if(mutableValue === "")
                        mutableValue = null;

                    this.$emit("input", mutableValue);
                }
            },
            classes: {
                get: function() {
                    let result = this.specificClass;
                    if (this.readonlyValue) {
                        result += " v-input--is-disabled";
                    }
                    return result.trim();
                }
            }
        },
        methods: {
            propagateChange: function () {
                this.$emit('change', this.value)
            },
            clearOnFocus() {
                if (this.value == 0){
                    this.$emit("input", null);
                }
            },
        }
    };
</script>
