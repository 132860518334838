import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default fiscaliteParticipationApi;

function fiscaliteParticipationApi() {
  return {
    ifu2561Infos: getIfu2561Infos(),
    ifu2561PDF: getIfu2561PDF(),
    pfu2777Infos: getPfu2777Infos(),
    pfu2777PDF: getPfu2777PDF(),
  };
}

function getIfu2561Infos() {
  function ifu2561Infos(year, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_participation.ifu2561Infos();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.year = year;
    return getForPath(queryParams, headers);
  }

  return ifu2561Infos;
}

function getIfu2561PDF() {
  function ifu2561PDF(year, versionCerfa, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_participation.ifu2561PDF();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.year = year;
    queryParams.nom_version_cerfa = versionCerfa;
    return getForPath(queryParams, headers);
  }

  return ifu2561PDF;
}

function getPfu2777Infos() {
  function pfu2777Infos(year, month, versionCerfa, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_participation.pfu2777Infos();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.year = year;
    queryParams.month = month;
    queryParams.nom_version_cerfa = versionCerfa;
    return getForPath(queryParams, headers);
  }

  return pfu2777Infos;
}

function getPfu2777PDF(){
  function pfu2777PDF(year, month, versionCerfa, queryParams, headers) {
      let path = internalApiRoutes.fiscalite_participation.pfu2777PDF();
      let getForPath = apiConstructor.get(path);
      queryParams = queryParams || {};
      queryParams.year = year;
      queryParams.month = month;
      queryParams.nom_version_cerfa = versionCerfa;
      return getForPath(queryParams, headers);
  }
  
  return pfu2777PDF;
}
