import Index from "@/views/Index";
import Exercice from "@/views/participation/exercice/PbExercice";
import ExercicesList from "@/views/participation/exercice/PbExercicesList";
import rolesList from "@/router/rolesList";
import RepartitionPb from "@/views/participation/exercice/RepartitionPb.vue";
import VentilationPb from "@/views/participation/exercice/VentilationPb.vue";

export default {
  path: "exercices/",
  component: Index,
  children: [
    {
      path: "",
      name: "pb-exercices",
      component: ExercicesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/edit/",
      name: "pb-edition-exercice",
      props: {
        creationMode: false
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "create/",
      name: "pb-creation-exercice",
      props: {
        creationMode: true
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/repartition-pb/",
      name: "repartition-pb",
      component: RepartitionPb,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/ventilation-pb/",
      name: "ventilation-pb",
      component: VentilationPb,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
  ]
};
