import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import "@/plugins/toast";
import "@/plugins/veevalidate";
import App from "@/App.vue";
import router from "@/router";
import api from "@/api";
import store from "@/store";
import "@/utils/vueFilters";
import fr from 'vee-validate/dist/locale/fr';

Vue.config.productionTip = process.env.NODE_ENV !== "production";

new Vue({
  iconfont: "fa",
  vuetify,
  router,
  store,
  api,
  render: h => h(App)
}).$mount("#app");
