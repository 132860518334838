<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false">
        <template v-slot:header>
            <loading-screen ref="loadingComponent"/>
            <v-personne-morale-header-raison-sociale
                :raison_sociale="personne_morale.raison_sociale"
                :numero_tiers="personne_morale.numero_tiers"
                :siret="personne_morale.siret"
            />
            <v-tabs v-model="currentTab">
                <v-tab key="donnees_collaborateur">Données tiers</v-tab>
                <v-tab key="docs" :disabled="!collaborateurId" v-if="!isEspacePersonnel">Documents du tiers</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="donnees_collaborateur">
                    <ValidationObserver v-slot="{ validate, errors, reset }">
                        <base-layout :withHeader="false">
                            <template v-slot:main>
                                <v-row no-gutters justify="center">
                                    <v-col md="12" lg="12" xl="11">
                                        <v-row class="mb-3 ml-3" no-gutters>
                                            <v-col cols="2">
                                                <h4 v-if="personne_morale.username">Nom d'utilisateur : {{ personne_morale.username }}</h4>
                                                <h4 v-else>Aucun accès utilisateur</h4>
                                            </v-col>
                                        </v-row>

                                        <v-expansion-panels multiple v-model="openedPanels" class="mb-2">

                                            <!-- Informations société -->
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    <v-row no-gutters align="center">
                                                        <v-col md="4" lg="4" xl="3">
                                                            <h3>Informations société</h3>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content eager>
                                                    <v-row>
                                                        <v-col md="6" lg="6" xl="6">
                                                            <ValidationProvider vid="raison_sociale"
                                                                                name="Raison sociale"
                                                                                rules="required|max:100"
                                                                                v-slot="{ errors }">
                                                                <v-text-field v-model="personne_morale.raison_sociale"
                                                                              label="Raison sociale"
                                                                              :error-messages="errors"
                                                                              :counter="100"
                                                                />
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col md="6" lg="6" xl="6">
                                                            <ValidationProvider vid="responsable_legal"
                                                                                name="Responsable légal"
                                                                                rules="max:100"
                                                                                v-slot="{ errors }">
                                                                <v-text-field v-model="personne_morale.responsable_legal"
                                                                              label="Responsable légal"
                                                                              :error-messages="errors"
                                                                              :counter="100"
                                                                />
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col md="3" lg="3" xl="3">
                                                            <ValidationProvider vid="numero_tiers"
                                                                                name="Numéro de tiers"
                                                                                rules="required"
                                                                                v-slot="{ errors }">
                                                                <v-text-field v-model="personne_morale.numero_tiers"
                                                                            label="Numéro de tiers"
                                                                            :error-messages="errors"
                                                                            :disabled="isEspacePersonnel"
                                                                />
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row justify="space-between">
                                                        <v-col md="4" lg="4" xl="4">
                                                            <ValidationProvider vid="siret"
                                                                                name="SIRET"
                                                                                rules="required|max:14"
                                                                                v-slot="{ errors }">
                                                                <v-text-field v-model="personne_morale.siret"
                                                                              label="SIRET"
                                                                              :error-messages="errors"
                                                                              :counter="14"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col md="3" lg="3" xl="3">
                                                            <ValidationProvider vid="legal_link"
                                                                                name="Lien juridique"
                                                                                rules="required"
                                                                                v-slot="{ errors }">
                                                                <v-select v-model="personne_morale.legal_link"
                                                                          label="Lien juridique"
                                                                          :items="selectItems.legal_link"
                                                                          :error-messages="errors"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col md="3" lg="3" xl="3">
                                                            <ValidationProvider vid="legal_form"
                                                                                name="Forme juridique"
                                                                                rules="required"
                                                                                v-slot="{ errors }">
                                                                <v-select v-model="personne_morale.legal_form"
                                                                          :items="selectItems.legal_form"
                                                                          :error-messages="errors"
                                                                          label="Forme juridique"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row justify="space-between">
                                                        <v-col md="6" lg="6" xl="6">
                                                            <ValidationProvider vid="is_coop"
                                                                                name="Est une coopérative"
                                                                                rules="required"
                                                                                v-slot="{ errors }">
                                                                <v-checkbox v-model="personne_morale.is_coop"
                                                                            label="Est une coopérative"
                                                                            false-value="false"
                                                                            :error-messages="errors"
                                                                            @change="clearFieldIfNoCoop()"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col md="6" lg="6" xl="6">
                                                            <ValidationProvider vid="coop_form"
                                                                                name="Est une coopérative"
                                                                                :rules="{ required: personne_morale.is_coop}"
                                                                                v-slot="{ errors }">
                                                                <v-select v-model="personne_morale.coop_form"
                                                                          label="Type de coopérative"
                                                                          :items="selectItems.coop_form"
                                                                          :error-messages="errors"
                                                                          :disabled="!personne_morale.is_coop"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row justify="space-between">
                                                        <v-col md="6" lg="6" xl="6">
                                                            <ValidationProvider vid="email"
                                                                                name="Email contact"
                                                                                rules="email"
                                                                                v-slot="{ errors }">
                                                                <v-text-field v-model="personne_morale.email"
                                                                              label="Email contact"
                                                                              :error-messages="errors"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col md="5" lg="5" xl="5">
                                                            <ValidationProvider vid="accept_envoi_demat"
                                                                                name="Accepte les envois dématérialisés"
                                                                                rules="required"
                                                                                v-slot="{ errors }">
                                                                <v-checkbox v-model="personne_morale.accept_envoi_demat"
                                                                            label="Accepte les envois dématérialisés"
                                                                            false-value="false"
                                                                            :error-messages="errors"/>
                                                            </ValidationProvider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-address
                                                        :address1="personne_morale.adresse.address1"
                                                        :address2="personne_morale.adresse.address2"
                                                        :zipCode="personne_morale.adresse.zip_code"
                                                        :city="personne_morale.adresse.city"
                                                        :typeVoie="personne_morale.adresse.type_voie"
                                                        :numeroVoie="personne_morale.adresse.numero_voie"
                                                        :country="personne_morale.adresse.country"
                                                        @update-address-1="personne_morale.adresse.address1 = $event"
                                                        @update-address-2="personne_morale.adresse.address2 = $event"
                                                        @update-zip-code="personne_morale.adresse.zip_code = $event"
                                                        @update-city="personne_morale.adresse.city = $event"
                                                        @update-numero-voie="personne_morale.adresse.numero_voie = $event"
                                                        @update-type-voie="personne_morale.adresse.type_voie = $event"
                                                        @update-country="personne_morale.adresse.country = $event"
                                                        ref="adresse"
                                                    />
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                            <!-- Informations bancaires -->
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    <v-row no-gutters align="center">
                                                        <v-col md="4" lg="4" xl="3">
                                                            <h3>Informations bancaires</h3>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content eager>
                                                    <v-informations-bancaire
                                                        :nom_banque="personne_morale.infos_bancaire.nom_banque"
                                                        :bic="personne_morale.infos_bancaire.bic"
                                                        :iban="personne_morale.infos_bancaire.iban"
                                                        @update-nom-banque="personne_morale.infos_bancaire.nom_banque = $event"
                                                        @update-bic="personne_morale.infos_bancaire.bic = $event"
                                                        @update-iban="personne_morale.infos_bancaire.iban = $event"
                                                        ref="infosBancaires"
                                                    />
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                            <!-- Rôle associé(e) -->
                                            <v-expansion-panel v-if="!isEspacePersonnel">
                                                <v-expansion-panel-header>
                                                    <v-row no-gutters align="center">
                                                        <v-col md="4" lg="4" xl="3">
                                                            <h3>Rôle associé(e)</h3>
                                                        </v-col>
                                                        <v-col md="1" lg="1" xl="1" v-if="is_already_associe">
                                                            <icone-etat-associe
                                                                :is-associe="personne_morale.is_currently_associe"
                                                                :is-associe-by-dates="personne_morale.is_currently_associe_by_dates"
                                                                :is-associe-by-capital="personne_morale.is_currently_associe_by_capital"
                                                            />
                                                        </v-col>
                                                        <v-col md="3" lg="2" xl="2" v-else style="margin-bottom: -20px;">
                                                            <v-checkbox v-model="modifier_donnees_associe" label="Activer le rôle" v-on:click.stop @change="onAssocieCheckboxChanges($event)"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content eager>
                                                    <v-donnees-associe
                                                        :modifier_donnees_associe="modifier_donnees_associe"
                                                        :disable_renonciation_prlvt_forfaitaire="true"
                                                        :date_acces_societariat="personne_morale.associe.date_acces_societariat"
                                                        :date_evt_declencheur_societariat="personne_morale.associe.date_evt_declencheur_societariat"
                                                        :lib_evt_declencheur_societariat="personne_morale.associe.lib_evt_declencheur_societariat"
                                                        :date_perte_qualite_associe="personne_morale.associe.date_perte_qualite_associe"
                                                        :motif_perte_qualite_associe="personne_morale.associe.motif_perte_qualite_associe"
                                                        :type_droit_de_vote="personne_morale.associe.type_droit_de_vote"
                                                        :renonciation_ips="personne_morale.associe.renonciation_ips"
                                                        :blocage_souscriptions="personne_morale.associe.blocage_souscriptions"
                                                        :exclu_documents_legaux="personne_morale.associe.exclu_documents_legaux"
                                                        :selectMotifPerteQualiteAssocie="selectItems.motif_perte_qualite_associe"
                                                        :selectTypeDroitDeVote="selectItems.type_droit_de_vote"
                                                        :scicCategorieAssocie="personne_morale.associe.scic_donnees_associe.code_scic_categorie"
                                                        :scicSousCategorieAssocie="personne_morale.associe.scic_donnees_associe.code_scic_sous_categorie"
                                                        :scicCollegeAssocie="personne_morale.associe.scic_donnees_associe.code_scic_college"
                                                        @update-date-acces-societariat="personne_morale.associe.date_acces_societariat = $event"
                                                        @update-date-evt-declencheur-societariat="personne_morale.associe.date_evt_declencheur_societariat = $event"
                                                        @update-lib-evt-declencheur-societariat="personne_morale.associe.lib_evt_declencheur_societariat = $event"
                                                        @update-date-perte-qualite-associe="personne_morale.associe.date_perte_qualite_associe = $event"
                                                        @update-motif-perte-qualite-associe="personne_morale.associe.motif_perte_qualite_associe = $event"
                                                        @update-renonciation-ips="personne_morale.associe.renonciation_ips = $event"
                                                        @update-type-droit-de-vote="personne_morale.associe.type_droit_de_vote = $event"
                                                        @update-blocage-souscriptions="personne_morale.associe.blocage_souscriptions = $event"
                                                        @update-exclu-documents-legaux="personne_morale.associe.exclu_documents_legaux = $event"
                                                        @update-scic-categorie-associe="personne_morale.associe.scic_donnees_associe.code_scic_categorie = $event"
                                                        @update-scic-sous-categorie-associe="personne_morale.associe.scic_donnees_associe.code_scic_sous_categorie = $event"
                                                        @update-scic-college-associe="personne_morale.associe.scic_donnees_associe.code_scic_college = $event"
                                                        ref="associe">
                                                    </v-donnees-associe>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:footer>
                                <v-row justify="end" no-gutters>
                                    <!-- Petits boutons -->
                                    <v-col md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                      :resetForm="reset"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="cancel"
                                                                      @emit-valid-save="saveData"
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </ValidationObserver>
                </v-tab-item>
                <v-tab-item key="docs" v-if="!isEspacePersonnel">
                    <v-documents-list :collaborateurId="collaborateurId"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import VAddress from "@/components/VAddress.vue";
    import VDocumentsList from "@/views/documents/VDocumentsList.vue";
    import VDonneesAssocie from "@/components/tiers/VDonneesAssocie";
    import VInformationsBancaire from "@/components/VInformationsBancaire.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import VPersonneMoraleHeaderRaisonSociale from "@/components/tiers/VPersonneMoraleHeaderRaisonSociale.vue";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import authService from "@/services/authService";
    import IconeEtatAssocie from "@/components/IconeEtatAssocie.vue";

    export default {
        components: {
            BaseLayout,
            VAddress,
            VInformationsBancaire,
            VDocumentsList,
            VDonneesAssocie,
            loadingScreen,
            VSaveButonWithValidation,
            VPersonneMoraleHeaderRaisonSociale,
            IconeEtatAssocie,
        },
        data() {
            return {
                openedPanels: [0],
                associePanelIndex: 2,
                isEspacePersonnel: true,
                currentTab: undefined,
                //model in modification
                personne_morale: {
                    raison_sociale: undefined,
                    siret: undefined,
                    legal_form: undefined,
                    email: undefined,
                    is_coop: false,
                    coop_form: undefined,
                    legal_link: "aucun",
                    personne_type: "per_mor",
                    accept_envoi_demat: false,
                    is_currently_associe: false,
                    associe: {
                        date_acces_societariat: undefined,
                        date_evt_declencheur_societariat: undefined,
                        lib_evt_declencheur_societariat: undefined,
                        date_perte_qualite_associe: undefined,
                        motif_perte_qualite_associe: undefined,
                        renonciation_ips: false,
                        type_droit_de_vote: undefined,
                        blocage_souscriptions: false,
                        exclu_documents_legaux: false,
                        scic_donnees_associe: {
                            code_scic_categorie: undefined,
                            code_scic_sous_categorie: undefined,
                            code_scic_college: undefined,
                        },
                    },
                    adresse: {
                        address1: undefined,
                        address2: undefined,
                        zip_code: undefined,
                        city: undefined,
                        type_voie: undefined,
                        numero_voie: undefined,
                        country: "FRANCE"
                    },
                    infos_bancaire: {
                        nom_banque: undefined,
                        bic: undefined,
                        iban: undefined
                    },
                },
                //Object containing selectors
                selectItems: {},
                modifier_donnees_associe: false,
                is_already_associe: false,
            };
        },
        // method call when page is opening
        mounted() {
            this.getSelectItems();
        },
        computed: {
            choices() {
                const {getCompanyLabel} = this.$store.getters;

                return {
                    form: getCompanyLabel("company.legal_form"),
                };
            },
            collaborateurId: function () {
                return this.$route.params.id;
            }
        },
        methods: {
            onAssocieCheckboxChanges(newValue) {
                if (newValue) {
                    this.openPanelAssocie();
                } else {
                    this.closePanelAssocie();
                }
            },

            openPanelAssocie() {
                Utils.openExpandablePanel(this.openedPanels, this.associePanelIndex);
            },
            closePanelAssocie() {
                Utils.closeExpandablePanel(this.openedPanels, this.associePanelIndex);
            },

            hasCapitalAccess: function () {
                return authService.hasAccessCapital();
            },
            // get labels of personnes morales for selector
            getSelectItems() {
                return internalApi.personneMorale.getLabels().then(result => {
                    let {associe} = result;
                    let {personne_morale} = result;
                    this.selectItems = {
                        legal_form: Utils.fromLabelToSelect(personne_morale["per_mor.legal_form"]),
                        coop_form: Utils.fromLabelToSelect(personne_morale["per_mor.coop_form"]),
                        legal_link: Utils.fromLabelToSelect(personne_morale["per_mor.legal_link"]),
                        motif_perte_qualite_associe: Utils.fromLabelToSelect(
                            associe.motif_perte_qualite_associe
                        ),
                        type_droit_de_vote: Utils.fromLabelToSelect(
                            associe.type_droit_de_vote
                        ),
                    };
                });
            },
            updatePersonneMoraleBeforeSend: function () {
                let data_to_send = _.cloneDeep(this.personne_morale);

                if (!this.modifier_donnees_associe) {
                    data_to_send.associe = undefined;
                } else {
                    // le clearable de vuetify set la variable à undefined. Or Axios drop les paramètres undefined. on force donc ces valeur à null si besoin
                    if (data_to_send.associe.motif_perte_qualite_associe === undefined) {
                        data_to_send.associe.motif_perte_qualite_associe = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_categorie === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_categorie = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_sous_categorie === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_sous_categorie = null;
                    }
                    if (data_to_send.associe.scic_donnees_associe.code_scic_college === undefined) {
                        data_to_send.associe.scic_donnees_associe.code_scic_college = null;
                    }
                }

                if (_.isEmpty(data_to_send.infos_bancaire) || !this.$refs.infosBancaires.infosBancairesFull()) {
                    data_to_send.infos_bancaire = undefined;
                }

                if (data_to_send.email === "") {
                    data_to_send.email = null;
                }

                delete data_to_send.personne_type;
                return data_to_send;
            },
            clearFieldIfNoCoop() {
                const person = this.personne_morale;
                if (!person.is_coop) {
                    person.coop_form = null;
                }
            },

            /**
             * Calls form field validations and then call API to create / update
             * model if all of them filled with the right format.
             */
            saveData() {
                this.submitForm();
            },
            cancel() {
                this.$router.push({name: "tiers"});
            }
        },
    };
</script>
