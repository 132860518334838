export default {
  univers: {
    commun: "COMMUN",
    capital: "CAPITAL",
    participation:"PARTICIPATION",
    interessement: "INTERESSEMENT",
  },
  loading_message: {
    chargement: "Chargement en cours. Merci de patienter.",
    telechargement: "Téléchargement de votre fichier en cours.",
    modification: "Modification en cours.",
    enregistrement: "Enregistrement en cours.",
    suppression: "Suppression en cours.",
    export: "Export des données en cours.",
    import: "Import des données en cours.",
    importLong: "Import des données en cours, le traitement est susceptible de durer plusieurs minutes.",
    exportLong: "Export des données en cours, le traitement est susceptible de durer plusieurs minutes.",
  },
  toast_message: {
    missingCerfaTitle: "Version du cerfa manquante",
    missingCerfaBody: "Veuillez sélectionner une version du cerfa à générer pour le télécharger.",
    missingCerfaMonthTitle: "Mois manquant",
    missingCerfaMonthBody: "Veuillez sélectionner un mois pour générer les informations du cerfa.",
  },
  monthsList: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  // ===== Ces constantes doivent être répétées dans le back "app/models/droits_acces.py" =====
  droits_acces: {
    types: {
      type_personnel: "type_personnel",
    },
    noms: {
      donnees_personnelles: "donnees_personnelles",
      espace_capital: "espace_capital",
      espace_capital_affectation_montee_capital: "espace_capital_affectation_montee_capital",
      espace_capital_affectation_versement_immediat: "espace_capital_affectation_versement_immediat",
      espace_capital_affectation_compte_courant: "espace_capital_affectation_compte_courant",
      espace_participation: "espace_participation",
      espace_pb_affectation_montee_capital: "espace_pb_affectation_montee_capital",
      espace_pb_affectation_versement_immediat: "espace_pb_affectation_versement_immediat",
      espace_pb_affectation_supports: "espace_pb_affectation_supports",
      espace_individuel_interessement: "espace_individuel_interessement",
      espace_inte_affectation_versement_immediat: "espace_inte_affectation_versement_immediat",
      espace_inte_affectation_supports: "espace_inte_affectation_supports",
      documents: "documents",
      
    },
    valeurs_possibles: {
      edition: "edition",
      consultation: "consultation",
    },
  },
  // Constante pour les types d'extensions de fichier //
  extensions_fichiers: {
    excel: ".xlsx, .xlsm, .xls, .xltx, .xltm",
    pdf: ".pdf",
    img: ".png, .jpeg, .jpg",
  },
  // Constantes pour les filtres //
  filter: {
    all: "all",
    true: "true",
    false: "false",
  },
  couleurs_accentuation: {
    bleu: { // Bleu "Eziscop"
      principale: "rgba(0, 181, 216, 1)", // Opaque pour accentuation forte
      secondaire: "rgba(0, 181, 216, 0.2)", // Transparent à 80% pour accentuation légère
    },
    orange: {
      principale: "rgba(216, 149, 44, 1)",
      secondaire: "rgba(216, 149, 44, 0.2)",
    },
  },
};
