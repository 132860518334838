// <!-- VUETIFY2 - OK -->
import VDatatableMouvementsSupportsSimplifie from "./VDatatableMouvementsSupportsSimplifie";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";

export default {
  extends: VDatatableMouvementsSupportsSimplifie,
  props: {
    beneficiaireId: Number,
  },
  methods: {
    updateDataTable() {
      if (!_.isNil(this.beneficiaireId)) {
        this.getOperationsList();
      }
    },
    getOperationsList() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
      let promise;
      if (_.isNil(this.supportId)) {
        promise = internalApi.operations.getOperationsBySupportsSimplifie(this.beneficiaireId, this.dateOperation, this.tauxRemu, this.aTerme);
      } else {
        promise = internalApi.operations.getOperationsBySupportsSimplifieForSupport(this.beneficiaireId, this.supportId, this.dateOperation, this.tauxRemu, this.aTerme);
      }
      return promise.then(result => {
        this.operationsBySupports = result.operations_by_support;
        this.montantTotal = result.montant_total;
        this.computeTotalOnDateOperation();

        const warn_msg_interets_courus = result.warn_msg_interets_courus;
        if (!_.isNil(warn_msg_interets_courus)) {
          this.tooltipInteretsCourus = warn_msg_interets_courus;
        } else {
          this.tooltipInteretsCourus = this.nominalMessageInteretsCourus;
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.$refs.loadingComponent.resetLoading();
      });
    },
  },
};