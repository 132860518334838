<template>

    <ValidationObserver ref="formDocuments" v-slot="{ reset, validate, errors }">
        <base-layout :withHeader="false" :withFooter="true">
            <template v-slot:main>
                <loading-screen ref="loadingComponentScic"/>
                <h3 class="ml-2">Tous les documents liés au statut Scic</h3>
                <v-row class="ml-2">
                    <v-col cols="3">
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list :value="selectedExerciceId"
                                              @update-selected-exercice="onExerciceChange($event)"
                                              :errors="errors"
                                              ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row class="ml-2">
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2" class="mr-4">
                        <v-import-export-file detail="Importer la signature"
                                            :isIcon="false"
                                            :typesFile="getTypesExtensionsFiles().img"
                                            @import-file="function(formData){onSignatureChange(formData)}"
                                            :allowedExport="false"/>
                    </v-col>
                    <v-col md="3" lg="3" xl="2" class="mr-4">
                        <img :src="signature.binary" v-if="signature.binary !== null"
                             style="max-width: 100%; max-height: 300px"/>
                    </v-col>
                </v-row>
                <h3 class="ml-2">Attestation fiscale</h3>
                <v-row class="ml-2">
                    <v-col>
                        <ValidationProvider vid="free_text_attestation_fiscale"
                                            name="texte libre sur l'attestation fiscale"
                                            rules="max:20000"
                                            v-slot="{ errors }">
                            <v-textarea
                                v-model="texte_juridique_exercice.string"
                                name="texte_juridique_exercice"
                                filled
                                label="Texte libre sur les éléments juridique de l'attestation fiscale"
                                auto-grow
                                :error-messages="errors[0]"
                            ></v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row class="ml-2">
                    <v-col>
                        <ValidationProvider vid="free_text_attestation_fiscale"
                                            name="texte libre sur l'attestation fiscale"
                                            rules="max:5000"
                                            v-slot="{ errors }">
                            <v-textarea
                                v-model="free_text_attestation_fiscale_obj.string"
                                name="free_text_attestation_fiscale"
                                filled
                                label="Texte libre sur l'attestation fiscale"
                                auto-grow
                                :error-messages="errors[0]"
                            ></v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row no-gutters justify="end">
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :resetForm="reset"
                                                      :validateForm="validate"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancelDocuments()"
                                                      @emit-valid-save="saveDocuments()"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import internalApi from "@/api/internalApi";
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue"
import BaseLayout from "@/components/BaseLayout";
import loadingScreen from "@/components/LoadingScreen";
import constantes from "@/utils/constantes";
import VImportExportFile from "@/components/VImportExportFile";
import VExercicesList from "@/components/VExercicesList";
import _ from "lodash";

export default {
    name: "VScicAdministration",
    data() {
        return {
            free_text_attestation_fiscale_obj: {
                string: null,
                code: "free_text_scic_attestation_fiscale",
                binary: null,
                exercice_id: null
            },
            texte_juridique_exercice: {
                string: null,
                code: "scic_attestation_fiscale_texte_juridique_exercice",
                binary: null,
                exercice_id: null
            },
            signature: {string: null, code: "signature", binary: null, exercice_id: null},
            selectedExerciceId: null,
        }
    },
    components: {
        BaseLayout,
        VSaveButonWithValidation,
        loadingScreen,
        VExercicesList,
        VImportExportFile,
    },
    methods: {
        fetchData() {
            internalApi.scicAdministrationParametres.getById(this.selectedExerciceId).then(result => {
                let founded_code = _.find(result, ["code", "free_text_scic_attestation_fiscale"]);
                if (founded_code !== undefined) {
                    this.free_text_attestation_fiscale_obj = founded_code;
                } else {
                    this.free_text_attestation_fiscale_obj = {
                        string: null,
                        code: "free_text_scic_attestation_fiscale",
                        binary: null,
                        exercice_id: null
                    };
                }
                let founded_code_signature = _.find(result, ["code", "signature"]);
                if (founded_code_signature !== undefined) {
                    this.signature = founded_code_signature;
                } else {
                    this.signature = {string: null, code: "signature", binary: null, exercice_id: null};
                }
                let founded_code_texte_juridique_exercice = _.find(result, ["code", "scic_attestation_fiscale_texte_juridique_exercice"]);
                if (founded_code_texte_juridique_exercice !== undefined) {
                    this.texte_juridique_exercice = founded_code_texte_juridique_exercice;
                } else {
                    this.texte_juridique_exercice = {
                        string: null,
                        code: "scic_attestation_fiscale_texte_juridique_exercice",
                        binary: null,
                        exercice_id: null
                    };
                }
            }).catch(err => {
                console.log(err);
            });
        },
        saveDocuments() {
            let data_text_attestation_fiscale = _.cloneDeep(this.free_text_attestation_fiscale_obj);
            delete data_text_attestation_fiscale.id;
            let data_signature = _.cloneDeep(this.signature);
            delete data_signature.id;
            let data_texte_juridique_exercice = _.cloneDeep(this.texte_juridique_exercice);
            delete data_texte_juridique_exercice.id;
            let payload = [data_text_attestation_fiscale, data_signature, data_texte_juridique_exercice]

            this.$refs.loadingComponentScic.setLoading(constantes.loading_message.enregistrement);
            internalApi.scicAdministrationParametres.putByIdWithToastConfirmation(this.selectedExerciceId, payload)
                .catch(err => {
                    console.error(err);
                })
                .finally(this.$refs.loadingComponentScic.resetLoading());
        },
        cancelDocuments() {
            this.selectedExerciceId = null;
            this.free_text_attestation_fiscale_obj = {
                string: null,
                code: "free_text_scic_attestation_fiscale",
                binary: null,
                exercice_id: null
            },
                this.texte_juridique_exercice = {
                    string: null,
                    code: "scic_attestation_fiscale_texte_juridique_exercice",
                    binary: null,
                    exercice_id: null
                },
                this.signature = {string: null, code: "signature", binary: null, exercice_id: null}
        },
        onSignatureChange(formData) {
            this.$refs.loadingComponentScic.setLoading(constantes.loading_message.enregistrement);
            let file = formData.get("file")
            if (!_.isNil(file)) {
                this.getBase64(file)
                    .then(result => {
                        this.signature.binary = result;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(this.$refs.loadingComponentScic.resetLoading());
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (e) => reject(e)
            });
        },
        onExerciceChange(selected_exercice) {
            this.selectedExerciceId = selected_exercice.id;
            this.fetchData();
        },
        /**
         * Retourne la constante des types d'extensions de fichiers
         */
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
    }
}
</script>

<style scoped>

</style>