// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Souscription from "./Souscription";
import Utils from "@/utils";
import constantes from "@/utils/constantes";


export default {
  mounted() {
    this.isEditable = true;
    this.canEditDate = true;
    this.isBrouillon = true;
    this.allowedDateAfterLastExercice = false;
    this.souscription.associe_id = this.associeId;
    this.souscription.date_mouvement = this.dateMouvement;
    this.souscription.date_signa_bulle = this.dateMouvement;
    this.specificBackRoute = this.aSpecificBackRoute;
    this.title = "Edition du bulletin de souscription";
  },
  extends: Souscription,
  computed: {},
  props: {
    associeId: {
      type: Number,
      required: true
    },
    dateMouvement: {
      type: String,
      required: true
    },
    aSpecificBackRoute: {
      type: String,
      required: false
    }
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
      internalApi.souscription.postBulletinSoucriptionLibre(this.souscription)
        .then(response => {
          Utils.downloadFile(response, "bulletin_souscription.pdf", Utils.pdfMimetype);
          this.$refs.loadingComponent.resetLoading();
          this.$router.push({name: "tiers"});
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    },
    saveButtonMessage() {
      return "Editer le document";
    }
  }
};
