<!-- VUETIFY2 - OK -->
<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col class="text-lg-center">
                <div>
                    <v-icon size="100px">{{ "$vuetify.icons.forbidden" }}</v-icon>
                </div>
                <div class="mt-5 mb-10">
                    <span>Vous n'êtes pas autorisé(e) à accéder à cet écran.</span>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {};
</script>
