import {Line} from "vue-chartjs";
import Utils from "@/utils.js";
import ChartsUtils from "@/graphiques/graphique_utils.js";

export default {
  extends: Line,
  props: {
    data: undefined,
    limit: undefined
  },
  watch: {
    data: function () {
      this.renderChartComponent();
    }
  },
  mounted() {
    if (!_.isNil(this.data)) {
      this.renderChartComponent();
    }
  },
  methods: {
    generatePng(title) {
      ChartsUtils.generatePng(title, this.$data._chart);
    },
    resize() {
      this.$data._chart.resize();
    },
    renderChartComponent() {
      let data_set_list = [];
      for (let i = 0; i < this.data.length; i++) {
        let categ = this.data[i];
        let categorieData = [];
        for (let j = 0; j < categ.liste_points.length; j++) {
          let mvt = categ.liste_points[j];
          categorieData.push(
            {
              x: mvt.x,
              y: mvt.y,
              detail: mvt.detail
            });
        }
        let dataset = {
          label: categ.libelle,
          backgroundColor: ChartsUtils.fixedColorList[i],
          borderColor: ChartsUtils.fixedColorList[i],
          data: categorieData,
          fill: false,
          code: categ.code,
        };
        data_set_list.push(dataset);
      }
      this.renderChart({
          datasets: data_set_list
        },
        {
          scales: {
            xAxes: [{
              type: 'time',
              distribution: 'series',
              time: {
                displayFormats: {
                  millisecond: 'DD/MM/YYYY',
                  second: 'DD/MM/YYYY',
                  minute: 'DD/MM/YYYY',
                  hour: 'DD/MM/YYYY',
                  day: 'DD/MM/YYYY',
                  week: 'DD/MM/YYYY',
                  month: 'DD/MM/YYYY',
                  quarter: 'DD/MM/YYYY',
                  year: 'DD/MM/YYYY',
                }
              },
              position: 'bottom',
              ticks: {
                source: 'data',
              },
              scaleLabel: {
                display: true,
                labelString: "Date de mouvement"
              }
            }],
            yAxes: [{
              type: 'linear',
              scaleLabel: {
                display: true,
                labelString: "Montants"
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom",
          },
          elements: {
            line: {
              tension: 0,
            },
          },
          tooltips: {
            callbacks: {
              title: function (tooltipItem) {
                let new_title = tooltipItem[0].xLabel || '';
                if (new_title) {
                  new_title = Utils.formatDateIsoToFr(new_title);
                }
                return new_title;
              },
              label: this.computeLabel,
            },
          },
          plugins: {
            datalabels: {
              display: false
            }
          },
        });
    },
    /**
    * Génération des détails à afficher dans le tooltip au survol des points du graph
    **/
    computeLabel(tooltipItem, data) {
      throw new Error("Not implemented.");
    },
    downloadExcelData(title, date) {

      let headers = [
        {
          text: "Code catégorie",
          value: "code_categorie"
        },
        {
          text: "Libelle",
          value: "libelle_categorie"
        },
        {
          text: "Date du mouvement",
          value: "date_mouvement"
        },
        {
          text: "Montant du mouvement",
          value: "montant_mouvement"
        }
      ];

      function flattenData(categ) {
        let returnValue = [];
        for (let i = 0; i < categ.liste_points.length; i++) {
          const mvt = categ.liste_points[i];
          returnValue.push({
            code_categorie: categ.code,
            libelle_categorie: categ.libelle,
            date_mouvement: Utils.formatDateStringIsoToFr(mvt.x),
            montant_mouvement: mvt.y,
          });
        }
        return returnValue;
      }

      let excelData = _.flatMap(this.data, flattenData);
      let ref = {
        _props: {
          headers: headers,
          items: excelData
        }
      };
      let workbookProps = {
        Title: title,
        Subject: title,
        Author: "eZIScop",
        CreatedDate: Date.now()
      };
      let sheetTitle = title.substring(0, 30);
      let fileTitle = title + " " + date + ".xlsx";
      Utils.writeXlsxFromDatatableRef(
        ref,
        workbookProps,
        sheetTitle,
        fileTitle
      );
    },
  },
};