import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default aboExerciceApi;

function aboExerciceApi() {
    const specificApi = {
        getIsCampagneActive: getIsCampagneActive(),
        importDonneesAssociesExcel: importDonneesAssociesExcel(),
        exportDonneesAssociesModeleExcel: exportDonneesAssociesModeleExcel(),
    };
    return Object.assign(specificApi, defaultApi(
        internalApiRoutes.aboExercices.baseUrl,
        [
            ACCEPTED_METHODS.getById,
            ACCEPTED_METHODS.putById,
        ],
    ));
}

function getIsCampagneActive() {
    function getIsCampagneActive(aboExerciceId, queryParams, headers) {
        let path = internalApiRoutes.aboExercices.getIsCampagneActive(aboExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }
    return getIsCampagneActive;
}

function importDonneesAssociesExcel() {
    function importDonneesAssociesExcel(exerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.importDonneesAssociesExcel(exerciceId);
      let postForPath = apiConstructor.post(path);
      return postForPath(body, queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
    }
  
    return importDonneesAssociesExcel;
  }

function exportDonneesAssociesModeleExcel() {
    function exportDonneesAssociesModeleExcel(exerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.exportDonneesAssociesModeleExcel(exerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(body, queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
    }
  
    return exportDonneesAssociesModeleExcel
  }
  