var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formDocuments",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var validate = ref.validate;
var errors = ref.errors;
return [_c('base-layout',{attrs:{"withHeader":false,"withFooter":true},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('loading-screen',{ref:"loadingComponentScic"}),_c('h3',{staticClass:"ml-2"},[_vm._v("Tous les documents liés au statut Scic")]),_c('v-row',{staticClass:"ml-2"},[_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"vid":"selectedExercice","name":"Exercice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-exercices-list',{ref:"exercices",attrs:{"value":_vm.selectedExerciceId,"errors":errors},on:{"update-selected-exercice":function($event){return _vm.onExerciceChange($event)}}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"mr-4",attrs:{"md":"3","lg":"3","xl":"2"}},[_c('v-import-export-file',{attrs:{"detail":"Importer la signature","isIcon":false,"typesFile":_vm.getTypesExtensionsFiles().img,"allowedExport":false},on:{"import-file":function(formData){_vm.onSignatureChange(formData)}}})],1),_c('v-col',{staticClass:"mr-4",attrs:{"md":"3","lg":"3","xl":"2"}},[(_vm.signature.binary !== null)?_c('img',{staticStyle:{"max-width":"100%","max-height":"300px"},attrs:{"src":_vm.signature.binary}}):_vm._e()])],1),_c('h3',{staticClass:"ml-2"},[_vm._v("Attestation fiscale")]),_c('v-row',{staticClass:"ml-2"},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"free_text_attestation_fiscale","name":"texte libre sur l'attestation fiscale","rules":"max:20000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"texte_juridique_exercice","filled":"","label":"Texte libre sur les éléments juridique de l'attestation fiscale","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.texte_juridique_exercice.string),callback:function ($$v) {_vm.$set(_vm.texte_juridique_exercice, "string", $$v)},expression:"texte_juridique_exercice.string"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"ml-2"},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"free_text_attestation_fiscale","name":"texte libre sur l'attestation fiscale","rules":"max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"free_text_attestation_fiscale","filled":"","label":"Texte libre sur l'attestation fiscale","auto-grow":"","error-messages":errors[0]},model:{value:(_vm.free_text_attestation_fiscale_obj.string),callback:function ($$v) {_vm.$set(_vm.free_text_attestation_fiscale_obj, "string", $$v)},expression:"free_text_attestation_fiscale_obj.string"}})]}}],null,true)})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"resetForm":reset,"validateForm":validate,"errorsList":errors},on:{"emit-cancel":function($event){return _vm.cancelDocuments()},"emit-valid-save":function($event){return _vm.saveDocuments()}}})],1)],1)]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }