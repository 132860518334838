import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default inteSalairePresencesApi;

function inteSalairePresencesApi() {
  return {
    getSalairePresencesInPeriode: getSalairePresencesInPeriode(),
    getTotauxSalairePresencesForExercice: getTotauxSalairePresencesForExercice(),
    saveSalairePresences: saveSalairePresences(),
    exportSalairePresenceExcel: exportSalairePresenceExcel(),
    importSalairePresenceExcel: importSalairePresenceExcel(),
  };
}

function getSalairePresencesInPeriode() {
  function salairePresencesInPeriode(exerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.inteSalairePresences.salairePresencesForExercice(exerciceId);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return salairePresencesInPeriode;
}

function saveSalairePresences() {
  function saveSalairePresences(inteExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.inteSalairePresences.salairePresencesForExercice(inteExerciceId);
    let postForPath = apiConstructor.put(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return saveSalairePresences;
}

function getTotauxSalairePresencesForExercice() {
  function getTotauxSalairePresencesForExercice(inteExerciceId, queryParams, headers) {
    let path = internalApiRoutes.inteSalairePresences.totauxSalairePresencesForExercice(inteExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getTotauxSalairePresencesForExercice;
}

function exportSalairePresenceExcel() {
  function exportSalairePresenceExcel(inteExerciceId, queryParams, headers) {
    let path = internalApiRoutes.inteSalairePresences.exportSalairePresencesForExercice(inteExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportSalairePresenceExcel;
}

function importSalairePresenceExcel() {
  function importSalairePresenceExcel(inteExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.inteSalairePresences.importSalairePresencesForExercice(inteExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return importSalairePresenceExcel;
}
