import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";
import {apiConstructor} from "../../index";

export default chiffrementApi;

function chiffrementApi() {
  const specificApi = {
    getKeysPresenceAtDate: getKeysPresenceAtDate(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.chiffrement.baseUrl));
}

function getKeysPresenceAtDate() {
  function getKeysPresenceAtDate(dateReference, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(dateReference);
    let path = internalApiRoutes.chiffrement.getKeysPresenceAtDate(dateString);
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getKeysPresenceAtDate;
}
