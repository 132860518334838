<!-- VUETIFY2 - OK -->
<template>
    <div>
        <!-- Fenêtre modale -->
        <v-dialog v-model="dialog" persistent max-width="600px" :disabled="salarie_id === null">
            <template v-slot:activator="{ on }">
                <v-icon-button-with-tool-tip v-if="edition"
                                             detail="Editer l'élément"
                                             :icon="'$vuetify.icons.edit'"
                                             :activator="on"
                                             btnSmall iconSmall btnIsIcon btnRound
                                             btnText
                                             tooltipBottom
                                             btnColorClass="transparent"
                                             iconColorClass="grey darken-1"/>
                <v-btn v-else color="primary" dark block :disabled="salarie_id === null" v-on="on">{{openButtonMessage}}
                </v-btn>
            </template>
            <v-card>
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider vid="date_debut"
                                                    name="Date de début"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                    <date-field v-model="item.date_debut"
                                                label="Date de début"
                                                :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <ValidationProvider vid="date_fin"
                                                    name="Date de fin"
                                                    :rules="{date_after: item.date_debut }"
                                                    v-slot="{ errors }">
                                    <date-field v-model="item.date_fin"
                                                label="Date de fin"
                                                :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-select v-if="item.date_fin"
                                          v-model="item.motif_fin_contrat"
                                          label="Motif de fin de contrat"
                                          :items="labels_motif_fin_contrat"
                                          clearable/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-row justify="end" no-gutters>
                            <!-- Bouton modal -->
                            <v-col md="4" lg="4" xl="4" class="mr-2">
                                <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                            </v-col>
                            <!-- Bouton modal -->
                            <v-col md="4" lg="4" xl="4">
                                <v-btn color="primary" @click="handleSubmit(submitNewItem)" block>
                                    {{labelConfirmation}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";
    import VIconButtonWithToolTip from "./VIconButtonWithToolTip";

    /**
     * Composant affichant une fenêtre modale pour créer un élément période de travail
     * @display_name Commun - VNewSalarieHistoryItem
     */
    export default {
        name: "VNewSalarieHistoryItem",
        components: {
            VIconButtonWithToolTip,
            DateField
        },
        props: {
            /**
             * L'id du salarié pour lequel créer une période de travail
             */
            salarie_id: {
                type: Number,
                default: null,
            },
            /**
             * Est en édition plutôt qu'en création
             */
            edition: {
                type: Boolean,
                default: false,
            },
            /**
             * L'objet à modifier
             */
            itemToEdit: {
                type: Object,
            },
        },
        data() {
            return {
                item: {
                    motif_fin_contrat: undefined,
                    date_debut: undefined,
                    date_fin: undefined,
                },
                dialog: false,
                labelAnnulation: "NON, ABANDONNER",
                labelConfirmation: "OUI, CONFIRMER",
                labels_motif_fin_contrat: undefined,
            }
        },
        mounted() {
            if (this.edition) {
                this.item = this.itemToEdit;
            }
            this.getLabelsMotif();
        },
        computed: {
            title() {
                return this.edition ? "Modification de la période de travail" : "Déclarer une période de travail";
            },
            openButtonMessage() {
                return "Déclarer une période de travail";
            },
        },
        methods: {
            /**
             * Récupère la liste des motifs de fin de contrat
             * @returns {[string]}
             */
            getLabelsMotif() {
                return internalApi.periodeTravail.getLabels().then(result => {
                    this.labels_motif_fin_contrat = Utils.fromLabelToSelect(
                        result.motif_fin_contrat
                    );
                });
            },
            /**
             * Créer ou édite l'item. Emet l'évènement submitNewItem si succès
             */
            submitNewItem() {
                if (!this.item.date_fin) {
                    this.item.motif_fin_contrat = null;
                    this.item.date_fin = null;
                }
                if (this.edition) {
                    this.editItem()
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.$emit("refresh-items-list");
                            this.closePopUp();
                        });
                } else {
                    this.item.salarie_id = this.salarie_id;
                    this.addItem()
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.$emit("refresh-items-list");
                            this.closePopUp();
                        });
                }

            },
            /**
             * Ferme la boîte de dialogue
             */
            closePopUp() {
                if (!this.edition) {
                    this.item = {
                        motif_fin_contrat: undefined,
                        date_debut: undefined,
                        date_fin: undefined,
                    };
                    this.$refs.observer.reset();
                }
                this.dialog = false;
            },
            /**
             * Renvoie une promesse permettant de créer l'item
             * @returns {promise}
             */
            addItem() {
                if (this.item.motif_fin_contrat === undefined) {
                    this.item.motif_fin_contrat = null;
                }
                return internalApi.periodeTravail.postAllWithToastConfirmation(this.item);
            },
            /**
             * Renvoie une promesse permettant d'éditer l'item
             * @param item l'item à éditer
             * @returns {promise}
             */
            editItem(item) {
                return internalApi.periodeTravail.putByIdWithToastConfirmation(this.item.id, this.item);
            },
        }
    }
</script>