// <!-- VUETIFY2 - OK -->
import _ from "lodash";
import internalApi from "@/api/internalApi";
import Annulation from "./Annulation";

export default {
  mounted() {
    this.isEditable = true;
    this.canEditDate = true;
  },
  extends: Annulation,
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      const annulationToSend = _.cloneDeep(this.annulation);
      if (_.isNil(annulationToSend.commentaire)) {
        annulationToSend.commentaire = null;
      }
      delete annulationToSend.type_annu;
      internalApi.annulation
        .postAllWithToastConfirmation([annulationToSend])
        .then(result => {
          this.pushToMouvements();
        });
    }
  }
};
