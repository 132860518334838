import Associes from "@/views/capital/Associe/Index";
import rolesList from "@/router/rolesList";
import BulletinsBrouillons from "@/views/capital/Associe/BulletinsBrouillons";

export default {
  path: "associes/",
  component: Associes,
  children: [
    {
      path: "bulletins-brouillons/:associeId/:typeBulletin",
      name: "bulletins-brouillons",
      component: BulletinsBrouillons,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
  ]
};
