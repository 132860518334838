import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default gestionDroitsAccesApi;

function gestionDroitsAccesApi() {
  const specificApi = {};

  return Object.assign(specificApi, defaultApi(internalApiRoutes.gestionDroitsAcces.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.putAll,
  ]));
}
