import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default deblocageApi;

function deblocageApi() {
  let specificApi = {
    getDeblocagesIndividuel: getDeblocagesIndividuel(),
    downloadBulletinDeblocage: downloadBulletinDeblocage(),
    downloadBulletinDeblocageIndividuel: downloadBulletinDeblocageIndividuel(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.deblocages.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById
  ]));
}

function getDeblocagesIndividuel() {
  function getDeblocages(queryParams, headers) {
    let path = internalApiRoutes.deblocages.getDeblocagesIndividuel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getDeblocages;
}

function downloadBulletinDeblocage() {
  function getBulletin(deblocageId, queryParams, headers) {
    let path = internalApiRoutes.deblocages.downloadBulletinDeblocage(deblocageId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getBulletin;
}

function downloadBulletinDeblocageIndividuel() {
  function getBulletinIndividuel(deblocageId, queryParams, headers) {
    let path = internalApiRoutes.deblocages.downloadBulletinDeblocageIndividuel(deblocageId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getBulletinIndividuel;
}
