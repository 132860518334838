var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"datatableExcludedSize":"110px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"pl-3"},[_vm._v("Intérêts de rémunération")])]},proxy:true},{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-container',{staticClass:"px-0"},[_c('v-data-table',{ref:"item_list",staticClass:"elevation-1",attrs:{"search":_vm.search,"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.tmops,"fixed-header":"","height":datatableSize,"no-data-text":"Aucune donnée","multi-sort":"","options":_vm.pagination,"loading":_vm.is_datatable_loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("onlyYearFilter")(item.annee)))]),_c('td',[_vm._v(_vm._s(item.semestre))]),_c('td',{staticClass:"text-lg-right"},[_vm._v(_vm._s(item.tmop_applicable)+" %")]),_c('td',[_c('v-row',[_c('v-tmop-edition',{attrs:{"oldTmop":item},on:{"edit-tmop":function (oldTmop, newTmop) { return _vm.editTmop(oldTmop, newTmop); }}}),_c('v-custom-confirmation-dialog',{attrs:{"message":"Êtes-vous sûr(e) de vouloir supprimer ce TMOP ?","isIcon":true,"iconValue":'$vuetify.icons.delete',"tooltipDetailMessage":"Supprimer le TMOP"},on:{"action-confirmee":function($event){return _vm.deleteTmop(item)}}})],1)],1)])]}}],null,true)})],1)]}}])},[_c('template',{slot:"footer"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md4":"","lg4":"","xl3":""}},[_c('v-tmop-creation',{on:{"add-tmop":function($event){return _vm.addTmop($event)}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md6":"","lg6":"","xl4":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var reset = ref.reset;
return [_c('v-save-buton-with-validation',{attrs:{"validateForm":validate,"resetForm":reset,"errorsList":errors},on:{"emit-cancel":function($event){return _vm.cancel()},"emit-valid-save":function($event){return _vm.submit()}}})]}}])})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }