<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout>
            <template slot="header">
                <h1>Exercice</h1>
            </template>
            <template slot="main">
                <v-row no-gutters>
                    <loading-screen ref="loadingComponent"/>
                    <v-col md="12" lg="12" xl="10">
                        <common-exercice :date_debut="exercice.date_debut"
                                         :date_fin="exercice.date_fin"
                                         :date_ag_annuelle="exercice.date_ag_annuelle"
                                         :libelle="exercice.libelle"
                                         :benefices="exercice.benefices"
                                         :montant_participation_brute="exercice.participation_brute"
                                         :exerciceValide="exercice.is_common_exercice_any_state_valide"
                                         @update-date-debut="exercice.date_debut = $event"
                                         @update-date-fin="exercice.date_fin = $event"
                                         @update-date-ag-annuelle="exercice.date_ag_annuelle = $event"
                                         @update-libelle="exercice.libelle = $event"
                                         @update-montant-participation-brute="exercice.participation_brute = $event"
                                         @update-benefices="exercice.benefices = $event">
                        </common-exercice>
                    </v-col>
                    <v-spacer/>
                </v-row>
            </template>
            <template slot="footer">
                <v-row justify="end" no-gutters>
                    <!-- Petits boutons -->
                    <v-col v-if="!exerciceValide" md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      :showToast="false"
                                                      :disabledValue="exercice.is_common_exercice_any_state_valide"
                                                      @emit-cancel="returnToExerciceList"
                                                      @emit-valid-save="save"/>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col v-else md="3" lg="3" xl="2">
                        <v-btn @click="returnToExerciceList" color="primary" block>Retour</v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import CommonExercice from "@/components/exercice/CommonExercice";

    /**
     * Composant Affichant les données communes de l'exercice
     * @displayName Commun - Exercice
     */
    export default {
        components: {
            CommonExercice,
            VSaveButonWithValidation,
            loadingScreen,
            BaseLayout
        },
        props: {
            /**
             *  Détermine si on est en train de créer un nouvel exercice ou de modifier
             *  un existant
             */
            creationMode: undefined
        },
        data() {
            return {
                exercice: {
                    is_common_exercice_any_state_valide: true,
                },
                exerciceValide: false,
                company: undefined,
            };
        },
        mounted() {

            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            let promises = [];

            let promise_company = this.getCompany();
            promises.push(promise_company);

            if (!this.creationMode) {
                const {exerciceId} = this.$route.params;
                let promise_exercice = this.getExerciceById(exerciceId).then(result => {
                    this.exerciceValide = this.exercice.is_valide;
                });
                promises.push(promise_exercice);
            } else {
                this.exercice = {
                    date_debut: undefined,
                    date_fin: undefined,
                    date_ag_annuelle: undefined,
                    libelle: undefined,
                    benefices: undefined,
                    participation_brute: undefined,
                }
            }

            Promise.all(promises).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });

        },
        methods: {
            getExerciceById(exercice_id) {
                return internalApi.exercice.getById(exercice_id).then(result => {
                    this.exercice = result;
                });
            },
            getCompany() {
                return internalApi.company.getVersionCompany()
                    .then(result => {
                        this.company = result;
                    });
            },
            isFilled(field) {
                return !_.isNil(field) && field !== ""
            },
            computeLabel(dependantField, text, nullText) {
                if (this.isFilled(dependantField)) {
                    return text;
                } else {
                    return nullText;
                }
            },
            /**
             * Selon si on est en création ou en édition, enregistre l'exercice ou
             * ses modifications, puis émèt un évènement create ou update selon
             * le cas
             */
            save() {
                this.exercice.company_id = this.company.id;
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                if (this.creationMode) {
                    internalApi.exercice
                        .postAllWithToastConfirmation(this.exercice)
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            console.error(err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                } else {
                    let data_to_send = _.cloneDeep(this.exercice);
                    // On ne veut pas pouvoir mettre ces données à jour depuis cet écran
                    delete data_to_send.id;
                    delete data_to_send.cap_exercice;
                    delete data_to_send.pb_exercice;
                    delete data_to_send.inte_exercice;
                    delete data_to_send.abo_exercice;
                    internalApi.exercice
                        .putByIdWithToastConfirmation(
                            this.exercice.id,
                            data_to_send,
                        )
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            console.error(err);
                            this.$refs.loadingComponent.resetLoading();
                        });
                }
            },
            returnToExerciceList() {
                this.$router.push({
                    name: "exercices"
                });
            },
        }
    };
</script>
