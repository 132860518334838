<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px" :disabled="disabled">
        <template v-slot:activator="{ on }">
            <v-btn v-if="!isModif" v-on="on" color="primary" dark block>{{openButtonMessage}}</v-btn>
            <v-icon-button-with-tool-tip v-else
                                         @click.stop="onButtonClick"
                                         :detail="tooltipDetailMessage"
                                         :icon="iconValue"
                                         btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         block
            />
        </template>
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
            <v-card-text>
                <ValidationObserver v-slot="{ handleSubmit }" ref="formRegleRepartitionPbAnciennete" v-if="dialog">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <v-container grid-list-md>
                            <v-row no-gutters>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="groupe" name="groupe"
                                                        rules="required" v-slot="{ errors }">
                                        <v-text-field v-model="regle.groupe"
                                                      label="Groupe"
                                                      :error-messages="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="anciennete" name="anciennete"
                                                        rules="required|numeric|min_value:0" v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="regle.anciennete"
                                                                       labelValue="Ancienneté (en mois)"
                                                                       :disabledValue="isDefault"
                                                                       :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="coefficient" name="coefficient"
                                                        rules="required|currency|min_value:0" v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="regle.coefficient"
                                                                       labelValue="Coefficient"
                                                                       :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </ValidationObserver>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="end">
                    <!-- Bouton modal -->
                    <v-col class="mr-2" md="4" lg="4" xl="4">
                        <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                    </v-col>
                    <!-- Bouton modal -->
                    <v-col md="4" lg="4" xl="4">
                        <v-btn color="primary" @click="submit" block>{{labelConfirmation}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";

    export default {
        name: "VNewRegleRepartitionPbAnciennete",
        components: {
            VIconButtonWithToolTip,
            VFormattedNumberTextField
        },
        props: {
            pbCompanyId: {
                type: Number,
                required: true,
                default: undefined
            },
            regleLabels: {
                type: Object,
                required: true,
                default: undefined
            },
            isDefault: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        computed: {
            title() {
                return undefined;
            },
            isModif() {
                return undefined;
            },
            openButtonMessage() {
                return undefined;
            },
            iconValue() {
                return undefined;
            },
            tooltipDetailMessage() {
                return undefined;
            }
        },
        data() {
            return {
                regle: {
                    id: undefined,
                    groupe: undefined,
                    anciennete: undefined,
                    coefficient: undefined
                },
                dialog: false,
                labelAnnulation: "NON, ABANDONNER",
                labelConfirmation: "OUI, CONFIRMER",
            }
        },
        methods: {
            submit() {
            },
            onButtonClick: function () {
                this.dialog = true;
            },
            closePopUp() {
                this.dialog = false;
                this.regle.groupe = undefined;
                this.regle.anciennete = undefined;
                this.regle.coefficient = undefined;
                this.$refs.formRegleRepartitionPbAnciennete.reset();
            }
        }
    }
</script>