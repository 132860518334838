// <!-- VUETIFY2 - OK -->
import Annulation from "./Annulation";
import internalApi from "@/api/internalApi";

export default {
  mounted() {
    this.isEditable = false;
    this.getAnnulationAssocie();
    this.specificBackRoute = this.aSpecificBackRoute;
  },
  extends: Annulation,
  computed: {
    annulationId() {
      return this.$route.params.id;
    },
  },
  props: {
    aSpecificBackRoute: {
      type: String,
      required: false
    }
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
    },

    /**
     * Méthode appelée par les composants enfants édition et consultation
     */
    getAnnulationAssocie() {
      internalApi.annulation.associe.getById(this.annulationId).then(res => {
        this.annulation = res;
      });
    },
  }
};