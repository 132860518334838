import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default pbExerciceApi;

function pbExerciceApi() {
  const specificApi = {
    getExercicesDisponiblesTauxRemu: getExercicesDisponiblesTauxRemu(),
    computeMontantPbPlancherLegal: computeMontantPbPlancherLegal(),
    computeMontantPbPlancherScop: computeMontantPbPlancherScop(),
    computeMontantPbPlafondScopBeneficeOnly: computeMontantPbPlafondScopBeneficeOnly(),
    computeMontantPbPlafondScopBeneficeImpactCapital: computeMontantPbPlafondScopBeneficeImpactCapital(),
    getPassPlafondsIndivEtCommun: getPassPlafondsIndivEtCommun(),
    getPassPlafondsIndivEtCommunNoExercice: getPassPlafondsIndivEtCommunNoExercice(),
    getMontantRenumerationSalairePlafond: getMontantRenumerationSalairePlafond(),
    getMontantRenumerationSalairePlafondFromExercice: getMontantRenumerationSalairePlafondFromExercice(),
    computeVersementImmediatNet: computeVersementImmediatNet(),
    getAllParticipationInteretsTotaux: getAllParticipationInteretsTotaux(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.pbExercices.baseUrl));
}

function computeMontantPbPlancherLegal() {
  function computeMontantPbPlancherLegal(body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.computeMontantPbPlancherLegal(body);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computeMontantPbPlancherLegal;
}

function computeMontantPbPlancherScop() {
  function computeMontantPbPlancherScop(body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.computeMontantPbPlancherScop(body);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computeMontantPbPlancherScop;
}

function computeMontantPbPlafondScopBeneficeOnly() {
  function computeMontantPbPlafondScopBeneficeOnly(body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.computeMontantPbPlafondScopBeneficeOnly(body);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computeMontantPbPlafondScopBeneficeOnly;
}

function computeMontantPbPlafondScopBeneficeImpactCapital() {
  function computeMontantPbPlafondScopBeneficeImpactCapital(body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.computeMontantPbPlafondScopBeneficeImpactCapital(body);
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computeMontantPbPlafondScopBeneficeImpactCapital;
}

function getPassPlafondsIndivEtCommun() {
  function getPassPlafondsIndivEtCommun(pb_exercice_id, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getPassPlafondsIndivEtCommun(pb_exercice_id, queryParams);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getPassPlafondsIndivEtCommun;
}

function getPassPlafondsIndivEtCommunNoExercice() {
  function getPassPlafondsIndivEtCommunNoExercice(queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getPassPlafondsIndivEtCommunNoExercice(queryParams);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getPassPlafondsIndivEtCommunNoExercice;
}

function getMontantRenumerationSalairePlafond() {
  function getMontantRenumerationSalairePlafond(queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getMontantRenumerationSalairePlafond(queryParams);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getMontantRenumerationSalairePlafond;
}

function getMontantRenumerationSalairePlafondFromExercice() {
  function getMontantRenumerationSalairePlafondFromExercice(exercice_id, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getMontantRenumerationSalairePlafondFromExercice(exercice_id, queryParams);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getMontantRenumerationSalairePlafondFromExercice;
}

function computeVersementImmediatNet() {
  function computeVersementImmediatNet(pb_exercice_id, body, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.computeVersementImmediatNet(pb_exercice_id, body);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computeVersementImmediatNet;
}

function getExercicesDisponiblesTauxRemu(){
  function getExercicesDisponiblesTauxRemu(support_id, queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getExercicesDisponiblesTauxRemu();
    queryParams = queryParams || {};
    queryParams.support_id = support_id;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getExercicesDisponiblesTauxRemu;
}

function getAllParticipationInteretsTotaux() {
  function getAllParticipationInteretsTotaux(queryParams, headers) {
    let path = internalApiRoutes.pbExercices.getAllParticipationInteretsTotaux();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAllParticipationInteretsTotaux;
}
