<template>
    <v-text-field
        v-model="localMaskedValue"
        v-bind="$attrs"
    />
</template>
<script>
    /**
     * Textfield + masque. Prend en paramètre le masque de la forme ## #### ###
     * hypothèse : la chaine cible ne contient aucun espace, le masque se contente de mettre un espace à l'affichage aux bons endroits.
     * @displayName Technique - Masque sur un textfields
     */
    import _ from "lodash";
    export default {
        props: {
            /**
             * Le masque à appliquer
             */
            mask: {
                type: String,
                required: true
            },

            /**
             * La valeur cible
             */
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            localMaskedValue: {
                get: function () {
                    return this.format(this.value, this.mask);
                },
                set: function (value) {
                    // on sauvegarde la valeur sans espaces
                    const newValue = value.replace(/\s/g, "");
                    this.$emit("input", newValue);
                }
            }
        },
        methods: {
            format(value, pattern) {
                let i = 0, c = 0, j = 0;
                let newPattern = "";
                // on tronque le pattern en fonction de ce qui est tapé (sans prendre en compte les espaces)
                while (!_.isNil(value) && (j < pattern.length && c < value.length)) {
                    const charValue = pattern.charAt(j);
                    newPattern += charValue;
                    j++;
                    if (charValue === "#") {
                        c++;
                    }
                }
                return newPattern.replace(/#/g, _ => value[i++]);
            }
        }
    };
</script>