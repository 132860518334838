<template>
    <v-container>
        <loading-screen ref="loadingComponent"/>
        <v-row justify="end" no-gutters class="mb-7">
            <v-col md="2" lg="2" xl="1">
                <v-import-export-file detail="Importer l'ancienneté"
                    :isIcon="true"
                    :typesFile="getTypesExtensionsFiles().excel"
                    @import-file="function(formData){ancienneteImport(formData)}"
                    exportDetail="Télécharger le modèle d'import de l'ancienneté"
                    @export-file="exportAnciennete()"
                    :allowedExport="true"/>
            </v-col>
        </v-row>
        <v-data-table :headers="headers"
                      :items="aboEligibilitesList"
                      :no-data-text="noDataTextMessage"
                      fixed-header
                      :footer-props="footerOpts"
                      class="elevation-1"
                      :options.sync="pagination"
                      :server-items-length="total_items"
                      :height="datatableSize"
                      :loading="loading">
            <template v-slot:item="{ item }">
                <tr>
                    <td class="text-lg">
                        {{ item.name}}
                    </td>
                    <td class="text-xs-left">
                        {{ item.numero_tiers }}
                    </td>
                    <td class="text-lg" v-if="hasContrainteAnciennete">
                        <ValidationProvider :vid="item.id + '.dateEntreeSociete'"
                            :name="'Date d\'entrée dans la société de ' + item.name"
                            :rules="{ required: hasContrainteAnciennete }"
                            v-slot="{ errors }">
                            <DateField
                                v-model="item.date_entree_societe"
                                label="Date d'entrée dans la société"
                                :errors="errors"
                                :disabled="disabled"
                                @input="dataChanged=true"
                            />
                        </ValidationProvider>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="confirmationDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col>
                                Vous avez saisi des données qui n'ont pas été enregistrées, êtes-vous sûr(e) de vouloir
                                continuer ?
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
    import _ from "lodash";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import DateField from "@/components/DateField";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VImportExportFile from "@/components/VImportExportFile";
    import Utils from "@/utils";


    /**
     * Composant affichant la liste des associés actifs pour lesquels déclarer l'éligibilité.
     * @displayName Abondement - Datatable eligibilites
     */
    export default {
        name: "v-datatable-abondement-eligibilites",
        components: {
            DateField,
            loadingScreen,
            VImportExportFile,
        },
        props: {
            exercice: {
                type: Object,
                required: true
            },
            datatableSize: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }, 
        },
        data() {
            return {
                headers: [],
                footerOpts: {itemsPerPageOptions: [10, 20, 50]},
                pagination: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ['name'],
                    sortDesc: [false],
                },
                nextPagination: {},
                aboEligibilitesList: [],
                total_items: -1,
                noDataTextMessage: "Aucun associé actif à la date de référence",
                loading: false,
                confirmationDialog: undefined,
            }
        },
        watch: {
            pagination: {
                /**
                 *  Vérifie si les données ont été modifiées par l'utilisateur. Si c'est le cas, on revient à la
                 *  pagination initiale et on enregistre la nouvelle pagination. Sinon on récupère la ventilation
                 */
                handler(newV, oldV) {
                    if (!this.confirmationDialog) {
                        if (this.dataChanged) {
                            this.confirmationDialog = true;

                            // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                            // le changement
                            this.nextPagination.page = newV.page;
                            this.nextPagination.itemsPerPage = newV.itemsPerPage;
                            this.nextPagination.sortBy = newV.sortBy;
                            this.nextPagination.sortDesc = newV.sortDesc;

                            // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                            // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                            this.pagination.page = oldV.page;
                            this.pagination.itemsPerPage = oldV.itemsPerPage;
                            this.pagination.sortBy = newV.sortBy;
                            this.pagination.sortDesc = newV.sortDesc;
                        } else {
                            this.updateAboEligiblitesList();
                        }
                    }
                },
                deep: true
            },
        },
        computed: {
            hasContrainteAnciennete() {
                return !_.isNil(this.exercice.abo_exercice.contrainte_anciennete);
            },
        },
        methods: {
            /**
             * Ferme la boîte de dialogue de confirmation de changement de page
             */
            closePopUp() {
                this.confirmationDialog = false;
            },
            /**
             * Ferme la pop up et récupère les éligibilités paginées à partir de la nouvelle pagination.
             * Met également le dataChanged à false
             */
            confirmChangePagination() {
                this.pagination.page = this.nextPagination.page;
                this.pagination.itemsPerPage = this.nextPagination.itemsPerPage;
                this.pagination.sortBy = this.nextPagination.sortBy;
                this.pagination.sortDesc = this.nextPagination.sortDesc;

                this.closePopUp();
                this.updateAboEligiblitesList();
                this.dataChanged = false;
            },
            /**
             * Met à jour la datatable des éligibilités
             */
            updateAboEligiblitesList() {
                this.loading = true;

                return internalApi.aboEligibilites.getById(
                    this.exercice.id,
                    {
                        page: this.pagination.page,
                        rows_per_page: this.pagination.itemsPerPage,
                        sort_key: this.pagination.sortBy[0],
                        descending: this.pagination.sortDesc[0],
                    },
                ).then(result => {
                    this.total_items = result.total_items;
                    this.aboEligibilitesList = result.abo_eligibilites;
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.computeHeaders();
                    this.loading = false;
                });
            },
            /**
             * Met à jour la datatable des éligibilités
             */
            saveAboEligibilites() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                return internalApi.aboEligibilites.putByIdWithToastConfirmation(this.exercice.id, this.aboEligibilitesList).finally(() => {
                    this.dataChanged = false;
                    this.$refs.loadingComponent.resetLoading();
                    this.updateAboEligiblitesList();
                });
            },
             /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            /**
             * Réalise l'import du fichier de l'ancienneté d'abondement
             */
            ancienneteImport(formData) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return internalApi.aboExercice.importDonneesAssociesExcel(this.exercice.id, formData)
                    .then(() => {
                        this.dialog = false;
                        this.updateAboEligiblitesList();
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Déclenché quand on souhaite exporter de l'ancienneté d'abondement
             */
            exportAnciennete() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                return internalApi.aboExercice.exportDonneesAssociesModeleExcel(this.exercice.id)
                    .then(response => {
                        Utils.downloadFile(response, "modele_donnees_associes_abondement.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            fetchContrainteAnciennete() {
                return internalApi.aboExercice.getById(this.exerciceId).then(
                    result => {
                        this.hasContrainteAnciennete = !_.isNil(result.abo_exercice.contrainte_anciennete);
                        this.computeHeaders();
                    }
                );
            },
            computeHeaders() {
                var building_headers = [
                    {
                        text: "Tiers",
                        value: "name",
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                    },
                ];
                if (this.hasContrainteAnciennete){
                    building_headers.push({
                        text: "Date d'entrée dans la société",
                        value: "date_entree_societe",
                        width: "40%",

                    });
                }
                this.headers = building_headers;
            },
        },
    }
</script>