import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import { apiConstructor } from "../../index";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default inteCompanyApi;

function inteCompanyApi() {
  const specificApi = {
    getCurrentInteCompany: getCurrentInteCompany(),
    getAccordsInteCompanyEnumByName: getAccordsInteCompanyEnumByName(),
    createNewInteAccord: createNewInteAccord(),
    deleteDocumentInteAccord: deleteDocumentInteAccord(),
    createInteAccordDocument: createInteAccordDocument(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.inteCompanies.baseUrl));
}

function getCurrentInteCompany() {
  function getCurrentStatusInteCompany(queryParams, headers) {
    let path = internalApiRoutes.inteCompanies.accordsInteCompany();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getCurrentStatusInteCompany;
}

function getAccordsInteCompanyEnumByName() {
  function getAccordsInteCompanyEnumByName(queryParams, headers) {
    let path = internalApiRoutes.inteCompanies.accordsInteCompanyEnumByName();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAccordsInteCompanyEnumByName;
}

function createNewInteAccord() {
  function createNewInteAccord(body, queryParams, headers) {
    let path = internalApiRoutes.inteCompanies.accordsInteCompany();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createNewInteAccord;
}

function deleteDocumentInteAccord() {
  function deleteDocumentInteAccord(id, body, queryParams, headers) {
    let path = internalApiRoutes.inteCompanies.deleteDocumentInteAccord(id);
    let deleteForPath = apiConstructor.delete(path);
    return deleteForPath(body, queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
    });
  }
  return deleteDocumentInteAccord;
}

function createInteAccordDocument() {
  function createInteAccordDocument(body, queryParams, headers) {
    let path = internalApiRoutes.inteCompanies.createInteAccordDocument();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createInteAccordDocument;
}