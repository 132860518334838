import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default annulationApi;

function annulationApi() {
  const specificApi = {
    postMontantARembourser: apiConstructor.post(
      internalApiRoutes.annulationsMontantARembourser
    ),
    postBulletinAnnulationPartielle: postBulletinAnnulationPartielle(),
    associe: defaultApi(internalApiRoutes.annulationAssocie, [ACCEPTED_METHODS.getById])
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.annulations));
}



function postBulletinAnnulationPartielle() {
  function bulletin(body, queryParams, headers) {
    let path = internalApiRoutes.brouillonBulletinAnnulationPartielle;
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return bulletin;
}