import {Bar} from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartsUtils from "@/graphiques/graphique_utils.js";

export default {
  extends: Bar,
  watch: {
    data: function () {
      this.renderChartComponent();
    },
    limit: function () {
      if (!_.isNil(this.limit)) {
        this.renderChartComponent();
      }
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    Chart.Tooltip.positioners.cursor = function (chartElements, coordinates) {
      return coordinates;
    };
    if (!_.isNil(this.data)) {
      this.renderChartComponent();
    }
  },
  props: {
    data: undefined,
    limit: undefined
  },
  methods: {
    generatePng(title) {
      ChartsUtils.generatePng(title, this.$data._chart);
    },
    resize() {
      this.$data._chart.resize();
    },
    downloadExcelData(title, date) {
      ChartsUtils.getBarPieExportExcel(this.data, title, date);
    },
    renderChartComponent() {
      const options = ChartsUtils.getBarPieOptions(this.data, false, true, true, this.limit);
      this.renderChart(options.data, options.options);
    }
  },
};