<!-- VUETIFY2 - OK -->
<template>
    <v-row align="center">
        <v-col class="mr-4" md="3" lg="3" xl="3">
            <v-autocomplete v-model="localSelectedVersionId"
                            :label="labelUnivers"
                            :items="versionsList"
                            item-text="nom_version"
                            item-value="id"
                            :disabled="isDisabled"/>
        </v-col>
        <v-col v-if="endDate" md="6" lg="6" xl="6">
            Du {{startDate}} au {{endDate}}
        </v-col>
        <v-col v-else md="6" lg="6" xl="6">
            Depuis le {{startDate}}
        </v-col>
    </v-row>
</template>

<script>
    import Utils from "../utils";

    export default {
        name: "versions-list",
        props: {
            selectedVersion: {
                type: Object,
                default: undefined
            },
            versionsList: {
                type: Array,
                required: true
            },
            labelUnivers: {
                type: String,
                required: true
            },
            isDisabled: Boolean,
        },
        data() {
            return {
                statutsList: []
            }
        },
        computed: {
            localSelectedVersionId: {
                get: function () {
                    return this.selectedVersion.id;
                },
                set: function (newvalue) {
                    _.forEach(this.versionsList, version => {
                        if(version.id === newvalue) {
                            this.$emit("update-selected-version", version);
                        }
                    });
                }
            },
            startDate() {
                return Utils.formatDateIsoToFr(this.selectedVersion.date_debut)
            },
            endDate() {
                return Utils.formatDateIsoToFr(this.selectedVersion.date_fin)
            }
        },
    };
</script>


