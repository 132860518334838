// <!-- VUETIFY2 - OK -->
import VTmop from "./VTmop"

export default {
  name: "v-tmop-creation",
  extends: VTmop,
  computed: {
    isModif() {
      return false;
    },
    title(){
      return "Créer un TMOP";
    },
    openButtonMessage(){
      return "Créer un TMOP";
    },
  },
  methods: {
    submit() {
      let data = _.cloneDeep(this.tmop);
      data.annee = this.preparedAnnee();
      this.$emit("add-tmop", data);
      this.closePopUp();
    }
  }
};