<!-- VUETIFY2 - OK -->
<template>
    <dettes-list :univers="univers"/>
</template>

<script>
    import constantes from "@/utils/constantes";
    import DettesList from "@/components/DettesList";

    /**
     * Vue affichant la liste des dettes côté capital
     * @displayName Capital - CapDettes
     */
    export default {
        components: {
            DettesList,
        },
        data() {
            return {
                univers: constantes.univers.capital,
            };
        },
    }
</script>