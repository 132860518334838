// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonneMorale from "./PersonneMorale";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    this.isEspacePersonnel = false;
    const id = this.$route.params.id;
    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
    internalApi.personneMorale.getById(id).then(result => {
      // Need to guess some data to use it correctly
      if(_.isNil(result.infos_bancaire)){
        result.infos_bancaire = {
          "nom_banque": null,
          "bic": null,
          "iban": null,
        }
      }
      if(_.isNil(result.associe)){
        result.associe = {
          date_acces_societariat: undefined,
          date_evt_declencheur_societariat: undefined,
          lib_evt_declencheur_societariat: undefined,
          date_perte_qualite_associe: undefined,
          motif_perte_qualite_associe: undefined,
          renonciation: false,
          type_droit_de_vote: undefined,
          scic_donnees_associe: null,
        }
      }
      if(_.isNil(result.associe.scic_donnees_associe)){
        result.associe.scic_donnees_associe = {
          code_scic_categorie: undefined,
          code_scic_sous_categorie: undefined,
          code_scic_college: undefined,
        }
      }
      this.personne_morale = result;
      this.modifier_donnees_associe = !_.isNil(result.associe.id);
      // Savoir si le tiers est déjà associé (empêcher l'utilisateur de décocher les données associé)
      this.is_already_associe = this.modifier_donnees_associe;
    })
    .finally(() => {
      this.$refs.loadingComponent.resetLoading();
    });
  },
  extends: PersonneMorale,
  computed: {},
  methods: {
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonneMoraleBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
      return internalApi.personneMorale
        .putByIdWithToastConfirmation(data_to_send.id, data_to_send)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          if (result) {
            this.$router.push({name: "tiers"});
          }
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
