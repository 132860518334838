<!-- VUETIFY2 - OK -->
<template>
    <v-select   :items="scicSousCategoriesToSelect"
                label="Sous catégorie d'associé"
                :error-messages="errors"
                :disabled="isDisabled"
                v-model="localScicSousCategorieAssocie"
                :clearable="true"
    />
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    /**
     * Composant affichant la liste des sous-catégories d'associé (SCIC).
     * @displayName Scic - Liste sous-catégories d'associé
     */
    export default {
        name: "VScicSousCategorieList",
        props: {
            /**
             * La catégorie sélectionnée (sert à sélectionner les sous-catégories)
             */
            scicCategorieAssocie: {
                type: String,
            },
            /**
             * La sous catégorie sélectionnée
             */
            scicSousCategorieAssocie: {
                type: String,
            },
            /**
             * Désactive ou non le composant
             */
            isDisabled: Boolean,
            /**
             * Les erreurs générées par un ValidationProvider
             */
            errors: {
                type: Array,
                required: false,
            }
        },
        mounted() {
            this.fetchScicSousCategorieLabels();
        },
        computed: {
            localScicSousCategorieAssocie: {
                get: function () {
                    return this.scicSousCategorieAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-sous-categorie-associe", newvalue);
                }
            },
            scicSousCategoriesToSelect() {
                let sousCategories = []
                if(this.scicSousCategorieLabels.hasOwnProperty(this.scicCategorieAssocie)){
                    sousCategories =  this.scicSousCategorieLabels[this.scicCategorieAssocie]
                }
                return Utils.fromLabelToSelect(sousCategories)
            }
        },
        watch:{
            scicSousCategoriesToSelect() {
                // Clear the sous categorie if we're not just loading the vue and changing the main categorie
                if(this.scicSousCategorieLabels.hasOwnProperty(this.scicCategorieAssocie) && !this.scicSousCategorieLabels[this.scicCategorieAssocie].hasOwnProperty(this.scicSousCategorieAssocie)){
                    this.localScicSousCategorieAssocie = undefined;
                }
            },
        },
        data() {
            return {
                scicSousCategorieLabels: {},
            }
        },
        methods: {
            fetchScicSousCategorieLabels() {
                internalApi.scicSousCategorie.getLabels().then(res => {
                    this.scicSousCategorieLabels = res.categories;
                });
            }
        }
    };
</script>


