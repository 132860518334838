import Index from "@/views/Index";
import Dettes from "@/views/common/reglements/Dettes.vue";
import DetailDette from "@/views/common/reglements/DetailDette.vue";
import ReglementDette from "@/views/common/reglements/ReglementDette.vue";
import rolesList from "@/router/rolesList";

export default {
  path: "dettes/",
  component: Index,
  children: [
    {
      path: "/",
      name: "dettes",
      component: Dettes,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":id/",
      name: "detail-dette",
      component: DetailDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "commun"
      }
    },
    {
      path: ":id/reglement-dette/",
      name: "reglement-dette",
      component: ReglementDette,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
      props: {
        univers: "commun"
      }
    }
  ]
};
