// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import AnnulationTotale from "./AnnulationTotale";

export default {
  mounted() {
  },
  extends: AnnulationTotale,
  data() {
    return {
      canEditDate: true,
    }
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      const annulations_totale = this.annulationtotaleinformations.categories.map(categorie_detail => {
        return {
          "category_id": categorie_detail.id,
          "montant_remboursement_parts_sociales": categorie_detail.montant,
          "nb_parts_sociales": categorie_detail.nb_parts,
          "type_annu": "tot",
          "associe_id": this.annulationtotale.associe_id,
          "date_mouvement": this.annulationtotale.date_mouvement,
          "motif": "aucun_motif",
          "commentaire": !_.isNil(this.annulationtotale.commentaire) ? this.annulationtotale.commentaire : null,
        };
      });
      const annulation_totale_to_send = {
          "associe_id": this.annulationtotale.associe_id,
          "date_mouvement": this.annulationtotale.date_mouvement,
          "commentaire": !_.isNil(this.annulationtotale.commentaire) ? this.annulationtotale.commentaire : null,
      }
      internalApi.annulationtotale
        .createAnnulationTotale(annulation_totale_to_send)
        .then(result => {
          this.pushToMouvements();
        }).catch(error => {
          console.error(error);
        });
    }
  }
};
