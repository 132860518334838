<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSize="85px">
        <template slot="header">
            <h1>Liste des Exercices</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <v-container fluid>
                <v-data-table class="elevation-1"
                              fixed-header
                              no-data-text="Aucun exercice n'a été crée."
                              :height="datatableSize"
                              :footer-props="footerProps"
                              :headers="headers"
                              :items="exercicesList"
                              :options.sync="pagination"
                              :loading="isDataLoading">
                    <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                        <v-tooltip top :key="h.value">
                            <template v-slot:activator="{ on }">
                            <span v-on="{ ...on }">
                                {{ h.text }}
                            </span>
                            </template>
                            <span>
                                {{ h.tooltip }}
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr :class="{exercice_valide: item.is_common_exercice_all_states_valide}">
                            <td>{{ item.date_debut | dateIsoToFrFilter }}</td>
                            <td>{{ item.date_fin | dateIsoToFrFilter }}</td>
                            <td>{{ item.libelle }}</td>
                            <td>{{ item.date_ag_annuelle | dateIsoToFrFilter}}</td>
                            <td class="text-lg-center">
                                <v-icon-button-with-tool-tip
                                    :routeCible="{name: 'edition-exercice',params: { exerciceId: item.id }}"
                                    detail="Paramétrage de l'exercice"
                                    :icon="'$vuetify.icons.edit'"
                                    btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                    btnColorClass="transparent"
                                    iconColorClass="grey darken-1"
                                />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </template>
        <template slot="footer">
            <v-row justify="end" no-gutters>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn dark color="primary" @click="createExercice()" block>
                        <v-icon left>{{"$vuetify.icons.add"}}</v-icon>
                        Créer un exercice
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Vue affichant la liste des exercices côté commun
     * @displayName Commun - ExercicesList
     */
    export default {
        components: {
            BaseLayout,
            VIconButtonWithToolTip
        },
        data() {
            return {
                exercicesList: [],
                headers: [
                    {
                        text: "Date de début",
                        tooltip: "Date de début de l'exercice",
                        value: "date_debut"
                    },
                    {
                        text: "Date de fin",
                        tooltip: "Date de fin de l'exercice",
                        value: "date_fin"
                    },
                    {
                        text: "Libellé",
                        tooltip: "Libellé de l'exercice",
                        value: "libelle"
                    },
                    {
                        text: "Date AG",
                        tooltip: "Date de l'Assemblée Générale annuelle",
                        value: "date_ag_annuelle"
                    },
                    {
                        text: "Actions",
                        tooltip: "Actions",
                        value: "actions",
                        align: "center",
                        sortable: false
                    }
                ],
                exerciceToEdit: {},
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                isDataLoading: true,
            };
        },
        mounted() {
            this.isDataLoading = true;
            this.getExercicesList().finally(() => {
                this.isDataLoading = false;
            });
        },
        methods: {
            getExercicesList() {
                return internalApi.exercice.getAll().then(result => {
                    this.exercicesList = _.isNil(result) ? [] : result;
                });
            },
            createExercice() {
                this.$router.push({
                    name: "creation-exercice"
                });
            }
        }
    };
</script>

<style>
    .exercice_valide {
        background-color: lightgrey;
    }
</style>