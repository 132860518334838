<!-- VUETIFY2 - OK -->
<template>
    <v-row align="center">
        <v-col cols="1" v-if="multiple">
            <v-row>
                <v-checkbox v-model="isAllChecked" hide-details
                            @change="checkAll($event)" :indeterminate="isIndeterminate"/>
            </v-row>
        </v-col>
        <v-col>
            <v-autocomplete :value="value"
                            :label="label"
                            :items="associeList"
                            :error-messages="errors"
                            @input="changedInput($event)"
                            :disabled="isDisabled"
                            :multiple="multiple"/>
        </v-col>
    </v-row>
</template>

<script>
import internalApi from "@/api/internalApi";
import Utils from "@/utils.js";
import _ from "lodash";

/**
 * Composant affichant la liste des associés.
 * @displayName Capital - Liste associés
 */
export default {
    props: {
        /**
         * L'associé sélectionné à laffichage du composant
         */
        value: {
            type: [Number, Array]
        },
        /**
         * Est désactivé
         */
        isDisabled: Boolean,
        /**
         * Option pour afficher un élément 'tous les associes'
         */
        optionAllAssocies: {
            type: Boolean,
            required: false,
            default: false
        },
        errors: {
            type: Array,
            required: false
        },
        label: {
            type: String,
            required: false,
            default: ""
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted() {
        this.fetchAssocieList();
    },
    data() {
        return {
            associeList: [],
            isAllChecked: 0,
            isIndeterminate: false,
        }
    },
    methods: {
        /**
         * Récupère la liste des associés et ajoute l'option "Tous les associés" pour le
         * autocomplete
         */
        fetchAssocieList() {
            internalApi.associe.getAll().then(res => {
                this.associeList = Utils.fromArrayToSelect(res, "display_name_numero_tiers");
                if (this.optionAllAssocies) {
                    this.associeList.unshift({text: "Tous les associés", value: -1});
                }
            });
        },
        checkAll(isAllCheckedEvt) {
            if (isAllCheckedEvt) {
                const checkAllAssocie = _.flatMap(this.associeList, function (item) {
                    return item.value
                })
                this.changedInput(checkAllAssocie);
            } else {
                this.changedInput([]);
            }

        },
        changedInput(event) {
            if (event.length === this.associeList.length) {
                this.isAllChecked = true;
                this.isIndeterminate = false;
            } else if (event.length === 0) {
                this.isAllChecked = false;
                this.isIndeterminate = false;
            } else {
                this.isIndeterminate = true;
            }
            this.$emit('input', event);
        }
    }
};
</script>


