// <!-- VUETIFY2 - OK -->
import SupportInteressementAbstract from "./SupportInteressementAbstract";
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";


export default {
  extends: SupportInteressementAbstract,
  abstract: false,
  data() {
    return {
      isCreation: false,
      pageTitle: "Éditer un support d'intéressement",
    };
  },
  mounted() {
    this.getInteSupportById(this.inteSupportId);
  },
  methods: {
    getInteSupportById(id) {
      internalApi.inteSupport.getById(id)
        .then(result => {
          this.support = result;
          this.support.type_support = "transfert_externe";
        })
        .catch(err => {
          console.log(err);
        });
    },
    submit() {
      this.$refs.formSupportInteressement.validate().then(
        success => {
          if (success) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            const cleaned_support = this.getCleanedSupport();

            internalApi.inteSupport.putByIdWithToastConfirmation(this.support.id, cleaned_support)
              .then(res => {
                this.$refs.loadingComponent.resetLoading();
                this.cancel();
              })
              .catch(err => {
                console.log(err);
                this.$refs.loadingComponent.resetLoading();
              });
          }
        }
      );
    }
  }
};