// <!-- VUETIFY2 - OK -->
import Souscription from "./Souscription";

export default {
  mounted() {
    this.isEditable = false;
    this.getSouscription();
  },
  extends: Souscription,
  computed: {
    souscriptionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
    }
  }
};