<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px" :disabled="disabledValue()">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" @click="computeSelectedData()" :disabled="disabledValue()" block>
                Créer règlement(s)
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Règlement(s)</v-card-title>
            <v-card-text>
                <ValidationObserver v-slot="{ handleSubmit }" ref="formReglementGroupe">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <v-container grid-list-md>
                            <v-row>
                                Montant des dettes sélectionnées : {{ restantDu | roundEuroFilter}}
                            </v-row>
                            <br/>
                            <v-row dense>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="date_reglement" name="Date du paiement"
                                                        rules="required" v-slot="{ errors }">
                                        <DateField v-model="informations_reglements.date_de_reglement"
                                                label="Date du paiement"
                                                :errors="errors"/>
                                    </ValidationProvider>
                                </v-col>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="type_reglement" name="Type de paiement"
                                                        rules="required" v-slot="{ errors }">
                                        <v-select v-model="informations_reglements.type_reglement"
                                                :items="choixTypeReglement"
                                                label="Type de paiement"
                                                item-text="text"
                                                item-value="value"
                                                :error-messages="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col md="6" lg="6" xl="6">
                                    <v-text-field v-model="informations_reglements.commentaire"
                                                label="Commentaire"/>
                                </v-col>
                                <v-col md="6" lg="6" xl="6">
                                    <ValidationProvider vid="detail_reglement" name="Référence du paiement"
                                                        rules="required" v-slot="{ errors }">
                                        <v-text-field v-model="informations_reglements.detail_reglement"
                                                    label="Référence du paiement"
                                                    :error-messages="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row dense justify="end">
                                <!-- Bouton modal -->
                                <v-col md="4" lg="4" xl="4">
                                    <v-btn @click="closePopUp" block>
                                        Annuler
                                    </v-btn>
                                </v-col>
                                <!-- Bouton modal -->
                                <v-col md="4" lg="4" xl="4" class="mr-4">
                                    <v-btn color="primary" @click="submitReglementsGroupe" block>
                                        Solder les dettes
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </ValidationObserver>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant le formulaire pour régler un groupe de dettes
     * @displayName Commun - Règlement groupé dettes
     */
    export default {
        components: {
            DateField
        },
        name: "ReglementGroupe",
        data() {
            return {
                dialog: false,
                informations_reglements: {
                    date_de_reglement: undefined,
                    detail_reglement: undefined,
                    type_reglement: undefined,
                    commentaire: undefined
                },
                restantDu: undefined,
                canSelectSepa: true,
            }
        },
        props: {
            /**
             * Les règlements sélectionnés
             */
            selected: {
                type: Array,
                required: true
            }
        },
        computed: {
            localSelected() {
                return this.selected;
            },
            choixTypeReglement() {
                let choix = [];
                if (this.canSelectSepa) {
                    choix.push({text: 'Paiement SEPA', value: 'paiement_sepa'});
                }
                choix.push(
                    {text: 'Virement', value: 'virement'},
                    {text: 'Chèque', value: 'cheque'},
                    {text: 'Autre (sans référence)', value: 'autre'},
                );

                return choix;
            },
        },
        methods: {
            disabledValue() {
                return !(!_.isEmpty(this.localSelected) && this.localSelected.every(val => val.restant_du > 0));
            },
            computeSelectedData() {
                let restantDu = 0;
                let canSelectSepa = true;
                for (let i = 0; i < this.localSelected.length; ++i) {
                    restantDu += this.localSelected[i].restant_du;
                    if (!this.detteIsEligibleSepa(this.localSelected[i])){
                        canSelectSepa = false;
                    }
                }
                this.restantDu = restantDu;
                this.canSelectSepa = canSelectSepa;
            },
            detteIsEligibleSepa(dette) {
                return !(["Transfert externe de la participation", "Transfert externe de l'intéressement"].includes(dette.type));
            },
            closePopUp() {
                this.dialog = false;
                // Reset values for next opening
                this.informations_reglements = {
                    date_de_reglement: undefined,
                    detail_reglement: undefined,
                    type_reglement: undefined,
                    commentaire: undefined
                };
                this.$refs.formReglementGroupe.reset();
            },
            submitReglementsGroupe() {
                this.$refs.formReglementGroupe.validate().then(
                    success => {
                        if(success){
                            this.removePaidDettes();
                            let reglements = [];
                            for (let i = 0; i < this.localSelected.length; ++i) {
                                let reglement = {
                                    "dette_id": this.localSelected[i].id,
                                    "beneficiaire_different": false,
                                    "denomination_tierce": undefined,
                                    "montant_reglement": this.localSelected[i].restant_du,
                                    "date_reglement": this.informations_reglements.date_de_reglement,
                                    "reference_reglement": this.informations_reglements.detail_reglement,
                                    "type_reglement": this.informations_reglements.type_reglement,
                                    "commentaire": this.informations_reglements.commentaire
                                };
                                reglements.push(reglement);
                            }
                            internalApi.reglements.postAllWithToastConfirmation({"reglements": reglements}).then(() => {
                                this.closePopUp();
                                this.$emit("submit");
                            });
                        }
                    }
                );
            },
            removePaidDettes() {
                for (let i = this.localSelected.length - 1; i >= 0; --i) {
                    if (this.localSelected[i].restant_du === 0) {
                        this.localSelected.splice(i, 1)
                    }
                }
            }
        }
    }
</script>