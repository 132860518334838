<template>
    <v-select
        v-model="localSelectedOption"
        :label="label"
        :items="localItems"
        :error-messages="errors"
        :item-disabled="itemDisabled"
        :disabled="!isEditable"
        @change="propagateChange"
    >
        <template v-slot:item="{ item, attrs, on }">
            <v-tooltip :left="tooltipLeft" :right="tooltipRight" :top="tooltipTop" :bottom="tooltipBottom" :disabled="!item.tooltip">
                <template v-slot:activator="{ on: tooltip }">
                    <div style="width: 100%;" v-on="{ ...tooltip }">
                        <div v-on="on" v-bind="attrs" style="pointer-events: auto; cursor: pointer;">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span>{{ item.text }}</span>
                                    <v-icon v-if="item.tooltip" class="ml-2" small left v-on="{ on }">{{ optionsToDisabledIcon }}</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                        </div>
                    </div>
                </template>
                <span>{{ item.tooltip }}</span>
            </v-tooltip>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: "VSelectCustom",
        data() {
            return {
                localItems: [],
            }
        },
        props: {
            label: {
                type: String,
                required: true,
                default: "",
            },
            value: {
                twoWay: true,
                required: true,
            },
            items: {
                type: Array,
                required: false,
            },
            errors: {
                type: Array,
                required: true,
            },
            isEditable: {
                type: Boolean,
                required: false,
            },
            optionsToDisabledIcon: {
                type: String,
                required: false,
                default: "$vuetify.icons.info",
            },
            itemDisabled: {
                type: Function,
                required: false,
                default: undefined,
            },
            itemTooltip: {
                type: Function,
                required: false,
                default: () => null,
            },
            tooltipBottom: {
                type: Boolean,
                required: false,
                default: false,
            },
            tooltipTop: {
                type: Boolean,
                required: false,
                default: false,
            },
            tooltipLeft: {
                type: Boolean,
                required: false,
                default: false,
            },
            tooltipRight: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        watch: {
            items: {
                deep: true,
                handler(newVal, oldVal) {
                    this.addTooltipToItems(newVal);
                }
            },
        },
        computed: {
            localSelectedOption: {
                get: function () {
                    return this.value;
                },
                set: function (newvalue) {
                    this.$emit("input", newvalue);
                }
            },
        },
        methods: {
            propagateChange: function () {
                this.$emit('change', this.value);
            },
            
            addTooltipToItems(items) {
                let localItems = _.cloneDeep(items);
                localItems.forEach(item => {
                    item["tooltip"] = !_.isNil(this.itemTooltip) ? this.itemTooltip(item) : null;
                });
                this.localItems = localItems;
            },
        },
    }
</script>
