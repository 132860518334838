<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-formatted-number-text-field 
                v-model="valueToShow"
                :labelValue="label" 
                :reverseValue="true"
                :readonlyValue="true"
                @click="openDialog"
            >
                <template v-slot:prepend-inner>
                    <v-icon-button-with-tool-tip
                        detail="Modifier le coefficient d'ancienneté"
                        icon="$vuetify.icons.edit"
                        @click="openDialog"
                        btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                        btnColorClass="transparent"
                        iconColorClass="grey darken-1"
                        block
                    />
                </template>

            </v-formatted-number-text-field>

        </v-col>
        <v-col cols="1" class="text-lg-right pa-0">
            <v-tooltip top v-if="value && explainTextDerogatoryValue">
                <template v-slot:activator="{ on }">
                    <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                    </v-icon>
                </template>
                <span 
                    v-html="explainTextDerogatoryValue"/>
            </v-tooltip>
        </v-col>
        <v-col class="flex-grow-0">
            <v-dialog v-model="dialog" max-width="600px" content-class="overflow-visible" eager>
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>{{label}}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <p>{{cardSubtitleText}}</p>
                            <v-radio-group v-model="selectedOption">
                                <v-row align="center">
                                    <v-col>
                                        <v-radio label="Valeur par défaut" :value="DEFAULT_VALUE" />
                                        <div v-if="explainTextDefaultValue" class="v-card__subtitle pa-0">{{explainTextDefaultValue}}</div>
                                    </v-col>
                                    <v-col>
                                        <v-formatted-number-text-field
                                            v-model="localDefaultValue"
                                            :reverseValue="true"
                                            :disabledValue="true"
                                            labelValue="Valeur par défaut"
                                        />
                                    </v-col>
                                </v-row>
                                <v-divider class="my-4"/>
                                <v-row align="center">
                                    <v-col>
                                        <v-radio label="Valeur dérogatoire" :value="DEROGATORY_VALUE" />
                                        <div v-if="explainTextDerogatoryValue" class="v-card__subtitle pa-0">{{explainTextDerogatoryValue}}</div>
                                    </v-col>
                                    <v-col>
                                        <v-formatted-number-text-field
                                            v-model="localValue"
                                            :reverseValue="true"
                                            labelValue="Valeur dérogatoire"
                                            :disabledValue="selectedOption == DEFAULT_VALUE"
                                        />
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row justify="end" dense>
                            <!-- Bouton modal -->
                            <v-col cols="4">
                                <v-btn @click="onCancelClick" block ref="cancelBtn">
                                    Annuler
                                </v-btn>
                            </v-col>
                            <!-- Bouton modal -->
                            <v-col cols="4">
                                <v-btn color="primary" @click="onSaveClick" block>
                                    Enregistrer
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    import _ from "lodash";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    
    export default {
        name: "show-default-or-derogatory-value",
        components: {
            VFormattedNumberTextField,
            VIconButtonWithToolTip,
        },
        data() {
            return {
                dialog: false,
                DEFAULT_VALUE: "coeff_defaut",
                DEROGATORY_VALUE:"coeff_derogatoire",
                selectedOption: undefined,
            }
        },
        mounted() {
           this.selectedOption = this.getWhichValueToShow();
        },
        props: {
            label: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            defaultValue: {
                required: true,
            },
            value: {
                twoWay: true,
                required: false,
                default: undefined,
            },
            explainTextDefaultValue: {
                type: String,
                required: false,
            },
            explainTextDerogatoryValue: {
                type: String,
                required: false,
            },
            cardSubtitleText: {
                type: String,
                required: false,
            },
            tooltipWhenDefault: {
                type: String,
                required: false,
            },
            tooltipWhenDerogatory: {
                type: String,
                required: false,
            }

        },
        watch: {
            dialog() {
                if (this.dialog) {
                    this.selectedOption = this.getWhichValueToShow();
                }
            }
        },
        computed: {
            valueToShow: {
                get: function() {
                     if (this.getWhichValueToShow() == this.DEFAULT_VALUE) {
                        return this.defaultValue;
                     }
                     return this.value;
                },
                set: function(value) {
                    this.$emit("input", value);

                }
            },
            localValue: {
                get: function () {
                     return this.value;
                },
                set: function (value) {
                    this.$emit("input", value);
                }
            },
            localDefaultValue: {
                get: function () {
                     return this.defaultValue;
                },
            }
        },
        methods: {
            openDialog() {
                this.dialog = true;
            },
            getWhichValueToShow() {
                if (_.isNil(this.value)) {
                    return this.DEFAULT_VALUE;
                }
                return this.DEROGATORY_VALUE;
            },
            onCancelClick(){
                this.localValue = this.value;
                this.dialog = false;
            },
            onSaveClick(){
                if (this.selectedOption === this.DEFAULT_VALUE) {
                    this.localValue = null;
                }
                this.updatevalue(this.localValue);
                this.dialog = false;
            },
            updatevalue(value) {
                this.$emit("update-value", value);
            },
        },
    }
</script>