<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <h2>État des supports de participation</h2>
        <p>
            <span>Montant total disponible à date de l'opération : {{ this.montantTotalDispoAdate | roundEuroFilter }}</span>
        </p>
        <v-row no-gutters>
            <v-col v-if="this.operationsBySupports.length > 0">
                <v-tabs v-model="currentTab">
                    <v-tab v-for="supp in this.operationsBySupports" :key="supp.name">{{supp.name}}</v-tab>
                    <v-tab-item v-for="supp in this.operationsBySupports" :key="supp.name">
                        <v-row align="stretch" no-gutters>
                            <v-col align-self="stretch">
                                <v-data-table :search="search"
                                            :footer-props="footerProps"
                                            :headers="showedHeaders"
                                            :items="supp.operation_by_support"
                                            :options.sync="options"
                                            fixed-header
                                            :height="height"
                                            no-data-text="Aucun mouvement">
                                    <template v-for="h in showedHeaders" v-slot:[`header.${h.value}`]="{ header }">
                                        <v-row v-bind:key="'tooltip_' + h.value" align="center">
                                            <v-col v-if="header.tooltip != null">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                    </template>
                                                    <span v-html="header.tooltip"/>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col align-self="end" :text-align="header.align">
                                                {{ header.text }}
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <tr>
                                            <td class="text-lg-left" v-if="showColumn('disponibilite')">
                                                Disponible le {{item.date_disponibilite | dateIsoToFrFilter}}
                                            </td>
                                            <td class="text-lg-right" v-if="showColumn('participation')">
                                                {{item.participation | roundEuroFilter}}
                                            </td>
                                            <td class="text-lg-right" v-if="showColumn('interets')">
                                                    <v-row justify="end">
                                                    <v-col cols="auto">
                                                        {{item.interets | roundEuroFilter}}
                                                    </v-col>
                                                </v-row>
                                            </td>
                                            <td class="text-lg-right" v-if="showColumn('total')">{{item.montant |
                                                roundEuroFilter}}
                                            </td>
                                            <td class="text-lg-right grey lighten-3" v-if="showColumn('interets_courus')">
                                                    <v-row justify="end">
                                                    <v-col cols="auto">
                                                        {{item.interets_courus | roundEuroFilter}}
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:[`body.append`]>
                                        <tr class="v-data-table__nohoverezi">
                                            <td class="text-lg-left font-weight-bold">Totaux</td>
                                            <td class="text-lg-right font-weight-bold" v-if="showColumn('participation')">{{ supp.montant_participation_total |
                                                roundEuroFilter }}
                                            </td>
                                            <td class="text-lg-right font-weight-bold" v-if="showColumn('interets')">{{ supp.montant_interets_total |
                                                roundEuroFilter }}
                                            </td>
                                            <td class="text-lg-right font-weight-bold" v-if="showColumn('total')">{{ supp.montant_total |
                                                roundEuroFilter }}
                                            </td>
                                            <td class="text-lg-right font-weight-bold grey lighten-3" v-if="showColumn('interets_courus')">
                                                {{ supp.montant_interets_courus_total | roundEuroFilter }}
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-col>
            <v-col v-else>
                <v-card :height="height" elevation="2" color="#eeeeee">
                    <v-row align="center" justify="center" style="height:100%;">
                        <v-col>
                            <p align="center" justify="center">Aucune opération n'a été enregistrée pour le bénéficiaire à cette date</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import _ from "lodash";

    /**
     * VDatatable pour des opérations simplifiées par date de disponibilité
     * @display_name Participation - VDatatable mouvements simplifiés
     */
    export default {
        name: "VDatatableMouvementsSupportsSimlifie",
        components: {
            loadingScreen,
        },
        props: {
            supportId: {
                type: Number,
                required: false,
                default: undefined
            },
            /**
             * La date pour laquelle filtrer le montant total et calculer les intérêts courus
             */
            dateOperation: {
                type: String,
                required: false,
            },
            /**
             * Le taux de rémunération à prendre en compte
             */
            tauxRemu: {
                type: Number,
                required: false,
                default: undefined,
            },
            /**
             * Si les données à afficher sont à terme ou à date d'opération
             */
            aTerme: {
                type: Boolean,
                required: false,
                default: false,
            },
            /**
             * Colonnes à ne pas afficher
             */
            columnsToHide: {
                type: Array,
                required: false,
                default() {
                    return [];
                },
            },
            /**
             * Hauteur de la data-table
             */
            height: {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                search: "",
                options: {
                    sortBy: ["date_mouvement"],
                    sortDesc: [true],
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                showedHeaders: [],
                currentTab: 0,
                operationsBySupports: [],
                montantTotal: 0,
                montantTotalDispoAdate: 0,
                tooltipInteretsCourus: null,
                nominalMessageInteretsCourus: "Les intérêts courus sont une estimation temporaire des intérêts générés par les montants placés durant l’exercice en cours.<br/>Ces montants sont amenés à fluctuer en fonction de l’évolution du TMOP",
                debouncedUpdateDataTable: undefined,
            }
        },
        watch: {
            currentTab() {
                this.computeTotalOnDateOperation();
            },
            beneficiaireId() {
                this.debouncedUpdateDataTable();
            },
            dateOperation() {
                this.debouncedUpdateDataTable();
            },
            supportId() {
              this.debouncedUpdateDataTable();
            },
            tauxRemu() {
              this.debouncedUpdateDataTable();
            },
            headers: {
                handler() {
                    this.updateShowedHeaders();
                },
                deep: true,
            },
        },
        computed: {
            headers() {
                return [
                    {text: "Disponibilité", sortable: true, value: "disponibilite", align: "left", tooltip: null},
                    {text: "Participation", sortable: true, value: "participation", align: "right", tooltip: null},
                    {text: "Intérêts échus bruts", sortable: true, value: "interets", align: "right", tooltip: null},
                    {text: "Total", sortable: true, value: "total", align: "right", tooltip: null},
                    {text: "Intérêts courus bruts", sortable: true, value: "interets_courus", align: "right", tooltip: this.tooltipInteretsCourus},
                ]
            }
        },
        mounted() {
            const delayInMillis = 500;
            this.debouncedUpdateDataTable = _.debounce(this.updateDataTable, delayInMillis);
            this.updateShowedHeaders();
            this.updateDataTable();
        },
        methods: {
            updateShowedHeaders() {
                this.showedHeaders = [];
                for (let header of this.headers) {
                    if (!this.columnsToHide.includes(header.value)) {
                        this.showedHeaders.push(header);
                    }
                }
            },
            showColumn(column_name) {
                return !this.columnsToHide.includes(column_name);
            },
            /**
             * Hérité dans les fichiers .js selon la partie de l'application où cette vue est utilisée (sécurité)
             */
            updateDataTable() {

            },
            computeTotalOnDateOperation() {
                if (typeof this.operationsBySupports[this.currentTab] !== 'undefined') {
                    let mnt = 0;
                    const currentDateOpe = this.dateOperation;
                    _.forEach(this.operationsBySupports[this.currentTab].operation_by_support, function (value) {
                        if (value.date_disponibilite <= currentDateOpe) {
                            mnt += value.montant;
                        }
                    });

                    this.montantTotalDispoAdate = mnt.toFixed(2);  // L'addition de flottants peut engendrer des anomalies
                } else {
                    this.montantTotalDispoAdate = 0;
                }
            }
        }
    }
</script>