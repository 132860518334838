<!-- VUETIFY2 - OK -->
<template>
    <dettes-list :univers="univers"/>
</template>

<script>
    import constantes from "@/utils/constantes";
    import DettesList from "@/components/DettesList";

    export default {
        components: {
            DettesList,
        },
        data() {
            return {
                univers: constantes.univers.participation,
            };
        },
    }
</script>