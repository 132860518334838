<!-- VUETIFY2 - OK -->
<template>
    <v-container>
        <loading-screen ref="loadingComponent"/>
        <v-row class="d-block" no-gutters>
            <template>
                <v-data-table
                    :headers="headers"
                    :items="repartitionPbAncienneteList"
                    :no-data-text="noDataTextMessage"
                    :sort-by="'groupe'"
                    :sort-desc="false"
                    :must-sort="true"
                    class="elevation-1"
                    fixed-header
                    hide-default-footer
                >
                    <template v-slot:footer>
                        <v-toolbar flat>
                            <template>
                                <!-- Petit bouton -->
                                <v-col md="3" lg="3" xl="2">
                                    <v-regle-repartition-pb-anciennete-creation @create-regle="getAllRepartitionPbAnciennete(pbCompanyId)"
                                                                                :pbCompanyId="pbCompanyId"
                                                                                :regleLabels="labels"
                                                                                :disabled="disabled"
                                    />
                                </v-col>
                            </template>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.regle`]="{ item }">
                        {{ labels.regle_rep_anciennete[item.regle] }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-custom-confirmation-dialog v-if="!disabled && !item.is_default"
                                                      message="Êtes-vous sûr(e) de vouloir supprimer cette règle ?"
                                                      :isIcon="true"
                                                      :iconValue="'$vuetify.icons.delete'"
                                                      @action-confirmee="deleteRegleById(item.id)"
                                                      tooltipDetailMessage="Supprimer la règle."/>

                        <v-regle-repartition-pb-anciennete-edition v-if="!disabled && item.is_default"
                                                                   @edit-regle="getAllRepartitionPbAnciennete(pbCompanyId)"
                                                                   :pbCompanyId="pbCompanyId"
                                                                   :regleLabels="labels"
                                                                   :isDefault="true"
                                                                   :oldRegle="item"
                                                                   :disabled="disabled"/>
                    </template>
                </v-data-table>
            </template>
        </v-row>
    </v-container>
</template>

<script>

    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VRegleRepartitionPbAncienneteCreation
        from "@/components/regleRepartitionPbAnciennete/VRegleRepartitionPbAncienneteCreation";
    import VCustomConfirmationDialog from "./VCustomConfirmationDialog";
    import constantes from "@/utils/constantes";
    import VRegleRepartitionPbAncienneteEdition
        from "./regleRepartitionPbAnciennete/VRegleRepartitionPbAncienneteEdition";

    export default {
        name: "RepartitionPbAnciennete",
        components: {
            VRegleRepartitionPbAncienneteEdition,
            VCustomConfirmationDialog,
            VRegleRepartitionPbAncienneteCreation,
            loadingScreen
        },
        props: {
            pbCompanyId: {
                type: Number,
                required: true,
                default: undefined
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                labels: {regle_rep_anciennete: {}},
                repartitionPbAncienneteList: [],
                headers: [
                    {
                        text: "Groupe",
                        value: "groupe",
                    },
                    {
                        text: "Règle",
                        value: "regle",
                        align: "right",
                    },
                    {
                        text: "Ancienneté (en mois)",
                        value: "anciennete",
                        align: "right",
                    },
                    {
                        text: "Coefficient",
                        value: "coefficient",
                        align: "right",
                    },
                    {
                        text: "Actions",
                        sortable: false,
                        value: "actions",
                        align: "left"
                    }
                ],
                noDataTextMessage: "Aucune donnée disponible.",
            }
        },
        mounted() {
            this.getAllRepartitionPbAnciennete(this.pbCompanyId);
            this.getLabels();
        },
        methods: {
            getLabels() {
                internalApi.repartitionPbAnciennete.getLabels()
                    .then(result => {
                        this.labels = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getAllRepartitionPbAnciennete(pbCompanyId) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                return internalApi.repartitionPbAnciennete.getAllByPbCompany(pbCompanyId)
                    .then(result => {
                        this.repartitionPbAncienneteList = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            deleteRegleById(id) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
                internalApi.repartitionPbAnciennete.deleteByIdWithToastConfirmation(id)
                    .then(result => {
                        this.$refs.loadingComponent.resetLoading();
                        this.getAllRepartitionPbAnciennete(this.pbCompanyId);
                    })
                    .catch(err => {
                        console.log(err);
                        this.$refs.loadingComponent.resetLoading();
                    })
            }
        }
    }
</script>