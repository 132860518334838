import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default aboAttributionApi;

function aboAttributionApi() {
  return {
    getAboAttributionByAboExerciceId: getAboAttributionByAboExerciceId(),
    getAboAttributionByAboExerciceIdIndividuel: getAboAttributionByAboExerciceIdIndividuel(),
    computeSingleAttributionPreview: computeSingleAttributionPreview(),
    saveAboAttribution: saveAboAttribution(),
    saveAboAttributionIndividuel: saveAboAttributionIndividuel(),
    validateAboAttribution: validateAboAttribution(),
    downloadAboAttributionExcel: downloadAboAttributionExcel(),
    getNbPartsMaxByAboExerciceId: getNbPartsMaxByAboExerciceId(),
    getTotauxAboAttribution: getTotauxAboAttribution(),
    getStateBulletinsSouscriptionAbondement: getStateBulletinsSouscriptionAbondement(),
    generateBulletinsSouscriptionAbondement: generateBulletinsSouscriptionAbondement(),
    downloadBulletinsSouscriptionAbondement: downloadBulletinsSouscriptionAbondement(),
  }
}

function getAboAttributionByAboExerciceId() {
    function getAboAttributionByAboExerciceId(aboExerciceId, queryParams, headers) {
        let path = internalApiRoutes.aboExercices.attribution.aboAttributionByAboExerciceId(aboExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }
    return getAboAttributionByAboExerciceId;
}

function getAboAttributionByAboExerciceIdIndividuel() {
  function getAboAttributionByAboExerciceIdIndividuel(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.aboAttributionByAboExerciceIdIndividuel(aboExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(queryParams, headers);
  }
  return getAboAttributionByAboExerciceIdIndividuel;
}

function computeSingleAttributionPreview() {
    function computeSingleAttributionPreview(aboExerciceId, queryParams, headers) {
        let path = internalApiRoutes.aboExercices.attribution.aboComputeSingleAttributionPreview(aboExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }
    return computeSingleAttributionPreview;
}

function saveAboAttribution() {
  function saveAboAttribution(aboExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.aboExercices.attribution.aboAttributionByAboExerciceId(aboExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return saveAboAttribution;
}

function saveAboAttributionIndividuel() {
  function saveAboAttributionIndividuel(aboExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.aboExercices.attribution.aboAttributionByAboExerciceIdIndividuel(aboExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return saveAboAttributionIndividuel;
}

function validateAboAttribution() {
  function validateAboAttribution(aboExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.aboExercices.attribution.aboAttributionValidate(aboExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return validateAboAttribution;
}

function downloadAboAttributionExcel() {
  function downloadAboAttributionExcel(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.downloadAboAttributionExcel(aboExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(queryParams, headers);
  }

  return downloadAboAttributionExcel;
}

function getNbPartsMaxByAboExerciceId() {
  function getNbPartsMaxByAboExerciceId(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.nbPartsMaxByAboExerciceId(aboExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(queryParams, headers);
  }

  return getNbPartsMaxByAboExerciceId;
}

function getTotauxAboAttribution() {
  function getTotauxAboAttribution(aboExerciceId, queryParams, headers) {
    let path = internalApiRoutes.aboExercices.attribution.getTotauxAboAttribution(aboExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getTotauxAboAttribution;
}

function getStateBulletinsSouscriptionAbondement() {
  function getStateBulletinsSouscriptionAbondement(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.getStateBulletinsSouscriptionAbondement(aboExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
  }
  return getStateBulletinsSouscriptionAbondement;
}

function generateBulletinsSouscriptionAbondement() {
  function generateBulletinsSouscriptionAbondement(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.generateBulletinsSouscriptionAbondement(aboExerciceId);
      let postForPath = apiConstructor.post(path);
      return postForPath(queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
  }
  return generateBulletinsSouscriptionAbondement;
}

function downloadBulletinsSouscriptionAbondement() {
  function downloadBulletinsSouscriptionAbondement(aboExerciceId, queryParams, headers) {
      let path = internalApiRoutes.aboExercices.attribution.downloadBulletinsSouscriptionAbondement(aboExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
  }
  return downloadBulletinsSouscriptionAbondement;
}