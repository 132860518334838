// <!-- VUETIFY2 - OK -->
import DeblocageAnticipe from "./DeblocageAnticipe";
import internalApi from "@/api/internalApi";

export default {
  extends: DeblocageAnticipe,
  computed: {
      title() {
        if(!_.isNil(this.deblocage.support_code)) {
          return "Deblocage anticipé du support de participation " + this.deblocage.support_code;
        }
        else {
          return "Déblocage anticipé";
        }
      }
  },
  data() {
    return {
      isEditable: false
    };
  },
  mounted() {
    const id = this.$route.params.id;
    internalApi.deblocageAnticipe.getById(id)
      .then(result => {
        this.deblocage = result;
      }).catch(err => {
      console.log(err);
    });
  }
};