<template>
    <v-menu nudge-left="70px" left :close-on-content-click="false" absolute>
        <template v-slot:activator="{ on: menu }">
            <v-icon-button-with-tool-tip
                :activator="menu"
                detail="Éditer un document pour le tiers"
                :icon="'$vuetify.icons.downloadPersonalFile'"
                btnIsIcon btnRound btnText tooltipBottom
                btnColorClass="transparent"
                iconColorClass="grey darken-1"
            />
        </template>
        <v-card>

            <v-row v-if="personneNameAndNumeroTiers">
                <v-col>
                    <v-card-title v-text="personneNameAndNumeroTiers"/>
                </v-col>
            </v-row>

            <v-card-text v-if="!(showCapitalData || showParticipationData)">
                Aucun document à éditer
            </v-card-text>

            <v-row v-else class="context-row">
                <v-col v-if="showCapitalData">
                    <v-list :disabled="associeId === null" dense class="super-dense-v-list">
                        <h3>
                            <p>CAPITAL</p>
                        </h3>
                        <v-list-item-group>
                            <v-divider/>
                            <v-list-item exact @click="downloadEtatDuCapital()" :disabled="associeId === null">
                                État du capital
                            </v-list-item>
                            <v-divider/>
                            <v-list-item :to="getRouteBulletinSouscription()" :disabled="associeId === null">
                                Bulletin de souscription
                            </v-list-item>
                            <v-divider/>
                            <v-list-item :to="getRouteBulletinAnnulationPartielle()" :disabled="associeId === null">
                                Bulletin d'annulation partielle
                            </v-list-item>
                            <v-divider/>
                            <v-list-item :to="getRouteBulletinAnnulationTotale()" :disabled="associeId === null">
                                Bulletin d'annulation totale
                            </v-list-item>
                            <v-divider/>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col v-if="showParticipationData">
                    <v-list :disabled="beneficiaireId === null" dense class="super-dense-v-list">
                        <h3>
                            <p>PARTICIPATION</p>
                        </h3>
                        <v-list-item-group>
                            <v-divider/>
                            <v-list-item @click="downloadEtatDeLaParticipation()" :disabled="beneficiaireId === null">
                                État de la participation
                            </v-list-item>
                            <v-divider/>
                        </v-list-item-group>
                    </v-list>
                </v-col>
            </v-row>
        </v-card>
    </v-menu>
</template>
<script>
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";

    /**
     * Affiche un menu contextuel pour choisir un document à éditer pour un tiers
     * @displayName Commun - Menu contextuel documents tiers
     */
    export default {
        components: {
            VIconButtonWithToolTip,
        },
        name: "v-contextual-menu-tiers-documents",
        props: {
            personneNameAndNumeroTiers: {
                type: String,
                default: null,
            },
            associeId: {
                type: Number,
                default: null,
            },
            beneficiaireId: {
                type: Number,  
                default: null,
            },
            showCapitalData: {
                type: Boolean,
                default: false,
            },
            showParticipationData: {
                type: Boolean,
                default: false,
            },
        },
        methods:{
            /**
             * Emet un évènement on-download-etat-du-capital (télécharger l'état du capital)
             */
            downloadEtatDuCapital() {
                this.$emit("on-download-etat-du-capital", this.associeId);
            },
            /**
             * Emet un évènement on-download-etat-de-la-participation (télécharger l'état de la participation)
             */
            downloadEtatDeLaParticipation() {
                this.$emit("on-download-etat-de-la-participation", this.beneficiaireId);
            },
            getRouteBulletinSouscription() {
                if(this.associeId !== null)
                    return {name:'bulletins-brouillons', params: {associeId: this.associeId, typeBulletin:'sous'}};
                return undefined;
            },
            getRouteBulletinAnnulationPartielle() {
               if(this.associeId !== null)
                    return {name:'bulletins-brouillons', params: {associeId: this.associeId, typeBulletin:'part'}};
                return undefined; 
            },
            getRouteBulletinAnnulationTotale() {
               if(this.associeId !== null)
                    return {name:'bulletins-brouillons', params: {associeId: this.associeId, typeBulletin:'tot'}};
                return undefined; 
            }
        }
    }
</script>
<style scoped>
    .context-row {
        margin: 0px;
    }
    .context-row>.col {
        min-width: 245px;
        margin: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 4px;
        border-left: 4px solid #00b5d8;
    }
    .context-row h3 {
        display: inline;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        font-weight: normal;
    }
    .super-dense-v-list {
        padding: 0px;
    }
</style>