import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default pbSupportApi;

function pbSupportApi() {
  const specificApi = {
    getLivretEpargneSalariale: getLivretEpargneSalariale(),
    getStateInteretsRemuneration: getStateInteretsRemuneration(),
    getPbTauxRemuBySupportId: getPbTauxRemuBySupportId(),
    createPbTauxRemuBySupportId: createPbTauxRemuBySupportId(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.pbSupports.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById,
    ACCEPTED_METHODS.getLabels,
  ]));
}

function getLivretEpargneSalariale() {
  function les(body, queryParams, headers) {
    let path = internalApiRoutes.pbSupports.livretEpargneSal();
    let getForPath = apiConstructor.get(path);
    return getForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return les;
}

function getStateInteretsRemuneration() {
  function getState(pbSupportId, queryParams, headers) {
    let path = internalApiRoutes.pbSupports.getStateInteretsRemuneration(pbSupportId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getState;
}

function getPbTauxRemuBySupportId() {
  function getPbTauxRemuBySupportId(pbSupportId, queryParams, headers){
    let path = internalApiRoutes.pbSupports.pbTauxRemuBySupportId(pbSupportId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getPbTauxRemuBySupportId;
}

function createPbTauxRemuBySupportId() {
  function createPbTauxRemuBySupportId(pbSupportId, body, queryParams, headers){
    let path = internalApiRoutes.pbSupports.pbTauxRemuBySupportId(pbSupportId);
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.simpleLaunch, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return createPbTauxRemuBySupportId;
}

