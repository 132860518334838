<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col>
                        <v-data-table :headers="headersInterets"
                                      :items="[detail]"
                                      hide-default-header
                                      hide-default-footer
                                      class="vertical elevation-1"
                                      dense>
                            <template v-slot:header="{}">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="text-lg-center">Intérêts</th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{items}">
                                <tbody>
                                    <tr v-for="header in headersInterets"
                                        :key="header.value"
                                        :class="{ bold: header.bold }"
                                        :style="coloredTrStyle(header.color ? header.color.secondaire : null)">
                                        <th :style="coloredThStyle(header.color ? header.color.principale : null)">{{header.text}}</th>
                                        <td class="text-lg-center">{{header.symbol}}</td>
                                        <td class="text-lg-right" v-for="item in items" :key="item.id">
                                            <template v-if="!(header.nullable && item[header.value] == null)">{{item[header.value] | roundEuroFilter}}</template>
                                            <template v-else>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                    </template>
                                                    <span v-if="header.value == 'montant_charges_sociales'"
                                                          v-html="`Le bénéficiaire était exonéré de charges sociales à la date de création de la dette.`"
                                                    />
                                                    <span v-else-if="header.value == 'montant_prelevement_forfaitaire'"
                                                          v-html="`Le bénéficiaire avait renoncé au prélèvement forfaitaire à la date de création de la dette.`"
                                                    />
                                                </v-tooltip>
                                                {{0 | roundEuroFilter}}
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row v-if="isMontantParticipationAvailable">
                    <v-col>
                        <v-data-table :headers="headersParticipation"
                                      :items="[detail]"
                                      hide-default-header
                                      hide-default-footer
                                      class="vertical elevation-1"
                                      dense>
                            <template v-slot:header="{}">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="text-lg-center">Participation aux bénéfices</th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{items}">
                                <tbody>
                                    <tr v-for="header in headersParticipation"
                                        :key="header.value"
                                        :class="{ bold: header.bold }"
                                        :style="coloredTrStyle(header.color ? header.color.secondaire : null)">
                                        <th :style="coloredThStyle(header.color ? header.color.principale : null)">{{header.text}}</th>
                                        <td class="text-lg-center">{{header.symbol}}</td>
                                        <td class="text-lg-right" v-for="item in items" :key="item.id">{{item[header.value] | roundEuroFilter}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider vertical role="presentation" v-if="isMontantParticipationAvailable"/>
            <v-col class="d-flex align-start flex-column" v-if="isMontantParticipationAvailable">
                <v-data-table :headers="headersTotal"
                              :items="[detail]"
                              hide-default-header
                              hide-default-footer
                              class="vertical elevation-1"
                              dense>
                    <template v-slot:header="{}">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-lg-center">Total</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{items}">
                        <tbody>
                            <tr v-for="header in headersTotal"
                                :key="header.value"
                                :class="{ bold: header.bold }"
                                :style="coloredTrStyle(header.color ? header.color.secondaire : null)">
                                <th :style="coloredThStyle(header.color ? header.color.principale : null)">{{header.text}}</th>
                                <td class="text-lg-center">{{header.symbol}}</td>
                                <td class="text-lg-right" v-for="item in items" :key="item.id">{{item[header.value] | roundEuroFilter}}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import constantes from "@/utils/constantes";

export default {
    name: "TablesDetailDette",
    props: {
        detail: Object,
        is_reliquat_conversion_part_sociale: Boolean,
    },
    computed: {
        headersInterets() {
            const color = this.isMontantParticipationAvailable ? constantes.couleurs_accentuation.bleu : null;
            let headers = [
                {text: "Intérêts échus bruts", value: "montant_interets_echus_brut", symbol: "", nullable: false},
                {text: "Intérêts courus bruts", value: "montant_interets_courus_brut", symbol: "+", nullable: false},
                {text: "Charges sociales", value: "montant_charges_sociales", symbol: "-", nullable: true},
                {text: "Prélèvement forfaitaire", value: "montant_prelevement_forfaitaire", symbol: "-", nullable: true},
            ];

            if (this.is_reliquat_conversion_part_sociale) {
                headers.push(
                    {text: "Intérêts nets", value: "montant_total_interets_net", symbol: "="},
                    {text: "Intérêts nets convertis en parts sociales", value: "montant_net_interets_conv_ps", symbol: "-"},
                    {text: "Intérêts nets versés", value: "montant_net_interets_verses", symbol: "=", bold: false, color: color},
                );
            } else {
                headers.push(
                    {text: "Intérêts nets", value: "montant_total_interets_net", symbol: "=", bold: false, color: color},
                );
            }
            return headers;
        },
        headersParticipation() {
            const color = constantes.couleurs_accentuation.orange;
            let headers = [];

            if (this.is_reliquat_conversion_part_sociale) {
                headers.push(
                    {text: "Participation nette", value: "montant_net_pb", symbol: ""},
                    {text: "Participation convertie en parts sociales", value: "montant_net_pb_conv_ps", symbol: "-"},
                    {text: "Participation versée", value: "montant_net_pb_versee", symbol: "=", bold: false, color: color},
                );
            } else {
                headers.push(
                    {text: "Participation nette", value: "montant_net_pb", symbol: "", bold: false, color: color},
                );
            }
            return headers;
        },
        headersTotal() {
            const bleu = constantes.couleurs_accentuation.bleu;
            const orange = constantes.couleurs_accentuation.orange;
            let headers = [];
            if (this.is_reliquat_conversion_part_sociale) {
                headers.push(
                    {text: "Intérêts nets versés", value: "montant_net_interets_verses", symbol: "", color: bleu},
                    {text: "Participation versée", value: "montant_net_pb_versee", symbol: "+", color: orange},
                    {text: "Total versé", value: "montant_a_regler", symbol: "=", bold: true},
                );
            } else {
                headers.push(
                    {text: "Intérêts nets", value: "montant_total_interets_net", symbol: "", color: bleu},
                    {text: "Participation nette", value: "montant_net_pb", symbol: "+", color: orange},
                    {text: "Total versé", value: "montant_a_regler", symbol: "=", bold: true},
                );
            }

            return headers;
        },
        isMontantParticipationAvailable() {
            return !_.isNil(this.detail) && "montant_net_pb" in this.detail && !_.isNil(this.detail.montant_net_pb);
        },
    },
    methods: {
        coloredThStyle(colorString) {
            let style = {};
            if (!_.isNil(colorString)) {
                style = {
                    borderLeft: "5px solid " + colorString,
                    paddingLeft: "11px",
                }
            }
            return style;
        },
        coloredTrStyle(colorString) {
            let style = {};
            if (!_.isNil(colorString)) {
                style = {
                    backgroundColor: colorString,
                }
            }
            return style;
        },
    },
}
</script>
<style scoped>
    .v-data-table {
        width: 100%;
    }
</style>
