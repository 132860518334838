import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default inteExerciceApi;

function inteExerciceApi() {
  const specificApi = {
    computePlafondInteressementCollectif: computePlafondInteressementCollectif(),
    computePlafondInteressementIndividuel: computePlafondInteressementIndividuel(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.inteExercices.baseUrl));
}

function computePlafondInteressementCollectif() {
  function computePlafondInteressementCollectif(queryParams, headers) {
    let path = internalApiRoutes.inteExercices.plafondInteressementCollectif();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computePlafondInteressementCollectif;
}

function computePlafondInteressementIndividuel() {
  function computePlafondInteressementIndividuel(queryParams, headers) {
    let path = internalApiRoutes.inteExercices.plafondInteressementIndividuel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return computePlafondInteressementIndividuel;
}
