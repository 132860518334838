import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "../../index";

export default companyApi;

function companyApi() {
  const specificApi = {
    getVersionCompany: getVersionCompany(),
    getCurrentCompany: getCurrentCompany(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.companies.baseUrl));
}

function getVersionCompany() {
  function getVersionCompany(dateVersion, queryParams, headers) {
    let path = internalApiRoutes.companies.versionsCompany();
    queryParams = queryParams || {};
    queryParams.date = dateVersion;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getVersionCompany;
}

function getCurrentCompany() {
  function getCurrentCompany(queryParams, headers) {
    let path = internalApiRoutes.companies.currentCompany();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getCurrentCompany;
}
