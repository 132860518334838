<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialogDetail" max-width="600px" :disabled="disabledValue">
        <template v-slot:activator="{ on }">
            <v-btn slot="activator" dark color="primary" outlined :disabled="disabledValue" block v-on="on">
                <v-icon dark left>{{ "$vuetify.icons.info" }}</v-icon>
                Détail calcul
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                Détail du calcul de la répartition de la participation aux bénéfices
            </v-card-title>

            <v-card-text>
                <br/>
                <div class="subheading">Montant de la participation aux bénéfices :
                    <span class="font-weight-bold">{{explain.montant_pb | roundEuroFilter}}</span>
                </div>
                <br/>
                <div v-if="explain.repartition_uniforme && explain.repartition_uniforme.cle">
                    <div class="title">Répartition uniforme <span v-if="explain.repartition_uniforme.prorata_temporis">(prorata temporis)</span>
                    </div>
                    <br/>
                    <div>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{explain.repartition_uniforme.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{explain.repartition_uniforme.montant_total | roundEuroFilter}}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold" v-if="explain.repartition_uniforme.prorata_temporis">
                            {{explain.repartition_uniforme.montant_jour | roundEuroFilter }}
                        </span>
                        <span class="font-weight-bold" v-else>
                            {{explain.repartition_uniforme.montant_unitaire | roundEuroFilter }}
                        </span>
                        <span> par bénéficiaire<span
                            v-if="explain.repartition_uniforme.prorata_temporis"> et par jour</span></span>
                    </div>
                </div>
                <br/>
                <div v-if="explain.repartition_salaire && explain.repartition_salaire.cle">
                    <div class="title">Répartition selon le salaire</div>
                    <br/>
                    <div>
                        <span>Revenus mobilisés pour la répartition :  </span>
                        <span class="font-weight-bold">{{explain.repartition_salaire.masse_montant | roundEuroFilter }}</span>
                        <br/>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{explain.repartition_salaire.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{explain.repartition_salaire.montant_total | roundEuroFilter}}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold">
                            {{explain.repartition_salaire.montant_unitaire * 1000 | roundEuroFilter }}
                        </span>
                        <span> pour {{1000 | roundEuroFilter}} de revenu annuel brut pour un salarié ayant travaillé sur tout l'exercice</span>
                    </div>
                </div>
                <br/>
                <div v-if="explain.repartition_presence && explain.repartition_presence.cle">
                    <div class="title">Répartition selon le temps de présence</div>
                    <br/>
                    <div>
                        <span>Temps de présence mobilisés pour la répartition :  </span>
                        <span class="font-weight-bold">{{ explain.repartition_presence.masse_montant || 0}} h</span>
                        <br/>
                        <span>Clé de répartition : </span>
                        <span class="font-weight-bold">{{ explain.repartition_presence.cle | roundPercentFilter }}</span>
                        <br/>
                        <span class="font-weight-bold">
                            Un total de {{ explain.repartition_presence.montant_total | roundEuroFilter }}
                        </span>
                        <span> soit </span>
                        <span class="font-weight-bold">
                            {{ explain.repartition_presence.montant_unitaire * 1000 | roundEuroFilter }}
                        </span>
                        <span> pour 1 000 heures de présence sur l'année</span>
                    </div>
                </div>
                <br/>
                <div v-if="explain.repartition_anciennete && explain.repartition_anciennete.cle">
                    <div class="title">Répartition selon l'anciennete</div>
                    <br/>
                    <div>
                        <span>Clé de répartition : </span>
                        <span
                            class="font-weight-bold">{{explain.repartition_anciennete.cle | roundPercentFilter }}</span>
                        <br/>
                        <template v-for="rule in explain.repartition_anciennete.regles">
                            <div :key="rule.coefficient">
                                <span class="font-weight-bold">
                                    Un sous-total de {{rule.montant_total | roundEuroFilter}}
                                </span>
                                <span> avec </span>
                                <span class="font-weight-bold">
                                    {{rule.montant_unitaire | roundEuroFilter }}
                                </span>
                                <span> par bénéficiaire à partir de {{rule.anciennete}} mois d'ancienneté</span>
                                <v-divider/>
                            </div>
                        </template>
                    </div>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-row class="mx-0" justify="end">
                    <!-- Bouton modal -->
                    <v-col md="4" lg="4" xl="4">
                        <v-btn color="primary" text @click="dialogDetail = false" block>
                            Fermer
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /**
     * Composant affichant le détail des calculs pour une répartition pb
     * @display_name Participation - Détail de Répartition Pb
     */
    export default {
        name: "DetailRepartitionPb",
        props: {
            /**
             * Informations détaillant le calcul
             */
            explain: {
                type: Object
            },
            /**
             * Est désactivé
             */
            disabledValue: {
                type: Boolean
            }
        },
        data() {
            return {
                dialogDetail: false
            }
        }
    }
</script>