import defaultApi from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default deblocageTermeApi;

function deblocageTermeApi() {
  const specificApi = {
    getMontantDeblocageNetTerme: getMontantDeblocageNetTerme(),
    getDeblocagesGroupes: getDeblocagesGroupes(),
    postDeblocagesGroupes: postDeblocagesGroupes(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.deblocagesTermes.baseUrl));
}

function getMontantDeblocageNetTerme(){
  function getMontantDeblocageNetTerme(beneficiaire_id, support_id, montant, date_operation, taux_de_remuneration_interets_courus, queryParams, headers){
    let path = internalApiRoutes.deblocagesTermes.getMontantDeblocageNetTerme();
    queryParams = queryParams || {};
    queryParams.beneficiaire_id = beneficiaire_id;
    queryParams.support_id = support_id;
    queryParams.montant = montant;
    queryParams.date_operation = date_operation;
    queryParams.taux_de_remuneration_interets_courus = taux_de_remuneration_interets_courus;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getMontantDeblocageNetTerme;
}


function getDeblocagesGroupes(){
  function getDeblocagesGroupesInfos(queryParams, headers){
    let path = internalApiRoutes.deblocagesTermes.getDeblocagesGroupes();
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getDeblocagesGroupesInfos;
}



function postDeblocagesGroupes(){
  function postDeblocagesGroupesInfos(body, queryParams, headers){
    let path = internalApiRoutes.deblocagesTermes.getDeblocagesGroupes();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return postDeblocagesGroupesInfos;
}