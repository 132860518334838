// <!-- VUETIFY2 - OK -->
import SupportInteressementAbstract from "./SupportInteressementAbstract";
import internalApi from "@/api/internalApi";

export default {
  extends: SupportInteressementAbstract,
  abstract: false,
  data() {
    return {
      isCreation: true,
      pageTitle: "Créer un support d'intéressement",
    };
  },
  methods: {
    submit() {
      this.$refs.formSupportInteressement.validate().then(
        success => {
          if (success) {

            const cleaned_support = this.getCleanedSupport();

            internalApi.inteSupport.postAllWithToastConfirmation(cleaned_support)
              .then(res => {
                this.cancel();
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
      );
    }
  }
};