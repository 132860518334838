import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default affectationInteApi;

function affectationInteApi() {
  return {
    getInteAffectationByInteExerciceId: getInteAffectationByInteExerciceId(),
    getInteAffectationIndividuelByInteExerciceId: getInteAffectationIndividuelByInteExerciceId(),
    saveInteAffectationByInteExerciceId: saveInteAffectationByInteExerciceId(),
    saveInteAffectationIndividuelByInteExerciceId: saveInteAffectationIndividuelByInteExerciceId(),
    validateInteAffectationByInteExerciceId: validateInteAffectationByInteExerciceId(),
    getTemplateImportInteAffectationByInteExerciceId: getTemplateImportInteAffectationByInteExerciceId(),
    importInteAffectationByInteExerciceId: importInteAffectationByInteExerciceId(),
    downloadInteAffectationByInteExerciceId: downloadInteAffectationByInteExerciceId(),
    computeInteVersementImmediatNet: computeInteVersementImmediatNet(),
  }
}

function getInteAffectationByInteExerciceId() {
    function getInteAffectationByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectation(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return getInteAffectationByInteExerciceId;
}

function getInteAffectationIndividuelByInteExerciceId() {
    function getInteAffectationIndividuel(inteExerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationIndividuel(inteExerciceId);
        queryParams = queryParams || {};
        queryParams.page = page;
        queryParams.rows_per_page = rowsPerPage;
        queryParams.sort_key = sortBy;
        queryParams.descending = descending;
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return getInteAffectationIndividuel;
}

function saveInteAffectationByInteExerciceId() {
    function saveInteAffectationByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectation(inteExerciceId);
        let postForPath = apiConstructor.post(path);
        return postForPath(queryParams, headers).then(result => {
            TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
            return Promise.resolve(result);
        });
    }

    return saveInteAffectationByInteExerciceId;
}

function saveInteAffectationIndividuelByInteExerciceId() {
    function saveInteAffectationIndividuelByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationIndividuel(inteExerciceId);
        let postForPath = apiConstructor.post(path);
        return postForPath(queryParams, headers).then(result => {
            TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
            return Promise.resolve(result);
        });
    }

    return saveInteAffectationIndividuelByInteExerciceId;
}

function validateInteAffectationByInteExerciceId() {
    function validateInteAffectationByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationValidation(inteExerciceId);
        let postForPath = apiConstructor.post(path);
        return postForPath(queryParams, headers).then(result => {
            TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
            return Promise.resolve(result);
        });
    }

    return validateInteAffectationByInteExerciceId;
}

function getTemplateImportInteAffectationByInteExerciceId() {
    function getInteAffectationImportTemplateByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationImport(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return getInteAffectationImportTemplateByInteExerciceId;
}

function importInteAffectationByInteExerciceId() {
    function importInteAffectationByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationImport(inteExerciceId);
        let postForPath = apiConstructor.post(path);
        return postForPath(queryParams, headers).then(result => {
            TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
            return Promise.resolve(result);
        });
    }

    return importInteAffectationByInteExerciceId;
}

function downloadInteAffectationByInteExerciceId() {
    function downloadInteAffectationByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.inteExercicesInteAffectationDownload(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return downloadInteAffectationByInteExerciceId;
}

function computeInteVersementImmediatNet() {
    function computeInteVersementImmediatNet(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.affectation.computeInteVersementImmediatNet(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return computeInteVersementImmediatNet;
}