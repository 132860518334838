// <!-- VUETIFY2 - OK -->
import VScicSousCategorie from "./VScicSousCategorie"

export default {
  name: "v-scic-sous-categorie-creation",
  extends: VScicSousCategorie,
  computed: {
    isModif() {
      return false;
    },
    title() {
      return "Créer une sous-catégorie d'associé";
    },
    openButtonMessage() {
      return "Créer une sous-catégorie d'associé";
    },
  },
  methods: {
    submit() {
      let data = _.cloneDeep(this.sousCategorie);
      this.$emit("add-scic-sous-categorie", data);
      this.closePopUp();
    }
  }
};