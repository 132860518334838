import Utils from "@/utils.js";

function ChartsUtils() {
}

ChartsUtils.fixedColorList = ["#5899DA", "#E8743B", "#19A979", "#945ECF", "#13A4B4", "#525DF4", "#BF399E", "#6C8893", "#EE6868", "#2F6497", "#ED4A7B", "#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#0f34e1", "#718e07", "#9bdb8f", "#41ffe2", "#673e13", "#fd7b9f", "#d43c0b", "#38a8c4", "#82446d", "#54eea0", "#3acbbf", "#11906f", "#f0c4b4", "#bcd161", "#3c3e84", "#dc49fa", "#8a8624", "#57b594", "#41435c", "#720c44", "#2acc56", "#51b62e", "#f2aec6", "#0d7173", "#94be37", "#5086c0", "#cdd070", "#bb95eb", "#02b2b9", "#7a3256", "#008794", "#76ea76", "#5db2f4", "#5004b1", "#4054bc", "#196bd9", "#8b0e7e", "#2a3444", "#3d6d56", "#b984fb", "#53afab", "#bf3150", "#1d5a2c", "#d8c1c3", "#e3c1f4"];

function formatData(percent, montant, nbParts) {
  return percent + " %, " + Utils.separerMilierParEspace(String(montant)) + " €, " + nbParts + " parts";
}

/**
 * Mets en forme les données pour un histogramme ou un graphique circulaire
 * @param chartDatas
 * @param displayLegend : affiche ou non la légende (false pour un histogramme)
 * @param displaySmallLabel : c'est le code ou le libelle qui est affiché en bas
 * @param startZero : indique si l'axe des Y part de zéro
 * @return {{data: {datasets: {backgroundColor: Array, data: Array, tooltip_detail: Array, percent: Array, labels: Array}[], labels: Array}, options: {legend: {display: *, position: string}, plugins: {datalabels: {formatter: (function(*, *): string), color: string, anchor: string, display: string, font: {weight: string}}}, responsive: boolean, scales, title: {display: boolean, position: string, text: string}, maintainAspectRatio: boolean, tooltips: {callbacks: {label: (function(*, *): *)}, position: string}}}}
 */
ChartsUtils.getBarPieOptions = function (chartDatas, displayLegend, displaySmallLabel, startZero, limitValue) {
  let labels_list = [];
  let data_list = [];
  let detailed_label_list = [];
  let colorList = [];
  let percentList = [];
  let tooltip_detail_list = [];
  let limit = !_.isNil(limitValue) ? limitValue : chartDatas.data.length;
  // on génère X premières valeurs à afficher
  for (let i = 0; i < Math.min(limit, chartDatas.data.length); i++) {
    let currentItemDatas = chartDatas.data[i];
    const data = currentItemDatas.data;
    if (data) {
      labels_list.push(displaySmallLabel ? currentItemDatas.code : currentItemDatas.libelle);
      data_list.push(data);
      percentList.push(currentItemDatas.percent);
      const default_view = (currentItemDatas.code !== "" ? currentItemDatas.code + " : " : "") + currentItemDatas.tooltip;
      detailed_label_list.push(default_view);
      tooltip_detail_list.push(default_view);
      // on gère la couleur pour avoir une cohérence entre les graphiques
      colorList.push(this.fixedColorList[i]);
    }
  }

  // on gère l'ellagage pour la partie affichage : on somme tous les éléments après la limite pour les mttre dans un unique élément
  let detail = [];
  let total_ellage = 0;
  let total_parts = 0;
  for (let i = limit; i < chartDatas.data.length; i++) {
    let aData = chartDatas.data[i];
    if (aData.percent > 0) {
      detail.push(aData.libelle + " : " + aData.tooltip);
      total_ellage += aData.data;
    }
  }
  // si il y a bien eu un ellagae
  if (total_ellage > 0) {
    labels_list.push("Autre");
    data_list.push(total_ellage);
    const percent = Utils.formatNumber((total_ellage / chartDatas.total) * 100);
    percentList.push(percent);
    detailed_label_list.push(formatData(percent, total_ellage, total_parts));
    tooltip_detail_list.push(detail);
    colorList.push(this.fixedColorList[limit + 1]);
  }
  // on gère l'affichage du zéro sur l'axe des Y ou non
  let scaleDisplay = {};
  if (startZero) {
    scaleDisplay = {
      yAxes: [
        {
          ticks:
            {
              beginAtZero: true
            }
        }],
      xAxes: [{
        ticks:
          {
            autoSkip: false,
            maxTicksLimit: 20
          }
      }]
    };
  }
  return {
    data: {
      labels: labels_list,
      datasets: [
        {
          labels: detailed_label_list,
          backgroundColor: colorList,
          data: data_list,
          tooltip_detail: tooltip_detail_list,
          percent: percentList
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: displayLegend,
        position: "bottom",
      },
      title: {
        display: true,
        position: "bottom",
        text: chartDatas.total_message
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return data.datasets[tooltipItem.datasetIndex].tooltip_detail[tooltipItem.index];
          },
        },
        position: "cursor"
      },
      xAxes: [{
        ticks: {
          autoSkip: false,
          maxTicksLimit: 20
        }
      }],
      scales: scaleDisplay,
      plugins: {
        datalabels: {
          color: "black",
          anchor: "center",
          display: "auto",
          font: {
            weight: 'bold'
          },
          formatter: function (value, context) {
            return context.dataset.percent[context.dataIndex] + " %";
          }
        }
      }
    }
  };
};


/**
 * Exporte les données en fichier excel
 * @param data
 * @param title
 */
ChartsUtils.getBarPieExportExcel = function (data, title, date, withDetails) {
  let headers = [
    {
      text: "Libelle",
      value: "libelle"
    },
    {
      text: "Pourcentage",
      value: "percent"
    },
    {
      text: "Données",
      value: "data"
    }
  ];

  if (withDetails) {
    headers.push({
        text: "Détail",
        value: "tooltip"
    });
  }

  function flattenData(inputData, withDetails) {
    let returnValue = [];
    for (let i = 0; i < inputData.data.length; i++) {
      const pie_slice = inputData.data[i];
      let flattenPieSlice = {
        "libelle": pie_slice.libelle,
        "percent": pie_slice.percent,
        "data": pie_slice.data,
      };
      if (withDetails) {
        flattenPieSlice["tooltip"] = tooltip;
      }
      returnValue.push(flattenPieSlice);
    }
    return returnValue;
  }

  const items = flattenData(data, withDetails);

  let ref = {
    _props: {
      headers: headers,
      items: items
    }
  };
  let workbookProps = {
    Title: title,
    Subject: title,
    Author: "eZIScop",
    CreatedDate: Date.now()
  };
  let sheetTitle = title.substring(0, 30);
  let fileTitle = title + " " + date + ".xlsx";
  Utils.writeXlsxFromDatatableRef(
    ref,
    workbookProps,
    sheetTitle,
    fileTitle
  );
};

/**
 * Génère le fichier PNG du graphique actuel
 * @param title
 * @param chart
 */
ChartsUtils.generatePng = function (title, chart) {

  let base64PngData = chart.toBase64Image();
  const link = document.createElement("a");
  link.href = base64PngData;
  link.setAttribute("download", title + ".png");
  document.body.appendChild(link);
  link.click();
};

export default ChartsUtils;