import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default souscriptionApi;

function souscriptionApi() {
  const specificApi = {
    associe: defaultApi(internalApiRoutes.souscriptionAssocie, [ACCEPTED_METHODS.getById]),
    postBulletinSoucriptionLibre: postBulletinSoucriptionLibreById(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.souscriptions));
}


function postBulletinSoucriptionLibreById() {
  function bulletin(body, queryParams, headers) {
    let path = internalApiRoutes.brouillonBulletinSouscription;
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return bulletin;
}