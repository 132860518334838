import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import app_icons from "@/icon";
import fr from "vuetify/es5/locale/fr";

export const vuetifyOpts = {
  theme: {
    themes: {
      light: {
        primary: "#00B5D8", // couleur gamme chromatique e-COSI
        secondary: "#424242", // thème Vuetify par défaut
        accent: "#82B1FF", // thème Vuetify par défaut
        error: "#FF5252", // thème Vuetify par défaut
        info: "#2196F3", // thème Vuetify par défaut
        success: "#4CAF50", // thème Vuetify par défaut
        warning: "#F7A80A" // couleur plus sombre pour améliorer la lisibilité avec des textes blancs
//        warning: "#FFC107" // thème Vuetify par défaut
      }
    }
  },
  lang: {
    locales: {fr},
    current: "fr"
  },
  icons: {
    iconfont: "fa",
    values: app_icons
  }
};
Vue.use(Vuetify);

export default new Vuetify(vuetifyOpts);