<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate }">
        <loading-screen ref="loadingComponent"/>
        <!-- Fenêter modale -->
        <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
                <v-row justify="end" no-gutters>
                    <span v-on="on" class="text-decoration-underline row-pointer">Mot de passe oublié ?</span>
                </v-row>
            </template>
            <v-card>
                <v-toolbar dark color="primary" flat>
                    <v-toolbar-title>Réinitialisation du mot de passe</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <span>Un nouveau mot de passe généré aléatoirement vous sera envoyé par e-mail.</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ValidationProvider vid="username" name="Identifiant"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-text-field name="username"
                                            label="Identifiant"
                                            v-model="username"
                                            :error-messages="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" no-gutters>
                        <v-col cols="4">
                            <v-btn color="secondary" @click="close" block>Fermer</v-btn>
                        </v-col>
                        <v-col cols="4" class="ml-2">
                            <v-btn color="primary" @click="request_password_change(validate)" block>Réinitialiser</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </ValidationObserver>
</template>
<style scoped>
    .row-pointer {
        cursor: pointer;
    }
</style>
<script>

import internalApi from "@/api/internalApi";
import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";

export default {
    name: "v-forgot-password",
    components: {
        loadingScreen,
    },
    data() {
        return {
            dialog: false,
            username: ""
        }
    },
    methods: {
        request_password_change(validate) {
            validate().then(
                success => {
                    if(success){
                        this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
                        internalApi.user.forgotPassword(this.username, this.$route.params.tenant).then(() => {
                            this.close();
                        })
                        .finally(() => {
                            this.$refs.loadingComponent.resetLoading()
                        });
                        
                    }
                }
            )
            
        },
        close() {
            this.resetDialog();
        },
        resetDialog() {
            this.dialog = false;
            this.username = "";
        }
    }
}
</script>
