<template>
<v-container fluid :class="{grey: !hasScicExtensionAccess(), 'lighten-2': !hasScicExtensionAccess(), 'pl-0':1, 'pr-0':1}">
    <v-subheader><h3>SCIC</h3></v-subheader>
    <v-row no-gutters justify="space-between">
        <v-col md="5" lg="5" xl="5">
            <ValidationProvider vid="localCategorieAssocie"
                    name="Catégorie d'associé"
                    :rules="{ required: (isEditable && hasScicExtensionAccess())}"
                    v-slot="{ errors }">
                <v-scic-param-categorie-list :isDisabled="!hasScicExtensionAccess() || !isEditable"
                                            @update-scic-categorie-associe="localScicCategorieAssocie = $event"
                                            :scicCategorieAssocie="localScicCategorieAssocie"
                                            v-model="localScicCategorieAssocie"
                                            :errors="errors"/>
            </ValidationProvider>
        </v-col>
        <v-col md="5" lg="5" xl="5">
            <ValidationProvider vid="localSousCategorieAssocie"
                    name="Sous catégorie d'associé"
                    v-slot="{ errors }">
                <v-scic-sous-categorie-list :isDisabled="!hasScicExtensionAccess() || !isEditable"
                                            @update-scic-sous-categorie-associe="localScicSousCategorieAssocie = $event"
                                            :scicCategorieAssocie="localScicCategorieAssocie"
                                            :scicSousCategorieAssocie="localScicSousCategorieAssocie"
                                            v-model="localScicSousCategorieAssocie"
                                            :errors="errors"/>
            </ValidationProvider>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col md="5" lg="5" xl="5">
            <ValidationProvider vid="localCollegeAssocie"
                    name="Collège d'associé"
                    v-slot="{ errors }">
                <v-scic-college-list :isDisabled="!hasScicExtensionAccess() || !isEditable"
                                            @update-scic-college-associe="localScicCollegeAssocie = $event"
                                            :scicCollegeAssocie="localScicCollegeAssocie"
                                            v-model="localScicCollegeAssocie"
                                            :errors="errors"/>
            </ValidationProvider>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
    import VScicParamCategorieList from "@/components/scic/VScicParamCategorieList.vue";
    import VScicSousCategorieList from "@/components/scic/VScicSousCategorieList.vue";
    import VScicCollegeList from "@/components/scic/VScicCollegeList.vue";
    import authService from "@/services/authService";

    /**
     * Composant affichant un encart avec les données spécifiques à un associé SCIC.
     * @displayName Scic - Données associé
     */
    export default {
        name: "VScicDonneesAssocie",
        components: {
            VScicParamCategorieList,
            VScicSousCategorieList,
            VScicCollegeList,
        },
        props: {
            /**
             * La catégorie sélectionnée
             */
            scicCategorieAssocie: {
                type: String,
            },
            /**
             * La sous catégorie sélectionnée
             */
            scicSousCategorieAssocie: {
                type: String,
            },
            /**
             * Le collège sélectionné
             */
            scicCollegeAssocie: {
                type: String,
            },
            /**
             * Si la modification des données est autorisée
             */
            isEditable: {
                type: Boolean,
            }
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            }
        },
        computed: {
            localScicCategorieAssocie: {
                get: function () {
                    return this.scicCategorieAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-categorie-associe", newvalue);
                }
            },
            localScicSousCategorieAssocie: {
                get: function () {
                    return this.scicSousCategorieAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-sous-categorie-associe", newvalue);
                }
            },
            localScicCollegeAssocie: {
                get: function () {
                    return this.scicCollegeAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-college-associe", newvalue);
                }
            },
        },
    }
</script>