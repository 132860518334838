<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formNewUserAccess" v-slot="{ errors, validate, reset }">
        <base-layout headerSize="80px">
            <template slot="header">
                <h1>Créer un accès utilisateur</h1>
            </template>
            <template slot="main">
                <br/>
                <v-subheader><h2>Tiers pour qui créer le compte</h2></v-subheader>
                <v-container class="pt-0" grid-list-xl fluid>
                    <v-row>
                        <v-col md="6" lg="6" xl="3">
                            <ValidationProvider vid="personne" name="Personne" rules="required" v-slot="{ errors }" :disabled="isTechnicalUser">
                                <v-personnes-list v-model="localPersonneId"
                                                @input="localPersonneId = $event"
                                                :errors="errors"
                                                :noUserFilter="true"
                                                :isDisabled="isTechnicalUser"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col md="6" lg="6" xl="3">
                            <v-checkbox v-model="isTechnicalUser" label="Utilisateur technique" @change="updateTechnicalUser($event)" ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row no-gutters>
                    <v-col md="6" lg="6" xl="3">
                        <v-subheader><h2>Données de connexion</h2></v-subheader>
                        <v-container class="pt-0">
                            <v-row>
                                <v-col md="12" lg="12" xl="12">
                                    <ValidationProvider vid="username" name="Nom d'utilisateur" rules="required|max:100" v-slot="{ errors }">
                                        <v-text-field v-model="username"
                                                    label="Nom d'utilisateur"
                                                    :error-messages="errors"/>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col md="6" lg="6" xl="6">
                        <v-new-password-text-and-confirmation
                            v-model="password"/>
                    </v-col>
                </v-row>
            </template>
            <template slot="footer">
                <v-row justify="end" no-gutters>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :showToast="false"
                                                    :resetForm="reset"
                                                    :validateForm="validate"
                                                    :errorsList="errors"
                                                    @emit-cancel="returnToDroitsAcces"
                                                    @emit-valid-save="onCreateNewUseracces"
                                                    saveMessage="Créer un accès utilisateur"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import _ from "lodash";
    import VNewPasswordTextAndConfirmation from "@/components/administration/VNewPasswordTextAndConfirmation";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import BaseLayout from "@/components/BaseLayout";
    import internalApi from "@/api/internalApi";
    import VPersonnesList from "../../components/VPersonnesList";

    export default {
        name: "newUserAccess",
        components: {
            VPersonnesList,
            BaseLayout, VNewPasswordTextAndConfirmation, VSaveButonWithValidation
        },
        data() {
            return {
                isTechnicalUser: false,
                password: "",
                username: "",
                personne_id: undefined,
            };
        },
        methods: {
            returnToDroitsAcces() {
                this.$router.push({
                    name: "droits_acces"
                });
            },
            onCreateNewUseracces() {
                const body = {
                    "username": this.username,
                    "password": this.password,
                    "personne_id": this.personne_id
                };
                internalApi.user.postAllWithToastAsynchronous(body).then(() => {
                    this.returnToDroitsAcces();
                }).catch(err => {
                    console.log(err);
                });
            },
            updateTechnicalUser(event){
                this.isTechnicalUser = event;
                if(this.isTechnicalUser) {
                    this.localPersonneId = null;
                }
            }
        },
        computed: {
            localPersonneId: {
                get: function(){
                    return this.personne_id;
                },
                set: function(newvalue){
                    this.personne_id = newvalue;
                }
            }
        }
    }
</script>