import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default exerciceApi;

function exerciceApi() {
  const specificApi = {
    getExercicesEnumByName: getExercicesEnumByName(),
    exportPeriodesTravailForExerciceExcel: exportPeriodesTravailForExerciceExcel(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.exercices.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById,
    ACCEPTED_METHODS.getLabels,
    ACCEPTED_METHODS.postAll,
  ]));
}

function getExercicesEnumByName() {
  function getExercicesEnumByName(queryParams, headers) {
    let path = internalApiRoutes.exercices.getExercicesEnumByName();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getExercicesEnumByName;
}

function exportPeriodesTravailForExerciceExcel() {
  function exportPeriodesTravailForExerciceExcel(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.exercices.exportPeriodesTravailForExerciceExcel(exerciceId);
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportPeriodesTravailForExerciceExcel;
}
