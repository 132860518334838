import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";

export default scicDocumentsApi;

function scicDocumentsApi() {
  return {
    getAttestationFiscale: getAttestationFiscale(),
    getAttestationFiscaleForAssocieList: getAttestationFiscaleForAssocieList(),
  };
}


function getAttestationFiscale(){
  function getAttestationFiscaleForAssocie(exercice_id, queryParams, headers){
    let path = internalApiRoutes.scicDocuments.getAttestationFiscale(exercice_id);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getAttestationFiscaleForAssocie;
}
function getAttestationFiscaleForAssocieList(){
  function getAttestationFiscaleForList(body, queryParams, headers){
    let path = internalApiRoutes.scicDocuments.getAttestationFiscaleForAssocieList();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers);
  }
  return getAttestationFiscaleForList;
}