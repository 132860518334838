<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors: errorsForm, reset }" ref="form">
        <base-layout datatableExcludedSize="50vh" headerSize="50px">
            <template v-slot:header>
                <h1>{{title}}</h1>
            </template>
            <template v-slot:main="{ datatableSize }">
                <loading-screen ref="loadingComponent"/>
                <v-container fluid>
                    <v-row justify="space-between" no-gutters>
                        <v-col md="5" lg="5" xl="5">
                            <ValidationProvider v-if="!isEspaceBeneficiaire"
                                                vid="beneficiaire_id"
                                                name="Bénéficiaire"
                                                rules="required"
                                                v-slot="{ errors }">
                                <v-beneficiaires-list v-model="deblocage.beneficiaire_id"
                                                      label="Bénéficiaire"
                                                      :errors="errors"
                                                      :disabled="!isEditable"
                                />
                            </ValidationProvider>
                            <v-text-field v-else
                                          :value="beneficiaire_name"
                                          label="Bénéficiaire"
                                          disabled
                            />
                        </v-col>
                        <v-col md="5" lg="5" xl="5">
                            <ValidationProvider vid="date_mouvement"
                                                name="Date de l'opération"
                                                rules="required"
                                                v-slot="{ errors }">
                                <date-field v-model="deblocage.date_mouvement"
                                            label="Date de l'opération"
                                            :errors="errors"
                                            :clearable="false"
                                            :disabled="!isEditable"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                        <v-col md="5" lg="5" xl="5">
                            <v-pb-supports-list v-model="deblocage.support_id"
                                             withDefaultValue
                                             label="Support"
                                             :supportFilter="'actif_placement_interne'"
                                             :isEnabled="isEditable"
                            />
                        </v-col>
                        <v-col cols="5">
                            <v-taux-remu-for-deblocage  :tauxRemu="deblocage.taux_de_remuneration_interets_courus"
                                                        :isEditable="isEditable"
                                                        :dateReferenceTauxRemu="deblocage.date_mouvement"
                                                        @update-taux-remu="deblocage.taux_de_remuneration_interets_courus = $event"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="isEditable" no-gutters>
                        <!-- On veut obtenir un aperçu des montants disponibles à terme, donc on ne passe pas la dateOperation -->
                        <v-creation-mouvement-supports-simplifie :beneficiaireId="deblocage.beneficiaire_id"
                                                                 :tauxRemu="deblocage.taux_de_remuneration_interets_courus"
                                                                 :supportId="deblocage.support_id"
                                                                 :dateOperation="deblocage.date_mouvement"
                                                                 :aTerme="true"
                                                                 :isEditable="isEditable"
                                                                 :height="datatableSize"
                                                                 :isEspaceBeneficiaire="isEspaceBeneficiaire"
                                                                 ref="mouvementsSupports"/>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                        <v-col md="5" lg="5" xl="5">
                            <ValidationProvider vid="motif" name="motif"
                                                rules="required" v-slot="{ errors }">
                                <v-select v-model="deblocage.motif"
                                          :items="motifItems"
                                          label="Motif"
                                          :error-messages="errors"
                                          clearable
                                          :disabled="!isEditable"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col md="5" lg="5" xl="5">
                            <ValidationProvider vid="date_evenement" name="date de l'évènement"
                                                rules="required" v-slot="{ errors }">
                                <date-field v-model="deblocage.date_evenement"
                                            label="Date de l'évènement"
                                            :errors="errors"
                                            :disabled="!isEditable"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="5">
                            <v-row no-gutters align="center">
                                <v-col>
                                    <ValidationProvider vid="montant_debloque" name="montant débloqué"
                                                        rules="required|currency|min_value:0" v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="deblocage.montant"
                                                                    prefixValue="€"
                                                                    labelValue="Montant brut débloqué"
                                                                    :reverseValue="true"
                                                                    :errors="errors"
                                                                    :disabledValue="!isEditable"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="ml-3 flex-grow-0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small right v-on="{...on}">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>Le montant débloqué est prélevé en priorité sur la participation placée, puis sur les intérêts les plus anciens</span>
                                    </v-tooltip>
                                </v-col> 
                            </v-row>
                            <v-row no-gutters justify="space-between" v-if="!isEspaceBeneficiaire && isEditable">
                                <v-col cols="5">
                                    <v-tooltip bottom :disabled="canComputeMontantNet">
                                        <template v-slot:activator="{ on }">
                                        <div v-on="on" class="d-inline-block">
                                            <v-btn @click="computeCurrentMontantNet" color="primary" :disabled="!canComputeMontantNet">
                                                Générer le montant net
                                            </v-btn>
                                        </div>
                                        </template>
                                        <span>{{ textMissingDataMontantNet }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn text @click="solderPortefeuille" color="primary" block>
                                        Solder le portefeuille
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-spacer/>

                        <v-col cols="5" v-if="!isEspaceBeneficiaire && isEditable">
                            <v-row no-gutters align="center" v-if="deblocage.motif === 'acquisition_parts_sociales_scop' && capCompany.capital_montant_souscription_part == undefined">
                                <v-col>
                                    <ValidationProvider vid="deblocage_montant_souscription_part" name="Montant d'acquisition de la part sociale"
                                                        :rules="{ required: deblocage.motif === 'acquisition_parts_sociales_scop' && capCompany.capital_montant_souscription_part == undefined, currency: true, min_value: 0, excluded: 0 }"
                                                        v-slot="{ errors }">
                                        <v-formatted-number-text-field v-model="deblocage.montant_souscription_part"
                                                                       @change="resetDetailMontant"
                                                                       prefixValue="€"
                                                                       labelValue="Montant d'acquisition de la part sociale"
                                                                       :reverseValue="true"
                                                                       :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters align="center">
                                <v-col>
                                    <ValidationProvider vid="montant_deblocage_net" name="montant net du déblocage"
                                                        rules="required|currency|min_value:0" v-slot="{  }">
                                        <v-formatted-number-text-field v-model="montantDeblocageNet"
                                                                        prefixValue="€"
                                                                        labelValue="Montant net du déblocage"
                                                                        :reverseValue="true"
                                                                        :disabledValue="true"
                                                                        :persistentHintValue="displayMontantNetHint"
                                                                        :hintValue="valueMontantNetHint"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <!-- Info bulle -->
                                <v-col class="ml-3 flex-grow-0">
                                    <v-tooltip-table-taxe :detail="detail"/>
                                </v-col>
                            </v-row>
                            <div style="height: 64px;">
                                <template v-if="canShowConversionPartsSocialesDetail">
                                    <span>
                                        Montant converti en parts sociales : {{ detail.montant_converti_parts_sociales | roundCurrencyFilter }} €
                                        ({{ detail.nb_parts_sociales }} part(s) × {{ deblocage.montant_souscription_part | roundCurrencyFilter }} €)
                                    </span>
                                    <br/>
                                    <span>
                                        Montant remboursé : {{ detail.montant_rembourse | roundCurrencyFilter }} €
                                    </span>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row no-gutters justify="end">
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errorsForm"
                                                      @emit-cancel="onBackClick"
                                                      @emit-valid-save="submitData"
                                                      :disabledValue="!isEditable"/>

                        <v-dialog v-model="confirmationDialog" persistent max-width="600px">
                            <v-card round>
                                <ValidationObserver v-slot="{ handleSubmit }">
                                    <v-card-title class="headline grey lighten-2" primary-title>
                                        Créer les souscriptions
                                    </v-card-title>
                                    <v-divider/>
                                    <v-card-text>
                                        <p>Résumé de l'opération :</p>
                                        <ul>
                                            <li>
                                                Montant du déblocage net : {{ montantDeblocageNet | roundCurrencyFilter }} €
                                            </li>
                                            <li>
                                                Montant converti en parts sociales : {{ detail.montant_converti_parts_sociales | roundCurrencyFilter }} € ({{ detail.nb_parts_sociales }} part(s) × {{ deblocage.montant_souscription_part | roundCurrencyFilter }} €)
                                            </li>
                                            <li>
                                                Montant remboursé : {{ detail.montant_rembourse | roundCurrencyFilter }} €
                                            </li>
                                        </ul>
                                        <p style="margin-bottom: 0px; margin-top: 16px;">Veuillez sélectionner la catégorie de capital qui fera l'objet des souscriptions :</p>
                                        <ValidationProvider vid="categorieSouscription"
                                                            name="Catégorie de capital"
                                                            :rules="{ required: confirmationDialog }"
                                                            v-slot="{ errors }">
                                            <v-categories-capital-list v-model="deblocage.categorie_souscription"
                                                                       :errors="errors"/>
                                        </ValidationProvider>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="end" dense>
                                            <!-- Bouton modal -->
                                            <v-col md="4" lg="4" xl="4">
                                                <v-btn @click="onCancelClick" block>Annuler
                                                </v-btn>
                                            </v-col>
                                            <!-- Bouton modal -->
                                            <v-col md="4" lg="4" xl="4">
                                                <v-btn color="primary" :disabled="!deblocage.categorie_souscription"
                                                       @click="handleSubmit(validerSouscription)" block>
                                                    Confirmer
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </ValidationObserver>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import VCreationMouvementSupportsSimplifie from "@/components/mouvementsSupports/VCreationMouvementSupportsSimplifie";
    import DateField from "@/components/DateField";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation";
    import VBeneficiairesList from "@/components/VBeneficiairesList";
    import VPbSupportsList from "@/components/supportsList/VPbSupportsList";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import VSelect from "vuetify/lib/components/VSelect/VSelect";
    import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";
    import VTooltipTableTaxe from "@/components/tooltip/VTooltipTableTaxe"
    import VTauxRemuForDeblocage from "@/components/tauxRemunerationSupport/VTauxRemuForDeblocage"
    import authService from "@/services/authService";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";

    export default {
        name: "DeblocageAnticipe",
        components: {
            VSelect,
            VSaveButonWithValidation,
            VCreationMouvementSupportsSimplifie,
            BaseLayout,
            DateField,
            VBeneficiairesList,
            VFormattedNumberTextField,
            VPbSupportsList,
            VCategoriesCapitalList,
            VTooltipTableTaxe,
            VTauxRemuForDeblocage,
            loadingScreen,
        },
        data() {
            return {
                confirmationDialog: false,
                isEditable: true,
                isEspaceBeneficiaire: false,
                deblocage: {
                    id: undefined,
                    support_id: undefined,
                    support_code: undefined,
                    beneficiaire_id: undefined,
                    date_mouvement: null,  // Pour éviter le rechargement quand on focus la date
                    montant: undefined,
                    motif: undefined,
                    date_evenement: undefined,
                    categorie_souscription: undefined,
                    taux_de_remuneration_interets_courus: undefined,
                    montant_souscription_part: undefined,
                },
                motifItems: undefined,
                detail: {
                    montant_interets_echus_brut: 0,
                    montant_interets_courus_brut: 0,
                    montant_charges_sociales: 0,
                    montant_prelevement_forfaitaire: 0,
                    montant_converti_parts_sociales: null,
                    nb_parts_sociales: null,
                    montant_rembourse: null,
                    montant_total_interets_net: 0,
                },
                montantDeblocageNet: undefined,
                capCompany: {
                    capital_montant_souscription_part: undefined,
                },
            }
        },
        computed: {
            title() {
                return "Déblocage anticipé";
            },
            displayMontantNetHint() {
                const displayable_hints = ["mesure_urgence_pouvoir_achat_2022", "acquisition_parts_sociales_scop"];
                return displayable_hints.includes(this.deblocage.motif);
            },
            valueMontantNetHint() {
                if (this.deblocage.motif === "mesure_urgence_pouvoir_achat_2022") {
                    return "Pour ce motif, le montant net ne doit pas dépasser 10 000€";
                } else if (this.deblocage.motif === "acquisition_parts_sociales_scop") {
                    return "Seul le reliquat de la conversion en parts sociales sera remboursé";
                }
                return undefined;
            },
            canComputeMontantNet() {
                let canCompute = true
                let fields =  [
                    this.deblocage.taux_de_remuneration_interets_courus,
                    this.deblocage.date_mouvement,
                    this.deblocage.montant,
                    this.deblocage.beneficiaire_id,
                    this.deblocage.support_id,
                ];
                fields.forEach(function (element) {
                    if (_.isNil(element)) {
                        canCompute = false;
                    }
                });
                // Vérifier qu'on a bien un montant de part sociale
                if (this.deblocage.motif == "acquisition_parts_sociales_scop") {
                    canCompute = canCompute && (!_.isNil(this.deblocage.montant_souscription_part) || !_.isNil(this.capCompany.capital_montant_souscription_part))
                }
                return canCompute;
            },
            textMissingDataMontantNet() {
                let text = "Veuillez renseigner ";
                let missingData = [];
                if (_.isNil(this.deblocage.beneficiaire_id)){
                    missingData.push("le bénéficiaire");
                }
                if (_.isNil(this.deblocage.support_id)){
                    missingData.push("le support");
                }
                if (_.isNil(this.deblocage.date_mouvement)){
                    missingData.push("la date du déblocage");
                }
                if (_.isNil(this.deblocage.taux_de_remuneration_interets_courus)){
                    missingData.push("le taux de rémunération");
                }
                if (_.isNil(this.deblocage.montant)){
                    missingData.push("le montant à débloquer");
                }
                if (this.deblocage.motif == "acquisition_parts_sociales_scop" && _.isNil(this.deblocage.montant_souscription_part) && _.isNil(this.capCompany.capital_montant_souscription_part)) {
                    missingData.push("le montant d'acquisition de la part sociale");
                }
                text += missingData.join(", ") + ".";

                return text;
            },
            canShowConversionPartsSocialesDetail() {
                return !_.isNil(this.montantDeblocageNet) && this.deblocage.motif === "acquisition_parts_sociales_scop";
            },
        },
        watch: {
            "deblocage.montant": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.date_mouvement": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.taux_de_remuneration_interets_courus": {
                handler() {
                    this.resetDetailMontant();
                },
            },
            "deblocage.motif": {
                handler(new_value, old_value) {
                    // Le calcul du montant net est différent pour les acquisitions de parts sociales
                    const vals_to_check = [new_value, old_value];
                    if (vals_to_check.includes("acquisition_parts_sociales_scop")){
                        this.resetDetailMontant();
                    }
                },
            },
            "deblocage.beneficiaire_id": {
                handler() {
                    this.resetMontantDebloque();
                },
            },
            "deblocage.support_id": {
                handler() {
                    this.resetMontantDebloque();
                },
            },
        },
        mounted() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            let promise_select_items = this.getSelectItems();
            let promise_cap_company = this.getCapCompany();

            Promise.all([promise_select_items, promise_cap_company]).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        },
        methods: {
            resetMontantDebloque(){
                if(this.isEditable){
                    this.deblocage.montant = undefined;
                }
            },
            resetDetailMontant() {
                this.detail.montant_interets_echus_brut = 0;
                this.detail.montant_interets_courus_brut = 0;
                this.detail.montant_charges_sociales = 0;
                this.detail.montant_prelevement_forfaitaire = 0;
                this.detail.montant_total_interets_net = 0;
                this.detail.montant_converti_parts_sociales = null;
                this.detail.nb_parts_sociales = null;
                this.detail.montant_rembourse = null;
                this.montantDeblocageNet = undefined;
            },
            getSelectItems() {
                return internalApi.deblocageAnticipe.getLabels().then(result => {
                    this.motifItems = Utils.fromLabelToSelect(result);
                });
            },
            onBackClick() {
                this.$router.back();
            },
            submitData() {
                if (this.hasAccessCapital() && this.deblocage.motif === "acquisition_parts_sociales_scop") {
                    this.confirmationDialog = true;
                } else {
                    this.submit();
                }
            },
            submit() {
            },
            computeCurrentMontantNet(){
                if(this.canComputeMontantNet){
                    internalApi.deblocageAnticipe.getMontantDeblocageNetAnticipe(
                        this.deblocage.beneficiaire_id, this.deblocage.support_id,
                        this.deblocage.montant, this.deblocage.date_mouvement,
                        this.deblocage.taux_de_remuneration_interets_courus, this.deblocage.motif,
                        this.deblocage.montant_souscription_part,
                    ).then(result => {
                        const detailMontantNet = result.detail_montant_net_deblocage
                        let montant_charges_sociales = null;
                        let montant_prelevement_forfaitaire = null;
                        if (!_.isNil(detailMontantNet.montant_cs_interets_echus) && !_.isNil(detailMontantNet.montant_cs_interets_courus)) {
                            montant_charges_sociales = detailMontantNet.montant_cs_interets_echus + detailMontantNet.montant_cs_interets_courus;
                        }
                        if (!_.isNil(detailMontantNet.montant_pf_interets_echus) && !_.isNil(detailMontantNet.montant_pf_interets_courus)) {
                            montant_prelevement_forfaitaire = detailMontantNet.montant_pf_interets_echus + detailMontantNet.montant_pf_interets_courus;
                        }
                        this.detail.montant_interets_echus_brut = detailMontantNet.montant_interets_echus_brut;
                        this.detail.montant_interets_courus_brut = detailMontantNet.montant_interets_courus_brut;
                        this.detail.montant_charges_sociales = montant_charges_sociales;
                        this.detail.montant_prelevement_forfaitaire = montant_prelevement_forfaitaire;
                        this.detail.montant_total_interets_net = detailMontantNet.montant_interets_echus_net + detailMontantNet.montant_interets_courus_net;
                        this.montantDeblocageNet = detailMontantNet.montant_deblocage_net;

                        // Si la valeur d'acquisition de la part sociale est accessible et valide
                        if (!_.isNil(this.capCompany) && !_.isNil(this.capCompany.capital_montant_souscription_part) && this.capCompany.capital_montant_souscription_part > 0) {
                            this.deblocage.montant_souscription_part = this.capCompany.capital_montant_souscription_part;
                        }

                        // Si on tente de faire une conversion en parts sociales
                        if (!_.isNil(result.detail_conversion_parts_sociales)) {
                            const detailConversionPartsSociales = result.detail_conversion_parts_sociales
                            this.detail.nb_parts_sociales = detailConversionPartsSociales.nb_parts_sociales_creees;
                            this.detail.montant_converti_parts_sociales = detailConversionPartsSociales.montant_converti_parts_sociales;
                            this.detail.montant_rembourse = detailConversionPartsSociales.montant_a_verser;
                        } else {
                            this.detail.nb_parts_sociales = null;
                            this.detail.montant_converti_parts_sociales = null;
                            this.detail.montant_rembourse = null;
                        }
                    });
                } else {
                    this.resetDetailMontant();
                }
            },
            getCapCompany() {
                if (this.hasAccessCapital()) {
                    return internalApi.capCompany.getCurrentStatutCapCompany()
                    .then(result => {
                        this.capCompany = result;
                    });
                } else {
                    // Même si on ne peut pas accéder à l'univers Capital, renvoyer une Promise validée pour que le
                    // retour de la méthode reste consistant
                    return Promise.resolve(null);
                }
            },
            /**
             * Remplit le montant débloqué avec le montant disponible sur le support de pb
             */
            solderPortefeuille() {
                this.deblocage.montant = this.$refs.mouvementsSupports.$refs.mouvementsSupports._data.montantTotal;
            },
            /**
             * Ferme la boîte de dialogue, réinitialise les champs
             */
            onCancelClick() {
                this.confirmationDialog = false;
            },
            validerSouscription() {
                this.confirmationDialog = false;
                this.submit();
            },
            hasAccessCapital: function () {
                return authService.hasAccessCapital();
            },
        },
    }
</script>