<template>
    <base-layout>
        <template v-slot:header>
            <h1>Intérêts échus de la participation</h1>
        </template>
        <template v-slot:main="{datatableSize}">
            <loading-screen ref="loadingComponent"/>
            <v-row class="pl-3" no-gutters>
                <h2>
                    {{ pbSupport.name }}
                </h2>
            </v-row>
            <v-container fluid>
                <taux-remuneration-support-list :tauxRemuSupportList="pbSupport.taux_remu_supports"
                                                :pbSupportId="pbSupportId"
                                                :datatableSize="datatableSize"
                                                @edit-taux-remu="(newTaux) => editTauxRemu(newTaux)"
                                                @remove-taux-remu="removeTauxRemu()"
                                                ref="tauxRemuSupport"
                />
            </v-container>
        </template>
        <template v-slot:footer="{}">
            <v-row justify="start" no-gutters>
                <!-- Très grand bouton exception texte long -->
                <v-col md="4" lg="4" xl="3" v-if="canCreate">
                    <v-taux-remu-for-support-creation @create-taux-remu="addTauxRemu($event)"
                                          :pbSupportId="pbSupportId"
                                          />
                </v-col>
                <v-col cols="6" v-if="exerciceRunningInteretsRemuneration !== undefined">
                    <span class="warning--text">
                        Les intérêts de rémunération pour l'exercice "{{ exerciceRunningInteretsRemuneration }}"
                        <br/>
                        sont en cours de génération depuis le {{ dateGenerationInteretsRemuneration | dateIsoToFrFilter}}.
                    </span>
                </v-col>
                <v-spacer/>
                <v-col md="3" lg="3" xl="2" class="mr-2">
                    <v-btn block :to="{name: 'pb-supports'}">Retour</v-btn>
                </v-col>
                <v-col md="3" lg="3" xl="2">
                    <v-custom-confirmation-dialog message="Confirmez-vous les taux d'intérêts ?"
                                                    openButtonMessage="Valider"
                                                    title="Déclaration des taux d'intérêts"
                                                    :isIcon="false"
                                                    :disabled="canCreate"
                                                    @action-confirmee="createTauxRemu()"/>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import TauxRemunerationSupportList from "@/components/tauxRemunerationSupport/TauxRemunerationSupportList";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog";
    import VTauxRemuForSupportCreation from "@/components/tauxRemunerationSupport/VTauxRemuForSupportCreation";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";

    export default {
        name: "PbSupportInteretsEchus",
        components: {
            loadingScreen,
            VTauxRemuForSupportCreation,
            TauxRemunerationSupportList,
            VCustomConfirmationDialog,
            BaseLayout
        },
        computed: {
            pbSupportId() {
                return parseInt(this.$route.params.id) || undefined;
            },
            messageInformationExerciceRunningInteretsRemuneration() {
                return `Les intérêts de rémunération pour l'exercice ${this.exerciceRunningInteretsRemuneration} sont en cours de génération depuis le ${this.dateGenerationInteretsRemuneration}.`;
            },
            canCreate() {
                return (this.newPbTauxRemuSupport === undefined && this.exerciceRunningInteretsRemuneration === undefined);
            }
        },
        data() {
            return {
                pbSupport: {
                    id: undefined,
                    code: undefined,
                    name: undefined,
                    taux_remu_supports: [],
                },
                newPbTauxRemuSupport: undefined,
                exerciceRunningInteretsRemuneration: undefined,
                dateGenerationInteretsRemuneration: undefined,
            }
        },
        mounted() {
            this.getPbTauxRemuBySupportId(this.pbSupportId);
            this.getStateInteretsRemuneration();
        },
        methods: {
            getPbTauxRemuBySupportId(id) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.pbSupport.getPbTauxRemuBySupportId(id)
                    .then(result => {
                        this.pbSupport = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            getStateInteretsRemuneration() {
                internalApi.pbSupport.getStateInteretsRemuneration(this.pbSupportId)
                .then(result => {
                    if (result.state === "running"){
                        this.exerciceRunningInteretsRemuneration = result.description;
                        this.dateGenerationInteretsRemuneration = result.date_dernier_traitement;
                    } else {
                        this.exerciceRunningInteretsRemuneration = undefined;
                        this.dateGenerationInteretsRemuneration = undefined;
                    }
                })
            },
            addTauxRemu(tauxRemu) {
                this.pbSupport.taux_remu_supports.push(tauxRemu);
                this.newPbTauxRemuSupport = tauxRemu;
            },
            goToPbSupports() {
                this.$router.push({
                    name: "pb-supports"
                });
            },
            createTauxRemu(){
                if(this.newPbTauxRemuSupport === undefined){
                    return;
                }
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                internalApi.pbSupport.createPbTauxRemuBySupportId(this.pbSupportId, this.newPbTauxRemuSupport).then(()=>{
                    this.$refs.loadingComponent.resetLoading();
                    this.goToPbSupports();
                })
                .catch(err => {
                    console.log(err);
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            editTauxRemu(newTauxRemu){
                this.newPbTauxRemuSupport = newTauxRemu;
            },
            removeTauxRemu(){
                this.newPbTauxRemuSupport = undefined;
            }
        }
    }
</script>
