// <!-- VUETIFY2 - OK -->
import DeblocageTerme from "./DeblocageTerme"
import internalApi from "@/api/internalApi";

export default {
  extends: DeblocageTerme,
  computed: {
      title() {
        if(!_.isNil(this.deblocage.support_id)) {
          return "Deblocage à terme du support " + this.deblocage.support_code;
        }
        else {
          return "Déblocage à terme";
        }
      }
  },
  data() {
    return {
      isEditable: false
    };
  },
  mounted() {
    const id = this.$route.params.id;
    internalApi.deblocageTerme.getById(id)
      .then(result => {
        this.deblocage = result;
      }).catch(err => {
      console.log(err);
    });
  }
}