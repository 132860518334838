<!-- VUETIFY2 - OK -->
<template>
    <dettes-list/>
</template>

<script>
    import DettesList from "@/components/DettesList";

    export default {
        components: {
            DettesList
        }
    }
</script>