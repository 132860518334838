<!-- VUETIFY2 - OK -->
<template>
    <v-dialog v-model="confirmationDialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" :disabled="!areSalariesLoaded" block>
                <v-icon dark left>{{ "$vuetify.icons.forward" }}</v-icon>
                Créer les souscriptions
            </v-btn>
        </template>
        <v-card round>
            <ValidationObserver v-slot="{ handleSubmit }">
                <loading-screen ref="loadingComponent"/>
                <v-card-title class="headline grey lighten-2" primary-title>Créer les souscriptions de
                    l'exercice
                    "{{ libelleExercice }}" ?
                </v-card-title>
                <v-divider/>
                <v-card-text>Veuillez sélectionner la catégorie de capital qui fera l'objet des
                    souscriptions obligatoires :
                </v-card-text>
                <v-col md="6" offset-md="2">
                    <ValidationProvider vid="categorieSouscription"
                                        name="Catégorie de capital"
                                        :rules="{ required: true }"
                                        v-slot="{ errors }">
                        <v-categories-capital-list v-model="localCategorieSouscription"
                                                   :errors="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-card-text>Vous pouvez modifier la date de souscription, positionnée par défaut à
                    la date
                    d'AG :
                </v-card-text>
                <v-divider/>
                <v-col md="6" offset-md="2">
                    <ValidationProvider vid="dateSouscription"
                                        name="Date de souscription"
                                        :rules="{ required: true, date_after: dateMinimumSouscription }"
                                        v-slot="{ errors }">
                        <DateField v-model="localDateSouscription"
                                   label="Date de souscription"
                                   :errors="errors"
                        />
                    </ValidationProvider>
                </v-col>
                <v-card-text class="font-weight-medium">
                    <v-icon color="warning">{{ "$vuetify.icons.warning" }}</v-icon>
                    Les affectations de participation aux bénéfices ne seront plus modifiables après
                    cette
                    action.
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" dense>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn @click="onCancelClick" block>Annuler
                            </v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" :disabled="!localCategorieSouscription"
                                   @click="handleSubmit(validerSouscription)" block>
                                Confirmer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import DateField from "@/components/DateField.vue";
import loadingScreen from "@/components/LoadingScreen.vue";
import internalApi from "@/api/internalApi";
import VCategoriesCapitalList from "@/components/capital/VCategoriesCapitalList";

/**
 * Composant affichant une fenêtre contenant les informations par rapport à la catégorie
 * de capital et à la date des souscriptions obligatoire pour un exercice.
 * Lorsqu'on valide, créer les souscriptions obligatoires pour l'exercice
 * @displayName
 */
export default {
    components: {
        VCategoriesCapitalList,
        DateField,
        loadingScreen,
    },
    props: {
        /**
         * L'identifiant de l'exercice pour lequel créer les souscriptions obligatoires
         */
        exerciceId: {
            type: Number,
            required: true
        },
        /**
         * Le libellé de l'exercice
         */
        libelleExercice: {
            type: String,
            required: true,
        },
        /**
         * La liste des informations concernant les souscriptions
         */
        pbSalarieListToPost: {
            type: Array,
            required: true
        },
        /**
         * Les catégories de capitaux disponibles pour les souscriptions
         */
        categorieCapitalList: {
            type: Array,
            required: true,
        },
        /**
         * La date d'AG de l'exercice, valeur par défaut de la date des souscriptions
         */
        dateAgExercice: {
            type: String,
            required: false,
            default: undefined
        },
        /**
         * La catégorie de souscription pré-enregistrée si elle existe (ie provient de l'univers participation)
         */
        categorieSouscription: {
            type: Number,
            required: false,
            default: undefined
        },
        /**
         * La date de souscription pré-enregistrée si elle existe (ie provient de l'univers participation)
         */
        dateSouscription: {
            type: String,
            required: false,
            default: undefined
        },
    },
    watch: {
        pbSalarieListToPost(newVal) {
            this.areSalariesLoaded = newVal.length !== 0;
        },
    },
    mounted() {
        this.resetForm();
        this.getDateMinimumSouscription();
    },
    data() {
        return {
            confirmationDialog: false,
            dateMinimumSouscription: undefined,
            areSalariesLoaded: false,
        }
    },
    computed: {
        localDateSouscription: {
            get: function () {
                return this.dateSouscription;
            },
            set: function (newvalue) {
                this.$emit("update-date-souscription", newvalue);
            }
        },
        localCategorieSouscription: {
            get: function () {
                return this.categorieSouscription;
            },
            set: function (newvalue) {
                this.$emit("update-categorie-capital", newvalue);
            }
        },
    },
    methods: {
        /**
         * Remet les valeurs par défaut dans les champs
         */
        resetForm() {
            if (this.localCategorieSouscription === undefined) {
                this.localDateSouscription = this.dateAgExercice;
                this.localCategorieSouscription = undefined;
            }
        },
        /**
         * Récupère la date minimum à laquelle réaliser les souscriptions obligatoires
         */
        getDateMinimumSouscription() {
            internalApi.capExercice.dateMinimumSouscription()
                .then(results => {
                    this.dateMinimumSouscription = results.date_minimum_souscription;
                }).catch(err => {
                console.log(err);
            });
        },
        /**
         * Ferme la boîte de dialogue, réinitialise les champs
         */
        onCancelClick() {
            this.confirmationDialog = false;
            this.resetForm();
        },
        /**
         * Valide et donc crée les souscriptions
         */
        validerSouscription() {
            this.confirmationDialog = false;
            this.$refs.loadingComponent.setLoading("Création des souscriptions");
            internalApi.monteeCapital.souscrireParticipationByExerciceId(this.exerciceId, {
                date_souscription: this.localDateSouscription,
                categorie_souscription: this.localCategorieSouscription,
                participations: this.pbSalarieListToPost
            }).then(res => {
                this.$emit("souscriptions-validees");
            }).catch(error => {
                console.error(error);
                return Promise.reject(error);
            }).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        },
    }
}
</script>