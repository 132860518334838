import Utils from "@/utils.js";

const ANNULATIONS = "/annulations";
const ASSOCIES = "/associes";
const SALARIES = "/salaries";
const PERIODES_TRAVAIL = "/periodes-travail";
const BENEFICIAIRES = "/beneficiaires";
const CAPITAL = "/capital";
const CATEGORIES_CAPITAUX = "/categories-capitaux";
const COMPANIES = "/companies";
const CAP_COMPANIES = "/cap-companies";
const INTE_COMPANIES = "/inte-companies"
const PB_COMPANIES = "/pb-companies";
const REPARTITION_PB_ANCIENNETE = "/repartition-pb-anciennete";
const CONTROLES_STATUS = "/cap-conformite";
const EXERCICES = "/exercices";
const CAP_EXERCICES = "/cap-exercices";
const MONTEES_CAPITAL = "/montees-capital";
const ABO_EXERCICES = "/abo-exercices";
const ABO_ELIGIBILITES = "/abo-eligibilites";
const AFFECTATION_CAPITAL = "/cap-affectation";
const PB_EXERCICES = "/pb-exercices";
const INTE_EXERCICES = "/inte-exercices";
const VENTILATION_PB = "/ventilation-pb";
const MOUVEMENTS = "/mouvements";
const PERSONNES_PHYSIQUES = "/personnes-physiques";
const PERSONNES_MORALES = "/personnes-morales";
const SOUSCRIPTIONS = "/souscriptions";
const USERS = "/users";
const PERSONNES = "/personnes";
const DETTES = "/dettes";
const REGLEMENTS = "/reglements";
const AUTH = "/auth";
const FISCALITE_CERFA = "/fiscalite/cerfa";
const ASYNCHRONOUS = "/asynchronous";
const FISCALITE_CAPITAL = "/cap-fiscalite";
const FISCALITE_PARTICIPATION = "/pb-fiscalite";
const CAP_CHARTS = "/cap-charts";
const PB_CHARTS = "/pb-charts";
const MANUEL_UTILISATEUR = "/manuel-utilisateur";
const DOCUMENTS = "/documents";
const ADMINISTRATION = "/administration";
const GESTION_DROITS_ACCES = "/gestion-droits-acces";
const PB_SUPPORTS = "/pb-supports-de-placement";
const INTE_SUPPORTS = "/inte-supports-de-placement";
const TAUX_MOYEN_RENDEMENT_OBLI = "/tmop";
const PB_DEBLOCAGES = "/pb-deblocages";
const PB_DEBLOCAGES_ANTICIPES = "/pb-deblocages-anticipes";
const PB_DEBLOCAGES_TERMES = "/pb-deblocages-termes";
const OPERATIONS = "/operations";
const SALAIRE_PRESENCES = "/salaire-presence";
const PB_SALAIRE_PRESENCES = "/pb-salaire-presence";
const INTE_SALAIRE_PRESENCES = "/inte-salaire-presence";
const TAUX_FISCALITES = "/taux-fiscalites";
const COUNTRIES_LIST = "countries";
const ABO_ATTRIBUTION = "/abo-attribution";
const REPARTITION_PB = "/repartition-pb";
const INTE_REPARTITION = "/repartition-inte";
const INTE_AFFECTATION = "/affectation-inte";
const SCIC_RAPPORTS = "/scic/rapports";
const SCIC_PARAM_CATEGORIE = "/scic/param-categorie";
const SCIC_COLLEGE = "/scic/colleges";
const SCIC_SOUS_CATEGORIE = "/scic/sous-categories";
const SCIC_ADMINISTRATION_PARAMETRES = "/scic/administration/parametres";
const SCIC_DOCUMENTS = "/scic/documents";
const SCIC_TAUX_FISCALITE = "/scic/taux-fiscalite";
const CHIFFREMENT = "/chiffrement";

export default {
  annulations: ANNULATIONS,
  annulationTotale: {
    getByAssocieAndDate: getByAssocieAndDate,
    deleteAnnulationTotale: deleteAnnulationTotale,
    brouillonBulletinAnnulationTotale: ANNULATIONS + "/totale/bulletin/brouillon",
    createAnnulationTotale: ANNULATIONS + `/totale`,
  },
  annulationsMontantARembourser: ANNULATIONS + "/montant_a_rembourser",
  annulationAssocie: ANNULATIONS + "/associe",
  associes: ASSOCIES,
  salaries: SALARIES,
  periodeTravail: {
    baseUrl: PERIODES_TRAVAIL,
    deletePeriodeTravailById: deletePeriodeTravailById,
    getPeriodesTravailSalarie: getPeriodesTravailSalarie,
    getPeriodesTravailInPeriode: getPeriodesTravailInPeriode,
  },
  beneficiaires: {
    baseUrl: BENEFICIAIRES,
    etatDeLaParticipation: etatDeLaParticipation,
    getBeneficiaireIndividuel: getBeneficiaireIndividuel
  },
  brouillonBulletinSouscription: SOUSCRIPTIONS + "/bulletin/brouillon",
  brouillonBulletinAnnulationPartielle: ANNULATIONS + "/bulletin/brouillon",
  associesFeuilleEmargement: associesFeuilleEmargement,
  associesEtatDuCapital: associesEtatDuCapital,
  associesEtatDuCapitalIndividuel: associesEtatDuCapitalIndividuel,
  droitsVotePerAssocie: droitsVotePerAssocie,
  capital: {
    evolutionExcel: evolutionExcel,
    rapportCacADate: capitalRapportCacADate,
    portefeuilleByDate: capitalPortefeuilleByDate,
    aDate: capitalADate,
    liberationSouscriptions: capitalLiberationSouscriptions,
    getStateEtatsDuCapital: getStateEtatsDuCapital,
    generateEtatsDuCapital: generateEtatsDuCapital,
    getEtatsDuCapital: getEtatsDuCapital,
    getDisponibiliteDuCapital: getDisponibiliteDuCapital,
  },
  categoriesCapitaux: {
    baseUrl: CATEGORIES_CAPITAUX,
    getCategoriesByDate: getCategoriesByDate,
    getStatutCategById: getStatutCategById
  },
  companies: {
    baseUrl: COMPANIES,
    versionsCompany: versionsCompany,
    currentCompany: currentCompany,
  },
  capCompanies: {
    baseUrl: CAP_COMPANIES,
    statutsCapCompany: statutsCapCompany,
    statutsCapCompanyByDate: statutsCapCompanyByDate,
    statutsCapCompanyEnumByName: statutsCapCompanyEnumByName,
    getStatutCapCompanyById: getStatutCapCompanyById,
    deleteStatutsDocument: deleteStatutsDocument,
    createStatutsDocument: createStatutsDocument,
  },
  inteCompanies: {
    baseUrl: INTE_COMPANIES,
    accordsInteCompany:accordsInteCompany,
    accordsInteCompanyEnumByName: accordsInteCompanyEnumByName,
    deleteDocumentInteAccord: deleteDocumentInteAccord,
    createInteAccordDocument: createInteAccordDocument,
  },
  pbCompanies: {
    baseUrl: PB_COMPANIES,
    statutsPbCompany: statutsPbCompany,
    statutsPbCompanyEnumByName: statutsPbCompanyEnumByName,
    statutsPbCompanyByDate: statutsPbCompanyByDate,
    statutsPbCompanyById: statutsPbCompanyById,
    deleteDocumentAccord: deleteDocumentAccord,
    createPbAccordDocument: createPbAccordDocument,
  },
  repartitionPbAnciennete: {
    baseUrl: REPARTITION_PB_ANCIENNETE
  },
  controleStatus: {
    baseUrl: CONTROLES_STATUS,
    capitalSuperieurPlancher: controlesStatusCapitalSuperieurPlancherOn,
    capitalSuperieurQuartCapitalMax: controlesStatusCapitalSuperieurQuartCapitalMaxOn,
    capitalSalariesExSalariesSuperieurMoitieCapital: controlesStatusCapitalSalariesExSalariesSuperieurMoitieCapital,
    capitalAssocieInferieurMoitieCapital: controlesStatusCapitalAssocieInferieurMoitieCapital,
    checkDroitsVoteAssocieExterneDontCoop: checkDroitsVoteAssocieExterneDontCoop,
    checkDroitsVoteAssocieExterneHorsCoop: checkDroitsVoteAssocieExterneHorsCoop,
  },
  exercices: {
    baseUrl: EXERCICES,
    getExercicesEnumByName: getExercicesEnumByName,
    // export les périodes de travail pour un Exercice
    exportPeriodesTravailForExerciceExcel: exportPeriodesTravailForExerciceExcel,
  },
  capExercices: {
    baseUrl: CAP_EXERCICES,
    certificatsPbById: exercicesCertificatsPb,
    repartition: {
      baseUrl: exercicesRepartitionCap,
      proposition: exercicesRepartitionCapProposition,
      espaceAssocie: repartitionCapEspaceAssocie,
      getStateFichesIndividuelles: capExercicesGetStateFichesIndividuelles,
      generateFichesIndividuelles: capExercicesGenerateFichesIndividuelles,
      ficheIndividuelle: capExercicesFicheIndividuelle,
      fichesIndividuelles: capExercicesFichesIndividuelles,
    },
    affectation: {
      baseUrl: capAffectationByExerciceId,
      validate: validateCapAffectationByExerciceId,
      affectationIndividuel: exercicesAffectationIndividuel,
      exportCapAffectationModeleExcel: exportCapAffectationModeleExcel,
      importCapAffectationExcel: importCapAffectationExcel,
      getStateBulletinsSouscriptionIps: getStateBulletinsSouscriptionIps,
      generateBulletinsSouscriptionIps: generateBulletinsSouscriptionIps,
      getBulletinsSouscriptionIps: getBulletinsSouscriptionIps,
      areBulletinsSouscriptionGenerable:areBulletinsSouscriptionGenerable,
    },
    dateMinimumSouscription: {
      baseUrl: exercicesDateMinimumSouscription
    },
  },
  monteesCapital: {
    monteesCapitalByExercice: monteesCapitalByExercice,
    monteesCapitalValidation: monteesCapitalValidation,
    monteesCapitalSouscription: monteesCapitalSouscription,
    monteesCapitalRecalcul: monteesCapitalRecalcul,
    getStateBulletinsObligatoires: getStateBulletinsObligatoires,
    generateBulletinsObligatoires: generateBulletinsObligatoires,
    getBulletinsObligatoires: getBulletinsObligatoires,
  },
  aboExercices: {
    baseUrl: ABO_EXERCICES,
    getIsCampagneActive: getIsCampagneActive,
    importDonneesAssociesExcel: importDonneesAssociesExcel,
    exportDonneesAssociesModeleExcel: exportDonneesAssociesModeleExcel,
    attribution: {
      aboAttributionByAboExerciceId: aboAttributionByAboExerciceId,
      aboAttributionByAboExerciceIdIndividuel: aboAttributionByAboExerciceIdIndividuel,
      aboComputeSingleAttributionPreview: aboComputeSingleAttributionPreview,
      aboAttributionValidate: aboAttributionValidate,
      downloadAboAttributionExcel: downloadAboAttributionExcel,
      nbPartsMaxByAboExerciceId: nbPartsMaxByAboExerciceId,
      getTotauxAboAttribution: getTotauxAboAttribution,
      getStateBulletinsSouscriptionAbondement: getStateBulletinsSouscriptionAbondement,
      generateBulletinsSouscriptionAbondement:generateBulletinsSouscriptionAbondement,
      downloadBulletinsSouscriptionAbondement: downloadBulletinsSouscriptionAbondement,
    },
  },
  aboEligibilites: {
    baseUrl: ABO_ELIGIBILITES,
  },
  pbExercices: {
    baseUrl: PB_EXERCICES,
    getExercicesDisponiblesTauxRemu: getExercicesDisponiblesTauxRemu,
    computeMontantPbPlancherLegal: computeMontantPbPlancherLegal,
    computeMontantPbPlancherScop: computeMontantPbPlancherScop,
    computeMontantPbPlafondScopBeneficeOnly: computeMontantPbPlafondScopBeneficeOnly,
    computeMontantPbPlafondScopBeneficeImpactCapital: computeMontantPbPlafondScopBeneficeImpactCapital,
    computeVersementImmediatNet: computeVersementImmediatNet,
    getPassPlafondsIndivEtCommun: getPassPlafondsIndivEtCommun,
    getPassPlafondsIndivEtCommunNoExercice: getPassPlafondsIndivEtCommunNoExercice,
    getMontantRenumerationSalairePlafond: getMontantRenumerationSalairePlafond,
    getMontantRenumerationSalairePlafondFromExercice: getMontantRenumerationSalairePlafondFromExercice,
    getAllParticipationInteretsTotaux: getAllParticipationInteretsTotaux,
    participation: {
      repartitionPb: exercicesRepartitionPb,
      exercicesExportRepartitionPbExcel: exercicesExportRepartitionPbExcel,
      repartitionPbIndividuel: exercicesRepartitionPbIndividuel,
      ventilationPb: exercicesVentilationPb,
      ventilationPbIndividuel: exercicesVentilationPbIndividuel,
      validateVentilationPb: exercicesValidateVentilationPb,
      exportAffectationPbExcel: exercicesExportAffectationPbExcel,
      importAffectationPbExcel: exercicesImportAffectationPbExcel,
      getStateFichesIndividuelles: exercicesGetStateFichesIndividuelles,
      generateFichesIndividuelles: exercicesGenerateFichesIndividuelles,
      fichesIndividuelles: exercicesFichesIndividuelles,
      ficheIndividuelle: exercicesFicheIndividuelle,
      benefHasRepartitionOrOperationsOnSupport: exerciceCheckBeneficiaire,
      rapportAnnuel: exercicesRapportAnnuelAffectedParticipation,
    },
  },
  inteExercices: {
    baseUrl: INTE_EXERCICES,
    plafondInteressementCollectif: plafondInteressementCollectif,
    plafondInteressementIndividuel: plafondInteressementIndividuel,
    repartition: {
      inteExercicesInteRepartition: inteExercicesInteRepartition,
      getStateFichesIndividuellesInte: getStateFichesIndividuellesInte,
      generateFichesIndividuellesInte: generateFichesIndividuellesInte,
      getFichesIndividuellesInte: getFichesIndividuellesInte,
      inteFicheIndividuelle: inteExerciceFicheIndividuelle,
      inteExercicesInteRepartitionIndividuel: inteExercicesInteRepartitionIndividuel,
      inteFicheIndividuelle: inteExerciceFicheIndividuelle,
    },
    affectation: {
      inteExercicesInteAffectation: inteExercicesInteAffectation,
      inteExercicesInteAffectationIndividuel: inteExercicesInteAffectationIndividuel,
      inteExercicesInteAffectationValidation: inteExercicesInteAffectationValidation,
      inteExercicesInteAffectationImport: inteExercicesInteAffectationImport,
      inteExercicesInteAffectationDownload: inteExercicesInteAffectationDownload,
      computeInteVersementImmediatNet: computeInteVersementImmediatNet,
    },
  },
  mouvements: MOUVEMENTS,
  deleteMouvementById: deleteMouvementById,
  getMvtsAssocie: getMvtsAssocie,
  importMouvementsPartsSociales: importMouvementsPartsSociales,
  exportMouvementsPartsSociales: exportMouvementsPartsSociales,
  personnesPhysiques: {
    baseUrl: PERSONNES_PHYSIQUES,
    anonymizePersonnePhysique: anonymizePersonnePhysique,
    getInfosEspacePersonnelUrl: infosEspacePersonnelPPUrl,
    putInfosEspacePersonnelUrl: infosEspacePersonnelPPUrl,
  },
  importBulletinsMasse: importBulletinsMasse,
  exportBulletinsMasse: exportBulletinsMasse,
  downloadTotaliteMouvementsExcel: downloadTotaliteMouvementsExcel,
  personnesMorales: {
    baseUrl: PERSONNES_MORALES,
    getInfosEspacePersonnelUrl: infosEspacePersonnelPMUrl,
    putInfosEspacePersonnelUrl: infosEspacePersonnelPMUrl,
  },
  souscriptions: SOUSCRIPTIONS,
  souscriptionAssocie: SOUSCRIPTIONS + "/associe",
  users: {
    baseUrl: USERS,
    passwordModificationResponsable: passwordModificationResponsable,
    changePassword: changePassword,
    forgotPassword: forgotPassword,
    createMassAccess: createMassAccess,
  },
  personnes: {
    baseUrl: PERSONNES,
    all: PERSONNES,
    getEmptyUserAccessPerson: getEmptyUserAccessPerson,
    exportTiersPeriodesTravailExcel: exportTiersPeriodesTravailExcel,
    importTiersPeriodesTravailExcel: importTiersPeriodesTravailExcel,
    exportModeleTiersPeriodesTravailExcel: exportModeleTiersPeriodesTravailExcel,
  },
  dettes: DETTES,
  generationSepa: generationSepa,
  downloadDetail: downloadDetail,
  downloadDettes: downloadDettes,
  reglements: {
    baseUrl: REGLEMENTS,
    deleteReglementById: deleteReglementById
  },
  authentication: {
    login: AUTH + "/login",
    refreshToken: AUTH + "/refresh",
    logout: AUTH + "/logout",
  },
  asynchronous_task: {
    getBatchesNotificationsList: getBatchesNotificationsList,
    getTasksList: getTasksList,
  },
  cerfa: {
    baseUrl: FISCALITE_CERFA,
    getCorrespondingCerfaVersion: getCorrespondingCerfaVersion,
  },
  fiscalite_capital: {
    ifu2561Infos: ifu2561Infos_capital,
    ifu2561PDF: ifu2561PDF_capital,
    ifu2561terAssociePDF: ifu2561terAssociePDF,
    pfu2777Infos: pfu2777Infos_capital,
    pfu2777PDF: pfu2777PDF_capital,
    ifuTeletransmission: ifuTeletransmission_capital,
    listeMinisterielle: listeMinisterielle,
  },
  fiscalite_participation: {
    ifu2561Infos: ifu2561Infos_participation,
    ifu2561PDF: ifu2561PDF_participation,
    pfu2777Infos: pfu2777Infos_participation,
    pfu2777PDF: pfu2777PDF_participation,
  },
  capCharts: {
    allAssocieCharts: allAssocieCharts,
    allCompanyChartsCapital: allCompanyChartsCapital,
    associeCharts: associeCharts,
    allDroitsDeVoteData: allDroitsDeVoteData,
  },
  pbCharts: {
    companyChartsParticipation: companyChartsParticipation,
    beneficiaireCharts: beneficiaireCharts,
    beneficiaireChartsIndividuel: beneficiaireChartsIndividuel,
  },
  manuelUtilisateur: MANUEL_UTILISATEUR,
  documents: {
    baseUrl: DOCUMENTS,
    documents: documents,
    documentsPersonnels: documentsPersonnels,
    documentDownload: documentDownload,
    documentAccordDownload: documentAccordDownload,
    documentPersonnelDownload: documentPersonnelDownload,
    getByIdPersonnel: getByIdPersonnel,
    documentsGeneral: documentsGeneral,
  },
  administration: {
    generatedDocumentsLogo: generatedDocumentsLogo,
    generatedDocumentsFooter: generatedDocumentsFooter,
    documentsFields: documentsFields,
    uploadFileInitial: uploadFileInitial,
    downloadFileInitial: downloadFileInitial,
    downloadModelFileInitial: downloadModelFileInitial,
    downloadFileTiersActifs: downloadFileTiersActifs,
  },
  pbSupports: {
    baseUrl: PB_SUPPORTS,
    livretEpargneSal: livretEpargneSal,
    getStateInteretsRemuneration: getStateInteretsRemuneration,
    pbTauxRemuBySupportId: pbTauxRemuBySupportId,
  },
  inteSupports: {
    baseUrl: INTE_SUPPORTS,
    inteLivretEpargneSal: inteLivretEpargneSal,
  },
  tauxMoyenRendementObli: {
    baseUrl: TAUX_MOYEN_RENDEMENT_OBLI,
    tauxMoyenRendementObliById: tauxMoyenRendementObliById,
    getTauxRemuProposition: getTauxRemuProposition,
    getTauxRemuDetailsProposition: getTauxRemuDetailsProposition,
    getTauxRemuPropositionValide: getTauxRemuPropositionValide,
  },
  deblocages: {
    baseUrl: PB_DEBLOCAGES,
    getDeblocagesIndividuel: getDeblocagesIndividuel,
    downloadBulletinDeblocage: downloadBulletinDeblocage,
    downloadBulletinDeblocageIndividuel: downloadBulletinDeblocageIndividuel,
  },
  deblocagesAnticipes: {
    baseUrl: PB_DEBLOCAGES_ANTICIPES,
    getMontantDeblocageNetAnticipe: getMontantDeblocageNetAnticipe,
  },
  deblocagesTermes: {
    baseUrl: PB_DEBLOCAGES_TERMES,
    getMontantDeblocageNetTerme: getMontantDeblocageNetTerme,
    getDeblocagesGroupes: getDeblocagesGroupes
  },
  operations: {
    baseUrl: OPERATIONS,
    getOperationsBySupports: getOperationsBySupports,
    getOperationsBySupportsSimplifie: getOperationsBySupportsSimplifie,
    getOperationsBySupportsSimplifieForSupport: getOperationsBySupportsSimplifieForSupport,
    getOperationsBySupportsSimplifieIndividuel: getOperationsBySupportsSimplifieIndividuel,
    exportDetailOperations: exportDetailOperations,
    getDisponibilitesBeneficiaires: getDisponibilitesBeneficiaires,
    exportDisponibilitesBeneficiaires: exportDisponibilitesBeneficiaires,
    getStateEtatsDeLaParticipation: getStateEtatsDeLaParticipation,
    generateEtatsDeLaParticipation: generateEtatsDeLaParticipation,
    getEtatsDeLaParticipation: getEtatsDeLaParticipation,
  },
  commonSalairePresences: {
    baseUrl: SALAIRE_PRESENCES,
  },
  pbSalairePresences: {
    baseUrl: PB_SALAIRE_PRESENCES,
    salairePresencesForExercice: pbSalairePresencesForExercice,
    exportSalairePresencesForExercice: exportPbSalairePresencesForExercice,
    importSalairePresencesForExercice: importPbSalairePresencesForExercice,
    totauxSalairePresencesForExercice: totauxPbSalairePresencesForExercice
  },
  inteSalairePresences: {
    baseUrl: INTE_SALAIRE_PRESENCES,
    salairePresencesForExercice: inteSalairePresencesForExercice,
    exportSalairePresencesForExercice: exportInteSalairePresencesForExercice,
    importSalairePresencesForExercice: importInteSalairePresencesForExercice,
    totauxSalairePresencesForExercice: totauxInteSalairePresencesForExercice
  },
  tauxFiscalites: {
    baseUrl: TAUX_FISCALITES,
    deleteTauxFiscaliteById: deleteTauxFiscaliteById,
    getTauxFiscaliteByDate: getTauxFiscaliteByDate
  },
  countriesList: COUNTRIES_LIST,
  scicRapports: {
    baseUrl: SCIC_RAPPORTS,
    getExcelProfilsAssocies: getExcelProfilsAssocies,
  },
  scicParamCategorie: {
    baseUrl: SCIC_PARAM_CATEGORIE,
    getGraphParamCategorieGraphRepartitionMontantCapital: getGraphParamCategorieGraphRepartitionMontantCapital,
  },
  scicCollege: {
    baseUrl: SCIC_COLLEGE,
    massEditing: scicCollegeMassEditing,
    getGraphCollegeGraphRepartitionAssocies: getGraphCollegeGraphRepartitionAssocies,
  },
  scicSousCategorie: {
    baseUrl: SCIC_SOUS_CATEGORIE,
    massEditing: scicSousCategorieMassEditing,
    getGraphSousCategorieGraphRepartitionMontantCapital: getGraphSousCategorieGraphRepartitionMontantCapital,
  },
  scicAdministrationParametres: {
    baseUrl: SCIC_ADMINISTRATION_PARAMETRES,
  },
  scicDocuments: {
    baseUrl: SCIC_DOCUMENTS,
    getAttestationFiscale: getAttestationFiscale,
    getAttestationFiscaleForAssocieList: getAttestationFiscaleForAssocieList,
  },
  ScicTauxFiscalite: {
    baseUrl: SCIC_TAUX_FISCALITE,
  },
  gestionDroitsAcces: {
    baseUrl: GESTION_DROITS_ACCES,
  },
  chiffrement: {
    baseUrl: CHIFFREMENT,
    getKeysPresenceAtDate: getKeysPresenceAtDate,
  },
};

// Company
function versionsCompany() {
  return COMPANIES + `/versions-company`;
}

function currentCompany() {
  return COMPANIES + `/current-company`;
}

// CapCompany
function statutsCapCompany() {
  return CAP_COMPANIES + `/statuts-cap-company`;
}

function statutsCapCompanyByDate(dateString) {
  return CAP_COMPANIES + `/statuts-cap-company` + `/${dateString}`;
}

function statutsCapCompanyEnumByName() {
  return CAP_COMPANIES + `/enum-by-name`;
}

function getStatutCapCompanyById(capCompanyId) {
  return CAP_COMPANIES + `/${capCompanyId}`;
}
function deleteStatutsDocument(id) {
  return CAP_COMPANIES + `/${id}/document`;
}
function createStatutsDocument() {
  return CAP_COMPANIES + `/document`;
}

// InteCompany
function accordsInteCompany() {
  return INTE_COMPANIES + `/accords-inte-company`;
}

function accordsInteCompanyEnumByName() {
  return INTE_COMPANIES + `/enum-by-name`;
}

function deleteDocumentInteAccord(id) {
  return INTE_COMPANIES + `/${id}/document`;
}

function createInteAccordDocument() {
  return INTE_COMPANIES + `/document`;
}


// PbCompany
function statutsPbCompany() {
  return PB_COMPANIES + `/statuts-pb-company`;
}

function statutsPbCompanyEnumByName() {
  return PB_COMPANIES + `/enum-by-name`;
}

function statutsPbCompanyByDate(dateString) {
  return PB_COMPANIES + `/statuts-pb-company` + `/${dateString}`;
}

function statutsPbCompanyById(pb_company_id) {
  return PB_COMPANIES + `/${pb_company_id}`;
}

function deleteDocumentAccord(id) {
  return PB_COMPANIES + `/${id}/document`;
}

function createPbAccordDocument() {
  return PB_COMPANIES + `/document`;
}


// Catégories capitaux
function getCategoriesByDate(dateString) {
  return CATEGORIES_CAPITAUX + `/${dateString}`;
}

function getStatutCategById(categstatutId) {
  return CATEGORIES_CAPITAUX + `/get-statuts/${categstatutId}`;
}

// Controles Status
function controlesStatusCapitalSuperieurPlancherOn() {
  return CONTROLES_STATUS + "/capital-superieur-plancher";
}

function controlesStatusCapitalSuperieurQuartCapitalMaxOn() {
  return CONTROLES_STATUS + "/capital-superieur-quart-capital-max";
}

function controlesStatusCapitalSalariesExSalariesSuperieurMoitieCapital() {
  return CONTROLES_STATUS + "/capital-salaries-ex-salaries-superieur-moitie-capital";
}

function controlesStatusCapitalAssocieInferieurMoitieCapital() {
  return CONTROLES_STATUS + "/capital-associe-inferieur-moitie-capital";
}

function checkDroitsVoteAssocieExterneDontCoop() {
  return CONTROLES_STATUS + "/droits-vote-associes-externes-dont-coop";
}

function checkDroitsVoteAssocieExterneHorsCoop() {
  return CONTROLES_STATUS + "/droits-vote-associes-externes-hors-coop";
}

// Exercice
function getExercicesEnumByName() {
  return EXERCICES + `/enum-by-name`;
}

function exportPeriodesTravailForExerciceExcel(exerciceId) {
  return EXERCICES + `/${exerciceId}/export-periodes-travail`;
}

// Cap Exercice
function exercicesRepartitionCapProposition(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/proposition-repartition-cap`;
}

function exercicesRepartitionCap(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/repartition-cap`;
}

function repartitionCapEspaceAssocie(exerciceId) {
  return exercicesRepartitionCap(exerciceId) + `/espaceassocie`;
}

function exercicesCertificatsPb(id) {
  return CAP_EXERCICES + `/${id}/certificats_participation_benefice`;
}

// Fiches individuelles asynchrones
function capExercicesGetStateFichesIndividuelles(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/fiches-individuelles/state`;
}
function capExercicesGenerateFichesIndividuelles(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/fiches-individuelles/generate`;
}
function capExercicesFichesIndividuelles(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/fiches-individuelles`;
}
// Fiche individuelle individuel
function capExercicesFicheIndividuelle(exerciceId) {
  return CAP_EXERCICES + `/${exerciceId}/fiches-individuelles/individuel`;
}

// Montées capital
function monteesCapitalByExercice(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}`;
}

function monteesCapitalValidation(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/validation-montant`;
}

function monteesCapitalSouscription(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/souscription`;
}

function monteesCapitalRecalcul(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/recalcul`;
}

function getStateBulletinsObligatoires(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/bulletins-obligatoires/state`;
}

function generateBulletinsObligatoires(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/bulletins-obligatoires/generate`;
}

function getBulletinsObligatoires(exerciceId) {
  return MONTEES_CAPITAL + `/${exerciceId}/bulletins-obligatoires`;
}

// Affectation exercice Capital
function capAffectationByExerciceId(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}`;
}

function validateCapAffectationByExerciceId(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/validate`;
}

function areBulletinsSouscriptionGenerable(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/are-bulletins-souscription-generable`;
}

// Capital individuel
function exercicesAffectationIndividuel(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/individuel`;
}

function exportCapAffectationModeleExcel(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/export-modele-fichier`;
}
function importCapAffectationExcel(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/import`;
}

// Bulletins de souscription des IPS
function getStateBulletinsSouscriptionIps(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/bulletins-souscriptions-ips/state`;
}

function generateBulletinsSouscriptionIps(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/bulletins-souscriptions-ips/generate`;
}

function getBulletinsSouscriptionIps(exerciceId) {
  return AFFECTATION_CAPITAL + `/${exerciceId}/bulletins-souscriptions-ips`;
}

// Abo exercices

function getIsCampagneActive(aboExerciceId) {
  return ABO_EXERCICES + `/${aboExerciceId}/is-campagne-active`;
}

function importDonneesAssociesExcel(aboExerciceId) {
  return ABO_EXERCICES + `/${aboExerciceId}/import-anciennete`;
}

function exportDonneesAssociesModeleExcel(aboExerciceId) {
  return ABO_EXERCICES + `/${aboExerciceId}/export-anciennete`;
}

// Abo attributions

function aboAttributionByAboExerciceId(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}`;
}

function aboAttributionByAboExerciceIdIndividuel(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/individuel`;
}

function aboComputeSingleAttributionPreview(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/compute-single-attribution-preview`;
}

function aboAttributionValidate(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/validate`;
}

function downloadAboAttributionExcel(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/download-excel`;
}

function nbPartsMaxByAboExerciceId(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/nb-parts-sociales-abondees-max`;
}

function getTotauxAboAttribution(aboExerciceId) {
  return ABO_ATTRIBUTION + `/${aboExerciceId}/totaux`;
}

// Abo Docs
function getStateBulletinsSouscriptionAbondement(exerciceId) {
  return ABO_ATTRIBUTION + `/${exerciceId}/bulletins-souscription/state`;
}
function generateBulletinsSouscriptionAbondement(exerciceId) {
  return ABO_ATTRIBUTION + `/${exerciceId}/bulletins-souscription/generate`;
}
function downloadBulletinsSouscriptionAbondement(exerciceId) {
  return ABO_ATTRIBUTION + `/${exerciceId}/bulletins-souscription`;
}

// Pb exercices

function exercicesGetStateFichesIndividuelles(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/fiches-individuelles/state`;
}

function exercicesGenerateFichesIndividuelles(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/fiches-individuelles/generate`;
}

function exercicesFichesIndividuelles(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/fiches-individuelles`;
}

function exercicesFicheIndividuelle(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/fiches-individuelles/individuel`;
}

function exerciceCheckBeneficiaire(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/fiches-individuelles/check-beneficiaire`;
}

function exercicesRapportAnnuelAffectedParticipation(exerciceId) {
  return PB_EXERCICES + `/${exerciceId}/rapport-annuel`;
}

function exercicesRepartitionPb(exerciceId) {
  return PB_EXERCICES + REPARTITION_PB + `/${exerciceId}`;
}

function exercicesRepartitionPbIndividuel(exerciceId) {
  return PB_EXERCICES + REPARTITION_PB + `/individuel/${exerciceId}`;
}

function exercicesExportRepartitionPbExcel(exerciceId) {
  return PB_EXERCICES + REPARTITION_PB + `/${exerciceId}/export`;
}

function exercicesVentilationPb(exerciceId) {
  return VENTILATION_PB + `/${exerciceId}`;
}

function exercicesVentilationPbIndividuel(exerciceId) {
  return VENTILATION_PB + `/${exerciceId}/individuel`;
}

function exercicesValidateVentilationPb(exerciceId) {
  return VENTILATION_PB + `/${exerciceId}/validate`;
}

function exercicesExportAffectationPbExcel(exerciceId) {
  return VENTILATION_PB + `/${exerciceId}/export`;
}

function exercicesImportAffectationPbExcel(exerciceId) {
  return VENTILATION_PB + `/${exerciceId}/import`;
}

// Inté exercices

// -- Paramétrage
function plafondInteressementCollectif() {
  return INTE_EXERCICES + `/montant-plafond-interessement-collectif`;
}

function plafondInteressementIndividuel() {
  return INTE_EXERCICES + `/montant-plafond-interessement-individuel`;
}

// -- Répartition
function inteExercicesInteRepartition(inteExerciceId) {
  return INTE_EXERCICES + INTE_REPARTITION + `/${inteExerciceId}`;
}

function inteExercicesInteRepartitionIndividuel(inteExerciceId) {
  return INTE_EXERCICES + INTE_REPARTITION + `/individuel/${inteExerciceId}`;
}

function getStateFichesIndividuellesInte(inteExerciceId) {
  return INTE_EXERCICES + `/${inteExerciceId}/fiches-individuelles/state`;
}

function generateFichesIndividuellesInte(inteExerciceId) {
  return INTE_EXERCICES + `/${inteExerciceId}/fiches-individuelles/generate`;
}

function getFichesIndividuellesInte(inteExerciceId) {
  return INTE_EXERCICES + `/${inteExerciceId}/fiches-individuelles`;
}

function inteExerciceFicheIndividuelle(inteExerciceId) {
  return INTE_EXERCICES + `/${inteExerciceId}/fiches-individuelles/individuel`;
}

// -- Affectation
function inteExercicesInteAffectation(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}`;
}

function inteExercicesInteAffectationIndividuel(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}/individuel`;
}

function inteExercicesInteAffectationValidation(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}/validate`;
}

function inteExercicesInteAffectationImport(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}/import`;
}

function inteExercicesInteAffectationDownload(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}/download`;
}

function computeInteVersementImmediatNet(inteExerciceId) {
  return INTE_EXERCICES + INTE_AFFECTATION + `/${inteExerciceId}/versement-immediat-net`;
}

// Univers capital

function getByAssocieAndDate(id, dateMouvement) {
  return ANNULATIONS + `/totale/${id}/${dateMouvement}`;
}

// Suppression d'annulation totale
function deleteAnnulationTotale(associeId, dateMouvement) {
  return ANNULATIONS + `/totale/${associeId}/${dateMouvement}`;
}

function exercicesDateMinimumSouscription() {
  return CAP_EXERCICES + `/date-minimum-souscription`;
}


function evolutionExcel(dateReference) {
  return CAPITAL + `/evolution/excel/${dateReference}`
}

function capitalRapportCacADate(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return CAPITAL + `/rapport-cac/pdf/${dateString}`;
}

function capitalPortefeuilleByDate(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return CAPITAL + `/portefeuille/excel/${dateString}`;
}

function capitalADate(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return CAPITAL + `/${dateString}`;
}

function capitalLiberationSouscriptions(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return CAPITAL + `/rapport-liberation-souscriptions/excel/${dateString}`;
}

function getDisponibiliteDuCapital(dateString) {
  return CAPITAL + `/disponibilite-du-capital/excel/${dateString}`;
}

// Asynchrone états du capital
function getStateEtatsDuCapital() {
  return CAPITAL + `/etats-du-capital/state`;
}

function generateEtatsDuCapital(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return CAPITAL + `/etats-du-capital/generate/${dateString}`;
}

function getEtatsDuCapital() {
  return CAPITAL + `/etats-du-capital`;
}

// Asynchrone etats de la participation
function getStateEtatsDeLaParticipation() {
  return OPERATIONS + `/etats-de-la-participation/state`;
}

function generateEtatsDeLaParticipation(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return OPERATIONS + `/${dateString}/etats-de-la-participation/generate`;
}

function getEtatsDeLaParticipation() {
  return OPERATIONS + `/etats-de-la-participation`;
}

// Périodes de travail
function deletePeriodeTravailById(periodeTravailId) {
  return PERIODES_TRAVAIL + `/${periodeTravailId}`;
}

function getPeriodesTravailSalarie(periodeTravailId) {
  return PERIODES_TRAVAIL + `/salarie/${periodeTravailId}`;
}

function getPeriodesTravailInPeriode(date_str) {
  return PERIODES_TRAVAIL + `/periodes-travail-a-date/${date_str}`;
}

// Associes
function associesFeuilleEmargement(date) {
  return ASSOCIES + `/feuille-emargement/${date}`;
}

function associesEtatDuCapital(associeId) {
  return ASSOCIES + `/${associeId}/etat-du-capital`;
}

function associesEtatDuCapitalIndividuel() {
  return ASSOCIES + `/etat-du-capital-individuel`;
}

function droitsVotePerAssocie(date) {
  let dateString;
  if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = Utils.displayDateToIsoFormat(date);
  }
  return ASSOCIES + `/droits-vote/excel/${dateString}`;
}

function deleteMouvementById(reglementId) {
  return MOUVEMENTS + `/${reglementId}/`;
}

function getMvtsAssocie() {
  return MOUVEMENTS + "/espaceassocie";
}

function importMouvementsPartsSociales() {
  return MOUVEMENTS + "/import";
}

function exportMouvementsPartsSociales() {
  return MOUVEMENTS + "/export";
}

function importBulletinsMasse() {
  return MOUVEMENTS + "/import-bulletins-masse";
}

function exportBulletinsMasse() {
  return MOUVEMENTS + "/export-bulletins-masse";
}

// Téléchagement de la totalité des mouvements
function downloadTotaliteMouvementsExcel() {
  return MOUVEMENTS + "/download-totalite-mouvements-excel";
}

function generationSepa() {
  return DETTES + "/genererfichiersepa";
}

function downloadDetail(dette_id) {
  return DETTES + `/${dette_id}/download-detail`;
}
function downloadDettes() {
  return DETTES + "/download";
}
// PbExercices
function getExercicesDisponiblesTauxRemu() {
  return PB_EXERCICES + "/exercices-disponibles-taux-remu";
}

function getPassPlafondsIndivEtCommun(pb_exercice_id) {
  return PB_EXERCICES + `/montant-pb-plafond-individuel-et-commun/${pb_exercice_id}`;
}

function getPassPlafondsIndivEtCommunNoExercice() {
  return PB_EXERCICES + "/montant-pb-plafond-individuel-et-commun";
}

function getMontantRenumerationSalairePlafond() {
  return PB_EXERCICES + "/montant-remuneration-selon-salaire-plafond";
}

function getMontantRenumerationSalairePlafondFromExercice(exercice_id) {
  return PB_EXERCICES + `/montant-remuneration-selon-salaire-plafond/${exercice_id}`;
}

function computeMontantPbPlancherLegal() {
  return PB_EXERCICES + "/montant-pb-plancher-legal";
}

function computeMontantPbPlancherScop() {
  return PB_EXERCICES + "/montant-pb-plancher-scop";
}

function computeMontantPbPlafondScopBeneficeOnly() {
  return PB_EXERCICES + "/montant-pb-plafond-scop/benefice-uniquement";
}

function computeMontantPbPlafondScopBeneficeImpactCapital() {
  return PB_EXERCICES + "/montant-pb-plafond-scop/benefice-impact-capital";
}

function computeVersementImmediatNet(pb_exercice_id) {
  return PB_EXERCICES + `/${pb_exercice_id}/versement-immediat-net`;
}

function getAllParticipationInteretsTotaux() {
  return PB_EXERCICES + `/participation-interets-totaux-all`;
}

// reglements
function deleteReglementById(reglementId) {
  return REGLEMENTS + `/${reglementId}`;
}


// Fiscalite cerfa
function getCorrespondingCerfaVersion() {
  return FISCALITE_CERFA + `/corresponding`;
}

// rabbitmq tasks
function getBatchesNotificationsList() {
  return ASYNCHRONOUS + `/batches`;
}
function getTasksList() {
  return ASYNCHRONOUS + `/tasksMonitoring`;
}

//fiscalite_capital
function ifu2561Infos_capital() {
  return FISCALITE_CAPITAL + `/ifu2561/infos`;
}

function ifu2561PDF_capital() {
  return FISCALITE_CAPITAL + `/ifu2561/pdf`;
}

function ifu2561terAssociePDF() {
  return FISCALITE_CAPITAL + `/ifu2561ter/espaceassocie/pdf`;
}

function pfu2777Infos_capital() {
  return FISCALITE_CAPITAL + `/pfu2777/infos`;
}

function pfu2777PDF_capital() {
  return FISCALITE_CAPITAL + `/pfu2777/pdf`;
}

function ifuTeletransmission_capital(exerciceId) {
  return FISCALITE_CAPITAL + `/ifuteletransmission/${exerciceId}`;
}

function listeMinisterielle(exerciceId) {
  return FISCALITE_CAPITAL + `/liste_ministerielle/${exerciceId}`;
}

//fiscalite_participation
function ifu2561Infos_participation() {
  return FISCALITE_PARTICIPATION + `/ifu2561/infos`;
}

function ifu2561PDF_participation() {
  return FISCALITE_PARTICIPATION + `/ifu2561/pdf`;
}

function pfu2777Infos_participation() {
  return FISCALITE_PARTICIPATION + `/pfu2777/infos`;
}

function pfu2777PDF_participation() {
  return FISCALITE_PARTICIPATION + `/pfu2777/pdf`;
}

//charts
function allAssocieCharts() {
  return CAP_CHARTS + "/associe";
}

function allCompanyChartsCapital() {
  return CAP_CHARTS + "/company_capital";
}

function associeCharts() {
  return CAP_CHARTS + "/espaceassocie";
}

function allDroitsDeVoteData() {
  return CAP_CHARTS + "/droits_de_vote";
}

function companyChartsParticipation() {
  return PB_CHARTS + "/company_participation";
}

function beneficiaireCharts() {
  return PB_CHARTS + "/beneficiaire";
}

function beneficiaireChartsIndividuel() {
  return PB_CHARTS + "/beneficiaire/individuel";
}

// Docs tiers
function documents(collaborateurId, type_document) {
  if (!_.isNil(type_document)) {
    return DOCUMENTS + "/" + collaborateurId + "/" + type_document;
  } else {
    return DOCUMENTS + "/" + collaborateurId;
  }
}

function documentsPersonnels(type_document) {
  if (!_.isNil(type_document)) {
    return DOCUMENTS + "/personnels/" + type_document;
  } else {
    return DOCUMENTS + "/personnels/";
  }
}

function documentsGeneral() {
  return DOCUMENTS + "/general";
}

function documentDownload(docId) {
  return DOCUMENTS + "/" + docId + "/download";
}

function documentAccordDownload(type_document) {
  return DOCUMENTS + "/accords/" + type_document + "/download";
}

function documentPersonnelDownload(docId) {
  return DOCUMENTS + "/personnel/" + docId + "/download";
}

// Acquisition des infos d'un document
function getByIdPersonnel(docId) {
  return DOCUMENTS + "/personnel/" + docId;
}

// personne
function importTiersPeriodesTravailExcel() {
  return PERSONNES + "/import";
}

function exportModeleTiersPeriodesTravailExcel() {
  return PERSONNES + "/export-modele";
}

function exportTiersPeriodesTravailExcel() {
  return PERSONNES + "/export";
}

function getEmptyUserAccessPerson() {
  return PERSONNES + "/no-user-access";
}

// personne physique
function anonymizePersonnePhysique(personnePhysiqueId) {
  return PERSONNES_PHYSIQUES + `/${personnePhysiqueId}/anonymize`;
}
function infosEspacePersonnelPPUrl() {
  return PERSONNES_PHYSIQUES + "/espace-personnel";
}

// personne morale
function infosEspacePersonnelPMUrl() {
  return PERSONNES_MORALES + "/espace-personnel";
}

// users
function passwordModificationResponsable(userId) {
  return USERS + "/password-modification/" + userId;
}

function changePassword() {
  return USERS + "/change-password";
}

function forgotPassword() {
  return USERS + "/forgot-password";
}

function createMassAccess() {
  return USERS + "/create-mass-access";
}

// administration
function generatedDocumentsLogo() {
  return ADMINISTRATION + "/generated-documents/logo";
}
function generatedDocumentsFooter() {
  return ADMINISTRATION + "/generated-documents/footer";
}
// import de fiche initial
function uploadFileInitial() {
  return ADMINISTRATION + "/import-initial";
}

// export de fiche initial
function downloadFileInitial() {
  return ADMINISTRATION + "/export-initial";
}
function downloadModelFileInitial() {
  return ADMINISTRATION + "/export-model-initial";
}

function documentsFields() {
  return ADMINISTRATION + "/documents-fields";
}

// nombre de tiers actifs
function downloadFileTiersActifs(dateString) {
  return ADMINISTRATION + `/export-tiers-actifs/${dateString}`;
}

// PB Supports
function livretEpargneSal() {
  return PB_SUPPORTS + "/livret-epargne-salariale";
}

function getStateInteretsRemuneration(pbSupportId) {
  return PB_SUPPORTS + `/${pbSupportId}/interets-remuneration/state`;
}

function pbTauxRemuBySupportId(pbSupportId) {
  return PB_SUPPORTS + `/${pbSupportId}/taux-remuneration`;
}

// Supports d'intéressement
function inteLivretEpargneSal() {
  return INTE_SUPPORTS + "/inte-livret-epargne-salariale";
}

// Operations
function getOperationsBySupports() {
  return OPERATIONS + `/operations-by-supports`;
}

function getOperationsBySupportsSimplifie() {
  return OPERATIONS + `/operations-by-supports-simplifie`;
}

function getOperationsBySupportsSimplifieForSupport() {
  return OPERATIONS + `/operations-by-supports-simplifie/support`;
}

function getOperationsBySupportsSimplifieIndividuel(dateReference) {
  return OPERATIONS + `/operations-by-supports-simplifie/individuel/${dateReference}`;
}

function exportDetailOperations() {
  return OPERATIONS + `/operations-by-supports/export`;
}

function getDisponibilitesBeneficiaires(dateReference) {
  return OPERATIONS + `/disponibilites-beneficiaires/${dateReference}`;
}

function exportDisponibilitesBeneficiaires(dateReference) {
  return OPERATIONS + `/disponibilites-beneficiaires/export/${dateReference}`;
}

// Deblocages
function getDeblocagesIndividuel() {
  return PB_DEBLOCAGES + `/individuel`;
}

function downloadBulletinDeblocage(deblocageId) {
  return PB_DEBLOCAGES + `/bulletin/${deblocageId}`;
}

function downloadBulletinDeblocageIndividuel(deblocageId) {
  return PB_DEBLOCAGES + `/bulletin/individuel/${deblocageId}`;
}

function getMontantDeblocageNetAnticipe() {
  return PB_DEBLOCAGES_ANTICIPES + `/montant-deblocage-net`;
}

function getMontantDeblocageNetTerme() {
  return PB_DEBLOCAGES_TERMES + `/montant-deblocage-net`;
}

function getDeblocagesGroupes() {
  return PB_DEBLOCAGES_TERMES + `/groupes`;
}

// TMOP
function tauxMoyenRendementObliById(tauxRemuId) {
  return TAUX_MOYEN_RENDEMENT_OBLI + `/${tauxRemuId}`;
}

function getTauxRemuProposition() {
  return TAUX_MOYEN_RENDEMENT_OBLI + `/proposition-taux-remu`;
}

function getTauxRemuDetailsProposition() {
  return TAUX_MOYEN_RENDEMENT_OBLI + `/details-proposition-taux-remu`;
}

function getTauxRemuPropositionValide() {
  return TAUX_MOYEN_RENDEMENT_OBLI + `/proposition-taux-remu-valide`;
}


// Bénéficiaire
function etatDeLaParticipation(beneficiaire_id) {
  return BENEFICIAIRES + `/etat-de-la-participation/${beneficiaire_id}`;
}

function getBeneficiaireIndividuel() {
  return BENEFICIAIRES + `/individuel`;
}

// PB Salaires presences
function pbSalairePresencesForExercice(pbExerciceId) {
  return PB_SALAIRE_PRESENCES + `/${pbExerciceId}`;
}

function totauxPbSalairePresencesForExercice(pbExerciceId) {
  return PB_SALAIRE_PRESENCES + `/totaux/${pbExerciceId}`;
}

function exportPbSalairePresencesForExercice(pbExerciceId) {
  return PB_SALAIRE_PRESENCES + `/${pbExerciceId}/export`;
}

function importPbSalairePresencesForExercice(pbExerciceId) {
  return PB_SALAIRE_PRESENCES + `/${pbExerciceId}/import`;
}

// Inte Salaires presences
function inteSalairePresencesForExercice(inteExerciceId) {
  return INTE_SALAIRE_PRESENCES + `/${inteExerciceId}`;
}

function totauxInteSalairePresencesForExercice(inteExerciceId) {
  return INTE_SALAIRE_PRESENCES + `/totaux/${inteExerciceId}`;
}

function exportInteSalairePresencesForExercice(inteExerciceId) {
  return INTE_SALAIRE_PRESENCES + `/${inteExerciceId}/export`;
}

function importInteSalairePresencesForExercice(inteExerciceId) {
  return INTE_SALAIRE_PRESENCES + `/${inteExerciceId}/import`;
}

// Taux fiscalité
function deleteTauxFiscaliteById(tauxFiscaliteId) {
  return TAUX_FISCALITES + `/${tauxFiscaliteId}`;
}

function getTauxFiscaliteByDate(dateString) {
  return TAUX_FISCALITES + `/${dateString}`;
}

// SCIC Général
function getExcelProfilsAssocies(dateString) {
  return SCIC_RAPPORTS + `/profils-associes/excel/${dateString}`;
}

// SCIC Catégories
function getGraphParamCategorieGraphRepartitionMontantCapital(dateString) {
  return SCIC_PARAM_CATEGORIE + `/graph/repartition-montant-capital/${dateString}`;
}

// SCIC Collèges
function scicCollegeMassEditing() {
  return SCIC_COLLEGE + `/mass-editing`;
}

function getGraphCollegeGraphRepartitionAssocies(dateString) {
  return SCIC_COLLEGE + `/graph/repartition-associes/${dateString}`;
}

// SCIC Sous-catégories
function scicSousCategorieMassEditing() {
  return SCIC_SOUS_CATEGORIE + `/mass-editing`;
}

function getGraphSousCategorieGraphRepartitionMontantCapital(dateString) {
  return SCIC_SOUS_CATEGORIE + `/graph/repartition-montant-capital/${dateString}`;
}

function getAttestationFiscale(exercice_id) {
  return SCIC_DOCUMENTS + `/attestation-fiscale/${exercice_id}`;
}

function getAttestationFiscaleForAssocieList() {
  return SCIC_DOCUMENTS + `/attestation-fiscale/list`;
}

// Chiffrement
function getKeysPresenceAtDate(dateString) {
  return CHIFFREMENT + `/presence-clefs/${dateString}`;
}