import Index from "@/views/Index";
import Exercice from "@/views/common/exercice/Exercice";
import ExercicesList from "@/views/common/exercice/ExercicesList";
import rolesList from "@/router/rolesList";

export default {
  path: "exercices/",
  component: Index,
  children: [
    {
      path: "",
      name: "exercices",
      component: ExercicesList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: ":exerciceId/edit/",
      name: "edition-exercice",
      props: {
        creationMode: false
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "create/",
      name: "creation-exercice",
      props: {
        creationMode: true
      },
      component: Exercice,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
  ]
};
