<template>
    <v-row no-gutters align="center">
        <v-col>
            <ValidationProvider name="Taux de rémunération" vid="taux" v-slot="{ errors }"
                            rules="required|between:0,100">
                <v-formatted-number-text-field v-model="localTauxRemu"
                                                labelValue="Taux de rémunération"
                                                prefixValue="%"
                                                :reverseValue="true"
                                                :errors="errors"
                                                :disabledValue="true"
                />
            </ValidationProvider>
        </v-col>
        <!-- Fenêtre modale -->
        <v-col class="flex-grow-0" v-if="isEditable">
            <v-dialog v-model="dialog" max-width="600px" content-class="overflow-visible" eager>
                <template v-slot:activator="{ on }">
                    <v-icon-button-with-tool-tip
                                                detail="Modifier le taux"
                                                icon="$vuetify.icons.edit"
                                                :activator="on"
                                                btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                                btnColorClass="transparent"
                                                iconColorClass="grey darken-1"
                                                block
                    />
                </template>
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>Taux d'intérêts courus</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-taux-remu-field-with-tmop :tauxRemu="localTauxRemu"
                                                                :dateReference="dateReferenceTauxRemu"
                                                                @fetch-new-taux-remu="updateTauxRemu($event)"
                                                                @update-taux-remu="localTauxRemu = $event"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-row justify="end" dense>
                            <!-- Bouton modal -->
                            <v-col cols="4">
                                <v-btn @click="onCancelClick" block>
                                    Annuler
                                </v-btn>
                            </v-col>
                            <!-- Bouton modal -->
                            <v-col cols="4">
                                <v-btn color="primary" @click="onSaveClick" block>
                                    Enregistrer
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>


<script>
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VTauxRemuFieldWithTmop from "@/components/tauxRemunerationSupport/VTauxRemuFieldWithTmop"

    /**
     * Composant affichant une fenêtre modale contenant les informations concernant
     * un taux de rémunération pour faire un déblocage
     * @displayName Participation - VTauxRemuForDeblocage
     */
    export default {
        name: "v-taux-remu-for-deblocage",
        components: {
            VIconButtonWithToolTip,
            VFormattedNumberTextField,
            VTauxRemuFieldWithTmop
        },
        props: {
            isEditable: {
                type: Boolean,
                default: true,
            },
            tauxRemu: {
                type: Number,
                required: false,
            },
            dateReferenceTauxRemu: {
                type: [Date, String],
                required: false,
            }
        },
        watch: {
            tauxRemu(newVal) {
                this.localTauxRemu = newVal;
            }
        },
        data() {
            return {
                dialog: false,
                localTauxRemu: undefined,
            }
        },
        methods: {
            onCancelClick(){
                this.localTauxRemu = this.tauxRemu;
                this.dialog = false;
            },
            onSaveClick(){
                this.updateTauxRemu(this.localTauxRemu);
                this.dialog = false;
            },
            updateTauxRemu(newTaux){
                this.$emit("update-taux-remu", newTaux);
            }
        }
    }
</script>
