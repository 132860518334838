<!-- VUETIFY2 - OK -->
<template>
    <v-app-bar app clipped-left fixed dark color="primary">
        <loading-screen ref="loadingComponent"/>
        <v-app-bar-nav-icon @click="$emit('toggle-navigation')">
            <v-icon>{{"$vuetify.icons.bars"}}</v-icon>
        </v-app-bar-nav-icon>
        <v-row justify="space-around" no-gutters>
            <v-col cols="4">
                <logo-svg-complet-blanc width="170px"/>
            </v-col>
            <v-col align-self="center" cols="4">
                <v-row justify="center">
                    <span class="app-title" style="text-transform: uppercase;">société {{this.companyName}}</span>
                </v-row>
            </v-col>
            <v-col cols="4" align-self="center">
                <v-row class="header-buttons" justify="end" align="center">
                    <v-toolbar-items class="md6 lg6 xl6 hidden-xs-only">
                        <v-menu offset-y nudge-bottom="5">
                            <template v-slot:activator="{ on }">
                                <v-btn text v-on="on">
                                    <v-icon left size="22">{{"$vuetify.icons.help"}}</v-icon>
                                    Aide
                                    <v-icon right small>{{"$vuetify.icons.down_arrow"}}</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-icon-button-with-tool-tip
                                        detailButtonText="Télécharger le manuel utilisateur"
                                        :icon="'$vuetify.icons.download'"
                                        btnRound btnText btnSmall iconSmall tooltipBottom
                                        btnColorClass="transparent"
                                        iconColorClass="grey darken-1"
                                        @click="downloadManuelUtilisateur"
                                        :isTooltipDisabled="true"
                                    />
                                </v-list-item>
                                <v-list-item>
                                    <v-icon-button-with-tool-tip detailButtonText="Rapport de version"
                                                                 :icon="'$vuetify.icons.version'"
                                                                 btnRound btnText btnSmall iconSmall
                                                                 tooltipBottom
                                                                 btnColorClass="transparent"
                                                                 iconColorClass="grey darken-1"
                                                                 @click="goToRapportVersion"
                                                                 :isTooltipDisabled="true"
                                    />
                                </v-list-item>
                                <v-list-item>
                                    <v-about-dialog/>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn id="user_header_btn" text>
                            <v-icon left size="22">{{"$vuetify.icons.per_phy"}}</v-icon>
                            {{username}}
                            <v-icon right small>{{"$vuetify.icons.down_arrow"}}</v-icon>
                        </v-btn>
                        <v-menu activator="#user_header_btn" offset-y nudge-bottom="5">
                            <v-list>
                                <v-list-item>
                                        <v-icon-button-with-tool-tip
                                            :routeCible="{name: changePasswordRoute}"
                                            detail="Modification du mot de passe"
                                            :icon="'$vuetify.icons.key'"
                                            btnRound btnText btnSmall iconSmall tooltipBottom
                                            btnColorClass="transparent"
                                            iconColorClass="grey darken-1"
                                            detailButtonText="Modifier le mot de passe"
                                    />
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn text @click="onLogout">
                            <v-icon left size="22">{{"$vuetify.icons.sign_out"}}</v-icon>
                            Se déconnecter
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items class="hidden-sm-and-up">
                        <v-btn text icon>
                            <v-icon>{{"$vuetify.icons.help"}}</v-icon>
                        </v-btn>
                        <v-btn text icon>
                            <v-icon>{{"$vuetify.icons.per_phy"}}</v-icon>
                        </v-btn>
                        <v-btn text icon>
                            <v-icon>{{"$vuetify.icons.sign_out"}}</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-row>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>

    import authService from '@/services/authService.js';
    import LogoSvgCompletBlanc from "@/components/assets/LogoSvgCompletBlanc.vue";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VAboutDialog from "@/components/VAboutDialog.vue";

    export default {
        components: {
            LogoSvgCompletBlanc,
            loadingScreen,
            VIconButtonWithToolTip,
            VAboutDialog
        },
        data() {
            return {
                username: "",
                changePasswordRoute: "changePassword",
                appVersion: process.env.VUE_APP_VERSION,
            };
        },
        mounted() {
            this.getAuthenticatedUser();
            this.refreshCompanyName();
        },
        computed: {
            companyName() {
                return this.$store.getters.getCompanyName();
            }
        },
        methods: {
            onLogout: function () {
                authService.logout();
            },
            getAuthenticatedUser: function () {
                authService.getUsername().then(
                    user => {
                        this.username = user
                    }
                );
            },
            downloadManuelUtilisateur: function () {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.manuelUtilisateur.getAll()
                    .then(response => {
                        this.$refs.loadingComponent.resetLoading();
                        let versionUnderscore = this.appVersion.replace(/\./g, "_");
                        Utils.downloadFile(
                            response,
                            "manuels_utilisateur_eziscop_" + versionUnderscore + ".zip",
                            Utils.zipMimetype,
                        );
                    })
                    .catch(err => {
                        console.error(err);
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            goToRapportVersion() {
                window.open("https://www.eziscop.fr/version-eziscop", "versionEziscop");
            },
            refreshCompanyName() {
                return internalApi.company.getCurrentCompany()
                    .then(result => {
                        this.$store.commit("SET_CURRENT_COMPANY", result);
                    });
            },
        }
    };
</script>

<style scoped>
    .app-title {
        font-size: 22px;
    }
    .row.header-buttons {
        height: 64px;
    }
</style>
