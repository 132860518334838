import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages.json";
import Utils from "@/utils";

export default personneMoraleApi;

function personneMoraleApi() {
  const specificApi = {
    putInfosEspacePersonnelWithToastConfirmation: putInfosEspacePersonnelWithToastConfirmation(),
    getInfosEspacePersonnel: getInfosEspacePersonnel(),
  };
  return Object.assign(specificApi,defaultApi(internalApiRoutes.personnesMorales.baseUrl, [
    ACCEPTED_METHODS.getLabels,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById
  ]));
}

function putInfosEspacePersonnelWithToastConfirmation() {
  function putInfosEspacePersonnel(body, queryParams, headers) {
    let path = internalApiRoutes.personnesMorales.putInfosEspacePersonnelUrl();
    let putForPath = apiConstructor.put(path, body);
    return putForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return putInfosEspacePersonnel;
}

function getInfosEspacePersonnel() {
  function infosEspacePersonnel(queryParams, headers) {
    let path = internalApiRoutes.personnesMorales.getInfosEspacePersonnelUrl();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return infosEspacePersonnel;
}