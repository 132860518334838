<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-row no-gutters>
            <v-col md="12" lg="12" xl="12">
                <loading-screen ref="loadingComponent"/>
                <v-data-table :search="search"
                                :headers="headers"
                                :items="localTauxRemuSupportList"
                                :options.sync="pagination"
                                :height="datatableSize"
                                fixed-header
                                hide-default-footer
                                :footer-props="footerProps"
                                class="elevation-1"
                                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="text-lg-center">{{item.libelle_exercice}}</td>
                            <td class="text-lg-center">{{item.taux_remu}} %</td>
                            <td class="text-lg-center">{{item.date_versement | dateIsoToFrFilter}}</td>
                            <td>
                                <v-row justify="center">
                                    <v-col md="3" lg="3" xl="2">
                                        <v-taux-remu-for-support-edition v-if="isNew(item)"
                                                            :oldTauxRemu="item"
                                                            :pbSupportId="pbSupportId"
                                                            @edit-taux-remu="(oldTaux, newTaux) => editTauxRemu(oldTaux, newTaux)"
                                        />
                                    </v-col>
                                    <v-col md="3" lg="3" xl="2">
                                        <v-custom-confirmation-dialog v-if="isNew(item)"
                                                                    message="Etes vous sûr de vouloir supprimer ce taux de rémunération ?"
                                                                    :isIcon="true"
                                                                    :iconValue="'$vuetify.icons.delete'"
                                                                    @action-confirmee="removeTauxRemu(item)"
                                                                    tooltipDetailMessage="Supprimer le taux de rémunération."/>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import VTauxRemuForSupportEdition from "./VTauxRemuForSupportEdition";
    import internalApi from "@/api/internalApi";
    import Vue from 'vue';

    export default {
        name: "TauxRemunerationSupportList",
        components: {
            VTauxRemuForSupportEdition,
            VCustomConfirmationDialog,
            loadingScreen,
        },
        props: {
            pbSupportId: {
                type: Number,
                default: undefined,
            },
            tauxRemuSupportList: {
                type: Array,
                default: undefined,
            },
            datatableSize: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                pagination: {
                    sortBy: ['date_versement'],
                    sortDesc: [true],
                },
                headers: [
                    {
                        text: "Exercice",
                        value: "libelle_exercice",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Taux de rémunération",
                        value: "taux_remu",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Date de versement",
                        value: "date_versement",
                        sortable: true,
                        align: "center",
                    },
                    {
                        text: "Actions",
                        value: "actions",
                        sortable: false,
                        align: "center",
                    },
                ],
                search: "",
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                exercicesListById: {},
            }
        },
        computed: {
            localTauxRemuSupportList: {
                get: function () {
                    return this.tauxRemuSupportList;
                },
                set: function (newvalue) {},
            },
        },
        watch: {
            localTauxRemuSupportList() {
                this.getLibellesPbExercices();
            }
        },
        methods: {
            getLibellesPbExercices() {
                return internalApi.exercice.getExercicesEnumByName()
                    .then(results => {
                        let listById = {};
                        results.forEach(exercice => {
                            listById[exercice.id] = exercice;
                        });
                        this.exercicesListById = listById;
                        // Enrichir localTauxRemuSupportList avec le libellé de l'exercice
                        this.localTauxRemuSupportList.forEach((taux, index) => {
                            // Utilisation de la méthode prévue par Vue pour que la modification de l'object déclenche une update de la datatable
                            // Ici ajoute l'attribut 'libelle_exercice' avec la valeur this.libellePbExercice(taux.pb_exercice_id) dans l'objet taux
                            Vue.set(taux, 'libelle_exercice', this.libellePbExercice(taux.pb_exercice_id))
                        });
                    });
            },
            libellePbExercice(pbExerciceId) {
                return this.exercicesListById[pbExerciceId].libelle;
            },
            isNew(tauxRemu) {
                return _.isNil(tauxRemu.id);
            },
            editTauxRemu(oldTauxRemu, newTauxRemu) {
                this.removeTauxRemu(oldTauxRemu);
                this.localTauxRemuSupportList.push(newTauxRemu);
                this.getLibellesPbExercices();
                this.$emit("edit-taux-remu", newTauxRemu);
            },
            removeTauxRemu(tauxRemu) {
                for (let i = 0; i < this.localTauxRemuSupportList.length; i++) {
                    if (this.localTauxRemuSupportList[i] === tauxRemu) {
                        this.localTauxRemuSupportList.splice(i, 1);
                    }
                }
                this.$emit("remove-taux-remu");
            },
        }
    }
</script>