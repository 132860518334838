// <!-- VUETIFY2 - OK -->
import Fiscalite from "@/views/common/fiscalite/Fiscalite";

export default {
    data() {
        return {
            univers: "PARTICIPATION",
            selectableExercice: false,
            selectableYear: true,
            selectableMonth: false,
            documentsList: [
                {
                    title: "2561/2561 ter",
                    component: "IFU_2561_participation"
                },
                {
                    title: "2777",
                    component: "PFU_2777_participation"
                }
            ],
        };
    },
    extends: Fiscalite,
    methods: {

    }
};
