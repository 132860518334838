import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";

export default associeApi;

function associeApi() {
  const specificApi = {
    getAll: getAll(),
    getEtatDuCapitalById: getEtatDuCapitalById(),
    getEtatDuCapitalIndividuel: getEtatDuCapitalIndividuel(),
    getDroitsDeVotePerAssocie: getDroitsDeVotePerAssocie(),
    getFeuilleEmargement: getFeuilleEmargement(),
  };

  return Object.assign(
    specificApi,
    defaultApi(internalApiRoutes.associes)
  );
}

function getEtatDuCapitalById() {
  function etat_du_capital(associeId, queryParams, headers) {
    let path = internalApiRoutes.associesEtatDuCapital(associeId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return etat_du_capital;
}

function getEtatDuCapitalIndividuel() {
  function etat_du_capital(queryParams, headers) {
    let path = internalApiRoutes.associesEtatDuCapitalIndividuel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return etat_du_capital;
}

function getDroitsDeVotePerAssocie() {
  function droitsVote(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let droitsVotePath = internalApiRoutes.droitsVotePerAssocie(dateString);
    let getForPath = apiConstructor.get(droitsVotePath);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return droitsVote;
}

function getAll() {
  function getAllAssocies(queryParams, headers) {
    let path = internalApiRoutes.associes;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getAllAssocies;
}

function getFeuilleEmargement() {
  function feuilleEmargement(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.associesFeuilleEmargement(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    })
  }

  return feuilleEmargement;
}
