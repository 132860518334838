import router from '@/router/';
import internalApi from "@/api/internalApi";
import jwt_decode from "jwt-decode";
import store from "@/store/";
import {TOAST} from "@/plugins/toast/definition";

const SESSION_STORAGE_TOKEN = "userToken";
const SESSION_EXPIRATION_DATE = "expirationDate";
const SESSION_ROLES = "roles";
const SESSION_PERSONNE_TYPE = "personne_type";
const SESSION_ASSOCIE_ID = "associe_id";
const SESSION_BENEFICIAIRE_ID = "beneficiaire_id";
const SESSION_PERSONNE_ID = "personne_id";
const TENANT = "tenant";
const ACCES_CAPITAL = "accesCapital";
const ACCES_PARTICIPATION = "accesParticipation";
const ACCES_INTERESSEMENT = "accesInteressement";
const ACCES_EXTENSION_SCIC = "acces_extension_scic"
const ACCES_EXTENSION_ABONDEMENT = "acces_extension_abondement"
const SESSION_DOIT_CHANGER_MOT_DE_PASSE = "doit_changer_mot_de_passe"

export default {
  refreshPromise: null,
  logoutAlreadyPerformed: false, // Variable pour éviter plusieurs deconnexion en //, dû aux promises par exemple
  /**
   * Fonction permettant de se connecter à eziscop
   * @param username
   * @param password
   * @param tenant
   */
  login: function (username, password, tenant) {
    this.logoutAlreadyPerformed = false;
    sessionStorage.setItem(TENANT, tenant);
    const connectionInfos = {
      "username": username,
      "password": password
    };
    internalApi.authentication.login(connectionInfos).then(result => {
      const token = result.access_token;
      _saveNewToken(token);
      this.initStore();
      router.push({name: "home"});
    });
  },

  /**
   * Fonction permettant de se déconnecter à eziscop : reset le token et redirige vers la page de login
   */
  logout: function () {
    if (!this.logoutAlreadyPerformed) {
      const tenant = sessionStorage.getItem(TENANT);
      _resetToken();
      this.resetStore();
      if (!_.isNil(tenant)) {
        router.push({
          name: "login",
          params: {
            tenant: tenant
          }
        });
      } else {
        router.push({
          name: "wrongurl"
        });
      }
      this.logoutAlreadyPerformed = true;
    }
  },

  /**
   * Initialise le store avec les données à acquérir dès l'authentification
   */
  initStore: function () {
    store.dispatch("fetchDroitsAcces");
  },

  /**
   * Reset toutes les entrées du store
   */
  resetStore: function () {
    store.dispatch("resetFilters");
    store.dispatch("resetCapCompany");
    store.dispatch("resetCompany");
    store.dispatch("resetDroitsAcces");
    store.dispatch("resetPaginations");
  },

  /**
   * Fonction renvoyant une promise contenant un token valide :
   * - si le token est null, vide ou 'undefined' => renvoi une erreur
   * - si sa date d'expiration n'est pas passé => renvoi ce token
   * - sinon appelle le refresh et le rafraichi
   * @return {*}
   */
  getValidToken: function () {
    let currentToken = sessionStorage.getItem(SESSION_STORAGE_TOKEN);
    if (!_.isEmpty(currentToken) && "undefined" !== currentToken) {
      const now = Math.floor(Date.now() / 1000);
      const expiration_date = sessionStorage.getItem(SESSION_EXPIRATION_DATE);
      if (expiration_date < now) {
        return this.refreshToken();
      }
      return Promise.resolve(currentToken);
    } else {
      return Promise.reject(new Error("Le token est null"));
    }
  },

  /**
   * Récupère un token actualisé pour remplacer l'actuel
   */
  refreshToken: function() {
    // on utilise une variable unique refreshpromise pour ne pas lancer des refresh en //
    if (_.isNil(this.refreshPromise)) {
      this.refreshPromise = Promise.resolve(internalApi.authentication.refresh()
        .then(result => {
          const token = result.access_token;
          _saveNewToken(token);
          this.refreshPromise = null;
          return token;
        })
        .catch(err => {
          this.refreshPromise = null;
          this.logout();
          return null;
        })
      );
    }
    return this.refreshPromise;
  },

  /**
   * Renvoi le token courant, qu'il soit valide ou non
   * @return {string}
   */
  getTokenWithoutRefresh: function () {
    return sessionStorage.getItem(SESSION_STORAGE_TOKEN);
  },

  /**
   * Renvoi le nom de l'utilisateur connecté
   * @return {Promise<T>}
   */
  getUsername: function () {
    return Promise.resolve(this.getValidToken()
      .then(token => {
        try {
          const decoded_token = _decode_token(token);
          return decoded_token.display_name;
        } catch (err) {
          console.error("Erreur lors du decodage du token : " + err);
          this.logout();
          return err;
        }
      })
      .catch(this.logout));
  },

  /**
   * Renvoi l'id associé de l'utilisateur connecté
   * @return {String}
   */
  getIdAssocie: function () {
    return sessionStorage.getItem(SESSION_ASSOCIE_ID);
  },

  /**
   * Renvoi l'id beneficiaire de l'utilisateur connecté
   * @return {String}
   */
  getIdBeneficiaire: function () {
    return sessionStorage.getItem(SESSION_BENEFICIAIRE_ID);
  },

  /**
   * Renvoi l'id personne de l'utilisateur connecté
   * @return {String}
   */
  getIdPersonne: function () {
    return sessionStorage.getItem(SESSION_PERSONNE_ID);
  },
  
  /**
   * Redirige vers la page indiquant un accès non autorisé
   */
  unauthorized: function () {
    router.push({name: "unauthorized"});
  },

  /**
   * Si l'utilisateur doit changer son mot de passe,
   * on le force à aller sur la page concernée
   */
  forceChangePassword: function () {
    TOAST.warning("Vous devez changer de mot de passe avant de poursuivre", "Votre mot de passe vous a été communiqué récemment, veuillez le changer pour vous assurer que personne d'autre ne puisse le connaître.");
    router.push({name: "changePassword"});
  },

  /**
   * Renvoi la liste de roles courante
   * @return {string[]}
   */
  getCurrentRoles: function () {
    return sessionStorage.getItem(SESSION_ROLES).split(",");
  },

    /**
   * Renvoie si l'utilisateur connecté doit changer son mot de passe
   * @return {string[]}
   */
  getDoitChangerMotDePasse: function () {
    return sessionStorage.getItem(SESSION_DOIT_CHANGER_MOT_DE_PASSE) === "true";
  },
  

  /**
   * Renvoi le type de la personne connectée (None, per_phy, per_mor)
   * @return {string[]}
   */
  getCurrentPersonneType: function () {
    return sessionStorage.getItem(SESSION_PERSONNE_TYPE);
  },
  /**
   * Renvoi le tenant courant
   * @return {string}
   */
  getCurrentTenant: function () {
    return sessionStorage.getItem(TENANT);
  },

  hasAccessCapital: function () {
    return sessionStorage.getItem(ACCES_CAPITAL) === "true";
  },

  hasAccessParticipation: function () {
    return sessionStorage.getItem(ACCES_PARTICIPATION) === "true";
  },
  
  hasAccessInteressement: function () {
    return sessionStorage.getItem(ACCES_INTERESSEMENT) === "true";
  },

  checkHasScicExtensionAccess: function () {
    return sessionStorage.getItem(ACCES_EXTENSION_SCIC) === "true";
  },

  checkHasAbondementExtensionAccess: function () {
    return sessionStorage.getItem(ACCES_EXTENSION_ABONDEMENT) === "true";
  },
  
};


/**
 * décode le token passé en paramètre
 * @param token
 * @return {*}
 * @private
 */
function _decode_token(token) {
  return jwt_decode(token);
}

/**
 * Supprime le token courant de la session ainsi que la date d'expiration et les rôles associés
 * @private
 */
function _resetToken() {
  sessionStorage.removeItem(SESSION_STORAGE_TOKEN);
  sessionStorage.removeItem(SESSION_EXPIRATION_DATE);
  sessionStorage.removeItem(SESSION_ROLES);
  sessionStorage.removeItem(SESSION_PERSONNE_TYPE);
  sessionStorage.removeItem(SESSION_ASSOCIE_ID);
  sessionStorage.removeItem(SESSION_BENEFICIAIRE_ID);
  sessionStorage.removeItem(SESSION_PERSONNE_ID);
  sessionStorage.removeItem(TENANT);
  sessionStorage.removeItem(ACCES_CAPITAL);
  sessionStorage.removeItem(ACCES_PARTICIPATION);
  sessionStorage.removeItem(ACCES_INTERESSEMENT);
  sessionStorage.removeItem(ACCES_EXTENSION_SCIC);
  sessionStorage.removeItem(ACCES_EXTENSION_ABONDEMENT);
  sessionStorage.removeItem(SESSION_DOIT_CHANGER_MOT_DE_PASSE);
}

/**
 * Enregistre un nouveau token dans la session et maj la date d'expiration et les rôles
 * @param token
 * @private
 */
function _saveNewToken(token) {
  const decodedToken = _decode_token(token);
  sessionStorage.setItem(SESSION_STORAGE_TOKEN, token);
  sessionStorage.setItem(SESSION_EXPIRATION_DATE, decodedToken.exp);
  sessionStorage.setItem(SESSION_ROLES, decodedToken.rls);
  sessionStorage.setItem(SESSION_PERSONNE_TYPE, decodedToken.personne_type);
  sessionStorage.setItem(TENANT, decodedToken.tenant);
  sessionStorage.setItem(SESSION_ASSOCIE_ID, decodedToken.associe_id);
  sessionStorage.setItem(SESSION_BENEFICIAIRE_ID, decodedToken.beneficiaire_id);
  sessionStorage.setItem(SESSION_PERSONNE_ID, decodedToken.personne_id);
  sessionStorage.setItem(ACCES_CAPITAL, decodedToken.acces_capital);
  sessionStorage.setItem(ACCES_PARTICIPATION, decodedToken.acces_participation);
  sessionStorage.setItem(ACCES_INTERESSEMENT, decodedToken.acces_interessement);
  sessionStorage.setItem(ACCES_EXTENSION_SCIC, decodedToken.acces_extension_scic);
  sessionStorage.setItem(ACCES_EXTENSION_ABONDEMENT, decodedToken.acces_extension_abondement);
  sessionStorage.setItem(SESSION_DOIT_CHANGER_MOT_DE_PASSE, decodedToken.doit_changer_mot_de_passe);
}