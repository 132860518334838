<!-- VUETIFY2 - OK -->
<template>
    <v-menu v-model="showMenu" min-width="0" offset-y transition="scale-transition"
            :close-on-content-click="false" :disabled="disabled">
        <template v-slot:activator="{ on }">
            <v-text-field
                v-model="localDate"
                :clearable="clearable"
                :error-messages="formatError(errors)"
                :disabled="disabled"
                :label="label"
                hint="JJ/MM/AAAA"
                persistent-hint
                prepend-icon="event"
                @focus="date = parseDate(localDate)"
                @click:clear="clearValue()"
                v-on="on"
            />
        </template>
        <v-date-picker
            v-if="!disabled"
            v-model="date"
            :name="name"
            @input="closePicker()"
            no-title
            first-day-of-week="1"
            scrollable
            :allowed-dates="allowedDate"
            locale="fr-FR"/>
    </v-menu>

</template>

<script>

    import _ from "lodash";
    import Utils from "@/utils";

    export default {
        name: "DateField",
        introduction: "Component used to define the custom date-picker for the app.",
        description: `
            The components embeds a datepicker to select a specific date, but displays it
            in a formatted input text.
        `,
        token: "<DateField label='My new date'>",
        props: {
            name: String,
            value: {
                type: [Date, String, Number],
                default: null
            },
            label: {
                type: String,
                note: "Label to be displayed as field name."
            },
            errors: {
                type: Array,
                required: false
            },
            clearable: {
                type: Boolean,
                required: false,
                default: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            allowedDate: {
                type: Function,
                required: false,
                default: val => true
            },
        },
        data() {
            return {
                // Used to close date picker
                showMenu: false,
                date: undefined,
                localErrors: [],
                formatErrorMessage: "Le format de la date doit être JJ/MM/AAAA",
            };
        },
        mounted() {
            const delayInMillis = 500;
            this.debounceDateFormat = _.debounce(newvalue => {this.debounceDateFormatFunc(newvalue)}, delayInMillis);
        },
        methods: {
            debounceDateFormatFunc(newValue) {
                const parsedDate = this.parseDate(newValue);
                if (parsedDate) {
                    // newValue est une date valide, émettre un event avec la nouvelle valeur
                    this.$emit("input", parsedDate);
                    this.localErrors = [];
                } else if (newValue) {
                    // newValue n'est pas une date valide, afficher une erreur explicite
                    this.localErrors = [this.formatErrorMessage];
                } else {
                    // newValue est null ou "", émettre un event avec null comme valeur
                    this.$emit("input", null);
                    this.localErrors = [];
                }
            },
            closePicker() {
                this.showMenu = false;
            },
            clearValue() {
                this.localDate = "";
            },
            parseDate (date) {
                if (!date) return null;
                let parsedDate = Utils.formatDateStringFrToIso(date);
                if (!parsedDate) return null;
                return parsedDate;
            },
            formatDate (date) {
                if (!date) return null;
                return Utils.formatDateStringIsoToFr(date);
            },
            formatError(errors) {
                if (_.isNil(errors)) {
                    errors = [];
                }
                return Utils.formatError(errors.concat(this.localErrors));
            },
        },
        computed: {
            localDate: {
                get: function () {
                    return this.formatDate(this.value);
                },
                set: function (newvalue) {
                    this.debounceDateFormat(newvalue);
                }
            },
        },
        watch: {
            date (val) {
                this.localDate = this.formatDate(val);
            },
        },
    };
</script>
