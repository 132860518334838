import internalApi from "@/api/internalApi";
import VDocumentAbstract from "./VDocumentAbstract";
import constantes from "@/utils/constantes";

export default {
    name: "VDocumentStatuts",
    abstract: false,
    extends: VDocumentAbstract,

    computed: {
        infoBulleContent: function() {
            return "Ce document est partagé avec tous les associés";
        },
        documentTitle: function() {
            return "Statuts";
        },
        typeDocument: function() {
            return "Statuts";
        },
        fileName: function() {
            return "statuts.pdf";
        },
    },
    methods: {
        uploadFile(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.capCompany.createStatutsDocument(formData)
                .then(res => {
                    this.loadDocument().then(result => {
                        this.$emit("new-uploaded-doc", res);
                    });
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
        },
        loadDocument: function () {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.capCompany.getCurrentStatutCapCompany()
                .then(res => {
                    this.documentId = res.doc_accord_id;
                    this.companyUnivId = res.id;
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });

        },

        /**
         * Delete document
         */
        
        unlinkDocument: function () {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
            return internalApi.capCompany.deleteStatutsDocument(this.companyUnivId, {"doc_accord_id": null})
                .then(res => {
                    this.loadDocument().then(result => {
                        this.$emit("on-delete-document");
                    });
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
        },
        
        
    }

}    
