<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-autocomplete :value="value"
                        @input="$emit('input', $event)"
                        label="Tiers"
                        :items="personneList"
                        :error-messages="errors"
                        :disabled="isDisabled"/>
    </div>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";

    export default {
        components: {
            loadingScreen
        },
        name: "v-personnes-list",
        props: {
            value: {
                type: Number
            },
            isDisabled: Boolean,
            noUserFilter: Boolean,
            errors: {
                type: Array,
                required: false,
            }
        },
        mounted() {
            this.fetchPersonneList();
        },
        data() {
            return {
                personneList: []
            }
        },
        methods: {
            fetchPersonneList() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.personne.getAll({
                    page: -1,
                    rows_per_page: -1,
                    user_filter: this.noUserFilter ? constantes.filter.false : constantes.filter.all,
                }).then(res => {
                    this.personneList = Utils.fromArrayToSelect(res.personnes, "display_name_numero_tiers");
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
        }
    };
</script>


