import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";

export default scicApi;

function scicApi() {
  const specificApi = {
    getExcelProfilsAssocies: getExcelProfilsAssocies(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.scicCollege.baseUrl, [

  ]));
}

function getExcelProfilsAssocies() {
  function getAll(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.scicRapports.getExcelProfilsAssocies(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getAll;
}
