// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonneMorale from "@/views/common/Tiers/PersonneMorale";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    this.isEspacePersonnel = false;
  },
  extends: PersonneMorale,
  computed: {

  },
  methods: {
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonneMoraleBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
      return internalApi.personneMorale
        .postAllWithToastConfirmation(data_to_send)
        .then(() => {
          this.$refs.loadingComponent.resetLoading();
          this.$router.push({name: "tiers"});
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
