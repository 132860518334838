import Supports from "@/views/participation/pbSupport/Index";
import SupportsList from "@/views/participation/pbSupport/SupportsList";
import PbSupportCreation from "@/views/participation/pbSupport/PbSupportCreation";
import PbSupportEdition from "@/views/participation/pbSupport/PbSupportEdition";
import PbSupportInteretsEchus from "@/views/participation/pbSupport/PbSupportInteretsEchus";
import rolesList from "@/router/rolesList";


export default {
  path: "pb-supports-de-placement/",
  component: Supports,
  children: [
    {
      path: "",
      name: "pb-supports",
      component: SupportsList,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "pb-supports/:id/",
      name: "edition-support",
      component: PbSupportEdition,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "pb-supports/:id/interets-echus",
      name: "pb-support-interets-echus",
      component: PbSupportInteretsEchus,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
    {
      path: "pb-supports/",
      name: "creation-support",
      component: PbSupportCreation,
      meta: {
        authorizedRoles: [rolesList.ADMINISTRATEUR, rolesList.GESTIONNAIRE, rolesList.RESPONSABLE],
      },
    },
  ]
};