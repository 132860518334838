// <!-- VUETIFY2 - OK -->
import DonneesBeneficiairesAbstract from "./DonneesBeneficiairesAbstract"

import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";
import Utils from "@/utils";
import {TOAST} from "@/plugins/toast/definition";

export default {
    name: "InteDonneesBeneficiaires",
    extends: DonneesBeneficiairesAbstract,
    abstract: false,
    computed: {
        isPb: function () {
            return false;
        },
        isInte: function () {
            return true;
        },
    },
    methods: {
        getCurrentUnivCompany() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            return internalApi.inteCompany.getCurrentInteCompany()
                .then(result => {
                    this.company = result;
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         * Exporte les données de la datatable sous forme d'un fichier excel
         */
        exportSalairePresences() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
            return internalApi.inteSalairePresence.exportSalairePresenceExcel(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "donnees_beneficiaires.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        onSalairePresencesImport(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.import);
            return internalApi.inteSalairePresence.importSalairePresenceExcel(this.exercice.id, formData)
                .then(() => {
                    this.getRefDatatableSalairePresences().getAllSalairePresencesSync();
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        /**
         *  Export les données périodes de travail pour un exercice
         */
        exportPeriodesTravail() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.export)
            return internalApi.exercice.exportPeriodesTravailForExerciceExcel(this.exercice.id)
                .then(response => {
                    Utils.downloadFile(response, "periodes_de_travail.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });

        },
    }
}
