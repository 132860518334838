var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.disabledValue},on:{"click":function($event){$event.stopPropagation();_vm.localDialog = true}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","disabled":_vm.disabledValue},model:{value:(_vm.localDialog),callback:function ($$v) {_vm.localDialog=$$v},expression:"localDialog"}},[(_vm.localDialog === true)?_c('ValidationObserver',{ref:"formNewVersion",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12"}},[_c('ValidationProvider',{attrs:{"vid":"nom_version_statuts","name":"nom version statuts","rules":{ required: true, excluded: _vm.nomVersionsUsed }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nom de la version","data-vv-name":"nom_version_statuts","data-vv-as":"Nom de la version","error-messages":errors},model:{value:(_vm.nom_version),callback:function ($$v) {_vm.nom_version=$$v},expression:"nom_version"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":"","wrap":""}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12"}},[_c('ValidationProvider',{attrs:{"vid":"date_debut","name":"date debut","rules":"required: true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":"Date de début","name":"date_debut","data-vv-as":"Date de début","errors":errors},model:{value:(_vm.date_debut),callback:function ($$v) {_vm.date_debut=$$v},expression:"date_debut"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.messageAvertissement)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{attrs:{"vid":"confirmation","name":"confirmation","rules":"required:true|is:Je valide"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirmation","data-vv-name":"confirmation","data-vv-as":"Confirmation","error-messages":errors},model:{value:(_vm.messageConfirmation),callback:function ($$v) {_vm.messageConfirmation=$$v},expression:"messageConfirmation"}})]}}],null,true)})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('i',[_vm._v("Pour confirmer l'opération, saisissez « Je valide »")])])],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{staticClass:"mr-2",attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.closePopUp()}}},[_vm._v("Non, abandonner")])],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return handleSubmit(_vm.submitNewVersion)}}},[_vm._v("Oui, confirmer ")])],1)],1)],1)],1)]}}],null,false,3720805359)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }