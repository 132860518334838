<!-- VUETIFY2 - OK -->
<template>
    <v-icon-button-with-tool-tip :detail="detail"
                                 detailButtonText="Export"
                                 :icon="'$vuetify.icons.download'"
                                 btnRound btnText btnSmall iconSmall
                                 tooltipBottom
                                 btnColorClass="transparent"
                                 iconColorClass="grey darken-1"
                                 @click.stop="exportFile"
                                 :disabled="disabled"
    />
</template>

<script>
    import VIconButtonWithToolTip from "./VIconButtonWithToolTip";

    /**
     * Composant permettant d'exporter un fichier de l'application
     * @displayName Commun - VExportFile
     */
    export default {
        name: "VExportFile",
        components: {
            VIconButtonWithToolTip
        },
        props: {
            detail: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        methods: {
            /**
             * Emet un évènement pour exporter le fichier
             */
            exportFile() {
                this.dialog = false;
                this.$emit("export-file");
            }
        }
    }
</script>
