<!-- VUETIFY2 - OK -->
<template>
        <v-autocomplete :value="value"
                        :label="label"
                        :items="beneficiaireList"
                        :error-messages="errors"
                        @input="$emit('input', $event)"
                        :disabled="disabled"/>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    export default {
        name: "v-beneficiaires-list",
        props: {
            value: {
                type: Number
            },
            label: {
                type: String,
                required: false,
                default: "Bénéficiaire"
            },
            disabled: Boolean,
            /**
             * Option pour afficher un élément 'tous les bénéficiaires'
             */
            optionAllBeneficiaires: {
                type: Boolean,
                required: false,
                default: false
            },
            errors: {
                type: Array,
                required: false
            }
        },
        mounted() {
            this.fetchBeneficiaireList();
        },
        data() {
            return {
                beneficiaireList: []
            }
        },
        methods: {
            fetchBeneficiaireList() {
                internalApi.beneficiaire.getAll().then(res => {
                    this.beneficiaireList = Utils.fromArrayToSelect(res, "display_name_numero_tiers");
                    if (this.optionAllBeneficiaires) {
                        this.beneficiaireList.unshift({text: "Tous les bénéficiaires", value: -1});
                    }
                });
            },
        }
    };
</script>


