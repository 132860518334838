import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils";

export default pbChartsApi;

function pbChartsApi() {
  return {
    companyChartsParticipationData: getCompanyChartsParticipation(),
    beneficiaireChartsData: getBeneficiaireCharts(),
    beneficiaireChartsIndividuelData: getBeneficiaireChartsIndividuel(),
  };
}

function getCompanyChartsParticipation() {
  function companyDataParticipation(date, queryParams, headers) {
    let path = internalApiRoutes.pbCharts.companyChartsParticipation();
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return companyDataParticipation;
}

function getBeneficiaireCharts() {
  function beneficiaireChartsData(beneficiaireId, date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    queryParams.beneficiaire_id = beneficiaireId;
    let path = internalApiRoutes.pbCharts.beneficiaireCharts();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return beneficiaireChartsData;
}

function getBeneficiaireChartsIndividuel() {
  function beneficiaireChartsIndividuelData(date, queryParams, headers) {
    queryParams = queryParams || {};
    queryParams.date = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.pbCharts.beneficiaireChartsIndividuel();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return beneficiaireChartsIndividuelData;
}