<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
                <v-icon-button-with-tool-tip v-if="isIcon"
                                             @click.stop="onButtonClick"
                                             :detail="tooltipDetailMessage"
                                             :icon="iconValue"
                                             :activator="on"
                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                             btnColorClass="transparent"
                                             iconColorClass="grey darken-1"
                                             block
                />
                <v-btn v-else v-on="{...on, ...activator}" @click="onButtonClick" color="primary" dark block>
                    <v-icon left>{{"$vuetify.icons.txt"}}</v-icon>
                    {{openButtonMessage}}
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline grey lighten-2 center">
                    <v-row justify="start" class="ma-n1">
                    {{title}}
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on" class="ml-2">{{ "$vuetify.icons.info" }}
                            </v-icon>
                        </template>
                        <span>
                            La télédéclaration du CERFA 2561 nécessite un traitement en trois étapes :
                            nommage, compression et chiffrage.
                            <br/>
                            Seul le fichier chiffré est attendu par l’administration fiscale,
                            cependant vous pouvez librement accéder aux fichiers intermédiaires.
                            <br/>
                            Deux clefs de chiffrage sont proposées, l'une pour tester votre fichier, l'autre pour le dépôt définitif.
                        </span>
                    </v-tooltip>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <ValidationObserver v-if="dialog">
                        <v-row class="mt-1">
                            <v-col>
                                <ValidationProvider vid="anneeTeletransmisson" name="anneeTeletransmisson" v-slot="{ errors }">
                                    <v-years-list v-model="anneeTeletransmisson"
                                                  label="Année de règlement des intérêts aux parts sociales"
                                                  :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <ValidationProvider vid="versionFichier"
                                                    name="Version du fichier"
                                                    rules="required|numeric|max:3"
                                                    v-slot="{ errors }">
                                    <v-formatted-number-text-field v-model="versionFichier"
                                                                   labelValue="Version du fichier"
                                                                   :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                            <v-col>
                                <ValidationProvider vid="typeFichier" name="Type de fichier"
                                                    rules="required" v-slot="{ errors }">
                                    <v-select v-model="typeFichier"
                                              :items="selectTypesFichiers"
                                              label="Type de fichier"
                                              item-text="text"
                                              item-value="value"
                                              :error-messages="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row v-if="typeFichier == 'encrypted_file'">
                            <v-col>
                                <ValidationProvider vid="typeClefGnupg" name="Clef de chiffrement"
                                                    rules="required">
                                    <p class="mb-0">Chiffrer le fichier pour un import en environnement de :</p>
                                    <v-radio-group v-model="typeClefGnupg" class="mt-1">
                                        <v-radio value="production" label="Production" :disabled="!isProductionKeyPresent"/>
                                        <v-radio value="test" label="Test" :disabled="!isTestKeyPresent"/>
                                    </v-radio-group>
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters justify="end">
                        <!-- Bouton modal -->
                        <v-col class="mr-2" md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col cols="4">
                            <v-btn color="primary" @click="onSetDataTeletransmission" block>{{labelConfirmation}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VYearsList from "@/components/VYearsList";

    export default {
        name: "v-dialog-annee-reglement-ag",
        components: {
            loadingScreen,
            VIconButtonWithToolTip,
            VFormattedNumberTextField,
            VYearsList,
        },
        $_veeValidate: {
            validator: 'new'
        },
        props: {
            externalValidateForm: {
                type: Function,
                required: false
            },
            anneeAg: {
                required: false,
                type: Number,
            },
            activator: {
                type: Object,
                required: false,
                default: undefined
            },
            openButtonMessage: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
                default: "Année de règlement"
            },
            labelAnnulation: {
                type: String,
                required: false,
                default: "Non, abandonner"
            },
            labelConfirmation: {
                type: String,
                required: false,
                default: "Oui, confirmer"
            },
            iconValue: {
                required: false
            },
            isIcon: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipDetailMessage: {
                type: String,
                required: false,
                default: ""
            },

        },
        data() {
            return {
                dialog: false,
                anneeTeletransmisson: undefined,
                legalSiren: undefined,
                nomFichier: undefined,
                versionFichier: 1,
                typeFichier: "compressed_file",
                selectTypesFichiers: [],
                selectTypesFichiersEncrypted: {"value": "encrypted_file", "text": "Fichier chiffré"},
                selectTypesFichiersOthers: [
                    {"value": "compressed_file", "text": "Fichier compressé"},
                    {"value": "plain_file", "text": "Fichier simple"},
                ],
                typeClefGnupg: "production",
                isProductionKeyPresent: false,
                isTestKeyPresent: false,
            };
        },
        mounted() {
            this.fetchKeys();
        },
        watch: {
            anneeAg: function (newValue) {
                this.anneeTeletransmisson = newValue;
            },
        },
        methods: {
            computeSelectTypesFichiersAndTypeFichier() {
                if (!this.isProductionKeyPresent && !this.isTestKeyPresent) {
                    this.typeFichier = "compressed_file";
                    this.selectTypesFichiers = this.selectTypesFichiersOthers;
                } else {
                    this.typeFichier = "encrypted_file";
                    this.selectTypesFichiers = [this.selectTypesFichiersEncrypted].concat(this.selectTypesFichiersOthers);
                }
                if (!this.isProductionKeyPresent) {
                    this.typeClefGnupg = "test";
                }
            },
            fetchKeys: function() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.chiffrement.getKeysPresenceAtDate(Date.now())
                    .then(res => {
                        this.isProductionKeyPresent = res.is_production_key_present;
                        this.isTestKeyPresent = res.is_test_key_present;
                        this.computeSelectTypesFichiersAndTypeFichier();
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },

            onSetDataTeletransmission: function () {
                const data_to_send = {
                    "annee_teletransmission": this.anneeTeletransmisson,
                    "type_fichier": this.typeFichier,
                    "version_fichier": this.versionFichier,
                    "type_clef_gnupg": this.typeClefGnupg,
                }
                this.$emit("update-data-teletransmission", data_to_send)
                this.dialog = false;     
            },
            
            closePopUp() {
                this.dialog = false;
            },
            onButtonClick: function () {
              this.$emit("open-popup-click");
            },
        }
    };
</script>