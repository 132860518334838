// <!-- VUETIFY2 - OK -->
import VTauxRemuForSupport from "./VTauxRemuForSupport"

export default {
  extends: VTauxRemuForSupport,
  props: {
    oldTauxRemu: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  mounted() {
    this.fetchExercicesList();
    if (!_.isNil(this.oldTauxRemu.id)) {
      this.tauxRemu.id = this.oldTauxRemu.id;
    }
    this.tauxRemu.pb_exercice_id = this.oldTauxRemu.pb_exercice_id;
    this.tauxRemu.taux_remu = this.oldTauxRemu.taux_remu;
    this.tauxRemu.date_versement = this.oldTauxRemu.date_versement;
  },
  computed: {
    isModif() {
      return true;
    },
    title() {
      return "Modification d'un taux de rémunération";
    },
    tooltipDetailMessage() {
      return "Modifier un taux de rémunération";
    },
    iconValue() {
      return "$vuetify.icons.edit";
    },
  },
  methods: {
    submit() {
      const data_to_update = _.cloneDeep(this.tauxRemu);
      this.$emit("edit-taux-remu", this.oldTauxRemu, data_to_update);
      this.closePopUp();
    },
    closePopUp() {
      this.dialog = false;
      this.$refs.formTauxRemu.reset();
    },
  }
};