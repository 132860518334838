<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <h3 style="display: inline-block;">Livret d'épargne salariale</h3>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon class="ml-2" v-on="on" small left>{{ "$vuetify.icons.info" }}</v-icon>
            </template>
            <span v-if="hasAccessParticipation()"
                v-html="`Le livret d'épargne salariale fait état des supports de placement de participation actifs`">
            </span>
            <span v-else
                v-html="`Nécessite l'accès à l'univers Participation`">
            </span>
        </v-tooltip>
        <v-row no-gutters>
            <!-- Petit bouton -->
            <v-col md="3" lg="3" xl="2">
                <v-btn class="mt-2" color="primary" @click="downloadLivretEpargneSalariale()" block :disabled="!hasAccessParticipation()">
                    Télécharger
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import authService from "@/services/authService";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen.vue";

    export default {
        name: "v-livret-epargne-salariale",
        components: {
            loadingScreen
        },
        methods: {
            /**
             * Vérifie l'accès à l'unviers participation
             */
            hasAccessParticipation: function () {
                return authService.hasAccessParticipation();
            },
            downloadLivretEpargneSalariale() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                internalApi.pbSupport.getLivretEpargneSalariale()
                    .then(response => {
                        this.$refs.loadingComponent.resetLoading();
                        Utils.downloadFile(response, "Livret epargne salariale.pdf", Utils.pdfMimetype);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$refs.loadingComponent.resetLoading();
                    });
            }
        }
    }
</script>