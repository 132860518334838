<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid>
        <loading-screen ref="loadingComponent"/>
        <v-row>
            <v-datatable-mouvements-supports-simplifie-creation v-if="! isEspaceBeneficiaire"
                                                                :dateOperation="dateOperation"
                                                                :tauxRemu="tauxRemu"
                                                                :beneficiaireId="beneficiaireId"
                                                                :supportId="supportId"
                                                                :aTerme="aTerme"
                                                                :height="height"
                                                                style="width: 100%;"
                                                                ref="mouvementsSupports"/>

            <v-datatable-mouvements-supports-simplifie-espace-beneficiaire v-else
                                                                           :dateOperation="dateOperation"
                                                                           :beneficiaireId="beneficiaireId"
                                                                           :supportId="supportId"
                                                                           :aTerme="aTerme"
                                                                           :height="height"
                                                                           ref="mouvementsSupports"/>
        </v-row>
    </v-container>
</template>


<script>
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VDatatableMouvementsSupportsSimplifieCreation from "@/components/mouvementsSupports/VDatatableMouvementsSupportsSimplifieCreation";
    import VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire from "@/components/mouvementsSupports/VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire";


    export default {
        name: "VCreationMouvementSupportSimplifie",
        components: {
            loadingScreen,
            VDatatableMouvementsSupportsSimplifieCreation,
            VDatatableMouvementsSupportsSimplifieEspaceBeneficiaire,
        },
        props: {
            height: String,
            beneficiaireId: {
                type: Number,
                required: false,
                default: undefined,
            },
            dateOperation: {
                type: String,
                required: false,
                default: undefined,
            },
            tauxRemu: {
                type: Number,
                required: false,
                default: undefined,
            },
            supportId: {
                type: Number,
                required: false,
                default: undefined,
            },
            aTerme: {
                type: Boolean,
                required: false,
                default: false,
            },
            type: {
                type: String,
                required: false,
                default: 'terme',
            },
            isEditable: {
                type: Boolean,
                required: true,
            },
            isEspaceBeneficiaire: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
    }
</script>