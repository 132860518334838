<!-- VUETIFY2 - OK -->
<template>
    <v-autocomplete :value="value"
                    @input="onSelectedExerciceChanges($event)"
                    label="Exercice"
                    :items="localExercicesList"
                    item-text="libelle"
                    item-value="id"
                    :error-messages="errors"
                    :disabled="isDisabled"/>
</template>

<script>
    import _ from "lodash";
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant une liste déroulante des exercices enregistrés dans l'application
     * @displayName Commun - Liste des exercices
     */
    export default {
        name: "v-exercices-list",
        props: {
            /**
             * Numéro de l'exercice à présélectionner
             */
            value: {
                type: [Object, Number]
            },
            /**
             * Si vrai, la liste est désactivée, si faux, la liste est activée
             */
            isDisabled: Boolean,
            /**
             * Ajouter un élément "tous les exercices" à la liste
             */
            optionAllExercices: {
                type: Boolean,
                required: false,
                default: false
            },
            errors: {
                type: Array,
                required: false
            },
            /**
             * Si on souhaite manipuler une liste spécifique d'exercices.
             */
            propExercicesList: {
                type: Array,
                required: false,
                default: undefined,
            }
        },
        mounted() {
            this.fetchExercicesListAsPromise().then(res => {
                this.computeExercicesList(res);
            });
        },
        data() {
            return {
                localExercicesList: [],
            }
        },
        computed: {
            localSelectedExercice: {
                get: function () {
                    return this.valueSelectedItem;
                },
                set: function (newvalue) {
                    this.$emit("update-selected-exercice", newvalue);
                }
            },
        },
        methods: {
            onSelectedExerciceChanges(new_exercice_id) {
                this.localSelectedExercice = _.find(this.localExercicesList, ['id', new_exercice_id]);
                this.$emit('input', new_exercice_id);
            },
            getExercicesList() {
                return internalApi.exercice.getExercicesEnumByName()
                    .then(results => {
                        return results;
                    });
            },
            fetchExercicesListAsPromise() {
                let promise;
                if (this.propExercicesList === undefined) {
                    promise = Promise.resolve(this.getExercicesList());
                } else {
                    promise = Promise.resolve(this.propExercicesList)
                }
                return promise;
            },
            computeExercicesList(exerciceList) {
                this.localExercicesList = _.sortBy(exerciceList, "date_debut").reverse();
                if (this.optionAllExercices) {
                    this.localExercicesList.unshift({libelle: "Tous les exercices", id: -1});
                }
            }
        }
    };
</script>


