<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formNewUsersMassAccess" v-slot="{ errors, validate, reset }">
        <loading-screen ref="loadingComponent"/>
        <base-layout headerSize="300px">
            <template slot="header">
                <h1>Créer des accès en masse</h1>
                <v-row>
                    <v-col>
                        <span v-html="infoBulleContent"/>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="start">
                    <v-col class="ml-2" cols="3">
                        <ValidationProvider vid="loginType" name="Modèle de login"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-radio-group v-model="loginType" label="Modèle de login :" :error-messages="errors">
                                <v-radio v-for="typeLogin in loginTypeList" :key="typeLogin.key"
                                         :label="typeLogin.label" :value="typeLogin.key"/>
                            </v-radio-group>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="4" class="ml-5 pa-0">
                        <v-text-field v-model="search"
                                      append-icon="search"
                                      label="Filtrer les tiers"
                                      single-line/>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main="{ datatableSize }">
                <v-row no-gutters>
                    <v-col cols="11" class="ml-5 pa-0">
                        <v-data-table v-model="selectedTiers"
                                      show-select
                                      :search="search"
                                      :headers="headers"
                                      :items="listeTiersSansAcces"
                                      :no-data-text="noDataTextMessage"
                                      class="elevation-1"
                                      :options.sync="options"
                                      fixed-header
                                      hide-default-footer
                                      :loading="isLoading"
                                      :height="datatableSize"
                                      disable-pagination
                        >
                            <template v-slot:item="{isSelected, select, item}">
                                <tr>
                                    <td>
                                        <v-simple-checkbox :ripple="false"
                                                           :value="isSelected"
                                                           @input="select($event)"/>
                                    </td>
                                    <td class="text-lg-left">
                                        <span>{{ item.name }}</span>
                                    </td>
                                    <td class="text-lg-left">
                                        <span>{{ item.numero_tiers }}</span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
            <template slot="footer">
                <v-row justify="end" no-gutters>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :showToast="false"
                                                      :resetForm="reset"
                                                      :validateForm="validate"
                                                      :errorsList="errors"
                                                      @emit-cancel="returnToDroitsAcces"
                                                      @emit-valid-save="onCreateNewUsersMassAccess"
                                                      saveMessage="Créer des accès en masse"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
import BaseLayout from "@/components/BaseLayout";
import internalApi from "@/api/internalApi";

import loadingScreen from "@/components/LoadingScreen.vue";
import constantes from "@/utils/constantes";

export default {
    name: "newUsersMassAccessCreation",
    components: {
        BaseLayout, VSaveButonWithValidation, loadingScreen
    },
    data() {
        return {
            loginType: undefined,
            infoBulleContent: "Par défaut, lors de la création de droits d'accès en masse, les modalités suivantes sont appliquées :<br\>- Login : modèle du login sélectionné<br\>- Mot de passe : généré aléatoirement et reçu par mail",
            loginTypeList: [{
                key: "prenom_nom",
                label: "Première lettre du premier prénom + nom de famille ou raison sociale"
            }, {key: "numero_tiers", label: "Numéro de tiers"}],
            noDataTextMessage: "Tous les tiers ont un accès.",
            listeTiersSansAcces: [],
            options: {
                sortBy: ["last_name"],
                sortDesc: [true],
            },
            headers: [
                {text: "Nom - Prénom / Raison sociale", value: "name", align: "start"},
                {text: "Numéro de tiers", value: "numero_tiers", align: "start"},
            ],
            selectedTiers: [],
            search: "",
            isLoading: true
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        returnToDroitsAcces() {
            this.$router.push({
                name: "droits_acces"
            });
        },
        onCreateNewUsersMassAccess() {
            const body = {
                login_type: this.loginType,
                user_ids: this.selectedTiers.map(x => x.id)
            };
            this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
            internalApi.user.createMassAccess(body).then(() => {
                this.returnToDroitsAcces();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        },
        fetchUsers() {
            this.isLoading = true;
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            internalApi.personne.getPersonWithNoUserAccess().then(result => {
                this.listeTiersSansAcces = result;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.isLoading = false;
                this.$refs.loadingComponent.resetLoading();
            });
        },
    },
    computed: {}
}
</script>