<!-- VUETIFY2 - OK -->
<template>
    <v-container grid-list-lg fluid>
        <v-row>
            <v-col md="3" lg="3" xl="3">
                <v-select v-model="localSalTypeContrat"
                          label="Type de contrat"
                          :items="selectItems.type_contrat"
                          :disabled="!modifier_donnees_salarie"
                          clearable
                />
            </v-col>
            <v-col md="3" lg="3" xl="3">
                <v-select v-model="localSalTempsDeTravail"
                          label="Temps de travail"
                          :items="selectItems.temps_de_travail"
                          :disabled="!modifier_donnees_salarie"
                          clearable/>
            </v-col>
            <v-col md="3" lg="3" xl="3">
                <v-select v-model="localSalCategorieDeSalarie"
                          label="Catégorie de salariés"
                          :items="selectItems.categorie_de_salarie"
                          :disabled="!modifier_donnees_salarie"
                          clearable/>
            </v-col>
            <v-col md="3" lg="3" xl="3">
                <v-select v-model="localSalRole"
                          label="Rôle"
                          :items="selectItems.role"
                          :disabled="!modifier_donnees_salarie"
                          clearable/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <ValidationProvider vid="is_interets_places"
                                name="Intérêts de rémunération des supports de participation"
                                :rules="{'required': modifier_donnees_salarie}" v-slot="{ errors }">
                <v-radio-group v-model="localIsInteretsPlaces"
                            label="Intérêts de rémunération des supports de participation"
                            :disabled="!modifier_donnees_salarie"
                            :error-messages="errors">
                    <v-radio label="Placement" :value="true"/>
                    <v-radio label="Versement immédiat" :value="false"/>
                </v-radio-group>
            </ValidationProvider>
        </v-row>
    </v-container>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    /**
     * Composant pour afficher/modifier les données d'un salarié
     * @display_name Commun - VDonneesSalarie
     */
    export default {
        name: "VDonneesSalarie",
        props: {
            /**
             * Détermine si la personne est salariée ou non (et donc autorise ou non
             * la modification des champs)
             */
            modifier_donnees_salarie: {
                type: Boolean,
                default: false
            },
            /**
             * Type de contrat salarié
             */
            sal_type_contrat: {
                type: String,
                default: null
            },
            /**
             * Temps de travail d'un salarié (partiel, temps plein)
             */
            sal_temps_de_travail: {
                type: String,
                default: null
            },
            /**
             * Catégorie du salarié
             */
            sal_categorie_de_salarie: {
                type: String,
                default: null
            },
            /**
             * Rôle du salarié (gérant, etc)
             */
            sal_role: {
                type: String,
                default: null
            },
            /**
             * Détermine si les intérêts sont placés
             */
            is_interets_places:  {
                type: Boolean,
                default: null
            },
        },
        mounted() {
            this.getSelectItems();
        },
        data(){
            return {
                selectItems: {},
            }
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            /**
             * Récupère la liste des possiblités pour les champs à choix multiples
             * @returns {{string: [string]}}
             */
            getSelectItems() {
                return internalApi.salarie.getLabels().then(result => {
                    let {salarie} = result;
                    this.selectItems = {
                        type_contrat: Utils.fromLabelToSelect(salarie.type_contrat),
                        temps_de_travail: Utils.fromLabelToSelect(salarie.temps_de_travail),
                        categorie_de_salarie: Utils.fromLabelToSelect(salarie.categorie_de_salarie),
                        role: Utils.fromLabelToSelect(salarie.role),
                    };
                });
            },
        },
        computed: {
            localSalTypeContrat: {
                get: function () {
                    return this.sal_type_contrat;
                },
                set: function (newvalue) {
                    this.$emit("update-sal-type-contrat", newvalue);
                }
            },
            localSalTempsDeTravail: {
                get: function () {
                    return this.sal_temps_de_travail;
                },
                set: function (newvalue) {
                    this.$emit("update-sal-temps-de-travail", newvalue);
                }
            },
            localSalCategorieDeSalarie: {
                get: function () {
                    return this.sal_categorie_de_salarie;
                },
                set: function (newvalue) {
                    this.$emit("update-sal-categorie-de-salarie", newvalue);
                }
            },
            localSalRole: {
                get: function () {
                    return this.sal_role;
                },
                set: function (newvalue) {
                    this.$emit("update-sal-role", newvalue);
                }
            },
            localIsInteretsPlaces: {
                get: function () {
                    return this.is_interets_places;
                },
                set: function (newvalue) {
                    this.$emit("update-is-interets-places", newvalue);
                }
            },
        }
    }
</script>