import VSupportsList from "./VSupportsList"
import internalApi from "@/api/internalApi";
import Utils from "@/utils.js";

export default {
    extends: VSupportsList,
    name: "v-inte-supports-list",
    methods: {
        fetchSupportList() {
            internalApi.inteSupport.getAll({
                support_filter: this.supportFilter,
            }).then(res => {
                const resToList = Utils.fromArrayToSelect(res);
                this.supportList = resToList;
                if(this.withDefaultValue) {
                    this.localSupportValue = resToList[0].value;
                }
            });
        },
    },
};