<template>
    <v-autocomplete @input="onSelectedCerfaChanges($event)"
                    v-model="localSelectedCerfa"
                    label="Version du cerfa"
                    :items="localCerfaList"
                    item-text="label"
                    item-value="name"
                    :error-messages="errors"
                    :disabled="isDisabled"/>
</template>

<script>
    import _ from "lodash";
    import internalApi from "@/api/internalApi";

    /**
     * Composant affichant une liste déroulante des cerfa enregistrés dans l'application
     * @displayName Commun - Liste des cerfa
     */
    export default {
        name: "v-cerfa-list",
        props: {
            /**
             * Si vrai, la liste est désactivée, si faux, la liste est activée
             */
            isDisabled: Boolean,
            errors: {
                type: Array,
                required: false,
            },
            /**
             * L'id de l'exercice à traiter
             */
            exerciceId: {
                type: Number
            },
            /**
             * Indique l'univers sélectionné (CAPITAL ou PARTICIPATION)
             */
            univers: {
                type: String
            },
            /**
             * Le type de cerfa (IFU_2561_capital, IFU_2561_participation, PFU_2777_capital ou PFU_2777_participation)
             */
            typeCerfa: {
                type: String
            },
            /**
             * L'année sélectionnée (ex: 2020, 2029, ...)
             */
            year: {
                type: Number
            },
            /**
             * Le mois sélectionné (1 pour Janvier, ..., 12 pour Décembre)
             */
            month: {
                type: Number
            },
            /**
             * Le nom du cerfa proposé adapté à la situation
             */
            correspondingCerfa: {
                type: String
            },
            /**
             * Le cerfa sélectionné, attributs: {name, label, ter}
             */
            selectedCerfa: {
                type: Object
            },
        },
        mounted() {
            this.fetchCerfaListAsPromise();
        },
        data() {
            return {
                cerfaLabels: {},
                localCerfaList: [],
            }
        },
        computed: {
            localSelectedCerfa: {
                get: function () {
                    return this.selectedCerfa;
                },
                set: function (newvalue) {
                    this.$emit("update-selected-cerfa", newvalue);
                }
            },
            localCorrespondingCerfa: {
                get: function () {
                    return this.correspondingCerfa;
                },
                set: function (newvalue) {
                    this.$emit("update-corresponding-cerfa", newvalue);
                }
            },
        },
        watch: {
            univers() {
                this.updateLocalCerfaList();
                this.computeCorrespondingCerfa();
            },
            typeCerfa() {
                this.updateLocalCerfaList();
                this.computeCorrespondingCerfa();
            },
            exerciceId() {
                this.computeCorrespondingCerfa();
            },
            year() {
                this.computeCorrespondingCerfa();
            },
            month() {
                this.computeCorrespondingCerfa();
            },
        },
        methods: {
            onSelectedCerfaChanges(new_cerfa_name) {
                this.localSelectedCerfa = _.find(this.localCerfaList, ['name', new_cerfa_name]);
                this.$emit('input', new_cerfa_name);
            },
            getCerfaList() {
                return internalApi.cerfa.getLabels()
                    .then(labels => {
                        this.cerfaLabels = labels;
                    });
            },
            fetchCerfaListAsPromise() {
                return Promise.resolve(this.getCerfaList());
            },
            updateLocalCerfaList() {
                // reset la sélection
                this.localSelectedCerfa = undefined;
                var availableCerfaList = [];
                if (!_.isNil(this.cerfaLabels[this.univers]) && !_.isNil(this.typeCerfa)) {
                    var tmpMatch = _.find(this.cerfaLabels[this.univers], {"type_cerfa": this.typeCerfa});
                    availableCerfaList = tmpMatch["versions_cerfa"];
                }
                this.localCerfaList = availableCerfaList;
            },
            getCorrespondingCerfa() {
                return internalApi.cerfa.getCorrespondingCerfaVersion(this.exerciceId, this.univers, this.typeCerfa, this.year, this.month)
                    .then(cerfa => {
                        return cerfa;
                    })
                    .catch(err => {
                        console.error("Erreur pour récupérer une proposition de cerfa:" + err);
                        return undefined
                    });
            },
            checkCanGetCerfa() {
                if (_.isNil(this.univers) || _.isNil(this.typeCerfa)){
                    return false;
                }
                if (this.univers=='CAPITAL'){
                    if(_.isNil(this.exerciceId)){
                        return false;
                    }
                }
                if (this.univers=='PARTICIPATION'){
                    if (_.isNil(this.year)) {
                        return false;
                    }
                    if (this.typeCerfa=='PFU_2777_participation'){
                        return !_.isNil(this.month);
                    }
                }
                return true;
            },
            computeCorrespondingCerfa() {
                if (!this.checkCanGetCerfa()){
                    this.localSelectedCerfa = undefined;
                    this.localCorrespondingCerfa = undefined;
                    return;
                }
                // Les cerfas seront déjà triés du plus récent au plus ancien
                this.getCorrespondingCerfa().then(name_cerfa => {
                    // On cherche le cerfa correspondant à la situation pour le remonter
                    let correspondingItem = _.find(this.localCerfaList, {'name': name_cerfa});
                    if (correspondingItem===undefined){
                        this.localCorrespondingCerfa = "(pas de correspondance)";
                    }else{
                        this.localCorrespondingCerfa = correspondingItem.label;
                        this.localSelectedCerfa = correspondingItem;
                    }
                });
            }
        }
    };
</script>
