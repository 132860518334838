// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Souscription from "./Souscription";

export default {
  mounted() {
    this.isEditable = true;
    this.canEditDate = true;
  },
  extends: Souscription,
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      internalApi.souscription
        .postAllWithToastConfirmation(this.souscription)
        .then(result => {
          if (result) {
            this.pushToMouvements();
          }
        });
    }
  }
};
