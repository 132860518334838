<!-- VUETIFY2 - OK -->
<template>
    <base-layout id="dataManagement" :withFooter="false" headerSize="60px">
        <template v-slot:header>
            <loading-screen ref="loadingComponent"/>
        </template>
        <template v-slot:main>
            <base-layout :withHeader="false" :withFooter="true">
                <template v-slot:main>
                    <v-container v-if="isAdministrateur || isResponsable">
                        <v-row>
                            <v-col>
                                <h3>Ensemble des données</h3>
                            </v-col>
                        </v-row>
                        <v-row v-if="allowImportFichierInitial">
                            <v-col md="4" lg="6" xl="3">
                                Importer le fichier d'import initial
                            </v-col>
                            <v-col md="3" lg="2" xl="2">
                                <v-import-export-file
                                    detail="Importer le fichier d'import initial"
                                    exportDetail="Télécharger le modèle de fichier d'import initial"
                                    :typesFile="getTypesExtensionsFiles().excel"
                                    @import-file="function(formData){uploadFileInitial(formData)}"
                                    :allowedExport="allowImportFichierInitial"
                                    @export-file="downloadModelFileInitial"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col md="12" lg="8" xl="6">
                                <v-data-table-download-reports :rapports="updatedDownloadItems"/>
                            </v-col>
                        </v-row>
                        <template>
                            <v-row>
                                <v-col md="12" lg="8" xl="6">
                                    <template>
                                        <v-dialog v-model="dialog" persistent max-width="600px">
                                            <v-card>
                                                <ValidationObserver v-slot="{ handleSubmit }" v-if="dialog === true">
                                                    <v-card-title class="headline grey lighten-2" primary-title>Export des tiers actifs</v-card-title>
                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <ValidationProvider name="nombre de tiers actifs"
                                                                                        vid="number_tiers_actifs" v-slot="{ errors }"
                                                                                        rules="required">
                                                                        <DateField v-model="number_tiers_actifs_on_date"
                                                                                label="Date d'export"
                                                                                :errors="errors"
                                                                        />
                                                                    </ValidationProvider>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-row justify="end" class="mx-0">
                                                            <!-- Bouton modal -->
                                                            <v-col md="4" lg="4" xl="4">
                                                                <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                                                            </v-col>
                                                            <!-- Bouton modal -->
                                                            <v-col md="4" lg="4" xl="4">
                                                                <v-btn color="primary" @click="handleSubmit(submit)" block>OUI, CONFIRMER</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-actions>
                                                </ValidationObserver>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                </v-col>
                            </v-row>
                    </template>
                    </v-container>
                </template>
            </base-layout>
        </template>
    </base-layout>
</template>

<script>
import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import BaseLayout from "@/components/BaseLayout";
import _ from "lodash";
import authService from "@/services/authService";
import rolesList from "@/router/rolesList";
import constantes from "@/utils/constantes";
import VImportExportFile from "@/components/VImportExportFile";
import loadingScreen from "@/components/LoadingScreen.vue";
import moment from "moment/moment";
import DateField from "@/components/DateField.vue";
import VDataTableDownloadReports from "@/components/VDataTableDownloadReports.vue";

export default {
    name: "DataManagement",
    components: {
        BaseLayout,
        VImportExportFile,
        loadingScreen,
        DateField,
        VDataTableDownloadReports,
    },
    data() {
        return {
            dialog: false,
            currentTab: undefined,
            isAdministrateur: false,
            isResponsable: false,
            formData: new FormData(),
            exportAllDataDetail: "Extraction de toutes les données client",
            number_tiers_actifs: undefined,
            number_tiers_actifs_on_date: moment().format("YYYY-MM-DD"),
            download_items: [],
        };
    },
    mounted() {
        const currentRoles = authService.getCurrentRoles();
        this.isAdministrateur = !_.isEmpty(_.intersection([rolesList.ADMINISTRATEUR], currentRoles));
        this.isResponsable = !_.isEmpty(_.intersection([rolesList.RESPONSABLE], currentRoles));
    },
    computed: {
        allowImportFichierInitial() {
            return ! Boolean(this.$store.getters.getCompanyName());
        },
        updatedDownloadItems() {
            const updatedItems = [...this.download_items];
            if (this.allowImportFichierInitial) {
                const newItems = [
                    {
                        name: "Télécharger le modèle de fichier d'import initial",
                        downloadFunction: this.downloadModelFileInitial,
                        icon: "$vuetify.icons.excel",
                    },
                ];
                updatedItems.push(...newItems);
            } else {
                const newItems = [
                    {
                        name: "Tiers actifs",
                        downloadFunction: this.openPopUp,
                        icon: "$vuetify.icons.excel",
                    },
                    {
                        name: "Télécharger toutes les données",
                        downloadFunction: this.downloadFileInitial,
                        icon: "$vuetify.icons.excel",
                    },
                ];
                updatedItems.push(...newItems);
            }
            return updatedItems;

        },
        tenant(){
            return authService.getCurrentTenant();
        }
    },
    methods: {
        getTypesExtensionsFiles() {
            return constantes.extensions_fichiers;
        },
        hasCapitalAccess: function () {
            return authService.hasAccessCapital();
        },
        
        uploadFileInitial(formData) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.importLong);
            internalApi.administration.uploadFileInitial(formData)
                .then(() => {
                    this.$emit("new-uploaded-doc");
                })
                .catch(err => {
                    console.error(err)
                }).finally(() => {
                     this.$refs.loadingComponent.resetLoading();
                });
        },
        downloadFileInitial() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.importLong);
            return internalApi.administration.downloadFileInitial()
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "export_global_" + new Date().toISOString().slice(0, 10) + ".xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        downloadModelFileInitial() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            return internalApi.administration.downloadModelFileInitial()
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "modele_fichier_initial.xlsx", Utils.excelMimetype);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        downloadFileTiersActifs(dateString) {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.importLong);
            return internalApi.administration.downloadFileTiersActifs(dateString)
                .then(response => {
                    Utils.downloadFile(
                        response, "export_tiers_actifs_" + this.tenant + "_" 
                        + this.number_tiers_actifs_on_date + ".xlsx", Utils.excelMimetype
                    );
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
        },
        closePopUp() {
            this.dialog = false;
        },
        openPopUp() {
            this.dialog = true;
        },
        submit() {
            this.downloadFileTiersActifs(this.number_tiers_actifs_on_date);
            this.closePopUp();
        }
    }
}
</script>
