import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils";

export default repartitionInteApi;

function repartitionInteApi() {
  return {
    getInteRepartitionByInteExerciceId: getInteRepartitionByInteExerciceId(),
    getInteRepartitionIndividuelByInteExerciceId: getInteRepartitionIndividuelByInteExerciceId(),
    validateInteRepartitionByInteExerciceId: validateInteRepartitionByInteExerciceId(),
    getInteFicheIndividuelle: getInteFicheIndividuelle(),
    getStateFichesIndividuellesInte: getStateFichesIndividuellesInte(),
    generateFichesIndividuellesInte: generateFichesIndividuellesInte(),
    getFichesIndividuellesInte: getFichesIndividuellesInte(),
  }
}

function getInteRepartitionByInteExerciceId() {
    function getInteRepartitionByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.repartition.inteExercicesInteRepartition(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return getInteRepartitionByInteExerciceId;
}
  
function getInteRepartitionIndividuelByInteExerciceId() {
    function getInteRepartitionIndividuelByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.repartition.inteExercicesInteRepartitionIndividuel(inteExerciceId);
        let getForPath = apiConstructor.get(path);
        return getForPath(queryParams, headers);
    }

    return getInteRepartitionIndividuelByInteExerciceId;
}

function validateInteRepartitionByInteExerciceId() {
    function validateInteRepartitionByInteExerciceId(inteExerciceId, queryParams, headers) {
        let path = internalApiRoutes.inteExercices.repartition.inteExercicesInteRepartition(inteExerciceId);
        let postForPath = apiConstructor.post(path);
        return postForPath(queryParams, headers).then(result => {
            TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
            return Promise.resolve(result);
        });
    }

    return validateInteRepartitionByInteExerciceId;
}

function getInteFicheIndividuelle() {
    function fiche(inteExerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.inteExercices.repartition.inteFicheIndividuelle(inteExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(body, queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
    }
  
    return fiche;
  }

  function getStateFichesIndividuellesInte() {
    function getState(inteExerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.inteExercices.repartition.getStateFichesIndividuellesInte(inteExerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(body, queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
    }

    return getState;
  }

  function generateFichesIndividuellesInte() {
    function generate(inteExerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.inteExercices.repartition.generateFichesIndividuellesInte(inteExerciceId);
      let postForPath = apiConstructor.post(path);
      return postForPath(body, queryParams, headers).then(result => {
        TOAST.success(TOAST_MESSAGE.success.asynchrone.defaultTitle, Utils.EMPTY_STRING);
        return Promise.resolve(result);
      });
    }

    return generate;
  }

  function getFichesIndividuellesInte() {
    function fichesIndividuelles(exerciceId, body, queryParams, headers) {
      let path = internalApiRoutes.inteExercices.repartition.getFichesIndividuellesInte(exerciceId);
      let getForPath = apiConstructor.get(path);
      return getForPath(body, queryParams, headers).then(result => {
        return Promise.resolve(result);
      });
    }

    return fichesIndividuelles;
  }