import internalApi from "@/api/internalApi";
import _ from "lodash";

export default {
  state: {
    last_selected_cap_company_status: undefined
  },

  getters: {
    getLastSelectedCapCompanyStatus: state => () => {
      return _.cloneDeep(state.last_selected_cap_company_status);
    },
  },

  mutations: {
    SET_LAST_SELECTED_CAP_COMPANY_STATUS(state, obj) {
      state.last_selected_cap_company_status = obj;
    }
  },
  actions: {
    resetCapCompany({commit}) {
      commit("SET_LAST_SELECTED_CAP_COMPANY_STATUS", null);
    }
  }
};
