import {TOAST} from "@/plugins/toast/definition";
import Utils from "@/utils.js";
import httpStatusText from "./httpStatusText";
import rolesList from "@/router/rolesList.js"
import authService from '@/services/authService.js';
import router from '@/router/';
import store from "@/store";


const ERROR_TITLE = "Une erreur est survenue";

export default function displayError(error) {
  let errorMessage = Utils.EMPTY_STRING;
  let errorTitle = ERROR_TITLE;
  if (error.response && error.response.status) {
    const status = error.response.status;
    if (status < 400) {
      errorMessage = "Erreur inattendue";
    } else if (status === 401) {
      console.error("Authentification requise : " + error.response.data.message);
      authService.logout();
      if (error.response.data) {
        if (error.response.data.error === "ExpiredRefreshError" || error.response.data.type === "ExpiredAccessError") {
          errorMessage = "La session a expiré, veuillez vous reconnecter.";
        } else if (error.response.data.error === "MissingUserError" || error.response.data.type === "MissingUserError") {
          errorMessage = "Utilisateur inconnu.";
        } else if (error.response.data.error === "MissingToken" || error.response.data.type === "MissingToken") {
          errorMessage = "Impossible de charger les informations, veuillez vous reconnecter.";
        } else if (error.response.data.error === "AuthenticationError" || error.response.data.type === "AuthenticationError") {
          errorMessage = "La combinaison identifiant/mot de passe est erronée. En cas de question, nous vous invitons à contacter votre coopérative.";
        }
      } else {
        errorMessage = httpStatusText[status];
      }
    } else if (status === 403) {
      if (error.response.data.type === "UnauthorisedUniverseException") {
        errorMessage = error.response.data.message;
        router.push({name: "home"});
      } else if (error.response.data.type === "UnauthorisedDroitAccesException") {
        errorMessage = error.response.data.message;
      } else {
        console.error("Accès non autorisé pour le rôle : " + error.response.data.message);
        errorMessage = httpStatusText[status];
      }
    } else if (status === 422) {
      errorMessage = "Corps de la requête invalide.";
    }
    else {
      if (error.response.data && error.response.data.message && error.response.data.message !== "") {
        errorMessage += "\n" + error.response.data.message;

      } else {
        if (authService.getCurrentRoles().includes(rolesList.TIERS)) {
          errorMessage = `Veuillez contacter votre responsable :<br/>
          <ul>
            <li>${store.getters.getCompanyResponsablePhone().replace(/(^\+33)(\d)(\d+)/, '$1 $2 $3').replace(/\B(?=(\d{2})+(?!\d))/g, " ")}</li>
            <li>${store.getters.getCompanyResponsableEmail()}</li>
          </ul>`;
        } else {
          errorMessage = httpStatusText[status];
        }
      }
      if (error.response.data && error.response.data.error_code && error.response.data.error_code !== "") {
        errorTitle += " - " + error.response.data.error_code;
      }

    }
  } else {
    errorMessage = "Connexion au serveur perdue";
  }
  TOAST.error(errorTitle, errorMessage);
}
