import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default reglementsApi;

function reglementsApi() {
  const specificApi = {
    deleteReglementById: deleteReglementById(),
  };
  return Object.assign(specificApi, defaultApi(internalApiRoutes.reglements.baseUrl, [
    ACCEPTED_METHODS.postAll,
  ]));
}

function deleteReglementById() {
  function deleteReglement(id, queryParams, headers) {
    let path = internalApiRoutes.reglements.deleteReglementById(id);
    let getForPath = apiConstructor.delete(path);
    return getForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return deleteReglement;
}