import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default administrationApi;

function administrationApi() {
  return {
    uploadGeneratedDocumentsLogo: uploadGeneratedDocumentsLogo(),
    uploadGeneratedDocumentsFooter: uploadGeneratedDocumentsFooter(),
    postDocumentsFields: postDocumentsFields(),
    getDocumentsFields: getDocumentsFields(),
    uploadFileInitial: uploadFileInitial(),
    downloadFileInitial: downloadFileInitial(),
    downloadModelFileInitial: downloadModelFileInitial(),
    downloadFileTiersActifs: downloadFileTiersActifs(),
  };
}

function uploadGeneratedDocumentsLogo() {
  function uploadGeneratedDocumentsLogo(body, queryParams, headers) {
    let path = internalApiRoutes.administration.generatedDocumentsLogo();
    queryParams = queryParams || {};
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return uploadGeneratedDocumentsLogo;
}
function uploadGeneratedDocumentsFooter() {
  function uploadGeneratedDocumentsFooter(body, queryParams, headers) {
    let path = internalApiRoutes.administration.generatedDocumentsFooter();
    queryParams = queryParams || {};
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return uploadGeneratedDocumentsFooter;
}

function postDocumentsFields() {
  function upload(body, queryParams, headers) {
    let path = internalApiRoutes.administration.documentsFields();
    let postForPath = apiConstructor.post(path, body);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return upload;
}
function getDocumentsFields() {
  function getDocumentsFieldsMethod(queryParams, headers) {
    let path = internalApiRoutes.administration.documentsFields();
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return getDocumentsFieldsMethod;
}

function uploadFileInitial() {
  function uploadFileInitial(body, queryParams, headers) {
    let path = internalApiRoutes.administration.uploadFileInitial();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }
  return uploadFileInitial;
}

function downloadFileInitial() {
  function downloadFileInitial(queryParams, headers) {
    let path = internalApiRoutes.administration.downloadFileInitial();
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadFileInitial;
}

function downloadModelFileInitial() {
  function downloadModelFileInitial(queryParams, headers) {
    let path = internalApiRoutes.administration.downloadModelFileInitial();
    queryParams = queryParams || {};
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadModelFileInitial;
}

function downloadFileTiersActifs() {
  function downloadFileTiersActifs(dateString, queryParams, headers) {
    let path = internalApiRoutes.administration.downloadFileTiersActifs(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return downloadFileTiersActifs;
}