<!-- VUETIFY2 - OK -->
<template>
    <v-navigation-drawer v-model="drawer" app clipped dark class="grey darken-3">
        <p class="universe-title text-uppercase white--text ma-0 pa-4">
            {{universeTitle || universe}}
        </p>
        <v-list class="pa-0">
            <v-list-item-group active-class="primary--text">
                <v-list-item exact v-for="page in getMenuList()"
                             :key="page.id"
                             :to="page.route">

                    <v-row no-gutters align="center">
                        <v-col md="2" lg="2" xl="3">
                            <v-list-item-icon>
                                <v-icon v-text="page.icon"/>
                            </v-list-item-icon>
                        </v-col>
                        <v-col>
                            <v-list-item-content>
                                <v-list-item-title v-text="page.title"/>
                            </v-list-item-content>
                        </v-col>
                    </v-row>

                </v-list-item>
            </v-list-item-group>
        </v-list>
        <template v-slot:append>
            <div v-if="isCurrentUserTiers()">
                <div style="position: relative; top:12px; text-align: center;"><v-icon>{{ "$vuetify.icons.question" }}</v-icon></div>
                <div class="px-2 pb-2 pt-3 mb-2" style="background-color: #555; color:#FFF; font-size:0.8em;">
                    <p>En cas de question, nous vous invitons à contacter votre coopérative&nbsp;:</p>
                    <ul class="pl-2">
                        <li><v-row align="center" justify="space-between" no-gutters>
                            <v-col cols="3">Contact&nbsp;:</v-col>
                            <v-col style="text-align: right;">{{companyResponsablePrenom}} {{companyResponsableNom}}</v-col>
                        </v-row></li>
                        <li><v-row align="center" justify="space-between" no-gutters>
                            <v-col cols="3">Courriel&nbsp;:</v-col>
                            <v-col style="text-align: right;"><a :href="'mailto:' + getCompanyResponsableEmail">{{getCompanyResponsableEmail}}</a></v-col>
                        </v-row></li>
                        <li><v-row align="center" justify="space-between" no-gutters>
                            <v-col cols="4">Téléphone&nbsp;:</v-col>
                            <v-col style="text-align: right;"><a :href="'tel:' + companyResponsablePhone">{{companyResponsablePhone}}</a></v-col>
                        </v-row></li>
                    </ul>
                </div>
            </div>
            <template v-if="mayDisplayUniverses()">
                <v-list>
                    <v-list-item-group v-for="universMenu in getUniversMenuList()" :key="universMenu.label">
                        <v-list-item :class="universMenu.styleClass" :to="{name: universMenu.route}">
                            <v-list-item-content>
                                <v-list-item-title v-text="universMenu.label"/>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
            <div class="pl-2 mt-n2" style="color:#888; font-size:0.9em;">
                Version {{ appVersion }} - {{ tenant }}
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import authService from "@/services/authService";
    import _ from "lodash";
    import rolesList from "../router/rolesList";

    export default {
        props: ['universe', 'universeTitle'],
        methods: {
            toggleNavigation: function () {
                this.drawer = !this.drawer;
            },
            routeAccessAuthorized: function (routeName) {
                let route = this.getRoute(routeName.name, this.$router.options.routes);
                const authorized_roles = route.meta.authorizedRoles;
                const currentRoles = authService.getCurrentRoles();
                const rolesIntersection = _.intersection(authorized_roles, currentRoles);
                const personneType = authService.getCurrentPersonneType();
                const personneTypeMatch = _.isNil(personneType) || _.isNil(route.meta.allowedPersonneType) || personneType === route.meta.allowedPersonneType;
                // Si la personne doit changer son mot de passe, on vérifie que la route n'est pas une exception
                const doitChangerMotDePasse = _.includes(currentRoles, rolesList.DOIT_CHANGER_MOT_DE_PASSE);
                const ignoreDoitChangerMotDePasse = route.meta.ignoreDoitChangerMotDePasse || false;
                const canProceedWithoutChangingPassword = !doitChangerMotDePasse || ignoreDoitChangerMotDePasse;
                return !_.isEmpty(rolesIntersection) && personneTypeMatch && canProceedWithoutChangingPassword;
            },
            getRoute: function (routeName, routesList) {
                let result = _.find(routesList, i => i.name === routeName);
                if (_.isNil(result)) {
                    let indexRoute = 0;
                    while (indexRoute < routesList.length && _.isNil(result)) {
                        let route = routesList[indexRoute];
                        if (!_.isNil(route.children)) {
                            result = this.getRoute(routeName, route.children);
                        }
                        indexRoute++;
                    }
                }
                return result
            },
            getMenuList: function () {
                return _.filter(this.menu[this.universe], menu => (this.routeAccessAuthorized(menu.route) &&
                    (_.isNil(menu.acces) || menu.acces === "capital" && this.hasCapitalAccess() || menu.acces === "participation" && this.hasParticipationAccess() || menu.acces === "interessement" && this.hasInteressementAccess())
                ))
            },
            getUniversMenuList() {
                var universMenuList = []
                var styleIndex = 1
                if (this.universe !== 'commun'){
                    universMenuList.push({
                        label: "COMMUN",
                        route: "uv-commun",
                        styleClass: "univ-" + styleIndex
                    })
                    styleIndex ++;
                }
                if (this.universe !== 'capital' && this.hasCapitalAccess()){
                    universMenuList.push({
                        label: "CAPITAL",
                        route: "uv-capital",
                        styleClass: "univ-" + styleIndex
                    })
                    styleIndex ++;
                }
                if (this.universe !== 'participation' && this.hasParticipationAccess()){
                    universMenuList.push({
                        label: "PARTICIPATION",
                        route: "uv-participation",
                        styleClass: "univ-" + styleIndex
                    })
                    styleIndex ++;
                }
                if (this.universe !== 'interessement' && this.hasInteressementAccess()){
                    universMenuList.push({
                        label: "INTÉRESSEMENT",
                        route: "uv-interessement",
                        styleClass: "univ-" + styleIndex
                    })
                    styleIndex ++;
                }

                return universMenuList
            },
            mayDisplayUniverses: function () {
                const authorized_roles = [rolesList.ADMINISTRATEUR, rolesList.RESPONSABLE, rolesList.GESTIONNAIRE];
                const currentRoles = authService.getCurrentRoles();
                const rolesIntersection = _.intersection(authorized_roles, currentRoles);
                return !_.isEmpty(rolesIntersection);
            },
            isCurrentUserTiers: function () {
                return authService.getCurrentRoles().includes(rolesList.TIERS);
            },
            hasCapitalAccess: function () {
                return authService.hasAccessCapital();
            },
            hasParticipationAccess: function () {
                return authService.hasAccessParticipation();
            },
            hasInteressementAccess: function () {
                return authService.hasAccessInteressement();
            },
        },
        computed: {
            tenant() {
                return authService.getCurrentTenant();
            },
            companyResponsableNom() {
                let nom = this.$store.getters.getCompanyResponsableNom();
                if (!_.isNil(nom)) {
                    nom = nom.toUpperCase();
                }
                return nom;
            },
            companyResponsablePrenom() {
                return this.$store.getters.getCompanyResponsablePrenom();
            },
            getCompanyResponsableEmail() {
                return this.$store.getters.getCompanyResponsableEmail();
            },
            companyResponsablePhone() {
                return this.$store.getters.getCompanyResponsablePhone();
            },
        },
        data() {
            return {
                appVersion: process.env.VUE_APP_VERSION,
                drawer: true,
                mini: true,
                menu: {
                    commun: [
                        {
                            id: 0,
                            title: "Données personnelles",
                            icon: "$associes",
                            route: {name: "pp_infos_personnelles"}
                        },
                        {
                            id: 1,
                            title: "Données personnelles",
                            icon: "$associes",
                            route: {name: "pm_infos_personnelles"}
                        },
                        {
                            id: 2,
                            title: "Espace capital",
                            icon: "$personalInfos",
                            route: {name: "espace_capital"},
                            acces: "capital"
                        },
                        {
                            id: 3,
                            title: "Espace participation",
                            icon: "$personalInfos",
                            route: {name: "espace_participation"},
                            acces: "participation"
                        },
                        {
                            id: 4,
                            title: "Espace intéressement",
                            icon: "$personalInfos",
                            route: {name: "espace_individuel_interessement"},
                            acces: "interessement"
                        },
                        {
                            id: 5,
                            title: "Documents",
                            icon: "$documents",
                            route: {name: "espace_personnel_documents"},
                        },
                        {
                            id: 6,
                            title: "Société",
                            icon: "$societe",
                            route: {name: "company"}
                        },
                        {
                            id: 7,
                            title: "Tiers",
                            icon: "$associes",
                            route: {name: "tiers"}
                        },
                        {
                            id: 8,
                            title: "Exercices",
                            icon: "$exercices",
                            route: {name: "exercices"}
                        },
                        {
                            id: 9,
                            title: "Dettes",
                            icon: "$regulation",
                            route: {name: "dettes"}
                        },
                        {
                            id: 10,
                            title: "Fiscalité",
                            icon: "$landmark",
                            route: {name: "taux_fiscalite"}
                        },
                        {
                            id: 11,
                            title: "Droits d'accès",
                            icon: "$droitsAcces",
                            route: {name: "droits_acces"}
                        },
                        {
                            id: 12,
                            title: "Administration",
                            icon: "$settings",
                            route: {name: "administration"}
                        },
                    ],
                    capital: [
                        {
                            id: 0,
                            title: "Statuts",
                            icon: "$statuts",
                            route: {name: "statuts"}
                        },
                        {
                            id: 1,
                            title: "Mouvements",
                            icon: "$mouvement",
                            route: {name: "mouvements"}
                        },
                        {
                            id: 2,
                            title: "Exercices",
                            icon: "$exercices",
                            route: {name: "cap-exercices"}
                        },
                        {
                            id: 3,
                            title: "Dettes",
                            icon: "$regulation",
                            route: {name: "cap-dettes"}
                        },
                        {
                            id: 4,
                            title: "Documents légaux",
                            icon: "$documents",
                            route: {name: "fiscalite_capital"}
                        },
                        {
                            id: 5,
                            title: "Tableaux de bord",
                            icon: "$capital",
                            route: {name: "dashboard_capital"}
                        },
                        {
                            id: 6,
                            title: "Conformité",
                            icon: "$conformite",
                            route: {name: "conformite"}
                        },
                    ],
                    participation: [
                        {
                            id: 0,
                            title: "Accord",
                            icon: "$documents",
                            route: {name: "accord"}
                        },
                        {
                            id: 1,
                            title: "Supports",
                            icon: "$piggy_bank",
                            route: {name: "pb-supports"}
                        },
                        {
                            id: 2,
                            title: "Exercices",
                            icon: "$exercices",
                            route: {name: "pb-exercices"}
                        },
                        {
                            id: 3,
                            title: "Déblocages",
                            icon: "$hand_money",
                            route: {name: "pb-deblocages"}
                        },
                        {
                            id: 4,
                            title: "Dettes",
                            icon: "$regulation",
                            route: {name: "pb-dettes"}
                        },
                        {
                            id: 5,
                            title: "Documents légaux",
                            icon: "$documents",
                            route: {name: "fiscalite_participation"}
                        },
                        {
                            id: 6,
                            title: "Tableaux de bord",
                            icon: "$capital",
                            route: {name: "dashboard_participation"}
                        },
                    ],
                    interessement: [
                        {
                            id: 0,
                            title: "Accord",
                            icon: "$documents",
                            route: {name: "inte-accord"}
                        },
                        {
                            id: 1,
                            title: "Supports",
                            icon: "$piggy_bank",
                            route: {name: "liste-supports-interessement"}
                        },
                        {
                            id: 2,
                            title: "Exercices",
                            icon: "$exercices",
                            route: {name: "inte-exercices"}
                        },
                        {
                            id: 3,
                            title: "Dettes",
                            icon: "$regulation",
                            route: {name: "inte-dettes"}
                        },
                    ],
                }
            };
        },
    };
</script>

<style>
    .univ-1 {
        background-color: #555;
    }

    .univ-2 {
        background-color: #777;
    }
    
    .univ-3 {
        background-color: #999;
    }

    .app-version {
        color: white;
        text-align: right;
    }

    .universe-title {
        font-size: 1.2vw;
        background-color: #555;
    }
</style>
