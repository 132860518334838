<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="110px" :withFooter="false">
        <template v-slot:header>
            <v-tabs v-model="currentTab">
                <v-tab key="colleges">Collèges de vote</v-tab>
                <v-tab key="sous_categorie_associe">Sous-catégories d'associés</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="colleges">
                    <v-scic-colleges-list/>
                </v-tab-item>
                <v-tab-item key="sous_categorie_associe">
                    <v-scic-sous-categories-list/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>
<script>
    import VScicCollegesList from "@/views/scic/VScicCollegesList";
    import VScicSousCategoriesList from "@/views/scic/VScicSousCategoriesList";
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";

    export default {
        name: "VScicCollegesSousCategories",
        components: {
            loadingScreen,
            BaseLayout,
            VScicCollegesList,
            VScicSousCategoriesList,
        },
        data() {
            return {
                currentTab: undefined,
            }
        },
    }
</script>