<!-- VUETIFY2 - OK -->
<template>
    <v-select   :items="scicCollegeLabels"
                label="Collège d'associé"
                :error-messages="errors"
                :disabled="isDisabled"
                v-model="localScicCollegeAssocie"
                :clearable="true"
    />
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils.js";

    /**
     * Composant affichant la liste des collèges de vote (SCIC).
     * @displayName Scic - Liste collèges de vote
     */
    export default {
        name: "VScicCollegeList",
        props: {
            /**
             * Le collège sélectionné
             */
            scicCollegeAssocie: {
                type: String,
            },
            /**
             * Désactive ou non le composant
             */
            isDisabled: Boolean,
            /**
             * Les erreurs générées par un ValidationProvider
             */
            errors: {
                type: Array,
                required: false,
            }
        },
        mounted() {
            this.fetchScicCollegeLabels();
        },
        computed: {
            localScicCollegeAssocie: {
                get: function () {
                    return this.scicCollegeAssocie;
                },
                set: function (newvalue) {
                    this.$emit("update-scic-college-associe", newvalue);
                }
            },
        },
        data() {
            return {
                scicCollegeLabels: [],

            }
        },
        methods: {
            fetchScicCollegeLabels() {
                internalApi.scicCollege.getLabels().then(res => {
                    this.scicCollegeLabels = Utils.fromLabelToSelect(res.colleges);
                });
            }
        }
    };
</script>


