var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","disabled":!_vm.areSalariesLoaded,"block":""}},on),[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(_vm._s("$vuetify.icons.forward"))]),_vm._v(" Créer les souscriptions ")],1)]}}]),model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('v-card',{attrs:{"round":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v("Créer les souscriptions de l'exercice \""+_vm._s(_vm.libelleExercice)+"\" ? ")]),_c('v-divider'),_c('v-card-text',[_vm._v("Veuillez sélectionner la catégorie de capital qui fera l'objet des souscriptions obligatoires : ")]),_c('v-col',{attrs:{"md":"6","offset-md":"2"}},[_c('ValidationProvider',{attrs:{"vid":"categorieSouscription","name":"Catégorie de capital","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-categories-capital-list',{attrs:{"errors":errors},model:{value:(_vm.localCategorieSouscription),callback:function ($$v) {_vm.localCategorieSouscription=$$v},expression:"localCategorieSouscription"}})]}}],null,true)})],1),_c('v-card-text',[_vm._v("Vous pouvez modifier la date de souscription, positionnée par défaut à la date d'AG : ")]),_c('v-divider'),_c('v-col',{attrs:{"md":"6","offset-md":"2"}},[_c('ValidationProvider',{attrs:{"vid":"dateSouscription","name":"Date de souscription","rules":{ required: true, date_after: _vm.dateMinimumSouscription }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateField',{attrs:{"label":"Date de souscription","errors":errors},model:{value:(_vm.localDateSouscription),callback:function ($$v) {_vm.localDateSouscription=$$v},expression:"localDateSouscription"}})]}}],null,true)})],1),_c('v-card-text',{staticClass:"font-weight-medium"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(_vm._s("$vuetify.icons.warning"))]),_vm._v(" Les affectations de participation aux bénéfices ne seront plus modifiables après cette action. ")],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.onCancelClick}},[_vm._v("Annuler ")])],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.localCategorieSouscription,"block":""},on:{"click":function($event){return handleSubmit(_vm.validerSouscription)}}},[_vm._v(" Confirmer ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }