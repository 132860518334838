var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('loading-screen',{ref:"loadingComponent"}),_c('v-data-table',{ref:"liste_salaires_presences",staticClass:"elevation-1",attrs:{"search":_vm.search,"footer-props":_vm.footerProps,"headers":_vm.headers,"items":_vm.salairePresences,"loading":_vm.loadingSalairePresences,"server-items-length":_vm.total_items,"height":_vm.datatableSize,"fixed-header":"","no-data-text":"Aucune donnée.","options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.showPlafond && item.montant_salaire > item.plafond_proratise)?_c('span',{staticClass:"ml-5"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("Le salaire de ce(tte) salarié(e) dépasse son plafond personnalisé positionné sur cet exercice ("+_vm._s(_vm._f("roundEuroFilter")(item.plafond_proratise))+"), le montant au dessus de ce plafond ne sera pas pris en compte lors de la répartition de la participation.")])])],1):_vm._e()]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.numero_tiers)+" ")])]),(_vm.repartitionSalaireActive)?_c('td',[_c('ValidationProvider',{attrs:{"name":'Salaire de ' + item.name,"rules":"currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"slot":"input","prefixValue":"€","labelValue":"Salaire","reverseValue":true,"isSingleLine":true,"isAutoFocus":false,"min":0,"errors":errors,"hideDetails":true,"specificClass":"v-text-field-inline-datatable-editing","disabledValue":_vm.disabled},on:{"input":function($event){_vm.dataChanged = true}},slot:"input",model:{value:(item.montant_salaire),callback:function ($$v) {_vm.$set(item, "montant_salaire", $$v)},expression:"item.montant_salaire"}})]}}],null,true)})],1):_vm._e(),(_vm.repartitionPresenceActive)?_c('td',[_c('ValidationProvider',{attrs:{"name":'Heures annuelles de ' + item.name,"rules":"currency|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"slot":"input","labelValue":"Heures annuelles","reverseValue":true,"isSingleLine":true,"isAutoFocus":false,"min":0,"errors":errors,"hideDetails":true,"specificClass":"v-text-field-inline-datatable-editing","disabledValue":_vm.disabled},on:{"input":function($event){_vm.dataChanged = true}},slot:"input",model:{value:(item.heures_annuelles),callback:function ($$v) {_vm.$set(item, "heures_annuelles", $$v)},expression:"item.heures_annuelles"}})]}}],null,true)})],1):_vm._e(),(_vm.repartitionAncienneteActive)?_c('td',[_c('show-default-or-derogatory-value',{attrs:{"label":"Coefficient d'ancienneté","defaultValue":item.coefficient_anciennete,"explainTextDefaultValue":_vm.explainTextDefaultValue,"explainTextDerogatoryValue":_vm.explainTextDerogatoryValue,"cardSubtitleText":_vm.computedCardSubtitleText(item.name)},on:{"update-value":function($event){_vm.derogatoryValue = $event}},model:{value:(item.coefficient_anciennete_derogatoire),callback:function ($$v) {_vm.$set(item, "coefficient_anciennete_derogatoire", $$v)},expression:"item.coefficient_anciennete_derogatoire"}})],1):_vm._e(),_c('td',[_c('ValidationProvider',{attrs:{"name":'Règle d\'éligibilité de ' + item.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.choixReglesEligibilite,"item-text":"text","item-value":"value","hideDetails":true,"error-messages":errors,"disabled":_vm.disabled},on:{"input":function($event){_vm.dataChanged = true}},model:{value:(item.regle_eligibilite),callback:function ($$v) {_vm.$set(item, "regle_eligibilite", $$v)},expression:"item.regle_eligibilite"}})]}}],null,true)})],1)])]}}])})],1)],1),(_vm.repartitionSalaireActive)?_c('v-row',[_vm._v(" Total des salaires : "+_vm._s(_vm._f("roundEuroFilter")(_vm.total_salaire))+" ")]):_vm._e(),(_vm.repartitionPresenceActive)?_c('v-row',[_vm._v(" Total des temps de présence : "+_vm._s(_vm._f("roundNumberFilter")(_vm.total_presence))+" heures ")]):_vm._e(),(_vm.plafond_salaire > 0 && _vm.showPlafond)?_c('v-row',[_vm._v(" Plafond de salaire : "+_vm._s(_vm._f("roundEuroFilter")(_vm.plafond_salaire))+" pour un salarié ayant travaillé sur tout l'exercice ")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v("Avertissement")]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_vm._v(" Vous avez saisi des données qui n'ont pas été enregistrées, êtes-vous sûr(e) de vouloir continuer ? ")])],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.closePopUp}},[_vm._v("NON, ANNULER")])],1),_c('v-col',{attrs:{"md":"4","lg":"4","xl":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.confirmChangePagination}},[_vm._v("OUI, CONFIRMER")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }