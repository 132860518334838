import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default aboEligibilitesApi;

function aboEligibilitesApi() {
    return defaultApi(
        internalApiRoutes.aboEligibilites.baseUrl,
        [
            ACCEPTED_METHODS.getById,
            ACCEPTED_METHODS.putById,
        ],
    );
}
