var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formNewUserAccess",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var reset = ref.reset;
return [_c('base-layout',{attrs:{"headerSize":"80px"}},[_c('template',{slot:"header"},[_c('h1',[_vm._v("Créer un accès utilisateur")])]),_c('template',{slot:"main"},[_c('br'),_c('v-subheader',[_c('h2',[_vm._v("Tiers pour qui créer le compte")])]),_c('v-container',{staticClass:"pt-0",attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"3"}},[_c('ValidationProvider',{attrs:{"vid":"personne","name":"Personne","rules":"required","disabled":_vm.isTechnicalUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-personnes-list',{attrs:{"errors":errors,"noUserFilter":true,"isDisabled":_vm.isTechnicalUser},on:{"input":function($event){_vm.localPersonneId = $event}},model:{value:(_vm.localPersonneId),callback:function ($$v) {_vm.localPersonneId=$$v},expression:"localPersonneId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"3"}},[_c('v-checkbox',{attrs:{"label":"Utilisateur technique"},on:{"change":function($event){return _vm.updateTechnicalUser($event)}},model:{value:(_vm.isTechnicalUser),callback:function ($$v) {_vm.isTechnicalUser=$$v},expression:"isTechnicalUser"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"3"}},[_c('v-subheader',[_c('h2',[_vm._v("Données de connexion")])]),_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12"}},[_c('ValidationProvider',{attrs:{"vid":"username","name":"Nom d'utilisateur","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nom d'utilisateur","error-messages":errors},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)})],1)],1)],1)],1),_c('v-col',{attrs:{"md":"6","lg":"6","xl":"6"}},[_c('v-new-password-text-and-confirmation',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1),_c('template',{slot:"footer"},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"md":"6","lg":"6","xl":"4"}},[_c('v-save-buton-with-validation',{attrs:{"showToast":false,"resetForm":reset,"validateForm":validate,"errorsList":errors,"saveMessage":"Créer un accès utilisateur"},on:{"emit-cancel":_vm.returnToDroitsAcces,"emit-valid-save":_vm.onCreateNewUseracces}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }