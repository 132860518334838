<!-- VUETIFY2 - OK -->
<template>
    <v-tooltip key="label" :bottom="tooltipBottom" :top="tooltipTop" :left="tooltipLeft" :right="tooltipRight"
               :disabled="isTooltipDisabled">
        <template v-slot:activator="{ on: tooltip }">
            <span v-on="{ ...tooltip }">
                <v-btn :bottom="btnBottom" :top="btnTop" :left="btnLeft" :right="btnRight" :rounded="btnRound"
                       :icon="btnIsIcon"
                       :text="btnText" :fab="btnFab" :dark="btnDark" :small="btnSmall" :color="btnColorClass"
                       :to="routeCible"
                       :disabled="disabled"
                       @click="$emit('click', $event)" class="grey--text" v-on="{ ...activator }">
                    <v-icon :small="iconSmall" :color="iconColorClass">{{ icon }}</v-icon>
                    <span v-if="showTextButton()" style="margin-left: 5px">{{detailButtonText}}</span>
                </v-btn>
            </span>
        </template>
        <span>{{ detailToShow }}</span>
    </v-tooltip>
</template>

<script>
    /**
     * Component documented usig Propdoc syntax:
     * https://alligator.io/vuejs/propdoc-document-components/
     */
    export default {
        name: "VIconButtonWithToolTip",
        introduction: "Bouton avec tooltip.",
        description: "Composant affichant l'icone icon dans un bouton rond. Un tooltip avec le detail s'affiche en dessous quand la souris passe dessus",
        props: {
            detail: String,
            detailOnDisabledButton: {
                type: String,
                required: false,
            },
            icon: String,
            btnColorClass: {
                type: String,
                required: false,
                default: "primary"
            },
            iconColorClass: {
                type: String,
                required: false,
                default: ""
            },
            routeCible: {
                type: Object,
                required: false,
                default: undefined
            },
            btnFab: {
                type: Boolean,
                required: false,
                default: false
            },
            btnText: {
                type: Boolean,
                required: false,
                default: false
            },
            btnIsIcon: {
                type: Boolean,
                required: false,
                default: false
            },
            btnDark: {
                type: Boolean,
                required: false,
                default: false
            },
            btnSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            btnRound: {
                type: Boolean,
                required: false,
                default: false
            },
            iconSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            tooltipBottom: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipTop: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipLeft: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipRight: {
                type: Boolean,
                required: false,
                default: false
            },
            btnBottom: {
                type: Boolean,
                required: false,
                default: false
            },
            btnTop: {
                type: Boolean,
                required: false,
                default: false
            },
            btnLeft: {
                type: Boolean,
                required: false,
                default: false
            },
            btnRight: {
                type: Boolean,
                required: false,
                default: false
            },
            detailButtonText: {
                type: String,
                required: false,
                default: null
            },
            isTooltipDisabled: {
                type: Boolean,
                required: false,
                default: false
            },
            activator: {
                required: false,
                default: undefined
            },
        },
        methods: {
            showTextButton() {
                return !_.isNil(this.detailButtonText);
            },
        },
        computed: {
            detailToShow() {
                if (_.isNil(this.detailOnDisabledButton)) {
                    return this.detail;
                }
                return this.disabled ? this.detailOnDisabledButton : this.detail;
            }
        },
    };
</script>
