<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-data-table :headers="headers"
                      :height="height"
                      class="elevation-1"
                      :items="associeRows"
                      :no-data-text="noDataTextMessage"
                      :footer-props="footerProps"
                      :loading="isDataLoading"
                      fixed-header
                      :options.sync="options">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <span>
                            <v-row no-gutters align="center" justify="space-between">
                                <v-col class="shrink text-no-wrap">
                                    {{ item.label }}
                                </v-col>
                                <v-col md="1" lg="1" xl="1" v-if="item.has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>
                                            {{ message_detail_extra_mouvements_after_exercice }}<br/>
                                            Codes des catégories concernées : {{ item.has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag.join(', ') }}
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col md="1" lg="1" xl="1" v-if="item.renonciation && !isRepartitionValide">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                        </template>
                                        <span>Intérêts aux parts sociales non pris en compte pour cause de renonciation.</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </span>
                    </td>
                    <td>
                        <span>{{ item.numero_tiers }}</span>
                    </td>
                    <td v-for="categ in categoryColumns" :key="categ.code" align="right">
                        <v-tooltip bottom v-if="item[categ.code]">
                            <template v-slot:activator="{ on }">
                                <span v-on="{ ...on }">
                                    {{ item[categ.code].montant | roundCurrencyFilter }}
                                </span>
                            </template>
                            <span>{{ item[categ.code].nb_parts | roundNumberFilter }} part(s) éligible(s)</span><br/>
                            <span v-if="item[categ.code].charges_sociales != null">{{ item[categ.code].charges_sociales | roundEuroFilter}} charges sociales</span>
                            <span v-else>Exonéré de charges sociales ou personne morale</span>
                            <br/>
                            <span v-if="item[categ.code].prelevement_forfaitaire != null">{{ item[categ.code].prelevement_forfaitaire | roundEuroFilter}} prélèvement forfaitaire</span>
                            <span v-else>Renonciation au prélèvement forfaitaire ou personne morale</span>
                            <br/>
                            <span>{{ item[categ.code].montant_a_payer | roundEuroFilter }} montant à payer</span><br/>
                        </v-tooltip>
                        <span v-else>-</span>
                    </td>
                    <td class="text-lg-right">{{ item.total | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.total_charges_sociales | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.total_prelevement_forfaitaire | roundCurrencyFilter }}</td>
                    <td class="text-lg-right">{{ item.total_a_payer | roundCurrencyFilter }}</td>
                </tr>
            </template>
            <template v-slot:[`body.append`] v-if="isTotal">
                <tr class="v-data-table__nohoverezi">
                    <td class="font-weight-bold" colspan="2">
                        Totaux par catégorie de capital
                    </td>
                    <td class="text-lg-right font-weight-bold" v-for="categ in categoryColumns" :key="categ.code">
                        {{ categ.total | roundCurrencyFilter }}
                    </td>
                    <td class="text-lg-right font-weight-bold">
                        {{ repartitionTotal | roundCurrencyFilter }}
                    </td>
                    <td class="text-lg-right font-weight-bold">
                        {{ repartitionChargesSociales | roundCurrencyFilter }}
                    </td>
                    <td class="text-lg-right font-weight-bold">
                        {{ prelevementForfaitaireTotal | roundCurrencyFilter }}
                    </td>
                    <td class="text-lg-right font-weight-bold">
                        {{ repartitionTotalMontantAPayer | roundCurrencyFilter }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import Utils from "@/utils.js";
    import _ from "lodash";
    import loadingScreen from "@/components/LoadingScreen.vue";

    /**
     * Composant affichant une VDatatable d'une répartition aux intérêts capital
     * @display_name Capital - VDatatableRepartitionCap
     */
    export default {
        components: {
            loadingScreen,
        },
        name: "v-datatable-repartition-cap",
        props: {
            /**
             * La liste de répartitions
             */
            repartitionsList: {
                type: Array,
                required: true
            },
            /**
             * L'infobulle à afficher si des mouvements hors exercice sont comptabilisés
             */
            message_detail_extra_mouvements_after_exercice: {
                type: String,
                required: true,
                default: "",
            },
            /**
             * Texte à afficher si tableau vide
             */
            noDataTextMessage: {
                type: String,
                required: false,
                default: "Aucune donnée disponible."
            },
            /**
             * Est un affichage de plusieurs lignes
             */
            isTotal: {
                type: Boolean,
                required: false,
                default: true,
            },
            /**
             * Hauteur de la datatable
             */
            height: {
                type: String,
                required: false,
            },
            /**
             * Indiquer que la datatable attends des données
             */
            isDataLoading: {
                type: Boolean,
                default: false,
            },
            isRepartitionValide: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                options: {
                    sortBy: ["label"]
                },
            };
        },
        mounted() {
        },
        methods: {
            /**
             * Prepare specific associe table row with repartition amount if any for each
             * available category
             * @param {Object} row containing data to be display on a specific table row
             * @param {Array[Object]} associeRepartitionList list of repartitions associated
             * with the current row's user
             */
            computeMontantAssociePerCategory(row, associeRepartitionList) {
                for (let categ of this.categoryColumns) {
                    const match = _.find(
                        associeRepartitionList,
                        item => item.category_code === categ.code
                    );
                    if (match) {
                        row[categ.code] = {
                            montant: match.montant,
                            nb_parts: match.nb_parts,
                            montant_net: match.montant_net,
                            charges_sociales: match.charges_sociales,
                            prelevement_forfaitaire: match.prelevement_forfaitaire,
                            montant_a_payer: match.montant_a_payer
                        };
                    }
                }
            },
        },
        computed: {

            /**
             * Aggregate repartition data to get associe rows' labels and
             * repartition amount for each categoty with totals
             */
            associeRows() {
                return _.chain(this.repartitionsList)
                    .groupBy("associe_name")
                    .map((list, id) => {

                        // Avoid setting total to 0 if all 'prelevement_forfaitaire' are null
                        let total_prelevement_forfaitaire = null;
                        if (!_.every(list, ["prelevement_forfaitaire", null])) {
                            total_prelevement_forfaitaire = _.sumBy(list, "prelevement_forfaitaire");
                        }
                        let total_charges_sociales = null;
                        if (!_.every(list, ["charges_sociales", null])) {
                            total_charges_sociales = _.sumBy(list, "charges_sociales");
                        }

                        let row_has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag = [];
                        list.forEach(entry => {
                            if (entry.has_extra_mouvements_entre_fin_exercice_et_date_ag) {
                                row_has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag.push(entry.category_code);
                            }
                        });

                        const row = {
                            id: id,
                            label: list[0].associe_name,
                            numero_tiers: list[0].associe_numero_tiers,
                            renonciation: list[0].renonciation,
                            total: _.sumBy(list, "montant"),
                            total_net: _.sumBy(list, "montant_net"),
                            total_charges_sociales: total_charges_sociales,
                            total_prelevement_forfaitaire: total_prelevement_forfaitaire,
                            total_a_payer: _.sumBy(list, "montant_a_payer"),
                            has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag: row_has_categories_with_extra_mouvements_entre_fin_exercice_et_date_ag,
                        };
                        this.computeMontantAssociePerCategory(row, list);
                        return row;
                    })
                    .value();
            },
            /**
             * Aggregate repartition data to get category columns label and total
             */
            categoryColumns() {
                return _.chain(this.repartitionsList)
                    .groupBy("category_code")
                    .map((list, code) => ({
                        code: code,
                        label: list[0].category_code,
                        total: Utils.formatCurrency()(
                            Number(
                                _.sumBy(list, function (o) {
                                    return Number(o.montant);
                                })
                            )
                        )
                    }))
                    .sortBy("label")
                    .value();
            },
            /**
             * Prepare table header cells with each available category
             */
            headers() {
                const arr = [{text: "Associé(e)", value: "label"}, {text: "Numéro de tiers", value: "numero_tiers"}];

                for (let categ of this.categoryColumns) {
                    arr.push({
                        text: `Cat. ${categ.label}`,
                        value: `${categ.label}.montant`,
                        align: "right",
                        sortable: true
                    });
                }
                arr.push({text: "Total brut", value: "total", align: "right"});
                arr.push({text: "Charges sociales", value: "total_charges_sociales", align: "right"});
                arr.push({
                    text: "Prélèvements forfaitaires",
                    value: "total_prelevements_forfaitaires",
                    align: "right"
                });
                arr.push({text: "Total à payer", value: "total_a_payer", align: "right"});
                return arr;
            },

            repartitionTotal() {
                return Utils.formatCurrency()(
                    _.sumBy(this.categoryColumns, function (o) {
                        return Number(o.total);
                    })
                );
            },
            repartitionChargesSociales() {
                return Utils.formatCurrency()(
                    _.sumBy(this.associeRows, function (o) {
                        return Number(o.total_charges_sociales);
                    })
                );
            },
            repartitionTotalMontantAPayer() {
                return Utils.formatCurrency()(
                    _.sumBy(this.associeRows, function (associe) {
                        return Number(associe.total_a_payer);
                    })
                );
            },
            prelevementForfaitaireTotal() {
                return Utils.formatCurrency()(
                    _.sumBy(this.associeRows, function (associe) {
                        return Number(associe.total_prelevement_forfaitaire);
                    })
                );
            }
        }
    };
</script>
