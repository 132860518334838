// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Utils from "@/utils.js";
import VPfu from "@/components/fiscalite/VPfu";

/**
 * Composant affichant les données PFU capital
 * @display_name Capital - PFU
 */
export default {
    extends: VPfu,
    watch: {
        exerciceId() {
            if (this.selectedCerfa!==undefined){
                this.loadDataSync();
            }
        },
        selectedCerfa() {
            if (this.selectedCerfa!==undefined){
                this.loadDataSync();
            }
        },
    },
    methods: {
        /**
         * Détermine les intitulés de colonne
         */
        computeHeaders(){
            return [
                {
                    text: "Type de taux",
                    value: "type_taux",
                    sortable: true,
                    align: "center",
                    width: "40%"
                },
                {
                    text: "Base imposable",
                    value: "base_imposable",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Taux",
                    value: "taux",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Code",
                    value: "code",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
                {
                    text: "Impôt",
                    value: "impot",
                    sortable: false,
                    align: "center",
                    width: "15%"
                },
            ];
        },
        /**
         * Charge les données dans les tableaux
         */
        loadData() {
            this.loading = true;

            return new Promise((resolve, reject) => {

                internalApi.fiscalite_capital.pfu2777Infos(this.exerciceId, this.selectedCerfa.name)
                    .then(res => {
                        const dateFin = res.date_fin;
                        const total = res.total;
                        const titrePfuTableauPrelevementsForfaitaires = res.titre_pfu_tableau_prelevements_forfaitaires;
                        const detailPfuTableauPrelevementsForfaitaires = res.detail_pfu_tableau_prelevements_forfaitaires;
                        const titrePfuTableauChargesInterets = res.titre_pfu_tableau_charges_interets;
                        const detailPfuTableauChargesInterets = res.detail_pfu_tableau_charges_interets;
                        resolve({
                            'dateFin': dateFin,
                            'total': total,
                            'titrePfuTableauPrelevementsForfaitaires': titrePfuTableauPrelevementsForfaitaires,
                            'detailPfuTableauPrelevementsForfaitaires': detailPfuTableauPrelevementsForfaitaires,
                            'titrePfuTableauChargesInterets': titrePfuTableauChargesInterets,
                            'detailPfuTableauChargesInterets': detailPfuTableauChargesInterets
                        });
                    })
                    .catch(err => {
                        console.error("Erreur lors de la récupération des pfu : ", err);
                        const dateFin = undefined;
                        const total = 0;
                        const titrePfuTableauPrelevementsForfaitaires = undefined;
                        const detailPfuTableauPrelevementsForfaitaires = undefined;
                        const titrePfuTableauChargesInterets = undefined;
                        const detailPfuTableauChargesInterets = undefined;
                        reject({
                            'dateFin': dateFin,
                            'total': total,
                            'titrePfuTableauPrelevementsForfaitaires': titrePfuTableauPrelevementsForfaitaires,
                            'detailPfuTableauPrelevementsForfaitaires': detailPfuTableauPrelevementsForfaitaires,
                            'titrePfuTableauChargesInterets': titrePfuTableauChargesInterets,
                            'detailPfuTableauChargesInterets': detailPfuTableauChargesInterets
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
            });
        },
        /**
         * Télécharge le cerfa 2777
         */
        downloadPfu2777PDF: function () {
            if(!this.checkCanDownloadPfu()){
                return;
            }
            this.$refs.loadingComponent.setLoading("Récupération du PDF en cours ...");
            internalApi.fiscalite_capital.pfu2777PDF(this.exerciceId, this.selectedCerfa.name)
                .then(response => {
                    this.$refs.loadingComponent.resetLoading();
                    Utils.downloadFile(response, "PFU_2777.pdf", Utils.pdfMimetype);
                })
                .catch(err => {
                    console.error(err);
                    this.$refs.loadingComponent.resetLoading();
                });
        }
    }
};
