// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonnePhysique from "./PersonnePhysique";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    this.isEspacePersonnel = false;
  },
  extends: PersonnePhysique,
  computed: {

    pageTitle() {
      return "Créer un tiers personne physique";
    }
  },
  methods: {
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonnePhysiqueBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
      return internalApi.personnePhysique
        .postAllWithToastConfirmation(data_to_send)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          this.$router.push({name: "tiers"});;
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
