import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";
import Utils from "@/utils.js";

export default pbSalairePresencesApi;

function pbSalairePresencesApi() {
  return {
    getSalairePresencesInPeriode: getSalairePresencesInPeriode(),
    getTotauxSalairePresencesForExercice: getTotauxSalairePresencesForExercice(),
    saveSalairePresences: saveSalairePresences(),
    exportSalairePresenceExcel: exportSalairePresenceExcel(),
    importSalairePresenceExcel: importSalairePresenceExcel(),
  };
}

function getSalairePresencesInPeriode() {
  function salairePresencesInPeriode(pbExerciceId, page, rowsPerPage, sortBy, descending, queryParams, headers) {
    let path = internalApiRoutes.pbSalairePresences.salairePresencesForExercice(pbExerciceId);
    queryParams = queryParams || {};
    queryParams.page = page;
    queryParams.rows_per_page = rowsPerPage;
    queryParams.sort_key = sortBy;
    queryParams.descending = descending;
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return salairePresencesInPeriode;
}

function saveSalairePresences() {
  function saveSalairePresences(pbExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbSalairePresences.salairePresencesForExercice(pbExerciceId);
    let postForPath = apiConstructor.put(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return saveSalairePresences;
}

function getTotauxSalairePresencesForExercice() {
  function getTotauxSalairePresencesForExercice(pbExerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbSalairePresences.totauxSalairePresencesForExercice(pbExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getTotauxSalairePresencesForExercice;
}

function exportSalairePresenceExcel() {
  function exportSalairePresenceExcel(pbExerciceId, queryParams, headers) {
    let path = internalApiRoutes.pbSalairePresences.exportSalairePresencesForExercice(pbExerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return exportSalairePresenceExcel;
}

function importSalairePresenceExcel() {
  function importSalairePresenceExcel(pbExerciceId, body, queryParams, headers) {
    let path = internalApiRoutes.pbSalairePresences.importSalairePresencesForExercice(pbExerciceId);
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return importSalairePresenceExcel;
}
