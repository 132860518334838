// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonnePhysique from "./PersonnePhysique";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    this.isEspacePersonnel = false;
    const id = this.$route.params.id;
    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
    internalApi.personnePhysique.getById(id).then(result => {
      // Need to guess some data to use it correctly
      if(_.isNil(result.infos_bancaire)){
        result.infos_bancaire = {
          "nom_banque": null,
          "bic": null,
          "iban": null,
        };
      }
      if(_.isNil(result.salarie)){
        result.salarie = {
          sal_type_contrat: undefined,
          sal_temps_de_travail: undefined,
          sal_categorie_de_salarie: undefined,
          sal_role: undefined,
          is_interets_places: true,
        };
      }
      if(_.isNil(result.associe)){
        result.associe = {
          date_acces_societariat: undefined,
          date_evt_declencheur_societariat: undefined,
          lib_evt_declencheur_societariat: undefined,
          date_perte_qualite_associe: undefined,
          motif_perte_qualite_associe: undefined,
          renonciation: false,
          type_droit_de_vote: "unitaire",  // Le droit de vote est toujours unitaire pour les pp
          blocage_souscriptions: false,
          exclu_documents_legaux: false,
          scic_donnees_associe: null,
        };
      }
      if(_.isNil(result.associe.scic_donnees_associe)){
        result.associe.scic_donnees_associe = {
          code_scic_categorie: undefined,
          code_scic_sous_categorie: undefined,
          code_scic_college: undefined,
        };
      }
      this.personne_physique = result;
      this.modifier_donnees_salarie = !_.isNil(result.salarie.id);
      this.modifier_donnees_associe = !_.isNil(result.associe.id);

      // Savoir si le tiers est déjà salarié ou associé (empêcher l'utilisateur de décocher les données salarié/associé)
      this.is_already_salarie = this.modifier_donnees_salarie;
      this.is_already_associe = this.modifier_donnees_associe;

      this.computeInfosVilleNaissanceRequired();
    })
    .finally(() => {
      this.$refs.loadingComponent.resetLoading();
    });
  },
  extends: PersonnePhysique,
  computed: {
    pageTitle() {
      return "Modifier un tiers personne physique";
    }
  },
  methods: {
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonnePhysiqueBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
      return internalApi.personnePhysique
        .putByIdWithToastConfirmation(data_to_send.id, data_to_send)
        .then(result => {
          if (result) {
            this.$refs.loadingComponent.resetLoading();
            this.$router.push({name: "tiers"});
          }
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
