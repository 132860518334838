import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default asynchronousApi;

function asynchronousApi() {
  const specificApi =  {
    getBatchNotifications: getBatchesList(),
    getTasksMonitoring: getTasksMonitoringList(),
  };
    return Object.assign(specificApi, defaultApi(internalApiRoutes.periodeTravail.baseUrl));
}

function getBatchesList() {
  function batchesList(queryParams, headers) {
    let path = internalApiRoutes.asynchronous_task.getBatchesNotificationsList();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return batchesList;
}

function getTasksMonitoringList() {
  function tasksMonitoringList(queryParams, headers) {
    let path = internalApiRoutes.asynchronous_task.getTasksList();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }
  return tasksMonitoringList;
}