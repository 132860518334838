<!-- VUETIFY2 - OK -->
<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-icon-button-with-tool-tip v-if="isIcon"
                                         :detail="detail"
                                         detailButtonText="Import"
                                         :icon="'$vuetify.icons.upload'"
                                         :activator="on"
                                         btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         :disabled="disabled"/>
            <v-btn v-else v-on="{...on, ...activator}" color="primary" dark block :disabled="disabled">
                <v-icon dark left>{{"$vuetify.icons.upload"}}</v-icon>
                {{detail}}
            </v-btn>
        </template>
        <v-card>
            <ValidationObserver v-slot="{ validate, errors, reset }">
                <v-card-title class="headline grey lighten-2" primary-title>Importer un fichier</v-card-title>
                <v-card-text>
                    <v-container fill-height fluid>
                        <v-row align="center" justify="center" v-if="allowedExport">
                            <v-col>
                                <v-icon-button-with-tool-tip :detail="exportDetail"
                                                            detailButtonText="Télécharger le modèle de fichier"
                                                            :icon="'$vuetify.icons.download'"
                                                            btnRound btnText btnSmall iconSmall
                                                            tooltipBottom
                                                            btnColorClass="transparent"
                                                            iconColorClass="grey darken-1"
                                                            @click.stop="exportFile"
                                                            :disabled="disabled"
                                                            block
                                />
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-0">
                            <v-col>
                                <ValidationProvider vid="file"
                                                    name="selection de fichier"
                                                    :rules="{ required: dialog }"
                                                    v-slot="{ errors }">
                                    <v-file-input label="Choisissez votre fichier"
                                                :accept="typesFile"
                                                truncate-length="40"
                                                v-model="file"
                                                :prepend-icon="'$vuetify.icons.upload'"
                                                :error-messages="cleanErrors(errors)">
                                    </v-file-input>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row v-show="showForceOption" class="mt-0">
                            <v-col class="pt-0">
                                <ValidationProvider vid="forceOption"
                                                    name="Forcer l'import"
                                                    v-slot="{ errors }">
                                    <v-checkbox v-model="forceOption"
                                                label="Forcer l'import"
                                                :error-messages="errors"
                                                hide-details
                                                class="mr-2"
                                                style="display: inline-block;"
                                    />
                                </ValidationProvider>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small left v-on="on" class="mt-n2">{{ "$vuetify.icons.info" }}</v-icon>
                                    </template>
                                    <span>{{ fancyShowForceOptionTo }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- Petits boutons modaux, pas de taille relative -->
                            <v-col class="mx-4">
                                <v-save-buton-with-validation :validateForm="validate"
                                                              :resetForm="reset"
                                                              :errorsList="errors"
                                                              @emit-cancel="closePopUp"
                                                              @emit-valid-save="submit"
                                                              @emit-invalid-save="cleanErrorMessages=false"
                                                              saveMessage="OUI, CONFIRMER"
                                                              cancelMessage="NON, ABANDONNER"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import authService from "@/services/authService";
import Utils from "@/utils.js";
import VIconButtonWithToolTip from "./VIconButtonWithToolTip";
import VSaveButonWithValidation from "./VSaveButonWithValidation";

/**
 * Composant affichant une fenêtre modale demandant à l'utilisateur de renseigner
 * un fichier, peut aussi proposer un modèle d'export
 * @displayName Commun - VImportExportFile
 */
export default {
    name: "VImportExportFile",
    components: {
        VSaveButonWithValidation,
        VIconButtonWithToolTip
    },
    props: {
        detail: {
            type: String,
            required: true,
        },
        exportDetail: {
            type: String,
            required: false,
        },
        allowedExport: {
            type: Boolean,
            required: false,
            default: true,
        },
        typesFile: {
            type: String,
            required: false,
            default: "",
        },
        isIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
        activator: {
            type: Object,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
        * Liste des rôles ayant accès à l'option de forçage de l'import, aucun par défaut
        * Remplir la liste au besoin avec :
        * - rolesList.ADMINISTRATEUR
        * - rolesList.RESPONSABLE
        * - rolesList.GESTIONNAIRE
        * - rolesList.TIERS
        */
        showForceOptionTo: {
            type: Array,
            required: false,
            default(rawProps) {
                return [];
            },
        },
    },
    mounted() {
        const currentRoles = authService.getCurrentRoles();
        this.showForceOption = !_.isEmpty(_.intersection(this.showForceOptionTo, currentRoles));
        this.computeFancyShowForceOptionTo();
    },
    data() {
        return {
            dialog: false,
            formData: new FormData(),
            file: null,
            cleanErrorMessages: true,
            showForceOption: false,
            forceOption: false,
        }
    },
    methods: {
        computeFancyShowForceOptionTo() {
            if (this.showForceOptionTo.length > 1) {
                this.fancyShowForceOptionTo = "Accessible uniquement par les rôles ";
            } else {
                this.fancyShowForceOptionTo = "Accessible uniquement par le rôle ";
            }
            this.fancyShowForceOptionTo += Utils.formatListToFrench(this.showForceOptionTo) + ".";
        },
        /**
         * Ferme la fenêtre du fichier à importer
         */
        closePopUp() {
            this.dialog = false;
            this.formData = new FormData();
            this.file = null;
            this.cleanErrorMessages = true;
        },
        /**
         * Enregistre le fichier choisi par l'utilisateur
         * @param file
         */
        onFileChange(file) {
            if (file != null) {
                this.formData.set("file", file, file.name);
            }
        },
        /**
         * Permet de contrôler manuellement si le message d'erreur est renvoyé 
         * en fonction du booléen cleanErrorMessages
         * @param errors
         */
        cleanErrors(errors) {
            if (!this.cleanErrorMessages) {
                return errors;
            }
            return [];
        },
        /**
         * Emet un évènement pour exporter le fichier
         */
        exportFile() {
            this.$emit("export-file");
        },
        /**
         * Réalise l'import du fichier puis émet un évènement
         * indiquant que l'import s'est bien réalisé
         */
        submit() {
            this.formData.set("file", this.file, this.file.name);
            this.$emit("import-file", this.formData, {"force": this.forceOption});
            this.closePopUp();
        },
    },
};
</script>
