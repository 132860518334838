<!-- VUETIFY2 - OK -->
<template>
    <div>
        <v-dialog v-model="dialog" persistent :max-width="maxWidth">
            <template v-slot:activator="{ on }">
                <v-icon-button-with-tool-tip v-if="isIcon"
                                             @click.stop="onButtonClick"
                                             :detail="tooltipDetailMessage"
                                             :detailOnDisabledButton="tooltipDetailOnDisabledButtonMessage"
                                             :icon="iconValue"
                                             :activator="on"
                                             :disabled="disabled"
                                             btnIsIcon btnRound btnText btnSmall iconSmall tooltipBottom
                                             btnColorClass="transparent"
                                             iconColorClass="grey darken-1"
                                             block
                />
                <v-btn v-else v-on="{...on, ...activator}" @click="onButtonClick" color="primary" block :disabled="disabled">
                    {{openButtonMessage}}
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                <v-card-text>
                    <div v-if="$slots['custom-infos']" class="mt-4">
                        <slot name="custom-infos"/>
                        <v-divider class="mt-4"></v-divider>
                    </div>
                    <v-row no-gutters :justify="justifyMessage">
                        <div class="prevent-scale-up">
                            <ValidationObserver v-slot="{ handleSubmit }" ref="formCustomConfirmationDialog" v-if="dialog">
                                <form @submit.prevent="handleSubmit(onSubmit)">
                                    <v-row no-gutters wrap class="mt-4">
                                        <div v-html="message"></div>
                                    </v-row>
                                    <v-row no-gutters wrap>
                                        <ValidationProvider vid="confirmation" name="confirmation"
                                                            rules="required:true|is:Je valide" v-slot="{ errors }">
                                            <v-text-field v-model="messageConfirmation"
                                                          label="Confirmation"
                                                          data-vv-name="confirmation"
                                                          data-vv-as="Confirmation"
                                                          :error-messages="errors[0]"
                                            />
                                        </ValidationProvider>
                                    </v-row>
                                    <p><i>Pour confirmer l'opération, saisissez « Je valide »</i></p>
                                </form>
                            </ValidationObserver>
                        </div>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters justify="end">
                        <!-- Bouton de taille fixe -->
                        <div class="mr-2">
                            <v-btn color="primary" @click="onCancelClick" block>{{labelAnnulation}}</v-btn>
                        </div>
                        <!-- Bouton de taille fixe -->
                        <div>
                            <v-btn color="primary" @click="onValidateClick" block>{{labelConfirmation}}</v-btn>
                        </div>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import {TOAST} from "@/plugins/toast/definition";
    import _ from "lodash";

    export default {
        name: "v-custom-confirmation-dialog",
        components: {
            VIconButtonWithToolTip
        },
        $_veeValidate: {
            validator: 'new'
        },
        props: {
            /**
             * Additional validation for another form
             */
            externalValidateForm: {
                type: Function,
                required: false
            },
            /**
             * Errors from the other form
             */
            externalErrorsList: {
                type: Object,
                required: false
            },
            /**
             * Show errors of the other form
             */
            showToast: {
                type: Boolean,
                default: true
            },
            activator: {
                type: Object,
                required: false,
                default: undefined
            },
            message: {
                type: String,
                required: true
            },
            /**
             * How to justify the message to display
             */
            justifyMessage: {
                type: String,
                required: false,
                default: "start"
            },
            openButtonMessage: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
                default: "Confirmation"
            },
            labelAnnulation: {
                type: String,
                required: false,
                default: "Non, abandonner"
            },
            labelConfirmation: {
                type: String,
                required: false,
                default: "Oui, confirmer"
            },
            iconValue: {
                required: false
            },
            isIcon: {
                type: Boolean,
                required: false,
                default: false
            },
            tooltipDetailMessage: {
                type: String,
                required: false,
                default: ""
            },
            tooltipDetailOnDisabledButtonMessage: {
                type: String,
                required: false,
                default: null
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            maxWidth: {
                type: String,
                required: false,
                default: "600px"
            },

        },
        data() {
            return {
                dialog: false,
                messageConfirmation: undefined,
            };
        },
        methods: {
            /**
             * Si le formulaire modal est submit (en appuyant sur enter par exemple)
             */
            onSubmit: function () {
                this.onValidateClick();
            },
            onButtonClick: function () {
              this.$emit("open-popup-click");
            },
            /**
             * Enchaine la validation des formulaires
             */
            onValidateClick: function () {
                this.validateExternalForm().then(
                    success => {
                        if (success) {
                            this.validateInternalForm();
                        }
                    }
                ).catch(
                    error => {
                        console.error("Erreur en validant le formulaire: ", error);
                    }
                )
            },
            /**
             * Validation du formulaire externe
             */
            validateExternalForm: function () {
                return new Promise((resolve, reject) => {
                    if (!_.isNil(this.externalValidateForm)) {
                        this.externalValidateForm().then(
                            success => {
                                if (success) {
                                    resolve(true);
                                } else if (this.showToast) {
                                    this.showErrorToast();
                                    resolve(false);
                                }
                            }
                        ).catch(err => {
                            reject(err);
                        });
                    } else {
                        resolve(true);
                    }
                })
            },
            /**
             * Validation du formulaire interne ("Je valide")
             */
            validateInternalForm: function () {
                this.$refs.formCustomConfirmationDialog.validate().then(validForm => {
                    if (validForm) {
                        this.$emit("action-confirmee");
                        this.onCancelClick();
                    }
                });
            },
            onCancelClick: function () {
                this.$refs.formCustomConfirmationDialog.reset();
                this.dialog = false;
                this.messageConfirmation = undefined;
                this.$emit("cancel-popup-click");
            },
            /**
             * Display errors from the external form
             */
            showErrorToast() {
                let raw_errors = this.externalErrorsList;
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            }
        }
    };
</script>
<style scoped>
    /*
     * Donner une taille fixe aux boutons de la barre d'action
     * pour éviter qu'elle ne varie en fonction de la largeur de la fenêtre modale
    */
    .v-card__actions button {
        width: 190px;
    }
    .prevent-scale-up {
        max-width: 552px;
    }
</style>
