// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import Annulation from "./Annulation";

export default {
  mounted() {
    this.isEditable = true;
    this.canEditDate = true;
    this.getAnnulation();
  },
  extends: Annulation,
  computed: {
    annulationId() {
      return this.$route.params.id;
    },
  },
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      let cloned_annulation = _.cloneDeep(this.annulation);
      delete cloned_annulation.id;
      internalApi.annulation
        .putByIdWithToastConfirmation(
          this.annulation.id,
          cloned_annulation
        )
        .then(result => {
          this.pushToMouvements();
        });
    }
  }
};
