import Vue from "vue";
import {ValidationProvider, extend, localize, ValidationObserver} from 'vee-validate';
import {validateCurrency} from "./validator_rules";
import messagesFr from "vee-validate/dist/locale/fr";
import * as rules from 'vee-validate/dist/rules';


// const currencyPattern = new RegExp("[\d]+[[.,]{1}[\d]{1,2}]{0,1}")
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend("nom_version_statuts", {
  validate(value, args) {
    return value !== args;
  },
  params: ['length']
});

localize({fr : {
    messages: {
      is: "Le champ {_field_} ne correspond pas à la valeur attendue.",
      nom_version_statuts: "Le champ Nom de la version ne peut pas être similaire au nom d’une autre version.",
      currency: "Le champ {_field_} doit être un nombre.",
      date_after: "La date doit être postérieure à {target}.",
      date_before: "La date doit être antérieure à {target}."
    }
  }});
localize("fr", messagesFr);
extend("currency", validateCurrency);
extend("date_before", {
  validate(value, {target}) {
    if (_.isNil(target)){
      return true;
    }
    else {
      return Date.parse(value) <= Date.parse(target);
    }
  },
  params: ['target']
});
extend("date_after", {
  validate(value, {target}) {
    if (_.isNil(target)){
      return true;
    }
    else {
      return Date.parse(value) >= Date.parse(target);
    }
  },
  params: ['target']
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

