<!-- VUETIFY2 - OK -->
<template>
    <v-row no-gutters justify="end">
        <!-- Gros bouton modal -->
        <v-col class="mr-4" md="5" lg="5" xl="5">
            <v-btn block @click="cancel">{{cancelMessage}}</v-btn>
        </v-col>
        <!-- Gros bouton modal -->
        <v-col md="5" lg="5" xl="5">
            <v-btn block color="primary" @click="validate" :disabled="disabledValue">{{saveMessage}}</v-btn>
        </v-col>
    </v-row>
</template>


<script>

    import {TOAST} from "@/plugins/toast/definition";
    import Utils from "@/utils";
    import _ from "lodash";

    export default {
        name: "v-save-buton-with-validation",
        props: {
            validateForm: {
                type: Function,
                required: false
            },
            resetForm: {
                type: Function,
                required: false
            },
            errorsList: {
                type: Object,
                required: false
            },
            showToast: {
                type: Boolean,
                default: true
            },
            saveMessage: {
                type: String,
                required: false,
                default: "Enregistrer"
            },
            cancelMessage: {
                type: String,
                required: false,
                default: "Annuler"
            },
            disabledValue: {
                type: Boolean,
                required: false
            }
        },
        methods: {
            cancel() {
                this.resetForm();
                this.$emit("emit-cancel")
            },
            validate() {
                this.validateForm().then(
                    success => {
                        if (success) {
                            this.$emit("emit-valid-save");
                        }
                        else {
                            this.$emit("emit-invalid-save");
                            if (this.showToast) {
                                this.showErrorToast();
                            }
                        }
                    }
                ).catch(err => {
                    console.error(err);
                });
            },
            formatError(errors) {
                return Utils.formatError(errors);
            },
            showErrorToast() {
                let raw_errors = this.errorsList;
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                const formatedErrors = this.formatError(errors);
                formatedErrors.forEach(error => TOAST.error("Champ requis manquant", error));
            }
        }
    };
</script>