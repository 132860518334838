<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formReglementDette" v-slot="{ validate, errors, reset }">
        <base-layout headerSize="50px">
            <template slot="header">
                <h1> Règlement </h1>
            </template>
            <template slot="main">
                <loading-screen ref="loadingComponent"/>
                <br/>
                <!-- Bénéficiaire -->
                <v-subheader><h2> Bénéficiaire </h2></v-subheader>
                <v-container>
                    <v-row justify="space-between">
                        <v-col md="6" lg="6" xl="6">
                            <ValidationProvider vid="associe_name" name="Bénéficiaire" rules="required" v-slot="{ errors }">
                                <v-text-field label="Bénéficiaire" v-model="associe_name" 
                                            :error-messages="errors" :disabled="true"/>
                            </ValidationProvider>
                        </v-col>
                        <v-col md="5" lg="5" xl="5">
                            <v-checkbox v-model="nouveau_reglement.beneficiaire_different"
                                        label="Bénéficiaire autre que l'ayant droit"
                                        @click.native="removeValidatonErrorsFromDenominationTiers"
                                        @change="clearFieldIfNoBeneficiaireDifferent()"/>
                        </v-col>
                    </v-row>
                    <v-row justify="end">
                        <v-col md="5" lg="5" xl="5">
                            <ValidationProvider ref="denomination_tiers_provider" vid="denomination_tiers" name="Dénomination tiers" :rules="nouveau_reglement.beneficiaire_different ? 'required' : ''" v-slot="{ errors }">
                                <v-text-field v-model="nouveau_reglement.denomination_tierce" label="Dénomination tiers"
                                            :disabled="!nouveau_reglement.beneficiaire_different"
                                            :error-messages="errors"/>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
                <br/>

                <!-- Règlement de la dette -->
                <v-subheader><h2> Règlement de la dette </h2></v-subheader>
                <v-container>
                    <v-row>
                        <v-col md="6" lg="6" xl="6">
                            <ValidationProvider vid="date_reglement" name="Date du paiement" rules="required" v-slot="{ errors }">
                                <DateField v-model="nouveau_reglement.date_reglement" label="Date du paiement"
                                        name="date_reglement" :errors="errors"/>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col md="6" lg="6" xl="6">
                            <ValidationProvider vid="montant_reglement" name="Montant du paiement"
                                                :rules="'required|currency|min_value:0|max_value:' + restant_du.toString()" v-slot="{ errors }">
                                <v-formatted-number-text-field v-model="nouveau_reglement.montant_reglement"
                                                            labelValue="Montant du paiement"
                                                            :errors="errors"
                                                            prefixValue="€"
                                                            :reverseValue="true"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col md="5" lg="5" xl="5">
                            <v-formatted-number-text-field labelValue="Restant dû"
                                                        prefixValue="€"
                                                        :reverseValue="true"
                                                        v-model=restant_du
                                                        :disabledValue="true"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- Petit bouton -->
                        <v-col md="3" lg="3" xl="2">
                            <v-btn text color="primary" @click="solderDette()" block> Solder la dette</v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col md="6" lg="6" xl="6">
                            <ValidationProvider vid="type_reglement" name="Type de paiement" rules="required" v-slot="{ errors }">
                                <v-select v-model="nouveau_reglement.type_reglement"
                                        :items="choixTypeReglement" label="Type de paiement"
                                        item-text="text"
                                        item-value="value"
                                        :error-messages="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col md="5" lg="5" xl="5">
                            <v-text-field v-model="nouveau_reglement.commentaire" label="Commentaire"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="6" lg="6" xl="6">
                            <ValidationProvider vid="reference_reglement" name="Référence du paiement" rules="required" v-slot="{ errors }">
                                <v-text-field v-model="nouveau_reglement.reference_reglement"
                                            label="Référence du paiement"
                                            :error-messages="errors"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template slot="footer">
                <v-row justify="end" dense>
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      :showToast="false"
                                                      @emit-cancel="retourDetailDette"
                                                      @emit-valid-save="submit"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import DateField from "@/components/DateField.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";

    export default {
        components: {
            DateField: DateField,
            VFormattedNumberTextField: VFormattedNumberTextField,
            loadingScreen,
            BaseLayout,
            VSaveButonWithValidation
        },
        data() {
            return {
                retourDetailDetteRoute: "detail-dette",
                associe_name: undefined,
                restant_du: -1,
                nouveau_reglement: {
                    dette_id: undefined,
                    beneficiaire_different: false,
                    denomination_tierce: undefined,
                    montant_reglement: undefined,
                    date_reglement: undefined,
                    reference_reglement: undefined,
                    type_reglement: undefined,
                    commentaire: undefined
                },
                isLoading: false,
                loadingMessage: undefined,
                typeDette: undefined,
            }
        },

        mounted() {
            if(this.univers === "capital") {
                this.retourDetailDetteRoute = "cap-detail-dette";
            }
            else if(this.univers === "participation") {
                this.retourDetailDetteRoute = "pb-detail-dette";
            }
            else if(this.univers === "interessement") {
                this.retourDetailDetteRoute = "inte-detail-dette";
            }
            this.fetchDette();
        },

        computed: {
            detteId() {
                return this.$route.params.id;
            },
            choixTypeReglement() {
                let choix = [];
                if (this.canSelectSepa()) {
                    choix.push({text: 'Paiement SEPA', value: 'paiement_sepa'});
                }
                choix.push(
                    {text: 'Virement', value: 'virement'},
                    {text: 'Chèque', value: 'cheque'},
                    {text: 'Autre (sans référence)', value: 'autre'},
                );

                return choix;
            },
        },
        props: {
            univers: {
                type: String,
                required: true
            }
        },
        methods: {
            solderDette() {
                this.nouveau_reglement.montant_reglement = this.restant_du;
            },
            fetchDette() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.dettes.getById(this.detteId).then(result => {
                    this.associe_name = result.associe_name;
                    this.restant_du = result.restant_du;
                    this.typeDette = result.type;
                    this.nouveau_reglement.dette_id = parseInt(this.detteId);
                }).catch(err => {
                    console.log(err);
                }).finally( () => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            removeValidatonErrorsFromDenominationTiers() {
                // Lorsque l'on coche ou décoche la case bénéfiaire autre que l'ayant droit, les erreurs liés au champ
                // Dénomination tiers disparaissent
                this.$refs.denomination_tiers_provider.reset();
            },
            clearFieldIfNoBeneficiaireDifferent() {
                const reglement = this.nouveau_reglement;
                if (!reglement.beneficiaire_different) {
                    reglement.denomination_tierce = null;
                }
            },
            retourDetailDette() {
                this.$router.push({
                    name: this.retourDetailDetteRoute,
                    params: {
                        id: this.detteId
                    }
                });
            },
            submit() {
                if (this.nouveau_reglement.beneficiaire_different === false) {
                    this.nouveau_reglement.denomination_tierce = undefined;
                }
                internalApi.reglements.postAllWithToastConfirmation({"reglements": [this.nouveau_reglement]}).then(() => {
                    this.retourDetailDette();
                });
            },
            canSelectSepa(){
                return !(["Transfert externe de la participation", "Transfert externe de l'intéressement"].includes(this.typeDette));
            },
        }
    };
</script>