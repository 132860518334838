// <!-- VUETIFY2 - OK -->
import DeblocageTermeConsultation from "./DeblocageTermeConsultation";
import internalApi from "@/api/internalApi";

export default {
  extends: DeblocageTermeConsultation,
  data() {
    return {
      isEspaceBeneficiaire: true,
      beneficiaire_name: undefined
    };
  },
  mounted() {
    this.getBeneficiaire();
  },
  methods: {
    /**
     * Récupère les informations du bénéficiaire connecté
     */
    getBeneficiaire() {
      internalApi.beneficiaire.getBeneficiaireIndividuel()
        .then(result => {
            this.beneficiaire_name = result.name;
        });
    }
  }
};