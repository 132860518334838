<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" dark block>
                Créer un taux de fiscalité
            </v-btn>
        </template>
        <v-card>
            <ValidationObserver v-slot="{ handleSubmit }" v-if="dialog === true">
                <v-card-title class="headline grey lighten-2" primary-title>Créer un taux de fiscalité</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col md="12" lg="12" xl="12">
                                <ValidationProvider name="Taux de réduction d'impôts" vid="taux_reduction_impots" v-slot="{ errors }"
                                                    rules="required|between:0,100">
                                    <v-formatted-number-text-field v-model="taux.taux_reduction_impots"
                                                                   labelValue="Taux de réduction d'impôts"
                                                                   prefixValue="%"
                                                                   :reverseValue="true"
                                                                   :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="12" lg="12" xl="12">
                                <ValidationProvider name="Date d'entrée en vigueur" vid="date_debut"
                                                    v-slot="{ errors }"
                                                    rules="required">
                                    <date-field v-model="taux.date_debut"
                                                label="Date d'entrée en vigueur"
                                                :errors="errors"
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="end" class="mx-0">
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn color="primary" @click="handleSubmit(submit)" block>OUI, CONFIRMER</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import DateField from "@/components/DateField";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";

    export default {
        name: "VScicNewTauxFiscalite",
        components: {
            VFormattedNumberTextField,
            DateField
        },
        data() {
            return {
                dialog: false,
                taux: {
                    taux_reduction_impots: undefined,
                    date_debut: undefined,
                }
            }
        },
        methods: {
            closePopUp() {
                this.dialog = false;
                this.taux.date_debut = undefined;
                this.taux.taux_reduction_impots = undefined;
            },
            submit() {
                internalApi.ScicTauxFiscalite.postAllWithToastConfirmation(this.taux)
                    .then(result => {
                        this.$emit("on-taux-reduction-impot-created");
                        this.closePopUp();
                    });
            }
        },
    }
</script>