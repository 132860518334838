<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="110px" :withFooter="false">
        <template slot="header">
            <h1>Statuts</h1>
            <v-tabs v-model="currentTab">
                <v-tab key="general">Général</v-tab>
                <v-tab key="scic" :disabled="! hasScicExtensionAccess()">SCIC</v-tab>
            </v-tabs>
        </template>
        <template slot="main">
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="general">
                    <base-layout :withHeader="false">
                        <template slot="main">
                            <ValidationObserver ref="formStatuts">
                                <v-col md="12" lg="12" xl="9">
                                    <v-container>
                                        <versions-list :selectedVersion="statut"
                                                       :versionsList="enumSelectVersion"
                                                       @update-selected-version="updateSelectedVersion($event.id)"
                                                       labelUnivers="Version des statuts"
                                                       />

                                        <!-- Capital -->
                                        <h3>Capital société</h3>
                                        <v-container>
                                            <v-row justify="space-between">
                                                <v-col md="3" lg="3" xl="3">
                                                    <ValidationProvider vid="capital_montant_initial" name="Capital social initial"
                                                                        rules="required|currency|min_value:0"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field v-model="statut.capital_montant_initial"
                                                                                       prefixValue="€"
                                                                                       labelValue="Capital social initial"
                                                                                       :reverseValue="true"
                                                                                       :errors="errors"
                                                                                       :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col md="3" lg="3" xl="3">
                                                    <ValidationProvider vid="capital_montant_minimum" name="Capital social minimum"
                                                                        rules="required|currency|min_value:0"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field v-model="statut.capital_montant_social_min"
                                                                                       prefixValue="€"
                                                                                       labelValue="Capital social minimum"
                                                                                       :reverseValue="true"
                                                                                       :errors="errors"
                                                                                       :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="3" lg="3" xl="3">
                                                    <ValidationProvider vid="capital_montant_souscription_part"
                                                                        name="Valeur d'acquisition de la part"
                                                                        rules="required|currency|min_value:0|excluded:0"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field
                                                            v-model="statut.capital_montant_souscription_part"
                                                            prefixValue="€"
                                                            labelValue="Valeur d'acquisition de la part"
                                                            :reverseValue="true"
                                                            :errors="errors"
                                                            :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-divider/>
                                        <h3>Capital associé(e)</h3>
                                        <v-container>
                                            <v-row justify="space-between">
                                                <v-col md="3" lg="3" xl="3">
                                                    <ValidationProvider vid="capital_montant_plancher_global"
                                                                        name="Plancher global"
                                                                        rules="currency|min_value:0"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field v-model="statut.capital_montant_plancher_global"
                                                                                       prefixValue="€"
                                                                                       labelValue="Plancher global"
                                                                                       :reverseValue="true"
                                                                                       :errors="errors"
                                                                                       :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col md="3" lg="3" xl="3">
                                                    <ValidationProvider vid="capital_montant_plafond_global"
                                                                        name="Plafond global"
                                                                        rules="currency|min_value:0"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field v-model="statut.capital_montant_plafond_global"
                                                                                       prefixValue="€"
                                                                                       labelValue="Plafond global"
                                                                                       :reverseValue="true"
                                                                                       :errors="errors"
                                                                                       :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>

                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-divider/>
                                        <h3>Droit de vote</h3>
                                        <v-container>
                                            <v-row>
                                                <v-col md="12" lg="12" xl="12">
                                                    <ValidationProvider vid="droits_de_vote_associes_non_coop_pourcentage_plafond"
                                                                        name="Plafond des associé(e)s externes (hors sociétés coopératives)"
                                                                        rules="required|between:0,100"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field
                                                            v-model="statut.droits_de_vote_associes_non_coop_pourcentage_plafond"
                                                            prefixValue="%"
                                                            labelValue="Plafond des associé(e)s externes (hors sociétés coopératives)"
                                                            :reverseValue="true"
                                                            :errors="errors"
                                                            :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="12" lg="12" xl="12">
                                                    <ValidationProvider vid="droits_de_vote_associes_coop_pourcentage_plafond"
                                                                        name="Plafond des associé(e)s externes sociétés coopératives"
                                                                        rules="required|between:0,100"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field
                                                            v-model="statut.droits_de_vote_associes_coop_pourcentage_plafond"
                                                            prefixValue="%"
                                                            labelValue="Plafond des associé(e)s externes sociétés coopératives"
                                                            :reverseValue="true"
                                                            :errors="errors"
                                                            :disabledValue="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-divider/>
                                        <h3>Intérêts aux parts sociales</h3>
                                        <v-container>
                                            <v-row>
                                                <v-col md="12" lg="12" xl="12">
                                                    <ValidationProvider vid="interet_regle_elligibilite"
                                                                        name="Règle d'éligibilité"
                                                                        rules="required"
                                                                        v-slot="{ errors }">
                                                        <v-select v-model="statut.interet_regle_elligibilite"
                                                                  :items="choicesRegleDividendeEligibilite"
                                                                  label="Règle d'éligibilité"
                                                                  :error-messages="errors"
                                                                  :disabled="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col md="12" lg="12" xl="12">
                                                    <v-checkbox v-model="statut.renum_parts_sociales_detenues_assemblee_generale"
                                                                label="Rémunération des parts sociales détenues à l'assemblée générale"
                                                                :disabled="statut.interet_regle_elligibilite != 'parts_possedees_fin_exe'"
                                                                style="display:inline-block;"/>
                                                    <v-tooltip top v-if="statut.interet_regle_elligibilite != 'parts_possedees_fin_exe'">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon small left class="ml-2 pb-3" v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                                        </template>
                                                        <span>Cette option n'est disponible que pour la règle d'éligibilité "Parts possédées à la date de fin d'exercice"</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-divider/>
                                        <h3>Souscriptions</h3>
                                        <v-container>
                                            <v-row>
                                                <v-col md="6" lg="6" xl="6">
                                                    <ValidationProvider vid="souscription_engagement_type"
                                                                        name="Type d'engagement de souscription"
                                                                        rules="required"
                                                                        v-slot="{ errors }">
                                                        <v-select v-model="statut.souscription_engagement_type"
                                                                  :items="choicesTypeEngagement"
                                                                  label="Type d'engagement de souscription"
                                                                  :error-messages="errors"
                                                                  :disabled="isOldStatut"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col md="6" lg="6" xl="6">
                                                    <ValidationProvider vid="souscription_valeur_engagement"
                                                                        name="Valeur"
                                                                        rules="required|between:0,100"
                                                                        v-slot="{ errors }">
                                                        <v-formatted-number-text-field v-model="statut.souscription_valeur_engagement"
                                                                                       prefixValue="%"
                                                                                       labelValue="Valeur"
                                                                                       :reverseValue="true"
                                                                                       :errors="errors"
                                                                                       :disabledValue="isOldStatut"
                                                        >
                                                        </v-formatted-number-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-checkbox v-model="statut.blocage_parts_sociales_issues_pb"
                                                                label="Blocage des parts sociales par affectation de PB"
                                                                false-value="false"/>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-container>
                                </v-col>
                                <v-container>
                                    <v-row align="center" class="white">
                                        <v-col md="12" lg="12" xl="9">

                                            <!-- Liste des catégories de capital -->
                                            <v-list subheader>
                                                <h3>Liste des catégories de capital</h3>
                                                <v-list-item v-for="(categorieCapital, index) in categorieCapitalList"
                                                             :key="categorieCapital.code" @mouseover="categorieCapital.showIcon=true"
                                                             @mouseout="categorieCapital.showIcon=false">
                                                    <v-list-item-avatar width="100">
                                                        <v-chip>Capital {{ categorieCapital.code }}</v-chip>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title class="pl-5" v-html="categorieCapital.libelle"/>
                                                    <v-list-item-action>
                                                        <v-icon-button-with-tool-tip v-if="isOldStatut"
                                                                                     :routeCible="{name:'consultation-categorie-capital', params: {categorieCapitalId: categorieCapital.id}}"
                                                                                     :detail="'Visualiser la catégorie de capital ' + categorieCapital.code"
                                                                                     :icon="'$vuetify.icons.view'"
                                                                                     btnIsIcon btnText iconSmall tooltipBottom
                                                                                     btnColorClass="transparent"
                                                                                     iconColorClass="grey darken-1"/>
                                                        <v-icon-button-with-tool-tip v-else
                                                                                     :routeCible="{name:'categorie-capital', params: {categorieCapitalId: categorieCapital.id}}"
                                                                                     :detail="'Modifer la catégorie de capital ' + categorieCapital.code"
                                                                                     :icon="'$vuetify.icons.edit'"
                                                                                     btnIsIcon btnText iconSmall tooltipBottom
                                                                                     btnColorClass="transparent"
                                                                                     iconColorClass="grey darken-1"/>
                                                    </v-list-item-action>
                                                    <v-divider v-if="index + 1 < categorieCapitalList.length"/>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </ValidationObserver>
                        </template>
                        <template slot="footer">
                            <v-row justify="space-between" dense>
                                <!-- Grand bouton-->
                                <v-col md="4" lg="4" xl="3">
                                    <new-statut
                                        v-if="isResponsable()"
                                        :dialog="dialog"
                                        :versionsList="enumSelectVersion"
                                        :nouvelleVersion="statut"
                                        :categoriesCapital="categorieCapitalList"
                                        :disabledValue="isOldStatut"
                                        @on-open-dialog="onOpenDialog($event)"
                                        @on-version-created="onVersionCreated()"
                                        slot="activator">
                                    </new-statut>
                                </v-col>
                                <!-- Grand bouton-->
                                <v-col md="4" lg="4" xl="3">
                                    <v-btn :to="{name:'categorie-capital-creation'}" class="primary" block :disabled="isOldStatut">
                                        <v-icon left dark>{{ "$vuetify.icons.add" }}</v-icon>
                                        Créer une catégorie de capital
                                    </v-btn>
                                </v-col>
                                <!-- Grand bouton-->
                                <v-col md="4" lg="4" xl="3">
                                    <v-btn color="primary" @click="saveStatut" block :disabled="isOldStatut">
                                        Enregistrer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </base-layout>
                </v-tab-item>
                <v-tab-item key="scic">
                    <v-scic-colleges-sous-categories/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import VScicCollegesSousCategories from "@/views/scic/VScicCollegesSousCategories.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import BaseLayout from "@/components/BaseLayout";
    import NewStatut from "@/components/versions/NewStatut"
    import internalApi from "@/api/internalApi";
    import VersionsList from "@/components/VersionsList";
    import authService from "../../services/authService";
    import rolesList from "../../router/rolesList";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";

    /**
     * Vue affichant les données liées au capital de la société comme le capital social initial, la
     * valeur d'une part sociale et la création/modification des catégories de capitaux. Ces données
     * peuvent être versionnées.
     * @displayName Capital - Statuts
     */
    export default {
        components: {
            VScicCollegesSousCategories,
            VersionsList,
            VFormattedNumberTextField,
            VIconButtonWithToolTip,
            BaseLayout,
            NewStatut,
            loadingScreen,
        },
        data() {
            return {
                dialog: undefined,
                statut: {},
                categorieCapitalList: [],
                isOldStatut: false,
                choicesTypeEngagement: [],
                choicesRegleDividendeEligibilite: [],
                enumSelectVersion: [],
                enumSelectedVersion: {},
                currentTab: undefined,
            };
        },
        watch: {
            statut: function (statut) {
                this.computeIsOldStatut(statut);
            },
            'statut.interet_regle_elligibilite': function(val) {
                if (val != "parts_possedees_fin_exe") {
                    this.statut.renum_parts_sociales_detenues_assemblee_generale = false;
                }
            },
        },
        mounted() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);

            let promise_company_enum = this.getStatutsCapCompanyEnumByName();
            let promise_company_labels = this.getCapCompanyLabels();

            Promise.all([
                promise_company_enum,
                promise_company_labels,
            ]).catch(error => {
                // S'il y a eu une erreur, retirer le loading, sinon attendre que updateSelectedVersion() le fasse
                this.$refs.loadingComponent.resetLoading();
            });
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
            /**
             * Calcule si le statut actuellement affiché est révolu ou non.
             * @param statut: Le statut en question
             */
            computeIsOldStatut(statut) {
                this.isOldStatut = ! _.isNil(statut) && "date_fin" in statut && ! _.isNil(statut.date_fin);
            },
            /**
             * Obtenir les versions de statuts simplifiées (id et nom de version uniquement) pour toutes les versions.
             * Si le cache a en mémoire l'id d'une version, séléctionner la version correspondante,
             * sinon, s'il y a au moins une version existante, séléctionner la dernière version chronologiquement.
             * Si une version a été séléctionnée, obtenir ses données via this.updateSelectedVersion.
             */
            getStatutsCapCompanyEnumByName() {
                return internalApi.capCompany.getStatutsCapCompanyEnumByName()
                    .then(results => {
                        this.enumSelectVersion = results;

                        let cachedSelectedCapCompanyStatus = this.$store.getters.getLastSelectedCapCompanyStatus();
                        if (!_.isNil(cachedSelectedCapCompanyStatus)) {
                            this.statut = _.find(this.enumSelectVersion, ['id', cachedSelectedCapCompanyStatus]);
                        } else if (this.enumSelectVersion.length > 0) {
                            this.statut = this.enumSelectVersion[0];
                        } else {
                            this.statut = undefined;
                        }

                        if (! _.isNil(this.statut)) {
                            return this.updateSelectedVersion(this.statut.id);
                        }
                    });
            },
            /**
             * Demande l'optention de toutes les données d'une version d'id donné.
             * Met à jour le cache avec le nouvel id de la version séléctionnée.
             * @param new_cap_company_id: Id de la version à obtenir
             */
            updateSelectedVersion(new_cap_company_id) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                this.getStatutById(new_cap_company_id).then(res => {
                    this.$store.commit("SET_LAST_SELECTED_CAP_COMPANY_STATUS", new_cap_company_id);
                })
                .finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });

            },
            /**
             * Obtient toutes les données d'une version d'id donné.
             * @param cap_company_id: Id de la version à obtenir
             */
            getStatutById(cap_company_id) {
                return internalApi.capCompany.getStatutCapCompanyById(cap_company_id)
                    .then(result => {
                        this.statut = result
                        return this.getCategoriesByDate(this.statut.date_debut);
                    });
            },
            /**
             * Récupère toutes les catégories de capital correspondantes à la date donnée
             * @param dateString: Date à laquelle rechercher les catégories de capital
             */
            getCategoriesByDate(dateString) {
                let res_promise;
                if (! _.isNil(dateString)) {
                    res_promise = internalApi.categorieCapital.getCategoriesByDate(dateString).then(result => {
                        this.categorieCapitalList = result
                    });
                } else {
                    res_promise = new Promise((resolve, reject) => {
                        resolve();
                    });
                }
                return res_promise;
            },
            /**
             * Obtenir les labels des statuts
             */
            getCapCompanyLabels() {
                internalApi.capCompany.getLabels()
                    .then(results => {
                        this.choicesTypeEngagement = this.getSelectOptionsFromObject(results.type_engagement);
                        this.choicesRegleDividendeEligibilite = this.getSelectOptionsFromObject(results.regle_dividende_elligibilite);
                    }
                );
            },
            /**
             * Obtenir les options de champs select sous forme d'énums
             * [{
             *      value: "valeur_technique",
             *      text: "Valeur d'affichage"
             *  }, ...]
             * À partir de
             * {"valeur_technique": "Valeur d'affichage", ...}
             *
             * @param object: L'objet à traiter
             */
            getSelectOptionsFromObject(object) {
                let options = [];
                for (let key in object) {
                    if (object.hasOwnProperty(key)) {
                        options.push({
                            value: key,
                            text: object[key]
                        });
                    }
                }
                return options;
            },
            /**
             * Valide les champs et enregistre le statut si les champs sont valides
             */
            saveStatut() {
                this.$refs.formStatuts.validate().then(success => {
                    if (success) {
                        return internalApi.capCompany
                            .putByIdWithToastConfirmation(this.statut.id, this.statut)
                            .then(result => {
                                this.updateStatut();
                            });
                    }
                });
            },
            /**
             * Invalide le cache concernant la version des statuts séléctionnée,
             * recharge la liste des versions de statuts
             */
            onVersionCreated() {
                this.$store.commit("SET_LAST_SELECTED_CAP_COMPANY_STATUS", null);
                this.getStatutsCapCompanyEnumByName();
                this.updateStatut();
            },
            /**
             * Rafraîchit les données lors de la création d'un nouveau statut
             */
            updateStatut() {
                this.dialog = false;
            },
            /**
             * Vérifie les champs et s'ils sont valides, affiche la boite de dialogue pour
             * la création d'un nouveau statut
             * @param newVal: Boolean qui détermine si la fenêtre est ouverte ou fermée
             */
            onOpenDialog(newVal) {
                this.$refs.formStatuts.validate().then(success => {
                    if (success) {
                        this.dialog = newVal;
                    }
                });
            },
            /**
             * Détermine si l'utilisateur est responsable ou non
             * @returns {boolean}
             */
            isResponsable: function () {
                return authService.getCurrentRoles().includes(rolesList.RESPONSABLE);
            },
        }
    }
</script>