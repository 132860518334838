// <!-- VUETIFY2 - OK -->
import VScicSousCategorie from "./VScicSousCategorie"
import Utils from "@/utils.js";


export default {
  extends: VScicSousCategorie,
  props: {
    oldScicSousCategorie: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.onOldScicSousCategorieChanges(this.oldScicSousCategorie);
  },
  watch: {
    oldScicSousCategorie(new_val) {
      this.onOldScicSousCategorieChanges(new_val);
    }
  },
  computed: {
    isModif() {
      return true;
    },
    title() {
      return "Modification d'un collège de vote";
    },
    tooltipDetailMessage() {
      return "Modifier ce collège de vote";
    },
    iconValue() {
      return "$vuetify.icons.edit";
    },
  },
  methods: {
    onOldScicSousCategorieChanges(new_value) {
      if(!_.isNil(new_value.id)) {
        this.sousCategorie.id = new_value.id;
      }
      this.sousCategorie.code = new_value.code;
      this.sousCategorie.name = new_value.name;
      this.sousCategorie.param_categorie = new_value.param_categorie;
      this.sousCategorie.description = new_value.description;
    },
    submit() {
        const new_scic_sous_categorie = _.cloneDeep(this.sousCategorie);
        this.$emit("edit-scic-sous-categorie", this.oldScicSousCategorie, new_scic_sous_categorie);
        this.closePopUp();
    },
    closePopUp() {
      this.dialog = false;
      this.$refs.formScicSousCategorie.reset();
    },
  }
};