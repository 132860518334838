// <!-- VUETIFY2 - OK -->
import VScicCollege from "./VScicCollege"
import Utils from "@/utils.js";


export default {
  extends: VScicCollege,
  props: {
    oldScicCollege: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.onOldScicCollegeChanges(this.oldScicCollege);
  },
  watch: {
    oldScicCollege(new_val) {
      this.onOldScicCollegeChanges(new_val);
    }
  },
  computed: {
    isModif() {
      return true;
    },
    title() {
      return "Modification d'un collège de vote";
    },
    tooltipDetailMessage() {
      return "Modifier ce collège de vote";
    },
    iconValue() {
      return "$vuetify.icons.edit";
    },
  },
  methods: {
    onOldScicCollegeChanges(new_value) {
      if(!_.isNil(new_value.id)) {
        this.college.id = new_value.id;
      }
      this.college.code = new_value.code;
      this.college.name = new_value.name;
      this.college.pourcentage_droit_vote = new_value.pourcentage_droit_vote;
      this.college.description = new_value.description;
    },
    submit() {
        const new_scic_college = _.cloneDeep(this.college);
        this.$emit("edit-scic-college", this.oldScicCollege, new_scic_college);
        this.closePopUp();
    },
    closePopUp() {
      this.dialog = false;
      this.$refs.formScicCollege.reset();
    },
  }
};