import {apiConstructor} from "../../index";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import Utils from "@/utils.js";

export default scicCollegeApi;

function scicCollegeApi() {
  const specificApi = {
    postMassEditing: postMassEditing(),
    putMassEditing: putMassEditing(),
    getGraphCollegeGraphRepartitionAssocies: getGraphCollegeGraphRepartitionAssocies(),
  };

  return Object.assign(specificApi, defaultApi(internalApiRoutes.scicCollege.baseUrl, [
    ACCEPTED_METHODS.getAll,
    ACCEPTED_METHODS.getById,
    ACCEPTED_METHODS.putById,
    ACCEPTED_METHODS.postAll,
    ACCEPTED_METHODS.getLabels,
  ]));
}

function postMassEditing() {
  function postAll(body, queryParams, headers) {
    let path = internalApiRoutes.scicCollege.massEditing();
    let postForPath = apiConstructor.post(path);
    return postForPath(body, queryParams, headers);
  }

  return postAll;
}

function putMassEditing() {
  function putAll(body, queryParams, headers) {
    let path = internalApiRoutes.scicCollege.massEditing();
    let putForPath = apiConstructor.put(path);
    return putForPath(body, queryParams, headers);
  }

  return putAll;
}

function getGraphCollegeGraphRepartitionAssocies() {
  function getAll(date, queryParams, headers) {
    const dateString = Utils.displayDateToIsoFormat(date);
    let path = internalApiRoutes.scicCollege.getGraphCollegeGraphRepartitionAssocies(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return getAll;
}
