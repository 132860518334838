// <!-- VUETIFY2 - OK -->
import internalApi from "@/api/internalApi";
import PersonneMorale from "./PersonneMorale";
import constantes from "@/utils/constantes";

export default {
  mounted() {
    const id = this.$route.params.id;
    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
    internalApi.personneMorale.getInfosEspacePersonnel(id).then(result => {
      // Need to guess some data to use it correctly
      if(_.isNil(result.infos_bancaire)){
        result.infos_bancaire = {
          "nom_banque": null,
          "bic": null,
          "iban": null,
        };
      }
      this.personne_morale = result;
    }).finally(() => {
      this.$refs.loadingComponent.resetLoading();
    });
  },
  extends: PersonneMorale,
  computed: {},
  methods: {
    cancel(){
      this.$router.push({name: "home"});
    },
    /**
     * Make API calls to POST or PUT resource on backend
     */
    submitForm() {
      const data_to_send = this.updatePersonneMoraleBeforeSend();
      this.$refs.loadingComponent.setLoading(constantes.loading_message.modification);
      return internalApi.personneMorale
        .putInfosEspacePersonnelWithToastConfirmation(data_to_send)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          if (result) {
            this.$router.push({name: "home"});
          }
        })
        .catch(err => {
          console.error(err);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
