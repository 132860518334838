<!-- VUETIFY2 - OK -->
<template>
    <!-- Fenêtre modale -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on: passwordReset }">
            <v-icon-button-with-tool-tip :activator="passwordReset"
                                         :detail="tooltipDetailMessage"
                                         :icon="'$vuetify.icons.key'"
                                         :btnIsIcon="btnIsIcon" btnRound btnText btnSmall iconSmall tooltipBottom
                                         btnColorClass="transparent"
                                         iconColorClass="grey darken-1"
                                         :detailButtonText="detailButtonText"
            />
        </template>
        <ValidationObserver ref="formPasswordReset">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>{{title}}</v-card-title>
                <v-container class="pt-0">
                    <v-container class="pt-0">
                        <v-row>
                            <v-col md="10" lg="10" xl="10">
                                <ValidationProvider v-if="showOldPassword" vid="wrong_old_password"
                                                    name="ancien mot de passe"
                                                    rules="required" v-slot="{ valid, errors }">
                                    <v-text-field label="Ancien mot de passe"
                                                  v-model="oldPassword"
                                                  :type="'password'"
                                                  :error-messages="errors"
                                                  :success="valid"/>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-new-password-text-and-confirmation v-model="newPassword"/>
                </v-container>
                <v-card-actions>
                    <v-row justify="end" no-gutters>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4">
                            <v-btn block @click="dialog = false">{{labelAnnulation}}</v-btn>
                        </v-col>
                        <!-- Bouton modal -->
                        <v-col md="4" lg="4" xl="4" class="ml-3">
                            <v-btn block color="primary" @click="onPasswordChangeClick">
                                {{labelConfirmation}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>
<script>

    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    import VNewPasswordTextAndConfirmation from "@/components/administration/VNewPasswordTextAndConfirmation";

    export default {
        name: "v-password-reset-dialog",
        components: {
            VNewPasswordTextAndConfirmation,
            VIconButtonWithToolTip
        },
        props: {
            openButtonMessage: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
                default: "Confirmation"
            },
            labelAnnulation: {
                type: String,
                required: false,
                default: "Non, abandonner"
            },
            labelConfirmation: {
                type: String,
                required: false,
                default: "Oui, confirmer"
            },
            showOldPassword: {
                type: Boolean,
                required: false,
                default: false
            },
            btnIsIcon: {
                type: Boolean,
                required: false,
                default: true
            },
            detailButtonText: {
                type: String,
                required: false,
                default: null
            }
        },
        data() {
            return {
                tooltipDetailMessage: "Modification du mot de passe",
                newPassword: "",
                oldPassword: null,
                dialog: false,
                value: ''
            };
        },
        methods: {
            onPasswordChangeClick: function () {
                this.$refs.formPasswordReset.validate().then(
                    success => {
                        if (success) {
                            let passwords = {"new_password": this.newPassword, "old_password": this.oldPassword};
                            this.$emit("action-confirmee", passwords);
                        }
                    }
                )
            },
            closeDialog() {
                this.dialog = false;
            }
        }
    };
</script>