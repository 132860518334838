<!-- VUETIFY2 - OK -->
<template>
    <v-img src="/logo2.png" alt="Logo Eziscop" :width="width"/>
</template>


<script>
    export default {
        props: {
            width: {
                type: String,
                default: "100%"
            }
        }
    }
</script>