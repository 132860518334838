<template>
    <base-layout :withHeader="false" :withFooter="true" :datatableExcludedSize="datatableExcludedSize">
        <template v-slot:main="{ datatableSize }">
            <v-container fluid class="py-0">
                <loading-screen ref="loadingComponent"/>
                <v-row no-gutters>
                    <v-col cols="3">
                        <v-text-field v-model="searchName"
                                        append-icon="search"
                                        label="Nom du bénéficiaire"
                                        single-line/>
                    </v-col>
                    <v-col cols="3">
                        <v-radio-group class="ml-5" v-model="selectedDisponibiliteDisplayState"
                                                    @change="computeHeaders()">
                            <v-radio v-for="state in switchDisponibiliteDisplayStates" :key="state.key"
                                    :label="state.label"
                                    :value="state.key"/>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-data-table :headers="headers"
                              :items="beneficiairesData"
                              :no-data-text="noDataTextMessage"
                              :height="datatableSize"
                              fixed-header
                              :options.sync="options"
                              :footer-props="footerProps"
                              :search="searchName"
                              class="elevation-1">
                    <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                        <span :key="h.value">
                            {{ h.text }}
                            <br/>
                            {{ h.subtext }}
                        </span>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.name }}</td>
                            <td>{{ item.numero_tiers }}</td>
                            <template v-for="(disponibilite_data, index) in item.disponibilites">
                                <td v-if="canDisplayPlacements" class="text-right" :key="item.numero_tiers+'_placements_'+index">{{ disponibilite_data.placements | roundEuroFilterNotDisplayZero }}</td>
                                <td v-if="canDisplayInterets" class="text-right" :key="item.numero_tiers+'_interets_'+index">{{ disponibilite_data.interets | roundEuroFilterNotDisplayZero }}</td>
                                <td v-if="canDisplayTotal" class="text-right" :key="item.numero_tiers+'_total_'+index">{{ disponibilite_data.total | roundEuroFilterNotDisplayZero }}</td>
                            </template>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]>
                        <tr>
                            <td class="font-weight-bold">Totaux</td>
                            <td/> <!-- Case vide numéro de tiers -->
                            <template v-for="(disponibilite_data, index) in totaux">
                                <td v-if="canDisplayPlacements" class="font-weight-medium text-right" :key="'totaux_'+index+'_placements'">{{ disponibilite_data.placements | roundEuroFilterNotDisplayZero }}</td>
                                <td v-if="canDisplayInterets" class="font-weight-medium text-right" :key="'totaux_'+index+'_interets'">{{ disponibilite_data.interets | roundEuroFilterNotDisplayZero }}</td>
                                <td v-if="canDisplayTotal" class="font-weight-medium text-right" :key="'totaux_'+index+'_total'">{{ disponibilite_data.total | roundEuroFilterNotDisplayZero }}</td>
                            </template>
                        </tr>
                    </template>
                </v-data-table>
            </v-container>
        </template>
        <template v-slot:footer>
            <!-- Grand bouton -->
            <v-row>
                <v-col cols="3">
                    <v-btn dark color="primary" outlined @click="exportDisponibilitesBeneficiaires" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger les données
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import Utils from "@/utils";
    import VExportFile from "@/components/VExportFile";

/**
 * Composant affichant les disponibilites sur tous les supports de pb cumulés pour chaque bénéficiaire
 * @display_name Participation - VDatatableDisponibilitesBeneficiaires
 */
export default {
    name: "VDatatableDisponibilitesBeneficiaires",
    components: {
        BaseLayout,
        loadingScreen,
    },
    props: {
        /**
         * datatableExcludedSize for the datatable
         */
        datatableExcludedSize: {
            type: String,
            required: false,
        },
        dateReference: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            noDataTextMessage: "Aucune disponibilité n'a été trouvée",
            headers: [],
            beneficiairesData: [],
            dates_disponibilite: [],
            totaux: [],
            options: {
                sortBy: ['name'],
                sortDesc: [false],
                page: 1,
                itemsPerPage: 10,
            },
            footerProps: {
                itemsPerPageOptions: [
                    10,
                    20,
                    50,
                ],
            },
            searchName: undefined,
            selectedDisponibiliteDisplayState: "all",
            switchDisponibiliteDisplayStates: [
                {
                    label: "Participation nette et intérêts bruts",
                    key: "all"
                },
                {
                    label: "Participation nette",
                    key: "placements"
                },
                {
                    label: "Intérêts bruts",
                    key: "interets"
                },
            ],
        }
    },
    watch: {
        dateReference: function () {
            this.getDisponibilitesData();
        },
    },
    mounted() {
        this.getDisponibilitesData();
    },
    computed: {
        canDisplayPlacements(){
            return ["placements", "all"].includes(this.selectedDisponibiliteDisplayState)
        },
        canDisplayInterets(){
            return ["interets", "all"].includes(this.selectedDisponibiliteDisplayState)
        },
        canDisplayTotal(){
            return this.selectedDisponibiliteDisplayState == "all"
        },
    },
    methods: {
        getDisponibilitesData() {
            this.$refs.loadingComponent.setLoading("Chargement des disponibilités");
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            internalApi.operations.getDisponibilitesBeneficiaires(this.dateReference)
                .then(result => {
                    this.beneficiairesData = result.beneficiaires;
                    this.dates_disponibilite = result.dates_disponibilite;
                    this.totaux = result.totaux;
                }).catch(err => {
                    console.error("Erreur lors de la récupération des disponibilités : ", err);
                }).finally( () => {
                    this.computeHeaders();
                    this.$refs.loadingComponent.resetLoading();
                })
        },
        computeHeaders(){
            var building_headers = [{text: "Bénéficiaire", value: "name", fixed: true}, {text: "Numéro de tiers", value: "numero_tiers", fixed:true}]
            this.dates_disponibilite.forEach( (date, i) => {
                if(this.canDisplayPlacements){
                    building_headers.push({
                        text: Utils.formatDateIsoToFr(date),
                        subtext: "Participation nette",
                        value: date+'_placements',
                        sortable: false,
                        align: "end",
                    });
                }
                if(this.canDisplayInterets){
                    building_headers.push({
                        text: Utils.formatDateIsoToFr(date),
                        subtext: "Intérêts bruts",
                        value: date+'_interets',
                        sortable: false,
                        align: "end",
                    });
                }
                if(this.canDisplayTotal){
                    building_headers.push({
                        text: Utils.formatDateIsoToFr(date),
                        subtext: "Total",
                        value: date+'_total',
                        sortable: false,
                        align: "end",
                    });
                }
            });
            this.headers = building_headers;
        },
        exportDisponibilitesBeneficiaires() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
                internalApi.operations.exportDisponibilitesBeneficiaires(this.dateReference)
                    .then(response => {
                        Utils.downloadFile(response, "disponibilites.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
    },
}
</script>