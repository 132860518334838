<!-- VUETIFY2 - OK -->
<template>
    <v-row no-gutters>
        <v-col md="5" lg="5" xl="6" offset-md="1">
            <ValidationProvider vid="valueSelectedItem"
                                name="Associé(s)"
                                rules="required"
                                v-slot="{ errors }">
                <v-associes-list :value="valueSelectedItem"
                                 label="Associé(e)"
                                 :isDisabled="isDisabled"
                                 @input="updateSelectedAssocie($event)"
                                 :errors="errors"
                />
            </ValidationProvider>
        </v-col>
        <v-col md="4" lg="4" xl="3" offset-md="1">
            <v-row align="center" no-gutters>
                <v-col md="11" lg="11" xl="11">
                    <ValidationProvider vid="localDateMouvement" name="Date"
                                        rules="required"
                                        v-slot="{ errors }">
                        <DateField v-model="localDateMouvement"
                                   ref="date_annu"
                                   :errors="errors"
                                   :label="labelDate"
                                   :disabled="!canEditDate"
                                   :allowedDate="computeAllowedDates()"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col md="1" lg="1" xl="1" ml-4 v-if="allowedDateAfterLastExercice">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                        </template>
                        <span>Les dates sélectionnables sont celles postérieures à la date de fin du dernier exercice validé.</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import DateField from "@/components/DateField.vue";
    import moment from "moment";
    import _ from "lodash";
    import VAssociesList from "@/components/VAssociesList.vue"
    import internalApi from "@/api/internalApi";

    /**
     * Composant permettant de choisir un associé et une date
     * @displayName Capital - VDateAssocieHeader
     */
    export default {
        props: {
            /**
             * L'associé sélectionné
             */
            valueSelectedItem: {
                twoWay: true,
                type: Number
            },
            valueDateMouvement: {
                twoWay: true,
                type: String
            },
            /**
             * Le nom donné au champ de la date
             */
            labelDate: {
                type: String
            },
            itemsValue: {
                type: Array
            },
            isDisabled: Boolean,
            canEditDate:  Boolean,
            /**
             * Détermine si les dates sélectionnables sont uniquement celles postérieures à
             * la date de fin du dernier exercice validé
             */
            allowedDateAfterLastExercice: Boolean,
        },
        components: {
            DateField,
            VAssociesList,
        },
        data() {
            return {
                associeList: [],
                dateMinimumSouscription: undefined,
            }
        },
        computed: {
            localSelectedAssocie: {
                get: function () {
                    return this.valueSelectedItem;
                },
                set: function (newvalue) {
                    this.$emit("update-selected-item", newvalue);
                }
            },
            localDateMouvement: {
                get: function () {
                    return this.valueDateMouvement;
                },
                set: function (newvalue) {
                    this.$emit("update-date-mouvement", newvalue);
                }
            }
        },
        mounted() {
            this.getDateMinimumSouscription();
        },
        methods: {
            /**
             * Récupère la date minimum à laquelle réaliser les souscriptions et annulations
             */
            getDateMinimumSouscription() {
                internalApi.capExercice.dateMinimumSouscription()
                    .then(results => {
                        this.dateMinimumSouscription = results.date_minimum_souscription;
                    }).catch(err => {
                        console.log(err);
                    });
            },
            /**
             * Emet un évènement update-selected-associe. Cette fonction est appelée quand un
             * nouvel associé est sélectionné.
             * @param newValue le nouvel associé seléctionné
             */
            updateSelectedAssocie(newValue) {
                this.$emit("update-selected-associe", newValue);
            },
            /**
             * Retourne une fonction qui calcul les dates qui sont autorisées rapport
             * aux exercices validés
             * @returns {function}
             */
            computeAllowedDates() {
                if (this.allowedDateAfterLastExercice && ! _.isNil(this.dateMinimumSouscription)) {
                    return val => moment(this.dateMinimumSouscription, 'YYYY-MM-DD').isSameOrBefore(val);
                } else {
                    return val => true;
                }
            }
        }
    };
</script>


