<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" :withHeader="false">
        <template v-slot:main>
            <brouillon-souscription v-if="getTypeBulletin() === 'sous'"
                                    :associeId="getAssocieId()"
                                    :dateMouvement="getDateMouvement()"
                                    aSpecificBackRoute='{"name": "tiers"}'>
            </brouillon-souscription>
            <brouillon-annulation v-if="getTypeBulletin() === 'part'"
                                  :associeId="getAssocieId()"
                                  :dateMouvement="getDateMouvement()"
                                  aSpecificBackRoute='{"name": "tiers"}'>
            </brouillon-annulation>
            <brouillon-annulation-totale v-if="getTypeBulletin() === 'tot'"
                                         :associeId="getAssocieId()"
                                         :dateMouvement="getDateMouvement()"
                                         aSpecificBackRoute='{"name": "tiers"}'
                                         :isBrouillon="true"
                                         :allowedDateAfterLastExercice="false">
            </brouillon-annulation-totale>
        </template>
    </base-layout>
</template>

<script>
    import BrouillonSouscription from "@/views/capital/souscription/BrouillonSouscription";
    import BrouillonAnnulation from "@/views/capital/annulation/BrouillonAnnulation";
    import BrouillonAnnulationTotale from "@/views/capital/annulation/BrouillonAnnulationTotale";
    import moment from "moment";
    import BaseLayout from "../../../components/BaseLayout";

    /**
     * Composant permettant l'édition d'un bulletin de souscription, d'annulation ou
     * d'annulation totale
     * @displayName Capital - BulletinsBrouillons
     */
    export default {
        components: {BaseLayout, BrouillonSouscription, BrouillonAnnulation, BrouillonAnnulationTotale},
        data() {
            return {
                checkedValue: null,
                bulletinsList: [
                    {
                        title: "Bulletin de souscriptions de parts sociales",
                        key: "sous"
                    },
                    {
                        title: "Bulletin d'annulation partielle de parts sociales",
                        key: "part"
                    },
                    {
                        title: "Bulletin d'annulation totale de parts sociales",
                        key: "tot"
                    },
                ]
            };
        },
        methods: {
            /**
             * Récupère l'id de l'associé pour lequel éditer un bulletin via la route
             * @returns {number}
             */
            getAssocieId() {
                return parseInt(this.$route.params.associeId);
            },
            /**
             * Récupère le type de bulletin à éditer via la route
             * @returns {string}
             */
            getTypeBulletin() {
                return this.$route.params.typeBulletin;
            },
            /**
             * Retourne la date du jour au format YYYY-MM-DD
             * @returns {string}
             */
            getDateMouvement() {
                return moment().format("YYYY-MM-DD");
            },
        }
    };
</script>