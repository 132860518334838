/* VUETIFY2 - OK */
import VRegleRepartitionPbAnciennete from "./VRegleRepartitionPbAnciennete";
import internalApi from "@/api/internalApi";

export default {
  extends: VRegleRepartitionPbAnciennete,
  props: {
    oldRegle: {
      type: Object,
      required: true,
      default: undefined
    }
  },
  computed: {
    title() {
      return "Edition d'une règle de calcul de répartition selon l'ancienneté";
    },
    isModif() {
      return true;
    },
    openButtonMessage() {
      return "Modifier";
    },
    iconValue() {
      return "$vuetify.icons.edit";
    },
    tooltipDetailMessage() {
      return "Modifier une règle de répartition";
    }
  },
  mounted() {
    if (!_.isNil(this.oldRegle.id)) {
      this.regle.id = this.oldRegle.id;
    }
    this.regle.groupe = this.oldRegle.groupe;
    this.regle.anciennete = this.oldRegle.anciennete;
    this.regle.coefficient = this.oldRegle.coefficient;
  },
  methods: {
    submit() {
      this.$refs.formRegleRepartitionPbAnciennete.validate().then(validForm => {
        if (validForm) {
          let data = _.cloneDeep(this.regle);
          delete data.id;
          data.regle = "plus_de";
          data.pb_company_id = this.pbCompanyId;

          internalApi.repartitionPbAnciennete.putByIdWithToastConfirmation(this.regle.id, data)
            .then(res => {
              this.$emit("edit-regle");
              this.closePopUp();
            })
            .catch(err => {
              console.log(err);
              this.closePopUp();
            });
        }
      });
    },
    closePopUp() {
      this.dialog = false;
      this.$refs.formRegleRepartitionPbAnciennete.reset();
    },
  }
};