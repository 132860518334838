<!-- VUETIFY2 - OK -->
<template>
    <v-row style="margin-top: 10%;" justify="space-around">
        <v-col md="4" lg="4" xl="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-row>
                        <v-col>
                            <logo-svg-complet-blanc width="230px"/>
                        </v-col>
                    </v-row>
                </v-toolbar> 
                    <v-form @submit="connexion">
                        <v-card-text>
                                <v-text-field name="login"
                                            prepend-icon="person"
                                            label="Identifiant"
                                            v-model="username"
                                />
                                <v-password-visualiser
                                    prepend-icon="lock"
                                    label="Mot de passe"
                                    v-model="password"
                                    />
                            <v-forgot-password/>
                        </v-card-text>
                        <v-card-actions>
                            <v-row justify="end">
                                <!-- Grand bouton -->
                                <v-col md="4" lg="4" xl="3">
                                    <v-btn type="submit" color="primary"  block>Connexion</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import authService from '@/services/authService.js';
    import LogoSvgCompletBlanc from "@/components/assets/LogoSvgCompletBlanc.vue";
    import VForgotPassword from "@/components/VForgotPassword";
    import VPasswordVisualiser from '@/components/VPasswordVisualiser';

    export default {
        components: {
            LogoSvgCompletBlanc,
            VForgotPassword,
            VPasswordVisualiser,
        },
        data() {
            return {
                password: "",
                username: "",
                showPassword: false
            }
        },
        methods: {
            connexion:
                function (e) {
                    e.preventDefault();
                    authService.login(this.username, this.password, this.$route.params.tenant);
                },

        }
    };
</script>
